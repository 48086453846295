import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  Image,
  Dimensions,
  Animated,
  TouchableOpacity,
  StyleSheet,
  ScrollView,
  TextInput,
  FlatList,
} from "react-native";
import {
  MaterialCommunityIcons,
  FontAwesome,
  FontAwesome5,
  MaterialIcons,
  Ionicons,
} from "@expo/vector-icons";
import { useWindowSize } from "../components/responsive";
import { useRecoilState } from "recoil";
import { courseState, tokenState } from "../../reducer/recoil";
import { apiservice } from "../../service";
import Modal from "modal-react-native-web";
const { width, height } = Dimensions.get("window");
export default function cousePage4({
  rating,
  navigation,
  rate,
  rating1,
  rating2,
  rating3,
  rating4,
  rating5,
  onPress,
  use,
}) {
  const [token, setToken] = useRecoilState(tokenState);
  const [courseStates, setCourseStates] = useRecoilState(courseState);
  const [modalDelItem, setModalDelItem] = useState(false);
  const [id, setId] = useState();
  // const [rating1, setrating1] = useState(0);
  // const [rating2, setrating2] = useState(0);
  // const [rating3, setrating3] = useState(0);
  // const [rating4, setrating4] = useState(0);
  // const [rating5, setrating5] = useState(0);

  const DATA = [{ img: "MCU", name: "จิระพงศ์", text: "เรียนง่าย" }];
  const size = useWindowSize();
  // useEffect(() => {
  //   star();
  // }, []);

  // async function star() {
  //   const response = await apiservice({
  //     path: "/start-course/person?course_id=" + courseStates.id,
  //     method: "get",
  //     token: token?.accessToken,
  //   });
  //   if (response.status == 200) {
  //     setrating1((response.data.star?.["1"] * 100) / response.data.star.all);
  //     setrating2((response.data.star?.["2"] * 100) / response.data.star.all);
  //     setrating3((response.data.star?.["3"] * 100) / response.data.star.all);
  //     setrating4((response.data.star?.["4"] * 100) / response.data.star.all);
  //     setrating5((response.data.star?.["5"] * 100) / response.data.star.all);
  //   }
  // }
  return (
    <View style={{ minHeight: 500 }}>
      {rating.length == 0 && (
        <Text
          style={[styles.textTitle, { fontSize: size.width < 750 ? 16 : 20 }]}
        >
          {"\nไม่มีรีวิวสำหรับคอร์สนี้"}
        </Text>
      )}
      {rating.length > 0 && (
        <View
          style={{
            flexDirection: "row",
            borderBottomWidth: 1,
            borderBottomColor: "#BBB",
            paddingVertical: 25,
          }}
        >
          <View
            style={{
              width: size.width < 750 ? "30%" : "20%",
              alignItems: "center",
            }}
          >
            <Text style={styles.textTitle}>คะแนนเฉลี่ย</Text>
            <Text
              style={{ fontSize: 60, fontFamily: "Prompt", color: "#646464" }}
            >
              {rate}
            </Text>
            <View style={{ flexDirection: "row" }}>
              <MaterialIcons
                name={rate > 0 ? "star" : "star-border"}
                size={24}
                color="#0D88F0"
              />
              <MaterialIcons
                name={rate > 1 ? "star" : "star-border"}
                size={24}
                color="#0D88F0"
              />
              <MaterialIcons
                name={rate > 2 ? "star" : "star-border"}
                size={24}
                color="#0D88F0"
              />
              <MaterialIcons
                name={rate > 3 ? "star" : "star-border"}
                size={24}
                color="#0D88F0"
              />
              <MaterialIcons
                name={rate > 4 ? "star" : "star-border"}
                size={24}
                color="#0D88F0"
              />
            </View>
          </View>
          <View style={{ width: "70%", alignItems: "center" }}>
            <Text style={styles.textTitle}>รายละเอียด</Text>
            <View style={styles.viewScore}>
              <Text style={styles.textStar}>5</Text>
              <MaterialIcons name="star" size={15} color="#646464" />
              <View style={styles.star}>
                <View
                  style={[
                    styles.viewStar,
                    {
                      backgroundColor: rating5 != 0 ? "#0D88F0" : "#FFF",
                      width: rating5 + "%",
                    },
                  ]}
                ></View>
              </View>
            </View>
            <View style={styles.viewScore}>
              <Text style={styles.textStar}>4</Text>
              <MaterialIcons name="star" size={15} color="#646464" />
              <View style={styles.star}>
                <View
                  style={[
                    styles.viewStar,
                    {
                      backgroundColor: rating4 != 0 ? "#0D88F0" : "#FFF",
                      width: rating4 + "%",
                    },
                  ]}
                ></View>
              </View>
            </View>
            <View style={styles.viewScore}>
              <Text style={styles.textStar}>3</Text>
              <MaterialIcons name="star" size={15} color="#646464" />
              <View style={styles.star}>
                <View
                  style={[
                    styles.viewStar,
                    {
                      backgroundColor: rating3 != 0 ? "#0D88F0" : "#FFF",
                      width: rating3 + "%",
                    },
                  ]}
                ></View>
              </View>
            </View>
            <View style={styles.viewScore}>
              <Text style={styles.textStar}>2</Text>
              <MaterialIcons name="star" size={15} color="#646464" />
              <View style={styles.star}>
                <View
                  style={[
                    styles.viewStar,
                    {
                      backgroundColor: rating2 != 0 ? "#0D88F0" : "#FFF",
                      width: rating2 + "%",
                    },
                  ]}
                ></View>
              </View>
            </View>
            <View style={styles.viewScore}>
              <Text style={styles.textStar}>1</Text>
              <MaterialIcons name="star" size={15} color="#646464" />
              <View style={[styles.star, { marginLeft: 18 }]}>
                <View
                  style={[
                    styles.viewStar,
                    {
                      backgroundColor: rating1 != 0 ? "#0D88F0" : "#FFF",
                      width: rating1 + "%",
                    },
                  ]}
                ></View>
              </View>
            </View>
          </View>
        </View>
      )}
      <FlatList
        data={rating
          .sort((a, b) => {
            if (b.id > a.id) {
              return 1;
            }
            if (b.id < a.id) {
              return -1;
            }
            return 0;
          })
          .filter((e) => !e.read)}
        numColumns={1}
        renderItem={({ index, item }) => {
          return (
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                borderBottomWidth: 1,
                borderBottomColor: "#FFD3C4",
                paddingVertical: 30,
              }}
            >
              {token.role == "SUPERADMIN" && (
                <TouchableOpacity
                  onPress={async () => {
                    setId(item.uid);
                    setModalDelItem(true);
                  }}
                  style={{
                    position: "absolute",
                    right: 0,
                    zIndex: 99,
                    marginTop: -50,
                  }}
                >
                  <Ionicons name="eye" size={24} color="#484848" />
                </TouchableOpacity>
              )}
              <View style={{ flexDirection: "row", alignItems: "center" }}>
                <Image
                  style={{
                    width: 60,
                    height: 60,
                    borderRadius: 30,
                    marginRight: 15,
                    backgroundColor: "#CCCCCC",
                  }}
                  source={{
                    uri:
                      "https://api.courseoncloud.com/api/image/getimage/" +
                      item.user_accounts.image_url,
                  }}
                />
                <View>
                  <Text style={styles.textStar}>
                    {item.user_accounts.firstName == null
                      ? item.username
                      : item.user_accounts.firstName +
                        " " +
                        item.user_accounts.lastName}
                  </Text>
                  <Text style={styles.textStar}>{item.about}</Text>
                </View>
              </View>
              <View style={{ flexDirection: "row" }}>
                <MaterialIcons
                  name={item.rating > 0 ? "star" : "star-border"}
                  size={20}
                  color="#0D88F0"
                />
                <MaterialIcons
                  name={item.rating > 1 ? "star" : "star-border"}
                  size={20}
                  color="#0D88F0"
                />
                <MaterialIcons
                  name={item.rating > 2 ? "star" : "star-border"}
                  size={20}
                  color="#0D88F0"
                />
                <MaterialIcons
                  name={item.rating > 3 ? "star" : "star-border"}
                  size={20}
                  color="#0D88F0"
                />
                <MaterialIcons
                  name={item.rating > 4 ? "star" : "star-border"}
                  size={20}
                  color="#0D88F0"
                />
              </View>
            </View>
          );
        }}
      />
      <Modal
        transparent={true}
        visible={modalDelItem}
        onRequestClose={() => {
          Alert.alert("Modal has been closed.");
          setModalDelItem(!modalDelItem);
        }}
      >
        <View style={styles.bgModal}>
          <View
            style={[
              styles.viewDetailModal,
              { width: "30%", height: 183, paddingVertical: 25 },
            ]}
          >
            <Text
              style={[
                styles.textButtonModal,
                { color: "#444444", alignSelf: "center" },
              ]}
            >
              คุณต้องการซ่อนใช่รึไม่
            </Text>
            <View
              style={{
                flexDirection: "row",
                alignSelf: "center",
                marginTop: 39,
              }}
            >
              <TouchableOpacity
                onPress={() => setModalDelItem(!modalDelItem)}
                style={styles.buttonModal}
              >
                <Text
                  style={[
                    styles.textButtonModal,
                    {
                      color: "#444444",
                    },
                  ]}
                >
                  ยกเลิก
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={async () => {
                  const response = await apiservice({
                    path:
                      "/start-course/hidereview/" +
                      courseStates.id +
                      "?id=" +
                      id,
                    method: "get",
                    token: token?.accessToken,
                  });

                  setTimeout(() => {
                    setModalDelItem(!modalDelItem);
                    use;
                  }, 500);
                }}
                style={[
                  styles.buttonModal,
                  {
                    backgroundColor: "#0D88F0",
                    marginLeft: width * 0.05,
                  },
                ]}
              >
                <Text
                  style={[
                    styles.textButtonModal,
                    {
                      color: "#FFFFFF",
                    },
                  ]}
                >
                  ยืนยัน
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </Modal>
    </View>
  );
}
const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: "100%",
    backgroundColor: "#FFFFFF",
  },
  textStar: {
    fontSize: 10,
    fontFamily: "Prompt",
    color: "#646464",
    textAlign: "center",
  },
  textTitle: {
    fontSize: 20,
    fontFamily: "Prompt",
    color: "#646464",
  },
  viewStar: {
    width: "100%",
    height: 13,
    borderWidth: 1,
    borderColor: "#0D88F0",
  },
  viewScore: {
    flexDirection: "row",
    width: "70%",
    alignItems: "center",
    marginTop: 5,
  },
  star: {
    borderWidth: 1,
    width: "100%",
    marginLeft: 15,
    borderColor: "#AAA",
  },
  bgModal: {
    width: "100%",
    height: "100%",
    backgroundColor: "#000000bb",
    justifyContent: "center",
  },
  viewDetailModal: {
    width: "50%",
    height: 380,
    backgroundColor: "#FFFFFF",
    borderRadius: 20,
    alignSelf: "center",
    paddingHorizontal: width * 0.05,
    paddingVertical: 50,
  },
  buttonModal: {
    width: 102,
    height: 48,
    borderRadius: 4,
    backgroundColor: "#EFEFF3",
    justifyContent: "center",
    alignItems: "center",
  },
  textButtonModal: {
    fontSize: 25,
    fontFamily: "Prompt",
  },
});
