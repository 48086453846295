import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  Image,
  Dimensions,
  Animated,
  TouchableOpacity,
  StyleSheet,
  ScrollView,
  TextInput,
  FlatList,
} from "react-native";
import {
  MaterialCommunityIcons,
  FontAwesome,
  FontAwesome5,
  MaterialIcons,
  Ionicons,
} from "@expo/vector-icons";
import Modal from "modal-react-native-web";
import Header from "../components/header";
import { BottomPart, bottomPart } from "./home";
import ButtonLogin from "../components/buttonLogin";
import RNPickerSelect from "react-native-picker-select";
import { authActionRegister } from "../../action/authAction";
import * as DocumentPicker from "expo-document-picker";
import { apiservice } from "../../service";
import { useRecoilState, useRecoilValue } from "recoil";
import { registerState, tokenState } from "../../reducer/recoil";
import { useWindowSize } from "../components/responsive";
import NumberFormat from "react-number-format";
const AddressThailand = require("../address/address_thailand.json");

const { width, height } = Dimensions.get("window");
export default function instructor({ navigation, route }) {
  const [token, setToken] = useRecoilState(tokenState);
  const [modalSucceed, setModalSucceed] = useState(false);
  const [modalCheck, setModalCheck] = useState(false);
  const [modalError, setModalError] = useState(false);
  const [modalErrortel, setModalErrortel] = useState(false);
  const [modalErroridCard, setModalErroridCard] = useState(false);
  const [modalErrorbalance, setModalErrorbalance] = useState(false);
  const [modalWarning, setModalWarning] = useState(false);
  const [check, setCheck] = useState(false);
  const [check1, setCheck1] = useState(false);
  const [bankIndex, setBankIndex] = useState(-1);
  const [bank, setBank] = useState("");
  const [addEd, setAddEd] = useState([]);
  const [img, setimg] = useState("");
  const [img2, setimg2] = useState("");
  const [img3, setimg3] = useState("");
  const [img1, setimg1] = useState("");
  const [selectClass, setSelectClass] = useState("");
  const [dataRegis, setDataRegis] = useRecoilState(registerState);
  const detail = route.params;

  const bankList = ["ธนาคารกสิกร", "ธนาคารกรุงไทย", "ธนาคารไทยพาณิชย์"];

  const bankChangeHandler = (index, data) => {
    if (data == "ธนาคารกสิกร") {
      setState({ ...state, balance_info: "KBANK" });
    }
    if (data == "ธนาคารกรุงไทย") {
      setState({ ...state, balance_info: "KTB" });
    }
    if (data == "ธนาคารไทยพาณิชย์") {
      setState({ ...state, balance_info: "SCB" });
    }
    setBankIndex((preIndex) => index);
    setBank(data);
  };

  async function pickup1() {
    let result = await DocumentPicker.getDocumentAsync({
      type: "application/pdf,image/jpeg",
    });
    // setImage(result.uri)
    const response = await apiservice({
      path: "/image/createpdf",
      method: "post",
      body: {
        name: result.name.replace(".png", ""),
        base64: result.uri,
      },
    });
    setimg1(result.name);

    setState({ ...state, organization: response.data.imageRefId });
  }

  const placeholder = {
    label: "",
    value: null,
    color: "#FCBDA6",
  };

  const [status, setStatus] = useState([
    { label: "ปริญญาตรี", value: "0" },
    { label: "ปริญญาโท", value: "1" },
    { label: "ปริญญาเอก", value: "2" },
  ]);
  const placeholder3 = {
    label: "ตำบล",
    value: null,
    color: "#777777",
  };

  const [state, setState] = useState({
    role: "TEACHER",
    sub_role: "MAIN",
    username: dataRegis.username,
    email: detail != undefined ? detail.user_accounts.email : dataRegis.email,
    password: dataRegis.password,
    firstName: detail != null ? detail.user_accounts.firstName : "",
    lastName: detail != null ? detail.user_accounts.lastName : "",
    telNo: detail != null ? detail.user_accounts.telNo : "",
    address: detail != null ? detail.user_accounts.address : "",
    balance_info: "",
    balance_no: detail != null ? detail.user_accounts.balance_no : "",
    promptpay: detail != null ? detail.user_accounts.promptpay : "",
    education:
      detail != null
        ? detail.user_accounts.education
        : [
            {
              University: "",
              Faculty: "",
              Majored: "",
              everage: "",
              file: "",
            },
          ],
    organization: "",
    position: "Teacher",
    about_us: "",
    facebook_url: "",
    instagram_url: "",
    twitter_url: "",
    approve: false,
    code_serial: detail != null ? detail.user_accounts.code_serial : "",
  });

  async function addEducation() {
    setState((val) => ({
      ...val,
      education: val.education.concat({
        University: "",
        Faculty: "",
        Majored: "",
        everage: "",
        file: "",
      }),
    }));
  }

  async function register() {
    let data = {
      ...state,
      address: {
        house: body.house,
        Idcard: body.Idcard,
        province: address?.province,
        district: address?.amphoe,
        postcode: address?.zipcode,
        subDistrict: body.subDistrict,
        img: body.img,
      },
    };

    if (
      data.address != null &&
      state.balance_info != "" &&
      state.balance_no != "" &&
      state.education.map((item) => {
        item.University != "" &&
          item.Faculty != "" &&
          item.Majored != "" &&
          item.everage != "" &&
          item.file != "";
      }) &&
      state.email != "" &&
      state.firstName != "" &&
      state.lastName != "" &&
      state.telNo != ""
    ) {
      if (data.telNo.length != 10) {
        setModalErrortel(true);
      } else if (data.balance_no.length != 10) {
        setModalErrorbalance(true);
      } else if (data.address.Idcard.length != 13) {
        setModalErroridCard(true);
      } else {
        const regis = await authActionRegister(data);
        if (regis) {
          setModalSucceed(true);
        }
        if (!regis) {
          setModalError(true);
        }
      }
    } else {
      setModalCheck(true);
    }
  }
  const size = useWindowSize();

  const [open, setOpen] = useState([]);
  const [body, setbody] = useState({
    house: "",
    Idcard: "",
    subDistrict: "",
    img: "",
  });

  const [address, setaddress] = useState(null);

  async function getAddress(text) {
    let matchItems = AddressThailand.filter((address) => {
      const regex = new RegExp(`^${text}`, "gi");

      return address.zipcode.toString().includes(text);
    });

    if (matchItems.length > 50) {
      matchItems = [];
    }

    if (matchItems.length > 0) {
      setaddress(matchItems[0]);

      setOpen(
        matchItems.map((val) => ({ label: val.label, value: val.label }))
      );
    }
  }

  async function onInputChange(text) {
    const data = await getAddress(text);
  }

  useEffect(() => {
    getAddress();
  }, []);

  return (
    <View style={styles.container}>
      <ScrollView stickyHeaderIndices={[0]}>
        <Header registration navigation={navigation} login headertext />
        <Text
          style={{
            fontSize: size.width < 500 ? 35 : 50,
            fontFamily: "Prompt",
            color: "#646464",
            alignSelf: "center",
            marginTop: 50,
          }}
        >
          ใบสมัครเป็นผู้สอน
        </Text>
        <View
          style={{
            width: size.width < 500 ? "90%" : "75%",
            alignSelf: "center",
          }}
        >
          <Text
            style={[
              size.width < 500
                ? { fontSize: 25, fontFamily: "Prompt", color: "#646464" }
                : width < 1600
                ? stylesResponsive.textTitle
                : styles.textTitle,
            ]}
          >
            ข้อมูลส่วนตัว
          </Text>
          <View
            style={[
              styles.viewDetail,
              {
                flexDirection: size.width < 500 ? "column" : "row",
              },
            ]}
          >
            <View
              style={[
                styles.viewInput,
                { width: size.width < 500 ? "100%" : "50%" },
              ]}
            >
              <Text
                style={[
                  width < 1600
                    ? stylesResponsive.textDetail
                    : styles.textDetail,
                ]}
              >
                ชื่อ
              </Text>
              <TextInput
                editable={detail != null ? false : true}
                autoComplete="off"
                defaultValue={state.firstName}
                onChangeText={(text) => {
                  setState({ ...state, firstName: text });
                }}
                style={styles.textInput}
              />
            </View>
            <View
              style={[
                styles.viewInput,
                {
                  width: size.width < 500 ? "100%" : "50%",
                  marginTop: size.width < 500 ? 25 : 0,
                },
              ]}
            >
              <Text
                style={[
                  width < 1600
                    ? stylesResponsive.textDetail
                    : styles.textDetail,
                ]}
              >
                นามสกุล
              </Text>
              <TextInput
                editable={detail != null ? false : true}
                autoComplete="off"
                defaultValue={state.lastName}
                onChangeText={(text) => {
                  setState({ ...state, lastName: text });
                }}
                style={styles.textInput}
              />
            </View>
          </View>
          <View
            style={[
              styles.viewDetail,
              {
                flexDirection: size.width < 500 ? "column" : "row",
              },
            ]}
          >
            <View
              style={[
                styles.viewInput,
                { width: size.width < 500 ? "100%" : "50%" },
              ]}
            >
              <Text
                style={[
                  width < 1600
                    ? stylesResponsive.textDetail
                    : styles.textDetail,
                ]}
              >
                เบอร์โทรศัพท์
              </Text>
              <View
                style={[
                  styles.textInput,
                  {
                    maxWidth:
                      size.width < 1280 ? 200 : size.width < 1600 ? 300 : 465,
                    paddingHorizontal: 0,
                  },
                ]}
              >
                <NumberFormat
                  disabled={detail != null ? true : false}
                  value={state.telNo}
                  onValueChange={(value) => {
                    setState({ ...state, telNo: value.value });
                  }}
                  format="### ### ####"
                  style={{
                    borderWidth: 0,
                    height: 35,
                    maxWidth: 469,
                    color: "#646464",
                    paddingLeft: 15,
                    fontSize: 20,
                    fontFamily: "Prompt",
                  }}
                />
              </View>
              {/* <TextInput
                autoComplete="off"
                defaultValue={state.telNo}
                onChangeText={(text) => {
                  setState({ ...state, telNo: text });
                }}
                style={[styles.textInput, { maxWidth: 469 }]}
              /> */}
            </View>
            <View
              style={[
                styles.viewInput,
                {
                  width: size.width < 500 ? "100%" : "50%",
                  marginTop: size.width < 500 ? 25 : 0,
                },
              ]}
            >
              <Text
                style={[
                  width < 1600
                    ? stylesResponsive.textDetail
                    : styles.textDetail,
                ]}
              >
                {"Email"}
              </Text>
              <TextInput
                editable={detail != null ? false : true}
                autoComplete="off"
                defaultValue={state.email}
                onChangeText={(text) => {
                  setState({ ...state, email: text });
                }}
                style={styles.textInput}
              />
            </View>
          </View>
          <View
            style={[
              styles.viewInput,
              {
                width: "100%",
                flexDirection: size.width < 1280 ? "column" : "row",
                alignItems: size.width < 1280 ? "flex-start" : "center",
              },
            ]}
          >
            <Text
              style={[
                [
                  width < 1600
                    ? stylesResponsive.textDetail
                    : styles.textDetail,
                ],
                { marginTop: 25 },
              ]}
            >
              เลขบัตรประชาชน
            </Text>
            <View
              style={[
                styles.textInput,
                {
                  maxWidth: size.width < 1600 ? 300 : 420,
                  paddingHorizontal: 0,
                  marginTop: 20,
                },
              ]}
            >
              <NumberFormat
                disabled={detail != null ? true : false}
                value={state.address.Idcard}
                onValueChange={(value) => {
                  setbody((val) => ({
                    ...val,
                    Idcard: value.value,
                  }));
                }}
                format="#-####-#####-##-#"
                style={{
                  borderWidth: 0,
                  height: 35,

                  color: "#646464",
                  paddingLeft: 15,
                  fontSize: 20,
                  fontFamily: "Prompt",
                }}
              />
            </View>
            {detail != null ? (
              <Image
                resizeMode="stretch"
                style={{
                  width: "30%",
                  height: 200,
                  marginTop: 10,
                }}
                source={{
                  uri:
                    "https://api.courseoncloud.com/api/image/getimage/" +
                    detail?.user_accounts?.address?.img.replace(".png", ""),
                }}
              />
            ) : (
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  marginTop: 25,
                }}
              >
                <TouchableOpacity
                  disabled={detail != null ? true : false}
                  onPress={async () => {
                    let result = await DocumentPicker.getDocumentAsync({
                      type: "application/pdf,image/jpeg",
                    });
                    // setImage(result.uri)
                    const response = await apiservice({
                      path: "/image/createpdf",
                      method: "post",
                      body: {
                        name: result.name.replace(".png", ""),
                        base64: result.uri,
                      },
                    });
                    setTimeout(() => {
                      setimg2(response.data.imageRefId);
                      setbody((val) => ({
                        ...val,
                        img: response.data.imageRefId,
                      }));
                    }, 500);
                  }}
                  style={{
                    width: 150,
                    height: 40,
                    backgroundColor: "#0D88F0",
                    borderRadius: 5,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 20,
                      fontFamily: "Prompt",
                      color: "#FFFFFF",
                    }}
                  >
                    แนบหลักฐาน
                  </Text>
                </TouchableOpacity>
                <Text
                  style={{
                    fontSize: 16,
                    fontFamily: "Prompt",
                    color: "#646464",
                    opacity: 0.5,
                    marginLeft: 10,
                  }}
                >
                  {body.img == "" ? ".jpg , . jpeg , .pdf" : body.img}
                </Text>
              </View>
            )}
          </View>
          {/* <TextInput
            editable={detail != null ? false : true}
            maxLength={16}
            value={state.address.Idcard}
            onChangeText={(text) => {
              if (text.length == 13) {
                setbody((val) => ({
                  ...val,
                  Idcard:
                    text[0] +
                    "-" +
                    text[1] +
                    text[2] +
                    text[3] +
                    "-" +
                    text[4] +
                    text[5] +
                    text[6] +
                    text[7] +
                    text[8] +
                    "-" +
                    text[9] +
                    text[10] +
                    "-" +
                    text[11] +
                    text[12],
                }));
              } else {
                setbody((val) => ({
                  ...val,
                  Idcard: text,
                }));
              }
            }}
            // onChangeText={onInputChange}
            placeholder="Ex x-xxxx-xxxxx-xx-x"
            style={{
              maxWidth: 1412,
              width: "100%",
              minHeight: 50,
              borderWidth: 1,
              borderColor: "#E0E0E0",
              padding: 15,
              fontSize: 20,
              fontFamily: "Prompt",
              color: "#646464",
              marginTop: 10,
            }}
          /> */}
          <Text
            style={[
              [width < 1600 ? stylesResponsive.textDetail : styles.textDetail],
              { marginTop: 25 },
            ]}
          >
            ที่อยู่ที่ติดต่อได้
          </Text>

          {/* <TextInput
            autoComplete="off"
            defaultValue={state.address}
            onChangeText={(text) => {
              setState({ ...state, address: text });
            }}
            multiline
            style={{
              maxWidth: 1412,
              width: "100%",
              minHeight: 160,
              borderWidth: 1,
              borderColor: "#E0E0E0",
              padding: 15,
              fontSize: 20,
              fontFamily: "Prompt",
              color: "#646464",
            }}
          /> */}

          <TextInput
            value={state.address.house}
            editable={detail != null ? false : true}
            // defaultValue={user.full_address.ShippingAddress}
            onChangeText={(text) =>
              setbody({
                ...body,
                house: text,
              })
            }
            // onChangeText={onInputChange}
            placeholder="บ้านเลขที่"
            style={{
              maxWidth: 1412,
              width: "100%",
              minHeight: 50,
              borderWidth: 1,
              borderColor: "#E0E0E0",
              padding: 15,
              fontSize: 20,
              fontFamily: "Prompt",
              color: "#646464",
              marginTop: 10,
            }}
          />
          <TextInput
            value={state.address.postcode}
            editable={detail != null ? false : true}
            onChangeText={onInputChange}
            placeholder="รหัสไปรษณีย์"
            style={{
              maxWidth: 1412,
              width: "100%",
              minHeight: 50,
              borderWidth: 1,
              borderColor: "#E0E0E0",
              padding: 15,
              fontSize: 20,
              fontFamily: "Prompt",
              color: "#646464",
              marginTop: 10,
            }}
          />

          <View
            style={{
              maxWidth: 1412,
              width: "100%",
              minHeight: 50,
              borderWidth: 1,
              borderColor: "#E0E0E0",
              padding: 15,
              marginTop: 10,
            }}
          >
            {detail == null ? (
              <Text
                style={{
                  fontFamily: "Prompt",
                  color: "#707070",
                  fontSize: 20,
                }}
              >
                {address != null ? address.province : "จังหวัด"}
              </Text>
            ) : (
              <Text
                style={{
                  fontFamily: "Prompt",
                  color: "#707070",
                  fontSize: 20,
                }}
              >
                {state.address?.province}
              </Text>
            )}
          </View>

          <View
            style={{
              maxWidth: 1412,
              width: "100%",
              minHeight: 50,
              borderWidth: 1,
              borderColor: "#E0E0E0",
              padding: 15,
              marginTop: 10,
            }}
          >
            {detail == null ? (
              <Text
                style={{
                  fontFamily: "Prompt",
                  color: "#707070",
                  fontSize: 20,
                }}
              >
                {address != null ? address.amphoe : "อำเภอ"}
              </Text>
            ) : (
              <Text
                style={{
                  fontFamily: "Prompt",
                  color: "#707070",
                  fontSize: 20,
                }}
              >
                {state.address?.subDistrict}
              </Text>
            )}
          </View>
          {detail != null ? (
            <View
              style={{
                maxWidth: 1412,
                width: "100%",
                minHeight: 50,
                borderWidth: 1,
                borderColor: "#E0E0E0",
                padding: 15,
                marginTop: 10,
              }}
            >
              <Text
                style={{ fontSize: 20, fontFamily: "Prompt", color: "#646464" }}
              >
                {detail.user_accounts?.address?.district}
              </Text>
            </View>
          ) : (
            <RNPickerSelect
              disabled={detail != null ? true : false}
              value={body.subDistrict}
              placeholder={placeholder3}
              textInputProps={{ style: { fontFamily: "Prompt" } }}
              onValueChange={(selectSubDistrict) => {
                setbody({
                  ...body,
                  subDistrict: selectSubDistrict,
                });
              }}
              style={{
                inputWeb: {
                  maxWidth: 1412,
                  width: "100%",
                  minHeight: 50,
                  borderWidth: 1,
                  borderColor: "#E0E0E0",
                  padding: 15,
                  fontSize: 20,
                  fontFamily: "Prompt",
                  color: "#646464",
                  marginTop: 10,
                  backgroundColor: "#FFF",
                },
              }}
              items={open}
            />
          )}

          <Text
            style={[
              [width < 1600 ? stylesResponsive.textTitle : styles.textTitle],
              { marginTop: 65 },
            ]}
          >
            ข้อมูลธนาคาร
          </Text>
          {detail != null ? (
            <Text
              style={[
                width < 1600 ? stylesResponsive.textDetail : styles.textDetail,
                ,
                { marginTop: 10 },
              ]}
            >
              {"ธนาคาร"}
              {detail.user_accounts?.balance_info == "KBANK"
                ? "กสิกรไทย"
                : detail.user_accounts?.balance_info == "SCB"
                ? "ไทยพาณิชย์"
                : detail.user_accounts?.balance_info == "KTB" && "กรุงไทย"}
            </Text>
          ) : (
            <View
              style={{
                flexDirection: size.width < 500 ? "column" : "row",
                marginTop: 25,
              }}
            >
              <Text
                style={[
                  width < 1600
                    ? stylesResponsive.textDetail
                    : styles.textDetail,
                ]}
              >
                เลือกธนาคาร
              </Text>
              {bankList.map((data, index) => (
                <TouchableOpacity
                  disabled={detail != null ? true : false}
                  key={data}
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    marginLeft: width < 1280 ? 10 : 40,
                  }}
                  onPress={bankChangeHandler.bind(this, index, data)}
                >
                  <MaterialCommunityIcons
                    name={
                      index === bankIndex
                        ? "checkbox-marked"
                        : "checkbox-blank-outline"
                    }
                    size={30}
                    color="#BBBBBB"
                  />
                  <Text
                    style={[
                      width < 1600
                        ? stylesResponsive.textDetail
                        : styles.textDetail,
                    ]}
                  >
                    {data}
                  </Text>
                </TouchableOpacity>
              ))}
            </View>
          )}

          <View style={styles.viewBank}>
            <Text
              style={[
                width < 1600 ? stylesResponsive.textDetail : styles.textDetail,
              ]}
            >
              เลขบัญชี
            </Text>
            <View
              style={[
                styles.textInputBank,
                {
                  paddingHorizontal: 0,
                  maxWidth:
                    size.width < 500 ? 220 : size.width < 1280 ? 500 : 1200,
                },
              ]}
            >
              <NumberFormat
                disabled={detail != null ? true : false}
                value={state.balance_no}
                onValueChange={(value) => {
                  setState({ ...state, balance_no: value.value });
                }}
                format="###-#-#####-#"
                style={{
                  borderWidth: 0,
                  height: 35,

                  color: "#646464",
                  paddingLeft: 15,
                  fontSize: 20,
                  fontFamily: "Prompt",
                }}
              />
            </View>
          </View>
          {/* <Text
            style={[
              [width < 1600 ? stylesResponsive.textDetail : styles.textDetail],
              { marginTop: 25 },
            ]}
          >
            หรือ
          </Text> */}
          {/* <View style={styles.viewBank}>
            <Text
              style={[
                width < 1600 ? stylesResponsive.textDetail : styles.textDetail,
              ]}
            >
              เลขพร้อมเพย์
            </Text>
            <View style={[styles.textInputBank, { paddingHorizontal: 0 }]}>
              <NumberFormat
                disabled={detail != null ? true : false}
                value={state.promptpay}
                onValueChange={(value) => {
                  setState({ ...state, promptpay: value.value });
                }}
                format="### #### ###"
                style={{
                  borderWidth: 0,
                  height: 35,

                  color: "#646464",
                  paddingLeft: 15,
                  fontSize: 20,
                  fontFamily: "Prompt",
                }}
              />
            </View>
          </View> */}
          {detail != null && (
            <Image
              resizeMode="stretch"
              style={{
                width: "30%",
                height: 200,
                marginVertical: 10,
              }}
              source={{
                uri:
                  "https://api.courseoncloud.com/api/image/getimage/" +
                  state.code_serial.replace(".png", ""),
              }}
            />
          )}
          {detail == null && (
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                marginTop: 25,
              }}
            >
              <TouchableOpacity
                disabled={detail != null ? true : false}
                onPress={async () => {
                  let result = await DocumentPicker.getDocumentAsync({
                    type: "application/pdf,image/jpeg",
                  });
                  // setImage(result.uri)
                  const response = await apiservice({
                    path: "/image/createpdf",
                    method: "post",
                    body: {
                      name: result.name.replace(".png", ""),
                      base64: result.uri,
                    },
                  });
                  setTimeout(() => {
                    setimg3(response.data.imageRefId);
                    setState((val) => ({
                      ...val,
                      code_serial: response.data.imageRefId,
                    }));
                  }, 500);
                }}
                style={{
                  width: 150,
                  height: 40,
                  backgroundColor: "#0D88F0",
                  borderRadius: 5,
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Text
                  style={{
                    fontSize: 20,
                    fontFamily: "Prompt",
                    color: "#FFFFFF",
                  }}
                >
                  แนบหลักฐาน
                </Text>
              </TouchableOpacity>
              <Text
                style={{
                  fontSize: 16,
                  fontFamily: "Prompt",
                  color: "#646464",
                  opacity: 0.5,
                  marginLeft: 10,
                }}
              >
                {state.code_serial == ""
                  ? ".jpg , . jpeg , .pdf"
                  : state.code_serial}
              </Text>
            </View>
          )}
          <Text
            style={[
              [width < 1600 ? stylesResponsive.textTitle : styles.textTitle],
              { marginTop: 65 },
            ]}
          >
            ข้อมูลด้านการศึกษา
          </Text>
          <View>
            <FlatList
              data={state.education}
              numColumns={1}
              renderItem={({ index, item }) => {
                return (
                  <View>
                    {index > 0 && detail == null && (
                      <TouchableOpacity
                        onPress={() => {
                          setState((val) => ({
                            ...val,
                            education: val.education.filter((items, indexs) => {
                              return indexs != index;
                            }),
                          }));
                        }}
                        style={{ position: "absolute", right: 0 }}
                      >
                        <Ionicons
                          name="ios-close-circle-outline"
                          size={35}
                          color="#CCC"
                        />
                      </TouchableOpacity>
                    )}
                    <View
                      style={{
                        flexDirection: "row",
                        alignItems: "center",
                        marginTop: 25,
                      }}
                    >
                      <Text
                        style={[
                          width < 1600
                            ? stylesResponsive.textDetail
                            : styles.textDetail,
                        ]}
                      >
                        ช่วงชั้น
                      </Text>
                      {detail != null ? (
                        <View
                          style={{
                            width: "100%",
                            maxWidth: 350,
                            height: 30,
                            borderWidth: 1,
                            borderColor: "#E0E0E0",
                            marginHorizontal: 20,
                            justifyContent: "center",
                            paddingHorizontal: 15,
                            marginTop: 10,
                            backgroundColor: "#FFF",
                          }}
                        >
                          <Text
                            style={{
                              color: "#646464",
                              fontFamily: "Prompt",
                              fontSize: 20,
                            }}
                          >
                            {item.everage == 0
                              ? "ปริญญาตรี"
                              : item.everage == 1
                              ? "ปริญญาโท"
                              : item.everage == 0 && "ปริญญาเอก"}
                          </Text>
                        </View>
                      ) : (
                        <RNPickerSelect
                          disabled={detail != null ? true : false}
                          placeholder={placeholder}
                          onValueChange={(val) => {
                            setState((e) => ({
                              ...e,
                              education: e.education.map((el, i) =>
                                i == index ? { ...el, everage: val } : { ...el }
                              ),
                            }));
                          }}
                          items={status}
                          style={{
                            inputWeb: {
                              width: "100%",
                              maxWidth: 350,
                              height: 30,
                              borderWidth: 1,
                              borderColor: "#E0E0E0",
                              marginHorizontal: 20,
                              color: "#646464",
                              fontFamily: "Prompt",
                              fontSize: 20,
                              paddingHorizontal: 15,
                              marginTop: 10,
                              backgroundColor: "#FFF",
                            },
                          }}
                        />
                      )}
                    </View>

                    <View
                      style={{
                        flexDirection: width < 500 ? "column" : "row",
                        marginTop: 25,
                        alignItems: width < 500 ? "flex-start" : "center",
                      }}
                    >
                      <Text
                        style={[
                          width < 1600
                            ? stylesResponsive.textDetail
                            : styles.textDetail,
                        ]}
                      >
                        มหาวิทยาลัย
                      </Text>
                      <TextInput
                        editable={detail != null ? false : true}
                        autoComplete="off"
                        value={item.University}
                        onChangeText={(val) => {
                          setState((e) => ({
                            ...e,
                            education: e.education.map((el, i) =>
                              i == index
                                ? { ...el, University: val }
                                : { ...el }
                            ),
                          }));
                        }}
                        style={[
                          styles.textInputEducation,
                          { maxWidth: width < 500 ? 300 : 400 },
                        ]}
                      />
                      <Text
                        style={[
                          width < 1600
                            ? stylesResponsive.textDetail
                            : styles.textDetail,
                        ]}
                      >
                        คณะ
                      </Text>
                      <TextInput
                        editable={detail != null ? false : true}
                        autoComplete="off"
                        value={item.Faculty}
                        onChangeText={(val) => {
                          setState((e) => ({
                            ...e,
                            education: e.education.map((el, i) =>
                              i == index ? { ...el, Faculty: val } : { ...el }
                            ),
                          }));
                        }}
                        style={[
                          styles.textInputEducation,
                          { maxWidth: width < 500 ? 300 : 400 },
                        ]}
                      />
                      <Text
                        style={[
                          width < 1600
                            ? stylesResponsive.textDetail
                            : styles.textDetail,
                        ]}
                      >
                        สาขา
                      </Text>
                      <TextInput
                        editable={detail != null ? false : true}
                        autoComplete="off"
                        value={item.Majored}
                        onChangeText={(val) => {
                          setState((e) => ({
                            ...e,
                            education: e.education.map((el, i) =>
                              i == index ? { ...el, Majored: val } : { ...el }
                            ),
                          }));
                        }}
                        style={[
                          styles.textInputEducation,
                          { maxWidth: width < 500 ? 300 : 400 },
                        ]}
                      />
                    </View>

                    {detail != null ? (
                      <Image
                        resizeMode="stretch"
                        style={{
                          width: "30%",
                          height: 200,
                          marginVertical: 10,
                        }}
                        source={{
                          uri:
                            "https://api.courseoncloud.com/api/image/getimage/" +
                            item.file.replace(".png", ""),
                        }}
                      />
                    ) : (
                      <View
                        style={{
                          flexDirection: "row",
                          alignItems: "center",
                          marginTop: 25,
                        }}
                      >
                        <TouchableOpacity
                          disabled={detail != null ? true : false}
                          onPress={async () => {
                            let result = await DocumentPicker.getDocumentAsync({
                              type: "application/pdf,image/jpeg",
                            });
                            // setImage(result.uri)
                            const response = await apiservice({
                              path: "/image/createpdf",
                              method: "post",
                              body: {
                                name: result.name.replace(".png", ""),
                                base64: result.uri,
                              },
                            });
                            setTimeout(() => {
                              setimg(response.data.imageRefId);
                              setState((e) => ({
                                ...e,
                                education: e.education.map((el, i) =>
                                  i == index
                                    ? { ...el, file: response.data.imageRefId }
                                    : { ...el }
                                ),
                              }));
                            }, 500);
                          }}
                          style={{
                            width: 150,
                            height: 50,
                            backgroundColor: "#0D88F0",
                            borderRadius: 5,
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Text
                            style={{
                              fontSize: 20,
                              fontFamily: "Prompt",
                              color: "#FFFFFF",
                            }}
                          >
                            แนบหลักฐาน
                          </Text>
                        </TouchableOpacity>
                        <Text
                          style={{
                            fontSize: 16,
                            fontFamily: "Prompt",
                            color: "#646464",
                            opacity: 0.5,
                            marginLeft: 10,
                          }}
                        >
                          {state?.education?.map((item) => {
                            return item?.file;
                          }) == ""
                            ? ".jpg , . jpeg , .pdf"
                            : item?.file}
                        </Text>
                      </View>
                    )}
                  </View>
                );
              }}
            />
          </View>
          {detail == null && (
            <TouchableOpacity
              disabled={detail != null ? true : false}
              onPress={addEducation}
              style={{
                width: 180,
                height: 50,
                borderWidth: 1,
                borderColor: "#D1DDE5",
                alignItems: "center",
                justifyContent: "center",
                marginTop: 45,
              }}
            >
              <Text
                style={{ fontSize: 20, fontFamily: "Prompt", color: "#0D88F0" }}
              >
                เพิ่มข้อมูลการศึกษา
              </Text>
            </TouchableOpacity>
          )}
          {detail == null && (
            <View
              style={{
                flexDirection: size.width < 500 ? "column" : "row",
                alignItems: size.width < 500 ? "flex-start" : "center",
                marginTop: size.width < 500 ? 20 : 52,
              }}
            >
              <View style={{ flexDirection: "row" }}>
                <TouchableOpacity
                  disabled={detail != null ? true : false}
                  onPress={() => {
                    setCheck((val) => !val);
                  }}
                >
                  <MaterialCommunityIcons
                    name={check ? "checkbox-marked" : "checkbox-blank-outline"}
                    size={30}
                    color="#BBBBBB"
                  />
                </TouchableOpacity>
                <Text
                  style={{
                    fontSize: 20,
                    fontFamily: "Prompt",
                    color: "#646464",
                    marginLeft: 20,
                  }}
                >
                  เป็นองค์กรหรือหน่วยงานการศึกษา
                </Text>
              </View>
              <TouchableOpacity
                disabled={detail != null ? true : false}
                // disabled={check ? false : true}
                onPress={pickup1}
                style={{
                  width: 150,
                  height: 40,
                  backgroundColor: check ? "#0D88F0" : "#CCCCCC",
                  borderRadius: 5,
                  alignItems: "center",
                  justifyContent: "center",
                  marginLeft: size.width < 500 ? 0 : 20,
                  marginTop: size.width < 500 ? 20 : 0,
                }}
              >
                <Text
                  style={{
                    fontSize: 20,
                    fontFamily: "Prompt",
                    color: "#FFFFFF",
                  }}
                >
                  แนบหลักฐาน
                </Text>
              </TouchableOpacity>
              <Text
                style={{
                  fontSize: 16,
                  fontFamily: "Prompt",
                  color: "#646464",
                  opacity: 0.5,
                  marginLeft: size.width < 500 ? 0 : 10,
                }}
              >
                {img1 == "" ? "    .jpg , . jpeg , .pdf" : img1}
              </Text>
            </View>
          )}
          {detail == null && (
            <View style={{ flexDirection: "row", marginTop: 25 }}>
              <TouchableOpacity
                disabled={detail != null ? true : false}
                onPress={() => {
                  setCheck1((val) => !val);
                }}
              >
                <MaterialCommunityIcons
                  name={check1 ? "checkbox-marked" : "checkbox-blank-outline"}
                  size={30}
                  color="#BBBBBB"
                />
              </TouchableOpacity>
              <Text
                style={{
                  fontSize: 20,
                  fontFamily: "Prompt",
                  color: "#646464",
                  marginLeft: 20,
                }}
              >
                ฉันอ่านและยอมรับ
                <TouchableOpacity
                  onPress={() => {
                    navigation.navigate("InstructorConditions");
                  }}
                >
                  <Text
                    style={{
                      color: "#E82626",
                      textDecoration: "underline #E82626",
                    }}
                  >
                    เงื่อนไขและข้อตกลง
                  </Text>
                </TouchableOpacity>
                ในการสมัครเป็นผู้สอนกับCoC
              </Text>
            </View>
          )}
          {detail == null && (
            <View
              style={{
                flexDirection: "row",
                marginLeft: size.width < 500 ? 10 : 50,
                marginTop: 31,
              }}
            >
              <Image
                style={{ width: 20, height: 20 }}
                source={require("../img/ic_info_outline_24px.png")}
              />
              <Text
                style={{
                  fontSize: 14,
                  fontFamily: "Prompt",
                  color: "#E82626",
                  marginLeft: 5,
                }}
              >
                เมื่อทำการสมัครแล้วกรุณารอ 1 - 2
                วันเพื่อให้ทางแอดมินตรวจสอบข้อมูลของท่าน และรอรับ username และ
                password เริ่มต้นได้ทาง E-mail ที่ท่านระบุ หากภายใน 1 - 2
                วันไม่มีการตอบรับ กรุณาติดต่อเรา
              </Text>
            </View>
          )}
          {detail == null && (
            <View
              style={{
                flexDirection: "row",
                marginTop: 107,
                alignSelf: "center",
                marginBottom: 63,
                width: "75%",
                justifyContent: "center",
              }}
            >
              <TouchableOpacity
                disabled={detail != null ? true : false}
                //disabled={check1 ? false : true}
                onPress={register}
                style={{
                  maxWidth: size.width < 500 ? 150 : 200,
                  width: "100%",
                  height: 60,
                  backgroundColor: check1 ? "#0D88F0" : "#CCCCCC",
                  borderRadius: 5,
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Text
                  style={{
                    fontSize: 30,
                    fontFamily: "Prompt",
                    color: "#FFFFFF",
                  }}
                >
                  สมัคร
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                disabled={detail != null ? true : false}
                onPress={() => navigation.goBack()}
                style={{
                  maxWidth: size.width < 500 ? 150 : 200,
                  width: "100%",
                  height: 60,
                  borderWidth: 1,
                  borderColor: "#0D88F0",
                  borderRadius: 5,
                  alignItems: "center",
                  justifyContent: "center",
                  marginLeft: 20,
                }}
              >
                <Text
                  style={{
                    fontSize: 30,
                    fontFamily: "Prompt",
                    color: "#0D88F0",
                  }}
                >
                  ยกเลิก
                </Text>
              </TouchableOpacity>
            </View>
          )}
          <Modal
            transparent={true}
            visible={modalSucceed}
            onRequestClose={() => {
              Alert.alert("Modal has been closed.");
              setModalSucceed(!modalSucceed);
            }}
          >
            <View style={styles.bgModal}>
              <View
                style={{
                  width: size.width < 1280 ? "80%" : "45%",
                  height: 270,
                  borderWidth: 1,
                  borderColor: "#0D88F0",
                  backgroundColor: "#FFFFFF",
                  alignSelf: "center",
                  alignItems: "center",
                }}
              >
                <TouchableOpacity
                  onPress={() => {
                    window.location.replace(navigation.navigate("Home"));
                    setModalSucceed(!modalSucceed);
                  }}
                  style={{
                    position: "absolute",
                    alignSelf: "flex-end",
                    margin: 13,
                  }}
                >
                  <MaterialIcons name="clear" size={14} color="#0D88F0" />
                </TouchableOpacity>
                <View style={{ paddingVertical: 45, alignItems: "center" }}>
                  <Text
                    style={{
                      fontSize: size.width < 1280 ? 30 : 35,
                      fontFamily: "Prompt",
                      color: "#74C745",
                    }}
                  >
                    เสร็จสิ้น!
                  </Text>
                  <Text
                    style={[
                      styles.text20,
                      { marginTop: 10, fontSize: size.width < 1280 ? 16 : 20 },
                    ]}
                  >
                    ระบบได้ทำการบันทึกข้อมูลของท่าน
                  </Text>
                  <Text
                    style={[
                      styles.text25,
                      {
                        marginTop: 10,
                        fontSize: size.width < 1280 ? 20 : 25,
                        textAlign: size.width < 500 && "center",
                        marginHorizontal: size.width < 500 && 10,
                      },
                    ]}
                  >
                    โปรด
                    <Text style={{ textDecorationLine: "underline" }}>
                      {size.width < 500 ? "รอการอนุมัติ\n" : "รอการอนุมัติ"}
                    </Text>
                    จากทางแอดมินภายใน 1 - 2 วัน
                  </Text>
                  <View
                    style={{
                      flexDirection: "row",
                      marginTop: 10,
                    }}
                  >
                    <Text
                      style={[
                        styles.text18,
                        { fontSize: size.width < 1280 ? 14 : 18 },
                      ]}
                    >
                      หาก 1 - 2 วันยังไม่มีการตอบรับกรุณา
                    </Text>
                    <TouchableOpacity
                      style={{ alignSelf: size.width < 500 && "center" }}
                    >
                      <Text
                        style={[
                          styles.text18,
                          {
                            textDecorationLine: "underline",
                            color: "#E82626",
                            fontSize: size.width < 1280 ? 14 : 18,
                          },
                        ]}
                      >
                        ติดต่อเรา
                      </Text>
                    </TouchableOpacity>
                  </View>
                </View>
              </View>
            </View>
          </Modal>
          <Modal
            transparent={true}
            visible={modalCheck}
            onRequestClose={() => {
              Alert.alert("Modal has been closed.");
              setModalCheck(!modalCheck);
            }}
          >
            <View style={styles.bgModal}>
              <View
                style={{
                  width: size.width < 1280 ? "80%" : "45%",
                  height: 230,
                  borderWidth: 1,
                  borderColor: "#0D88F0",
                  backgroundColor: "#FFFFFF",
                  alignSelf: "center",
                  alignItems: "center",
                }}
              >
                <TouchableOpacity
                  onPress={() => {
                    setModalCheck(!modalCheck);
                  }}
                  style={{
                    position: "absolute",
                    alignSelf: "flex-end",
                    margin: 13,
                  }}
                >
                  <MaterialIcons name="clear" size={14} color="#0D88F0" />
                </TouchableOpacity>
                <View style={{ paddingVertical: 55, alignItems: "center" }}>
                  <Text
                    style={{
                      fontSize: size.width < 1280 ? 30 : 35,
                      fontFamily: "Prompt",
                      color: "red",
                    }}
                  >
                    เกิดข้อผิดพลาด
                  </Text>
                  <Text
                    style={{
                      fontSize: size.width < 1280 ? 30 : 35,
                      fontFamily: "Prompt",
                      color: "#646464",
                    }}
                  >
                    กรุณากรอกข้อมูลให้ครบถ้วน
                  </Text>
                </View>
              </View>
            </View>
          </Modal>
          <Modal
            transparent={true}
            visible={modalError}
            onRequestClose={() => {
              Alert.alert("Modal has been closed.");
              setModalError(!modalError);
            }}
          >
            <View style={styles.bgModal}>
              <View
                style={{
                  width: size.width < 1280 ? "80%" : "45%",
                  height: 230,
                  borderWidth: 1,
                  borderColor: "#0D88F0",
                  backgroundColor: "#FFFFFF",
                  alignSelf: "center",
                  alignItems: "center",
                }}
              >
                <TouchableOpacity
                  onPress={() => {
                    setModalError(!modalError);
                  }}
                  style={{
                    position: "absolute",
                    alignSelf: "flex-end",
                    margin: 13,
                  }}
                >
                  <MaterialIcons name="clear" size={14} color="#0D88F0" />
                </TouchableOpacity>
                <View style={{ paddingVertical: 55, alignItems: "center" }}>
                  <Text
                    style={{
                      fontSize: size.width < 1280 ? 30 : 35,
                      fontFamily: "Prompt",
                      color: "red",
                    }}
                  >
                    เกิดข้อผิดพลาด
                  </Text>
                  <Text
                    style={{
                      fontSize: size.width < 1280 ? 30 : 35,
                      fontFamily: "Prompt",
                      color: "#646464",
                    }}
                  >
                    มีบัญชีผู้ใช้หรืออีเมลอยู่ในระบบแล้ว
                  </Text>
                </View>
              </View>
            </View>
          </Modal>
          <Modal
            transparent={true}
            visible={modalErrortel}
            onRequestClose={() => {
              Alert.alert("Modal has been closed.");
              setModalErrortel(!modalErrortel);
            }}
          >
            <View style={styles.bgModal}>
              <View
                style={{
                  width: size.width < 1280 ? "80%" : "45%",
                  height: 230,
                  borderWidth: 1,
                  borderColor: "#0D88F0",
                  backgroundColor: "#FFFFFF",
                  alignSelf: "center",
                  alignItems: "center",
                }}
              >
                <TouchableOpacity
                  onPress={() => {
                    setModalErrortel(!modalErrortel);
                  }}
                  style={{
                    position: "absolute",
                    alignSelf: "flex-end",
                    margin: 13,
                  }}
                >
                  <MaterialIcons name="clear" size={14} color="#0D88F0" />
                </TouchableOpacity>
                <View style={{ paddingVertical: 55, alignItems: "center" }}>
                  <Text
                    style={{
                      fontSize: size.width < 1280 ? 30 : 35,
                      fontFamily: "Prompt",
                      color: "red",
                    }}
                  >
                    เกิดข้อผิดพลาด
                  </Text>
                  <Text
                    style={{
                      fontSize: size.width < 1280 ? 30 : 35,
                      fontFamily: "Prompt",
                      color: "#646464",
                    }}
                  >
                    กรุณากรอกเบอร์โทรศัพท์ให้ถูกต้อง
                  </Text>
                </View>
              </View>
            </View>
          </Modal>
          <Modal
            transparent={true}
            visible={modalErrorbalance}
            onRequestClose={() => {
              Alert.alert("Modal has been closed.");
              setModalErrorbalance(!modalErrorbalance);
            }}
          >
            <View style={styles.bgModal}>
              <View
                style={{
                  width: size.width < 1280 ? "80%" : "45%",
                  height: 230,
                  borderWidth: 1,
                  borderColor: "#0D88F0",
                  backgroundColor: "#FFFFFF",
                  alignSelf: "center",
                  alignItems: "center",
                }}
              >
                <TouchableOpacity
                  onPress={() => {
                    setModalErrorbalance(!modalErrorbalance);
                  }}
                  style={{
                    position: "absolute",
                    alignSelf: "flex-end",
                    margin: 13,
                  }}
                >
                  <MaterialIcons name="clear" size={14} color="#0D88F0" />
                </TouchableOpacity>
                <View style={{ paddingVertical: 55, alignItems: "center" }}>
                  <Text
                    style={{
                      fontSize: size.width < 1280 ? 30 : 35,
                      fontFamily: "Prompt",
                      color: "red",
                    }}
                  >
                    เกิดข้อผิดพลาด
                  </Text>
                  <Text
                    style={{
                      fontSize: size.width < 1280 ? 30 : 35,
                      fontFamily: "Prompt",
                      color: "#646464",
                    }}
                  >
                    กรุณากรอกเลขบัญชีให้ถูกต้อง
                  </Text>
                </View>
              </View>
            </View>
          </Modal>
          <Modal
            transparent={true}
            visible={modalErroridCard}
            onRequestClose={() => {
              Alert.alert("Modal has been closed.");
              setModalErroridCard(!modalErroridCard);
            }}
          >
            <View style={styles.bgModal}>
              <View
                style={{
                  width: size.width < 1280 ? "80%" : "45%",
                  height: 230,
                  borderWidth: 1,
                  borderColor: "#0D88F0",
                  backgroundColor: "#FFFFFF",
                  alignSelf: "center",
                  alignItems: "center",
                }}
              >
                <TouchableOpacity
                  onPress={() => {
                    setModalErroridCard(!modalErroridCard);
                  }}
                  style={{
                    position: "absolute",
                    alignSelf: "flex-end",
                    margin: 13,
                  }}
                >
                  <MaterialIcons name="clear" size={14} color="#0D88F0" />
                </TouchableOpacity>
                <View style={{ paddingVertical: 55, alignItems: "center" }}>
                  <Text
                    style={{
                      fontSize: size.width < 1280 ? 30 : 35,
                      fontFamily: "Prompt",
                      color: "red",
                    }}
                  >
                    เกิดข้อผิดพลาด
                  </Text>
                  <Text
                    style={{
                      fontSize: size.width < 1280 ? 30 : 35,
                      fontFamily: "Prompt",
                      color: "#646464",
                    }}
                  >
                    กรุณากรอกเลขบัตรประชาชนให้ถูกต้อง
                  </Text>
                </View>
              </View>
            </View>
          </Modal>
          <Modal
            transparent={true}
            visible={modalWarning}
            onRequestClose={() => {
              Alert.alert("Modal has been closed.");
              setModalWarning(!modalWarning);
            }}
          >
            <View style={styles.bgModal}>
              <View
                style={{
                  width: "60%",
                  height: "90%",
                  borderWidth: 1,
                  borderColor: "#0D88F0",
                  backgroundColor: "#FFFFFF",
                  alignSelf: "center",
                }}
              >
                <ScrollView>
                  <TouchableOpacity
                    onPress={() => {
                      setModalWarning(false);
                    }}
                    style={{ position: "absolute", right: 0, zIndex: 99 }}
                  >
                    <Ionicons name="close" size={30} color="#0D88F0" />
                  </TouchableOpacity>
                  <View style={{ paddingVertical: 40, paddingHorizontal: 20 }}>
                    <Text style={[styles.text18, { fontWeight: "bold" }]}>
                      {"\t"}
                      ข้อกำหนดและเงื่อนไขสำหรับการสมัครเป็นผู้สอน ผ่านเว็บไซต์
                      www.courseoncloud.com ของ บริษัท เอเชีย คอนเน็คท์
                      คอร์ปอเรชั่น จำกัด
                    </Text>
                    <Text style={styles.text18}>
                      {"\t"}
                      ผู้สมัครสมาชิกตกลงยินยอมตาม
                      ข้อกำหนดและเงื่อนไขสำหรับการสมัครเป็นผู้สอนของ บริษัท
                      เอเชีย คอนเน็คท์ คอร์ปอเรชั่น จำกัด ดังต่อไปนี้{"\n\t"}1.
                      ต้องเป็นผู้สมัคร ผ่าน www.courseoncloud.com
                      เท่านั้นโดยแนบเอกสารหลักฐานประกอบการสมัครให้ครบถ้วน
                      เมื่อบริษัท เอเชีย คอนเน็คท์ คอร์ปอเรชั่น จำกัด
                      ตรวจสอบหลักฐานต่างๆ
                      เห็นว่าถูกต้องครบถ้วนและผ่านเกณฑ์การคัดเลือกตามระเบียบของบริษัท
                      เอเชีย คอนเน็คท์ คอร์ปอเรชั่น จำกัดแล้ว บริษัท เอเชีย
                      คอนเน็คท์ คอร์ปอเรชั่น จำกัด จะทำการส่งข้อความยืนยันไปยัง
                      e-mail ที่ผู้สมัครได้ระบุไว้ ผู้สมัครจึงจะสามารถใช้
                      Username และ Password เพื่อเข้าใช้ระบบ (Log in)
                      มิฉะนั้นบัญชีของผู้สมัครจะถูกยกเลิกโดยมิต้องแจ้งให้ทราบล่วงหน้า
                      {"\n\t"}2. ผู้สมัครต้องระวัง และ รักษา Username และ
                      Password เพื่อเข้าใช้ระบบ (Log in)
                      โดยไม่ให้ผู้ใดใช้แอบอ้างหรือกระทำการใดๆ
                      อันเป็นการละเมิดสิทธิส่วนบุคคล และ
                      ก่อให้เกิดความเสียหายต่อบริษัท เอเชีย คอนเน็คท์
                      คอร์ปอเรชั่น จำกัด โดยใช้ข้อมูลของผู้สมัครมาแอบอ้าง
                      ถือเป็นความผิดของผู้สมัคร และ
                      ต้องรับโทษตามที่กฎหมายกำหนดไว้{"\n\t"}3. ข้อมูลภาพ เสียง
                      วิดีโอ และเนื้อหาต่างๆที่ผู้สอนนำมาสอน
                      จะต้องไม่ละเมิดลิขสิทธิ์ผู้ใด หากพบมีการละเมิดลิขสิทธิ์
                      มีข้อร้องเรียน หรือฟ้องร้อง
                      ผู้สอนต้องรับผิดชอบแต่เพียงผู้เดียว
                      โดยไม่เกี่ยวข้องกับบริษัทฯแต่อย่างใด และบริษัทฯ
                      สามารถยกเลิกการเป็นผู้สอนได้โดยไม่ต้องแจ้งล่วงหน้า{"\n\t"}
                      4.เมื่อผู้สมัครได้รับการแต่งตั้งเป็นผู้สอนของบริษัทเอเชีย
                      คอนเน็คท์ คอร์ปอเรชั่น จำกัด แล้ว
                      จะได้รับส่วนแบ่งจากรายได้ที่บริษัทฯ
                      เรียกเก็บค่าให้คำปรึกษาจากลูกค้าแต่ละรายที่ตนให้คำปรึกษา
                      ในอัตราที่บริษัทกำหนด{"\n\t"}ทั้งนี้ บริษัท เอเชีย
                      คอนเน็คท์ คอร์ปอเรชั่น จำกัด จะตัดยอดทุกสิ้นเดือน
                      และโอนเงินให้กับผู้สอนภายในวันที่ 10 ของเดือนถัดไป
                      โดยจำนวนเงินที่ได้นั้นเป็นจำนวนหลังหักค่าโอน
                      หักภาษีมูลค่าเพิ่มหักภาษี ณ ที่จ่าย (ในกรณีรายได้เกิน1,000
                      บาทต่อรอบบิล) และค่าภาษีต่างๆตามที่กฎหมายกำหนดแล้ว{"\n\t"}
                      5.เมื่อผู้สมัครได้รับการแต่งตั้งเป็นผู้สอนของบริษัท เอเชีย
                      คอนเน็คท์ คอร์ปอเรชั่น จำกัด ต้องรักษาความลับของลูกค้า และ
                      ความลับต่างๆของบริษัทฯ
                      โดยไม่นำไปเปิดเผยต่อบุคคลภายนอกที่ไม่มีส่วนเกี่ยวข้องใด ๆ
                      กับบริษัท เอเชีย คอนเน็คท์ คอร์ปอเรชั่น จำกัด โดยเด็ดขาด
                      {"\n\t"}
                      6.เมื่อผู้สมัครได้รับการแต่งตั้งเป็นผู้สอนของบริษัท เอเชีย
                      คอนเน็คท์ คอร์ปอเรชั่น จำกัด
                      ต้องให้ข้อมูลที่ถูกต้องแก่ลูกค้า และตรงไปตรงมา
                      ตามหลักการและทฤษฏีที่เป็นที่ยอมรับทั่วไป{"\n\t"}7.
                      ข้อมูลส่วนบุคคลของผู้สมัครที่ได้ลงทะเบียน
                      หรือผ่านการใช้งานของเว็บไซต์สำหรับการเป็นผู้สอนของบริษัท
                      ทั้งหมดนั้น ผู้สมัครยอมรับและตกลงว่าเป็นสิทธิของบริษัท
                      ในการใช้ข้อมูลเกี่ยวข้องกับการเป็นผู้สอน ของบริษัท เอเชีย
                      คอนเน็คท์ คอร์ปอเรชั่น จำกัดเท่านั้น{"\n\t"}8.
                      ผู้สมัครยอมรับและยินยอมให้เป็นเอกสิทธิ์ของ บริษัท
                      ในการยกเลิกการเป็นผู้สอนของ บริษัท หาก บริษัทฯ
                      พบว่ามีการกระทำผิด ข้อกำหนด และเงื่อนไขของ บริษัทฯ{"\n\t"}
                      9. ข้าพเจ้าผู้สมัคร ได้อ่านเงื่อนไขการสมัครและยินยอมสมัคร
                      เป็นผู้สอน ของบริษัท เอเชีย คอนเน็คท์ คอร์ปอเรชั่น จำกัด
                      ผู้สมัครตกลงยินยอมและปฏิบัติตามข้อตกลง และเงื่อนไขต่าง ๆ
                      ตามที่ระบุไว้ในข้อตกลงดังกล่าว
                      รวมทั้งข้อตกลงและเงื่อนไขอื่น ๆ ตามที่บริษัท เอเชีย
                      คอนเน็คท์ คอร์ปอเรชั่น จำกัด ที่จะกำหนดขึ้นในภายหน้าด้วย
                      {"\n\n"}หมายเหตุ{"\t\t"}ราคาที่แสดงหน้าเว็บไชด์
                      www.courseoncloud.com เป็นราคาที่รวมภาษีมูลค่าเพิ่มแล้ว
                    </Text>
                  </View>
                </ScrollView>
              </View>
            </View>
          </Modal>
        </View>
        <BottomPart navigation={navigation} />
      </ScrollView>
    </View>
  );
}
const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: "100%",
    backgroundColor: "#FFFFFF",
  },
  textDetail: {
    fontSize: 30,
    fontFamily: "Prompt",
    color: "#646464",
  },
  textInput: {
    width: "100%",
    maxWidth: 600,
    height: 35,
    borderWidth: 1,
    borderColor: "#E0E0E0",
    color: "#646464",
    paddingHorizontal: 15,
    fontSize: 20,
    fontFamily: "Prompt",
    marginHorizontal: 20,
  },
  viewDetail: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: 25,
  },
  viewInput: {
    width: "50%",
    flexDirection: "row",
    alignItems: "center",
  },
  textTitle: {
    fontSize: 40,
    fontFamily: "Prompt",
    color: "#646464",
  },
  textInputBank: {
    width: "100%",
    maxWidth: 1200,
    height: 35,
    borderWidth: 1,
    borderColor: "#E0E0E0",
    color: "#646464",
    paddingHorizontal: 15,
    fontSize: 20,
    fontFamily: "Prompt",
    marginHorizontal: 20,
  },
  viewBank: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 25,
    alignItems: "center",
  },
  textInputEducation: {
    width: "100%",
    maxWidth: 400,
    height: 30,
    borderWidth: 1,
    borderColor: "#E0E0E0",
    color: "#646464",
    paddingHorizontal: 15,
    fontSize: 20,
    fontFamily: "Prompt",
    marginHorizontal: 20,
  },
  bgModal: {
    width: "100%",
    height: "100%",
    backgroundColor: "#000000bb",
    justifyContent: "center",
  },
  bgDetailModal: {
    width: "90%",
    minHeight: 1245,
    backgroundColor: "#FFFFFF",
    borderWidth: 1,
    borderColor: "#0D88F0",
    alignSelf: "center",
    padding: 25,
    marginVertical: "10%",
  },
  text20: {
    fontSize: 20,
    fontFamily: "Prompt",
    color: "#646464",
  },
  text18: {
    fontFamily: "Prompt",
    fontSize: 18,
    color: "#646464",
  },
  text25: {
    fontSize: 25,
    fontFamily: "Prompt",
    color: "#646464",
  },
});
const stylesResponsive = StyleSheet.create({
  textDetail: {
    fontSize: 22,
    fontFamily: "Prompt",
    color: "#646464",
  },
  textTitle: {
    fontSize: 32,
    fontFamily: "Prompt",
    color: "#646464",
  },
});
