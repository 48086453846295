import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  Image,
  Dimensions,
  TouchableOpacity,
  StyleSheet,
  TextInput,
  FlatList,
  BackHandler,
} from "react-native";
import { MaterialCommunityIcons } from "@expo/vector-icons";
const { width, height } = Dimensions.get("window");
import firebase from "firebase/app";
import { apiservice } from "../../service";
import { useRecoilState, useRecoilValue } from "recoil";
import { otherStates, tokenState } from "../../reducer/recoil";
import moment from "moment";
import { useWindowSize } from "../components/responsive";
import { deleteChat } from "../../action/authAction";
export default function chat({ navigation }) {
  const [chatGroup, setChatGroup] = useState(true);
  const [chat, setChat] = useState(false);
  const [user, setUser] = useState(null);
  const [intd, setIntd] = useState(null);
  const [otherState, setotherStates] = useRecoilState(otherStates);
  const [detail, setDetail] = useState(null);

  const size = useWindowSize();
  const [messages, setMessages] = useState([]);
  const [body, setBody] = useState({
    roomname: "",
    sending: [],
  });

  const [data, setDocs] = useState([]);
  const [sentText, setsentText] = useState("");
  const [channel, setchannel] = useState("100");
  const token = useRecoilValue(tokenState);
  const [checkIndex, setCheckIndex] = useState();
  useEffect(() => {
    if (user) {
      setBody((val) => ({ ...val, image: user.user_accounts.image_url }));
    }
  }, [user]);

  async function User() {
    const response = await apiservice({
      path: "/authen/user",
      method: "get",
      token: token?.accessToken,
    });
    if (response.status == 200) {
      setUser(response.data.result);
    }
  }

  function Condition(params) {
    try {
      return detail.allUser.filter((item) => item.uid == params)[0].chat_model
        .user_accounts;
    } catch (error) {
      return "";
    }
  }

  async function allroom() {
    const response = await apiservice({
      path: "/course/allroom",
      method: "get",
      token: token?.accessToken,
    });
    if (response.status == 200) {
      setDatas(response.data);
    }
  }
  async function Delete(id) {
    const Delete = await deleteChat({
      id: id,
    });
  }
  useEffect(() => {
    User();
    allroom();
  }, []);

  const [DATA, setDatas] = useState([]);

  useEffect(() => {
    const unsubscribeListener = setInterval(() => {
      allroom();
    }, 1000);

    return () => clearInterval(unsubscribeListener);
  }, []);

  useEffect(() => {
    const unsubscribeListener = firebase
      .firestore()
      .collection("MESSAGE_THREADS")
      .doc(channel) // เลขห้อง ต้องใส่ซซซซซซซซซซซซ
      .collection("MESSAGES")
      .orderBy("createdAt", "desc")
      .onSnapshot((querySnapshot) => {
        const messages = querySnapshot.docs.map((doc) => {
          const firebaseData = doc.data();

          const data = {
            _id: doc.id,
            text: "",
            createdAt: new Date().getTime(),
            ...firebaseData,
          };

          if (!firebaseData.system) {
            data.user = {
              ...firebaseData.user,
              name: firebaseData.user.displayName,
            };
          }

          return data;
        });

        setMessages(messages);
      });

    return () => unsubscribeListener();
  }, [channel]);

  const onSend = () => {
    const text = sentText;

    setsentText("");
    apiservice({
      path: "/course/updateroom",
      method: "put",
      body: {
        Ref: detail.data.Ref,
        image: user.user_accounts.image_url,
      },
      token: token?.accessToken,
    });

    firebase
      .firestore()
      .collection("MESSAGE_THREADS")
      .doc(channel) // เลขห้อง +++++++++++++++++++++++++
      .collection("MESSAGES")
      .add({
        text,
        createdAt: new Date().getTime(),
        user: {
          _id: user.id,
          avatar: "",
          // name: user.user_accounts.firstName,
          name: "",
        },
      });
  };

  if (user == null) {
    return <View />;
  }

  return (
    <View style={{ paddingHorizontal: 50 }}>
      <Text
        style={[styles.textTitle, { marginTop: size.width < 500 ? 20 : 45 }]}
      >
        ข้อความ
      </Text>
      <View style={{ flexDirection: size.width < 500 ? "column" : "row" }}>
        <View style={{}}>
          <TouchableOpacity
            onPress={() => {
              setIntd(null);
              setChat(false);
              setChatGroup(true);
            }}
            style={[
              styles.createGroup,
              { backgroundColor: chatGroup && "#0D88F0" },
            ]}
          >
            <MaterialCommunityIcons
              name="plus-circle"
              size={size.width < 1280 ? 30 : (size.width * 2) / 100}
              color={chatGroup ? "#FFFFFF" : "#0D88F0"}
            />
            <Text
              style={[
                styles.textName,
                {
                  color: chatGroup ? "#FFFFFF" : "#0B2051",
                  fontSize: size.width < 1280 ? 16 : (size.width * 1) / 100,
                },
              ]}
            >
              สร้างกลุ่มแชท
            </Text>
          </TouchableOpacity>
          <FlatList
            numColumns={1}
            data={DATA}
            style={{}}
            renderItem={({ item, index }) => {
              return (
                <TouchableOpacity
                  onPress={async () => {
                    setIntd(index);
                    setchannel("100");
                    setMessages([]);
                    const response = await apiservice({
                      path: "/course/getroom/" + item.id,
                      method: "get",
                      token: token?.accessToken,
                    });

                    if (response.status == 200) {
                      setchannel(item.Ref);
                      setDetail({
                        ...response.data,
                        image:
                          item.chat_model.chat_model.user_accounts.image_url,
                      });
                      setChatGroup(false);
                      setChat(true);
                      allroom();
                    }
                  }}
                  style={[
                    styles.ButtonChat,
                    {
                      backgroundColor:
                        chat && index == intd ? "#0D88F0" : "#FFFFFF",
                    },
                  ]}
                >
                  <View style={{ flexDirection: "row", alignItems: "center" }}>
                    <Image
                      style={{ width: 40, height: 40, marginRight: 20 }}
                      resizeMode="cover"
                      source={{
                        uri:
                          "https://api.courseoncloud.com/api/image/getimage/" +
                          item.image,
                      }}
                    />
                    <Text
                      numberOfLines={1}
                      style={[
                        styles.textChat,
                        {
                          color: chat && index == intd ? "#FFFFFF" : "#0B2051",
                          fontSize:
                            size.width < 1280 ? 16 : (size.width * 1) / 100,
                          width: 150,
                        },
                      ]}
                    >
                      {item.roomname}
                    </Text>
                  </View>
                  {item.notificationLog > 0 && (
                    <View style={styles.viewNoti}>
                      <Text style={styles.textNoti}>
                        {item.notificationLog}
                        {item.notificationLog >= 99 && "+"}
                      </Text>
                    </View>
                  )}
                </TouchableOpacity>
              );
            }}
          />
        </View>
        {chatGroup && (
          <View
            style={{
              width: size.width < 500 ? "100%" : "43%",
              marginTop: size.width < 500 ? 20 : 0,
              marginLeft: size.width < 500 ? 0 : 50,
            }}
          >
            <View style={styles.viewDetail}>
              <Text
                style={[
                  styles.textDetail,
                  { fontSize: size.width < 1280 ? 16 : (size.width * 1) / 100 },
                ]}
              >
                ชื่อกลุ่ม
              </Text>
              <TextInput
                autoComplete="off"
                onChangeText={(text) => {
                  setBody((val) => ({ ...val, roomname: text }));
                }}
                value={body.roomname}
                style={styles.inputDetail}
              />
            </View>
            {body.sending.map((item, index) => {
              return (
                <View style={[styles.viewDetail, { marginTop: 30 }]}>
                  <Text
                    style={[
                      styles.textDetail,
                      {
                        fontSize:
                          size.width < 1280 ? 16 : (size.width * 1) / 100,
                      },
                    ]}
                  >
                    ส่งคำเชิญ
                  </Text>
                  <TextInput
                    autoComplete="off"
                    onChangeText={(text) => {
                      setBody((val) => ({
                        ...val,
                        sending: body.sending.map((items, indexs) => {
                          if (index == indexs) {
                            return text;
                          } else {
                            return items;
                          }
                        }),
                      }));
                    }}
                    value={item}
                    style={styles.inputDetail}
                  />
                </View>
              );
            })}
            <TouchableOpacity
              onPress={() => {
                setBody((val) => ({
                  ...val,
                  sending: val.sending.concat(""),
                }));
              }}
              style={{
                flexDirection: "row",
                alignItems: "center",
                marginTop: 20,
                width: "60%",
                alignSelf: "center",
                marginRight: 10,
              }}
            >
              <MaterialCommunityIcons
                name="plus-circle"
                size={size.width < 500 ? 30 : 40}
                color="#0D88F0"
              />
              <Text
                style={[
                  styles.textDetail,
                  { fontSize: size.width < 1280 ? 16 : (size.width * 1) / 100 },
                ]}
              >
                เพิ่ม E-mail
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={async () => {
                const response = await apiservice({
                  path: "/course/createroom",
                  method: "post",
                  body,
                  token: token?.accessToken,
                });
                if (response.status == 200) {
                  allroom();
                  setBody({
                    roomname: "",
                    sending: [],
                    image: user.user_accounts.image_url,
                  });
                }
              }}
              style={{
                width: 146,
                height: 50,
                backgroundColor: "#0D88F0",
                borderRadius: 10,
                justifyContent: "center",
                alignItems: "center",
                marginVertical: 34,
                alignSelf: size.width < 500 ? "flex-start" : "center",
              }}
            >
              <Text style={styles.textNoti}>สร้างแชท</Text>
            </TouchableOpacity>
          </View>
        )}
        {chat && (
          <View
            style={{
              width: size.width < 500 ? "100%" : "70%",

              marginLeft: size.width < 500 ? 0 : 20,
              marginTop: 20,
            }}
          >
            <View
              style={{
                flexDirection: size.width < 500 ? "column" : "row",
                justifyContent: "space-between",
              }}
            >
              <View style={{ flexDirection: "row" }}>
                <Image
                  style={{ width: 40, height: 40, marginRight: 20 }}
                  source={{
                    uri:
                      "https://api.courseoncloud.com/api/image/getimage/" +
                      detail.data.image,
                    // DATA.chat_model.user_accounts.image_url,
                  }}
                  resizeMode="cover"
                />
                <Text style={styles.textDetail}>{detail.data.roomname}</Text>
              </View>
              <TouchableOpacity
                onPress={() => {
                  Delete(detail.data.id);
                  setChat(false);
                  setChatGroup(true);
                }}
                style={{
                  width: size.width < 500 ? 70 : 90,
                  height: size.width < 500 ? 30 : 40,
                  borderRadius: 20,
                  backgroundColor: "#E82626",
                  justifyContent: "center",
                  alignItems: "center",
                  marginLeft: size.width < 500 && 50,
                }}
              >
                <Text
                  style={[
                    styles.textNoti,
                    { fontSize: size.width < 500 ? 12 : 20 },
                  ]}
                >
                  ลบแชท
                </Text>
              </TouchableOpacity>
            </View>
            <View
              style={{
                width: "100%",
                maxWidth: size.width > 500 && 900,
                height: size.width < 500 ? 400 : 650,
                backgroundColor: "#E9E9E9",
                marginTop: 10,
                padding: 20,
              }}
            >
              <FlatList
                inverted
                data={messages}
                renderItem={({ item, index }) => {
                  return user.id != item.user._id ? (
                    <View>
                      <View
                        style={{
                          maxWidth: size.width < 500 ? "100%" : "40%",
                          flexDirection: "row",
                          marginTop: 10,
                          paddingLeft: 10,
                        }}
                      >
                        <View style={{ maxWidth: "80%" }}>
                          <Text
                            style={[
                              styles.textChat,
                              {
                                padding: 20,
                                minHeight: 65,
                                justifyContent: "center",
                                alignItems: "center",
                                borderRadius: 20,
                                borderBottomLeftRadius: 0,

                                backgroundColor: "#FFFFFF",
                                fontSize: size.width < 500 ? 14 : 20,
                              },
                            ]}
                          >
                            {item.text}
                          </Text>
                        </View>
                        <View
                          style={{ width: "20%", justifyContent: "flex-end" }}
                        >
                          <Text
                            style={[
                              styles.textTimeChat,
                              { alignSelf: "flex-start" },
                            ]}
                          >
                            {moment(item.createdAt).format("hh:mm")} น.
                          </Text>
                        </View>
                      </View>
                      <TouchableOpacity
                        onPress={() => {
                          setotherStates({
                            id: item.user._id,
                            user_accounts: Condition(item.user._id),
                          });
                          navigation.navigate("otherprofile");
                        }}
                      >
                        <Text
                          style={[
                            styles.textTimeChat,
                            {
                              alignSelf: "flex-start",
                              paddingLeft: 10,
                            },
                          ]}
                        >
                          {Condition(item.user._id).firstName +
                            " " +
                            Condition(item.user._id).lastName}
                        </Text>
                      </TouchableOpacity>
                    </View>
                  ) : (
                    <View
                      style={{
                        flexDirection: "row",
                        width: size.width < 500 ? "100%" : "40%",
                        justifyContent: "flex-end",
                        alignSelf: "flex-end",
                        marginTop: 10,
                      }}
                    >
                      <View
                        style={{
                          width: "30%",
                          justifyContent: "flex-end",
                        }}
                      >
                        <MaterialCommunityIcons
                          name="check"
                          size={15}
                          color="#0B2051"
                          style={{
                            alignSelf: "flex-end",
                            alignItems: "center",
                          }}
                        >
                          <Text style={styles.textTimeChat}>
                            {moment(item.createdAt).format("hh:mm")} น.
                          </Text>
                        </MaterialCommunityIcons>
                      </View>
                      <View
                        style={{
                          maxWidth: "70%",
                          alignSelf: "flex-end",
                        }}
                      >
                        <Text
                          style={[
                            styles.textChat,
                            {
                              padding: 20,
                              backgroundColor: "#0D88F0",
                              minHeight: 65,
                              justifyContent: "center",
                              alignItems: "center",
                              borderRadius: 20,
                              borderBottomRightRadius: 0,
                              fontSize: size.width < 500 ? 14 : 20,
                              // alignSelf: "flex-end",
                            },
                          ]}
                        >
                          {item.text}
                        </Text>
                      </View>
                    </View>
                  );
                }}
              />
            </View>

            <TextInput
              autoComplete="off"
              placeholder="พิมพ์ข้อความ .."
              placeholderTextColor="#D4D4D4"
              multiline
              textAlignVertical="top"
              style={{
                width: "100%",
                maxWidth: 900,
                minHeight: 100,
                borderWidth: 1,
                borderColor: "#0D88F0",
                padding: 15,
                fontSize: size.width < 500 ? 12 : 16,
                fontFamily: "Prompt",
                color: "#0B2051",
                paddingRight: "10%",
              }}
              value={sentText}
              onChangeText={(text) => {
                setsentText(text);
              }}
            />
            <TouchableOpacity
              disabled={sentText == "" ? true : false}
              onPress={onSend}
              style={{
                width: size.width < 500 ? 73 : 83,
                height: size.width < 500 ? 25 : 32,
                backgroundColor: sentText == "" ? "#CCCCCC" : "#0D88F0",
                borderRadius: 5,
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "flex-end",
                margin: 10,
                marginTop: -40,
              }}
            >
              <Text
                style={{
                  fontSize: size.width < 500 ? 12 : 16,
                  fontFamily: "Prompt",
                  color: "#FFFFFF",
                }}
              >
                ส่ง
              </Text>
            </TouchableOpacity>
          </View>
        )}
      </View>
    </View>
  );
}
const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: "100%",
    backgroundColor: "#FFFFFF",
  },
  textTitle: {
    fontSize: 30,
    fontFamily: "Prompt",
    color: "#0B2051",
    marginTop: 45,
  },
  createGroup: {
    width: "100%",
    maxWidth: 300,
    height: 70,
    borderWidth: 1,
    borderColor: "#BBB",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    marginTop: 12,
    paddingHorizontal: 20,
  },
  textName: {
    fontSize: 25,
    fontFamily: "Prompt",
    color: "#0B2051",
    marginLeft: 10,
  },
  ButtonChat: {
    width: "100%",
    maxWidth: 300,
    height: 70,
    borderWidth: 1,
    borderColor: "#CCC",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
    borderTopWidth: 0,
    paddingHorizontal: 20,
  },
  textChat: {
    fontSize: 20,
    fontFamily: "Prompt",
    color: "#0B2051",
    marginLeft: 20,
  },
  textNoti: {
    fontSize: 20,
    fontFamily: "Prompt",
    color: "#FFFFFF",
  },
  viewNoti: {
    width: 40,
    height: 40,
    borderRadius: 20,
    backgroundColor: "#0D88F0",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    right: 10,
  },
  viewDetail: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  textDetail: {
    fontSize: 20,
    fontFamily: "Prompt",
    color: "#0B2051",
  },
  inputDetail: {
    width: "100%",
    maxWidth: 400,
    height: 40,
    borderWidth: 1,
    borderColor: "#BBBBBB",
    paddingHorizontal: 15,
    fontSize: 18,
    fontFamily: "Prompt",
    color: "#0B2051",
  },
  textChat: {
    fontSize: 18,
    fontFamily: "Prompt",
    color: "#0B2051",
  },
  textTimeChat: {
    fontSize: 10,
    fontFamily: "Prompt",
    color: "#0B2051",
    alignSelf: "flex-end",
  },
});
