import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  Image,
  Dimensions,
  Animated,
  TouchableOpacity,
  StyleSheet,
  ScrollView,
  TextInput,
  FlatList,
} from "react-native";
import {
  MaterialCommunityIcons,
  MaterialIcons,
  Feather,
  Ionicons,
  FontAwesome,
} from "@expo/vector-icons";
import RNPickerSelect from "react-native-picker-select";
import Modal from "modal-react-native-web";
import { useRecoilState } from "recoil";
import { tokenState } from "../../../reducer/recoil";
import { useIsFocused } from "@react-navigation/native";
import { apiservice } from "../../../service";
import moment from "moment";
import { deleteCourse } from "../../../action/authAction";
import { useWindowSize } from "../../components/responsive";
const { width, height } = Dimensions.get("window");
export default function coursesTaught({
  onPress,
  setCourseName,
  setCourseName1,
}) {
  const [id, setId] = useState();
  const [modalDelItem, setModalDelItem] = useState(false);
  const [data, setData] = useState();
  const [deGreeIndex, setDeGreeIndex] = useState(0);
  const [token, setToken] = useRecoilState(tokenState);
  const [course, setCourse] = useState({});
  const [university, setUniversity] = useState();
  const [name, setUniversityName] = useState();
  const [value, labe] = useState(null);
  const [university_id, setUniversity_id] = useState();
  const size = useWindowSize();
  const degreeList = ["ปริญญาตรี", "ปริญญาโท", "ปริญญาเอก"];
  useEffect(() => {
    if (token) {
      getUniversity(token);
      getProfile(token);
    }
  }, [token, university_id]);

  async function Delete() {
    const Delete = await deleteCourse({
      token: token?.accessToken,
      id: id,
    });
  }

  async function getProfile(token) {
    try {
      const response = await apiservice({
        path: "/authen/user",
        method: "get",
        token: token?.accessToken,
      });
      if (response.status == 200) {
        setUniversity_id(response.data.result.university.id);
        getCourse(response.data.result.university.id);
        setUniversityName(response.data.result.university.university_name_th);
        getFaculty(response.data.result.university.id);
      }
    } catch (error) {
      throw error;
    }
  }

  async function getUniversity(token) {
    try {
      const response = await apiservice({
        path: "/course/getuniversity?page=1&totalpage=100",
        method: "get",
        token: token?.accessToken,
      });

      if (response.status == 200) {
        let pagination = [];
        for (let index = 0; index < response.data.total_page; index++) {
          pagination = pagination.concat({});
        }

        setUniversity(response.data);
      }
    } catch (error) {
      throw error;
    }
  }
  async function getUniversity1(token, val) {
    try {
      const response = await apiservice({
        path:
          "/course/getallcouseapprove?university_id=" +
          university_id +
          "&page=1&totalpage=100&faculty_id=" +
          val,
        method: "get",
        token: token?.accessToken,
      });

      if (response.status == 200) {
        let pagination = [];
        for (let index = 0; index < response.data.total_page; index++) {
          pagination = pagination.concat({});
        }

        setCourse(response.data.result);
      }
    } catch (error) {
      throw error;
    }
  }

  async function getUniversity2(token, val) {
    try {
      const response = await apiservice({
        path:
          "/course/getallcouseapprove?university_id=" +
          university_id +
          "&page=1&totalpage=100&grade_id=" +
          val,
        method: "get",
        token: token?.accessToken,
      });

      if (response.status == 200) {
        let pagination = [];
        for (let index = 0; index < response.data.total_page; index++) {
          pagination = pagination.concat({});
        }

        setCourse(response.data.result);
      }
    } catch (error) {
      throw error;
    }
  }
  async function getFaculty(params) {
    try {
      const response = await apiservice({
        path: "/course/getfaculty?page=1&totalpage=100&university_id=" + params,
        method: "get",
        token: token?.accessToken,
      });

      if (response.status == 200) {
        let pagination = [];
        for (let index = 0; index < response.data.total_page; index++) {
          pagination = pagination.concat({});
        }
        setFaculty(
          response.data.result.map((item) => {
            return {
              label: item.facuty_name_th + "/" + item.major_name_th,
              value: item.id.toString(),
            };
          })
        );
      }
    } catch (error) {
      throw error;
    }
  }
  async function getCourse(params) {
    try {
      const response = await apiservice({
        path:
          "/course/getallcouseapprove?university_id=" +
          params +
          "&page=1&totalpage=100",
        method: "get",
        token: token?.accessToken,
      });

      if (response.status == 200) {
        let pagination = [];
        for (let index = 0; index < response.data.total_page; index++) {
          pagination = pagination.concat({});
        }

        setCourse(response.data.result);
      }
    } catch (error) {
      throw error;
    }
  }

  const degreeChangeHandler = (index) => {
    getUniversity2(token, index);

    setDeGreeIndex((preIndex) => index);
  };
  const placeholder = {
    label: "คณะทั้งหมด",
    value: null,
    color: "#0D88F0",
  };

  const [faculty, setFaculty] = useState([]);
  var dd = moment.locale("th");

  return (
    <View>
      <View style={{ flexDirection: "row", marginTop: 10 }}>
        <TouchableOpacity>
          <Text
            style={{
              fontSize: 23,
              fontFamily: "Prompt",
              color: "#808D93",
            }}
          >
            คอร์สมหาวิทยาลัย
          </Text>
        </TouchableOpacity>
        <Text
          style={{
            fontSize: 23,
            fontFamily: "Prompt",
            color: "#808D93",
            marginHorizontal: 18,
          }}
        >
          /
        </Text>
        <Text
          style={{
            fontSize: 23,
            fontFamily: "Prompt",
            color: "#0D88F0",
          }}
        >
          คอร์สที่สอน
        </Text>
      </View>
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          marginTop: 39,
        }}
      >
        <View
          style={{
            width: size.width < 500 ? "80%" : "50%",
            height: 64,
            borderRadius: 4,
            backgroundColor: "#EFEFF3",
            paddingHorizontal: 23,
            justifyContent: "center",
          }}
        >
          <Text
            style={{
              fontSize: width < 1600 ? 20 : 25,
              fontFamily: "Prompt",
              color: "#444444",
            }}
          >
            {name}
          </Text>
        </View>
      </View>
      <View
        style={{
          flexDirection: size.width < 500 ? "column" : "row",
          marginTop: 46,
        }}
      >
        {degreeList.map((data, index) => (
          <TouchableOpacity
            key={data}
            style={{
              flexDirection: "row",
              alignItems: "center",
              marginLeft: index == 0 ? 0 : size.width < 500 ? 0 : width * 0.05,
            }}
            onPress={degreeChangeHandler.bind(this, index)}
          >
            <MaterialIcons
              name={
                index === deGreeIndex
                  ? "radio-button-checked"
                  : "radio-button-unchecked"
              }
              size={30}
              color={index === deGreeIndex ? "#0D88F0" : "#808D93"}
            />
            <Text
              style={[
                styles.text23,
                { color: index === deGreeIndex ? "#0D88F0" : "#808D93" },
              ]}
            >
              {data}
            </Text>
          </TouchableOpacity>
        ))}
      </View>
      {faculty.length > 0 && (
        <RNPickerSelect
          placeholder={placeholder}
          onValueChange={(val) => {
            labe(val);

            if (val != null && val != "คณะทั้งหมด") {
              getUniversity1(token, val);
            } else {
              getCourse(university_id);
            }
          }}
          items={faculty}
          style={{
            inputWeb: {
              backgroundColor: "#FFFFFF",
              borderColor: "#0D88F0",
              width: size.width < 500 ? "80%" : "25%",
              height: 32,
              color: "#0D88F0",
              fontFamily: "Prompt",
              fontSize: 16,
              paddingHorizontal: 10,
              marginVertical: 33,
            },
          }}
        />
      )}
      <ScrollView horizontal>
        <View
          style={{
            width:
              size.width < 500
                ? size.width * 2.5
                : size.width < 1280
                ? size.width * 1.0
                : size.width * 0.78,
            // minHeight: height * 2.5,
          }}
        >
          <View style={styles.viewSubject}>
            <Text style={[styles.text16B, { paddingLeft: 67, width: "30%" }]}>
              ชื่อวิชา
            </Text>
            <Text style={[styles.text16B, { width: "20%" }]}>คณะ</Text>
            <Text style={[styles.text16B, { width: "10%" }]}>สาขา</Text>
            <Text style={[styles.text16B, { width: "15%" }]}>วันที่สร้าง</Text>
            <Text style={[styles.text16B, { paddingLeft: 67, width: "25%" }]}>
              อาจารย์ที่สร้างคอร์ส
            </Text>
            <View style={{ width: "5%", alignItems: "center" }} />
          </View>
          <FlatList
            numColumns={1}
            data={course}
            renderItem={({ item, index }) => {
              return (
                <TouchableOpacity
                  onPress={() => {
                    setCourseName(item.title);
                    setCourseName1(item);
                    onPress();
                  }}
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    borderBottomWidth: 1,
                    borderBottomColor: "#E5E5E5",
                    height: 50,
                    alignItems: "center",
                  }}
                >
                  <Text
                    style={[styles.text16B, { paddingLeft: 67, width: "30%" }]}
                  >
                    {item.title}
                  </Text>
                  <Text style={[styles.text16B, { width: "20%" }]}>
                    {item.faculty != null && item.faculty.facuty_name_th}
                  </Text>
                  <Text style={[styles.text16B, { width: "10%" }]}>
                    {item.faculty != null && item.faculty.major_name_th}
                  </Text>
                  <Text style={[styles.text16B, { width: "15%" }]}>
                    {moment(item.updatedAt)
                      .add(543, "year")
                      .format("DD MMMM YYYY")}
                  </Text>
                  <Text
                    style={[styles.text16B, { paddingLeft: 67, width: "25%" }]}
                  >
                    {item.teacher.firstName} {item.teacher.lastName}
                  </Text>
                  <TouchableOpacity
                    onPress={() => {
                      setId(item.id);
                      setModalDelItem(true);
                    }}
                    style={{ width: "5%", alignItems: "center" }}
                  >
                    <MaterialIcons name="delete" size={24} color="black" />
                  </TouchableOpacity>
                </TouchableOpacity>
              );
            }}
          />
        </View>
      </ScrollView>
      <Modal
        transparent={true}
        visible={modalDelItem}
        onRequestClose={() => {
          Alert.alert("Modal has been closed.");
          setModalDelItem(!modalDelItem);
        }}
      >
        <View style={styles.bgModal}>
          <View
            style={[
              styles.viewDetailModal,
              { width: "30%", height: 183, paddingVertical: 25 },
            ]}
          >
            <Text
              style={[
                styles.textButtonModal,
                { color: "#444444", alignSelf: "center" },
              ]}
            >
              คุณต้องการจะลบใช่รึไม่
            </Text>
            <View
              style={{
                flexDirection: "row",
                alignSelf: "center",
                marginTop: 39,
              }}
            >
              <TouchableOpacity
                onPress={() => setModalDelItem(!modalDelItem)}
                style={styles.buttonModal}
              >
                <Text
                  style={[
                    styles.textButtonModal,
                    {
                      color: "#444444",
                    },
                  ]}
                >
                  ยกเลิก
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => {
                  Delete();
                  setTimeout(() => {
                    getUniversity(token);
                    getProfile(token);
                  }, 500);
                  setModalDelItem(!modalDelItem);
                }}
                style={[
                  styles.buttonModal,
                  {
                    backgroundColor: "#0D88F0",
                    marginLeft: width * 0.05,
                  },
                ]}
              >
                <Text
                  style={[
                    styles.textButtonModal,
                    {
                      color: "#FFFFFF",
                    },
                  ]}
                >
                  ยืนยัน
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </Modal>
    </View>
  );
}
const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: "100%",
    backgroundColor: "#FFFFFF",
  },
  text16B: {
    fontFamily: "Prompt",
    fontSize: 16,
    color: "#000000",
  },
  viewSubject: {
    width: "100%",
    height: 51,
    backgroundColor: "#E5E5E5",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  text23: {
    fontSize: 23,
    fontFamily: "Prompt",
    color: "#808D93",
  },
  viewDetailModal: {
    width: "50%",

    backgroundColor: "#FFFFFF",
    borderRadius: 20,
    alignSelf: "center",
    paddingHorizontal: width * 0.05,
    paddingVertical: 50,
  },
  bgModal: {
    width: "100%",
    height: "100%",
    backgroundColor: "#000000bb",
    justifyContent: "center",
  },
  buttonModal: {
    width: 102,
    height: 48,
    borderRadius: 4,
    backgroundColor: "#EFEFF3",
    justifyContent: "center",
    alignItems: "center",
  },
  textButtonModal: {
    fontSize: 25,
    fontFamily: "Prompt",
  },
  inputModal: {
    width: "100%",
    height: 67,
    borderRadius: 9,
    borderWidth: 1,
    borderColor: "#707070",
    fontSize: 35,
    fontFamily: "Prompt",
    color: "#444444",
    textAlign: "center",
    marginTop: 40,
  },
  text14R: {
    fontSize: 14,
    color: "red",
    fontFamily: "Prompt",
  },
});
