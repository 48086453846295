import React, { useState } from "react";
import { View, Text, Image, Dimensions } from "react-native";
import HTMLRenderer from "react-html-renderer";
import { useWindowSize } from "../components/responsive";
import ReactPlayer from "react-player/youtube";
const { width } = Dimensions.get("window");
export default function cousePage0({ route, source, url }) {
  const embed = route;

  const stringToHTML = function (str) {
    const domContainer = document.createElement("span");
    domContainer.innerHTML = str;
    return domContainer;
  };

  const parentEmbed = stringToHTML(embed);

  let oldIframe = parentEmbed.querySelectorAll("oembed");
  oldIframe = Array.from(oldIframe);

  for (const i in oldIframe) {
    //Get the url from oembed tag
    let url = oldIframe[i].getAttribute("url");
    //Replace 'watch?v' with 'embed/'
    url = url.replace("watch?v=", "embed/");

    //Create a iframe tag
    const newIframe = document.createElement("iframe");
    newIframe.setAttribute("width", "auto");
    newIframe.setAttribute("height", "auto");
    newIframe.setAttribute("allowFullScreen", "");
    newIframe.setAttribute("frameBorder", 0);
    if (url) {
      newIframe.setAttribute("src", url);
    }
    // replace oldIframe with newIframe
    oldIframe[i].parentNode.replaceChild(newIframe, oldIframe[i]);
  }

  const contentToRender = parentEmbed.outerHTML?.replace(
    /<img /g,
    `<img width="${width * 0.5}" height="auto" `
  );
  const size = useWindowSize();
  return (
    <View style={{ minHeight: 500 }}>
      {url != "" ? (
        <View>
          <ReactPlayer
            url={url}
            width={"100%"}
            height={size.width < 750 ? 250 : 574}
            controls={true}
          />
          {/* <View
            style={{
              width: "100%",
              height: 200,
              position: "absolute",
              backgroundColor: "red",
              zIndex: 99,
            }}
          /> */}
        </View>
      ) : (
        <Image
          resizeMode="stretch"
          style={{
            width: "100%",
            height: size.width < 750 ? 250 : 510,
            marginVertical: 20,
          }}
          source={source}
        />
      )}

      <Text
        style={{
          fontFamily: "Prompt",
          fontSize: size.width < 750 ? 16 : 16,
          color: "#646464",
        }}
      >
        <HTMLRenderer
          html={contentToRender}
          components={
            {
              // h1: props => <Heading color="red" {...props} />,
              // h2: Subheading,
              // a: Link,
            }
          }
        />
      </Text>
    </View>
  );
}
