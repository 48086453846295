//import liraries
import axios from 'axios';
import React, { Component, useEffect } from 'react';
import { View, Text, StyleSheet } from 'react-native';

// create a component
const MyComponent = () => {

    useEffect(() => {
        line()
    }, [])

    const line = async () => {
        const query = window.location.search.substring(1);
        console.log('====================================');
        console.log(query);
        console.log('====================================');
        const tokenResponse = await axios.post(
            'https://api.line.me/oauth2/v2.1/token',
            {
                grant_type: 'authorization_code',
                code: code,
                redirect_uri: 'https://api.courseoncloud.com/api/authen/line',
                client_id: '1656890076',
                client_secret: 'c1f1a07de8820a90fc061a8cf58dd72c',
            },
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            }
        );

        const accessToken = tokenResponse.data.access_token;

        const profileResponse = await axios.get('https://api.line.me/v2/profile', {
            headers: {
                'Authorization': `Bearer ${accessToken}`,
            },
        });

        const userProfile = profileResponse.data;
        alluser_collapse.createLine({
            user: {
                displayName: userProfile.displayName,
                id: userProfile.userId,
            }
        }, res);
    }

    return (
        <View style={styles.container}>
            <Text>MyComponent</Text>
        </View>
    );
};

// define your styles
const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#2c3e50',
    },
});

//make this component available to the app
export default MyComponent;
