import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  Image,
  Dimensions,
  Animated,
  TouchableOpacity,
  StyleSheet,
  ScrollView,
  TextInput,
  FlatList,
} from "react-native";
import {
  MaterialCommunityIcons,
  MaterialIcons,
  Feather,
  Ionicons,
  FontAwesome,
} from "@expo/vector-icons";
import { useWindowSize } from "./responsive";
export default function buttonMenuSuperAdmin({
  p1,
  p2,
  p3,
  p4,
  p5,
  p6,
  p7,
  color,
  border,
  onPress,
}) {
  const size = useWindowSize();
  return (
    <TouchableOpacity
      style={[
        styles.buttonTitle,
        {
          borderLeftWidth: border ? 3 : 0,
          marginTop: size.width < 1400 ? 40 : 50,
        },
      ]}
      onPress={onPress}
    >
      <Ionicons //folder-open-outline
        name={
          (p1 && "home-outline") ||
          (p2 && "folder-open-outline") ||
          (p3 && "pie-chart-outline") ||
          (p4 && "options-outline") ||
          (p5 && "settings-outline") ||
          (p6 && "cart-outline") ||
          (p7 && "wallet-outline")
        }
        size={30}
        color={color ? "#0D88F0" : "#A2A2A2"}
      />
      <Text
        style={[
          styles.textTitle,
          {
            color: color ? "#0D88F0" : "#A2A2A2",
          },
        ]}
      >
        {(p1 && "คอร์สเรียนมหาวิทยาลัย") ||
          (p2 && "คอร์สเรียนทั่วไป") ||
          (p3 && "ผู้ใช้งาน admin") ||
          (p4 && "Report") ||
          (p5 && "Slide & Banner") ||
          (p6 && "Discount") ||
          (p7 && "คำร้องเปลี่ยนบัญชี")}
      </Text>
    </TouchableOpacity>
  );
}
const styles = StyleSheet.create({
  buttonTitle: {
    paddingLeft: 20,
    flexDirection: "row",
    alignItems: "center",
    marginTop: 50,

    borderLeftColor: "#0D88F0",
  },
  textTitle: {
    fontFamily: "Prompt",
    fontSize: 16,
    marginLeft: 32,
  },
});
