import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  Image,
  Dimensions,
  Animated,
  TouchableOpacity,
  StyleSheet,
  ScrollView,
  TextInput,
  FlatList,
} from "react-native";
import {
  MaterialCommunityIcons,
  MaterialIcons,
  Feather,
  Ionicons,
  FontAwesome,
  FontAwesome5,
} from "@expo/vector-icons";
import RNPickerSelect from "react-native-picker-select";
import Modal from "modal-react-native-web";
import { useRecoilState, useRecoilValue } from "recoil";
import { tokenState } from "../../../../reducer/recoil";
import { useIsFocused } from "@react-navigation/native";
import { apiservice } from "../../../../service";
import moment from "moment";
import { useWindowSize } from "../../../components/responsive";
const { width, height } = Dimensions.get("window");
export default function Student({ onPress }) {
  const [DATA, setData] = useState([]);
  const [DataTeacher, setDataTeacher] = useState([]);
  const token = useRecoilValue(tokenState);
  const [search, setSearch] = useState("");
  const size = useWindowSize();
  useEffect(() => {
    apiCall();
  }, [token]);

  async function apiCall() {
    const res = await apiservice({
      path: "/authen/getgeneralstudent?page=1&totalpage=100",
      method: "get",
      token: token?.accessToken,
    });

    if (res?.status == 200) {
      setData(res?.data?.result);
    }
  }

  return (
    <View style={{ marginTop: 20 }}>
      <Text
        style={{
          fontFamily: "Prompt",
          fontSize: 23,
          color: "#0D88F0",
        }}
      >
        ผู้เรียน
      </Text>
      <TextInput
        onChangeText={(text) => {
          setSearch(text);
        }}
        autoComplete="off"
        placeholder={"ค้นหาจากชื่อผู้เรียน .."}
        placeholderTextColor="#0D88F0"
        style={[styles.input, { marginTop: 27 }]}
      />
      <ScrollView horizontal>
        <View
          style={{
            width:
              size.width < 500
                ? size.width * 2.5
                : size.width < 1280
                ? size.width * 1.0
                : size.width * 0.75,
            // minHeight: height * 2.5,
          }}
        >
          <View style={styles.viewSubject}>
            <Text
              style={[styles.text16B, { width: "10%", textAlign: "center" }]}
            >
              ลำดับ
            </Text>
            <Text style={[styles.text16B, { width: "20%" }]}>ชื่อผู้เรียน</Text>
            <Text
              style={[
                styles.text16B,
                {
                  width: "30%",
                  textAlign: "center",
                  paddingRight: width * 0.05,
                },
              ]}
            >
              จำนวนคอร์สที่เรียน
            </Text>
            <Text style={[styles.text16B, { width: "20%" }]}>
              วันที่เป็นสมาชิก
            </Text>
            <Text
              style={[styles.text16B, { width: "20%", textAlign: "center" }]}
            >
              รายละเอียด
            </Text>
          </View>
          <FlatList
            numColumns={1}
            data={DATA.filter((item) => {
              return item.user_accounts?.firstName?.includes(search);
            })}
            renderItem={({ item, index }) => {
              return (
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    borderBottomWidth: 1,
                    borderBottomColor: "#E5E5E5",
                    height: 50,
                    alignItems: "center",
                  }}
                >
                  <Text
                    style={[
                      styles.text16B,
                      { width: "10%", textAlign: "center" },
                    ]}
                  >
                    {index + 1}
                  </Text>
                  {item.user_accounts?.firstName != null ? (
                    <Text style={[styles.text16B, { width: "20%" }]}>
                      {item.user_accounts.firstName != null
                        ? item?.user_accounts?.firstName +
                          "  " +
                          item?.user_accounts?.lastName
                        : ""}
                    </Text>
                  ) : (
                    <Text style={[styles.text16B, { width: "20%" }]}></Text>
                  )}

                  <Text
                    style={[
                      styles.text16B,
                      {
                        width: "30%",
                        textAlign: "center",
                        paddingRight: width * 0.05,
                      },
                    ]}
                  >
                    {item?.allResult?.length}
                  </Text>
                  <Text style={[styles.text16B, { width: "20%" }]}>
                    {moment(item.user_accounts.createdAt)
                      .add(543, "year")
                      .format("DD MMMM YYYY")}
                  </Text>
                  <TouchableOpacity
                    onPress={() => onPress(item)}
                    style={{ width: "20%", alignItems: "center" }}
                  >
                    <FontAwesome5
                      name="clipboard-list"
                      size={24}
                      color="black"
                    />
                  </TouchableOpacity>
                </View>
              );
            }}
          />
        </View>
      </ScrollView>
    </View>
  );
}
const styles = StyleSheet.create({
  input: {
    maxWidth: 400,
    width: "100%",
    height: 32,
    borderWidth: 1,
    borderColor: "#0D88F0",
    borderRadius: 4,
    paddingHorizontal: 10,
    fontFamily: "Prompt",
    fontSize: 16,
    color: "#0D88F0",
  },
  text16B: {
    fontFamily: "Prompt",
    fontSize: 16,
    color: "#000000",
  },
  viewSubject: {
    width: "100%",
    height: 51,
    backgroundColor: "#E5E5E5",
    marginTop: 35,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
});
