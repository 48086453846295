import { apiservice } from "../service";
import { Linking } from "react-native";

export async function authActionLogin(state) {
  try {
    const response = await apiservice({
      path: "/authen/login",
      method: "post",
      body: state,
    });
    if (response.status == 200) {
      return response.data;
    } else {
      return false;
    }
  } catch (error) {
    throw error;
  }
}
export async function authActionRegister(data) {
  try {
    const response = await apiservice({
      path: "/authen/register",
      method: "POST",
      body: data,
    });
    if (response.status == 200) {
      return response.data;
    } else {
      return false;
    }
  } catch (error) {
    throw error;
  }
}
export async function authActionPutUser({ state, token }) {
  try {
    const response = await apiservice({
      path: "/authen/update",
      method: "put",
      body: state,
      token: token,
    });
    if (response.status == 200) {
      return response.data;
    } else {
      return false;
    }
  } catch (error) {
    throw error;
  }
}
export async function authActionPutUserByAdmin({ state, token, id }) {
  try {
    const response = await apiservice({
      path: "/authen/updateby/" + id,
      method: "put",
      body: state,
      token: token,
    });
    if (response.status == 200) {
      return response.data;
    } else {
      return false;
    }
  } catch (error) {
    throw error;
  }
}
export async function authActionUploadImg(state) {
  try {
    const response = await apiservice({
      path: "/image/create",
      method: "post",
      body: state,
    });
    if (response.status == 200) {
      return response.data;
    } else {
      return false;
    }
  } catch (error) {
    throw error;
  }
}
export async function authActionCreateTeacher(state) {
  try {
    const response = await apiservice({
      path: "/authen/registerteacher",
      method: "post",
      body: state,
    });
    if (response.status == 200) {
      return response.data;
    } else {
      return false;
    }
  } catch (error) {
    throw error;
  }
}
export async function authActionCreateStudent(state) {
  try {
    const response = await apiservice({
      path: "/authen/registerteacher",
      method: "post",
      body: state,
    });
    if (response.status == 200) {
      return response.data;
    } else {
      return false;
    }
  } catch (error) {
    throw error;
  }
}
export async function authActionCreateFaculty({ state, token }) {
  try {
    const response = await apiservice({
      path: "/course/createfaculty",
      method: "post",
      body: state,
      token: token,
    });
    if (response.status == 200) {
      return response.data;
    } else {
      return false;
    }
  } catch (error) {
    throw error;
  }
}
export async function authActionCreateUniversity({ state, token }) {
  try {
    const response = await apiservice({
      path: "/course/createuniversity",
      method: "post",
      body: state,
      token: token,
    });
    if (response.status == 200) {
      return response.data;
    } else {
      return false;
    }
  } catch (error) {
    throw error;
  }
}
export async function authActionCreateCreatecert({ state, token }) {
  try {
    const response = await apiservice({
      path: "/course/createcert",
      method: "post",
      body: state,
      token: token,
    });
    if (response.status == 200) {
      return response.data;
    } else {
      return false;
    }
  } catch (error) {
    throw error;
  }
}
export async function authActionCreateBanner({ state, token }) {
  try {
    const response = await apiservice({
      path: "/banner/create",
      method: "post",
      body: state,
      token: token,
    });
    if (response.status == 200) {
      return response.data;
    } else {
      return false;
    }
  } catch (error) {
    throw error;
  }
}
export async function authActionStartCourseCreate({ state, token }) {
  try {
    const response = await apiservice({
      path: "/start-course/create",
      method: "post",
      body: state,
      token: token,
    });
    if (response.status == 200) {
      return response.data;
    } else {
      return false;
    }
  } catch (error) {
    throw error;
  }
}
export async function authActionUniverscitybanner({ state, token }) {
  try {
    const response = await apiservice({
      path: "/banner/creatunivercitybanner",
      method: "post",
      body: state,
      token: token,
    });

    if (response.status == 200) {
      return response.data;
    } else {
      return false;
    }
  } catch (error) {
    throw error;
  }
}
export async function authActionForgetPassword({ state, token }) {
  try {
    const response = await apiservice({
      path: "/authen/forget",
      method: "post",
      body: state,
      token: token,
    });
    if (response.status == 200) {
      return response.data;
    } else {
      return false;
    }
  } catch (error) {
    throw error;
  }
}
export async function authActionCreateCategory({ state, token }) {
  try {
    const response = await apiservice({
      path: "/category/create",
      method: "post",
      body: state,
      token: token,
    });
    if (response.status == 200) {
      return response.data;
    } else {
      return false;
    }
  } catch (error) {
    throw error;
  }
}
export async function authActionRating({ state, token }) {
  try {
    const response = await apiservice({
      path: "/start-course/createreview",
      method: "post",
      body: state,
      token: token,
    });
    if (response.status == 200) {
      return response.data;
    } else {
      return false;
    }
  } catch (error) {
    throw error;
  }
}
export async function deleteCategory({ token, id }) {
  try {
    const response = await apiservice({
      path: "/delete/category/" + id,
      method: "delete",
      token,
    });
    if (response.status == 200) {
      return response.data;
    } else {
      return false;
    }
  } catch (error) {
    throw error;
  }
}
export async function deleteTeacher({ token, id }) {
  try {
    const response = await apiservice({
      path: "/delete/teacher/" + id,
      method: "delete",
      token,
    });
    if (response.status == 200) {
      return response.data;
    } else {
      return false;
    }
  } catch (error) {
    throw error;
  }
}
export async function deleteStudent({ token, id }) {
  try {
    const response = await apiservice({
      path: "/delete/student/" + id,
      method: "delete",
      token,
    });
    if (response.status == 200) {
      return response.data;
    } else {
      return false;
    }
  } catch (error) {
    throw error;
  }
}
export async function deleteFacuty({ token, id }) {
  try {
    const response = await apiservice({
      path: "/delete/faculty/" + id,
      method: "delete",
      token,
    });
    if (response.status == 200) {
      return response.data;
    } else {
      return false;
    }
  } catch (error) {
    throw error;
  }
}
export async function deleteCourse({ token, id }) {
  try {
    const response = await apiservice({
      path: "/delete/course/" + id,
      method: "delete",
      token,
    });
    if (response.status == 200) {
      return response.data;
    } else {
      return false;
    }
  } catch (error) {
    throw error;
  }
}
export async function deleteRegis_couse({ token, id }) {
  try {
    const response = await apiservice({
      path: "/delete/regis_couse/" + id,
      method: "delete",
      token,
    });
    if (response.status == 200) {
      return response.data;
    } else {
      return false;
    }
  } catch (error) {
    throw error;
  }
}
export async function deleteAdmin({ token, id }) {
  try {
    const response = await apiservice({
      path: "/delete/admin/" + id,
      method: "delete",
      token,
    });
    if (response.status == 200) {
      return response.data;
    } else {
      return false;
    }
  } catch (error) {
    throw error;
  }
}
export async function deleteSlideheader1({ token, id }) {
  try {
    const response = await apiservice({
      path: "/delete/slideheader1/" + id,
      method: "delete",
      token,
    });
    if (response.status == 200) {
      return response.data;
    } else {
      return false;
    }
  } catch (error) {
    throw error;
  }
}
export async function deleteSlideheader2({ token, id }) {
  try {
    const response = await apiservice({
      path: "/delete/slideheader2/" + id,
      method: "delete",
      token,
    });
    if (response.status == 200) {
      return response.data;
    } else {
      return false;
    }
  } catch (error) {
    throw error;
  }
}
export async function deleteUnversity({ token, id }) {
  try {
    const response = await apiservice({
      path: "/delete/university/" + id,
      method: "delete",
      token,
    });
    if (response.status == 200) {
      return response.data;
    } else {
      return false;
    }
  } catch (error) {
    throw error;
  }
}
export async function deleteChat({ id }) {
  try {
    const response = await apiservice({
      path: "/course/deleteroom/" + id,
      method: "delete",
    });
    if (response.status == 200) {
      return response.data;
    } else {
      return false;
    }
  } catch (error) {
    throw error;
  }
}
export async function CreateContact({ state, token }) {
  try {
    const response = await apiservice({
      path: "/contact/createcontact",
      method: "post",
      token,
      body: state,
    });
    if (response.status == 200) {
      return response.data;
    } else {
      return false;
    }
  } catch (error) {
    throw error;
  }
}
export async function CreateInfor({ state, token }) {
  try {
    const response = await apiservice({
      path: "/info/createinfo",
      method: "post",
      token,
      body: state,
    });
    if (response.status == 200) {
      return response.data;
    } else {
      return false;
    }
  } catch (error) {
    throw error;
  }
}
export async function CreateDiscount({ state, token }) {
  try {
    const response = await apiservice({
      path: "/quiz/discount/create",
      method: "post",
      token,
      body: state,
    });
    if (response.status == 200) {
      return response.data;
    } else {
      return false;
    }
  } catch (error) {
    throw error;
  }
}
export async function UseCode({ state }) {
  try {
    const response = await apiservice({
      path: "/quiz/discount/usecode",
      method: "post",

      body: state,
    });
    if (response.status == 200) {
      return response.data;
    } else {
      return false;
    }
  } catch (error) {
    throw error;
  }
}
