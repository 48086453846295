import React, { useState } from "react";
import {
  View,
  Text,
  Image,
  Dimensions,
  Animated,
  TouchableOpacity,
  StyleSheet,
  ScrollView,
  TextInput,
  FlatList,
} from "react-native";
import {
  MaterialCommunityIcons,
  FontAwesome,
  FontAwesome5,
  MaterialIcons,
} from "@expo/vector-icons";
import { useWindowSize } from "../components/responsive";
const { width, height } = Dimensions.get("window");
export default function coursePage3({ route }) {
  const DATA = [
    { Q: "คอร์สนี้สอนเกี่ยวกับอะไร", A: "การนำพุทธศาสนามาใช้ในการแนะแนว" },
    { Q: "คอร์สนี้สอนเกี่ยวกับอะไร", A: "การนำพุทธศาสนามาใช้ในการแนะแนว" },
  ];

  const size = useWindowSize();
  return (
    <View style={{ minHeight: 500 }}>
      <FlatList
        data={route}
        numColumns={1}
        renderItem={({ index, item }) => {
          return (
            <View
              style={{
                borderBottomWidth: 1,
                borderBottomColor: "#BBB",
                paddingVertical: 28,
                paddingLeft: 20,
              }}
            >
              <Text
                style={{
                  fontSize: width < 750 ? 16 : 20,
                  fontFamily: "Prompt",
                  color: "#646464",
                }}
              >
                Q. {item.Q}
              </Text>
              <Text
                style={{
                  fontSize: width < 750 ? 16 : 20,
                  fontFamily: "Prompt",
                  color: "#646464",
                  marginTop: 10,
                }}
              >
                A. {item.A}
              </Text>
            </View>
          );
        }}
      />
    </View>
  );
}
