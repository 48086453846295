import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  Image,
  Dimensions,
  Animated,
  TouchableOpacity,
  StyleSheet,
  ScrollView,
  TextInput,
  FlatList,
} from "react-native";
import {
  MaterialCommunityIcons,
  MaterialIcons,
  Feather,
  Ionicons,
  FontAwesome,
  FontAwesome5,
} from "@expo/vector-icons";
import RNPickerSelect from "react-native-picker-select";
import Modal from "modal-react-native-web";
import { useRecoilState } from "recoil";
import { tokenState } from "../../../../reducer/recoil";
import { useIsFocused } from "@react-navigation/native";
import { apiservice } from "../../../../service";
import moment from "moment";
import { useWindowSize } from "../../../components/responsive";
const { width, height } = Dimensions.get("window");
export default function BillDetail({ ongoBack, onPress, items }) {
  const [DATA, setData] = useState(items.ALLDATA);
  const users = items.item;
  const size = useWindowSize();
  return (
    <View style={{ marginTop: 20 }}>
      <View style={{ flexDirection: "row" }}>
        <TouchableOpacity onPress={ongoBack}>
          <Text style={styles.text23}>การเงินรอบบิล</Text>
        </TouchableOpacity>
        <Text
          style={[
            styles.text23,
            {
              marginHorizontal: 18,
            },
          ]}
        >
          /
        </Text>
        <Text style={[styles.text23, { color: "#0D88F0" }]}>
          รายละเอียดรอบบิล
        </Text>
      </View>
      <View style={{ flexDirection: "row", marginTop: 30 }}>
        <Text style={styles.text18}>
          ชื่อผู้สอน :{" "}
          {users?.teacher?.user_accounts?.firstName +
            " " +
            users?.teacher?.user_accounts?.lastName}
        </Text>
        <Text style={[styles.text18, { marginLeft: width * 0.05 }]}>
          บิลรอบเดือน : {moment(users).format("MMMM")}
        </Text>
      </View>
      <ScrollView horizontal>
        <View
          style={{
            width:
              size.width < 500
                ? size.width * 2.5
                : size.width < 1280
                ? size.width * 1.0
                : size.width * 0.75,
            // minHeight: height * 2.5,
          }}
        >
          <View style={styles.viewSubject}>
            <Text
              style={[styles.text16B, { width: "10%", textAlign: "center" }]}
            >
              ลำดับ
            </Text>
            <Text style={[styles.text16B, { width: "20%" }]}>ชื่อคอร์ส</Text>
            <Text
              style={[styles.text16B, { width: "20%", textAlign: "center" }]}
            >
              ยอดเงินคงเหลือ
            </Text>
            <Text style={[styles.text16B, { width: "30%" }]}>บิลรอบเดือน</Text>
            <Text
              style={[styles.text16B, { width: "20%", textAlign: "center" }]}
            >
              รายชื่อผู้เรียน
            </Text>
          </View>
          <FlatList
            numColumns={1}
            data={DATA}
            renderItem={({ item, index }) => {
              return (
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    borderBottomWidth: 1,
                    borderBottomColor: "#E5E5E5",
                    height: 50,
                    alignItems: "center",
                  }}
                >
                  <Text
                    style={[
                      styles.text16B,
                      { width: "10%", textAlign: "center" },
                    ]}
                  >
                    {index + 1}
                  </Text>
                  <Text style={[styles.text16B, { width: "20%" }]}>
                    {item?.course?.title}
                  </Text>
                  <Text
                    style={[
                      styles.text16B,
                      { width: "20%", textAlign: "center" },
                    ]}
                  >
                    {item?.netAmount}
                  </Text>
                  <Text style={[styles.text16B, { width: "30%" }]}>
                    {moment(item.createdAt)
                      .add(543, "year")
                      .format("MMMM YYYY")}
                  </Text>
                  <TouchableOpacity
                    onPress={() => onPress(item)}
                    style={{ width: "20%", alignItems: "center" }}
                  >
                    <FontAwesome5
                      name="clipboard-list"
                      size={24}
                      color="black"
                    />
                  </TouchableOpacity>
                </View>
              );
            }}
          />
        </View>
      </ScrollView>
    </View>
  );
}
const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: "100%",
    backgroundColor: "#FFFFFF",
  },
  text23: {
    fontSize: 23,
    fontFamily: "Prompt",
    color: "#808D93",
  },
  text18: {
    fontSize: 18,
    fontFamily: "Prompt",
    color: "#272D3B",
  },
  text16B: {
    fontFamily: "Prompt",
    fontSize: 16,
    color: "#000000",
  },
  viewSubject: {
    width: "100%",
    height: 51,
    backgroundColor: "#E5E5E5",
    marginTop: 35,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
});
