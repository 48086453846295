import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  Image,
  Dimensions,
  TouchableOpacity,
  StyleSheet,
  TextInput,
  FlatList,
  ScrollView,
} from "react-native";
import {
  MaterialCommunityIcons,
  MaterialIcons,
  Ionicons,
} from "@expo/vector-icons";
import { useRecoilState } from "recoil";
import {
  pageWork,
  registerState,
  tokenState,
  userState,
} from "../../reducer/recoil";
import { apiservice } from "../../service";
import { useWindowSize } from "./responsive";
import Modal from "modal-react-native-web";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import GoogleLogin from "react-google-login";
// import {
//   login,
//   LoginPermission,
//   BotPrompt,
// } from 'expo-line-login';

const { width, height } = Dimensions.get("window");
import {
  authActionLogin,
  authActionRating,
  authActionRegister,
  authActionStartCourseCreate,
} from "../../action/authAction";
export default function header({
  registration,
  navigation,
  unShadow,
  login,
  headertext,
}) {
  const [page, setPage] = useRecoilState(pageWork);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [userName, setUserName] = useState("");
  const [imgProfile, setImgProfile] = useState("");
  const [token, setToken] = useRecoilState(tokenState);
  const [users, setUser] = useRecoilState(userState);
  const [profile, setProfile] = useState(false);
  const [auth, setAuth] = useState(false);
  const [search, setSearch] = useState("");
  const [modalLogin, setModalLogin] = useState(false);
  const [confirmPass, setConfirmPass] = useState("");
  const [checkUser, setCheckUser] = useState(false);
  const [checkEmail, setCheckEmail] = useState(false);
  const [checkPass, setCheckPass] = useState(false);
  const [checkText, setCheckText] = useState(false);
  const [password, setPassword] = useState(false);
  const [modalError, setModalError] = useState(false);
  const [modalMenu, setModalMenu] = useState(false);
  const [check, setCheck] = useState(false);
  const [check1, setCheck1] = useState(false);
  const [dataRegis, setDataRegis] = useRecoilState(registerState);
  const [pageModal, setPageModal] = useState(0);
  const [status, setStatus] = useState();
  const [state, setState] = useState({
    username: "",
    password: "",
  });
  const [state1, setState1] = useState({
    role: "USER",
    sub_role: "MAIN",
    username: "",
    email: "",
    password: "",
  });
  const emailValid =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  function errors({ value, type }) {
    if (type == "email") {
      return emailValid.test(value);
    }
  }
  async function onPress() {
    if (state.username.length > 0 && state.password.length > 0) {
      const login = await authActionLogin(state);
      setToken(login);
      if (login) {
        setStatus();
        setModalLogin(!modalLogin);
        // navigation.navigate("Lesson");
      }
      if (!login) {
        setStatus(login);
      }
    }
    if (state.username == "" || state.password == "") {
      setStatus(false);
    }
  }
  async function onPress1() {
    if (
      state1.username.length > 0 &&
      state1.email.length > 0 &&
      state1.password.length > 0 &&
      errors({ value: state1.email, type: "email" })
    ) {
      if (state1.password != confirmPass) {
        setPassword(true);
      }
      if (state1.password.length >= 8) {
        if (state1.password == confirmPass) {
          const register = await authActionRegister(state1);

          if (register) {
            setStatus();
            setPassword(false);
            setState1({
              role: "USER",
              sub_role: "MAIN",
              username: "",
              email: "",
              password: "",
            });
            setConfirmPass("");
            setCheck1(false);
            setPageModal(0);
          }
          if (!register) {
            setModalError(true);
            setStatus(register);
          }
        }
      }
    }
    if (state1.username.length == 0) {
      setCheckUser(true);
    }
    if (state1.email.length == 0) {
      setCheckEmail(true);
    }
    if (state1.password.length == 0) {
      setCheckPass(true);
    }
  }
  const responseFacebook = async (response) => {
    const res = await apiservice({
      path: "/authen/loginfacebook",
      method: "POST",
      body: {
        telephoneNo: null,
        image_url: response?.picture?.data?.url,
        firstName: response?.name?.split(" ")[0],
        lastName: response?.name?.split(" ")[1],
        email: response?.email,
        password: response?.id,
        gender: null,
        birthday: null,
        username: response?.id,
        Type: "FACEBOOK",
      },
    });

    setToken(res.data);

    if (res.data) {
      setStatus();
      setModalLogin(false);
      navigation.navigate("Home");
    }
  };
  const responseGoogle = async (response) => {
    const res = await apiservice({
      path: "/authen/logingoogle",
      method: "POST",
      body: {
        telephoneNo: null,
        image_url: response.profileObj.imageUrl,
        firstName: response.profileObj.givenName,
        lastName: response.profileObj.familyName,
        email: response.profileObj.email,
        password: response.profileObj.googleId,
        gender: null,
        birthday: null,
        username: response.profileObj.googleId,
        Type: "GOOGLE",
      },
    });

    setToken(res.data);

    if (res.data) {
      setStatus();
      setModalLogin(false);
      navigation.navigate("Home");
    }
  };
  function Logout() {
    setTimeout(() => {
      setToken("");
      setAuth(false);
      setProfile(false);
      window.location.replace("Home");
    }, 800);
  }

  useEffect(() => {
    if (token) {
      getProfile(token);
    }
  }, [token]);

  async function getProfile(token) {
    try {
      const response = await apiservice({
        path: "/authen/user",
        method: "get",
        token: token?.accessToken,
      });

      if (response.status == 200) {
        setAuth(true);
        setUser(response.data.result.user_accounts);
        setFirstName(
          response.data !== undefined &&
          response.data.result.user_accounts.firstName
        );
        setLastName(
          response.data !== undefined &&
          response.data.result.user_accounts.lastName
        );
        setUserName(
          response.data !== undefined && response.data.result.username
        );
        setImgProfile(
          response.data !== undefined &&
          response.data.result.user_accounts.image_url
        );
      } else if (response.status == 403) {
        const refresh = await apiservice({
          path: "/authen/refresh",
          method: "post",
          body: {
            refreshToken: token?.refreshToken,
          },
        });
        if (refresh.status == 200) {
          setToken({ ...token, accessToken: refresh.data });
        }
      }
    } catch (error) {
      throw error;
    }
  }
  async function getSearch() {
    try {
      const response = await apiservice({
        path: "/course/getsearch?page=1&lan=TH&totalspage=10&search=" + search,
        method: "get",
      });
      if (response.status == 200) {
        navigation.push("AllCourse", response.data);
      } else {
      }
    } catch (error) {
      throw error;
    }
  }
  const size = useWindowSize();
  return (
    <View
      style={[
        styles.container,
        {
          shadowOpacity: !unShadow && 0.7,
          height: size.width < 800 ? 80 : 55,
          paddingHorizontal:
            size.width < 1000 ? 20 : size.width < 1440 ? 30 : 50,
        },
      ]}
    >
      {size.width < 800 && (
        <TouchableOpacity
          onPress={() => {
            navigation.navigate("Home");
          }}
          style={{}}
        >
          <Image
            resizeMode="stretch"
            style={{ width: 69, height: 45 }}
            source={require("../img/logo.png")}
          />
        </TouchableOpacity>
      )}
      {size.width >= 800 && (
        <TouchableOpacity
          onPress={() => {
            navigation.navigate("Home");
          }}
          style={styles.imgLogo}
        >
          <Image
            resizeMode="stretch"
            style={styles.imgLogo}
            source={require("../img/logo.png")}
          />
        </TouchableOpacity>
      )}
      <View
        style={{
          width: size.width < 1280 ? "50%" : "75%",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        {!registration && (
          <View
            style={[
              styles.viewSearch,
              {
                width:
                  size.width < 1280
                    ? "100%"
                    : size.width < 1440
                      ? "20%"
                      : "25%",
              },
            ]}
          >
            {/* {size.width >= 1000 && (
              <TouchableOpacity
                onPress={() => {
                  navigation.navigate("AllCourse");
                }}
                style={styles.buttonCategory}
              >
                <Image
                  style={styles.imgBar}
                  source={require("../img/ic_toc_24px.png")}
                />
                <Text style={styles.text}>CATEGORY</Text>
              </TouchableOpacity>
            )} */}
            <TextInput
              autoComplete="off"
              onChangeText={(text) => {
                setSearch(text);
              }}
              onSubmitEditing={getSearch}
              style={[
                styles.inputSearch,
                {
                  height: size.width < 800 ? 30 : 30,
                },
              ]}
            />
            <TouchableOpacity
              onPress={() => {
                getSearch();
              }}
              style={[
                styles.buttonSearch,
                {
                  height: size.width < 800 ? 30 : 30,
                  width: size.width < 800 ? 30 : 30,
                },
              ]}
            >
              <MaterialCommunityIcons
                name="magnify"
                size={size.width < 800 ? 20 : 20}
                color="#FFFFFF"
              />
            </TouchableOpacity>
          </View>
        )}
        {size.width < 1280 && (
          <View
            style={{
              width: size.width < 500 ? "40%" : "15%",
            }}
          >
            <TouchableOpacity
              onPress={() => {
                setModalMenu(true);
              }}
              style={{
                marginLeft: -15,
                alignSelf: size.width < 500 ? "flex-start" : "flex-end",
              }}
            >
              <Ionicons name="menu" size={30} color="black" />
            </TouchableOpacity>
          </View>
        )}
        {size.width >= 1280 && (
          <View
            style={{
              width: size.width < 1440 ? "78%" : "70%",
            }}
          >
            {!headertext && size.width >= 1280 && (
              <FlatList
                data={[
                  { title: "คอร์สทั้งหมด", onPress: "AllCourse" },
                  { title: "มหาวิทยาลัยที่เข้าร่วม", onPress: "University" },
                  { title: "หลักสูตรเก็บหน่วยกิต", onPress: "" },
                  { title: "เกี่ยวกับเรา", onPress: "AboutMe" },
                  { title: "ร่วมงานกับเรา", onPress: "JoinMe" },
                ]}
                horizontal
                showsHorizontalScrollIndicator={false}
                renderItem={({ index, item }) => {
                  return (
                    <TouchableOpacity
                      onPress={() => {
                        navigation.navigate(item.onPress);
                      }}
                      style={{ marginLeft: size.width > 1850 ? 30 : 20 }}
                    >
                      <Text
                        style={[
                          styles.textButton1,
                          {
                            fontSize: size.width < 1520 ? 18 : 18,
                            color: "#434343",
                          },
                        ]}
                      >
                        {item.title}
                      </Text>
                    </TouchableOpacity>
                  );
                }}
              />
            )}
          </View>
        )}
      </View>
      <View
        style={{
          flexDirection: "row",

          marginLeft: 40,
          alignItems: "center",
        }}
      >
        {!auth && !login && (
          <TouchableOpacity
            onPress={() => {
              setModalLogin(true);
            }}
            style={[
              styles.buttonLogin,
              {
                width: size.width < 800 ? 60 : 145,
                height: size.width < 800 ? 30 : 30,
                marginLeft: size.width < 800 && 20,
              },
            ]}
          >
            <Text
              style={[
                styles.textLogin,
                { fontSize: size.width < 800 ? 12 : 18 },
              ]}
            >
              เข้าสู่ระบบ
            </Text>
          </TouchableOpacity>
        )}
        {size.width >= 1700 && !login && !auth && (
          <TouchableOpacity
            onPress={() => {
              setPageModal(1);
              setModalLogin(true);
            }}
            style={[styles.buttonRegister]}
          >
            <Text style={styles.textRegister}>สมัครสมาชิก</Text>
          </TouchableOpacity>
        )}
      </View>
      {token?.role == "Teacher" ||
        (token?.role == "SCHOOL" && (
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <TouchableOpacity
              onPress={() => {
                setProfile((val) => !val);
              }}
              style={{ flexDirection: "row", alignItems: "center" }}
            >
              {size.width >= 1280 && (
                <View style={{ flexDirection: "row" }}>
                  <Text style={styles.text}>
                    {firstName == null ? userName : firstName}{" "}
                    {lastName == null ? "" : lastName}
                  </Text>
                  <MaterialIcons
                    name={profile ? "arrow-drop-up" : "arrow-drop-down"}
                    size={30}
                    color="#0D88F0"
                  />
                </View>
              )}
              <Image
                style={{
                  width: size.width < 800 ? 40 : 40,
                  height: size.width < 800 ? 40 : 40,
                  borderRadius: 35,
                  backgroundColor: "#CCCCCC",
                }}
                source={{
                  uri: imgProfile.includes("https://")
                    ? imgProfile
                    : "https://api.courseoncloud.com/api/image/getimage/" +
                    imgProfile,
                }}
              />
            </TouchableOpacity>
            {profile && (
              <View
                style={{
                  width: size.width < 1280 ? 110 : 180,
                  height: 60,
                  borderWidth: 1,
                  borderColor: "#0D88F0",
                  position: "absolute",
                  backgroundColor: "#FFFFFF",
                  marginTop: size.width < 1280 ? height * 0.1 : 100,
                  zIndex: 99,
                  marginLeft: size.width < 1280 && -40,
                  right: 0,
                }}
              >
                <TouchableOpacity
                  onPress={() => {
                    setPage(0);
                    setProfile(false);
                    navigation.navigate("Profile");
                  }}
                  style={{ height: 30 }}
                >
                  <Text
                    style={[
                      styles.textProfile,
                      { fontSize: size.width < 1280 ? 14 : 18 },
                    ]}
                  >
                    โปรไฟล์
                  </Text>
                </TouchableOpacity>
                <TouchableOpacity onPress={Logout} style={{ height: 30 }}>
                  <Text
                    style={[
                      styles.textProfile,
                      { fontSize: size.width < 1280 ? 14 : 18 },
                    ]}
                  >
                    ออกจากระบบ
                  </Text>
                </TouchableOpacity>
              </View>
            )}
          </View>
        ))}
      {token?.role == "TEACHER" && (
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <TouchableOpacity
            onPress={() => {
              setProfile((val) => !val);
            }}
            style={{ flexDirection: "row", alignItems: "center" }}
          >
            {size.width >= 1280 && (
              <View style={{ flexDirection: "row" }}>
                <Text style={styles.text}>
                  {firstName == null ? userName : firstName}{" "}
                  {lastName == null ? "" : lastName}
                </Text>
                <MaterialIcons
                  name={profile ? "arrow-drop-up" : "arrow-drop-down"}
                  size={30}
                  color="#0D88F0"
                />
              </View>
            )}
            <Image
              style={{
                width: size.width < 800 ? 40 : 40,
                height: size.width < 800 ? 40 : 40,
                borderRadius: 35,
                backgroundColor: "#CCCCCC",
              }}
              source={{
                uri: imgProfile.includes("https://")
                  ? imgProfile
                  : "https://api.courseoncloud.com/api/image/getimage/" +
                  imgProfile,
              }}
            />
          </TouchableOpacity>
          {profile && (
            <View
              style={{
                width: size.width < 1280 ? 110 : 180,
                height: 60,
                borderWidth: 1,
                borderColor: "#0D88F0",
                position: "absolute",
                backgroundColor: "#FFFFFF",
                marginTop: size.width < 1280 ? height * 0.1 : 100,
                zIndex: 99,
                marginLeft: size.width < 1280 && -40,
                right: 0,
              }}
            >
              <TouchableOpacity
                onPress={() => {
                  setProfile(false);
                  navigation.navigate("Profile");
                }}
                style={{ height: 30 }}
              >
                <Text
                  style={[
                    styles.textProfile,
                    { fontSize: size.width < 1280 ? 14 : 18 },
                  ]}
                >
                  โปรไฟล์
                </Text>
              </TouchableOpacity>
              <TouchableOpacity onPress={Logout} style={{ height: 30 }}>
                <Text
                  style={[
                    styles.textProfile,
                    { fontSize: size.width < 1280 ? 14 : 18 },
                  ]}
                >
                  ออกจากระบบ
                </Text>
              </TouchableOpacity>
            </View>
          )}
        </View>
      )}
      {token?.role == "USER" && (
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <TouchableOpacity
            onPress={() => {
              setProfile((val) => !val);
            }}
            style={{ flexDirection: "row", alignItems: "center" }}
          >
            {size.width >= 1280 && (
              <View style={{ flexDirection: "row" }}>
                <Text style={styles.text}>
                  {firstName == null ? userName : firstName}{" "}
                  {lastName == null ? "" : lastName}
                </Text>
                <MaterialIcons
                  name={profile ? "arrow-drop-up" : "arrow-drop-down"}
                  size={30}
                  color="#0D88F0"
                />
              </View>
            )}
            <Image
              style={{
                width: size.width < 800 ? 40 : 40,
                height: size.width < 800 ? 40 : 40,
                borderRadius: 35,
                backgroundColor: "#CCCCCC",
              }}
              source={{
                uri: imgProfile.includes("https://")
                  ? imgProfile
                  : "https://api.courseoncloud.com/api/image/getimage/" +
                  imgProfile,
              }}
            />
          </TouchableOpacity>
          {profile && (
            <View
              style={{
                width: size.width < 1280 ? 110 : 180,
                height: 60,
                borderWidth: 1,
                borderColor: "#0D88F0",
                position: "absolute",
                backgroundColor: "#FFFFFF",
                marginTop: size.width < 1280 ? height * 0.1 : 100,
                zIndex: 99,
                marginLeft: size.width < 1280 && -40,
                right: 0,
              }}
            >
              <TouchableOpacity
                onPress={() => {
                  setProfile(false);
                  navigation.navigate("ProfileStudent");
                }}
                style={{ height: 30 }}
              >
                <Text
                  style={[
                    styles.textProfile,
                    { fontSize: size.width < 1280 ? 14 : 18 },
                  ]}
                >
                  โปรไฟล์
                </Text>
              </TouchableOpacity>
              <TouchableOpacity onPress={Logout} style={{ height: 30 }}>
                <Text
                  style={[
                    styles.textProfile,
                    { fontSize: size.width < 1280 ? 14 : 18 },
                  ]}
                >
                  ออกจากระบบ
                </Text>
              </TouchableOpacity>
            </View>
          )}
        </View>
      )}
      {token?.role == "ACCOUNTING" && (
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <TouchableOpacity
            onPress={() => {
              setProfile((val) => !val);
            }}
            style={{ flexDirection: "row" }}
          >
            <Text style={styles.text}>
              {firstName == null ? userName : firstName}{" "}
              {lastName == null ? "" : lastName}
            </Text>
            <MaterialIcons
              name={profile ? "arrow-drop-up" : "arrow-drop-down"}
              size={30}
              color="#0D88F0"
            />
          </TouchableOpacity>
          {profile && (
            <View
              style={{
                width: size.width < 1280 ? 130 : 180,
                height: 60,
                borderWidth: 1,
                borderColor: "#0D88F0",
                position: "absolute",
                backgroundColor: "#FFFFFF",
                marginTop: height * 0.1,
                zIndex: 99,
                right:
                  size.width < 500 ? size.width * 0.2 : size.width < 1280 && 0,
              }}
            >
              <TouchableOpacity
                onPress={() => {
                  setProfile(false);
                  navigation.navigate("UniversityCourseSA");
                }}
                style={{ height: 30 }}
              >
                <Text style={styles.textProfile}>จัดการข้อมูล</Text>
              </TouchableOpacity>
              <TouchableOpacity onPress={Logout} style={{ height: 30 }}>
                <Text style={styles.textProfile}>ออกจากระบบ</Text>
              </TouchableOpacity>
            </View>
          )}
          <Image
            style={{ width: 70, height: 70, borderRadius: 35 }}
            source={{
              uri: imgProfile.includes("https://")
                ? imgProfile
                : "https://api.courseoncloud.com/api/image/getimage/" +
                imgProfile,
            }}
          />
        </View>
      )}
      {token?.role == "GENERALADMIN" && (
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <TouchableOpacity
            onPress={() => {
              setProfile((val) => !val);
            }}
            style={{ flexDirection: "row" }}
          >
            <Text style={styles.text}>
              {firstName == null ? userName : firstName}{" "}
              {lastName == null ? "" : lastName}
            </Text>
            <MaterialIcons
              name={profile ? "arrow-drop-up" : "arrow-drop-down"}
              size={30}
              color="#0D88F0"
            />
          </TouchableOpacity>
          {profile && (
            <View
              style={{
                width: size.width < 1280 ? 130 : 180,
                height: 60,
                borderWidth: 1,
                borderColor: "#0D88F0",
                position: "absolute",
                backgroundColor: "#FFFFFF",
                marginTop: height * 0.1,
                zIndex: 99,
                right:
                  size.width < 500 ? size.width * 0.2 : size.width < 1280 && 0,
              }}
            >
              <TouchableOpacity
                onPress={() => {
                  setProfile(false);
                  navigation.navigate("UniversityCourseSA");
                }}
                style={{ height: 30 }}
              >
                <Text style={styles.textProfile}>จัดการข้อมูล</Text>
              </TouchableOpacity>
              <TouchableOpacity onPress={Logout} style={{ height: 30 }}>
                <Text style={styles.textProfile}>ออกจากระบบ</Text>
              </TouchableOpacity>
            </View>
          )}
          <Image
            style={{ width: 70, height: 70, borderRadius: 35 }}
            source={{
              uri: imgProfile.includes("https://")
                ? imgProfile
                : "https://api.courseoncloud.com/api/image/getimage/" +
                imgProfile,
            }}
          />
        </View>
      )}
      {token?.role == "SUPERADMIN" && (
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <TouchableOpacity
            onPress={() => {
              setProfile((val) => !val);
            }}
            style={{ flexDirection: "row", alignItems: "center" }}
          >
            <Text
              style={[styles.text, { fontSize: size.width < 1280 ? 14 : 18 }]}
            >
              {firstName == null ? userName : firstName}{" "}
              {lastName == null ? "" : lastName}
            </Text>
            <MaterialIcons
              name={profile ? "arrow-drop-up" : "arrow-drop-down"}
              size={size.width < 1280 ? 0 : 30}
              color="#0D88F0"
            />
          </TouchableOpacity>
          {profile && (
            <View
              style={{
                width: size.width < 1280 ? 130 : 180,
                height: 60,
                borderWidth: 1,
                borderColor: "#0D88F0",
                position: "absolute",
                backgroundColor: "#FFFFFF",
                marginTop: height * 0.1,
                zIndex: 99,
                right:
                  size.width < 500 ? size.width * 0.2 : size.width < 1280 && 0,
              }}
            >
              <TouchableOpacity
                onPress={() => {
                  setProfile(false);
                  navigation.navigate("UniversityCourseSA");
                }}
                style={{ height: 30 }}
              >
                <Text style={styles.textProfile}>จัดการข้อมูล</Text>
              </TouchableOpacity>
              <TouchableOpacity onPress={Logout} style={{ height: 30 }}>
                <Text style={styles.textProfile}>ออกจากระบบ</Text>
              </TouchableOpacity>
            </View>
          )}
          <Image
            style={{ width: 70, height: 70, borderRadius: 35 }}
            source={{
              uri: imgProfile.includes("https://")
                ? imgProfile
                : "https://api.courseoncloud.com/api/image/getimage/" +
                imgProfile,
            }}
          />
        </View>
      )}
      {token?.role == "ADMIN" && (
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <TouchableOpacity
            onPress={() => {
              setProfile((val) => !val);
            }}
            style={{ flexDirection: "row" }}
          >
            <Text style={styles.text}>
              {firstName == null ? userName : firstName}{" "}
              {lastName == null ? "" : lastName}
            </Text>
            <MaterialIcons
              name={profile ? "arrow-drop-up" : "arrow-drop-down"}
              size={30}
              color="#0D88F0"
            />
          </TouchableOpacity>
          {profile && (
            <View
              style={{
                width: size.width < 1280 ? 130 : 180,
                height: 60,
                borderWidth: 1,
                borderColor: "#0D88F0",
                position: "absolute",
                backgroundColor: "#FFFFFF",
                marginTop: height * 0.1,
                zIndex: 99,
                right:
                  size.width < 500 ? size.width * 0.2 : size.width < 1280 && 0,
              }}
            >
              <TouchableOpacity
                onPress={() => {
                  setProfile(false);
                  navigation.navigate("Admin");
                }}
                style={{ height: 30 }}
              >
                <Text style={styles.textProfile}>จัดการข้อมูล</Text>
              </TouchableOpacity>
              <TouchableOpacity onPress={Logout} style={{ height: 30 }}>
                <Text style={styles.textProfile}>ออกจากระบบ</Text>
              </TouchableOpacity>
            </View>
          )}
          <Image
            style={{ width: 70, height: 70, borderRadius: 35 }}
            source={{
              uri: imgProfile.includes("https://")
                ? imgProfile
                : "https://api.courseoncloud.com/api/image/getimage/" +
                imgProfile,
            }}
          />
        </View>
      )}
      <Modal
        transparent={true}
        visible={modalLogin}
        onRequestClose={() => {
          Alert.alert("Modal has been closed.");
          setModalLogin(!modalLogin);
        }}
      >
        <ScrollView style={{ flex: 1, backgroundColor: "#000000bb" }}>
          <View
            style={[styles.bgModal, { paddingVertical: size.width * 0.075 }]}
          >
            <TouchableOpacity
              onPress={() => {
                setModalLogin(false);
              }}
              style={{
                position: "fixed",
                top: "0px",
                right: "0px",
                bottom: "0px",
                left: "0px",
              }}
            />
            <View
              style={{
                width: size.width < 1280 ? size.width * 0.8 : size.width * 0.3,

                alignSelf: "center",
                justifyContent: "center",
                backgroundColor: "transparent",
                paddingBottom: 20,
                zIndex: 99,
              }}
            >
              <View style={{ flexDirection: "row" }}>
                <TouchableOpacity
                  onPress={() => {
                    setPageModal(0);
                  }}
                  style={{
                    height: 60,
                    alignItems: "center",
                    justifyContent: "center",
                    width:
                      size.width < 1280
                        ? size.width * 0.415
                        : size.width * 0.165,

                    borderBottomWidth: 60,
                    borderBottomColor: pageModal == 0 ? "#0D88F0" : "#FFF",

                    backgroundColor: "transparent",

                    borderRightWidth: 60,
                    borderRightColor: "transparent",
                    borderStyle: "solid",
                    zIndex: pageModal == 0 ? 99 : 1,
                  }}
                >
                  <Text
                    style={{
                      fontSize: size.width < 1280 ? 18 : 30,
                      fontFamily: "Prompt",
                      color: pageModal == 0 ? "#FFF" : "#646464",
                      marginTop: 60,
                      // marginLeft: index == 1 && -size.width * 0.03,
                    }}
                  >
                    {"เข้าสู่ระบบ"}
                  </Text>
                </TouchableOpacity>
                <TouchableOpacity
                  onPress={() => {
                    setPageModal(1);
                  }}
                  style={{
                    height: 60,
                    alignItems: "center",
                    justifyContent: "center",
                    width:
                      size.width < 1280
                        ? size.width * 0.415
                        : size.width * 0.165,

                    borderBottomWidth: 60,
                    borderBottomColor: pageModal == 1 ? "#0D88F0" : "#FFF",
                    borderLeftWidth: 50,
                    borderLeftColor: "transparent",
                    backgroundColor: "transparent",
                    marginLeft: -size.width * 0.03,
                    borderStyle: "solid",
                    zIndex: pageModal == 1 ? 99 : 1,
                  }}
                >
                  <Text
                    style={{
                      fontSize: size.width < 1280 ? 18 : 30,
                      fontFamily: "Prompt",
                      color: pageModal == 1 ? "#FFF" : "#646464",
                      marginTop: 60,
                      // marginLeft: index == 1 && -size.width * 0.03,
                    }}
                  >
                    {"สมัครสมาชิก"}
                  </Text>
                </TouchableOpacity>
              </View>
              {pageModal == 0 && (
                <View
                  style={{
                    paddingHorizontal: 50,
                    backgroundColor: "#FFF",
                    paddingVertical: 60,
                  }}
                >
                  <Text
                    style={[
                      styles.text18,
                      { fontSize: size.width < 1280 ? 18 : 25 },
                    ]}
                  >
                    ชื่อผู้ใช้งาน
                  </Text>
                  <TextInput
                    autoComplete="off"
                    defaultValue={state.username}
                    onChangeText={(text) =>
                      setState({ ...state, username: text })
                    }
                    style={[
                      styles.text18,
                      styles.inputLoginModal,
                      { borderColor: status == false ? "red" : "#EEEEEE" },
                    ]}
                  />
                  <Text
                    style={[
                      styles.text18,
                      { marginTop: 24, fontSize: size.width < 1280 ? 18 : 25 },
                    ]}
                  >
                    รหัสผ่าน
                  </Text>
                  <TextInput
                    autoComplete="off"
                    defaultValue={state.password}
                    onChangeText={(text) =>
                      setState({ ...state, password: text })
                    }
                    secureTextEntry
                    style={[
                      styles.text18,
                      styles.inputLoginModal,
                      { borderColor: status == false ? "red" : "#EEEEEE" },
                    ]}
                  />
                  {status == false && (
                    <Text
                      style={{
                        fontSize: 14,
                        fontFamily: "Prompt",
                        color: "red",
                      }}
                    >
                      ชื่อผู้ใช้งานหรือรหัสผ่านไม่ถูกต้อง
                    </Text>
                  )}
                  <TouchableOpacity
                    onPress={() => {
                      setCheck((val) => !val);
                    }}
                    style={{
                      flexDirection: "row",
                      marginTop: 15,
                      width: "50%",
                      alignItems: "center",
                    }}
                  >
                    <MaterialCommunityIcons
                      name={
                        check ? "checkbox-marked" : "checkbox-blank-outline"
                      }
                      size={24}
                      color="#eee"
                    />
                    <Text
                      style={[
                        styles.text18,
                        {
                          marginLeft: 10,
                          fontSize: size.width < 1280 ? 12 : 18,
                        },
                      ]}
                    >
                      จำชื่อผู้ใช้
                    </Text>
                  </TouchableOpacity>
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <TouchableOpacity
                      onPress={() => {
                        setCheck1((val) => !val);
                      }}
                      style={{
                        flexDirection: "row",
                        marginTop: 15,
                        width: "50%",
                        alignItems: "center",
                      }}
                    >
                      <MaterialCommunityIcons
                        name={
                          check1 ? "checkbox-marked" : "checkbox-blank-outline"
                        }
                        size={24}
                        color="#eee"
                      />
                      <Text
                        style={[
                          styles.text18,
                          {
                            marginLeft: 10,
                            fontSize: size.width < 1280 ? 12 : 18,
                          },
                        ]}
                      >
                        จำชื่อรหัสผ่าน
                      </Text>
                    </TouchableOpacity>
                    <TouchableOpacity
                      onPress={() => {
                        setModalLogin(!modalLogin);
                        navigation.navigate("NewPassword");
                      }}
                    >
                      <Text
                        style={[
                          styles.text18,
                          {
                            textDecorationLine: "underline",
                            marginTop: 15,
                            fontSize: size.width < 1280 ? 12 : 18,
                          },
                        ]}
                      >
                        ลืมรหัสผ่าน?
                      </Text>
                    </TouchableOpacity>
                  </View>
                  <TouchableOpacity
                    onPress={onPress}
                    style={{
                      width: "100%",

                      height: 40,
                      backgroundColor: "#0D88F0",
                      borderRadius: 5,
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: 21,
                      alignSelf: "center",
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 20,
                        fontFamily: "Prompt",
                        color: "#FFFFFF",
                      }}
                    >
                      เข้าสู่ระบบ
                    </Text>
                  </TouchableOpacity>
                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      marginTop: 30,

                      justifyContent: "space-between",
                    }}
                  >
                    <View
                      style={{
                        height: 1,
                        backgroundColor: "#707070",
                        width: "30%",
                      }}
                    />
                    <Text
                      style={{
                        fontSize: size.width < 1280 ? 12 : 20,
                        fontFamily: "Prompt",
                        color: "#707070",
                        marginHorizontal: 10,
                        textAlign: "center",
                      }}
                    >
                      เข้าสู่ระบบผ่าน
                    </Text>
                    <View
                      style={{
                        height: 1,
                        backgroundColor: "#707070",
                        width: "30%",
                      }}
                    />
                  </View>

                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <TouchableOpacity style={[
                      styles.button,
                      { marginTop: size.width < 800 ? 20 : 20 },
                    ]} onPress={() => {
                      const channelId = '1656890076';
                      const callbackUrl = 'https://api.courseoncloud.com/api/authen/line';
                      const state = '123abc';
                      const lineLoginUrl = `https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=${channelId}&redirect_uri=${encodeURIComponent(callbackUrl)}&state=${state}&scope=profile%20openid&nonce=09876xyz`
                      window.open(lineLoginUrl, '_blank');
                    }}>
                      <Image
                        style={[
                          styles.img,
                          {
                            width: size.width < 800 ? 20 : 30,
                            height: size.width < 800 ? 20 : 30,
                          },
                        ]}
                        source={require("../img/1024px-LINE_logo.svg_.png")}
                      />
                      <Text
                        style={[
                          styles.text,
                          {
                            fontSize: size.width < 1280 ? 14 : 20,
                            color: "#646464",
                          },
                        ]}
                      >
                        Line
                      </Text>
                    </TouchableOpacity>
                    <GoogleLogin
                      render={(renderProps) => (
                        <TouchableOpacity
                          onPress={renderProps.onClick}
                          style={[
                            styles.button,
                            { marginTop: size.width < 800 ? 20 : 20 },
                          ]}
                        >
                          <Image
                            style={[
                              styles.img,
                              {
                                width: size.width < 800 ? 20 : 30,
                                height: size.width < 800 ? 20 : 30,
                              },
                            ]}
                            source={require("../img/google_PNG19630.png")}
                          />
                          <Text
                            style={[
                              styles.text,
                              {
                                fontSize: size.width < 1280 ? 14 : 20,
                                color: "#646464",
                              },
                            ]}
                          >
                            Google
                          </Text>
                        </TouchableOpacity>
                      )}
                      clientId="648121112048-2bn9ranmpnpf2qkiocd31hkd27qkbaob.apps.googleusercontent.com"
                      buttonText="Login"
                      onSuccess={responseGoogle}
                      cookiePolicy={"single_host_origin"}
                    />
                  </View>
                </View>
              )}
              {pageModal == 1 && (
                <View
                  style={{
                    paddingHorizontal: 50,
                    backgroundColor: "#FFF",
                    paddingVertical: 60,
                  }}
                >
                  <View
                    style={{
                      width: "100%",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <View style={{ width: "45%" }}>
                      <Text style={styles.text18}>ชื่อผู้ใช้</Text>
                      <TextInput
                        autoComplete="off"
                        value={state1.username}
                        onChangeText={(text) => {
                          if (state1 != "") {
                            setCheckText(false);
                            setCheckUser(false);
                            setCheckEmail(false);
                            setCheckPass(false);
                          }
                          setState1({ ...state1, username: text });
                        }}
                        style={styles.textInput}
                      />
                      {checkText && (
                        <Text style={styles.text14R}>
                          กรุณากรอกข้อมูลให้ครบถ้วน
                        </Text>
                      )}
                      {checkUser && (
                        <Text style={styles.text14R}>
                          กรุณากรอกข้อมูลให้ครบถ้วน
                        </Text>
                      )}
                    </View>
                    <View style={{ width: "45%" }}>
                      <Text style={styles.text18}>อีเมล</Text>
                      <TextInput
                        autoComplete="off"
                        value={state1.email}
                        onChangeText={(text) => {
                          if (state != "") {
                            setCheckText(false);
                            setCheckUser(false);
                            setCheckEmail(false);
                            setCheckPass(false);
                          }
                          setState1({ ...state1, email: text });
                        }}
                        style={styles.textInput}
                      />
                      {state1.email.length > 0 &&
                        !errors({ value: state1.email, type: "email" }) && (
                          <Text style={styles.text14R}>
                            รูปแบบของอีเมลไม่ถูกต้อง
                          </Text>
                        )}
                      {checkText && (
                        <Text style={styles.text14R}>
                          กรุณากรอกข้อมูลให้ครบถ้วน
                        </Text>
                      )}
                      {checkEmail && (
                        <Text style={styles.text14R}>
                          กรุณากรอกข้อมูลให้ครบถ้วน
                        </Text>
                      )}
                    </View>
                  </View>
                  <View>
                    <View
                      style={{
                        width: "100%",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        marginTop: 30,
                      }}
                    >
                      <View style={{ width: "45%" }}>
                        <Text style={styles.text18}>รหัสผ่าน</Text>
                        <TextInput
                          autoComplete="off"
                          value={state1.password}
                          onChangeText={(text) => {
                            if (state1 != "") {
                              setCheckText(false);
                              setCheckUser(false);
                              setCheckEmail(false);
                              setCheckPass(false);
                            }
                            setState1({ ...state1, password: text });
                          }}
                          secureTextEntry
                          style={styles.textInput}
                        />
                        {state1.password.length > 0 &&
                          state1.password.length < 8 && (
                            <Text style={styles.text14R}>
                              ต้องมีรหัสผ่านอย่างน้อย 8 ตัว
                            </Text>
                          )}
                        {checkText && (
                          <Text style={styles.text14R}>
                            กรุณากรอกข้อมูลให้ครบถ้วน
                          </Text>
                        )}
                        {checkPass && (
                          <Text style={styles.text14R}>
                            กรุณากรอกข้อมูลให้ครบถ้วน
                          </Text>
                        )}
                      </View>
                      <View style={{ width: "45%" }}>
                        <Text style={styles.text18}>ยืนยันรหัสผ่าน</Text>
                        <TextInput
                          autoComplete="off"
                          value={confirmPass}
                          onChangeText={(text) => {
                            if (state1 != "") {
                              setCheckText(false);
                              setCheckUser(false);
                              setCheckEmail(false);
                              setCheckPass(false);
                            }
                            setConfirmPass(text);
                          }}
                          secureTextEntry
                          style={styles.textInput}
                        />
                        {password && (
                          <Text style={styles.text14R}>รหัสผ่านไม่ตรงกัน</Text>
                        )}
                        {checkText && (
                          <Text style={styles.text14R}>
                            กรุณากรอกข้อมูลให้ครบถ้วน
                          </Text>
                        )}
                        {checkPass && (
                          <Text style={styles.text14R}>
                            กรุณากรอกข้อมูลให้ครบถ้วน
                          </Text>
                        )}
                      </View>
                    </View>
                    <View
                      style={{
                        flexDirection: "row",

                        marginHorizontal: 10,
                        width: "100%",

                        alignSelf: "center",
                        marginTop: 30,
                      }}
                    >
                      <TouchableOpacity
                        onPress={() => {
                          setCheck1((val) => !val);
                        }}
                      >
                        <MaterialCommunityIcons
                          name={
                            check1
                              ? "checkbox-marked"
                              : "checkbox-blank-outline"
                          }
                          size={30}
                          color="#BBBBBB"
                        />
                      </TouchableOpacity>
                      <Text
                        style={{
                          fontSize: size.width < 500 ? 14 : 16,
                          fontFamily: "Prompt",
                          color: "#646464",
                          marginLeft: 20,
                        }}
                      >
                        ฉันอ่านและยอมรับ
                        <TouchableOpacity
                          onPress={() => {
                            setModalLogin(false);
                            navigation.navigate("LearnerConditions");
                          }}
                        >
                          <Text
                            style={{
                              color: "#E82626",
                              textDecoration: "underline #E82626",
                            }}
                          >
                            เงื่อนไขและข้อตกลง
                          </Text>
                        </TouchableOpacity>
                        ในการสมัครเป็นผู้เรียนกับCoC
                      </Text>
                    </View>
                  </View>
                  <TouchableOpacity
                    disabled={check1 ? false : true}
                    onPress={onPress1}
                    style={[
                      styles.button1,
                      {
                        backgroundColor: check1 ? "#0D88F0" : "#CCCCCC",
                      },
                    ]}
                  >
                    <Text style={styles.textButton}>สมัครสมาชิก</Text>
                  </TouchableOpacity>
                  <TouchableOpacity
                    disabled={check1 ? false : true}
                    onPress={() => {
                      if (
                        state1.username.length > 0 &&
                        state1.email.length > 0 &&
                        state1.password.length >= 8 &&
                        errors({ value: state1.email, type: "email" })
                      ) {
                        if (state1.password != confirmPass) {
                          setPassword(true);
                        } else {
                          setDataRegis(state1);

                          setState1({
                            role: "USER",
                            sub_role: "MAIN",
                            username: "",
                            email: "",
                            password: "",
                          });
                          setConfirmPass("");
                          setModalLogin(false);
                          setTimeout(() => {
                            navigation.navigate("Instructor");
                          }, 500);
                        }
                      }
                      if (state1.username.length == 0) {
                        setCheckUser(true);
                      }
                      if (state1.email.length == 0) {
                        setCheckEmail(true);
                      }
                      if (state1.password.length == 0) {
                        setCheckPass(true);
                      }
                    }}
                    style={[
                      styles.button1,
                      {
                        backgroundColor: check1 ? "#0D88F0" : "#CCCCCC",
                      },
                    ]}
                  >
                    <Text style={styles.textButton}>สมัครเป็นผู้สอน</Text>
                  </TouchableOpacity>
                  {/* <View
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      marginTop: 30,

                      justifyContent: "space-between",
                    }}
                  >
                    <View
                      style={{
                        height: 1,
                        backgroundColor: "#707070",
                        width: "30%",
                      }}
                    />
                    <Text
                      style={{
                        fontSize: 20,
                        fontFamily: "Prompt",
                        color: "#707070",
                        marginHorizontal: 10,
                      }}
                    >
                      สมัครผ่าน
                    </Text>
                    <View
                      style={{
                        height: 1,
                        backgroundColor: "#707070",
                        width: "30%",
                      }}
                    />
                  </View> */}
                  {/* <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <FacebookLogin
                      appId="1303789190134568"
                      fields="birthday,name,email,picture"
                      callback={responseFacebook}
                      render={(renderProps) => (
                        <TouchableOpacity
                          onPress={renderProps.onClick}
                          style={[
                            styles.button,
                            { marginTop: size.width < 800 ? 20 : 20 },
                          ]}
                        >
                          <Image
                            style={[
                              styles.img,
                              {
                                width: size.width < 800 ? 20 : 30,
                                height: size.width < 800 ? 20 : 30,
                              },
                            ]}
                            source={require("../img/1024px-Facebook_Logo_(2019).png")}
                          />
                          <Text
                            style={[
                              styles.text,
                              {
                                fontSize: size.width < 1280 ? 14 : 20,
                                color: "#646464",
                              },
                            ]}
                          >
                            Facebook
                          </Text>
                        </TouchableOpacity>
                      )}
                    />

                    <GoogleLogin
                      render={(renderProps) => (
                        <TouchableOpacity
                          onPress={renderProps.onClick}
                          style={[
                            styles.button,
                            { marginTop: size.width < 800 ? 20 : 20 },
                          ]}
                        >
                          <Image
                            style={[
                              styles.img,
                              {
                                width: size.width < 800 ? 20 : 30,
                                height: size.width < 800 ? 20 : 30,
                              },
                            ]}
                            source={require("../img/google_PNG19630.png")}
                          />
                          <Text
                            style={[
                              styles.text,
                              {
                                fontSize: size.width < 1280 ? 14 : 20,
                                color: "#646464",
                              },
                            ]}
                          >
                            Google
                          </Text>
                        </TouchableOpacity>
                      )}
                      clientId="648121112048-2bn9ranmpnpf2qkiocd31hkd27qkbaob.apps.googleusercontent.com"
                      buttonText="Login"
                      onSuccess={responseGoogle}
                      cookiePolicy={"single_host_origin"}
                    />
                  </View> */}
                </View>
              )}
            </View>
          </View>
        </ScrollView>
      </Modal>
      <Modal
        transparent={true}
        visible={modalError}
        onRequestClose={() => {
          Alert.alert("Modal has been closed.");
          setModalError(!modalError);
        }}
      >
        <View style={styles.bgModal}>
          <View
            style={{
              width: size.width < 1280 ? "80%" : "45%",
              height: 230,
              borderWidth: 1,
              borderColor: "#0D88F0",
              backgroundColor: "#FFFFFF",
              alignSelf: "center",
              alignItems: "center",
            }}
          >
            <TouchableOpacity
              onPress={() => {
                setModalError(!modalError);
              }}
              style={{
                position: "absolute",
                alignSelf: "flex-end",
                margin: 13,
                zIndex: 99,
              }}
            >
              <MaterialIcons name="clear" size={14} color="#0D88F0" />
            </TouchableOpacity>
            <View style={{ paddingVertical: 55, alignItems: "center" }}>
              <Text
                style={{
                  fontSize: size.width < 1280 ? 30 : 35,
                  fontFamily: "Prompt",
                  color: "red",
                }}
              >
                เกิดข้อผิดพลาด
              </Text>
              <Text
                style={{
                  fontSize: size.width < 1280 ? 22 : 35,
                  fontFamily: "Prompt",
                  color: "#0B2051",
                  textAlign: "center",
                }}
              >
                มีบัญชีผู้ใช้หรืออีเมลอยู่ในระบบแล้ว
              </Text>
            </View>
          </View>
        </View>
      </Modal>
      <Modal
        transparent={true}
        visible={modalMenu}
        onRequestClose={() => {
          Alert.alert("Modal has been closed.");
          setModalMenu(!modalMenu);
        }}
      >
        <View style={styles.bgModal}>
          <TouchableOpacity
            onPress={() => {
              setModalMenu(false);
            }}
            style={{
              position: "fixed",
              top: "0px",
              right: "0px",
              bottom: "0px",
              left: "0px",
            }}
          />
          <View
            style={{
              width: size.width < 500 ? "60%" : "40%",
              height: size.height,

              backgroundColor: "#FFFFFF",
              alignSelf: "flex-end",
            }}
          >
            <FlatList
              data={[
                { title: "คอร์สทั้งหมด", onPress: "AllCourse" },
                { title: "มหาวิทยาลัยที่เข้าร่วม", onPress: "University" },
                { title: "หลักสูตรเก็บหน่วยกิต", onPress: "" },
                { title: "เกี่ยวกับเรา", onPress: "AboutMe" },
                { title: "ร่วมงานกับเรา", onPress: "JoinMe" },
              ]}
              renderItem={({ index, item }) => {
                return (
                  <TouchableOpacity
                    onPress={() => {
                      setModalMenu(false);
                      navigation.navigate(item.onPress);
                    }}
                    style={{
                      borderBottomWidth: 1,
                      paddingVertical: 20,
                      marginHorizontal: 20,
                      borderColor: "#CCC",
                    }}
                  >
                    <Text
                      style={[
                        styles.textButton1,
                        { fontSize: size.width < 1520 ? 14 : 16 },
                      ]}
                    >
                      {item.title}
                    </Text>
                  </TouchableOpacity>
                );
              }}
            />
          </View>
        </View>
      </Modal>
    </View>
  );
}
const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: 145,
    backgroundColor: "#FFFFFF",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingHorizontal: width * 0.05,
    shadowColor: "#CCCC",
    shadowOffset: { width: -2, height: 4 },
    shadowRadius: 3,
  },
  imgLogo: {
    maxWidth: 69,
    width: "100%",
    maxHeight: 45,
    height: "100%",
  },
  imgBar: {
    width: 36.02,
    height: 28.78,
  },
  viewSearch: {
    flexDirection: "row",
    width: "25%",
    alignItems: "center",
    marginHorizontal: 20,
  },
  buttonCategory: {
    flexDirection: "row",
    marginRight: 37,
  },
  text: {
    fontSize: 18,
    fontFamily: "Prompt",
    color: "#0D88F0",
    marginLeft: 10,
  },
  inputSearch: {
    width: "100%",
    height: 63,
    backgroundColor: "#EEEEEE",
    borderTopLeftRadius: 3,
    borderBottomLeftRadius: 3,
    paddingHorizontal: 20,
    fontSize: 18,
    fontFamily: "Prompt",
  },
  buttonSearch: {
    width: 70,
    height: 63,
    backgroundColor: "#0D88F0",
    borderTopRightRadius: 3,
    borderBottomRightRadius: 3,
    justifyContent: "center",
    alignItems: "center",
  },
  buttonLogin: {
    width: 145,
    height: 60,
    backgroundColor: "#FFFFFF",
    borderWidth: 1,
    borderColor: "#D1DDE5",
    borderRadius: 5,
    alignItems: "center",
    justifyContent: "center",
  },
  textLogin: {
    fontSize: 18,
    fontFamily: "Prompt",
    color: "#0D88F0",
  },
  buttonRegister: {
    width: 145,
    height: 30,
    backgroundColor: "#0D88F0",
    borderWidth: 1,
    borderColor: "#0D88F0",
    borderRadius: 5,
    alignItems: "center",
    justifyContent: "center",
    marginLeft: 12,
  },
  textRegister: {
    fontSize: 18,
    fontFamily: "Prompt",
    color: "#FFFFFF",
  },
  textProfile: {
    borderBottomWidth: 1,
    borderBottomColor: "#0D88F0",
    fontSize: 18,
    fontFamily: "Prompt",
    color: "#0D88F0",
    textAlign: "center",
    marginHorizontal: 10,
  },
  textButton1: { fontSize: 14, fontFamily: "Prompt", color: "#646464" },
  viewUsername: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },
  textInput: {
    width: "100%",

    height: 40,
    borderWidth: 1,
    borderRadius: 5,
    borderColor: "#EEEEEE",
    fontSize: 16,
    fontFamily: "Prompt",
    color: "#646464",
    padding: 15,
    backgroundColor: "#EEEEEE",
  },
  text14R: {
    fontSize: 14,
    color: "red",
    fontFamily: "Prompt",
  },
  viewPassWord: {
    flexDirection: "row",
    marginVertical: 40,
    width: "100%",
    justifyContent: "center",
  },
  button1: {
    width: "100%",

    height: 40,
    backgroundColor: "#0D88F0",
    borderRadius: 3,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 25,
  },
  textButton: {
    fontSize: 20,
    fontFamily: "Prompt",
    color: "#FFFFFF",
  },
  text18: {
    fontSize: 16,
    fontFamily: "Prompt",
    color: "#646464",
  },
  inputLoginModal: {
    width: "100%",
    height: 60,
    borderWidth: 1,
    borderColor: "#FED3C4",
    paddingHorizontal: 15,
    backgroundColor: "#EEEEEE",
    borderRadius: 5,
  },
  button: {
    width: "45%",
    maxWidth: 500,
    height: 40,
    borderWidth: 1,
    borderColor: "#eee",
    borderRadius: 5,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    // marginTop: 20,
  },
  bgModal: {
    flex: 1,
  },
});
