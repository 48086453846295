import React, { useState } from "react";
import {
  View,
  Text,
  Image,
  TouchableOpacity,
  TextInput,
  StyleSheet,
} from "react-native";
import { useWindowSize } from "../components/responsive";
import Modal from "modal-react-native-web";
import Header from "../components/header";
import { apiservice } from "../../service";
export default function newPassword({ navigation }) {
  const [modal, setModal] = useState(false);
  const [body, setBody] = useState({ email: "" });

  const [modalError, setModalError] = useState(false);
  const size = useWindowSize();
  return (
    <View
      style={{
        width: "100%",
        height: "100%",
        backgroundColor: "#FFFFFF",
      }}
    >
      <View
        style={{
          borderWidth: 1,
          borderRadius: 5,
          width: size.width < 1280 ? "80%" : "30%",
          borderColor: "#CCCCCC",
          alignSelf: "center",
          alignItems: "center",
          marginTop: 50,
          padding: 40,
        }}
      >
        <Image
          resizeMode={"stretch"}
          style={{ width: 100, height: 80 }}
          source={require("../img/logo.png")}
        />
        <Text
          style={{
            fontSize: 18,
            fontFamily: "Prompt",
            marginTop: 20,
            color: "#0B2051",
          }}
        >
          ป้อนที่อยู่อีเมลเชื่อมโยงกับบัญชีของคุณ
        </Text>
        <View
          style={{
            width: size.width < 1280 ? "80%" : "60%",
            marginVertical: 20,
          }}
        >
          <Text
            style={{ fontSize: 18, fontFamily: "Prompt", color: "#0B2051" }}
          >
            อีเมล
          </Text>
          <TextInput
            autoComplete="off"
            value={body.email}
            onChangeText={(text) => {
              setBody({
                email: text,
              });
            }}
            style={{
              width: "100%",
              height: 40,
              borderWidth: 1,
              borderColor: "#CCCCCC",
              borderRadius: 5,
              fontSize: 18,
              fontFamily: "Prompt",
              color: "#0B2051",
              paddingHorizontal: 10,
            }}
          />
        </View>
        <TouchableOpacity
          onPress={async () => {
            //navigation.navigate("NewPassword1");
            const respnse = await apiservice({
              path: "/authen/resetpassemail",
              method: "post",
              body,
            });
            if (respnse.status == 200) {
              setModal(true);
            } else {
              setModalError(true);
            }
          }}
          style={{
            width: size.width < 1280 ? "80%" : "60%",
            height: 40,
            backgroundColor: "#0D88F0",
            borderRadius: 5,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Text
            style={{ fontSize: 18, fontFamily: "Prompt", color: "#FFFFFF" }}
          >
            ถัดไป
          </Text>
        </TouchableOpacity>
      </View>
      <Modal
        transparent={true}
        visible={modal}
        onRequestClose={() => {
          Alert.alert("Modal has been closed.");
          setModal(!modal);
        }}
      >
        <View style={styles.bgModal}>
          <View
            style={{
              width: size.width < 1280 ? "80%" : "45%",
              height: 270,
              borderWidth: 1,
              borderColor: "#0D88F0",
              backgroundColor: "#FFFFFF",
              alignSelf: "center",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text
              style={{
                fontSize: size.width < 1280 ? 30 : 35,
                fontFamily: "Prompt",
                color: "#74C745",
              }}
            >
              เราได้ทำการส่งอีเมลเรียบร้อย
            </Text>
            <Text
              style={[
                styles.text20,
                { marginTop: 10, fontSize: size.width < 1280 ? 16 : 20 },
              ]}
            >
              โปรดตรวจสอบอีเมลของท่านเพื่อเปลี่ยนรหัสผ่าน
            </Text>
            <TouchableOpacity
              onPress={() => {
                navigation.navigate("Home");
                setModal(!modal);
              }}
              style={{
                width: size.width < 1280 ? "30%" : "20%",
                height: 40,
                backgroundColor: "#0D88F0",
                borderRadius: 5,
                justifyContent: "center",
                alignItems: "center",
                marginTop: 20,
              }}
            >
              <Text
                style={{ fontSize: 18, fontFamily: "Prompt", color: "#FFFFFF" }}
              >
                ตกลง
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      </Modal>
      <Modal
        transparent={true}
        visible={modalError}
        onRequestClose={() => {
          Alert.alert("Modal has been closed.");
          setModalError(!modalError);
        }}
      >
        <View style={styles.bgModal}>
          <View
            style={{
              width: size.width < 1280 ? "80%" : "45%",
              height: 270,
              borderWidth: 1,
              borderColor: "#0D88F0",
              backgroundColor: "#FFFFFF",
              alignSelf: "center",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text
              style={{
                fontSize: size.width < 1280 ? 30 : 35,
                fontFamily: "Prompt",
                color: "red",
              }}
            >
              เกิดข้อผิดพลาด
            </Text>
            <Text
              style={[
                styles.text20,
                { marginTop: 10, fontSize: size.width < 1280 ? 16 : 20 },
              ]}
            >
              ไม่มีอีเมลนี้อยู่ในระบบ
            </Text>
            <TouchableOpacity
              onPress={() => {
                setModal(!modalError);
                navigation.navigate("Home");
              }}
              style={{
                width: size.width < 1280 ? "30%" : "20%",
                height: 40,
                backgroundColor: "#0D88F0",
                borderRadius: 5,
                justifyContent: "center",
                alignItems: "center",
                marginTop: 20,
              }}
            >
              <Text
                style={{ fontSize: 18, fontFamily: "Prompt", color: "#FFFFFF" }}
              >
                ตกลง
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      </Modal>
    </View>
  );
}
const styles = StyleSheet.create({
  bgModal: {
    width: "100%",
    height: "100%",
    backgroundColor: "#000000bb",
    justifyContent: "center",
  },
  text20: {
    fontSize: 20,
    fontFamily: "Prompt",
    color: "#0B2051",
  },
});
