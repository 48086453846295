import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  Image,
  Dimensions,
  Animated,
  TouchableOpacity,
  StyleSheet,
  ScrollView,
  TextInput,
  FlatList,
} from "react-native";
import {
  MaterialCommunityIcons,
  MaterialIcons,
  Feather,
  Ionicons,
  FontAwesome,
} from "@expo/vector-icons";
import RNPickerSelect from "react-native-picker-select";
import { useRecoilState } from "recoil";
import { tokenState } from "../../reducer/recoil";
import { useIsFocused } from "@react-navigation/native";
import { apiservice } from "../../service";
import { useWindowSize } from "./responsive";
const { width, height } = Dimensions.get("window");
export default function profileAdmin() {
  const [profile, setProfile] = useState("");
  const [token, setToken] = useRecoilState(tokenState);

  useEffect(() => {
    if (token) {
      getProfile(token);
    }
  }, [token]);

  async function getProfile(token) {
    try {
      const response = await apiservice({
        path: "/authen/user",
        method: "get",
        token: token?.accessToken,
      });

      setProfile(
        response.data !== undefined && response?.data?.result?.username
      );
    } catch (error) {
      throw error;
    }
  }
  const status = {
    label: "Active",
    value: "Active",
    color: "#A2A2A2",
  };
  const [status1, setstatus1] = useState([
    { label: "InActive", value: "InActive" },
  ]);
  const size = useWindowSize();
  return (
    <View
      style={[styles.viewProfile, { marginTop: size.width < 1400 ? 10 : 30 }]}
    >
      <View style={{ flexDirection: "row" }}>
        <Image
          style={styles.imgProfile}
          source={require("../img/Mask Group 9.png")}
        />
        <View style={{ marginLeft: 16 }}>
          <Text style={styles.text16W}>{profile}</Text>
          <RNPickerSelect
            placeholder={status}
            onValueChange={(val) => {}}
            items={status1}
            style={{
              inputWeb: {
                borderWidth: 0,
                width: 90,
                backgroundColor: "#444444",
                color: "#A2A2A2",
                fontFamily: "Prompt",
                fontSize: 16,
                marginLeft: -5,
              },
            }}
          />
        </View>
      </View>
    </View>
  );
}
const styles = StyleSheet.create({
  viewProfile: {
    height: "20%",
    paddingHorizontal: width * 0.01,
    marginTop: 30,
  },
  imgProfile: {
    width: 50,
    height: 50,
    borderRadius: 25,
  },
  text16W: {
    fontFamily: "Prompt",
    fontSize: 16,
    color: "#FFFFFF",
  },
});
