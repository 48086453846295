import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  Dimensions,
  TouchableOpacity,
  StyleSheet,
  ScrollView,
  TextInput,
} from "react-native";
import {
  MaterialCommunityIcons,
} from "@expo/vector-icons";
import Header from "../components/header";
import { BottomPart } from "./home";
import ButtonLogin from "../components/buttonLogin";
import { authActionLogin } from "../../action/authAction";
import { useRecoilState } from "recoil";
import { tokenState } from "../../reducer/recoil";
import { useWindowSize } from "../components/responsive";
import GoogleLogin from "react-google-login";
import { apiservice } from "../../service";


export function loginSocial() { }
export default function login({ navigation }) {
  const [token, setToken] = useRecoilState(tokenState);
  const [payload, setPayload] = useState(null);
  const [idToken, setIdToken] = useState(null);
  const [check, setCheck] = useState(false);
  const [check1, setCheck1] = useState(false);
  const [status, setStatus] = useState();
  const [state, setState] = useState({
    username: "",
    password: "",
  });
  const responseFacebook = async (response) => {
    const res = await apiservice({
      path: "/authen/loginfacebook",
      method: "POST",
      body: {
        telephoneNo: null,
        image_url: response.picture.data.url,
        firstName: response.name.split(" ")[0],
        lastName: response.name.split(" ")[1],
        email: response.email,
        password: response.id,
        gender: null,
        birthday: null,
        username: response.id,
        Type: "FACEBOOK",
      },
    });

    setToken(res.data);

    if (res.data) {
      setStatus();
      navigation.navigate("Home");
    }
  };
  const responseGoogle = async (response) => {
    const res = await apiservice({
      path: "/authen/logingoogle",
      method: "POST",
      body: {
        telephoneNo: null,
        image_url: response.profileObj.imageUrl,
        firstName: response.profileObj.givenName,
        lastName: response.profileObj.familyName,
        email: response.profileObj.email,
        password: response.profileObj.googleId,
        gender: null,
        birthday: null,
        username: response.profileObj.googleId,
        Type: "GOOGLE",
      },
    });

    setToken(res.data);

    if (res.data) {
      setStatus();
      navigation.navigate("Home");
    }
  };

  async function onPress() {
    if (state.username.length > 0 && state.password.length > 0) {
      const login = await authActionLogin(state);
      setToken(login);

      if (login) {
        setStatus();
        navigation.navigate("Home");
      }
      if (!login) {
        setStatus(login);
      }
    }
    if (state.username == "" || state.password == "") {
      setStatus(false);
    }
  }

  const size = useWindowSize();

  return (
    <View style={styles.container}>
      <ScrollView stickyHeaderIndices={[0]}>
        <Header registration navigation={navigation} login headertext />
        <View
          style={{
            flexDirection: size.width < 800 ? "column" : "row",
            height: size.width < 800 ? "60%" : "90%",
            width: "100%",
          }}
        >
          <View
            style={[
              styles.halfFristPage,
              {
                width: size.width < 800 ? "100%" : "50%",
                borderRightWidth: size.width < 800 ? 0 : 1,
                alignSelf: size.width < 800 && "center",
              },
            ]}
          >
            <Text
              style={[
                styles.textTitle,
                { paddingTop: "10%", fontSize: size.width < 800 ? 30 : 40 },
              ]}
            >
              เข้าสู่ระบบ
            </Text>
            <View style={{ width: "50%", marginTop: 16 }}>
              <Text style={styles.textDetail}>ชื่อผู้ใช้งาน</Text>
              <TextInput
                autoComplete="off"
                defaultValue={state.username}
                onChangeText={(text) => {
                  if (state != "") {
                    setStatus();
                  }
                  setState({ ...state, username: text });
                }}
                style={[
                  styles.textInput,
                  { borderColor: status == false ? "red" : "#BBBBBB" },
                ]}
              />
            </View>
            <View style={{ width: "50%", marginTop: 24 }}>
              <Text style={styles.textDetail}>รหัสผ่าน</Text>
              <TextInput
                autoComplete="off"
                defaultValue={state.password}
                onChangeText={(text) => {
                  if (state != "") {
                    setStatus();
                  }
                  setState({ ...state, password: text });
                }}
                secureTextEntry
                style={[
                  styles.textInput,
                  { borderColor: status == false ? "red" : "#BBBBBB" },
                ]}
              />
              {status == false && (
                <Text
                  style={{
                    fontSize: 14,
                    fontFamily: "Prompt",
                    color: "red",
                  }}
                >
                  ชื่อผู้ใช้งานหรือรหัสผ่านไม่ถูกต้อง
                </Text>
              )}
            </View>

            <TouchableOpacity
              onPress={() => {
                setCheck((val) => !val);
              }}
              style={{ flexDirection: "row", marginTop: 15, width: "50%" }}
            >
              <MaterialCommunityIcons
                name={check ? "checkbox-marked" : "checkbox-blank-outline"}
                size={24}
                color="#BBBBBB"
              />
              <Text style={styles.textDetail}>จำชื่อผู้ใช้</Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => {
                setCheck1((val) => !val);
              }}
              style={{ flexDirection: "row", marginTop: 15, width: "50%" }}
            >
              <MaterialCommunityIcons
                name={check1 ? "checkbox-marked" : "checkbox-blank-outline"}
                size={24}
                color="#BBBBBB"
              />
              <Text style={styles.textDetail}>จำชื่อรหัสผ่าน</Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={onPress}
              style={{
                width: "50%",
                maxWidth: 300,
                height: 60,
                backgroundColor: "#0D88F0",
                borderRadius: 5,
                alignItems: "center",
                justifyContent: "center",
                marginTop: 20,
              }}
            >
              <Text
                style={{ fontSize: 20, fontFamily: "Prompt", color: "#FFFFFF" }}
              >
                เข้าสู่ระบบ
              </Text>
            </TouchableOpacity>
            <View
              style={{
                flexDirection: "row",
                marginTop: 35,
                width: "50%",
                justifyContent: "space-between",
              }}
            >
              <TouchableOpacity
                onPress={() => {
                  navigation.navigate("NewPassword");
                }}
              >
                <Text
                  style={[
                    styles.textSupport,
                    { fontSize: size.width < 800 ? 16 : 20 },
                  ]}
                >
                  ลืมรหัสผ่าน?
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => {
                  navigation.navigate("Register");
                }}
              >
                <Text
                  style={[
                    styles.textSupport,
                    { fontSize: size.width < 800 ? 16 : 20, color: "#0D88F0" },
                  ]}
                >
                  สมัครสมาชิก
                </Text>
              </TouchableOpacity>
            </View>
          </View>
          <View
            style={[
              styles.halfBackPage,
              {
                width: size.width < 800 ? "140%" : "50%",
                alignSelf: size.width < 800 && "center",
              },
            ]}
          >
            <Text
              style={[
                styles.textTitle,
                { paddingTop: "10%", fontSize: size.width < 800 ? 30 : 40 },
              ]}
            >
              เข้าสู่ระบบผ่าน
            </Text>

            <GoogleLogin
              render={(renderProps) => (
                <ButtonLogin
                  disabled={renderProps.disabled}
                  onPress={renderProps.onClick}
                  img={require("../img/google_PNG19630.png")}
                  name={"เข้าสู่ระบบผ่าน Google"}
                />
              )}
              clientId="648121112048-2bn9ranmpnpf2qkiocd31hkd27qkbaob.apps.googleusercontent.com"
              buttonText="Login"
              onSuccess={responseGoogle}
              cookiePolicy={"single_host_origin"}
            />

            <ButtonLogin
              onPress={() => { window.open('') }}
              img={require("../img/1024px-LINE_logo.svg_.png")}
              name={"เข้าสู่ระบบผ่าน Line"}
            />
            {/* <LineLogin
              clientID="1656890076"
              clientSecret="c1f1a07de8820a90fc061a8cf58dd72c"
              state=""
              nonce=""
              redirectURI=""
              scope=""
              setPayload={setPayload}
              setIdToken={setIdToken}
            /> */}
          </View>
        </View>
        <BottomPart navigation={navigation} />
      </ScrollView>
    </View>
  );
}
const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: "100%",
    backgroundColor: "#FFFFFF",
  },
  textTitle: {
    fontSize: 40,
    fontFamily: "Prompt",
    color: "#646464",
  },
  textDetail: {
    fontSize: 18,
    fontFamily: "Prompt",
    color: "#646464",
  },
  textInput: {
    width: "100%",
    // maxWidth: 500,
    height: 60,
    borderWidth: 1,
    borderColor: "#0D88F0",
    borderRadius: 5,
    fontSize: 18,
    fontFamily: "Prompt",
    color: "#646464",
    padding: 15,
  },
  halfFristPage: {
    width: "50%",
    alignItems: "center",
    borderRightWidth: 0.5,
    borderRightColor: "#D1DDE5",
  },
  halfBackPage: {
    width: "50%",
    alignItems: "center",
  },
  textSupport: {
    fontSize: 20,
    fontFamily: "Prompt",
    color: "#0D88F0",
  },
});
