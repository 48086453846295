import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  Image,
  Dimensions,
  Animated,
  TouchableOpacity,
  StyleSheet,
  ScrollView,
  TextInput,
  FlatList,
} from "react-native";
import {
  MaterialCommunityIcons,
  MaterialIcons,
  Feather,
  Ionicons,
  FontAwesome,
} from "@expo/vector-icons";
import HeaderAdmin from "../../components/headerAdmin";
import ButtonMenuSuperAdmin from "../../components/buttonMenuSuperAdmin";
import Modal from "modal-react-native-web";
import {
  authActionCreateUniversity,
  deleteUnversity,
} from "../../../action/authAction";
import { useRecoilState } from "recoil";
import { dataUniversityState, tokenState } from "../../../reducer/recoil";
import { useIsFocused } from "@react-navigation/native";
import { apiservice } from "../../../service";
import moment from "moment";
import RNPickerSelect from "react-native-picker-select";
import ProfileAdmin from "../../components/profileAdmin";
import { useWindowSize } from "../../components/responsive";
const { width, height } = Dimensions.get("window");
export default function main({ navigation, route }) {
  const [dataUniversity, setDataUniversity] =
    useRecoilState(dataUniversityState);
  const [modalAddUniversity, setModalAddUniversity] = useState(false);
  const isfocused = useIsFocused();
  const [token, setToken] = useRecoilState(tokenState);
  const [modalDelItem, setModalDelItem] = useState(false);
  const [degree1, setDegree1] = useState(false);
  const [degree2, setDegree2] = useState(false);
  const [degree3, setDegree3] = useState(false);
  const [warning, setWarning] = useState(false);
  const [warningUniversity, setWarningUniversity] = useState(false);
  const [university, setUniversity] = useState([]);
  const [id, setId] = useState();
  const [openMenu, setOpenMenu] = useState(false);
  const [state, setState] = useState({
    university_name_en: "",
    university_description_en: "",
    university_name_th: "",
    university_description_th: "",
    major: [],
  });
  async function addUniversity() {
    if (
      (state.university_name_th.length > 0 && degree1 == true) ||
      degree2 == true ||
      degree3 == true
    ) {
      let arr = [];
      if (degree1) {
        arr = arr.concat(0);
      }
      if (degree2) {
        arr = arr.concat(1);
      }
      if (degree3) {
        arr = arr.concat(2);
      }
      if (state.university_name_th.length > 0) {
        const add = await authActionCreateUniversity({
          state: { ...state, major: arr },
          token: token?.accessToken,
        });
        setWarningUniversity(false);
        setWarning(false);
        setModalAddUniversity(!modalAddUniversity);
        getUniversity(token);
      }
    }
    if (degree1 == false && degree2 == false && degree3 == false) {
      setWarning(true);
    }
    if (state.university_name_th.length == 0) {
      setWarningUniversity(true);
    }
  }
  useEffect(() => {
    if (isfocused) {
      getUniversity(token);
    }
    if (token?.role == "ACCOUNTING") {
      navigation.navigate("ReportSA");
    }
  }, [token, isfocused]);

  async function getUniversity(token) {
    try {
      const response = await apiservice({
        path: "/course/getuniversity?page=1&totalpage=100",
        method: "get",
        token: token?.accessToken,
      });

      if (response.status == 200) {
        let pagination = [];
        for (let index = 0; index < response.data.total_page; index++) {
          pagination = pagination.concat({});
        }
        setUniversity(response.data);
      }
    } catch (error) {
      throw error;
    }
  }
  var dd = moment.locale("th");

  async function Delete() {
    const Delete = await deleteUnversity({
      token: token?.accessToken,
      id: id,
    });
  }
  function Logout() {
    setTimeout(() => {
      setToken("");
      window.location.replace("Home");
    }, 1000);
  }
  const size = useWindowSize();
  return (
    <View style={styles.container}>
      <HeaderAdmin
        navigation={navigation}
        onPress={() => {
          setOpenMenu((val) => !val);
        }}
      />
      <View style={{ width: "100%", flexDirection: "row", height: "100%" }}>
        {openMenu && (
          <View //menu
            style={{
              width: "13%",

              minWidth: 220,
              height: "100%",
              backgroundColor: "#444444",
              position: "absolute",
              zIndex: 99,
            }}
          >
            <View style={{ height: "80%" }}>
              {(token?.role == "SUPERADMIN" ||
                token?.role == "GENERALADMIN") && (
                <ButtonMenuSuperAdmin
                  p1
                  color
                  border
                  onPress={() => {
                    setOpenMenu(false);
                    navigation.navigate("UniversityCourseSA");
                  }}
                />
              )}
              {(token?.role == "SUPERADMIN" ||
                token?.role == "GENERALADMIN") && (
                <ButtonMenuSuperAdmin
                  p2
                  onPress={() => {
                    setOpenMenu(false);
                    navigation.navigate("GeneralCourseSA");
                  }}
                />
              )}
              {(token?.role == "SUPERADMIN" ||
                token?.role == "GENERALADMIN") && (
                <ButtonMenuSuperAdmin
                  p3
                  onPress={() => {
                    setOpenMenu(false);
                    navigation.navigate("AddAdminSA");
                  }}
                />
              )}
              {(token?.role == "ACCOUNTING" || token?.role == "SUPERADMIN") && (
                <ButtonMenuSuperAdmin
                  p4
                  onPress={() => {
                    setOpenMenu(false);
                    navigation.navigate("ReportSA");
                  }}
                />
              )}
              {(token?.role == "SUPERADMIN" ||
                token?.role == "GENERALADMIN") && (
                <ButtonMenuSuperAdmin
                  p5
                  onPress={() => {
                    setOpenMenu(false);
                    navigation.navigate("SlideBannerSA");
                  }}
                />
              )}
              {(token?.role == "SUPERADMIN" ||
                token?.role == "GENERALADMIN") && (
                <ButtonMenuSuperAdmin
                  p6
                  onPress={() => {
                    setOpenMenu(false);
                    navigation.navigate("Discount");
                  }}
                />
              )}
              {(token?.role == "SUPERADMIN" ||
                token?.role == "GENERALADMIN") && (
                <ButtonMenuSuperAdmin
                  p7
                  onPress={() => {
                    setOpenMenu(false);
                    navigation.navigate("Account");
                  }}
                />
              )}
            </View>
            <ProfileAdmin />
          </View>
        )}
        {size.width >= 1280 && (
          <View //menu
            style={{
              width: "13%",

              minWidth: 220,
              height: "100%",
              backgroundColor: "#444444",
            }}
          >
            <View style={{ height: "80%" }}>
              {(token?.role == "SUPERADMIN" ||
                token?.role == "GENERALADMIN") && (
                <ButtonMenuSuperAdmin
                  p1
                  color
                  border
                  onPress={() => {
                    navigation.navigate("UniversityCourseSA");
                  }}
                />
              )}
              {(token?.role == "SUPERADMIN" ||
                token?.role == "GENERALADMIN") && (
                <ButtonMenuSuperAdmin
                  p2
                  onPress={() => {
                    navigation.navigate("GeneralCourseSA");
                  }}
                />
              )}
              {(token?.role == "SUPERADMIN" ||
                token?.role == "GENERALADMIN") && (
                <ButtonMenuSuperAdmin
                  p3
                  onPress={() => {
                    navigation.navigate("AddAdminSA");
                  }}
                />
              )}
              {(token?.role == "ACCOUNTING" || token?.role == "SUPERADMIN") && (
                <ButtonMenuSuperAdmin
                  p4
                  onPress={() => {
                    navigation.navigate("ReportSA");
                  }}
                />
              )}
              {(token?.role == "SUPERADMIN" ||
                token?.role == "GENERALADMIN") && (
                <ButtonMenuSuperAdmin
                  p5
                  onPress={() => {
                    navigation.navigate("SlideBannerSA");
                  }}
                />
              )}
              {(token?.role == "SUPERADMIN" ||
                token?.role == "GENERALADMIN") && (
                <ButtonMenuSuperAdmin
                  p6
                  onPress={() => {
                    navigation.navigate("Discount");
                  }}
                />
              )}
              {(token?.role == "SUPERADMIN" ||
                token?.role == "GENERALADMIN") && (
                <ButtonMenuSuperAdmin
                  p7
                  onPress={() => {
                    navigation.navigate("Account");
                  }}
                />
              )}
            </View>
            <ProfileAdmin />
          </View>
        )}
        <View
          style={[
            styles.viewDetail,
            {
              width: size.width < 1280 ? "100%" : "87%",
              paddingHorizontal: size.width < 500 ? 20 : 55,
            },
          ]}
        >
          <TouchableOpacity
            onPress={() => {
              setModalAddUniversity(true);
            }}
            style={styles.buttonAdd}
          >
            <Text style={styles.text16W}>+ Add New</Text>
          </TouchableOpacity>
          <ScrollView horizontal>
            <View
              style={{
                width:
                  size.width < 500
                    ? size.width * 2.5
                    : size.width < 1280
                    ? size.width * 1.0
                    : size.width * 0.78,
                // minHeight: height * 2.5,
              }}
            >
              <View style={styles.viewSubject}>
                <Text
                  style={[
                    styles.text16B,
                    { width: "40%", textAlign: "left", paddingLeft: 67 },
                  ]}
                >
                  ชื่อมหาวิทยาลัย
                </Text>
                <Text style={[styles.text16B, { width: "15%" }]}>
                  วันที่สร้าง
                </Text>
                <Text style={[styles.text16B, { width: "10%" }]}>
                  จำนวนคอร์สทั้งหมด
                </Text>
                <Text style={[styles.text16B, { width: "10%" }]}>
                  อาจารย์ผู้สอน
                </Text>
                <Text style={[styles.text16B, { width: "10%" }]}>นักศึกษา</Text>
                <Text style={[styles.text16B, { width: "5%" }]}></Text>
              </View>
              <FlatList
                numColumns={1}
                data={university.result}
                renderItem={({ item, index }) => {
                  return (
                    <TouchableOpacity
                      onPress={() => {
                        setDataUniversity(item);
                        setTimeout(() => {
                          navigation.navigate("CourseTaughtSA");
                        }, 500);
                      }}
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        borderBottomWidth: 1,
                        borderBottomColor: "#E5E5E5",
                        height: 50,
                        alignItems: "center",
                      }}
                    >
                      <Text
                        style={[
                          styles.text16B,
                          { width: "40%", textAlign: "left", paddingLeft: 67 },
                        ]}
                      >
                        {item.university_name_th}
                      </Text>
                      <Text style={[styles.text16B, { width: "15%" }]}>
                        {moment(item.createdAt)
                          .add(543, "year")
                          .format("DD MMMM YYYY")}
                      </Text>
                      <Text style={[styles.text16B, { width: "10%" }]}>
                        {item.couse_totals}
                      </Text>
                      <Text style={[styles.text16B, { width: "10%" }]}>
                        {item.teacher_totals}
                      </Text>
                      <Text style={[styles.text16B, { width: "10%" }]}>
                        {item.student_totals}
                      </Text>

                      <TouchableOpacity
                        onPress={() => {
                          setId(item.id);
                          setModalDelItem(true);
                        }}
                        style={{ width: "5%", alignItems: "center" }}
                      >
                        <MaterialIcons name="delete" size={24} color="black" />
                      </TouchableOpacity>
                    </TouchableOpacity>
                  );
                }}
              />
            </View>
          </ScrollView>
        </View>
      </View>
      <Modal
        transparent={true}
        visible={modalAddUniversity}
        onRequestClose={() => {
          Alert.alert("Modal has been closed.");
          setModalAddUniversity(!modalAddUniversity);
        }}
      >
        <View style={styles.bgModal}>
          <View
            style={[
              styles.viewDetailModal,
              {
                width:
                  size.width < 500 ? "80%" : size.width < 1280 ? "70%" : "50%",
              },
            ]}
          >
            <TextInput
              autoComplete="off"
              defaultValue={state.university_name_th}
              onChangeText={(text) => {
                if (state != "") {
                  setWarningUniversity(false);
                  setWarning(false);
                }
                setState({ ...state, university_name_th: text });
              }}
              placeholder={"กรอกชื่อมหาวิทยาลัย"}
              placeholderTextColor={"#444444"}
              style={{
                width: "100%",
                height: 67,
                borderRadius: 9,
                borderWidth: 1,
                borderColor: "#707070",
                fontSize: size.width < 500 ? 18 : 35,
                fontFamily: "Prompt",
                color: "#444444",
                textAlign: "center",
              }}
            />
            {warningUniversity && (
              <Text
                style={{
                  fontSize: 18,
                  fontFamily: "Prompt",
                  color: "#FF0000",
                }}
              >
                กรุณากรอกชื่อมหาวิทยาลัย
              </Text>
            )}
            <View style={{ marginTop: 27 }}>
              <Text
                style={{
                  fontSize: 18,
                  fontFamily: "Prompt",
                  color: "#272D3B",
                }}
              >
                เลือกระดับปริญญาที่เปิดสอน (สามารถเลือกได้มากกว่า 1 รายการ)
              </Text>
              {warning && (
                <Text
                  style={{
                    fontSize: 18,
                    fontFamily: "Prompt",
                    color: "#FF0000",
                  }}
                >
                  ***กรุณาเลือกอย่างน้อย 1 รายการ***
                </Text>
              )}
            </View>
            <View
              style={{
                flexDirection: size.width < 500 ? "column" : "row",
                marginTop: size.width < 500 ? 10 : 34,
              }}
            >
              <TouchableOpacity
                onPress={() => {
                  setDegree1((val) => !val);
                  setWarning(false);
                  // setState()
                }}
                style={{ flexDirection: "row", alignItems: "center" }}
              >
                <MaterialIcons
                  name={
                    degree1 ? "radio-button-checked" : "radio-button-unchecked"
                  }
                  size={24}
                  color={degree1 ? "#0D88F0" : "#808D93"}
                />
                <Text
                  style={{
                    fontSize: 23,
                    fontFamily: "Prompt",
                    color: degree1 ? "#0D88F0" : "#808D93",
                  }}
                >
                  ปริญญาตรี
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => {
                  setDegree2((val) => !val);
                  setWarning(false);
                }}
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  marginHorizontal: size.width < 500 ? 0 : width * 0.05,
                }}
              >
                <MaterialIcons
                  name={
                    degree2 ? "radio-button-checked" : "radio-button-unchecked"
                  }
                  size={24}
                  color={degree2 ? "#0D88F0" : "#808D93"}
                />
                <Text
                  style={{
                    fontSize: 23,
                    fontFamily: "Prompt",
                    color: degree2 ? "#0D88F0" : "#808D93",
                  }}
                >
                  ปริญญาโท
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => {
                  setDegree3((val) => !val);
                  setWarning(false);
                }}
                style={{ flexDirection: "row", alignItems: "center" }}
              >
                <MaterialIcons
                  name={
                    degree3 ? "radio-button-checked" : "radio-button-unchecked"
                  }
                  size={24}
                  color={degree3 ? "#0D88F0" : "#808D93"}
                />
                <Text
                  style={{
                    fontSize: 23,
                    fontFamily: "Prompt",
                    color: degree3 ? "#0D88F0" : "#808D93",
                  }}
                >
                  ปริญญาเอก
                </Text>
              </TouchableOpacity>
            </View>
            <View
              style={{
                flexDirection: "row",
                alignSelf: "center",
                marginTop: 49,
              }}
            >
              <TouchableOpacity
                onPress={() => {
                  setWarning(false);
                  setModalAddUniversity(!modalAddUniversity);
                }}
                style={styles.buttonModal}
              >
                <Text
                  style={[
                    styles.textButtonModal,
                    {
                      color: "#444444",
                    },
                  ]}
                >
                  ยกเลิก
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={addUniversity}
                style={[
                  styles.buttonModal,
                  {
                    backgroundColor: "#0D88F0",
                    marginLeft: width * 0.05,
                  },
                ]}
              >
                <Text
                  style={[
                    styles.textButtonModal,
                    {
                      color: "#FFFFFF",
                    },
                  ]}
                >
                  ยืนยัน
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </Modal>
      <Modal
        transparent={true}
        visible={modalDelItem}
        onRequestClose={() => {
          Alert.alert("Modal has been closed.");
          setModalDelItem(!modalDelItem);
        }}
      >
        <View style={styles.bgModal}>
          <View
            style={[
              styles.viewDetailModal,
              {
                width:
                  size.width < 500 ? "80%" : size.width < 1280 ? "70%" : "30%",
                height: 200,
                paddingVertical: 25,
              },
            ]}
          >
            <Text
              style={[
                styles.textButtonModal,
                { color: "#444444", alignSelf: "center" },
              ]}
            >
              คุณต้องการจะลบใช่รึไม่
            </Text>
            <View
              style={{
                flexDirection: "row",
                alignSelf: "center",
                marginTop: 39,
              }}
            >
              <TouchableOpacity
                onPress={() => setModalDelItem(!modalDelItem)}
                style={styles.buttonModal}
              >
                <Text
                  style={[
                    styles.textButtonModal,
                    {
                      color: "#444444",
                    },
                  ]}
                >
                  ยกเลิก
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => {
                  Delete();
                  setTimeout(() => {
                    getUniversity(token);
                  }, 500);
                  setModalDelItem(!modalDelItem);
                }}
                style={[
                  styles.buttonModal,
                  {
                    backgroundColor: "#0D88F0",
                    marginLeft: width * 0.05,
                  },
                ]}
              >
                <Text
                  style={[
                    styles.textButtonModal,
                    {
                      color: "#FFFFFF",
                    },
                  ]}
                >
                  ยืนยัน
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </Modal>
    </View>
  );
}
const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: "100%",
    backgroundColor: "#FFFFFF",
  },
  text16W: {
    fontFamily: "Prompt",
    fontSize: 16,
    color: "#FFFFFF",
  },
  text16B: {
    fontFamily: "Prompt",
    fontSize: 16,
    color: "#000000",
  },
  buttonAdd: {
    width: 140,
    height: 40,
    backgroundColor: "#0D88F0",
    borderRadius: 20,
    alignItems: "center",
    justifyContent: "center",
    alignSelf: "flex-end",
  },
  viewSubject: {
    width: "100%",
    minHeight: 51,
    backgroundColor: "#E5E5E5",
    marginTop: 35,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  viewDetail: {
    width: "87%",
    height: "100%",
    paddingHorizontal: 55,
    paddingVertical: 28,
  },
  bgModal: {
    width: "100%",
    height: "100%",
    backgroundColor: "#000000bb",
    justifyContent: "center",
  },
  viewDetailModal: {
    width: "50%",

    backgroundColor: "#FFFFFF",
    borderRadius: 20,
    alignSelf: "center",
    paddingHorizontal: width * 0.05,
    paddingVertical: 50,
  },
  buttonModal: {
    width: 102,
    height: 48,
    borderRadius: 4,
    backgroundColor: "#EFEFF3",
    justifyContent: "center",
    alignItems: "center",
  },
  textButtonModal: {
    fontSize: 25,
    fontFamily: "Prompt",
  },
});
