import React, { useEffect, useState } from "react";
import {
  View,
  Text,
  Image,
  Dimensions,
  TouchableOpacity,
  StyleSheet,
  ScrollView,
} from "react-native";
import * as DocumentPicker from "expo-document-picker";
import { MaterialCommunityIcons, MaterialIcons } from "@expo/vector-icons";
import Header from "../components/header";
import Modal from "modal-react-native-web";
import ReactCountdownClock from "react-countdown-clock";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { apiservice } from "../../service";
import { useRecoilState, useRecoilValue } from "recoil";
import { courseState, tokenState, userState } from "../../reducer/recoil";
import { useIsFocused } from "@react-navigation/native";
import { useWindowSize } from "../components/responsive";
import HTMLRenderer from "react-html-renderer";
const { width, height } = Dimensions.get("window");

export default function subjectiveTest({ navigation, route }) {
  const [page, setPage] = useState(false);
  const [unLock, setUnLock] = useState(false);
  const [answer, setAnswer] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalVisible1, setModalVisible1] = useState(false);
  const [modalScore, setModalScore] = useState(false);
  const [check, setCheck] = useState(false);
  const [show, setShow] = useState([]);
  const [show1, setShow1] = useState([]);
  const [detail, setDetail] = useState("");
  const [too, setToo] = useState(null);
  const [users, setUser] = useRecoilState(userState);
  const [defaultsStatus, setStatus] = useState(true);
  const [course, setCourse] = useState({
    status: false,
    comment: "",
    approve: "",
  });
  const [doc, setDoc] = useState("");
  const [Oncecourse, myOncecourse] = useState(null);
  const token = useRecoilValue(tokenState);
  const [courseStates, setCourseStates] = useRecoilState(courseState);

  const focus = useIsFocused();

  function myCallback() {
    setModalVisible1(true);
  }

  useEffect(() => {
    if (focus) {
      if (route.params == undefined) {
        route.params = {};
      }
      checkStart();
      getWorkStart();
    }
  }, [focus]);

  async function checkStart() {
    const response = await apiservice({
      path: "/start-course/myOncecourse?course_id=" + route.params.course_id,
      method: "get",
      token: token?.accessToken,
    });
    if (response.status == 200) {
      myOncecourse(response.data);
    }
  }

  async function checkStartFinish() {
    const response = await apiservice({
      path: "/start-course/myOncecourse?course_id=" + route.params.course_id,
      method: "get",
      token: token?.accessToken,
    });

    if (response.status == 200) {
      myOncecourse(response.data);
    }
  }

  useEffect(() => {
    if (
      defaultsStatus &&
      route.params.dataStudymodel[route.params.Mainindex].listen_detail[
        route.params.index + 1
      ] == undefined &&
      defaultsStatus &&
      route.params.dataStudymodel[route.params.Mainindex + 1] == undefined
    ) {
      name1();
    }
  }, []);

  async function name1() {
    const respp = await apiservice({
      path: "/start-course/getalltotals/" + route.params.course_id,
      method: "get",
      token: token?.accessToken,
    });
    const resppt = await apiservice({
      path: "/start-course/getworktotals/" + route.params.course_id,
      method: "get",
      token: token?.accessToken,
    });
    if (respp.status == 200) {
      setShow1(resppt.data.results);
      setShow(respp.data.results);
      setModalScore(true);
    }
  }

  async function getWorkStart() {
    try {
      const users = await apiservice({
        path: "/authen/user",
        method: "get",
        token: token?.accessToken,
      });
      const response = await apiservice({
        path:
          "/start-course/" +
          (route.params.type == "WORK"
            ? "getworkBystudent"
            : "getwritingBystudent") +
          "?course_id=" +
          route.params.course_id +
          "&work_id=" +
          route.params.education_id +
          "&student_id=" +
          users.data.result.id,
        method: "get",
        token: token?.accessToken,
      });

      if (response.status == 200) {
        if (response.data.results != null) {
          setDoc(response.data.results.documets);
          setDetail(response.data.results.detail);
          setCourse(response.data.results);
          setAnswer(false);
          setUnLock(!response.data.results.status);
          setPage(response.data.results.status);
          setCheck(!response.data.results.status ? true : false);
        }
      }
    } catch (error) {}
  }

  async function onSubmit() {
    const response = await apiservice({
      path: "/start-course/creatework",
      method: "post",
      body: {
        course_id: route.params.course_id,
        work_id: route.params.education_id,
        detail,
        documets: doc,
        status: false,
      },
      token: token?.accessToken,
    });

    if (response.status == 200) {
      setPage(false);
      setCheck(true);
      // setModalVisible1(!modalVisible1);
    }
  }

  async function onSubmit1() {
    const response = await apiservice({
      path: "/start-course/createwriting",
      method: "post",
      body: {
        course_id: route.params.course_id,
        work_id: route.params.education_id,
        detail,
        documets: doc,
        status: false,
      },
      token: token?.accessToken,
    });

    if (response.status == 200) {
      setPage(false);
      setCheck(true);
      // setModalVisible1(!modalVisible1);
    }
  }

  function name() {
    try {
      if (route.params.image) {
        return route.params.image;
      } else {
        return route.params.img.image;
      }
    } catch (error) {
      return "";
    }
  }

  async function onPress() {
    let result = await DocumentPicker.getDocumentAsync({
      type: "application/pdf",
    });
    // setImage(result.uri)
    const response = await apiservice({
      path: "/image/createpdf",
      method: "post",
      body: {
        name: result.name,
        base64: result.uri,
      },
    });

    setDoc(response.data.imageRefId);
  }

  async function Chanfd(params, Mainindex, i) {
    if (params.type == "EXAM") {
      const res = await apiservice({
        path: "/quiz/get/" + params.education_id,
        method: "get",
        token: token?.accessToken,
      });

      navigation.navigate("TestReview", {
        ...params,
        ...res.data,
        Mainindex,
        index: i,
        number: 0,
        dataStudymodel: route.params.dataStudymodel,
      });
    } else if (params.type == "DESCRIPTION") {
      const res = await apiservice({
        path: "/course/geteducation/" + params.education_id,
        method: "get",
        token: token?.accessToken,
      });
      if (res.status == 200) {
        if (res.data.TYPE == "VDO") {
          navigation.navigate("LessonVideoReview", {
            ...params,
            ...res.data,
            number: 0,
            Mainindex,
            dataStudymodel: route.params.dataStudymodel,
            index: i,
          });
        } else {
          navigation.navigate("LessonReview", {
            ...params,
            ...res.data,
            number: 0,
            Mainindex,
            dataStudymodel: route.params.dataStudymodel,
            index: i,
          });
        }
      }
    } else if (params.type == "WORK") {
      const res = await apiservice({
        path: "/course/getwork/" + params.education_id,
        method: "get",
        token: token?.accessToken,
      });

      navigation.push("SubjectiveTestReview", {
        ...res.data,
        ...params,
        number: 0,
        Mainindex,
        index: i,
        dataStudymodel: route.params.dataStudymodel,
      });
    } else {
      const res = await apiservice({
        path: "/quiz/get/" + params.education_id,
        method: "get",
        token: token?.accessToken,
      });

      navigation.push("SubjectiveTestReview", {
        ...res.data,
        ...params,
        dataStudymodel: route.params.dataStudymodel,
        number: 0,
        Mainindex,
        index: i,
      });
    }
  }

  const ck = {
    toolbar: [
      "heading",
      "|",
      "bold",
      "italic",
      "link",
      "bulletedList",
      "numberedList",
      "blockQuote",
    ],
    heading: {
      options: [
        {
          model: "paragraph",
          title: "Paragraph",
          class: "ck-heading_paragraph",
        },
        {
          model: "heading1",
          view: "h1",
          title: "Heading 1",
          class: "ck-heading_heading1",
        },
        {
          model: "heading2",
          view: "h2",
          title: "Heading 2",
          class: "ck-heading_heading2",
        },
      ],
    },
  };
  const embed = route.params.detail;

  const stringToHTML = function (str) {
    const domContainer = document.createElement("span");
    domContainer.innerHTML = str;
    return domContainer;
  };

  const parentEmbed = stringToHTML(embed);

  let oldIframe = parentEmbed.querySelectorAll("oembed");
  oldIframe = Array.from(oldIframe);

  for (const i in oldIframe) {
    //Get the url from oembed tag
    let url = oldIframe[i].getAttribute("url");
    //Replace 'watch?v' with 'embed/'
    url = url.replace("watch?v=", "embed/");

    //Create a iframe tag
    const newIframe = document.createElement("iframe");
    newIframe.setAttribute("width", "auto");
    newIframe.setAttribute("height", "auto");
    newIframe.setAttribute("allowFullScreen", "");
    newIframe.setAttribute("frameBorder", 0);
    if (url) {
      newIframe.setAttribute("src", url);
    }
    // replace oldIframe with newIframe
    oldIframe[i].parentNode.replaceChild(newIframe, oldIframe[i]);
  }

  const contentToRender = parentEmbed.outerHTML?.replace(
    /<img /g,
    `<img width="${width * 0.5}" height="auto" `
  );
  const size = useWindowSize();

  useEffect(() => {
    if (show.length > 0) {
      totalsss();
    }
  }, [show]);

  async function totalsss() {
    let toootals = 0;
    const re = await Promise.all(
      route.params.progress.map(async (item, index) => {
        const res = await apiservice({
          path:
            "/quiz/get/" +
            item.listen_detail.filter((items) => items.type == "EXAM")[0]
              .education_id,
          method: "get",
          token: token?.accessToken,
        });
        const question = res.data.question;
        const answers = item.listen_detail.filter(
          (items) => items.type == "EXAM"
        )[0].answer;
        toootals =
          (answers
            .filter(
              (ele, ind) =>
                ind === answers.findIndex((elem) => elem.number === ele.number)
            )
            .filter((items, mainIndex) => {
              // if(items)

              if (
                question.filter((val, indexs) => indexs == items.number)[0]
                  .type == "1"
              ) {
                return (
                  question.filter((val, indexs) => indexs == items.number)[0]
                    .answer == items.answer
                );
              }
              if (
                question.filter((val, indexs) => indexs == items.number)[0]
                  .type == "2"
              ) {
                return (
                  question
                    .filter((val, indexs) => indexs == items.number)[0]
                    .answer.filter(
                      (e) => items.answer.filter((ev) => e == ev).length > 0
                    ).length ==
                  question.filter((val, indexs) => indexs == items.number)[0]
                    .answer.length
                );
              }
              if (
                question.filter((val, indexs) => indexs == items.number)[0]
                  .type == "3"
              ) {
                return question.filter(
                  (val, indexs) => indexs == items.number
                )[0].answer != null
                  ? question.filter((val, indexs) => indexs == items.number)[0]
                      .answer == items.answer
                  : items.answer == 0
                  ? true
                  : false;
              }
              if (
                question.filter((val, indexs) => indexs == items.number)[0]
                  .type == "4"
              ) {
                return (
                  question
                    .filter((val, indexs) => indexs == items.number)[0]
                    .answer.filter(
                      (e) =>
                        items.answer.filter((ev) => e.answer == ev.answer)
                          .length > 0
                    ).length ==
                  question.filter((val, indexs) => indexs == items.number)[0]
                    .answer.length
                );
              }
              if (
                question.filter((val, indexs) => indexs == items.number)[0]
                  .type == "5"
              ) {
                return (
                  question.filter((val, indexs) => {
                    if (val.type == "5") {
                      return (
                        answers
                          .filter((val, indexss) => indexs == val.number)[0]
                          .answer.filter((e, i) => val.choice[i] == e.ans)
                          .length == val.choice.length
                      );
                    } else {
                      return false;
                    }
                  }).length > 0
                );
              }

              return false;
            }).length /
            answers.length) *
          100;
      })
    );

    setToo(toootals);
  }

  if (route.params == undefined) {
    return <View />;
  }

  return (
    <View style={styles.container}>
      <ScrollView stickyHeaderIndices={[0]}>
        <Header navigation={navigation} />
        {!answer && page && route.params.type == "WRITING" && (
          <View
            style={{
              position: "absolute",
              marginTop: height * 0.2,
              marginLeft: width * 0.03,
            }}
          >
            <ReactCountdownClock
              seconds={
                route.params.conditions.timerType == "HOUR"
                  ? route.params.conditions.timer * 60 * 60
                  : route.params.conditions.timer * 60
              }
              paused={defaultsStatus}
              color="#0D88F0"
              alpha={0.9}
              size={300}
              onComplete={myCallback}
            />
          </View>
        )}
        <View>
          <View
            style={{
              width: size.width < 1280 ? "80%" : "50%",
              alignSelf: "center",
              marginTop: 35,
              minHeight: size.height - 155,
            }}
          >
            <Text
              style={{ fontFamily: "Prompt", fontSize: 11, color: "#0B2051" }}
            >
              {/* พุทธศาสตรบัณฑิต (การสอนพระพุทธศาสนาและจิตวิทยาแนะแนว) */}
            </Text>
            <Text
              style={{
                fontFamily: "Prompt",
                fontSize: size.width < 500 ? 16 : 25,
                color: "#0B2051",
              }}
            >
              {route.params.name}
            </Text>
            {name(route.params) && (
              <Image
                style={{
                  height: size.width < 500 ? 250 : 500,
                  marginVertical: 20,
                }}
                source={{
                  uri:
                    "https://api.courseoncloud.com/api/image/getimage/" +
                    name(route.params.image).replace(".png", ""),
                }}
              />
            )}
            <View>
              <Text
                style={{
                  fontFamily: "Prompt",
                  fontSize: size.width < 500 ? 14 : 20,
                  color: "#0B2051",
                }}
              >
                <HTMLRenderer
                  html={contentToRender}
                  components={
                    {
                      // h1: props => <Heading color="red" {...props} />,
                      // h2: Subheading,
                      // a: Link,
                    }
                  }
                />
              </Text>
              {!page && (
                <TouchableOpacity
                  disabled={defaultsStatus || check ? true : false}
                  onPress={() => {
                    setPage(true);
                  }}
                  style={{
                    width: size.width < 500 ? 120 : 170,
                    height: 50,
                    backgroundColor:
                      defaultsStatus || check ? "#CCCCCC" : "#0D88F0",
                    borderRadius: 10,
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: 20,
                    marginBottom: 45,
                  }}
                >
                  <Text
                    style={{
                      fontFamily: "Prompt",
                      fontSize: size.width < 500 ? 14 : 18,
                      color: "#FFFFFF",
                    }}
                  >
                    {route.params.type == "WRITING"
                      ? "เริ่มทำแบบทดสอบ"
                      : "เริ่มทำงานที่ได้รับ"}
                  </Text>
                </TouchableOpacity>
              )}
            </View>

            {page && (
              <View>
                <View style={styles.viewCkEditor}>
                  <CKEditor
                    editor={ClassicEditor}
                    data={detail}
                    config={ck}
                    onReady={(editor) => {
                      // You can store the "editor" and use when it is needed.
                    }}
                    onChange={async (event, editor) => {
                      const data = await editor.getData();

                      setDetail(data);
                    }}
                    onBlur={(event, editor) => {}}
                    onFocus={(event, editor) => {}}
                  />
                </View>

                <View style={{ flexDirection: "row", marginTop: 24 }}>
                  <TouchableOpacity
                    onPress={onPress}
                    style={{
                      width: size.width < 500 ? 120 : 200,
                      height: 50,
                      backgroundColor: "#0D88F0",
                      borderRadius: 20,
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "row",
                    }}
                  >
                    <MaterialIcons
                      name="attach-file"
                      size={24}
                      color="#FFFFFF"
                    />
                    <Text
                      style={{
                        fontFamily: "Prompt",
                        fontSize: size.width < 500 ? 12 : 16,
                        color: "#FFFFFF",
                        marginLeft: 20,
                      }}
                    >
                      แนบไฟล์งาน
                    </Text>
                  </TouchableOpacity>
                  <Text
                    style={{
                      fontFamily: "Prompt",
                      fontSize: size.width < 500 ? 12 : 20,
                      color: "#0B2051",
                      alignSelf: "center",
                      marginLeft: 15,
                    }}
                  >
                    {doc}
                  </Text>
                </View>
                {course.status && (
                  <View
                    style={{
                      width: "100%",
                      borderTopWidth: 1,
                      borderTopColor: "#0D88F0",
                      marginVertical: 40,
                    }}
                  >
                    <View
                      style={{
                        width: size.width < 500 ? 150 : 200,
                        height: 50,
                        backgroundColor:
                          course.approve == "approve" ? "#74C745" : "#ff0000",
                        borderRadius: 20,
                        justifyContent: "center",
                        alignItems: "center",
                        marginVertical: 45,
                      }}
                    >
                      <Text
                        style={{
                          fontSize: size.width < 500 ? 14 : 20,
                          fontFamily: "Prompt",
                          color: "#FFFFFF",
                        }}
                      >
                        {course.approve == "approve" ? "คุณผ่าน" : "คุณไม่ผ่าน"}
                      </Text>
                    </View>
                    <View
                      style={{
                        width: "100%",
                        height: 50,
                        backgroundColor: "#0D88F0",
                        paddingLeft: 20,
                        justifyContent: "center",
                      }}
                    >
                      <Text
                        style={{
                          fontSize: size.width < 500 ? 16 : 20,
                          fontFamily: "Prompt",
                          color: "#FFFFFF",
                        }}
                      >
                        ความคิดเห็นของผู้สอน
                      </Text>
                    </View>
                    <View
                      style={{
                        width: "100%",
                        minHeight: 200,
                        borderWidth: 1,
                        borderColor: "#0D88F0",
                      }}
                    >
                      <Text
                        style={{
                          fontFamily: "Prompt",
                          fontSize: size.width < 500 ? 14 : 20,
                          color: "#0B2051",
                          // alignSelf: "center",
                          marginLeft: 15,
                        }}
                      >
                        {course.comment}
                      </Text>
                    </View>
                    <TouchableOpacity
                      disabled={course.status}
                      onPress={() => {
                        setUnLock(true);
                      }}
                      style={{
                        width: size.width < 500 ? 120 : 165,
                        height: 57,
                        backgroundColor: course.status ? "#CCCCCC" : "#0D88F0",
                        borderRadius: 5,
                        justifyContent: "center",
                        alignItems: "center",
                        marginVertical: 40,
                        alignSelf: "center",
                      }}
                    >
                      <Text
                        style={{
                          fontFamily: "Prompt",
                          fontSize: size.width < 500 ? 14 : 25,
                          color: "#FFFFFF",
                        }}
                      >
                        เสร็จสิ้น
                      </Text>
                    </TouchableOpacity>
                    {course.approve == "notapprove" && (
                      <TouchableOpacity
                        onPress={async () => {
                          const response = await apiservice({
                            path:
                              "/start-course/" +
                              (route.params.type == "WORK"
                                ? "updatework"
                                : "updatewriting") +
                              "/" +
                              course.id,
                            method: "put",
                            body: {
                              course_id: route.params.course_id,
                              work_id: route.params.education_id,
                              detail,
                              documets: doc,
                              status: false,
                            },
                            token: token?.accessToken,
                          });

                          if (response.status == 200) {
                            setPage(false);
                            setCheck(true);
                            // setModalVisible1(!modalVisible1);
                          }
                        }}
                        style={{
                          width: size.width < 500 ? 120 : 165,
                          height: 57,
                          backgroundColor: "#0D88F0",
                          borderRadius: 5,
                          justifyContent: "center",
                          alignItems: "center",
                          marginVertical: 40,
                          alignSelf: "center",
                        }}
                      >
                        <Text
                          style={{
                            fontFamily: "Prompt",
                            fontSize: size.width < 500 ? 14 : 25,
                            color: "#FFFFFF",
                          }}
                        >
                          ส่งอีกครั้ง
                        </Text>
                      </TouchableOpacity>
                    )}
                  </View>
                )}
                {!course.status && (
                  <TouchableOpacity
                    onPress={() => {
                      setModalVisible(true);
                    }}
                    style={{
                      width: 150,
                      height: 50,
                      borderWidth: 1,
                      borderColor: "#0D88F0",
                      borderRadius: 5,
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "row",
                      marginVertical: height * 0.05,
                      alignSelf: "flex-end",
                      marginTop: height * 0.1,
                    }}
                  >
                    <Text
                      style={{
                        fontFamily: "Prompt",
                        fontSize: size.width < 500 ? 14 : 18,
                        color: "#0D88F0",
                      }}
                    >
                      {route.params.type == "WRITING" ? "ส่งข้อสอบ" : "ส่งงาน"}
                    </Text>
                  </TouchableOpacity>
                )}
              </View>
            )}
            {check && (
              <View
                onPress={() => {
                  setAnswer(true);
                  setCheck(false);
                  setPage(true);
                }}
                style={{
                  width: "100%",
                  maxWidth: 698,
                  height: 100,
                  borderWidth: 1,
                  borderColor: "#0D88F0",
                  borderRadius: 50,
                  alignItems: "center",
                  flexDirection: "row",
                  justifyContent: "center",
                  marginBottom: height * 0.05,
                }}
              >
                <Image
                  style={{ width: 80, height: 80 }}
                  source={require("../img/Group 66.png")}
                />
                <Text
                  style={{
                    fontFamily: "Prompt",
                    fontSize:
                      (size.width > 1600 && 40) ||
                      (size.width > 500 && 40) ||
                      (size.width < 500 && 14),
                    color: "#0B2051",
                    marginLeft: 15,
                  }}
                >
                  รอการตรวจและตอบกลับจากผู้สอน
                </Text>
              </View>
            )}

            <Modal
              transparent={true}
              visible={modalVisible}
              onRequestClose={() => {
                Alert.alert("Modal has been closed.");
                setModalVisible(!modalVisible);
              }}
            >
              <View style={styles.viewModal}>
                <View style={styles.viewDetailModal}>
                  <Text style={styles.textTitleModal}>
                    ยืนยันจะส่งคำตอบหรือไม่
                  </Text>
                  <View
                    style={{
                      flexDirection: "row",
                      marginTop: 80,
                      justifyContent: "space-between",
                    }}
                  >
                    <TouchableOpacity
                      onPress={() => {
                        if (route.params.type == "WRITING") {
                          onSubmit1();
                        } else {
                          onSubmit();
                        }
                        setPage(false);

                        setTimeout(() => {
                          checkStartFinish();
                          checkStart();
                        }, 500);
                        setModalVisible(!modalVisible);
                      }}
                      style={styles.buttonModal}
                    >
                      <Text style={styles.textButtonModal}>ตกลง</Text>
                    </TouchableOpacity>
                    <TouchableOpacity
                      onPress={() => {
                        setModalVisible(!modalVisible);
                      }}
                      style={styles.buttonModal1}
                    >
                      <Text
                        style={{
                          fontFamily: "Prompt",
                          fontSize: 30,
                          color: "#0D88F0",
                        }}
                      >
                        ยกเลิก
                      </Text>
                    </TouchableOpacity>
                  </View>
                </View>
              </View>
            </Modal>
            <Modal
              transparent={true}
              visible={modalVisible1}
              onRequestClose={() => {
                Alert.alert("Modal has been closed.");
                setModalVisible1(!modalVisible1);
              }}
            >
              <View style={styles.viewModal}>
                <View style={styles.viewDetailModal}>
                  <View style={{ alignItems: "center" }}>
                    <Image
                      style={{ width: 100, height: 100 }}
                      source={require("../img/Group 66.png")}
                    />
                    <Text style={[styles.textTitleModal, { marginTop: 40 }]}>
                      หมดเวลาทำแบบทดสอบ
                    </Text>
                    <TouchableOpacity
                      onPress={() => {
                        if (route.params.type == "WRITING") {
                          onSubmit1();
                        } else {
                          onSubmit();
                        }
                        setTimeout(() => {
                          checkStartFinish();
                          checkStart();
                        }, 500);
                        setPage(false);
                        setCheck(true);
                        setModalVisible1(!modalVisible1);
                      }}
                      style={[styles.buttonModal, { marginTop: 40 }]}
                    >
                      <Text style={styles.textButtonModal}>ตกลง</Text>
                    </TouchableOpacity>
                  </View>
                </View>
              </View>
            </Modal>
            <Modal
              transparent={true}
              visible={modalScore}
              onRequestClose={() => {
                Alert.alert("Modal has been closed.");
                setModalScore(!modalScore);
              }}
            >
              <View style={styles.viewModal}>
                <View
                  style={[
                    styles.viewDetailModal,
                    { maxWidth: 600, maxHeight: 500 },
                  ]}
                >
                  <View style={{}}>
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        paddingTop: 20,
                      }}
                    >
                      <Text
                        style={{
                          fontFamily: "Prompt",
                          fontSize: 30,
                          color: "#0B2051",
                        }}
                      >
                        ผลของคุณคือ
                      </Text>
                      <Text
                        style={{
                          fontFamily: "Prompt",
                          fontSize: 80,
                          color: "#0B2051",
                          marginLeft: 43,
                        }}
                      >
                        {too}%
                      </Text>
                    </View>
                    <Text
                      style={{
                        fontFamily: "Prompt",
                        fontSize: 20,
                        color: "#0B2051",
                        alignSelf: "center",
                        marginBottom: 40,
                      }}
                    >
                      สรุปการเรียนของคุณ
                    </Text>
                    <View style={{ flexDirection: "row" }}>
                      <View style={styles.viewDashedModal}>
                        <MaterialIcons
                          name="library-books"
                          size={40}
                          color="#0B2051"
                        />
                        <Text style={styles.text25}>
                          {show1.length}/{show1.length}
                        </Text>
                      </View>
                      <View
                        style={[
                          styles.viewDashedModal,
                          { justifyContent: "flex-end", borderRightWidth: 0 },
                        ]}
                      >
                        <MaterialIcons
                          name="archive"
                          size={40}
                          color="#0B2051"
                        />
                        <Text style={styles.text25}>
                          {show.filter((item) => item.type == "TEST").length}/
                          {show.filter((item) => item.type == "TEST").length}
                        </Text>
                      </View>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                      <View style={styles.viewDashedModal1}>
                        <MaterialIcons
                          name="live-help"
                          size={40}
                          color="#0B2051"
                        />
                        <Text style={styles.text25}>
                          {show.filter((item) => item.type == "WRITING").length}
                          /
                          {show.filter((item) => item.type == "WRITING").length}
                        </Text>
                      </View>
                      <View
                        style={{
                          flexDirection: "row",

                          justifyContent: "flex-end",
                          alignItems: "center",
                          width: "50%",
                        }}
                      >
                        <Text
                          style={{
                            fontFamily: "Prompt",
                            fontSize: 25,
                            color: "#0B2051",
                          }}
                        >
                          ผล
                        </Text>
                        <Text
                          style={{
                            fontFamily: "Prompt",
                            fontSize: 30,
                            color: "#74C745",
                            marginLeft: 25,
                          }}
                        >
                          ผ่าน
                        </Text>
                      </View>
                    </View>
                  </View>
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "center",
                      marginVertical: 30,
                    }}
                  >
                    {/* <TouchableOpacity
                    onPress={() => {
                      window.open(
                        `https://api.courseoncloud.com/api/image/export/${route.params.course_id}?uid=${users.uid}`
                      );
                      // https://api.courseoncloud.com/api/image/export/1?uid=32
                    }}
                    style={{
                      width: 141,
                      height: 44,
                      backgroundColor: "#0D88F0",
                      borderRadius: 5,
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 20,
                        fontFamily: "Prompt",
                        color: "#FFFFFF",
                      }}
                    >
                      Certificate
                    </Text>
                  </TouchableOpacity> */}
                    <TouchableOpacity
                      onPress={async () => {
                        setModalScore(!modalScore);
                        setUnLock(false);
                        const response = await apiservice({
                          path:
                            "/course/getall?page=1&lan=TH&course_id=" +
                            route.params.course_id,
                          method: "get",
                          //token: token?.accessToken,
                        });

                        navigation.navigate("Course", response.data.data[0]);
                      }}
                      style={{
                        width: 141,
                        height: 44,
                        borderWidth: 1,
                        borderColor: "#0D88F0",
                        borderRadius: 5,
                        justifyContent: "center",
                        alignItems: "center",
                        marginLeft: 60,
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 20,
                          fontFamily: "Prompt",
                          color: "#0D88F0",
                        }}
                      >
                        ดูคอร์ส
                      </Text>
                    </TouchableOpacity>
                  </View>
                  <Text
                    style={{
                      fontSize: 12,
                      fontFamily: "Prompt",
                      color: "#E82626",
                      alignSelf: "center",
                    }}
                  >
                    กรุณาติดต่อผู้สอนกรณีต้องการปรึกษาเรื่องผลการเรียน
                  </Text>
                </View>
              </View>
            </Modal>
          </View>
          {/* <View
          style={[
            styles.viewProgress,
            { backgroundColor: course.status ? "#74C745" : "#FFD54A" },
          ]}
        >
          <View style={styles.viewTextProgress}>
            <TouchableOpacity
              onPress={() => {
                // setUnLock(false);
                // navigation.goBack();
              }}
              style={{ margin: 10 }}
            >
              <MaterialCommunityIcons
                name="arrow-left-drop-circle"
                size={30}
                color="#FFFFFF"
              />
            </TouchableOpacity>
            <View>
              <Text style={[styles.textProgress, { textAlign: "left" }]}>
                บทก่อนหน้า
              </Text>
              <Text style={styles.textProgress}>{"ทำความรู้จักงานแนะแนว"}</Text>
            </View>
          </View>
          <TouchableOpacity
            disabled={true}
            style={[
              styles.buttonProgress,
              {
                backgroundColor: course.status ? "#E3F9D6" : "#FEF3CE",
              },
            ]}
          >
            <Text style={styles.textSuccess}>
              {course.status ? "เสร็จสิ้น" : "กำลังดำเนินการ.."}
            </Text>
          </TouchableOpacity>
          {route.params.dataStudymodel[route.params.Mainindex].listen_detail[
            route.params.index + 1
          ] != undefined ? (
            <View style={styles.viewTextProgress}>
              <View>
                <Text style={[styles.textProgress]}>บทถัดไป</Text>
                <Text style={styles.textProgress}>{"สรุปบทเรียนที่ 1"}</Text>
              </View>

              <TouchableOpacity
                onPress={() => {
                  const Mainindex = route.params.Mainindex;
                  const i = route.params.index;
                  const newi = route.params.index + 1;
         
                  if (
                    route.params.dataStudymodel[Mainindex].listen_detail[
                      i + 1
                    ] != undefined
                  ) {
                    Chanfd(
                      route.params.dataStudymodel[Mainindex].listen_detail[
                        i + 1
                      ],
                      Mainindex,
                      newi
                    );
                  } else {
                    setModalScore(true);
                  }
                }}
                disabled={course.status ? false : true}
                style={{ margin: 10 }}
              >
                <MaterialCommunityIcons
                  name={course.status ? "arrow-right-drop-circle" : "lock"}
                  size={30}
                  color="#FFFFFF"
                />
              </TouchableOpacity>
            </View>
          ) : (
            <Text></Text>
          )}
        </View> */}
          <View
            style={{
              width: "100%",
              height: 70,
              backgroundColor: defaultsStatus ? "#0D88F0" : "#D9D9D9",
              justifyContent: "center",
            }}
          >
            <View
              style={[
                styles.viewProgress,
                { backgroundColor: defaultsStatus ? "#0D88F0" : "#D9D9D9" },
              ]}
            >
              {route.params.Mainindex == 0 && route.params.index == 0 ? (
                <View style={styles.viewTextProgress}>
                  <TouchableOpacity style={{ margin: 10 }}>
                    <MaterialCommunityIcons
                      name="arrow-left-drop-circle"
                      size={0}
                      color="#FFFFFF"
                    />
                  </TouchableOpacity>
                  <View>
                    <Text
                      style={[styles.textProgress, { textAlign: "left" }]}
                    ></Text>
                    <Text numberOfLines={1} style={styles.textProgress}></Text>
                  </View>
                </View>
              ) : (
                <View style={styles.viewTextProgress}>
                  <TouchableOpacity
                    onPress={async () => {
                      const Mainindex = route.params.Mainindex;
                      const i = route.params.index;
                      const newi = route.params.index - 1;

                      if (
                        route.params.dataStudymodel[Mainindex].listen_detail[
                          i - 1
                        ] != undefined
                      ) {
                        Chanfd(
                          route.params.dataStudymodel[Mainindex].listen_detail[
                            i - 1
                          ],
                          Mainindex,
                          newi
                        );
                      } else {
                        Chanfd(
                          route.params.dataStudymodel[Mainindex - 1]
                            .listen_detail[
                            route.params.dataStudymodel[Mainindex - 1]
                              .listen_detail.length - 1
                          ],
                          Mainindex - 1,
                          route.params.dataStudymodel[Mainindex - 1]
                            .listen_detail.length - 1
                        );
                      }
                    }}
                    style={{ margin: 10 }}
                  >
                    <MaterialCommunityIcons
                      name="arrow-left-drop-circle"
                      size={30}
                      color="#FFFFFF"
                    />
                  </TouchableOpacity>
                  <View>
                    <Text style={[styles.textProgress, { textAlign: "left" }]}>
                      บทก่อนหน้า
                    </Text>
                    <Text numberOfLines={1} style={styles.textProgress}>
                      {route.params.dataStudymodel[route.params.Mainindex]
                        .listen_detail[route.params.index - 1] != undefined
                        ? route.params.dataStudymodel[route.params.Mainindex]
                            .listen_detail[route.params.index - 1].name
                        : route.params.dataStudymodel[
                            route.params.Mainindex - 1
                          ].listen_detail[
                            route.params.dataStudymodel[
                              route.params.Mainindex - 1
                            ].listen_detail.length - 1
                          ].name}
                    </Text>
                  </View>
                </View>
              )}
              <TouchableOpacity
                disabled={true}
                style={[
                  styles.buttonProgress,
                  {
                    backgroundColor: defaultsStatus ? "#E3F9D6" : "#FEF3CE",
                    width: size.width < 500 ? 100 : 150,
                  },
                ]}
              >
                <Text style={styles.textSuccess}>
                  {defaultsStatus ? "เสร็จสิ้น" : "กำลังดำเนินการ.."}
                </Text>
              </TouchableOpacity>{" "}
              {route.params.dataStudymodel ? (
                <View
                  style={[
                    styles.viewTextProgress,
                    { justifyContent: "flex-end" },
                  ]}
                >
                  <View>
                    <Text style={[styles.textProgress]}>บทถัดไป</Text>
                    <Text numberOfLines={1} style={styles.textProgress}>
                      {route.params.dataStudymodel[route.params.Mainindex]
                        .listen_detail[route.params.index + 1] != undefined
                        ? route.params.dataStudymodel[route.params.Mainindex]
                            .listen_detail[route.params.index + 1].name
                        : route.params.dataStudymodel[
                            route.params.Mainindex + 1
                          ] != undefined
                        ? route.params.dataStudymodel[
                            route.params.Mainindex + 1
                          ].listen_detail[0].name
                        : ""}
                    </Text>
                  </View>
                  <TouchableOpacity
                    onPress={async () => {
                      const Mainindex = route.params.Mainindex;
                      const i = route.params.index;
                      const newi = route.params.index + 1;

                      if (
                        route.params.dataStudymodel[Mainindex].listen_detail[
                          i + 1
                        ] != undefined
                      ) {
                        Chanfd(
                          route.params.dataStudymodel[Mainindex].listen_detail[
                            i + 1
                          ],
                          Mainindex,
                          newi
                        );
                      } else if (
                        route.params.dataStudymodel[Mainindex + 1]
                          .listen_detail[0] != undefined
                      ) {
                        Chanfd(
                          route.params.dataStudymodel[Mainindex + 1]
                            .listen_detail[0],
                          Mainindex + 1,
                          -1
                        );
                      } else {
                        const respp = await apiservice({
                          path:
                            "/start-course/getalltotals/" +
                            route.params.course_id,
                          method: "get",
                          token: token?.accessToken,
                        });
                        const resppt = await apiservice({
                          path:
                            "/start-course/getworktotals/" +
                            route.params.course_id,
                          method: "get",
                          token: token?.accessToken,
                        });
                        if (respp.status == 200) {
                          setShow1(resppt.data.results);
                          setShow(respp.data.results);
                          setModalScore(true);
                        }
                      }
                    }}
                    disabled={defaultsStatus ? false : true}
                    style={{ margin: 10 }}
                  >
                    <MaterialCommunityIcons
                      name={defaultsStatus ? "arrow-right-drop-circle" : "lock"}
                      size={30}
                      color="#FFFFFF"
                    />
                  </TouchableOpacity>
                </View>
              ) : (
                <View style={styles.viewTextProgress}>
                  <TouchableOpacity style={{ margin: 10 }}>
                    <MaterialCommunityIcons
                      name="arrow-right-drop-circle"
                      size={0}
                      color="#FFFFFF"
                    />
                  </TouchableOpacity>
                  <View>
                    <Text
                      style={[styles.textProgress, { textAlign: "right" }]}
                    ></Text>
                    <Text numberOfLines={1} style={styles.textProgress}></Text>
                  </View>
                </View>
              )}
            </View>
          </View>
        </View>
      </ScrollView>
    </View>
  );
}
const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: "100%",
    backgroundColor: "#FFFFFF",
  },
  viewProgress: {
    width: "100%",
    height: 70,
    flexDirection: "row",
    justifyContent: "space-between",
  },
  viewTextProgress: {
    flexDirection: "row",
    alignItems: "center",
    width: "30%",
  },
  textProgress: {
    fontFamily: "Prompt",
    fontSize: 12,
    color: "#FFFFFF",
    textAlign: "right",
  },
  buttonProgress: {
    width: 150,
    height: 40,
    borderRadius: 20,
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "center",
  },
  textSuccess: {
    fontFamily: "Prompt",
    fontSize: 12,
    color: "#0B2051",
  },
  viewModal: {
    width: "100%",
    height: "100%",
    backgroundColor: "#000000bb",
    justifyContent: "center",
  },
  viewDetailModal: {
    width: "100%",
    maxWidth: 650,
    height: "100%",
    maxHeight: 400,
    backgroundColor: "#FFFFFF",
    borderWidth: 1,
    borderColor: "#0D88F0",
    padding: 50,
    paddingHorizontal: width * 0.05,
    alignSelf: "center",
    justifyContent: "center",
  },
  buttonModal: {
    width: 150,
    height: 50,
    backgroundColor: "#0D88F0",
    borderRadius: 5,
    justifyContent: "center",
    alignItems: "center",
  },
  textButtonModal: {
    fontFamily: "Prompt",
    fontSize: 30,
    color: "#FFFFFF",
  },
  textTitleModal: {
    fontFamily: "Prompt",
    fontSize: 40,
    color: "#0B2051",
    alignSelf: "center",
  },
  buttonModal1: {
    width: 150,
    height: 50,
    borderWidth: 1,
    borderColor: "#0D88F0",
    borderRadius: 5,
    justifyContent: "center",
    alignItems: "center",
  },
  textDetailModal: {
    fontFamily: "Prompt",
    fontSize: 18,
    color: "#0B2051",
  },
  viewDashedModal: {
    flexDirection: "row",
    borderRightWidth: 2,
    borderBottomWidth: 2,
    borderRightColor: "#0D88F0",
    borderBottomColor: "#0D88F0",
    width: "50%",
    borderStyle: "dashed",
    alignItems: "center",
    paddingVertical: 20,
  },
  viewDashedModal1: {
    flexDirection: "row",
    borderRightWidth: 2,
    borderRightColor: "#0D88F0",
    width: "50%",
    borderStyle: "dashed",
    alignItems: "center",
    paddingVertical: 20,
  },
  text25: {
    fontFamily: "Prompt",
    fontSize: 25,
    color: "#0B2051",
    marginLeft: 25,
  },
  viewCkEditor: {
    borderWidth: 1,
    borderColor: "#0D88F0",
    marginTop: 55,
  },
});
