import React, { useEffect, useState, useRef } from "react";
import {
  View,
  Text,
  Image,
  Dimensions,
  Animated,
  TouchableOpacity,
  StyleSheet,
  ScrollView,
  TextInput,
  FlatList,
} from "react-native";
import "react-calendar/dist/Calendar.css";
import {
  MaterialCommunityIcons,
  MaterialIcons,
  Ionicons,
} from "@expo/vector-icons";
import Header from "../components/header";
import { BottomPart, bottomPart } from "./home";
import Modal from "modal-react-native-web";
import RNPickerSelect from "react-native-picker-select";
import InputCourse from "../components/inputCourse";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import * as DocumentPicker from "expo-document-picker";
import Calendar from "react-calendar";
import { apiservice } from "../../service";
import { useRecoilValue } from "recoil";
import { tokenState } from "../../reducer/recoil";
import moment from "moment";
import Autocomplete from "react-native-autocomplete-input";
import NumberFormat from "react-number-format";
import { useWindowSize } from "../components/responsive";
const { width, height } = Dimensions.get("window");
let selectData = ["คำอธิบาย", "บทเรียน", "ข้อมูลผู้สอน", "FAQ"];
let selectData1 = [
  "เนื้อหาบทเรียน",
  "งานที่มอบหมาย",
  "ข้อสอบปรนัย",
  "ข้อสอบอัตนัย",
];

export default function addCourse({ navigation, route }) {
  const token = useRecoilValue(tokenState);
  const [certificate, setCertificate] = useState(false);
  const [startD, setStartD] = useState(false);
  const [endD, setEndD] = useState(false);
  const [modalCourse, setModalCourse] = useState(false);
  const [modalWork, setModalWork] = useState(false);
  const [modalDatework, setModalDatework] = useState(false);
  const [modalTest, setModalTest] = useState(false);
  const [modalTest1, setModalTest1] = useState(false);
  const [modalSucceed, setModalSucceed] = useState(false);
  const [modalSucceed1, setModalSucceed1] = useState(false);
  const [modalTag, setModalTag] = useState(false);
  const [modalTeacher, setModalTeacher] = useState(false);
  const [modalStudyModel, setmodalStudyModel] = useState(false);
  const [modalWarning, setmodalmodalWarning] = useState(false);
  const [modalWarningTime, setmodalmodalWarningTime] = useState(false);
  const [page, setPage] = useState(0);

  const [pageCourse, setPageCourse] = useState(0);
  const [pageWork, setPageWork] = useState(0);
  const [pageTest, setPageTest] = useState(0);
  const [setting, setSetting] = useState(false);
  const [text, setText] = useState(0);
  const [choiceIndex, setChoiceIndex] = useState(-1);
  const [select, setSelect] = useState(0);
  const [selectQuestion, setSelectQuestion] = useState("1");
  const [title, setTitle] = useState("");
  const [workDate, setWorkDate] = useState({});
  const [openDate, setopenDate] = useState(false);
  const [openDateStart, setopenDateStart] = useState(false);
  const [openDateEnd, setopenDateEnd] = useState(false);
  const [tag, setTag] = useState("");
  const [teacher, setTeacher] = useState("");
  const [autoText, setAutotext] = useState("");
  const [data, setData] = useState([]);
  const [user_accounts, setUser_accounts] = useState({ person: [] });

  const [modalRecord, setmodalRecord] = useState(false);
  const [warningTitle, setWarningTitle] = useState(false);
  const [warningFaculty, setWarningfaculty] = useState(false);
  const [warningGrade, setWarningGrade] = useState(false);
  const [warningDetail, setWarningDetail] = useState(false);
  const [warningPercent, setWarningPercent] = useState(false);
  const [warningLevel, setWarningLevel] = useState(false);
  const [warningPerson, setWarningPerson] = useState(false);
  const [warningTime, setWarningTime] = useState(false);
  const [warningImg, setWarningImg] = useState(false);
  const [warningAbout, setWarningAbout] = useState(false);
  const [warningCategory, setWarningCategory] = useState(false);
  const [warningdegree, setWarningDegree] = useState(false);
  const [warningClock, setWarningClock] = useState(false);
  const [profile, setProfile] = useState();
  const [heightList, setheightList] = useState(0);

  const [Clock, setClock] = useState(
    "เวลาในการทำข้อสอบอัตนัยต้องอย่างน้อย 1 นาที"
  );
  const [warningSelectLesson, setWarningSelectLesson] = useState(true);
  const [show, setShow] = useState(true);
  let dataEdit = route.params.editInfo;

  const size = useWindowSize();
  const defaultModelrecent = (val) => {
    setModelRecent({ ...modelrecent, TYPE: "" });
    setModelRecent({
      time: "",
      telNo: "",
      document: [],
      detail: {
        image: "",
        detail: "",
      },
    });
    setwork({
      image: "",
      detail: "",
      limit: 0,
      limitdate: null,
    });
    setQuiz({
      TYPE: "QUIZ",
      img: {
        image: "",
        width: "",
        height: "",
      },
      detail: "",
      question: [],
      ans: [],
      conditions: {
        percent: "50",
        timer: 0,
        timerType: "MINUTE",
        randomquestion: false,
        randomanswer: false,
        retest: false,
        showanswer: false,
      },
    });
  };
  const [modelrecent, setModelRecent] = useState({
    TYPE: "",
    time: "",
    telNo: "",
    document: [],
    detail: {
      image: "",
      detail: "",
    },
  });

  const [quiz, setQuiz] = useState({
    TYPE: "QUIZ",
    img: {
      image: "",
      width: "",
      height: "",
    },
    detail: "",
    question: [],
    ans: [],
    conditions: {
      percent: "100",
      timer: 0,
      timerType: "MINUTE",
      randomquestion: false,
      randomanswer: false,
      retest: false,
      showanswer: false,
    },
  });

  function setcer(params) {
    setbody((val) => ({ ...val, cer_id: params }));
  }

  const [work, setwork] = useState({
    image: "",
    detail: "",
    limit: 0,
    limitdate: null,
  });
  const [image, setImage] = useState();
  const [workD, setWorkD] = useState(false);
  const [body, setbody] = useState({
    Type: route.params.university_id ? "COURSESCHOOL" : "COURSE",
    TAG: [],
    person: [],
    title: "",
    about: "",
    faculty_id: null,
    img_content: "",
    price: 0,
    grade_id: "",
    approve: "",
    grade: "",
    category_id: null,
    length_detail: "",
    duration_study: "",
    university_id: route.params.university_id,
    percent: 0,
    level: "",
    certificate: false,
    meetingurl: "",
    workDate: [],
    datetime: "",
    startDate: new Date(),
    endDate: moment(new Date()).add(1, "days"),
    certificate_detail: {},
    available: "DRAFT",
    educationmodel: [1],
    studymodel: [
      {
        number: 0,
        listen_name: "",
        tab: 0,
        listen_detail: [],
        textinputs: "",
      },
    ],
    cer_id: null,
    uid: route.params.uid,
    FAQ: [],
    announcement: " ข้อมูลผู้สอน.... ",
    vdo_url: "",
  });

  const placeholder = {
    label: "เลือกคณะ ..",
    value: null,
    color: "#FFD7C9",
  };
  const [status, setStatus] = useState([]);
  const [category, setCategory] = useState([]);
  const [days, setDays] = useState([
    { label: "อาทิตย์", value: "SUN" },
    { label: "จันทร์", value: "MON" },
    { label: "อังคาร", value: "TUES" },
    { label: "พุธ", value: "WED" },
    { label: "พฤหัส", value: "THUS" },
    { label: "ศุกร์", value: "FRI" },
    { label: "เสาร์", value: "SAT" },
  ]);
  const [timer, setTimer] = useState([
    { label: "00:00", value: "00:00" },
    { label: "00:30", value: "00:30" },
    { label: "01:00", value: "01:00" },
    { label: "01:30", value: "01:30" },
    { label: "02:00", value: "02:00" },
    { label: "02:30", value: "02:30" },
    { label: "03:00", value: "03:00" },
    { label: "03:30", value: "03:30" },
    { label: "04:00", value: "04:00" },
    { label: "04:30", value: "04:30" },
    { label: "05:00", value: "05:00" },
    { label: "05:30", value: "05:30" },
    { label: "06:00", value: "06:00" },
    { label: "06:30", value: "06:30" },
    { label: "07:00", value: "07:00" },
    { label: "07:30", value: "07:30" },
    { label: "08:00", value: "08:00" },
    { label: "08:30", value: "08:30" },
    { label: "09:00", value: "09:00" },
    { label: "09:30", value: "09:30" },
    { label: "10:00", value: "10:00" },
    { label: "10:30", value: "10:30" },
    { label: "11:00", value: "11:00" },
    { label: "11:30", value: "11:30" },
    { label: "12:00", value: "12:00" },
    { label: "12:30", value: "12:30" },
    { label: "13:00", value: "13:00" },
    { label: "13:30", value: "13:30" },
    { label: "14:00", value: "14:00" },
    { label: "14:30", value: "14:30" },
    { label: "15:00", value: "15:00" },
    { label: "15:30", value: "15:30" },
    { label: "16:00", value: "16:00" },
    { label: "16:30", value: "16:30" },
    { label: "17:00", value: "17:00" },
    { label: "17:30", value: "17:30" },
    { label: "18:00", value: "18:00" },
    { label: "18:30", value: "18:30" },
    { label: "19:00", value: "19:00" },
    { label: "19:30", value: "19:30" },
    { label: "20:00", value: "20:00" },
    { label: "20:30", value: "20:30" },
    { label: "21:00", value: "21:00" },
    { label: "21:30", value: "21:30" },
    { label: "22:00", value: "22:00" },
    { label: "22:30", value: "22:30" },
    { label: "23:00", value: "23:00" },
    { label: "23:30", value: "23:30" },
  ]);

  const [levels, setLevels] = useState([
    { label: "Beginner", value: "BEGINNER" },
    { label: "Intermediate", value: "INTERMEDIATE" },
    { label: "Advanced", value: "ADVANCED" },
  ]);
  const placeholder1 = {
    label: "",
    value: null,
    color: "#FCBDA6",
  };
  const placeholder2 = {
    label: "คำตอบเดียว",
    value: "1",
    color: "#0B2051",
  };
  const placeholder3 = {
    label: "นาที",
    value: "MINUTE",
    color: "#0B2051",
  };

  const [classYear, setClassYear] = useState([
    { label: "ปี 1", value: "1" },
    { label: "ปี 2", value: "2" },
    { label: "ปี 3", value: "3" },
    { label: "ปี 4", value: "4" },
  ]);
  const [degree, setDegree] = useState([
    { label: "ปริญญาตรี", value: "0" },
    { label: "ปริญญาโท", value: "1" },
    { label: "ปริญญาเอก", value: "2" },
  ]);
  const [study, setStudy] = useState([
    { label: "บทเรียนเนื้อหา", value: "DETAIL" },
    { label: "บทเรียนวิดีโอ", value: "VDO" },
    // { label: "บรรยายสด", value: "ZOOM" },
  ]);
  const [question, setQuestion] = useState([
    { label: "หลายคำตอบ", value: "2" },
    { label: "ถูก หรือ ผิด", value: "3" },
    { label: "จับคู่ข้อความ", value: "4" },
    { label: "เติมคำในช่องว่าง", value: "5" },
  ]);
  const [score, setScore] = useState([
    { label: "50%", value: "50" },
    { label: "55%", value: "55" },
    { label: "60%", value: "60" },
    { label: "65%", value: "65" },
    { label: "70%", value: "70" },
    { label: "75%", value: "75" },
    { label: "80%", value: "80" },
    { label: "85%", value: "85" },
    { label: "90%", value: "90" },
    { label: "95%", value: "95" },
    { label: "100%", value: "100" },
  ]);
  const [dated, setdated] = useState([
    { label: "ไม่กำหนดเวลา", value: "false" },
    { label: "กำหนดเวลา", value: "true" },
  ]);
  const [time, setTime] = useState([{ label: "ชั่วโมง", value: "HOUR" }]);
  async function pdf() {
    let result = await DocumentPicker.getDocumentAsync({
      type: "application/pdf,application/msword",
    });
    // setImage(result.uri)
    const response = await apiservice({
      path: "/image/createpdf",
      method: "post",
      body: {
        name: result.name,
        base64: result.uri,
      },
    });

    setModelRecent((val) => ({
      ...val,
      document: val.document.concat({
        name: result.name,
        files: response.data.imageRefId,
      }),
    }));
  }
  async function getProfile(token) {
    const response = await apiservice({
      path: "/authen/user",
      method: "get",
      token: token?.accessToken,
    });
    if (response.status == 200) {
      setProfile(response?.data?.result);
    }
  }
  async function imagecouse() {
    let result = await DocumentPicker.getDocumentAsync({
      type: "image/*",
    });
    // setImage(result.uri)
    const response = await apiservice({
      path: "/image/createpdf",
      method: "post",
      body: {
        name: result.name,
        base64: result.uri,
      },
    });

    setModelRecent((val) => ({
      ...val,
      detail: {
        ...val.detail,
        image: response.data.imageRefId,
      },
    }));
  }
  async function images() {
    let result = await DocumentPicker.getDocumentAsync({
      type: "image/*",
    });

    const response = await apiservice({
      path: "/image/createpdf",
      method: "post",
      body: {
        name: result.name,
        base64: result.uri,
      },
    });
  }

  async function imageQuiz() {
    let result = await DocumentPicker.getDocumentAsync({
      type: "image/*",
    });
    // setImage(result.uri)
    const response = await apiservice({
      path: "/image/createpdf",
      method: "post",
      body: {
        name: result.name,
        base64: result.uri,
      },
    });

    setQuiz((val) => ({
      ...val,
      img: {
        ...val.img,
        image: response.data.imageRefId,
      },
    }));
  }

  async function imagework() {
    let result = await DocumentPicker.getDocumentAsync({
      type: "image/*",
    });
    // setImage(result.uri)
    const response = await apiservice({
      path: "/image/createpdf",
      method: "post",
      body: {
        name: result.name,
        base64: result.uri,
      },
    });

    setwork((val) => ({
      ...val,
      image: response.data.imageRefId,
    }));
  }

  const DATA1 = [{ no: "1" }, { no: "2" }, { no: "3" }, { no: "4" }];
  const DATA2 = [{ name: "ถูก" }, { name: "ผิด" }];
  const DATA3 = [{ name: "" }, { name: "" }, { name: "" }];
  function onPressSetting() {
    if (modelrecent.TYPE == "DETAIL") {
      setPageCourse(1);
    }
    if (modelrecent.TYPE == "VDO") {
      setPageCourse(2);
    }
    if (modelrecent.TYPE == "ZOOM") {
      setPageCourse(3);
    }
  }
  const choiceChangeHandler = (index) => {
    setChoiceIndex((preIndex) => index);
  };
  async function pickupVideo() {
    let result = await DocumentPicker.getDocumentAsync({
      type: "video/mp4",
    });

    // const response = await apiservice({
    //   path: "/image/createvdo",
    //   method: "post",
    //   body: {
    //     name: result.name.replace(".mp4", ""),
    //     base64: result.uri,
    //   },
    // });
  }
  async function pickup() {
    let result = await DocumentPicker.getDocumentAsync({ type: "image/*" });
    setImage(result.uri);
    const response = await apiservice({
      path: "/image/createpdf",
      method: "post",
      body: {
        // name: result.name.replace(".png", ""),
        name: moment().format("DD-MM-YYYY-HH-mm-ss"),
        base64: result.uri,
      },
    });

    setbody({
      ...body,
      img_content: response.data.imageRefId.replace(".png", ""),
    });
    setWarningImg(false);
  }

  useEffect(() => {
    if (route.params.editInfo) {
      setShow(false);
      setbody(route.params.editInfo);
      setTimeout(() => {
        setShow(true);
      }, 300);
    }
  }, [token]);
  useEffect(() => {
    getProfile(token);
    getFaculty(token);
    getCategory(token);
    getTeacher(1);
    if (
      route.params.university_id != null &&
      route.params.university_id != "null"
    ) {
      setbody((val) => ({ ...val, approve: "STUDENT" }));
    }
  }, [autoText]);
  async function getFaculty(token) {
    try {
      const response = await apiservice({
        path:
          "/course/getfaculty?page=1&totalpage=100&university_id=" +
          route.params.university_id,
        method: "get",
        token: token?.accessToken,
      });
      if (response.status == 200) {
        const Psetitem1 = response.data.result.map((item) => {
          // major: "1"
          let data = [];
          if (item.major == "0") {
            data = "(ป.ตรี)";
          } else if (item.major == "1") {
            data = "(ป.โท)";
          } else if (item.major == "2") {
            data = "(ป.เอก)";
          }
          return {
            label:
              item.facuty_name_th +
              " (" +
              item.major_name_th +
              ")" +
              "  " +
              data,
            value: item.id.toString(),
          };
        });

        setTimeout(() => {
          setStatus(Psetitem1);
        }, 500);
      }
    } catch (error) {
      throw error;
    }
  }
  async function getCategory(token) {
    try {
      const response = await apiservice({
        path: "/category/getall?page=1&totalpage=100",
        method: "get",
        token: token?.accessToken,
      });
      if (response.status == 200) {
        const Psetitem2 = response.data.result.map((item) => {
          return { label: item.title, value: item.id.toString() };
        });
        setTimeout(() => {
          setCategory(Psetitem2);
        }, 500);
      }
    } catch (error) {
      throw error;
    }
  }
  async function getTeacher() {
    const response = await apiservice({
      path:
        "/authen/getfindteacher?university_id=" +
        route.params.university_id +
        "&page=1&totalpage=100&search=" +
        autoText,
      method: "get",
    });

    if (response.status == 200) {
      if (autoText.length > 0) {
        setData(response.data.result);
      }
    } else {
    }
  }
  const ref = useRef();

  async function create() {
    if (body.available == "APPROVE") {
      if (
        body.studymodel.filter((item) => {
          return (
            item.listen_detail.filter((items) => {
              return items.type == "EXAM";
            }).length > 0
          );
        }).length == 0
      ) {
        setmodalStudyModel(true);
        return;
      }

      let bodys = {
        ...body,
        available: body?.available == "APPROVE" ? "APPROVE" : "PENDING",
        TAGtext: JSON.stringify(body.TAG),
        vdo_url:
          body?.vdo_url?.length > 0
            ? "https://" +
              body?.vdo_url?.replace("https://", "")?.replace("http://", "")
            : "",
      };
      const response = await apiservice({
        path: "/course/updatecourse",
        method: "put",
        body: {
          ...bodys,
          available: bodys.available == "DRAFT" ? "PENDING" : bodys.available,
        },
        token: token?.accessToken,
      });
      if (response.status == 200) {
        setTimeout(() => {
          setbody({
            Type: "COURSESCHOOL",
            TAG: [],
            person: [],
            title: "",
            about: "",
            img_content: "",
            price: 0,
            approve: "",
            grade: "",
            grade_id: "",
            length_detail: "",
            duration_study: "",
            percent: 80,
            meetingurl: "",
            workDate: [],
            datetime: "",
            startDate: new Date(),
            endDate: moment(new Date()).add(1, "days"),
            level: "",
            certificate: false,
            certificate_detail: {},
            available: "DRAFT",
            educationmodel: [1],
            studymodel: [
              {
                number: 0,
                listen_name: "",
                tab: 0,
                listen_detail: [],
                textinputs: "",
              },
            ],
            cer_id: 1,
            uid: route.params.uid,
            FAQ: [
              {
                Q: "",
                A: "",
              },
            ],
            announcement: " ข้อมูลผู้สอน.... ",
            vdo_url: "",
          });
          setModalSucceed1(true);
        }, 1000);
      }
    } else {
      if (
        body.about != "" &&
        body.title != "" &&
        body.faculty_id != "" &&
        body.grade != "" &&
        body.grade_id != "" &&
        body.length_detail != "" &&
        body.percent != "" &&
        body.level != "" &&
        body.datetime != ""
        // body.category_id != ""
      ) {
        if (
          body.studymodel.filter((item) => {
            return (
              item.listen_detail.filter((items) => {
                return items.type == "EXAM";
              }).length > 0
            );
          }).length == 0
        ) {
          setmodalStudyModel(true);
        } else if (body.available == "DRAFT" && body.id != null) {
          let bodys = {
            ...body,
            // available: "APPROVE",
            TAGtext: JSON.stringify(body.TAG),
            vdo_url:
              body?.vdo_url?.length > 0
                ? "https://" +
                  body?.vdo_url?.replace("https://", "")?.replace("http://", "")
                : "",
          };
          const response = await apiservice({
            path: "/course/updatecourse",
            method: "put",
            body: {
              ...bodys,
              available:
                bodys.available == "DRAFT" ? "PENDING" : bodys.available,
            },
            token: token?.accessToken,
          });
          if (response.status == 200) {
            setTimeout(() => {
              setbody({
                Type: "COURSESCHOOL",
                TAG: [],
                person: [],
                title: "",
                about: "",
                img_content: "",
                price: 0,
                approve: "",
                grade: "",
                grade_id: "",
                length_detail: "",
                duration_study: "",
                percent: 80,
                meetingurl: "",
                workDate: [],
                datetime: "",
                startDate: new Date(),
                endDate: moment(new Date()).add(1, "days"),
                level: "",
                certificate: false,
                certificate_detail: {},
                available: "DRAFT",
                educationmodel: [1],
                studymodel: [
                  {
                    number: 0,
                    listen_name: "",
                    tab: 0,
                    listen_detail: [],
                    textinputs: "",
                  },
                ],
                cer_id: 1,
                uid: route.params.uid,
                FAQ: [
                  {
                    Q: "",
                    A: "",
                  },
                ],
                announcement: " ข้อมูลผู้สอน.... ",
                vdo_url: "",
              });
              setModalSucceed(true);
            }, 1000);
          }
        } else {
          let bodys = {
            ...body,
            available: "PENDING",
            rating: 0,
            TAGtext: JSON.stringify(body.TAG),
            vdo_url:
              body?.vdo_url?.length > 0
                ? "https://" +
                  body?.vdo_url?.replace("https://", "")?.replace("http://", "")
                : "",
          };
          const response = await apiservice({
            path:
              body.available == "APPROVE"
                ? "/course/updatecourse"
                : "/course/create",
            method: body.available == "APPROVE" ? "put" : "post",
            body: bodys,
            token: token?.accessToken,
          });
          if (response.status == 200) {
            setTimeout(() => {
              setbody({
                Type: "COURSESCHOOL",
                TAG: [],
                person: [],
                title: "",
                about: "",
                img_content: "",
                price: 0,
                approve: "",
                grade: "",
                grade_id: "",
                length_detail: "",
                duration_study: "",
                percent: 80,
                meetingurl: "",
                workDate: [],
                datetime: "",
                startDate: new Date(),
                endDate: moment(new Date()).add(1, "days"),
                level: "",
                certificate: false,
                certificate_detail: {},
                available: "DRAFT",
                educationmodel: [1],
                studymodel: [
                  {
                    number: 0,
                    listen_name: "",
                    tab: 0,
                    listen_detail: [],
                    textinputs: "",
                  },
                ],
                cer_id: 1,
                uid: route.params.uid,
                FAQ: [
                  {
                    Q: "",
                    A: "",
                  },
                ],
                announcement: " ข้อมูลผู้สอน.... ",
                vdo_url: "",
              });
              setModalSucceed(true);
            }, 1000);
          }
        }
      }
      if (body.about == "") {
        setWarningAbout(true);
      }
      if (body.title == "") {
        setWarningTitle(true);
      }
      if (body.faculty_id == null) {
        setWarningfaculty(true);
      }
      if (body.grade == "") {
        setWarningGrade(true);
      }
      if (body.length_detail == "") {
        setWarningDetail(true);
      }
      if (body.percent == "") {
        setWarningPercent(true);
      }
      if (body.level == "") {
        setWarningLevel(true);
      }
      if (body.datetime == "") {
        setWarningTime(true);
      }
      // if (body.img_content == "") {
      //   setWarningImg(true);
      // }
      // if (body.category_id == null) {
      //   setWarningCategory(true);
      // }
      if (body.grade_id == "") {
        setWarningDegree(true);
      }
    }
  }

  async function createGeneral() {
    if (body.available == "APPROVE") {
      let bodys = {
        ...body,
        // available: "APPROVE",
        TAGtext: JSON.stringify(body.TAG),
        vdo_url:
          body?.vdo_url?.length > 0
            ? "https://" +
              body?.vdo_url?.replace("https://", "")?.replace("http://", "")
            : "",
      };
      const response = await apiservice({
        path: "/course/updatecourse",
        method: "put",
        body: {
          ...bodys,
          available: bodys.available == "DRAFT" ? "PENDING" : bodys.available,
        },
        token: token?.accessToken,
      });
      if (response.status == 200) {
        setTimeout(() => {
          setbody({
            Type: "COURSESCHOOL",
            TAG: [],
            person: [],
            title: "",
            about: "",
            img_content: "",
            price: 0,
            approve: "",
            grade: "",
            grade_id: "",
            length_detail: "",
            duration_study: "",
            percent: 80,
            meetingurl: "",
            workDate: [],
            datetime: "",
            startDate: new Date(),
            endDate: moment(new Date()).add(1, "days"),
            level: "",
            certificate: false,
            certificate_detail: {},
            available: "DRAFT",
            educationmodel: [1],
            studymodel: [
              {
                number: 0,
                listen_name: "",
                tab: 0,
                listen_detail: [],
                textinputs: "",
              },
            ],
            cer_id: 1,
            uid: route.params.uid,
            FAQ: [
              {
                Q: "",
                A: "",
              },
            ],
            announcement: " ข้อมูลผู้สอน.... ",
            vdo_url: "",
          });
          setModalSucceed1(true);
        }, 1000);
      }
    } else {
      if (
        body.about != "" &&
        body.title != "" &&
        body.length_detail != "" &&
        body.percent != "" &&
        body.level != "" &&
        body.img_content != "" &&
        body.category_id != null
        // body.category_id != ""
      ) {
        if (
          body.studymodel.filter((item) => {
            return (
              item.listen_detail.filter((items) => {
                return items.type == "EXAM";
              }).length > 0
            );
          }).length == 0
        ) {
          setmodalStudyModel(true);
        } else if (body.available == "DRAFT" && body.id != null) {
          let bodys = {
            ...body,
            // available: "APPROVE",
            TAGtext: JSON.stringify(body.TAG),
            vdo_url:
              body?.vdo_url?.length > 0
                ? "https://" +
                  body?.vdo_url?.replace("https://", "")?.replace("http://", "")
                : "",
          };
          const response = await apiservice({
            path: "/course/updatecourse",
            method: "put",
            body: {
              ...bodys,
              available:
                bodys.available == "DRAFT" ? "PENDING" : bodys.available,
            },
            token: token?.accessToken,
          });
          if (response.status == 200) {
            setTimeout(() => {
              setbody({
                Type: "COURSESCHOOL",
                TAG: [],
                person: [],
                title: "",
                about: "",
                img_content: "",
                price: 0,
                approve: "",
                grade: "",
                grade_id: "",
                length_detail: "",
                duration_study: "",
                percent: 80,
                meetingurl: "",
                workDate: [],
                datetime: "",
                startDate: new Date(),
                endDate: moment(new Date()).add(1, "days"),
                level: "",
                certificate: false,
                certificate_detail: {},
                available: "DRAFT",
                educationmodel: [1],
                studymodel: [
                  {
                    number: 0,
                    listen_name: "",
                    tab: 0,
                    listen_detail: [],
                    textinputs: "",
                  },
                ],
                cer_id: 1,
                uid: route.params.uid,
                FAQ: [
                  {
                    Q: "",
                    A: "",
                  },
                ],
                announcement: " ข้อมูลผู้สอน.... ",
                vdo_url: "",
              });
              setModalSucceed(true);
            }, 1000);
          }
        } else {
          let bodys = {
            ...body,
            available: "PENDING",
            rating: 0,
            TAGtext: JSON.stringify(body.TAG),
            vdo_url:
              body?.vdo_url?.length > 0
                ? "https://" +
                  body?.vdo_url?.replace("https://", "")?.replace("http://", "")
                : "",
          };
          const response = await apiservice({
            path:
              body.available == "APPROVE"
                ? "/course/updatecourse"
                : "/course/create",
            method: body.available == "APPROVE" ? "put" : "post",
            body: bodys,
            token: token?.accessToken,
          });
          if (response.status == 200) {
            setTimeout(() => {
              setbody({
                Type: "COURSESCHOOL",
                TAG: [],
                person: [],
                title: "",
                about: "",
                img_content: "",
                price: 0,
                approve: "",
                grade: "",
                grade_id: "",
                length_detail: "",
                duration_study: "",
                percent: 80,
                meetingurl: "",
                workDate: [],
                datetime: "",
                startDate: new Date(),
                endDate: moment(new Date()).add(1, "days"),
                level: "",
                certificate: false,
                certificate_detail: {},
                available: "DRAFT",
                educationmodel: [1],
                studymodel: [
                  {
                    number: 0,
                    listen_name: "",
                    tab: 0,
                    listen_detail: [],
                    textinputs: "",
                  },
                ],
                cer_id: 1,
                uid: route.params.uid,
                FAQ: [
                  {
                    Q: "",
                    A: "",
                  },
                ],
                announcement: " ข้อมูลผู้สอน.... ",
                vdo_url: "",
              });
              setModalSucceed(true);
            }, 1000);
          }
        }
      } else {
      }
      if (body.about == "") {
        setWarningAbout(true);
      }
      if (body.title == "") {
        setWarningTitle(true);
      }

      if (body.length_detail == "") {
        setWarningDetail(true);
      }
      if (body.percent == "") {
        setWarningPercent(true);
      }
      if (body.level == "") {
        setWarningLevel(true);
      }

      if (body.img_content == "") {
        setWarningImg(true);
      }
      if (body.category_id == null) {
        setWarningCategory(true);
      }
    }
  }
  function hour() {
    if (body.length_detail > 2) {
      ("## : ## ชั่วโมง");
    } else {
      ("## ชั่วโมง");
    }
  }
  const ck = {
    toolbar: [
      "heading",
      "|",
      "bold",
      "italic",
      "link",
      "bulletedList",
      "numberedList",
      "blockQuote",
    ],
    heading: {
      options: [
        {
          model: "paragraph",
          title: "Paragraph",
          class: "ck-heading_paragraph",
        },
        {
          model: "heading1",
          view: "h1",
          title: "Heading 1",
          class: "ck-heading_heading1",
        },
        {
          model: "heading2",
          view: "h2",
          title: "Heading 2",
          class: "ck-heading_heading2",
        },
      ],
    },
  };
  const MAX_VAL = 100;
  const withValueLimit = (inputObj) => {
    const { value } = inputObj;
    if (value <= MAX_VAL) return true;
    return setbody({ ...body, percent: 100 });
  };
  const anotherFunc = (val) => {
    setTag("");
  };
  const persianNumeral = ["۰", "۱", "۲", "۳", "۴", "۵", "۶", "۷", "۸", "۹"];

  function testfill() {
    let num = body?.studymodel?.length * 2.3;

    body.studymodel.map((e, i) => (num = num * e?.listen_detail?.length));

    return num;
  }
  function testfill1() {
    setPage(-1);
    setTimeout(() => {
      setPage(1);
    }, 1);
  }
  return (
    <View style={styles.container}>
      <Modal
        transparent={true}
        visible={modalDatework}
        onRequestClose={() => {
          Alert.alert("Modal has been closed.");
          setModalDatework(!modalDatework);
        }}
      >
        <View style={styles.bgModal}>
          <View
            style={{
              width: size.width < 1280 ? "80%" : width * 0.6,
              height: null,
              backgroundColor: "#fff",
              borderRadius: 30,
              alignSelf: "center",
              paddingVertical: 20,
            }}
          >
            <Text
              style={[
                size.width < 500 ? stylesResponsive.text30 : styles.text30,
              ]}
            >
              เลือกวัน - เวลาที่สอน
            </Text>
            <View style={{ zIndex: 999 }}>
              <TouchableOpacity
                onPress={() => {
                  setopenDate((val) => !val);
                }}
                style={{
                  width: size.width < 1280 ? "80%" : 476,
                  height: 50,
                  borderWidth: 1,
                  borderColor: "#E0E0E0",
                  backgroundColor: "#FFFFFF",
                  justifyContent: "center",
                  paddingLeft: 15,
                  marginTop: 18,
                  alignSelf: "center",
                }}
              >
                <Text
                  style={{
                    color: "#0D88F0",
                    fontFamily: "Prompt",
                    fontSize: size.width < 1280 ? 14 : 18,
                  }}
                >
                  {workDate?.date ? workDate?.date : "วัน"}
                </Text>
                <Ionicons
                  name="chevron-down"
                  size={16}
                  color="#0D88F0"
                  style={{ position: "absolute", right: 5 }}
                />
              </TouchableOpacity>
              {openDate && (
                <View
                  style={{
                    width: size.width < 1280 ? "80%" : 476,
                    alignSelf: "center",
                    borderWidth: 1,
                    borderColor: "#CCC",
                    position: "absolute",
                    zIndex: 99,
                    marginTop: 70,
                    backgroundColor: "#FFF",
                  }}
                >
                  <FlatList
                    data={days}
                    style={{}}
                    renderItem={({ item, index }) => {
                      return (
                        <TouchableOpacity
                          onPress={() => {
                            setWorkDate((val) => ({
                              ...val,
                              date: item.value,
                            }));
                            setopenDate(false);
                          }}
                        >
                          <Text
                            style={{
                              color: "#0D88F0",
                              fontFamily: "Prompt",
                              fontSize: size.width < 1280 ? 14 : 18,
                              marginLeft: 15,
                            }}
                          >
                            {item.label}
                          </Text>
                        </TouchableOpacity>
                      );
                    }}
                  />
                </View>
              )}
            </View>

            <Text
              style={[
                size.width < 500 ? stylesResponsive.text30 : styles.text30,
                { marginTop: size.width < 1280 && 10, zIndex: -1 },
              ]}
            >
              เวลาสอน
            </Text>
            <View style={{ zIndex: 998 }}>
              <TouchableOpacity
                onPress={() => {
                  setopenDateStart((val) => !val);
                }}
                style={{
                  width: size.width < 1280 ? "80%" : 476,
                  height: 50,
                  borderWidth: 1,
                  borderColor: "#E0E0E0",
                  backgroundColor: "#FFFFFF",
                  justifyContent: "center",
                  paddingLeft: 15,
                  marginTop: 18,
                  alignSelf: "center",
                }}
              >
                <Text
                  style={{
                    color: "#0D88F0",
                    fontFamily: "Prompt",
                    fontSize: size.width < 1280 ? 14 : 18,
                  }}
                >
                  {workDate?.starttimer ? workDate?.starttimer : "เวลาเริ่มสอน"}
                </Text>
                <Ionicons
                  name="chevron-down"
                  size={16}
                  color="#0D88F0"
                  style={{ position: "absolute", right: 5 }}
                />
              </TouchableOpacity>

              {openDateStart && (
                <View
                  style={{
                    width: size.width < 1280 ? "80%" : 476,
                    alignSelf: "center",
                    borderWidth: 1,
                    borderColor: "#CCC",
                    position: "absolute",
                    zIndex: 99,
                    marginTop: 70,
                    backgroundColor: "#FFF",
                  }}
                >
                  <FlatList
                    data={timer}
                    style={{ height: size.height * 0.3 }}
                    renderItem={({ item, index }) => {
                      return (
                        <TouchableOpacity
                          onPress={() => {
                            setWorkDate((val) => ({
                              ...val,
                              starttimer: item.value,
                            }));
                            setopenDateStart(false);
                          }}
                        >
                          <Text
                            style={{
                              color: "#0D88F0",
                              fontFamily: "Prompt",
                              fontSize: size.width < 1280 ? 14 : 18,
                              marginLeft: 15,
                            }}
                          >
                            {item.label}
                          </Text>
                        </TouchableOpacity>
                      );
                    }}
                  />
                </View>
              )}
            </View>
            <View style={{ zIndex: 997 }}>
              <TouchableOpacity
                onPress={() => {
                  setopenDateEnd((val) => !val);
                }}
                style={{
                  width: size.width < 1280 ? "80%" : 476,
                  height: 50,
                  borderWidth: 1,
                  borderColor: "#E0E0E0",
                  backgroundColor: "#FFFFFF",
                  justifyContent: "center",
                  paddingLeft: 15,
                  marginTop: 18,
                  alignSelf: "center",
                }}
              >
                <Text
                  style={{
                    color: "#0D88F0",
                    fontFamily: "Prompt",
                    fontSize: size.width < 1280 ? 14 : 18,
                  }}
                >
                  {workDate?.endtimer
                    ? workDate?.endtimer
                    : "เวลาสิ้นสุดการสอน"}
                </Text>
                <Ionicons
                  name="chevron-down"
                  size={16}
                  color="#0D88F0"
                  style={{ position: "absolute", right: 5 }}
                />
              </TouchableOpacity>
              {openDateEnd && (
                <View
                  style={{
                    width: size.width < 1280 ? "80%" : 476,
                    alignSelf: "center",
                    borderWidth: 1,
                    borderColor: "#CCC",
                    position: "absolute",
                    zIndex: 99,
                    marginTop: 70,
                    backgroundColor: "#FFF",
                  }}
                >
                  <FlatList
                    data={timer}
                    style={{ height: size.height * 0.3 }}
                    renderItem={({ item, index }) => {
                      return (
                        <TouchableOpacity
                          onPress={() => {
                            setWorkDate((val) => ({
                              ...val,
                              endtimer: item.value,
                            }));
                            setopenDateEnd(false);
                          }}
                        >
                          <Text
                            style={{
                              color: "#0D88F0",
                              fontFamily: "Prompt",
                              fontSize: size.width < 1280 ? 14 : 18,
                              marginLeft: 15,
                            }}
                          >
                            {item.label}
                          </Text>
                        </TouchableOpacity>
                      );
                    }}
                  />
                </View>
              )}
            </View>

            <View
              style={{
                flexDirection: "row",
                marginVertical: 20,
                alignSelf: "center",
                zIndex: -2,
              }}
            >
              <TouchableOpacity
                onPress={async () => {
                  setModalDatework(false);
                }}
                style={{
                  width: size.width < 1280 ? 100 : 200,
                  height: size.width < 1280 ? 40 : 50,
                  borderWidth: 1,
                  borderColor: "#0D88F0",
                  borderRadius: 5,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Text
                  style={{
                    fontFamily: "Prompt",
                    fontSize: size.width < 1280 ? 14 : 25,
                    color: "#0D88F0",
                  }}
                >
                  ยกเลิก
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={async () => {
                  if (
                    workDate.starttimer != undefined &&
                    workDate.endtimer != undefined &&
                    workDate.date != undefined
                  ) {
                    setbody((val) => ({
                      ...val,
                      workDate: val.workDate.concat(workDate),
                    }));
                    setWorkDate({
                      starttimer: null,
                      endtimer: null,
                      date: null,
                    });

                    setModalDatework(false);
                  }
                }}
                style={{
                  width: size.width < 1280 ? 100 : 200,
                  height: size.width < 1280 ? 40 : 50,
                  backgroundColor: "#0D88F0",
                  borderRadius: 5,
                  justifyContent: "center",
                  alignItems: "center",
                  marginLeft: 15,
                }}
              >
                <Text
                  style={{
                    fontFamily: "Prompt",
                    fontSize: size.width < 1280 ? 14 : 25,
                    color: "#FFFFFF",
                  }}
                >
                  บันทึก
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </Modal>
      <ScrollView stickyHeaderIndices={[0]}>
        <Header navigation={navigation} />
        <View
          style={{
            width: "80%",
            alignSelf: "center",
            flexDirection: size.width < 1280 ? "column" : "row",
            justifyContent: "space-between",
            marginTop: height * 0.05,
          }}
        >
          <View style={{ width: size.width < 1280 ? "100%" : "60.4%" }}>
            <TextInput
              autoComplete="off"
              ref={ref}
              value={body.title}
              onChangeText={(text) => {
                if (body.title != null) {
                  setWarningTitle(false);
                }
                setbody({
                  ...body,
                  title: text,
                });
              }}
              placeholder="ชื่อคอร์สเรียน .."
              placeholderTextColor="#0D88F0"
              style={{
                width: "100%",
                height: 50,
                borderWidth: 1,
                borderColor: "#E0E0E0",
                backgroundColor: "#FFFFFF",
                fontSize: size.width < 1280 ? 14 : 18,
                paddingHorizontal: 20,
                fontFamily: "Prompt",
                color: "#0D88F0",
              }}
            />
            {warningTitle && (
              <Text
                style={[
                  size.width < 1280 ? stylesResponsive.text10R : styles.text14R,
                ]}
              >
                กรุณากรอกชื่อคอร์สเรียน
              </Text>
            )}
            {body.approve == "STUDENT" && (
              <RNPickerSelect
                placeholder={placeholder}
                // onValueChange={(val) => {

                // }}
                onValueChange={(value) => {
                  if (body.faculty_id != "") {
                    setWarningfaculty(false);
                  }
                  setbody((val) => ({ ...val, faculty_id: value }));
                }}
                value={body.faculty_id?.toString()}
                items={status.sort((a, b) => {
                  if (b.label > a.label) {
                    return -1;
                  }
                  if (b.label < a.label) {
                    return 1;
                  }
                  return 0;
                })}
                style={{
                  inputWeb: {
                    width: size.width < 1280 ? "100%" : 476,
                    height: 50,
                    borderWidth: 1,
                    borderColor: "#E0E0E0",
                    backgroundColor: "#FFFFFF",
                    color: "#0D88F0",
                    fontFamily: "Prompt",
                    fontSize: size.width < 1280 ? 14 : 18,
                    paddingLeft: 15,
                    marginTop: 18,
                  },
                }}
              />
            )}
            {warningFaculty && (
              <Text
                style={[
                  size.width < 1280 ? stylesResponsive.text10R : styles.text14R,
                ]}
              >
                กรุณาเลือกคณะ
              </Text>
            )}
            <TextInput
              autoComplete="off"
              onChangeText={(text) => {
                setbody({
                  ...body,
                  meetingurl: text,
                });
                if (text.length == 0) {
                  setbody((val) => ({
                    ...val,
                    workDate: [],
                  }));
                }
              }}
              value={body?.meetingurl}
              placeholder="Live URL .."
              placeholderTextColor="#0D88F0"
              style={{
                width: "100%",
                height: 50,
                borderWidth: 1,
                borderColor: "#E0E0E0",
                backgroundColor: "#FFFFFF",
                fontSize: size.width < 1280 ? 14 : 18,
                marginTop: 18,

                paddingHorizontal: 20,
                fontFamily: "Prompt",
                color: "#0D88F0",
              }}
            />
            <View
              style={{
                flexDirection: "row",
                flexWrap: "wrap",
                marginTop: body?.workDate?.length > 0 ? 20 : 0,
              }}
            >
              {body?.workDate?.map((val, index) => {
                return (
                  <View style={{ flexDirection: "row" }}>
                    <Text
                      style={{
                        fontSize: size.width < 1280 ? 14 : 16,
                        fontFamily: "Prompt",
                        color: "#0D88F0",
                        marginRight: 15,
                      }}
                    >
                      {val.date} {val.starttimer}-{val.endtimer}
                    </Text>
                    <TouchableOpacity
                      onPress={() => {
                        setbody((val) => ({
                          ...val,
                          workDate: val.workDate.filter((e, indexs) => {
                            return indexs != index;
                          }),
                        }));
                      }}
                      style={[
                        styles.buttonAdd,
                        {
                          marginTop: -5,
                          marginHorizontal: 5,
                          position: "absolute",
                          right: 0,
                        },
                      ]}
                    >
                      <MaterialIcons name="cancel" size={16} color="#EA2727" />
                    </TouchableOpacity>
                  </View>
                );
              })}
            </View>
            {body.meetingurl != "" && (
              <TouchableOpacity
                onPress={() => {
                  // pickup();
                  setModalDatework(true);
                }}
                style={styles.buttonAdd}
              >
                <MaterialIcons name="add-circle" size={30} color="#0B2051" />
                <Text
                  style={[
                    size.width < 1280
                      ? stylesResponsive.textButtonAdd
                      : styles.textButtonAdd,
                  ]}
                >
                  เพิ่มวันที่สอน
                </Text>
              </TouchableOpacity>
            )}
            {body?.TAG != [] && (
              <View
                style={{
                  flexDirection: "row",
                  flexWrap: "wrap",
                  marginTop:
                    body?.TAG != null && body?.TAG?.length > 0 ? 20 : 0,
                }}
              >
                {body?.TAG?.map((val, index) => {
                  return (
                    <View style={{ flexDirection: "row" }}>
                      <Text
                        style={{
                          fontSize: size.width < 1280 ? 14 : 16,
                          fontFamily: "Prompt",
                          color: "#0D88F0",
                          marginRight: 20,
                        }}
                      >
                        {val}
                      </Text>
                      <TouchableOpacity
                        onPress={() => {
                          setbody((val) => ({
                            ...val,
                            TAG: val.TAG.filter((e, indexs) => {
                              return indexs != index;
                            }),
                          }));
                        }}
                        style={[
                          styles.buttonAdd,
                          {
                            marginTop: -5,
                            marginHorizontal: 5,
                            position: "absolute",
                            right: 0,
                          },
                        ]}
                      >
                        <MaterialIcons
                          name="cancel"
                          size={16}
                          color="#EA2727"
                        />
                      </TouchableOpacity>
                    </View>
                  );
                })}
              </View>
            )}

            <TouchableOpacity
              onPress={() => {
                setModalTag(true);
              }}
              style={styles.buttonAdd}
            >
              <MaterialIcons name="add-circle" size={30} color="#0B2051" />
              <Text
                style={[
                  size.width < 1280
                    ? stylesResponsive.textButtonAdd
                    : styles.textButtonAdd,
                ]}
              >
                เพิ่มแท็ค
              </Text>
            </TouchableOpacity>

            <View
              style={{
                flexDirection: "row",
                flexWrap: "wrap",
                marginTop: body?.person?.length > 0 ? 20 : 0,
              }}
            >
              {body?.person?.map((val, index) => {
                return (
                  <View style={{ flexDirection: "row" }}>
                    <Text
                      style={{
                        fontSize: size.width < 1280 ? 14 : 16,
                        fontFamily: "Prompt",
                        color: "#0D88F0",
                        marginRight: 15,
                      }}
                    >
                      {val.firstName} {val.lastName}
                    </Text>
                    <TouchableOpacity
                      onPress={() => {
                        setbody((val) => ({
                          ...val,
                          person: val.person.filter((e, indexs) => {
                            return indexs != index;
                          }),
                        }));
                      }}
                      style={[
                        styles.buttonAdd,
                        {
                          marginTop: -5,
                          marginHorizontal: 5,
                          position: "absolute",
                          right: 0,
                        },
                      ]}
                    >
                      <MaterialIcons name="cancel" size={16} color="#EA2727" />
                    </TouchableOpacity>
                  </View>
                );
              })}
            </View>
            {body.approve == "STUDENT" && (
              <TouchableOpacity
                onPress={() => {
                  setModalTeacher(true);
                }}
                style={styles.buttonAdd}
              >
                <MaterialIcons name="add-circle" size={30} color="#0B2051" />
                <Text
                  style={[
                    size.width < 1280
                      ? stylesResponsive.textButtonAdd
                      : styles.textButtonAdd,
                  ]}
                >
                  เพิ่มผู้ช่วยสอน
                </Text>
              </TouchableOpacity>
            )}
            <View>
              <FlatList
                horizontal
                data={selectData}
                showsHorizontalScrollIndicator={false}
                style={{ marginTop: 31 }}
                renderItem={({ item, index }) => {
                  return (
                    <TouchableOpacity
                      style={{
                        backgroundColor:
                          page == index
                            ? "#FFFFFF"
                            : "#0D88F0" && page == -1 && index == 1
                            ? "#FFFFFF"
                            : "#0D88F0",
                        borderWidth: 1,
                        borderColor: "#0D88F0",
                        width:
                          size.width < 1280 ? size.width / 5 : size.width / 8.3,

                        height: 60,
                        justifyContent: "center",
                      }}
                      onPress={() => {
                        setPage(index);
                      }}
                    >
                      <Text
                        style={{
                          fontFamily: "Prompt",
                          fontSize: size.width < 1280 ? 14 : 20,
                          textAlign: "center",

                          color:
                            page == index
                              ? "#0D88F0"
                              : "#FFFFFF" && page == -1 && index == 1
                              ? "#0D88F0"
                              : "#FFFFFF",
                        }}
                      >
                        {item}
                      </Text>
                    </TouchableOpacity>
                  );
                }}
              />
              {page == -1 && (
                <View
                  style={{
                    width: "100%",
                    minHeight: 442,
                    borderWidth: 1,
                    borderColor: "#E0E0E0",
                    borderTopWidth: 0,
                    padding: 20,
                  }}
                >
                  <View
                    style={{
                      width: "100%",
                      backgroundColor: "#EBEBEB",
                      minHeight: 401,
                      padding: size.width < 500 ? 0 : 20,
                    }}
                  >
                    <FlatList
                      data={body.studymodel}
                      extraData={[body, modelrecent]}
                      scrollEnabled
                      renderItem={({ item, index }) => {
                        return (
                          <View
                            style={{
                              overflow: "hidden",
                              backgroundColor: "#EBEBEB",
                              height: 135,
                            }}
                          >
                            <FlatList
                              data={item.listen_detail}
                              extraData={body}
                              renderItem={({ item, index }) => {
                                const subIndex = index;
                                return (
                                  <View
                                    style={{
                                      overflow: "hidden",
                                      backgroundColor: "#EBEBEB",
                                      height: 36,
                                    }}
                                  ></View>
                                );
                              }}
                            />
                          </View>
                        );
                      }}
                    />
                  </View>
                </View>
              )}
              {page == 0 && (
                <View style={styles.viewCkEditor}>
                  {show && (
                    <CKEditor
                      editor={ClassicEditor}
                      data={body.about.toString()}
                      onReady={(editor) => {
                        // You can store the "editor" and use when it is needed.
                      }}
                      config={ck}
                      onChange={(event, editor) => {
                        const data = editor.getData();

                        setbody({
                          ...body,
                          about: editor.getData(),
                        });
                        if (body.about.length < 1) {
                          setWarningAbout(false);
                        }
                        // setDesCom(data);
                      }}
                      onBlur={(event, editor) => {}}
                      onFocus={(event, editor) => {}}
                    />
                  )}
                </View>
              )}
              {page == 1 && (
                <View
                  style={{
                    width: "100%",
                    minHeight: 442,
                    borderWidth: 1,
                    borderColor: "#E0E0E0",
                    borderTopWidth: 0,
                    padding: 20,
                  }}
                >
                  <View
                    style={{
                      width: "100%",
                      backgroundColor: "#EBEBEB",
                      minHeight: 401,
                      padding: size.width < 500 ? 0 : 20,
                    }}
                  >
                    <View
                      style={{
                        // height: 58 * testfill(),
                        overflow: "hidden",
                      }}
                    >
                      <FlatList
                        data={body.studymodel}
                        extraData={[body, modelrecent]}
                        contentContainerStyle={{
                          overflow: "hidden",
                          // height: 135 + testfill1(),
                        }}
                        scrollEnabled
                        renderItem={({ item, index }) => {
                          const mainItem = item;
                          const MainIndex = index;
                          return (
                            <View
                              style={{
                                overflow: "hidden",
                              }}
                            >
                              <View
                                style={{
                                  flexDirection: "row",
                                  alignItems: "center",
                                }}
                              >
                                <TextInput
                                  autoComplete="off"
                                  placeholderTextColor="#646464"
                                  placeholder="ชื่อบทเรียน .."
                                  value={item.listen_name}
                                  style={{
                                    width: size.width < 500 ? "100%" : 276,
                                    height: 27,
                                    borderWidth: 1,
                                    backgroundColor: "#FFFFFF",
                                    borderColor: "#0D88F0",
                                    borderStyle: "dashed",
                                    fontFamily: "Prompt",
                                    fontSize: 14,
                                    color: "#0D88F0",
                                    paddingHorizontal: 15,
                                    marginBottom: 10,
                                    marginTop: index != 0 && 25,
                                  }}
                                  onChangeText={(text) => {
                                    setbody((val) => ({
                                      ...val,
                                      studymodel: val.studymodel.map(
                                        (items, indexs) =>
                                          item.number == items.number
                                            ? {
                                                ...items,
                                                listen_name: text,
                                              }
                                            : items
                                      ),
                                    }));
                                  }}
                                />
                                {body.studymodel.length > 1 && (
                                  <TouchableOpacity
                                    onPress={() => {
                                      setbody((val) => ({
                                        ...val,
                                        studymodel: val.studymodel.filter(
                                          (e, indexs) => {
                                            return indexs != index;
                                          }
                                        ),
                                      }));
                                      testfill1();
                                    }}
                                    style={{
                                      marginLeft: 10,
                                      marginTop: index != 0 ? 15 : -5,
                                    }}
                                  >
                                    <MaterialIcons
                                      name="delete"
                                      size={18}
                                      color="#0B2051"
                                    />
                                  </TouchableOpacity>
                                )}
                              </View>
                              <View
                                style={{
                                  // flex: 1,

                                  height: 36 * item?.listen_detail?.length,
                                  overflow: "hidden",
                                }}
                              >
                                <FlatList
                                  data={item.listen_detail}
                                  extraData={body}
                                  renderItem={({ item, index }) => {
                                    const subIndex = index;
                                    return (
                                      <View
                                        style={[
                                          styles.viewDetailCourse,
                                          {
                                            borderColor:
                                              item.type == "DESCRIPTION"
                                                ? "#5EB4AA"
                                                : item.type == "WORK"
                                                ? "#F1BB6A"
                                                : item.type == "EXAM"
                                                ? "#E98399"
                                                : "#7BC6E8",
                                          },
                                        ]}
                                      >
                                        <View
                                          style={{
                                            flexDirection: "row",
                                            alignItems: "center",
                                            width: "80%",
                                          }}
                                        >
                                          <MaterialCommunityIcons
                                            name="dots-vertical"
                                            size={18}
                                            color="#0B2051"
                                          />
                                          <MaterialCommunityIcons
                                            name="dots-vertical"
                                            size={18}
                                            color="#0B2051"
                                            style={{ marginLeft: -12 }}
                                          />
                                          {item.type == "DESCRIPTION" && (
                                            <MaterialIcons
                                              name="library-books"
                                              size={12}
                                              color="#5EB4AA"
                                              style={{ marginHorizontal: 10 }}
                                            />
                                          )}
                                          {item.type == "WORK" && (
                                            <MaterialIcons
                                              name="archive"
                                              size={12}
                                              color="#F1BB6A"
                                              style={{ marginHorizontal: 10 }}
                                            />
                                          )}
                                          {item.type == "EXAM" && (
                                            <MaterialIcons
                                              name="live-help"
                                              size={12}
                                              color="#E98399"
                                              style={{ marginHorizontal: 10 }}
                                            />
                                          )}
                                          {item.type == "WRITING" && (
                                            <MaterialIcons
                                              name="description"
                                              size={12}
                                              color="#7BC6E8"
                                              style={{ marginHorizontal: 10 }}
                                            />
                                          )}
                                          <TextInput
                                            onChangeText={(text) => {
                                              setbody((val) => ({
                                                ...val,
                                                studymodel: val.studymodel.map(
                                                  (e, is) => {
                                                    if (is == MainIndex) {
                                                      return {
                                                        ...e,
                                                        listen_detail:
                                                          e.listen_detail.map(
                                                            (eis, ins) =>
                                                              ins == subIndex
                                                                ? {
                                                                    ...eis,
                                                                    name: text,
                                                                  }
                                                                : eis
                                                          ),
                                                      };
                                                    } else {
                                                      return e;
                                                    }
                                                  }
                                                ),
                                              }));
                                            }}
                                            defaultValue={item.name}
                                            style={[
                                              styles.text14,
                                              {
                                                width: "80%",
                                                height: 25,
                                                borderWidth: 0,
                                              },
                                            ]}
                                          />
                                        </View>
                                        <View
                                          style={{
                                            flexDirection: "row",
                                            alignItems: "center",
                                            width: "20%",
                                            justifyContent: "flex-end",
                                          }}
                                        >
                                          <TouchableOpacity
                                            onPress={async () => {
                                              setSelect(mainItem.number);
                                              setText(index);

                                              if (item.type == "DESCRIPTION") {
                                                const response =
                                                  await apiservice({
                                                    path:
                                                      "/course/geteducation/" +
                                                      item.education_id,
                                                    method: "get",
                                                    token: token?.accessToken,
                                                  });

                                                if (response.status == 200) {
                                                  setModelRecent(response.data);
                                                }
                                                setModalCourse(true);
                                              } else if (item.type == "WORK") {
                                                const response =
                                                  await apiservice({
                                                    path:
                                                      "/course/getwork/" +
                                                      item.education_id,
                                                    method: "get",
                                                    token: token?.accessToken,
                                                  });

                                                if (response.status == 200) {
                                                  setwork(response.data);
                                                }
                                                setModalWork(true);
                                              } else if (item.type == "EXAM") {
                                                const response =
                                                  await apiservice({
                                                    path:
                                                      "/start-course/final/" +
                                                      item.education_id,
                                                    method: "get",
                                                    token: token?.accessToken,
                                                  });
                                                if (response.status == 200) {
                                                  if (response.data != null) {
                                                    setQuiz(response.data);
                                                  }
                                                }
                                                setModalTest(true);
                                              } else if (
                                                item.type == "WRITING"
                                              ) {
                                                const response =
                                                  await apiservice({
                                                    path:
                                                      "/start-course/final/" +
                                                      item.education_id,
                                                    method: "get",
                                                    token: token?.accessToken,
                                                  });
                                                if (response.status == 200) {
                                                  if (response.data != null) {
                                                    setQuiz(response.data);
                                                  }
                                                }

                                                setModalTest1(true);
                                              }
                                            }}
                                            style={
                                              styles.viewButtonDetailCourse
                                            }
                                          >
                                            <Text style={styles.text12W}>
                                              {item.education_id
                                                ? "แก้ไข"
                                                : "เพิ่มเนื้อหา"}
                                            </Text>
                                          </TouchableOpacity>
                                          <TouchableOpacity
                                            onPress={() => {
                                              setbody((val) => ({
                                                ...val,
                                                studymodel: val.studymodel.map(
                                                  (e, is) => {
                                                    if (is == MainIndex) {
                                                      return {
                                                        ...e,
                                                        listen_detail:
                                                          e.listen_detail.filter(
                                                            (eis, ins) =>
                                                              ins != subIndex
                                                          ),
                                                      };
                                                    } else {
                                                      return e;
                                                    }
                                                  }
                                                ),
                                              }));
                                              testfill1();
                                            }}
                                          >
                                            <MaterialIcons
                                              name="delete"
                                              size={18}
                                              color="#0B2051"
                                              style={{ marginHorizontal: 10 }}
                                            />
                                          </TouchableOpacity>
                                        </View>
                                      </View>
                                    );
                                  }}
                                />
                              </View>
                              <View>
                                {/* Tag บน ให้เลือก */}
                                <FlatList
                                  horizontal
                                  data={selectData1}
                                  extraData={[body]}
                                  showsHorizontalScrollIndicator={false}
                                  style={{ marginTop: 15 }}
                                  renderItem={(e) => {
                                    const lessons = e.item;
                                    const indexss = e.index;

                                    return (
                                      <TouchableOpacity
                                        style={{
                                          backgroundColor:
                                            ((item.tab == indexss) == 0 &&
                                              "#EBEBEB") ||
                                            (item.tab == 0 && "#5EB4AA") ||
                                            (item.tab == 1 && "#F1BB6A") ||
                                            (item.tab == 2 && "#E98399") ||
                                            (item.tab == 3 && "#7BC6E8"),
                                          width:
                                            size.width < 1280
                                              ? size.width / 6
                                              : width * 0.08,
                                          height: size.width < 500 ? 30 : 21,
                                          justifyContent: "center",
                                          borderTopRightRadius: 5,
                                          borderTopLeftRadius: 5,
                                          flexDirection: "row",
                                          alignItems: "center",
                                        }}
                                        onPress={() => {
                                          setbody((val) => ({
                                            ...val,
                                            studymodel: val.studymodel.map(
                                              (items, indexs) =>
                                                item.number == items.number
                                                  ? {
                                                      ...items,
                                                      tab: indexss,
                                                    }
                                                  : items
                                            ),
                                          }));
                                        }}
                                      >
                                        <MaterialIcons
                                          name={
                                            (size.width > 500 &&
                                              indexss == 0 &&
                                              "library-books") ||
                                            (size.width > 500 &&
                                              indexss == 1 &&
                                              "archive") ||
                                            (size.width > 500 &&
                                              indexss == 2 &&
                                              "live-help") ||
                                            (size.width > 500 &&
                                              indexss == 3 &&
                                              "description")
                                          }
                                          size={12}
                                          color={
                                            item.tab == indexss
                                              ? "#FFFFFF"
                                              : "#0B2051"
                                          }
                                        />
                                        <Text
                                          style={{
                                            fontFamily: "Prompt",
                                            fontSize:
                                              size.width < 500 ? 10 : 12,
                                            textAlign: "center",
                                            color:
                                              item.tab == indexss
                                                ? "#FFFFFF"
                                                : "#0B2051",
                                          }}
                                        >
                                          {lessons}
                                        </Text>
                                      </TouchableOpacity>
                                    );
                                  }}
                                />

                                {
                                  <View>
                                    <View
                                      style={[
                                        styles.viewAddLesson,
                                        {
                                          backgroundColor:
                                            item.tab == 0
                                              ? "#5EB4AA"
                                              : item.tab == 1
                                              ? "#F1BB6A"
                                              : item.tab == 2
                                              ? "#E98399"
                                              : "#7BC6E8",
                                        },
                                      ]}
                                    >
                                      <TextInput
                                        autoComplete="off"
                                        value={item.textinputs}
                                        onChangeText={(txt) => {
                                          setbody((val) => ({
                                            ...val,
                                            studymodel: val.studymodel.map(
                                              (items, indexs) =>
                                                item.number == items.number
                                                  ? {
                                                      ...items,
                                                      textinputs: txt,
                                                    }
                                                  : items
                                            ),
                                          }));
                                        }}
                                        onSubmitEditing={(text) => {
                                          setbody((val) => ({
                                            ...val,
                                            studymodel: val.studymodel.map(
                                              (items, indexs) =>
                                                item.number == items.number
                                                  ? {
                                                      ...items,
                                                      listen_detail:
                                                        items.listen_detail.concat(
                                                          {
                                                            type:
                                                              item.tab == 0
                                                                ? "DESCRIPTION"
                                                                : item.tab == 1
                                                                ? "WORK"
                                                                : item.tab == 2
                                                                ? "EXAM"
                                                                : "WRITING",
                                                            name: text
                                                              .nativeEvent.text,
                                                            education_id: null,
                                                          }
                                                        ),
                                                      textinputs: "",
                                                    }
                                                  : items
                                            ),
                                          }));
                                          testfill1();
                                          // setTimeout(() => {
                                          //   setPage(1);
                                          // }, 1);
                                        }}
                                        style={[
                                          styles.inputAddLesson,
                                          {
                                            color:
                                              item.tab == 0
                                                ? "#5EB4AA"
                                                : item.tab == 1
                                                ? "#F1BB6A"
                                                : item.tab == 2
                                                ? "#E98399"
                                                : "#7BC6E8",
                                          },
                                        ]}
                                      />
                                      <TouchableOpacity
                                        disabled={true}
                                        style={{ marginLeft: -20 }}
                                      >
                                        <MaterialIcons
                                          name="keyboard-return"
                                          size={19}
                                          color="#0B2051"
                                        />
                                      </TouchableOpacity>
                                    </View>
                                  </View>
                                }
                              </View>
                            </View>
                          );
                        }}
                      />
                      {body?.studymodel?.length < 10 && (
                        <TouchableOpacity
                          onPress={() => {
                            setPage(-1);

                            setbody((val) => ({
                              ...val,
                              studymodel: val.studymodel.concat({
                                number: val.studymodel.length,
                                listen_name: "",
                                tab: 0,
                                listen_detail: [],
                              }),
                            }));
                            setTimeout(() => {
                              setPage(1);
                            }, 1);
                          }}
                          style={[styles.buttonAdd, { marginTop: 10 }]}
                        >
                          <MaterialIcons
                            name="add-circle"
                            size={20}
                            color="#0B2051"
                          />
                          <Text
                            style={[
                              size.width < 1280
                                ? stylesResponsive.textButtonAdd
                                : styles.textButtonAdd,
                              { fontSize: 14 },
                            ]}
                          >
                            เพิ่มบทเรียน
                          </Text>
                        </TouchableOpacity>
                      )}
                    </View>
                  </View>
                </View>
              )}
              {page == 2 && (
                <View style={styles.viewCkEditor}>
                  <CKEditor
                    editor={ClassicEditor}
                    data={body.announcement}
                    config={ck}
                    onReady={(editor) => {
                      // You can store the "editor" and use when it is needed.
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setbody({
                        ...body,
                        announcement: editor.getData(),
                      });
                    }}
                    onBlur={(event, editor) => {}}
                    onFocus={(event, editor) => {}}
                  />
                </View>
              )}
              {page == 3 && (
                <View
                  style={{
                    width: "100%",
                    minHeight: 442,
                    borderWidth: 1,
                    borderColor: "#E0E0E0",
                    borderTopWidth: 0,
                  }}
                >
                  <View
                    style={{
                      padding: body.FAQ.length > 0 ? 20 : 10,
                    }}
                  >
                    <FlatList
                      data={body.FAQ}
                      renderItem={({ item, index }) => {
                        return (
                          <View style={{ marginBottom: 10 }}>
                            <View
                              style={{
                                flexDirection: "row",
                                alignItems: "center",
                              }}
                            >
                              <TouchableOpacity
                                onPress={() => {
                                  setbody((val) => ({
                                    ...val,
                                    FAQ: val.FAQ.filter((i, e) => {
                                      return e != index;
                                    }),
                                  }));
                                }}
                                style={{ position: "absolute", right: 10 }}
                              >
                                <MaterialIcons
                                  name="cancel"
                                  size={24}
                                  color="#484848"
                                />
                              </TouchableOpacity>
                              <Text style={[styles.text18, { width: "5%" }]}>
                                {index + 1}.
                              </Text>
                              <Text style={[styles.text18]}>Q.</Text>
                              <TextInput
                                autoComplete="off"
                                defaultValue={item.Q}
                                onChangeText={(text) => {
                                  setbody((val) => ({
                                    ...val,
                                    FAQ: val.FAQ.map((items, indexs) => {
                                      if (index == indexs) {
                                        return {
                                          ...items,
                                          Q: text,
                                        };
                                      } else {
                                        return items;
                                      }
                                    }),
                                  }));
                                }}
                                style={[styles.text18, styles.inputQA]}
                              />
                            </View>
                            <View
                              style={{
                                flexDirection: "row",
                                marginTop: 10,
                                alignItems: "center",
                              }}
                            >
                              <Text
                                style={[styles.text18, { width: "5%" }]}
                              ></Text>
                              <Text style={[styles.text18]}>A.</Text>
                              <TextInput
                                autoComplete="off"
                                defaultValue={item.A}
                                onChangeText={(text) => {
                                  setbody((val) => ({
                                    ...val,
                                    FAQ: val.FAQ.map((items, indexs) => {
                                      if (index == indexs) {
                                        return {
                                          ...items,
                                          A: text,
                                        };
                                      } else {
                                        return items;
                                      }
                                    }),
                                  }));
                                }}
                                style={[styles.text18, styles.inputQA]}
                              />
                            </View>
                          </View>
                        );
                      }}
                    />

                    <TouchableOpacity
                      onPress={async () => {
                        setbody((val) => ({
                          ...val,
                          FAQ: val.FAQ.concat({
                            Q: "",
                            A: "",
                          }),
                        }));
                      }}
                      style={styles.buttonAdd}
                    >
                      <MaterialIcons
                        name="add-circle"
                        size={30}
                        color="#0B2051"
                      />
                      <Text
                        style={[
                          size.width < 1280
                            ? stylesResponsive.textButtonAdd
                            : styles.textButtonAdd,
                        ]}
                      >
                        เพิ่มFAQ
                      </Text>
                    </TouchableOpacity>
                  </View>
                </View>
              )}
              {warningAbout && (
                <Text
                  style={[
                    size.width < 1280
                      ? stylesResponsive.text10R
                      : styles.text14R,
                  ]}
                >
                  กรุณากรอกคำอธิบายคอร์สเรียน
                </Text>
              )}
            </View>
            {size.width >= 1280 && (
              <View style={{ flexDirection: "row", marginVertical: 60 }}>
                {body.available != "APPROVE" && (
                  <TouchableOpacity
                    onPress={async () => {
                      if (body.id != undefined) {
                        let bodys = {
                          ...body,
                          available:
                            body?.available == "APPROVE" ? "APPROVE" : "DRAFT",
                          rating: 0,
                          TAGtext: JSON.stringify(body.TAG),
                        };

                        const response = await apiservice({
                          path: "/course/updatecourse",
                          method: "put",
                          body: bodys,
                          token: token?.accessToken,
                        });
                        if (response.status == 200) {
                          setTimeout(() => {
                            setbody({
                              Type: "COURSESCHOOL",
                              TAG: [],
                              person: [],
                              title: "",
                              about: "",
                              img_content: "",
                              price: 0,
                              approve: "",
                              grade: "",
                              grade_id: "",
                              length_detail: "",
                              duration_study: "",
                              percent: 80,
                              meetingurl: "",
                              workDate: [],
                              datetime: "",
                              startDate: new Date(),
                              endDate: moment(new Date()).add(1, "days"),
                              level: "",
                              certificate: false,
                              certificate_detail: {},
                              available: "DRAFT",
                              educationmodel: [1],
                              studymodel: [
                                {
                                  number: 0,
                                  listen_name: "",
                                  tab: 0,
                                  listen_detail: [],
                                  textinputs: "",
                                },
                              ],
                              cer_id: 1,
                              uid: route.params.uid,
                              FAQ: [
                                {
                                  Q: "",
                                  A: "",
                                },
                              ],
                              announcement: " ข้อมูลผู้สอน.... ",
                            });
                          }, 1000);
                          setTimeout(() => {
                            setmodalRecord(true);
                          }, 200);

                          setTimeout(() => {
                            setmodalRecord(!modalRecord);
                          }, 5000);
                          setTimeout(() => {
                            navigation.navigate("Profile");
                          }, 3000);
                        }
                      } else {
                        let bodys = { body, ...body, available: "DRAFT" };
                        const response = await apiservice({
                          path: "/course/create",
                          method: "post",
                          body: bodys,
                          token: token?.accessToken,
                        });
                        setbody({
                          Type: "COURSESCHOOL",
                          title: "",
                          TAG: [],
                          person: [],
                          about: "",
                          img_content: "",
                          price: 0,
                          approve: "",
                          grade: "",
                          grade_id: "",
                          length_detail: "",
                          duration_study: "",
                          percent: "",
                          level: "",
                          certificate: false,
                          certificate_detail: {},
                          available: "DRAFT",
                          educationmodel: [1],
                          meetingurl: "",
                          workDate: [],
                          datetime: "",
                          startDate: new Date(),
                          endDate: moment(new Date()).add(1, "days"),
                          studymodel: [
                            {
                              number: 0,
                              listen_name: "",
                              tab: 0,
                              listen_detail: [],
                              textinputs: "",
                            },
                          ],
                          cer_id: 1,
                          uid: route.params.uid,
                          FAQ: [
                            {
                              Q: "",
                              A: "",
                            },
                          ],
                          announcement: " ข้อมูลผู้สอน.... ",
                        });
                        setTimeout(() => {
                          setmodalRecord(true);
                        }, 200);

                        setTimeout(() => {
                          setmodalRecord(!modalRecord);
                          window.location.replace("Profile");
                        }, 3000);
                      }
                    }}
                    style={{
                      width: 200,
                      height: 50,
                      borderWidth: 1,
                      borderColor: "#0D88F0",
                      borderRadius: 5,
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Text
                      style={{
                        fontFamily: "Prompt",
                        fontSize: 25,
                        color: "#0D88F0",
                      }}
                    >
                      บันทึกแบบร่าง
                    </Text>
                  </TouchableOpacity>
                )}

                <TouchableOpacity
                  onPress={route.params.university_id ? create : createGeneral}
                  style={{
                    width: 200,
                    height: 50,
                    backgroundColor: "#0D88F0",
                    borderRadius: 5,
                    justifyContent: "center",
                    alignItems: "center",
                    marginLeft: body.available == "APPROVE" ? 0 : 15,
                  }}
                >
                  <Text
                    style={{
                      fontFamily: "Prompt",
                      fontSize: 25,
                      color: "#FFFFFF",
                    }}
                  >
                    บันทึกและแสดง
                  </Text>
                </TouchableOpacity>
              </View>
            )}
          </View>
          <View
            style={{
              width: size.width < 1280 ? "100%" : "30%",
              marginTop: size.width < 1280 && 20,
            }}
          >
            {image != null || body.img_content != "" ? (
              <TouchableOpacity
                onPress={() => {
                  pickup();
                }}
                style={{
                  width: "100%",
                  height: 250,
                  borderWidth: 1,
                  borderColor: "#E0E0E0",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Image
                  resizeMode={"stretch"}
                  style={{
                    width: "100%",
                    height: 250,
                    borderWidth: 1,
                    borderColor: "#E0E0E0",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  source={{
                    uri:
                      "https://api.courseoncloud.com/api/image/getimage/" +
                      body?.img_content?.replace(".png", ""),
                  }}
                />
              </TouchableOpacity>
            ) : (
              <TouchableOpacity
                onPress={() => {
                  pickup();
                }}
                style={{
                  width: "100%",
                  height: 250,
                  borderWidth: 1,
                  borderColor: "#E0E0E0",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: size.width < 500 && 20,
                }}
              >
                <MaterialIcons name="panorama" size={50} color="#0B2051" />
                <Text
                  style={{
                    fontFamily: "Prompt",
                    fontSize: size.width < 1280 ? 20 : 25,
                    color: "#0B2051",
                  }}
                >
                  เพิ่มภาพหน้าปกคอร์สเรียน
                </Text>
              </TouchableOpacity>
            )}
            {warningImg && (
              <Text
                style={[
                  size.width < 1280 ? stylesResponsive.text10R : styles.text14R,
                ]}
              >
                กรุณาเลือกรูปภาพหน้าปกคอร์สเรียน
              </Text>
            )}
            <Text style={{ fontSize: 14, fontFamily: "Prompt", color: "#CCC" }}>
              375 X 250
            </Text>
            {/* <Image
              style={{
                maxWidth: 200,
                width: "100%",
                maxHeight: 200,
                height: "100%",
                borderRadius: 100,
                alignSelf: "center",
              }}
              source={{
                uri: "https://api.courseoncloud.com/api/image/getimage/" + image,
              }}
            /> */}
            {/* <InputCourse name="ราคา" /> */}
            <Text
              style={[
                size.width < 1280
                  ? stylesResponsive.textOptionCourse
                  : styles.textOptionCourse,
              ]}
            >
              URL วิดีโอแนะนำคอร์ส
            </Text>
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <TextInput
                autoComplete="off"
                value={body.vdo_url}
                onChangeText={(text) => {
                  setbody((val) => ({
                    ...val,
                    vdo_url: text,
                  }));
                }}
                style={{
                  width: "100%",
                  height: 50,
                  borderWidth: 1,
                  borderColor: "#E0E0E0",
                  paddingHorizontal: 15,

                  fontFamily: "Prompt",

                  fontSize: 18,
                  color: "#0D88F0",
                }}
              />
            </View>
            <Text style={{ fontSize: 14, fontFamily: "Prompt", color: "#CCC" }}>
              หมายเหตุ: กรณีไม่ใส่ URL จะแสดงภาพปกคอร์สเรียนแทน
            </Text>
            {body.university_id == null ? (
              <Text
                style={[
                  size.width < 1280
                    ? stylesResponsive.textOptionCourse
                    : styles.textOptionCourse,
                ]}
              >
                ราคา
              </Text>
            ) : (
              body.university_id == "null" && (
                <Text
                  style={[
                    size.width < 1280
                      ? stylesResponsive.textOptionCourse
                      : styles.textOptionCourse,
                  ]}
                >
                  ราคา
                </Text>
              )
            )}
            {body.university_id == null ? (
              <View
                style={{
                  width: "100%",
                  height: 50,
                  borderWidth: 1,
                  borderColor: "#E0E0E0",
                }}
              >
                <NumberFormat
                  value={body.price}
                  // format={"## : ## ชั่วโมง"}
                  onValueChange={(value) => {
                    // if (body.percent != null) {
                    //   setWarningDetail(false);
                    // }
                    setbody({
                      ...body,
                      price: value.floatValue,
                    });
                  }}
                  style={{
                    borderWidth: 0,
                    height: 50,
                    fontFamily: "Prompt",
                    paddingHorizontal: 15,
                    fontSize: 18,
                    color: "#0D88F0",
                    paddingLeft: 15,
                  }}
                />
              </View>
            ) : (
              // <TextInput
              //   autoComplete="off"
              //   onChangeText={(text) => {
              //     setbody({
              //       ...body,
              //       price: text,
              //     });
              //   }}
              //   style={{
              //     width: "100%",
              //     height: 50,
              //     borderWidth: 1,
              //     borderColor: "#E0E0E0",
              //     fontFamily: "Prompt",
              //     paddingHorizontal: 15,
              //     fontSize: 18,
              //     color: "#0D88F0",
              //   }}
              // />
              body.university_id == "null" && (
                <View
                  style={{
                    width: "100%",
                    height: 50,
                    borderWidth: 1,
                    borderColor: "#E0E0E0",
                  }}
                >
                  <NumberFormat
                    value={body.price}
                    // format={"## : ## ชั่วโมง"}
                    onValueChange={(value) => {
                      // if (body.percent != null) {
                      //   setWarningDetail(false);
                      // }
                      setbody({
                        ...body,
                        price: value.floatValue,
                      });
                    }}
                    style={{
                      borderWidth: 0,
                      height: 50,
                      fontFamily: "Prompt",
                      paddingHorizontal: 15,
                      fontSize: 18,
                      color: "#0D88F0",
                      paddingLeft: 15,
                    }}
                  />
                </View>
              )
            )}
            {/* price */}
            {/* <Text style={styles.textOptionCourse}>การอนุญาตเข้าเรียนคอร์ส</Text>
            <RNPickerSelect
              placeholder={placeholder1}
              onValueChange={(value) => {
                
                setbody((val) => ({ ...val, approve: value }));
              }}
              value={body.approve}
              items={authorization}
              style={{
                inputWeb: {
                  width: "100%",
                  height: 50,
                  borderWidth: 1,
                  borderColor: "#E0E0E0",
                  backgroundColor: "#FFFFFF",
                  color: "#0D88F0",
                  fontFamily: "Prompt",
                  fontSize: 18,
                  paddingHorizontal: 15,
                },
              }}
            /> */}
            {body.approve != "STUDENT" && (
              <Text
                style={[
                  size.width < 1280
                    ? stylesResponsive.textOptionCourse
                    : styles.textOptionCourse,
                ]}
              >
                หมวดหมู่
              </Text>
            )}
            {body.approve != "STUDENT" && (
              <RNPickerSelect
                placeholder={placeholder1}
                onValueChange={(val) => {
                  if (body.category_id != null) {
                    setWarningCategory(false);
                  }
                  setbody({
                    ...body,
                    category_id: val,
                  });
                }}
                value={body.category_id?.toString()}
                items={category}
                style={{
                  inputWeb: {
                    width: "100%",
                    height: 50,
                    borderWidth: 1,
                    borderColor: "#E0E0E0",
                    backgroundColor: "#FFFFFF",
                    color: "#0D88F0",
                    fontFamily: "Prompt",
                    fontSize: 18,
                    paddingHorizontal: 15,
                  },
                }}
              />
            )}
            {warningCategory && (
              <Text
                style={[
                  size.width < 1280 ? stylesResponsive.text10R : styles.text14R,
                ]}
              >
                กรุณาเลือกหมวดหมู่
              </Text>
            )}
            {body.approve == "STUDENT" && (
              <Text
                style={[
                  size.width < 1280
                    ? stylesResponsive.textOptionCourse
                    : styles.textOptionCourse,
                ]}
              >
                ระดับปริญญา
              </Text>
            )}
            {body.approve == "STUDENT" && (
              <RNPickerSelect
                placeholder={placeholder1}
                onValueChange={(val) => {
                  if (body.grade_id != "") {
                    setWarningDegree(false);
                  }

                  setbody({
                    ...body,
                    grade_id: val,
                  });
                }}
                value={body.grade_id}
                items={degree}
                style={{
                  inputWeb: {
                    width: "100%",
                    height: 50,
                    borderWidth: 1,
                    borderColor: "#E0E0E0",
                    backgroundColor: "#FFFFFF",
                    color: "#0D88F0",
                    fontFamily: "Prompt",
                    fontSize: 18,
                    paddingHorizontal: 15,
                  },
                }}
              />
            )}
            {warningdegree && (
              <Text
                style={[
                  size.width < 1280 ? stylesResponsive.text10R : styles.text14R,
                ]}
              >
                กรุณาเลือกระดับปริญญา
              </Text>
            )}
            {body.approve == "STUDENT" && (
              <Text
                style={[
                  size.width < 1280
                    ? stylesResponsive.textOptionCourse
                    : styles.textOptionCourse,
                ]}
              >
                ชั้นปี
              </Text>
            )}
            {body.approve == "STUDENT" && (
              <RNPickerSelect
                placeholder={placeholder1}
                onValueChange={(val) => {
                  if (body.grade != null) {
                    setWarningGrade(false);
                  }

                  setbody({
                    ...body,
                    grade: val,
                  });
                }}
                value={body.grade}
                items={classYear}
                style={{
                  inputWeb: {
                    width: "100%",
                    height: 50,
                    borderWidth: 1,
                    borderColor: "#E0E0E0",
                    backgroundColor: "#FFFFFF",
                    color: "#0D88F0",
                    fontFamily: "Prompt",
                    fontSize: 18,
                    paddingHorizontal: 15,
                  },
                }}
              />
            )}
            {warningGrade && (
              <Text
                style={[
                  size.width < 1280 ? stylesResponsive.text10R : styles.text14R,
                ]}
              >
                กรุณาเลือกชั้นปี
              </Text>
            )}
            {/* <InputCourse
              name="ความยาวเนื้อหา"
              defaultValue={body.length_detail}
              onChangeText={(text) => {
                if (body.length_detail != null) {
                  setWarningDetail(false);
                }
                setbody({
                  ...body,
                  length_detail: text,
                });
              }}
            /> */}
            <Text
              style={[
                size.width < 1280
                  ? stylesResponsive.textOptionCourse
                  : styles.textOptionCourse,
              ]}
            >
              ความยาวเนื้อหา
            </Text>
            <View
              style={{
                width: "100%",
                height: 50,
                borderWidth: 1,
                borderColor: "#E0E0E0",
              }}
            >
              <NumberFormat
                value={body.length_detail}
                format={"## : ## ชั่วโมง"}
                onValueChange={(value) => {
                  if (body.percent != null) {
                    setWarningDetail(false);
                  }
                  setbody({
                    ...body,
                    length_detail: value.floatValue,
                  });
                }}
                style={{
                  borderWidth: 0,
                  height: 50,
                  fontFamily: "Prompt",
                  paddingHorizontal: 15,
                  fontSize: 18,
                  color: "#0D88F0",
                  paddingLeft: 15,
                }}
              />
            </View>
            {warningDetail && (
              <Text
                style={[
                  size.width < 1280 ? stylesResponsive.text10R : styles.text14R,
                ]}
              >
                กรุณากรอกความยาวเนื้อหา
              </Text>
            )}
            <Text
              style={[
                size.width < 1280
                  ? stylesResponsive.textOptionCourse
                  : styles.textOptionCourse,
              ]}
            >
              เปอร์เซ็นการผ่านคอร์ส
            </Text>
            <View
              style={{
                width: "100%",
                height: 50,
                borderWidth: 1,
                borderColor: "#E0E0E0",
              }}
            >
              <NumberFormat
                value={body.percent}
                isAllowed={withValueLimit}
                format="###%"
                onValueChange={(value) => {
                  if (body.percent != null) {
                    setWarningPercent(false);
                  }
                  setbody({
                    ...body,
                    percent: value.floatValue,
                  });
                }}
                style={{
                  borderWidth: 0,
                  height: 50,
                  fontFamily: "Prompt",
                  paddingHorizontal: 15,
                  fontSize: 18,
                  color: "#0D88F0",
                  paddingLeft: 15,
                }}
              />
            </View>
            {warningPercent && (
              <Text
                style={[
                  size.width < 1280 ? stylesResponsive.text10R : styles.text14R,
                ]}
              >
                กรุณากรอกเปอร์เซ็นการผ่านคอร์ส
              </Text>
            )}
            {/* <InputCourse name="ระดับ" /> */}
            <Text
              style={[
                size.width < 1280
                  ? stylesResponsive.textOptionCourse
                  : styles.textOptionCourse,
              ]}
            >
              ระดับ
            </Text>
            <RNPickerSelect
              placeholder={placeholder1}
              onValueChange={(val) => {
                if (body.level != null) {
                  setWarningLevel(false);
                }
                setbody({
                  ...body,
                  level: val,
                });
              }}
              value={body.level}
              items={levels}
              style={{
                inputWeb: {
                  width: "100%",
                  height: 50,
                  borderWidth: 1,
                  borderColor: "#E0E0E0",
                  backgroundColor: "#FFFFFF",
                  color: "#0D88F0",
                  fontFamily: "Prompt",
                  fontSize: 18,
                  paddingHorizontal: 15,
                },
              }}
            />
            {warningLevel && (
              <Text
                style={[
                  size.width < 1280 ? stylesResponsive.text10R : styles.text14R,
                ]}
              >
                กรุณาเลือกระดับ
              </Text>
            )}
            {body.approve == "STUDENT" && (
              <Text
                style={[
                  size.width < 1280
                    ? stylesResponsive.textOptionCourse
                    : styles.textOptionCourse,
                ]}
              >
                กำหนดเวลาการสอน
              </Text>
            )}
            {body.approve == "STUDENT" && (
              <RNPickerSelect
                placeholder={placeholder1}
                onValueChange={(val) => {
                  if (body.datetime != "") {
                    setWarningTime(false);
                  }

                  setbody({
                    ...body,
                    datetime: val,
                  });
                }}
                value={body.datetime?.toString()}
                items={dated}
                style={{
                  inputWeb: {
                    width: "100%",
                    height: 50,
                    borderWidth: 1,
                    borderColor: "#E0E0E0",
                    backgroundColor: "#FFFFFF",
                    color: "#0D88F0",
                    fontFamily: "Prompt",
                    fontSize: 18,
                    paddingHorizontal: 15,
                  },
                }}
              />
            )}
            {warningTime && (
              <Text
                style={[
                  size.width < 1280 ? stylesResponsive.text10R : styles.text14R,
                ]}
              >
                กรุณาเลือกเวลาการสอน
              </Text>
            )}
            {body?.datetime?.toString() == "true" && (
              <Text
                style={[
                  size.width < 1280
                    ? stylesResponsive.textOptionCourse
                    : styles.textOptionCourse,
                ]}
              >
                วันที่เริ่มหลักสูตร
              </Text>
            )}
            {/* <RNPickerSelect
              placeholder={placeholder1}
              onValueChange={(val) => {
                setbody({
                  ...body,
                  duration_study: val,
                });
              }}
              value={body.duration_study}
              items={duration}
              style={{
                inputWeb: {
                  width: "100%",
                  height: 50,
                  borderWidth: 1,
                  borderColor: "#E0E0E0",
                  backgroundColor: "#FFFFFF",
                  color: "#0D88F0",
                  fontFamily: "Prompt",
                  fontSize: 18,
                  paddingHorizontal: 15,
                },
              }}
            /> */}
            {body.datetime.toString() == "true" && (
              <TouchableOpacity
                onPress={() => {
                  setStartD((val) => !val);
                }}
                style={{
                  width: "100%",
                  height: 50,
                  borderWidth: 1,
                  borderColor: "#E0E0E0",
                  backgroundColor: "#FFFFFF",
                  color: "#0D88F0",
                  fontFamily: "Prompt",
                  fontSize: 18,
                  paddingHorizontal: 15,
                  justifyContent: "center",
                }}
              >
                <Text
                  style={{
                    backgroundColor: "#FFFFFF",
                    color: "#0D88F0",
                    fontFamily: "Prompt",
                    fontSize: 18,
                  }}
                >
                  {moment(body.startDate).format("DD/MM/YYYY")}
                </Text>
              </TouchableOpacity>
            )}
            {startD && (
              <Calendar
                onChange={(dd) => {
                  setbody((val) => ({ ...val, startDate: dd }));
                  setStartD(false);
                }}
                // value={body.startDate}
              />
            )}
            {body.datetime.toString() == "true" && (
              <Text
                style={[
                  size.width < 1280
                    ? stylesResponsive.textOptionCourse
                    : styles.textOptionCourse,
                ]}
              >
                วันที่จบหลักสูตร
              </Text>
            )}
            {body.datetime.toString() == "true" && (
              <TouchableOpacity
                onPress={() => {
                  setEndD((val) => !val);
                }}
                style={{
                  width: "100%",
                  height: 50,
                  borderWidth: 1,
                  borderColor: "#E0E0E0",
                  backgroundColor: "#FFFFFF",
                  color: "#0D88F0",
                  fontFamily: "Prompt",
                  fontSize: 18,
                  paddingHorizontal: 15,
                  justifyContent: "center",
                }}
              >
                <Text
                  style={{
                    backgroundColor: "#FFFFFF",
                    color: "#0D88F0",
                    fontFamily: "Prompt",
                    fontSize: 18,
                  }}
                >
                  {moment(body.endDate).format("DD/MM/YYYY")}
                </Text>
              </TouchableOpacity>
            )}
            {endD && (
              <Calendar
                onChange={(dd) => {
                  setbody((val) => ({ ...val, endDate: dd }));
                  setEndD(false);
                }}
                // value={body.startDate}
              />
            )}
            <View style={{ flexDirection: "row", marginVertical: 40 }}>
              <TouchableOpacity
                onPress={() => {
                  setCertificate((val) => !val);
                  setbody((value) => ({ ...value, certificate: !certificate }));
                }}
              >
                <MaterialIcons
                  name={
                    body.certificate ? "check-box" : "check-box-outline-blank"
                  }
                  size={30}
                  color={body.certificate ? "#0D88F0" : "#BBBBBB"}
                />
              </TouchableOpacity>
              <Text
                style={{
                  color: "#0B2051",
                  fontFamily: "Prompt",
                  fontSize: size.width < 1280 ? 16 : 25,
                  marginLeft: 20,
                }}
              >
                ได้รับ Certificate
              </Text>
            </View>
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                marginBottom: height * 0.1,
              }}
            >
              <Text
                style={{ color: "#ADB7CF", fontFamily: "Prompt", fontSize: 14 }}
              >
                อัพโหลดไฟล์ .jpg .jpeg
              </Text>
              <TouchableOpacity
                onPress={() => {
                  navigation.navigate("Certificate", {
                    id: body.cer_id,
                    title: body.title,
                    teacherName:
                      profile?.user_accounts?.firstName +
                      " " +
                      profile?.user_accounts?.lastName,
                    setcer,
                  });
                }}
                disabled={body.certificate ? false : true}
                style={{
                  width: 200,
                  height: 50,
                  backgroundColor: body.certificate ? "#0D88F0" : "#CBCBCB",
                  borderRadius: 5,
                  justifyContent: "center",
                  alignItems: "center",
                  marginLeft: 19,
                }}
              >
                <Text
                  style={{
                    color: "#FFFFFF",
                    fontFamily: "Prompt",
                    fontSize: 18,
                  }}
                >
                  จัดการใบCertificate
                </Text>
              </TouchableOpacity>
            </View>
            {size.width < 1280 && (
              <View style={{ flexDirection: "row", marginVertical: 20 }}>
                <TouchableOpacity
                  onPress={async () => {
                    let bodys = { ...body, available: "DRAFT" };
                    const response = await apiservice({
                      path: "/course/create",
                      method: "post",
                      body: bodys,
                      token: token?.accessToken,
                    });
                    setbody({
                      Type: "COURSESCHOOL",
                      title: "test course",
                      about: "",
                      img_content: "",
                      price: 0,
                      approve: "",
                      grade: "",
                      length_detail: "",
                      duration_study: "",
                      percent: 80,
                      level: "",
                      certificate: true,
                      certificate_detail: {},
                      available: "DRAFT",
                      educationmodel: [1],
                      meetingurl: "",
                      workDate: [],
                      datetime: "",
                      startDate: new Date(),
                      endDate: moment(new Date()).add(1, "days"),
                      studymodel: [
                        {
                          number: 0,
                          listen_name: "",
                          tab: 0,
                          listen_detail: [],
                          textinputs: "",
                        },
                      ],
                      cer_id: 1,
                      uid: route.params.uid,
                      FAQ: [
                        {
                          Q: "",
                          A: "",
                        },
                      ],
                      announcement: " ข้อมูลผู้สอน.... ",
                    });
                  }}
                  style={{
                    width: 100,
                    height: 50,
                    borderWidth: 1,
                    borderColor: "#0D88F0",
                    borderRadius: 5,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Text
                    style={{
                      fontFamily: "Prompt",
                      fontSize: 14,
                      color: "#0D88F0",
                    }}
                  >
                    บันทึกแบบร่าง
                  </Text>
                </TouchableOpacity>

                <TouchableOpacity
                  onPress={route.params.university_id ? create : createGeneral}
                  style={{
                    width: 100,
                    height: 50,
                    backgroundColor: "#0D88F0",
                    borderRadius: 5,
                    justifyContent: "center",
                    alignItems: "center",
                    marginLeft: 15,
                  }}
                >
                  <Text
                    style={{
                      fontFamily: "Prompt",
                      fontSize: 14,
                      color: "#FFFFFF",
                    }}
                  >
                    บันทึกและแสดง
                  </Text>
                </TouchableOpacity>
              </View>
            )}
          </View>
        </View>
        <Modal
          transparent={true}
          visible={modalCourse}
          onRequestClose={() => {
            Alert.alert("Modal has been closed.");
            setModalCourse(!modalCourse);
          }}
        >
          <View style={styles.bgModal}>
            <ScrollView>
              <View
                style={[
                  size.width < 500
                    ? stylesResponsive.bgDetailModal
                    : styles.bgDetailModal,
                ]}
              >
                <View style={{ height: "100%" }}>
                  <View style={{ height: "90%" }}>
                    <Text
                      style={[
                        size.width < 500
                          ? stylesResponsive.text30
                          : styles.text30,
                      ]}
                    >
                      เนื้อหาบทเรียน
                    </Text>
                    <View style={{ flexDirection: "row", marginTop: 27 }}>
                      <TouchableOpacity
                        onPress={() => {
                          setPageCourse(0);
                        }}
                        style={[
                          size.width < 1280
                            ? stylesResponsive.lastTabModal
                            : styles.lastTabModal,
                          {
                            backgroundColor: pageCourse != 0 && "#0D88F0",
                          },
                        ]}
                      >
                        <Text
                          style={{
                            fontSize: size.width < 1280 ? 16 : 25,
                            fontFamily: "Prompt",
                            color: pageCourse == 0 ? "#0D88F0" : "#FFFFFF",
                          }}
                        >
                          ตั้งค่า
                        </Text>
                      </TouchableOpacity>
                      <TouchableOpacity
                        onPress={onPressSetting}
                        disabled={modelrecent.TYPE == "" ? true : false}
                        style={[
                          size.width < 1280
                            ? stylesResponsive.fristTabModal
                            : styles.fristTabModal,
                          {
                            backgroundColor: pageCourse == 0 && "#0D88F0",
                            borderWidth: pageCourse != 0 && 1,
                            borderColor: pageCourse != 0 && "#0D88F0",
                          },
                        ]}
                      >
                        <Text
                          style={{
                            fontSize: size.width < 1280 ? 16 : 25,
                            fontFamily: "Prompt",
                            color: pageCourse == 0 ? "#FFFFFF" : "#0D88F0",
                          }}
                        >
                          ใส่เนื้อหาบทเรียน
                        </Text>
                      </TouchableOpacity>
                    </View>
                    {pageCourse == 0 && (
                      <View style={{}}>
                        <View style={{ width: "80%", alignSelf: "center" }}>
                          <Text
                            style={{
                              fontSize: size.width < 500 ? 16 : 25,
                              fontFamily: "Prompt",
                              color: "#0B2051",
                              marginTop: 15,
                            }}
                          >
                            รูปแบบบทเรียน
                          </Text>
                          <RNPickerSelect
                            placeholder={placeholder1}
                            onValueChange={(value) => {
                              if (modelrecent.TYPE != "") {
                                setWarningSelectLesson(false);
                              }
                              setModelRecent((val) => ({
                                ...val,
                                TYPE: value,
                              }));
                              // setSelect(val);
                              setSetting(true);
                            }}
                            value={modelrecent.TYPE}
                            items={study}
                            style={{
                              inputWeb: {
                                width: "100%",
                                maxWidth: 700,
                                height: 50,
                                borderWidth: 1,
                                backgroundColor: "#FFFFFF",
                                borderColor: "#E0E0E0",
                                color: "#0D88F0",
                                fontFamily: "Prompt",
                                fontSize: size.width < 500 ? 14 : 18,
                                paddingLeft: 15,
                              },
                            }}
                          />
                          {warningSelectLesson && (
                            <Text
                              style={[
                                size.width < 1280
                                  ? stylesResponsive.text10R
                                  : styles.text14R,
                              ]}
                            >
                              กรุณาเลือกรูปแบบบทเรียนก่อนจึงจะใส่เนื้อหาบทเรียนได้
                            </Text>
                          )}
                          <Text
                            style={{
                              fontSize: size.width < 500 ? 16 : 25,
                              fontFamily: "Prompt",
                              color: "#0B2051",
                              marginTop: 45,
                            }}
                          >
                            เวลาที่ใช้ในการเรียน
                          </Text>
                          <View
                            // value={modelrecent.time}
                            // onChangeText={(text) => {
                            //   setModelRecent((val) => ({ ...val, time: text }));
                            // }}
                            style={{
                              width: "100%",
                              maxWidth: 700,
                              height: 50,
                              borderWidth: 1,
                              borderColor: "#E0E0E0",
                            }}
                          >
                            <NumberFormat
                              value={modelrecent.time}
                              format="####ชั่วโมง"
                              onValueChange={(value) => {
                                setModelRecent({
                                  ...modelrecent,
                                  time: value.floatValue,
                                });
                              }}
                              style={{
                                borderWidth: 0,
                                height: 50,
                                fontFamily: "Prompt",
                                paddingHorizontal: 15,
                                fontSize: size.width < 500 ? 14 : 18,
                                color: "#0D88F0",
                                paddingLeft: 15,
                              }}
                            />
                          </View>
                        </View>
                        <View
                          style={{
                            width: "100%",
                            paddingTop: 35,
                            paddingBottom: height * 0.05,
                            backgroundColor: "#EBEBEB",
                            marginVertical: 45,
                          }}
                        >
                          <View style={{ width: "90%", alignSelf: "center" }}>
                            <Text
                              style={{
                                fontSize: size.width < 500 ? 16 : 25,
                                fontFamily: "Prompt",
                                color: "#0B2051",
                              }}
                            >
                              เอกสารประกอบการเรียน
                            </Text>
                            <TouchableOpacity
                              onPress={pdf}
                              style={{
                                width: size.width < 500 ? 100 : 150,
                                height: 40,
                                backgroundColor: "#0D88F0",
                                justifyContent: "center",
                                alignItems: "center",
                                marginVertical: 15,
                              }}
                            >
                              <Text
                                style={{
                                  fontSize: size.width < 500 ? 14 : 18,
                                  fontFamily: "Prompt",
                                  color: "#FFFFFF",
                                }}
                              >
                                อัพโหลดไฟล์
                              </Text>
                            </TouchableOpacity>
                            <FlatList
                              data={modelrecent.document}
                              numColumns={1}
                              scrollEnabled={false}
                              renderItem={({ index, item }) => {
                                return (
                                  <View
                                    style={{
                                      flexDirection: "row",
                                      justifyContent: "space-between",
                                      borderTopWidth: index == 0 && 1,
                                      borderBottomWidth: 1,
                                      borderTopColor: "#BBBBBB",
                                      borderBottomColor: "#BBBBBB",
                                      paddingVertical: 16,
                                    }}
                                  >
                                    <Text
                                      style={{
                                        fontSize: size.width < 500 ? 14 : 18,
                                        fontFamily: "Prompt",
                                        color: "#0B2051",
                                      }}
                                    >
                                      {item.name}
                                    </Text>
                                    <TouchableOpacity
                                      onPress={() => {
                                        setModelRecent((val) => ({
                                          ...val,
                                          document: val.document.filter(
                                            (items, indexs) => {
                                              return indexs != index;
                                            }
                                          ),
                                        }));
                                      }}
                                      style={{ marginRight: 20 }}
                                    >
                                      <MaterialIcons
                                        name="delete"
                                        size={24}
                                        color="#0B2051"
                                      />
                                    </TouchableOpacity>
                                  </View>
                                );
                              }}
                            />
                          </View>
                        </View>
                      </View>
                    )}
                    {pageCourse == 1 && (
                      <View
                        style={{
                          alignItems: "center",
                          marginVertical: 30,
                        }}
                      >
                        <TouchableOpacity
                          onPress={imagecouse}
                          style={[
                            size.width < 500
                              ? stylesResponsive.uploadImgModal
                              : styles.uploadImgModal,
                          ]}
                        >
                          {modelrecent.detail.image.length > 0 ? (
                            <Image
                              resizeMode="stretch"
                              style={[
                                size.width < 500
                                  ? stylesResponsive.uploadImgModal
                                  : styles.uploadImgModal,
                                { width: "100%" },
                              ]}
                              source={{
                                uri:
                                  "https://api.courseoncloud.com/api/image/getimage/" +
                                  modelrecent.detail.image.replace(".png", ""),
                              }}
                            />
                          ) : (
                            <Text
                              style={[
                                size.width < 500
                                  ? stylesResponsive.text30Oo
                                  : styles.text30Oo,
                              ]}
                            >
                              อัพโหลดภาพปก ..
                            </Text>
                          )}
                        </TouchableOpacity>
                        <Text
                          style={{
                            width: "75%",
                            alignSelf: "center",
                            fontFamily: "Prompt",
                            color: "#CCC",
                          }}
                        >
                          1280x720
                        </Text>
                        <View
                          style={{
                            width: "75%",
                            borderWidth: 1,
                            borderColor: "#E0E0E0",
                            marginVertical: 25,
                          }}
                        >
                          <CKEditor
                            editor={ClassicEditor}
                            data={modelrecent.detail.detail}
                            config={ck}
                            onReady={(editor) => {
                              // You can store the "editor" and use when it is needed.
                            }}
                            onChange={async (event, editor) => {
                              const data = await editor.getData();

                              setModelRecent((val) => ({
                                ...val,
                                detail: {
                                  ...val.detail,
                                  detail: data,
                                },
                              }));
                            }}
                            onBlur={(event, editor) => {}}
                            onFocus={(event, editor) => {}}
                          />
                        </View>
                      </View>
                    )}
                    {pageCourse == 2 && (
                      <View
                        style={{
                          alignSelf: "center",
                          marginVertical: 30,
                          width: "70%",
                        }}
                      >
                        <Text
                          style={{
                            fontSize: size.width < 500 ? 16 : 25,
                            fontFamily: "Prompt",
                            color: "#0B2051",
                          }}
                        >
                          URL VIDEO
                        </Text>
                        <View
                          style={{
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <TextInput
                            autoComplete="off"
                            value={modelrecent.detail.image}
                            onChangeText={(text) => {
                              setModelRecent((val) => ({
                                ...val,
                                detail: {
                                  ...val.detail,
                                  image: text.includes("https://")
                                    ? text
                                    : text.includes("www")
                                    ? "https://" + text
                                    : "https://www." + text,
                                },
                              }));
                            }}
                            style={{
                              width: "100%",
                              height: 50,
                              borderWidth: 1,
                              borderColor: "#E0E0E0",
                              paddingHorizontal: 15,
                              fontSize: size.width < 500 ? 14 : 24,
                              fontFamily: "Prompt",
                              marginVertical: 18,
                              color: "#0B2051",
                            }}
                          />
                        </View>
                        <Text
                          style={[
                            size.width < 500
                              ? stylesResponsive.text25
                              : styles.text25,
                          ]}
                        >
                          เนื้อหา
                        </Text>
                        <View style={styles.viewCkEditor}>
                          <CKEditor
                            editor={ClassicEditor}
                            data={modelrecent.detail.detail}
                            config={ck}
                            onReady={(editor) => {
                              // You can store the "editor" and use when it is needed.
                            }}
                            onChange={async (event, editor) => {
                              const data = await editor.getData();

                              setModelRecent((val) => ({
                                ...val,
                                detail: {
                                  ...val.detail,
                                  detail: data,
                                },
                              }));
                            }}
                            onBlur={(event, editor) => {}}
                            onFocus={(event, editor) => {}}
                          />
                        </View>
                      </View>
                    )}
                    {pageCourse == 3 && (
                      <View
                        style={{
                          width: "100%",
                          alignItems: "center",
                          marginVertical: 30,
                        }}
                      >
                        <TouchableOpacity
                          onPress={imagecouse}
                          style={[
                            size.width < 500
                              ? stylesResponsive.uploadImgModal
                              : styles.uploadImgModal,
                          ]}
                        >
                          {modelrecent.detail.image.length > 0 ? (
                            <Image
                              resizeMode="stretch"
                              style={[
                                size.width < 500
                                  ? stylesResponsive.uploadImgModal
                                  : styles.uploadImgModal,
                                { width: "100%" },
                              ]}
                              source={{
                                uri:
                                  "https://api.courseoncloud.com/api/image/getimage/" +
                                  modelrecent.detail.image.replace(".png", ""),
                              }}
                            />
                          ) : (
                            <Text
                              style={[
                                size.width < 500
                                  ? stylesResponsive.text30Oo
                                  : styles.text30Oo,
                              ]}
                            >
                              อัพโหลดภาพปก ..
                            </Text>
                          )}
                        </TouchableOpacity>
                        <Text
                          style={{
                            width: "75%",
                            alignSelf: "center",
                            fontFamily: "Prompt",
                            color: "#CCC",
                          }}
                        >
                          1280x720
                        </Text>
                        <View style={{ width: "75%" }}>
                          <Text
                            style={[
                              size.width < 500
                                ? stylesResponsive.text25
                                : styles.text25,
                              { marginTop: 10 },
                            ]}
                          >
                            วันเวลาที่เรียน
                          </Text>
                          <View
                            style={{
                              flexDirection: "row",
                              justifyContent: "space-between",
                              paddingHorizontal: 15,
                              alignItems: "center",
                              borderWidth: 1,
                              borderColor: "#E0E0E0",
                              width: "100%",
                              height: 40,
                            }}
                          >
                            <Text
                              style={[
                                size.width < 500
                                  ? stylesResponsive.text25
                                  : styles.text25,
                              ]}
                            ></Text>
                            <TouchableOpacity>
                              <MaterialIcons
                                name="date-range"
                                size={20}
                                color="#0D88F0"
                              />
                            </TouchableOpacity>
                          </View>
                          <Text
                            style={[
                              size.width < 500
                                ? stylesResponsive.text25
                                : styles.text25,
                              { marginTop: 20 },
                            ]}
                          >
                            เวลาที่ใช้ในการเรียน
                          </Text>
                          <TextInput
                            autoComplete="off"
                            value={modelrecent.detail.onlineurl}
                            onChangeText={(text) => {
                              setModelRecent((val) => ({
                                ...val,
                                detail: {
                                  ...val.detail,
                                  onlineurl: text,
                                },
                              }));
                            }}
                            style={styles.inputSetting}
                          />
                          <Text
                            style={[
                              size.width < 500
                                ? stylesResponsive.text25
                                : styles.text25,
                              { marginTop: 20 },
                            ]}
                          >
                            URL การเรียนการสอนออนไลน์
                          </Text>
                          <TextInput
                            autoComplete="off"
                            style={styles.inputSetting}
                          />
                          <Text
                            style={[
                              size.width < 500
                                ? stylesResponsive.text25
                                : styles.text25,
                              { marginTop: 20 },
                            ]}
                          >
                            รหัสผ่านในการเข้าร่วม
                          </Text>
                          <TextInput
                            autoComplete="off"
                            value={modelrecent.detail.password}
                            onChangeText={(text) => {
                              setModelRecent((val) => ({
                                ...val,
                                detail: {
                                  ...val.detail,
                                  password: text,
                                },
                              }));
                            }}
                            secureTextEntry
                            style={styles.inputSetting}
                          />
                        </View>
                      </View>
                    )}
                  </View>
                  <View style={styles.viewRecordModal}>
                    <TouchableOpacity
                      onPress={async () => {
                        if (
                          modelrecent.time != "" &&
                          modelrecent.TYPE != "" &&
                          modelrecent.detail.detail != ""
                          //  &&
                          // modelrecent.detail.image != ""
                        ) {
                          delete modelrecent?.id;
                          const response = await apiservice({
                            path: "/course/createeducation",
                            method: "POST",
                            body: modelrecent,
                            token: token?.accessToken,
                          });
                          if (response.status == 200) {
                            setbody((val) => ({
                              ...val,
                              studymodel: val.studymodel.map((items, indexs) =>
                                select == items.number
                                  ? {
                                      ...items,
                                      listen_detail: items.listen_detail.map(
                                        (e, i) =>
                                          text == i
                                            ? {
                                                ...e,
                                                education_id:
                                                  response.data.data.id,
                                              }
                                            : e
                                      ),
                                    }
                                  : items
                              ),
                            }));
                            setTimeout(() => {
                              defaultModelrecent(
                                modelrecent.time,
                                modelrecent.telNo,
                                modelrecent.document,
                                modelrecent.detail,
                                modelrecent.detail.detail,
                                modelrecent.detail.image
                              ),
                                defaultModelrecent(modelrecent.TYPE);
                              setWarningSelectLesson(true);
                              setPageCourse(0);
                            }, 1000);

                            setModalCourse(!modalCourse);
                          }
                        } else {
                          setmodalmodalWarning(true);
                        }
                      }}
                      style={[
                        size.width < 500
                          ? stylesResponsive.buttonRecordModal
                          : styles.buttonRecordModal,
                      ]}
                    >
                      <Text
                        style={[
                          size.width < 500
                            ? stylesResponsive.text25W
                            : styles.text25W,
                        ]}
                      >
                        บันทึก
                      </Text>
                    </TouchableOpacity>
                    <TouchableOpacity
                      onPress={async () => {
                        setModelRecent({
                          TYPE: "",
                          time: "",
                          telNo: "",
                          document: [],
                          detail: {
                            image: "",
                            detail: "",
                          },
                        });

                        setModalCourse(!modalCourse);
                      }}
                      style={[
                        size.width < 500
                          ? stylesResponsive.buttonBackModal
                          : styles.buttonBackModal,
                      ]}
                    >
                      <Text
                        style={[
                          size.width < 500
                            ? stylesResponsive.text25O
                            : styles.text25O,
                        ]}
                      >
                        ยกเลิก
                      </Text>
                    </TouchableOpacity>
                  </View>
                </View>
              </View>
            </ScrollView>
          </View>
        </Modal>
        <Modal
          transparent={true}
          visible={modalWork}
          onRequestClose={() => {
            Alert.alert("Modal has been closed.");
            setModalWork(!modalWork);
          }}
        >
          <View style={styles.bgModal}>
            <ScrollView>
              <View
                style={[
                  size.width < 500
                    ? stylesResponsive.bgDetailModal
                    : styles.bgDetailModal,
                ]}
              >
                <View style={{ height: "100%" }}>
                  <View style={{ height: "90%" }}>
                    <Text
                      style={[
                        size.width < 500
                          ? stylesResponsive.text30
                          : styles.text30,
                      ]}
                    >
                      งานที่ได้รับมอบหมาย
                    </Text>
                    <View style={{ flexDirection: "row", marginTop: 27 }}>
                      <TouchableOpacity
                        onPress={() => {
                          setPageWork(0);
                        }}
                        style={[
                          size.width < 1280
                            ? stylesResponsive.fristTabModal
                            : styles.fristTabModal,
                          {
                            backgroundColor:
                              pageWork == 0 ? "#FFFFFF" : "#0D88F0",
                            borderWidth: pageWork == 0 && 1,
                            borderColor: pageWork == 0 && "#0D88F0",
                          },
                        ]}
                      >
                        <Text
                          style={{
                            fontSize: size.width < 500 ? 14 : 25,
                            fontFamily: "Prompt",
                            color: pageWork == 0 ? "#0D88F0" : "#FFFFFF",
                          }}
                        >
                          รายละเอียดงาน
                        </Text>
                      </TouchableOpacity>
                      <TouchableOpacity
                        onPress={() => {
                          setPageWork(1);
                        }}
                        style={[
                          size.width < 1280
                            ? stylesResponsive.lastTabModal
                            : styles.lastTabModal,
                          {
                            backgroundColor: pageWork != 1 && "#0D88F0",
                          },
                        ]}
                      >
                        <Text
                          style={{
                            fontSize: size.width < 500 ? 14 : 25,
                            fontFamily: "Prompt",
                            color: pageWork == 0 ? "#FFFFFF" : "#0D88F0",
                          }}
                        >
                          ตั้งค่า
                        </Text>
                      </TouchableOpacity>
                    </View>
                    {pageWork == 0 && (
                      <View
                        style={{
                          marginVertical: 30,
                          width: "100%",
                          alignItems: "center",
                        }}
                      >
                        <TouchableOpacity
                          onPress={imagework}
                          style={[
                            size.width < 500
                              ? stylesResponsive.uploadImgModal
                              : styles.uploadImgModal,
                          ]}
                        >
                          {work.image.length > 0 ? (
                            <Image
                              resizeMode="stretch"
                              style={[
                                size.width < 500
                                  ? stylesResponsive.uploadImgModal
                                  : styles.uploadImgModal,
                                { width: "100%" },
                              ]}
                              source={{
                                uri:
                                  "https://api.courseoncloud.com/api/image/getimage/" +
                                  work.image.replace(".png", ""),
                              }}
                            />
                          ) : (
                            <Text
                              style={[
                                size.width < 500
                                  ? stylesResponsive.text30Oo
                                  : styles.text30Oo,
                              ]}
                            >
                              อัพโหลดภาพปก ..
                            </Text>
                          )}
                        </TouchableOpacity>
                        <Text
                          style={{
                            width: "75%",
                            alignSelf: "center",
                            fontFamily: "Prompt",
                            color: "#CCC",
                          }}
                        >
                          1280x720
                        </Text>
                        <View
                          style={[
                            styles.viewCkEditor,
                            { width: "75%", marginVertical: 25 },
                          ]}
                        >
                          <CKEditor
                            editor={ClassicEditor}
                            data={work.detail}
                            config={ck}
                            onReady={(editor) => {
                              // You can store the "editor" and use when it is needed.
                            }}
                            onChange={async (event, editor) => {
                              const data = await editor.getData();

                              // setDesCom(data);
                              setwork((val) => ({ ...val, detail: data }));
                            }}
                            onBlur={(event, editor) => {}}
                            onFocus={(event, editor) => {}}
                          />
                        </View>
                      </View>
                    )}
                    {pageWork == 1 && (
                      <View
                        style={{
                          width: "80%",
                          alignSelf: "center",
                          marginVertical: 45,
                        }}
                      >
                        <Text
                          style={[
                            size.width < 500
                              ? stylesResponsive.text25
                              : styles.text25,
                          ]}
                        >
                          จำนวนการส่งงาน
                        </Text>
                        <View
                          // defaultValue={work.limit}
                          // onChangeText={(text) => {
                          //   setwork((val) => ({ ...val, limit: text }));
                          // }}
                          style={[
                            styles.inputWorkModal,
                            { paddingHorizontal: 0 },
                          ]}
                        >
                          <NumberFormat
                            value={work.limit}
                            format="##ครั้ง"
                            onValueChange={(value) => {
                              setwork({
                                ...work,
                                limit: value.floatValue,
                              });
                            }}
                            style={{
                              borderWidth: 0,
                              height: 50,

                              fontSize: size.width < 500 ? 14 : 18,
                              fontFamily: "Prompt",
                              color: "#0D88F0",
                              paddingLeft: 15,
                            }}
                          />
                        </View>
                        <Text style={styles.text12R}>
                          หากไม่กำหนดจะไม่จำกัดจำนวนการส่ง
                        </Text>
                        {/* <Text
                          style={[
                            size.width < 500
                              ? stylesResponsive.text25
                              : styles.text25,
                            ,
                            { marginTop: 10 },
                          ]}
                        >
                          เวลาในการส่งงาน
                        </Text> */}
                        {/* <TouchableOpacity
                          style={{ justifyContent: "center" }}
                          onPress={() => {
                            setWorkD((val) => !val);
                          }}
                        >
                          <Text
                            style={[
                              styles.inputWorkModal,
                              {
                                textAlignVertical: "center",
                                paddingTop: size.width < 500 ? 15 : 10,
                                fontSize: size.width < 500 ? 14 : 18,
                                fontFamily: "Prompt",
                                color: "#0D88F0",
                                marginTop: 0,
                              },
                            ]}
                          >
                            {work.limitdate != null &&
                              moment(work.limitdate).format("DD-MM-YYYY")}
                          </Text>
                        </TouchableOpacity> */}
                        {/* {workD && (
                          <Calendar
                            minDate={new Date()}
                            onChange={(dd) => {
                              setwork((val) => ({ ...val, limitdate: dd }));
                              setWorkD(false);
                            }}
                            value={work.limitdate}
                          />
                        )} */}
                        {/* <TextInput   autoComplete="off"
                          defaultValue={work.limitdate}
                          onChangeText={(text) => {
                            setwork((val) => ({ ...val, limitdate: text }));
                          }}
                          style={[styles.text25, styles.inputWorkModal]}
                        /> */}
                        {/* <Text style={styles.text12R}>
                          หากไม่กำหนดจะไม่จำกัดเวลาการส่ง
                        </Text> */}
                      </View>
                    )}
                  </View>
                  <View style={styles.viewRecordModal}>
                    <TouchableOpacity
                      onPress={async () => {
                        if (
                          // work.image != "" &&
                          work.detail != ""
                        ) {
                          delete work?.id;
                          const response = await apiservice({
                            path: "/course/createstudy",
                            method: "POST",
                            body: work,
                            token: token?.accessToken,
                          });
                          if (response.status == 200) {
                            setbody((val) => ({
                              ...val,
                              studymodel: val.studymodel.map((items, indexs) =>
                                select == items.number
                                  ? {
                                      ...items,
                                      listen_detail: items.listen_detail.map(
                                        (e, i) =>
                                          text == i
                                            ? {
                                                ...e,
                                                education_id:
                                                  response.data.data.id,
                                              }
                                            : e
                                      ),
                                    }
                                  : items
                              ),
                            }));
                            setTimeout(() => {
                              defaultModelrecent(
                                work.image,
                                work.detail,
                                work.limit,
                                work.limitdate
                              );
                            }, 1000);
                            setPageWork(0);
                            setModalWork(!modalWork);
                          }
                        } else {
                          setmodalmodalWarning(true);
                        }
                      }}
                      style={[
                        size.width < 500
                          ? stylesResponsive.buttonRecordModal
                          : styles.buttonRecordModal,
                      ]}
                    >
                      <Text
                        style={[
                          size.width < 500
                            ? stylesResponsive.text25W
                            : styles.text25W,
                        ]}
                      >
                        บันทึก
                      </Text>
                    </TouchableOpacity>
                    <TouchableOpacity
                      onPress={() => {
                        defaultModelrecent(
                          work.image,
                          work.detail,
                          work.limit,
                          work.limitdate
                        );
                        setModalWork(!modalWork);
                      }}
                      style={[
                        size.width < 500
                          ? stylesResponsive.buttonBackModal
                          : styles.buttonBackModal,
                      ]}
                    >
                      <Text
                        style={[
                          size.width < 500
                            ? stylesResponsive.text25O
                            : styles.text25O,
                        ]}
                      >
                        ยกเลิก
                      </Text>
                    </TouchableOpacity>
                  </View>
                </View>
              </View>
            </ScrollView>
          </View>
        </Modal>
        <Modal
          transparent={true}
          visible={modalTest}
          onRequestClose={() => {
            Alert.alert("Modal has been closed.");
            setModalTest(!modalTest);
          }}
        >
          <View style={styles.bgModal}>
            <ScrollView>
              <View
                style={[
                  size.width < 500
                    ? stylesResponsive.bgDetailModal
                    : styles.bgDetailModal,
                ]}
              >
                <View style={{ height: "100%" }}>
                  <View style={{ height: "90%" }}>
                    <Text
                      style={[
                        size.width < 500
                          ? stylesResponsive.text30
                          : styles.text30,
                      ]}
                    >
                      แบบทดสอบ
                    </Text>
                    <View style={{ flexDirection: "row", marginTop: 27 }}>
                      <TouchableOpacity
                        onPress={() => {
                          setPageTest(0);
                        }}
                        style={[
                          size.width < 1280
                            ? stylesResponsive.fristTabModal
                            : styles.fristTabModal,
                          {
                            width: "33.3%",
                            backgroundColor:
                              pageTest == 0 ? "#FFFFFF" : "#0D88F0",
                            borderWidth: pageTest == 0 && 1,
                            borderColor: pageTest == 0 && "#0D88F0",
                          },
                        ]}
                      >
                        <Text
                          style={{
                            fontSize: size.width < 500 ? 14 : 25,
                            fontFamily: "Prompt",
                            color: pageTest == 0 ? "#0D88F0" : "#FFFFFF",
                          }}
                        >
                          คำอธิบาย
                        </Text>
                      </TouchableOpacity>
                      <TouchableOpacity
                        onPress={() => {
                          setPageTest(1);
                        }}
                        style={[
                          size.width < 1280
                            ? stylesResponsive.lastTabModal
                            : styles.lastTabModal,
                          {
                            width: "33.3%",
                            backgroundColor:
                              pageTest == 1 ? "#FFFFFF" : "#0D88F0",
                          },
                        ]}
                      >
                        <Text
                          style={{
                            fontSize: size.width < 500 ? 14 : 25,
                            fontFamily: "Prompt",
                            color:
                              (pageTest == 0 && "#FFFFFF") ||
                              (pageTest == 1 && "#0D88F0") ||
                              (pageTest == 2 && "#FFFFFF"),
                          }}
                        >
                          ข้อสอบ
                        </Text>
                      </TouchableOpacity>
                      <TouchableOpacity
                        onPress={() => {
                          setPageTest(2);
                        }}
                        style={[
                          size.width < 1280
                            ? stylesResponsive.lastTabModal
                            : styles.lastTabModal,
                          {
                            width: "33.3%",
                            backgroundColor:
                              pageTest == 2 ? "#FFFFFF" : "#0D88F0",
                          },
                        ]}
                      >
                        <Text
                          style={{
                            fontSize: size.width < 500 ? 14 : 25,
                            fontFamily: "Prompt",
                            color:
                              (pageTest == 0 && "#FFFFFF") ||
                              (pageTest == 1 && "#FFFFFF") ||
                              (pageTest == 2 && "#0D88F0"),
                          }}
                        >
                          ตั้งค่า
                        </Text>
                      </TouchableOpacity>
                    </View>
                    {pageTest == 0 && (
                      <View
                        style={{
                          alignItems: "center",
                          marginVertical: 30,
                        }}
                      >
                        <TouchableOpacity
                          onPress={imageQuiz}
                          style={[
                            size.width < 500
                              ? stylesResponsive.uploadImgModal
                              : styles.uploadImgModal,
                          ]}
                        >
                          {quiz.img.image.length > 0 ? (
                            <Image
                              resizeMode="stretch"
                              style={[
                                size.width < 500
                                  ? stylesResponsive.uploadImgModal
                                  : styles.uploadImgModal,
                                { width: "100%" },
                              ]}
                              source={{
                                uri:
                                  "https://api.courseoncloud.com/api/image/getimage/" +
                                  quiz.img.image.replace(".png", ""),
                              }}
                            />
                          ) : (
                            <Text
                              style={[
                                size.width < 500
                                  ? stylesResponsive.text30Oo
                                  : styles.text30Oo,
                              ]}
                            >
                              อัพโหลดภาพปก ..
                            </Text>
                          )}
                        </TouchableOpacity>
                        <Text
                          style={{
                            width: "75%",
                            alignSelf: "center",
                            fontFamily: "Prompt",
                            color: "#CCC",
                          }}
                        >
                          1280x720
                        </Text>
                        <View
                          style={[
                            styles.viewCkEditor,
                            { width: "75%", marginVertical: 25 },
                          ]}
                        >
                          <CKEditor
                            editor={ClassicEditor}
                            data={quiz.detail}
                            config={ck}
                            onReady={(editor) => {
                              // You can store the "editor" and use when it is needed.
                            }}
                            onChange={async (event, editor) => {
                              const data = await editor.getData();
                              setQuiz((val) => ({ ...val, detail: data }));

                              // setDesCom(data);
                            }}
                            onBlur={(event, editor) => {}}
                            onFocus={(event, editor) => {}}
                          />
                        </View>
                      </View>
                    )}
                    <View style={{ overflow: "hidden" }}>
                      {pageTest == 1 && (
                        <FlatList
                          data={quiz.question}
                          extraData={[quiz]}
                          contentContainerStyle={{
                            justifyContent: "flex-start",
                            overflow: "hidden",
                          }}
                          ListEmptyComponent={
                            <View
                              style={{
                                width: size.width < 1280 ? "100%" : "70%",
                                alignSelf: "center",
                                backgroundColor: "#EBEBEB",
                                paddingVertical: 20,
                                paddingHorizontal: size.width < 500 ? 10 : 45,
                                marginTop: 40,
                                marginBottom: 150,
                                height: 400,
                              }}
                            >
                              <View style={{ marginTop: 16 }}>
                                <View
                                  style={[
                                    size.width < 500
                                      ? stylesResponsive.viewQuestionModal
                                      : styles.viewQuestionModal,
                                  ]}
                                >
                                  <Text
                                    style={[
                                      size.width < 500
                                        ? stylesResponsive.text20
                                        : styles.text20,
                                    ]}
                                  >
                                    คำถาม
                                  </Text>
                                </View>
                                <View
                                  style={[
                                    size.width < 500
                                      ? stylesResponsive.selectQuestionModal
                                      : styles.selectQuestionModal,
                                  ]}
                                >
                                  <RNPickerSelect
                                    placeholder={placeholder2}
                                    onValueChange={(val) => {
                                      setSelectQuestion(val);
                                    }}
                                    value={selectQuestion}
                                    items={question}
                                    style={{
                                      inputWeb: {
                                        width: size.width < 500 ? 100 : 170,
                                        height: 40,
                                        color: "#0B2051",
                                        fontFamily: "Prompt",
                                        fontSize: size.width < 500 ? 14 : 20,
                                        borderWidth: 0,
                                        backgroundColor: "#FFFFFF",
                                        marginVertical: 40,
                                      },
                                    }}
                                  />
                                  <TextInput
                                    autoComplete="off"
                                    value={title}
                                    onChangeText={setTitle}
                                    style={[
                                      size.width < 500
                                        ? stylesResponsive.inputQuestionModal
                                        : styles.inputQuestionModal,
                                    ]}
                                  />
                                  <TouchableOpacity
                                    onPress={() => {
                                      setQuiz((val) => ({
                                        ...val,
                                        question: val.question.concat({
                                          title,
                                          type: selectQuestion,
                                          answer:
                                            selectQuestion == "3" ? 0 : null,
                                          image: "",
                                          choice: [],
                                        }),
                                      }));
                                      setTitle("");
                                    }}
                                    style={[
                                      size.width < 500
                                        ? stylesResponsive.buttonAddQuestionModal
                                        : styles.buttonAddQuestionModal,
                                    ]}
                                  >
                                    <Text
                                      style={[
                                        size.width < 500
                                          ? stylesResponsive.text20
                                          : styles.text20,
                                        ,
                                        { color: "#FFFFFF" },
                                      ]}
                                    >
                                      เพิ่ม
                                    </Text>
                                  </TouchableOpacity>
                                </View>
                              </View>
                              <View
                                style={[
                                  styles.viewRecordModal,
                                  { marginTop: 70 },
                                ]}
                              >
                                <TouchableOpacity
                                  onPress={async () => {
                                    if (
                                      // quiz.img.image != "" &&
                                      quiz.detail != "" &&
                                      quiz.question.length > 0
                                    ) {
                                      const responsne = await apiservice({
                                        path:
                                          quiz.id != undefined
                                            ? "/start-course/updatefinal/" +
                                              quiz.id
                                            : "/quiz/create",
                                        method:
                                          quiz.id != undefined ? "put" : "Post",
                                        body: {
                                          ...quiz,
                                          question: quiz.question.map(
                                            (items) => {
                                              if (items.type == "4") {
                                                return {
                                                  ...items,
                                                  answer: items.choice,
                                                };
                                              } else {
                                                return items;
                                              }
                                            }
                                          ),
                                        },
                                        token: token?.accessToken,
                                      });

                                      if (responsne.status == 200) {
                                        setbody((val) => ({
                                          ...val,
                                          studymodel: val.studymodel.map(
                                            (items, indexs) =>
                                              select == items.number
                                                ? {
                                                    ...items,
                                                    listen_detail:
                                                      items.listen_detail.map(
                                                        (e, i) =>
                                                          text == i
                                                            ? {
                                                                ...e,
                                                                education_id:
                                                                  quiz.id !=
                                                                  undefined
                                                                    ? quiz.id
                                                                    : responsne
                                                                        .data
                                                                        .id,
                                                              }
                                                            : e
                                                      ),
                                                  }
                                                : items
                                          ),
                                        }));
                                        setTimeout(() => {
                                          defaultModelrecent(
                                            quiz.img.image,
                                            quiz.detail,
                                            quiz.ans,
                                            quiz.conditions.percent,
                                            quiz.conditions.timer,
                                            quiz.conditions.timerType,
                                            quiz.question
                                          );
                                          setPageTest(0);
                                        }, 1000);

                                        setModalTest(!modalTest);
                                      }
                                    } else {
                                      setmodalmodalWarning(true);
                                    }
                                  }}
                                  style={[
                                    size.width < 500
                                      ? stylesResponsive.buttonRecordModal
                                      : styles.buttonRecordModal,
                                  ]}
                                >
                                  <Text
                                    style={[
                                      size.width < 500
                                        ? stylesResponsive.text25W
                                        : styles.text25W,
                                    ]}
                                  >
                                    บันทึก
                                  </Text>
                                </TouchableOpacity>
                                <TouchableOpacity
                                  onPress={() => {
                                    defaultModelrecent(
                                      quiz.img.image,
                                      quiz.detail,
                                      quiz.ans,
                                      quiz.conditions.percent,
                                      quiz.conditions.timer,
                                      quiz.conditions.timerType,
                                      quiz.question
                                    );
                                    setModalTest(!modalTest);
                                  }}
                                  style={[
                                    size.width < 500
                                      ? stylesResponsive.buttonBackModal
                                      : styles.buttonBackModal,
                                  ]}
                                >
                                  <Text
                                    style={[
                                      size.width < 500
                                        ? stylesResponsive.text25O
                                        : styles.text25O,
                                    ]}
                                  >
                                    ยกเลิก
                                  </Text>
                                </TouchableOpacity>
                              </View>
                            </View>
                          }
                          renderItem={({ item, index }) => {
                            const mainItem = item;
                            const mainIndex = index;

                            return (
                              <View
                                style={{
                                  width: size.width < 1280 ? "100%" : "70%",
                                  alignSelf: "center",
                                  backgroundColor: "#EBEBEB",
                                  paddingVertical: 20,
                                  paddingHorizontal: size.width < 500 ? 10 : 45,
                                  marginTop: 40,
                                  marginBottom: -40,
                                  overflow: "hidden",
                                }}
                              >
                                {item?.image?.length > 0 && (
                                  <View>
                                    <Image
                                      style={{
                                        width: width * 0.25,
                                        height: width * 0.25,
                                      }}
                                      resizeMode="contain"
                                      source={{
                                        uri:
                                          "https://api.courseoncloud.com/api/image/getimage/" +
                                          item?.image.replace(".png", ""),
                                      }}
                                    />
                                  </View>
                                )}
                                <View
                                  style={{
                                    flexDirection:
                                      size.width < 500 ? "column" : "row",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <Text
                                    style={[
                                      size.width < 500
                                        ? stylesResponsive.text20
                                        : styles.text20,
                                    ]}
                                  >
                                    {index + 1}
                                    {"). " + item.title}
                                  </Text>
                                  <View
                                    style={{
                                      flexDirection: "row",
                                      alignSelf: size.width < 500 && "flex-end",
                                      alignItems: "center",
                                    }}
                                  >
                                    <TouchableOpacity
                                      onPress={async () => {
                                        if (item?.image?.length > 0) {
                                          setQuiz((val) => ({
                                            ...val,
                                            question: val.question.map(
                                              (items, indexs) => {
                                                return indexs == index
                                                  ? {
                                                      ...items,
                                                      image: null,
                                                    }
                                                  : items;
                                              }
                                            ),
                                          }));
                                        } else {
                                          let result =
                                            await DocumentPicker.getDocumentAsync(
                                              {
                                                type: "image/*",
                                              }
                                            );
                                          // setImage(result.uri)
                                          const response = await apiservice({
                                            path: "/image/createpdf",
                                            method: "post",
                                            body: {
                                              name: result.name,
                                              base64: result.uri,
                                            },
                                          });

                                          setQuiz((val) => ({
                                            ...val,
                                            question: val.question.map(
                                              (items, indexs) => {
                                                return indexs == index
                                                  ? {
                                                      ...items,
                                                      image:
                                                        response.data
                                                          .imageRefId,
                                                    }
                                                  : items;
                                              }
                                            ),
                                          }));
                                        }
                                      }}
                                    >
                                      <Text
                                        style={{
                                          // width: size.width < 500 ? 100 : 170,
                                          color: "#0B2051",
                                          fontFamily: "Prompt",
                                          fontSize: size.width < 500 ? 12 : 20,
                                          borderWidth: 0,
                                          backgroundColor: "#FFFFFF",
                                          backgroundColor: "#EBEBEB",
                                          textDecorationLine: "underline",
                                          marginRight: 15,
                                        }}
                                      >
                                        {item?.image?.length > 0
                                          ? "Delete image"
                                          : "Add Image"}
                                      </Text>
                                    </TouchableOpacity>
                                    <Text
                                      style={{
                                        // width: size.width < 500 ? 100 : 170,
                                        color: "#0B2051",
                                        fontFamily: "Prompt",
                                        fontSize: size.width < 500 ? 12 : 20,
                                        borderWidth: 0,
                                        backgroundColor: "#FFFFFF",
                                        backgroundColor: "#EBEBEB",
                                        textDecorationLine: "underline",
                                        marginRight: 15,
                                      }}
                                    >
                                      {item.type == 1
                                        ? "คำตอบเดียว"
                                        : item.type == 2
                                        ? "หลายคำตอบ"
                                        : item.type == 3
                                        ? "ถูกหรือผิด"
                                        : item.type == 4
                                        ? "จับคู่ข้อความ"
                                        : "เติมคำในช่องว่าง"}
                                    </Text>

                                    <TouchableOpacity
                                      onPress={() => {
                                        setQuiz((val) => ({
                                          ...val,
                                          question: val.question.filter(
                                            (items, indexs) => {
                                              return indexs != index;
                                            }
                                          ),
                                        }));
                                      }}
                                    >
                                      <MaterialIcons
                                        name="delete"
                                        size={size.width < 500 ? 18 : 24}
                                        color="#0B2051"
                                      />
                                    </TouchableOpacity>
                                  </View>
                                </View>
                                {item.type == "1" && (
                                  <View
                                    style={{
                                      height:
                                        1 * (item.choice.length * 55) + 40,
                                    }}
                                  >
                                    <View
                                      style={{
                                        height: 1 * (item.choice.length * 55),
                                      }}
                                    >
                                      <FlatList
                                        data={item.choice}
                                        numColumns={1}
                                        extraData={[quiz]}
                                        scrollEnabled={false}
                                        renderItem={({ index, item }) => {
                                          // log;
                                          return (
                                            <View
                                              style={[
                                                styles.detailChoiceModal,
                                                { overflow: "hidden" },
                                              ]}
                                            >
                                              <Text
                                                style={[
                                                  styles.text16,
                                                  styles.textNoChoice,
                                                ]}
                                              >
                                                {index + 1 + ". "}
                                              </Text>
                                              <TextInput
                                                autoComplete="off"
                                                value={item}
                                                onChangeText={(text) => {
                                                  setQuiz((val) => ({
                                                    ...val,
                                                    question: val.question.map(
                                                      (items, indexs) => {
                                                        if (
                                                          indexs == mainIndex
                                                        ) {
                                                          return {
                                                            ...items,
                                                            choice:
                                                              items.choice.map(
                                                                (vals, ins) => {
                                                                  if (
                                                                    ins == index
                                                                  ) {
                                                                    return text;
                                                                  } else {
                                                                    return vals;
                                                                  }
                                                                }
                                                              ),
                                                          };
                                                        } else {
                                                          return items;
                                                        }
                                                      }
                                                    ),
                                                  }));
                                                }}
                                                style={[
                                                  styles.inputDetailChoiceModal,
                                                  {
                                                    fontSize:
                                                      size.width < 500
                                                        ? 12
                                                        : 16,
                                                    paddingRight:
                                                      size.width < 500 && "50%",
                                                  },
                                                ]}
                                              />
                                              <View
                                                style={
                                                  styles.selectAnsChoiceModal
                                                }
                                              >
                                                <TouchableOpacity
                                                  key={item}
                                                  onPress={() => {
                                                    setQuiz((val) => ({
                                                      ...val,
                                                      question:
                                                        val.question.map(
                                                          (items, indexs) => {
                                                            if (
                                                              indexs ==
                                                              mainIndex
                                                            ) {
                                                              return {
                                                                ...items,
                                                                answer: index,
                                                              };
                                                            } else {
                                                              return items;
                                                            }
                                                          }
                                                        ),
                                                    }));
                                                  }}
                                                  style={{
                                                    flexDirection: "row",
                                                    alignItems: "center",
                                                    marginRight: 20,
                                                  }}
                                                >
                                                  <MaterialIcons
                                                    name={
                                                      index == mainItem.answer
                                                        ? "radio-button-checked"
                                                        : "radio-button-unchecked"
                                                    }
                                                    size={
                                                      size.width < 500 ? 20 : 24
                                                    }
                                                    color="#0D88F0"
                                                  />

                                                  <Text
                                                    style={[
                                                      styles.text16,
                                                      {
                                                        marginLeft: 10,
                                                        fontSize:
                                                          size.width < 500
                                                            ? 12
                                                            : 16,
                                                      },
                                                    ]}
                                                  >
                                                    คำตอบถูก
                                                  </Text>
                                                </TouchableOpacity>
                                                <TouchableOpacity
                                                  onPress={() => {
                                                    setQuiz((val) => ({
                                                      ...val,
                                                      question:
                                                        val.question.map(
                                                          (items, indexs) => {
                                                            if (
                                                              indexs ==
                                                              mainIndex
                                                            ) {
                                                              return {
                                                                ...items,
                                                                choice:
                                                                  items.choice.filter(
                                                                    (
                                                                      itemss,
                                                                      indexss
                                                                    ) => {
                                                                      return (
                                                                        indexss !=
                                                                        index
                                                                      );
                                                                    }
                                                                  ),
                                                              };
                                                            } else {
                                                              return items;
                                                            }
                                                          }
                                                        ),
                                                    }));
                                                  }}
                                                >
                                                  <MaterialIcons
                                                    name="cancel"
                                                    size={24}
                                                    color="#484848"
                                                  />
                                                </TouchableOpacity>
                                              </View>
                                            </View>
                                          );
                                        }}
                                      />
                                    </View>
                                    {/* {quiz.question != null && quiz.question.choice} */}
                                    <TouchableOpacity
                                      onPress={() => {
                                        setQuiz((val) => ({
                                          ...val,
                                          question: val.question.map(
                                            (items, indexs) => {
                                              if (indexs == mainIndex) {
                                                return {
                                                  ...items,
                                                  choice:
                                                    items.choice.concat(""),
                                                };
                                              } else {
                                                return items;
                                              }
                                            }
                                          ),
                                        }));
                                      }}
                                      style={[styles.buttonAddAnsModal]}
                                    >
                                      <MaterialIcons
                                        name="add-circle"
                                        size={24}
                                        color="#0B2051"
                                      />
                                      <Text
                                        style={[
                                          styles.text16,
                                          {
                                            marginLeft: 10,
                                            fontSize:
                                              size.width < 500 ? 12 : 16,
                                          },
                                        ]}
                                      >
                                        เพิ่มคำตอบ
                                      </Text>
                                    </TouchableOpacity>
                                  </View>
                                )}
                                {item.type == "2" && (
                                  <View
                                    style={{
                                      height:
                                        1 * (item.choice.length * 60) + 40,
                                    }}
                                  >
                                    <View
                                      style={{
                                        height: 1 * (item.choice.length * 60),
                                      }}
                                    >
                                      <FlatList
                                        data={item.choice}
                                        ext={[quiz]}
                                        numColumns={1}
                                        scrollEnabled={false}
                                        renderItem={({ index, item }) => {
                                          return (
                                            <View
                                              style={[
                                                styles.detailChoiceModal,
                                                { overflow: "hidden" },
                                              ]}
                                            >
                                              <Text
                                                style={[
                                                  styles.text16,
                                                  styles.textNoChoice,
                                                ]}
                                              >
                                                {index + 1 + ". "}
                                              </Text>
                                              <TextInput
                                                autoComplete="off"
                                                value={item}
                                                onChangeText={(text) => {
                                                  setQuiz((val) => ({
                                                    ...val,
                                                    question: val.question.map(
                                                      (items, indexs) => {
                                                        if (
                                                          indexs == mainIndex
                                                        ) {
                                                          return {
                                                            ...items,
                                                            choice:
                                                              items.choice.map(
                                                                (vals, ins) => {
                                                                  if (
                                                                    ins == index
                                                                  ) {
                                                                    return text;
                                                                  } else {
                                                                    return vals;
                                                                  }
                                                                }
                                                              ),
                                                          };
                                                        } else {
                                                          return items;
                                                        }
                                                      }
                                                    ),
                                                  }));
                                                }}
                                                style={[
                                                  styles.inputDetailChoiceModal,
                                                  {
                                                    fontSize:
                                                      size.width < 500
                                                        ? 12
                                                        : 16,
                                                    paddingRight:
                                                      size.width < 500 && "50%",
                                                  },
                                                ]}
                                              />
                                              <View
                                                style={
                                                  styles.selectAnsChoiceModal
                                                }
                                              >
                                                <TouchableOpacity
                                                  key={item}
                                                  onPress={() => {
                                                    setQuiz((val) => ({
                                                      ...val,
                                                      question:
                                                        val.question.map(
                                                          (items, indexs) => {
                                                            if (
                                                              indexs ==
                                                              mainIndex
                                                            ) {
                                                              return {
                                                                ...items,
                                                                answer:
                                                                  items.answer !=
                                                                  null
                                                                    ? items.answer.filter(
                                                                        (e) =>
                                                                          e ==
                                                                          index
                                                                      ).length >
                                                                      0
                                                                      ? items.answer.filter(
                                                                          (e) =>
                                                                            e !=
                                                                            index
                                                                        )
                                                                      : items.answer.concat(
                                                                          index
                                                                        )
                                                                    : [index],
                                                              };
                                                            } else {
                                                              return items;
                                                            }
                                                          }
                                                        ),
                                                    }));
                                                  }}
                                                  style={{
                                                    flexDirection: "row",
                                                    alignItems: "center",
                                                    marginRight: 20,
                                                  }}
                                                >
                                                  {mainItem.answer != null && (
                                                    <MaterialIcons
                                                      name={
                                                        mainItem.answer.filter(
                                                          (e) => e == index
                                                        ).length > 0
                                                          ? "radio-button-checked"
                                                          : "radio-button-unchecked"
                                                      }
                                                      size={24}
                                                      color="#0D88F0"
                                                    />
                                                  )}
                                                  {mainItem.answer == null && (
                                                    <MaterialIcons
                                                      name={
                                                        "radio-button-unchecked"
                                                      }
                                                      size={24}
                                                      color="#0D88F0"
                                                    />
                                                  )}
                                                  <Text
                                                    style={[
                                                      styles.text16,
                                                      {
                                                        marginLeft: 10,
                                                        fontSize:
                                                          size.width < 500
                                                            ? 12
                                                            : 16,
                                                      },
                                                    ]}
                                                  >
                                                    คำตอบถูก
                                                  </Text>
                                                </TouchableOpacity>
                                                <TouchableOpacity
                                                  onPress={() => {
                                                    setQuiz((val) => ({
                                                      ...val,
                                                      question:
                                                        val.question.map(
                                                          (items, indexs) => {
                                                            if (
                                                              indexs ==
                                                              mainIndex
                                                            ) {
                                                              return {
                                                                ...items,
                                                                choice:
                                                                  items.choice.filter(
                                                                    (
                                                                      itemss,
                                                                      indexss
                                                                    ) => {
                                                                      return (
                                                                        indexss !=
                                                                        index
                                                                      );
                                                                    }
                                                                  ),
                                                              };
                                                            } else {
                                                              return items;
                                                            }
                                                          }
                                                        ),
                                                    }));
                                                  }}
                                                >
                                                  <MaterialIcons
                                                    name="cancel"
                                                    size={24}
                                                    color="#484848"
                                                  />
                                                </TouchableOpacity>
                                              </View>
                                            </View>
                                          );
                                        }}
                                      />
                                    </View>
                                    <TouchableOpacity
                                      onPress={() => {
                                        setQuiz((val) => ({
                                          ...val,
                                          question: val.question.map(
                                            (items, indexs) => {
                                              if (indexs == mainIndex) {
                                                return {
                                                  ...items,
                                                  choice:
                                                    items.choice.concat(""),
                                                };
                                              } else {
                                                return items;
                                              }
                                            }
                                          ),
                                        }));
                                      }}
                                      style={styles.buttonAddAnsModal}
                                    >
                                      <MaterialIcons
                                        name="add-circle"
                                        size={24}
                                        color="#0B2051"
                                      />
                                      <Text
                                        style={[
                                          styles.text16,
                                          {
                                            marginLeft: 10,
                                            fontSize:
                                              size.width < 500 ? 12 : 16,
                                          },
                                        ]}
                                      >
                                        เพิ่มคำตอบ
                                      </Text>
                                    </TouchableOpacity>
                                  </View>
                                )}
                                {item.type == "3" && (
                                  <View>
                                    <FlatList
                                      data={DATA2}
                                      numColumns={1}
                                      scrollEnabled={false}
                                      renderItem={({ index, item }) => {
                                        return (
                                          <View
                                            style={{
                                              width: "100%",
                                              height: 40,
                                              borderWidth: 1,
                                              borderColor: "#0D88F0",
                                              paddingHorizontal: 20,
                                              backgroundColor: "#FFFFFF",
                                              justifyContent: "space-between",
                                              flexDirection: "row",
                                              alignItems: "center",
                                              marginTop: 10,
                                              overflow: "hidden",
                                            }}
                                          >
                                            <Text
                                              style={[
                                                styles.text16,
                                                {
                                                  fontSize:
                                                    size.width < 500 ? 12 : 16,
                                                },
                                              ]}
                                            >
                                              {item.name}
                                            </Text>

                                            <TouchableOpacity
                                              key={item}
                                              onPress={() => {
                                                setQuiz((val) => ({
                                                  ...val,
                                                  question: val.question.map(
                                                    (items, indexs) => {
                                                      if (indexs == mainIndex) {
                                                        return {
                                                          ...items,
                                                          answer: index,
                                                        };
                                                      } else {
                                                        return items;
                                                      }
                                                    }
                                                  ),
                                                }));
                                              }}
                                              style={[
                                                {
                                                  position: "relative",
                                                  flexDirection: "row",
                                                },
                                              ]}
                                            >
                                              <MaterialIcons
                                                name={
                                                  index == mainItem.answer
                                                    ? "radio-button-checked"
                                                    : "radio-button-unchecked"
                                                }
                                                size={
                                                  size.width < 500 ? 20 : 24
                                                }
                                                color="#0D88F0"
                                              />
                                              <Text
                                                style={[
                                                  styles.text16,
                                                  {
                                                    marginLeft: 10,
                                                    fontSize:
                                                      size.width < 500
                                                        ? 12
                                                        : 16,
                                                  },
                                                ]}
                                              >
                                                คำตอบถูก
                                              </Text>
                                            </TouchableOpacity>
                                          </View>
                                        );
                                      }}
                                    />
                                  </View>
                                )}
                                {item.type == "4" && (
                                  <View>
                                    <View
                                      style={{
                                        overflow: "hidden",
                                        height: item.choice.length * 65,
                                      }}
                                    >
                                      <FlatList
                                        data={item.choice}
                                        numColumns={1}
                                        scrollEnabled={false}
                                        renderItem={({ index, item }) => {
                                          return (
                                            <View
                                              style={{
                                                flexDirection: "row",
                                                justifyContent: "space-between",
                                                marginTop: 10,
                                                overflow: "hidden",
                                                height: 55,
                                              }}
                                            >
                                              <TextInput
                                                autoComplete="off"
                                                defaultValue={item.question}
                                                onChangeText={(text) => {
                                                  setQuiz((val) => ({
                                                    ...val,
                                                    question: val.question.map(
                                                      (items, indexs) => {
                                                        if (
                                                          indexs == mainIndex
                                                        ) {
                                                          return {
                                                            ...items,
                                                            choice:
                                                              items.choice.map(
                                                                (vals, ins) => {
                                                                  if (
                                                                    ins == index
                                                                  ) {
                                                                    return {
                                                                      ...vals,
                                                                      question:
                                                                        text,
                                                                    };
                                                                  } else {
                                                                    return vals;
                                                                  }
                                                                }
                                                              ),
                                                          };
                                                        } else {
                                                          return items;
                                                        }
                                                      }
                                                    ),
                                                  }));
                                                }}
                                                style={[
                                                  styles.text16,
                                                  {
                                                    width:
                                                      size.width < 500
                                                        ? "45%"
                                                        : "40%",
                                                    fontSize:
                                                      size.width < 500
                                                        ? 12
                                                        : 16,
                                                    height: 40,
                                                    borderWidth: 1,
                                                    borderColor: "#0D88F0",
                                                    paddingHorizontal: 15,
                                                    backgroundColor: "#FFFFFF",
                                                  },
                                                ]}
                                              />
                                              <View
                                                style={{
                                                  flexDirection: "row",
                                                  alignItems: "center",
                                                  marginTop: -10,
                                                  width:
                                                    size.width < 500
                                                      ? "45%"
                                                      : "40%",
                                                }}
                                              >
                                                <TextInput
                                                  autoComplete="off"
                                                  defaultValue={item.answer}
                                                  onChangeText={(text) => {
                                                    setQuiz((val) => ({
                                                      ...val,
                                                      question:
                                                        val.question.map(
                                                          (items, indexs) => {
                                                            if (
                                                              indexs ==
                                                              mainIndex
                                                            ) {
                                                              return {
                                                                ...items,
                                                                choice:
                                                                  items.choice.map(
                                                                    (
                                                                      vals,
                                                                      ins
                                                                    ) => {
                                                                      if (
                                                                        ins ==
                                                                        index
                                                                      ) {
                                                                        return {
                                                                          ...vals,
                                                                          answer:
                                                                            text,
                                                                        };
                                                                      } else {
                                                                        return vals;
                                                                      }
                                                                    }
                                                                  ),
                                                              };
                                                            } else {
                                                              return items;
                                                            }
                                                          }
                                                        ),
                                                    }));
                                                  }}
                                                  style={[
                                                    styles.text16,
                                                    {
                                                      width:
                                                        size.width < 500
                                                          ? "80%"
                                                          : "80%",
                                                      fontSize:
                                                        size.width < 500
                                                          ? 12
                                                          : 16,
                                                      height: 40,
                                                      borderWidth: 1,
                                                      borderColor: "#0D88F0",
                                                      paddingHorizontal: 15,
                                                      backgroundColor:
                                                        "#FFFFFF",
                                                    },
                                                  ]}
                                                />
                                              </View>
                                              <TouchableOpacity
                                                style={{
                                                  position: "absolute",
                                                  right: 0,
                                                  alignSelf: "center",
                                                  marginTop: -10,
                                                }}
                                                onPress={() => {
                                                  setQuiz((val) => ({
                                                    ...val,
                                                    question: val.question.map(
                                                      (items, indexs) => {
                                                        if (
                                                          indexs == mainIndex
                                                        ) {
                                                          return {
                                                            ...items,
                                                            choice:
                                                              items.choice.filter(
                                                                (
                                                                  itemss,
                                                                  indexss
                                                                ) => {
                                                                  return (
                                                                    indexss !=
                                                                    index
                                                                  );
                                                                }
                                                              ),
                                                          };
                                                        } else {
                                                          return items;
                                                        }
                                                      }
                                                    ),
                                                  }));
                                                }}
                                              >
                                                <MaterialIcons
                                                  name="cancel"
                                                  size={24}
                                                  color="#484848"
                                                />
                                              </TouchableOpacity>
                                            </View>
                                          );
                                        }}
                                      />
                                    </View>
                                    <TouchableOpacity
                                      onPress={() => {
                                        setQuiz((val) => ({
                                          ...val,
                                          question: val.question.map(
                                            (items, indexs) => {
                                              if (indexs == mainIndex) {
                                                return {
                                                  ...items,
                                                  choice: items.choice.concat({
                                                    question: "",
                                                    answer: "",
                                                  }),
                                                };
                                              } else {
                                                return items;
                                              }
                                            }
                                          ),
                                        }));
                                      }}
                                      style={styles.buttonAddAnsModal}
                                    >
                                      <MaterialIcons
                                        name="add-circle"
                                        size={24}
                                        color="#0B2051"
                                      />
                                      <Text
                                        style={[
                                          styles.text16,
                                          {
                                            marginLeft: 10,
                                            fontSize:
                                              size.width < 500 ? 12 : 16,
                                          },
                                        ]}
                                      >
                                        เพิ่มคำตอบ
                                      </Text>
                                    </TouchableOpacity>
                                    {/* <View style={styles.viewQuestionModal}>
                                  <Text style={[styles.text20]}>คำถาม</Text>
                                </View> */}
                                  </View>
                                )}
                                {item.type == "5" && (
                                  <View
                                    style={{
                                      marginTop: 16,
                                      width: "100%",
                                      overflow: "hidden",
                                      maxHeight: 550,
                                    }}
                                  >
                                    <View
                                      style={{
                                        flexDirection:
                                          size.width < 500 ? "column" : "row",
                                        justifyContent: "space-between",
                                        width: "100%",
                                        overflow: "hidden",
                                      }}
                                    >
                                      <TextInput
                                        autoComplete="off"
                                        defaultValue={item.answer}
                                        onChangeText={(text) => {
                                          setQuiz((val) => ({
                                            ...val,
                                            question: val.question.map(
                                              (items, indexs) => {
                                                if (indexs == mainIndex) {
                                                  return {
                                                    ...items,
                                                    answer: text,
                                                  };
                                                } else {
                                                  return items;
                                                }
                                              }
                                            ),
                                          }));
                                        }}
                                        multiline
                                        textAlignVertical="top"
                                        style={{
                                          width:
                                            size.width < 500 ? "100%" : "45%",
                                          minHeight: 130,
                                          borderWidth: 1,
                                          borderColor: "#0D88F0",
                                          padding: 20,
                                          backgroundColor: "#FFFFFF",
                                          fontFamily: "Prompt",
                                          fontSize: 16,
                                          color: "#0B2051",
                                        }}
                                      />

                                      <View
                                        style={{
                                          width:
                                            size.width < 500 ? "100" : "45%",
                                          overflow: "hidden",
                                        }}
                                      >
                                        <View
                                          style={{
                                            width:
                                              size.width < 500 ? "100%" : "80%",
                                            overflow: "hidden",
                                            height: 50 * item.choice.length,
                                          }}
                                        >
                                          <FlatList
                                            numColumns={1}
                                            data={item.choice}
                                            renderItem={({ item, index }) => {
                                              return (
                                                <View
                                                  style={{
                                                    flexDirection: "row",
                                                    alignItems: "center",
                                                    overflow: "hidden",
                                                    marginLeft:
                                                      size.width < 500
                                                        ? 0
                                                        : -20,
                                                    marginTop:
                                                      size.width < 500
                                                        ? 10
                                                        : index != 0
                                                        ? 10
                                                        : 0,
                                                    height: 40,
                                                  }}
                                                >
                                                  <Text
                                                    style={{
                                                      fontFamily: "Prompt",
                                                      fontSize: 16,
                                                      color: "#0B2051",
                                                    }}
                                                  >
                                                    {index + 1}
                                                    {"."}
                                                  </Text>
                                                  <TextInput
                                                    autoComplete="off"
                                                    value={item}
                                                    onChangeText={(text) => {
                                                      setQuiz((val) => ({
                                                        ...val,
                                                        question:
                                                          val.question.map(
                                                            (items, indexs) => {
                                                              if (
                                                                indexs ==
                                                                mainIndex
                                                              ) {
                                                                return {
                                                                  ...items,
                                                                  choice:
                                                                    items.choice.map(
                                                                      (
                                                                        vals,
                                                                        ins
                                                                      ) => {
                                                                        if (
                                                                          ins ==
                                                                          index
                                                                        ) {
                                                                          return text;
                                                                        } else {
                                                                          return vals;
                                                                        }
                                                                      }
                                                                    ),
                                                                };
                                                              } else {
                                                                return items;
                                                              }
                                                            }
                                                          ),
                                                      }));
                                                    }}
                                                    style={{
                                                      width: "100%",
                                                      height: 40,
                                                      borderWidth: 1,
                                                      borderColor: "#0D88F0",
                                                      backgroundColor:
                                                        "#FFFFFF",
                                                      paddingHorizontal: 10,
                                                      fontFamily: "Prompt",
                                                      fontSize: 16,
                                                      color: "#0B2051",
                                                      marginLeft: 10,
                                                    }}
                                                  />
                                                  <TouchableOpacity
                                                    style={{
                                                      position: "absolute",
                                                      right: 0,
                                                      alignSelf: "center",
                                                    }}
                                                    onPress={() => {
                                                      setQuiz((val) => ({
                                                        ...val,
                                                        question:
                                                          val.question.map(
                                                            (items, indexs) => {
                                                              if (
                                                                indexs ==
                                                                mainIndex
                                                              ) {
                                                                return {
                                                                  ...items,
                                                                  choice:
                                                                    items.choice.filter(
                                                                      (
                                                                        itemss,
                                                                        indexss
                                                                      ) => {
                                                                        return (
                                                                          indexss !=
                                                                          index
                                                                        );
                                                                      }
                                                                    ),
                                                                };
                                                              } else {
                                                                return items;
                                                              }
                                                            }
                                                          ),
                                                      }));
                                                    }}
                                                  >
                                                    <MaterialIcons
                                                      name="cancel"
                                                      size={24}
                                                      color="#484848"
                                                    />
                                                  </TouchableOpacity>
                                                </View>
                                              );
                                            }}
                                          />
                                        </View>
                                        <TouchableOpacity
                                          onPress={() => {
                                            setQuiz((val) => ({
                                              ...val,
                                              question: val.question.map(
                                                (items, indexs) => {
                                                  if (indexs == mainIndex) {
                                                    return {
                                                      ...items,
                                                      choice:
                                                        items.choice.concat(""),
                                                    };
                                                  } else {
                                                    return items;
                                                  }
                                                }
                                              ),
                                            }));
                                          }}
                                          style={styles.buttonAddAnsModal}
                                        >
                                          <MaterialIcons
                                            name="add-circle"
                                            size={24}
                                            color="#0B2051"
                                          />
                                          <Text
                                            style={[
                                              styles.text16,
                                              {
                                                marginLeft: 10,
                                                fontSize:
                                                  size.width < 500 ? 12 : 16,
                                              },
                                            ]}
                                          >
                                            เพิ่มคำตอบ
                                          </Text>
                                        </TouchableOpacity>
                                      </View>
                                    </View>
                                    <View
                                      style={{
                                        flexDirection: "row",
                                        alignItems: "center",
                                        marginTop: 10,
                                        width: "90%",
                                        alignSelf: "center",
                                      }}
                                    >
                                      <MaterialIcons
                                        name="info-outline"
                                        size={20}
                                        color="#0B2051"
                                      />
                                      <Text
                                        style={[
                                          styles.text16,
                                          { fontSize: 14, marginLeft: 20 },
                                        ]}
                                      >
                                        Example: Deborah was angry at her son.
                                        Her son didn't __(1)__ to her. Her son
                                        was 16 years old. Her son __(2)__ he
                                        knew everything. Her son __(3)__ at
                                        Deborah.
                                      </Text>
                                    </View>
                                    {/* <View style={styles.viewQuestionModal}>
                                  <Text style={[styles.text20]}>คำถาม</Text>
                                </View> */}
                                    {/* <View style={styles.selectQuestionModal}>
                                  <RNPickerSelect
                                    placeholder={placeholder2}
                                    onValueChange={(val) => {
                                      setSelectQuestion(val);
                                    }}
                                    items={question}
                                    style={{
                                      inputWeb: {
                                        width: 170,
                                        height: 40,
                                        color: "#0B2051",
                                        fontFamily: "Prompt",
                                        fontSize: 20,
                                        borderWidth: 0,
                                        backgroundColor: "#FFFFFF",
                                        marginVertical: 40,
                                      },
                                    }}
                                  />
                                  <TextInput   autoComplete="off"
                                    style={styles.inputQuestionModal}
                                  />
                                  <TouchableOpacity
                                    style={styles.buttonAddQuestionModal}
                                  >
                                    <Text
                                      style={[
                                        styles.text20,
                                        { color: "#FFFFFF" },
                                      ]}
                                    >
                                      เพิ่ม
                                    </Text>
                                  </TouchableOpacity>
                                </View> */}
                                  </View>
                                )}
                                {mainIndex == quiz.question.length - 1 && (
                                  <View
                                    style={{
                                      width:
                                        size.width < 1280 ? "120%" : "100%",
                                      alignSelf: "center",
                                      backgroundColor: "#EBEBEB",
                                      paddingVertical: 20,
                                      paddingHorizontal:
                                        size.width < 500 ? 10 : 45,
                                      marginTop: 40,
                                      marginBottom: 150,
                                      height: 400,
                                    }}
                                  >
                                    <View style={{ marginTop: 16 }}>
                                      <View
                                        style={[
                                          size.width < 500
                                            ? stylesResponsive.viewQuestionModal
                                            : styles.viewQuestionModal,
                                        ]}
                                      >
                                        <Text
                                          style={[
                                            size.width < 500
                                              ? stylesResponsive.text20
                                              : styles.text20,
                                          ]}
                                        >
                                          คำถาม
                                        </Text>
                                      </View>
                                      <View
                                        style={[
                                          size.width < 500
                                            ? stylesResponsive.selectQuestionModal
                                            : styles.selectQuestionModal,
                                        ]}
                                      >
                                        <RNPickerSelect
                                          placeholder={placeholder2}
                                          onValueChange={(val) => {
                                            setSelectQuestion(val);
                                          }}
                                          value={selectQuestion}
                                          items={question}
                                          style={{
                                            inputWeb: {
                                              width:
                                                size.width < 500 ? 100 : 170,
                                              height: 40,
                                              color: "#0B2051",
                                              fontFamily: "Prompt",
                                              fontSize:
                                                size.width < 500 ? 14 : 20,
                                              borderWidth: 0,
                                              backgroundColor: "#FFFFFF",
                                              marginVertical: 40,
                                            },
                                          }}
                                        />
                                        <TextInput
                                          autoComplete="off"
                                          value={title}
                                          onChangeText={setTitle}
                                          style={[
                                            size.width < 500
                                              ? stylesResponsive.inputQuestionModal
                                              : styles.inputQuestionModal,
                                          ]}
                                        />
                                        <TouchableOpacity
                                          onPress={() => {
                                            setQuiz((val) => ({
                                              ...val,
                                              question: val.question.concat({
                                                title,
                                                type: selectQuestion,
                                                answer:
                                                  selectQuestion == "3"
                                                    ? 0
                                                    : null,
                                                image: "",
                                                choice: [],
                                              }),
                                            }));
                                            setTitle("");
                                          }}
                                          style={[
                                            size.width < 500
                                              ? stylesResponsive.buttonAddQuestionModal
                                              : styles.buttonAddQuestionModal,
                                          ]}
                                        >
                                          <Text
                                            style={[
                                              size.width < 500
                                                ? stylesResponsive.text20
                                                : styles.text20,
                                              ,
                                              { color: "#FFFFFF" },
                                            ]}
                                          >
                                            เพิ่ม
                                          </Text>
                                        </TouchableOpacity>
                                      </View>
                                    </View>
                                    <View
                                      style={[
                                        styles.viewRecordModal,
                                        { marginTop: 60 },
                                      ]}
                                    >
                                      <TouchableOpacity
                                        onPress={async () => {
                                          if (
                                            // quiz.img.image != "" &&
                                            quiz.detail != "" &&
                                            quiz.question.length > 0
                                          ) {
                                            const responsne = await apiservice({
                                              path:
                                                quiz.id != undefined
                                                  ? "/start-course/updatefinal/" +
                                                    quiz.id
                                                  : "/quiz/create",
                                              method:
                                                quiz.id != undefined
                                                  ? "put"
                                                  : "Post",
                                              body: {
                                                ...quiz,
                                                question: quiz.question.map(
                                                  (items) => {
                                                    if (items.type == "4") {
                                                      return {
                                                        ...items,
                                                        answer: items.choice,
                                                      };
                                                    } else {
                                                      return items;
                                                    }
                                                  }
                                                ),
                                              },
                                              token: token?.accessToken,
                                            });

                                            if (responsne.status == 200) {
                                              setbody((val) => ({
                                                ...val,
                                                studymodel: val.studymodel.map(
                                                  (items, indexs) =>
                                                    select == items.number
                                                      ? {
                                                          ...items,
                                                          listen_detail:
                                                            items.listen_detail.map(
                                                              (e, i) =>
                                                                text == i
                                                                  ? {
                                                                      ...e,
                                                                      education_id:
                                                                        quiz.id !=
                                                                        undefined
                                                                          ? quiz.id
                                                                          : responsne
                                                                              .data
                                                                              .id,
                                                                    }
                                                                  : e
                                                            ),
                                                        }
                                                      : items
                                                ),
                                              }));
                                              setTimeout(() => {
                                                defaultModelrecent(
                                                  quiz.img.image,
                                                  quiz.detail,
                                                  quiz.ans,
                                                  quiz.conditions.percent,
                                                  quiz.conditions.timer,
                                                  quiz.conditions.timerType,
                                                  quiz.question
                                                );
                                                setPageTest(0);
                                              }, 1000);

                                              setModalTest(!modalTest);
                                            }
                                          } else {
                                            setmodalmodalWarning(true);
                                          }
                                        }}
                                        style={[
                                          size.width < 500
                                            ? stylesResponsive.buttonRecordModal
                                            : styles.buttonRecordModal,
                                        ]}
                                      >
                                        <Text
                                          style={[
                                            size.width < 500
                                              ? stylesResponsive.text25W
                                              : styles.text25W,
                                          ]}
                                        >
                                          บันทึก
                                        </Text>
                                      </TouchableOpacity>
                                      <TouchableOpacity
                                        onPress={() => {
                                          defaultModelrecent(
                                            quiz.img.image,
                                            quiz.detail,
                                            quiz.ans,
                                            quiz.conditions.percent,
                                            quiz.conditions.timer,
                                            quiz.conditions.timerType,
                                            quiz.question
                                          );
                                          setModalTest(!modalTest);
                                        }}
                                        style={[
                                          size.width < 500
                                            ? stylesResponsive.buttonBackModal
                                            : styles.buttonBackModal,
                                        ]}
                                      >
                                        <Text
                                          style={[
                                            size.width < 500
                                              ? stylesResponsive.text25O
                                              : styles.text25O,
                                          ]}
                                        >
                                          ยกเลิก
                                        </Text>
                                      </TouchableOpacity>
                                    </View>
                                  </View>
                                )}
                              </View>
                            );
                          }}
                        />
                      )}
                    </View>
                    {pageTest == 2 && (
                      <View
                        style={{
                          width: "80%",
                          alignSelf: "center",
                          marginTop: 45,
                          marginBottom: size.width < 500 && 20,
                        }}
                      >
                        <Text
                          style={[
                            size.width < 500
                              ? stylesResponsive.text25
                              : styles.text25,
                          ]}
                        >
                          จับเวลา
                        </Text>
                        <View style={{ flexDirection: "row", marginTop: 10 }}>
                          <View
                            style={[
                              {
                                width: size.width < 500 ? 100 : 265,
                                height: 50,
                                borderWidth: 1,
                                borderColor: "#E0E0E0",
                              },
                            ]}
                          >
                            <NumberFormat
                              onValueChange={(value) => {
                                setQuiz((val) => ({
                                  ...val,
                                  conditions: {
                                    ...val.conditions,
                                    timer: value.floatValue,
                                  },
                                }));
                              }}
                              style={{
                                borderWidth: 0,
                                height: 50,
                                color: "#0B2051",
                                fontFamily: "Prompt",
                                fontSize: size.width < 500 ? 14 : 18,
                                paddingLeft: 15,
                              }}
                            />
                          </View>

                          <RNPickerSelect
                            placeholder={placeholder3}
                            onValueChange={(value) => {
                              setQuiz((val) => ({
                                ...val,
                                conditions: {
                                  ...val.conditions,
                                  timerType: value,
                                },
                              }));
                            }}
                            value={quiz.conditions.timerType}
                            items={time}
                            style={{
                              inputWeb: {
                                width: size.width < 500 ? 100 : 177,
                                height: 50,
                                color: "#0B2051",
                                fontFamily: "Prompt",
                                fontSize: size.width < 500 ? 14 : 18,
                                borderWidth: 1,
                                borderColor: "#E0E0E0",
                                backgroundColor: "#FFFFFF",
                                paddingHorizontal: 15,
                                marginLeft: 20,
                              },
                            }}
                          />
                        </View>
                        <Text style={styles.text12R}>
                          หากไม่กำหนดจะไม่จับเวลาในการทำข้อสอบ
                        </Text>
                        <Text
                          style={[
                            size.width < 500
                              ? stylesResponsive.text25
                              : styles.text25,
                            { marginVertical: 20 },
                          ]}
                        >
                          เปอร์เซ็นการผ่าน
                        </Text>
                        <RNPickerSelect
                          placeholder={placeholder1}
                          onValueChange={(value) => {
                            setQuiz((val) => ({
                              ...val,
                              conditions: { ...val.conditions, percent: value },
                            }));
                          }}
                          value={quiz.conditions.percent}
                          items={score}
                          style={{
                            inputWeb: {
                              width: size.width < 500 ? "100%" : 265,
                              height: 50,
                              color: "#0B2051",
                              fontFamily: "Prompt",
                              fontSize: size.width < 500 ? 14 : 18,
                              borderWidth: 1,
                              borderColor: "#E0E0E0",
                              backgroundColor: "#FFFFFF",
                              paddingHorizontal: 15,
                            },
                          }}
                        />
                        <View style={{ marginTop: 20 }}>
                          <TouchableOpacity
                            onPress={() => {
                              setQuiz((val) => ({
                                ...val,
                                conditions: {
                                  ...val.conditions,
                                  randomquestion:
                                    !quiz.conditions.randomquestion,
                                },
                              }));
                            }}
                            style={{
                              flexDirection: "row",
                              alignItems: "center",
                              marginTop: 18,
                            }}
                          >
                            <MaterialIcons
                              name={
                                quiz.conditions.randomquestion
                                  ? "check-box"
                                  : "check-box-outline-blank"
                              }
                              size={size.width < 500 ? 20 : 30}
                              color="#0D88F0"
                            />
                            <Text
                              style={[
                                size.width < 500
                                  ? stylesResponsive.text25
                                  : styles.text25,
                                { marginLeft: 24 },
                              ]}
                            >
                              สุ่มสลับข้อสอบ
                            </Text>
                          </TouchableOpacity>
                          {/* <TouchableOpacity
                            onPress={() => {
                              setQuiz((val) => ({
                                ...val,
                                conditions: {
                                  ...val.conditions,
                                  randomanswer: !quiz.conditions.randomanswer,
                                },
                              }));
                            }}
                            style={{
                              flexDirection: "row",
                              alignItems: "center",
                              marginTop: 18,
                            }}
                          >
                            <MaterialIcons
                              name={
                                quiz.conditions.randomanswer
                                  ? "check-box"
                                  : "check-box-outline-blank"
                              }
                              size={size.width < 500 ? 20 : 30}
                              color="#0D88F0"
                            />
                            <Text
                              style={[
                                size.width < 500
                                  ? stylesResponsive.text25
                                  : styles.text25,
                                { marginLeft: 24 },
                              ]}
                            >
                              สุ่มสลับคำตอบ
                            </Text>
                          </TouchableOpacity> */}
                          <TouchableOpacity
                            onPress={() => {
                              setQuiz((val) => ({
                                ...val,
                                conditions: {
                                  ...val.conditions,
                                  retest: !quiz.conditions.retest,
                                },
                              }));
                            }}
                            style={{
                              flexDirection: "row",
                              alignItems: "center",
                              marginTop: 18,
                            }}
                          >
                            <MaterialIcons
                              name={
                                quiz.conditions.retest
                                  ? "check-box"
                                  : "check-box-outline-blank"
                              }
                              size={size.width < 500 ? 20 : 30}
                              color="#0D88F0"
                            />
                            <Text
                              style={[
                                size.width < 500
                                  ? stylesResponsive.text25
                                  : styles.text25,
                                { marginLeft: 24 },
                              ]}
                            >
                              อนุญาตให้ทำข้อสอบใหม่ (กรณีไม่ผ่าน)
                            </Text>
                          </TouchableOpacity>
                          <TouchableOpacity
                            onPress={() => {
                              setQuiz((val) => ({
                                ...val,
                                conditions: {
                                  ...val.conditions,
                                  showanswer: !quiz.conditions.showanswer,
                                },
                              }));
                            }}
                            style={{
                              flexDirection: "row",
                              alignItems: "center",
                              marginTop: 18,
                            }}
                          >
                            <MaterialIcons
                              name={
                                quiz.conditions.showanswer
                                  ? "check-box"
                                  : "check-box-outline-blank"
                              }
                              size={size.width < 500 ? 20 : 30}
                              color="#0D88F0"
                            />
                            <Text
                              style={[
                                size.width < 500
                                  ? stylesResponsive.text25
                                  : styles.text25,
                                { marginLeft: 24 },
                              ]}
                            >
                              แสดงเฉลย
                            </Text>
                          </TouchableOpacity>
                        </View>
                      </View>
                    )}
                  </View>
                  {pageTest != 1 && (
                    <View style={styles.viewRecordModal}>
                      <TouchableOpacity
                        onPress={async () => {
                          if (
                            // quiz.img.image != "" &&
                            quiz.detail != "" &&
                            quiz.question.length > 0
                          ) {
                            const responsne = await apiservice({
                              path:
                                quiz.id != undefined
                                  ? "/start-course/updatefinal/" + quiz.id
                                  : "/quiz/create",
                              method: quiz.id != undefined ? "put" : "Post",
                              body: {
                                ...quiz,
                                question: quiz.question.map((items) => {
                                  if (items.type == "4") {
                                    return { ...items, answer: items.choice };
                                  } else {
                                    return items;
                                  }
                                }),
                              },
                              token: token?.accessToken,
                            });

                            if (responsne.status == 200) {
                              setbody((val) => ({
                                ...val,
                                studymodel: val.studymodel.map(
                                  (items, indexs) =>
                                    select == items.number
                                      ? {
                                          ...items,
                                          listen_detail:
                                            items.listen_detail.map((e, i) =>
                                              text == i
                                                ? {
                                                    ...e,
                                                    education_id:
                                                      quiz.id != undefined
                                                        ? quiz.id
                                                        : responsne.data.id,
                                                  }
                                                : e
                                            ),
                                        }
                                      : items
                                ),
                              }));
                              setTimeout(() => {
                                defaultModelrecent(
                                  quiz.img.image,
                                  quiz.detail,
                                  quiz.ans,
                                  quiz.conditions.percent,
                                  quiz.conditions.timer,
                                  quiz.conditions.timerType,
                                  quiz.question
                                );
                                setPageTest(0);
                              }, 1000);

                              setModalTest(!modalTest);
                            }
                          } else {
                            setmodalmodalWarning(true);
                          }
                        }}
                        style={[
                          size.width < 500
                            ? stylesResponsive.buttonRecordModal
                            : styles.buttonRecordModal,
                        ]}
                      >
                        <Text
                          style={[
                            size.width < 500
                              ? stylesResponsive.text25W
                              : styles.text25W,
                          ]}
                        >
                          บันทึก
                        </Text>
                      </TouchableOpacity>
                      <TouchableOpacity
                        onPress={() => {
                          defaultModelrecent(
                            quiz.img.image,
                            quiz.detail,
                            quiz.ans,
                            quiz.conditions.percent,
                            quiz.conditions.timer,
                            quiz.conditions.timerType,
                            quiz.question
                          );
                          setModalTest(!modalTest);
                        }}
                        style={[
                          size.width < 500
                            ? stylesResponsive.buttonBackModal
                            : styles.buttonBackModal,
                        ]}
                      >
                        <Text
                          style={[
                            size.width < 500
                              ? stylesResponsive.text25O
                              : styles.text25O,
                          ]}
                        >
                          ยกเลิก
                        </Text>
                      </TouchableOpacity>
                    </View>
                  )}
                </View>
              </View>
            </ScrollView>
          </View>
        </Modal>
        <Modal
          transparent={true}
          visible={modalTest1}
          onRequestClose={() => {
            Alert.alert("Modal has been closed.");
            setModalTest1(!modalTest1);
          }}
        >
          <View style={styles.bgModal}>
            <ScrollView>
              <View
                style={[
                  size.width < 500
                    ? stylesResponsive.bgDetailModal
                    : styles.bgDetailModal,
                ]}
              >
                <View style={{ height: "100%" }}>
                  <View style={{ height: "90%" }}>
                    <Text
                      style={[
                        size.width < 500
                          ? stylesResponsive.text30
                          : styles.text30,
                      ]}
                    >
                      ข้อสอบอัตนัย
                    </Text>
                    <View style={{ flexDirection: "row", marginTop: 27 }}>
                      <TouchableOpacity
                        onPress={() => {
                          setPageWork(0);
                        }}
                        style={[
                          size.width < 1280
                            ? stylesResponsive.fristTabModal
                            : styles.fristTabModal,
                          {
                            backgroundColor:
                              pageWork == 0 ? "#FFFFFF" : "#0D88F0",
                            borderWidth: pageWork == 0 && 1,
                            borderColor: pageWork == 0 && "#0D88F0",
                          },
                        ]}
                      >
                        <Text
                          style={{
                            fontSize: size.width < 500 ? 14 : 25,
                            fontFamily: "Prompt",
                            color: pageWork == 0 ? "#0D88F0" : "#FFFFFF",
                          }}
                        >
                          รายละเอียด
                        </Text>
                      </TouchableOpacity>
                      <TouchableOpacity
                        onPress={() => {
                          setPageWork(1);
                        }}
                        style={[
                          size.width < 1280
                            ? stylesResponsive.lastTabModal
                            : styles.lastTabModal,
                          {
                            backgroundColor: pageWork != 1 && "#0D88F0",
                          },
                        ]}
                      >
                        <Text
                          style={{
                            fontSize: size.width < 500 ? 14 : 25,
                            fontFamily: "Prompt",
                            color: pageWork == 0 ? "#FFFFFF" : "#0D88F0",
                          }}
                        >
                          ตั้งค่า
                        </Text>
                      </TouchableOpacity>
                    </View>
                    {pageWork == 0 && (
                      <View
                        style={{
                          marginVertical: 30,
                          width: "100%",
                          alignItems: "center",
                        }}
                      >
                        <TouchableOpacity
                          onPress={imageQuiz}
                          style={[
                            size.width < 500
                              ? stylesResponsive.uploadImgModal
                              : styles.uploadImgModal,
                          ]}
                        >
                          {quiz.img.image.length > 0 ? (
                            <Image
                              resizeMode="stretch"
                              style={[
                                size.width < 500
                                  ? stylesResponsive.uploadImgModal
                                  : styles.uploadImgModal,
                                { width: "100%" },
                              ]}
                              source={{
                                uri:
                                  "https://api.courseoncloud.com/api/image/getimage/" +
                                  quiz.img.image.replace(".png", ""),
                              }}
                            />
                          ) : (
                            <Text
                              style={[
                                size.width < 500
                                  ? stylesResponsive.text30Oo
                                  : styles.text30Oo,
                              ]}
                            >
                              อัพโหลดภาพปก ..
                            </Text>
                          )}
                        </TouchableOpacity>
                        <Text
                          style={{
                            width: "75%",
                            alignSelf: "center",
                            fontFamily: "Prompt",
                            color: "#CCC",
                          }}
                        >
                          1280x720
                        </Text>
                        <View
                          style={[
                            styles.viewCkEditor,
                            { width: "75%", marginVertical: 25 },
                          ]}
                        >
                          <CKEditor
                            editor={ClassicEditor}
                            data={quiz.detail}
                            config={ck}
                            onReady={(editor) => {
                              // You can store the "editor" and use when it is needed.
                            }}
                            onChange={async (event, editor) => {
                              const data = await editor.getData();

                              // setDesCom(data);
                              setQuiz((val) => ({ ...val, detail: data }));
                            }}
                            onBlur={(event, editor) => {}}
                            onFocus={(event, editor) => {}}
                          />
                        </View>
                      </View>
                    )}
                    {pageWork == 1 && (
                      <View
                        style={{
                          width: "80%",
                          alignSelf: "center",
                          marginVertical: 45,
                        }}
                      >
                        <Text
                          style={[
                            size.width < 500
                              ? stylesResponsive.text25
                              : styles.text25,
                          ]}
                        >
                          จับเวลา
                        </Text>
                        <View style={{ flexDirection: "row", marginTop: 10 }}>
                          <View
                            // defaultValue={quiz.conditions.timer}
                            // onChangeText={(text) => {
                            //   setQuiz((val) => ({
                            //     ...val,
                            //     conditions: {
                            //       ...val.conditions,
                            //       timer: text,
                            //     },
                            //   }));
                            // }}
                            style={[
                              {
                                width: size.width < 500 ? 100 : 265,
                                height: 50,
                                borderWidth: 1,
                                borderColor: "#E0E0E0",
                              },
                            ]}
                          >
                            <NumberFormat
                              value={quiz.conditions.timer}
                              onValueChange={(value) => {
                                setQuiz((val) => ({
                                  ...val,
                                  conditions: {
                                    ...val.conditions,
                                    timer: value.floatValue,
                                  },
                                }));
                              }}
                              style={{
                                borderWidth: 0,
                                height: 50,
                                color: "#0B2051",
                                fontFamily: "Prompt",
                                fontSize: size.width < 500 ? 14 : 18,
                                paddingLeft: 15,
                              }}
                            />
                          </View>
                          <RNPickerSelect
                            placeholder={placeholder3}
                            onValueChange={(value) => {
                              setQuiz((val) => ({
                                ...val,
                                conditions: {
                                  ...val.conditions,
                                  timerType: value,
                                },
                              }));
                            }}
                            value={quiz.conditions.timerType}
                            items={time}
                            style={{
                              inputWeb: {
                                width: size.width < 500 ? 100 : 177,
                                height: 50,
                                color: "#0B2051",
                                fontFamily: "Prompt",
                                fontSize: size.width < 500 ? 14 : 18,
                                borderWidth: 1,
                                borderColor: "#E0E0E0",
                                backgroundColor: "#FFFFFF",
                                paddingHorizontal: 15,
                                marginLeft: 20,
                              },
                            }}
                          />
                        </View>
                        {/* <Text
                          style={[
                            size.width < 500
                              ? stylesResponsive.text25
                              : styles.text25,
                            { marginTop: 25 },
                          ]}
                        >
                          กำหนดคะแนนเต็ม
                        </Text> */}
                        {/* <View
                          style={[
                            {
                              width: size.width < 500 ? "100%" : 470,
                              height: 50,
                              borderWidth: 1,
                              borderColor: "#E0E0E0",
                            },
                          ]}
                        >
                          <NumberFormat
                            value={quiz.conditions.percent}
                            onValueChange={(value) => {
                              setQuiz((val) => ({
                                ...val,
                                conditions: {
                                  ...val.conditions,
                                  percent: value.floatValue,
                                },
                              }));
                            }}
                            style={{
                              borderWidth: 0,
                              height: 50,
                              color: "#0B2051",
                              fontFamily: "Prompt",
                              fontSize: size.width < 500 ? 14 : 18,
                              paddingLeft: 15,
                            }}
                          />
                        </View> */}
                      </View>
                    )}
                  </View>
                  <View style={styles.viewRecordModal}>
                    <TouchableOpacity
                      onPress={async () => {
                        if (
                          // quiz.img.image != ""&&
                          quiz.conditions.timer > 0 &&
                          quiz.detail != ""
                        ) {
                          const responsne = await apiservice({
                            path: "/quiz/create",
                            method: "Post",
                            body: quiz,
                            token: token?.accessToken,
                          });
                          if (responsne.status == 200) {
                            setbody((val) => ({
                              ...val,
                              studymodel: val.studymodel.map((items, indexs) =>
                                select == items.number
                                  ? {
                                      ...items,
                                      listen_detail: items.listen_detail.map(
                                        (e, i) =>
                                          text == i
                                            ? {
                                                ...e,
                                                education_id: responsne.data.id,
                                              }
                                            : e
                                      ),
                                    }
                                  : items
                              ),
                            }));
                            setTimeout(() => {
                              defaultModelrecent(
                                quiz.img.image,
                                quiz.detail,
                                quiz.conditions.timer,
                                quiz.conditions.timerType,
                                quiz.conditions.percent
                              );
                              setPageWork(0);
                            }, 1000);
                            setModalTest1(!modalTest1);
                          }
                        } else if (quiz.conditions.timer <= 0) {
                          setmodalmodalWarningTime(true);
                        } else {
                          setmodalmodalWarning(true);
                        }
                      }}
                      style={[
                        size.width < 500
                          ? stylesResponsive.buttonRecordModal
                          : styles.buttonRecordModal,
                      ]}
                    >
                      <Text
                        style={[
                          size.width < 500
                            ? stylesResponsive.text25W
                            : styles.text25W,
                        ]}
                      >
                        บันทึก
                      </Text>
                    </TouchableOpacity>
                    <TouchableOpacity
                      onPress={() => {
                        defaultModelrecent(
                          quiz.img.image,
                          quiz.detail,
                          quiz.conditions.timer,
                          quiz.conditions.timerType,
                          quiz.conditions.percent
                        );
                        setModalTest1(!modalTest1);
                      }}
                      style={[
                        size.width < 500
                          ? stylesResponsive.buttonBackModal
                          : styles.buttonBackModal,
                      ]}
                    >
                      <Text
                        style={[
                          size.width < 500
                            ? stylesResponsive.text25O
                            : styles.text25O,
                        ]}
                      >
                        ยกเลิก
                      </Text>
                    </TouchableOpacity>
                  </View>
                </View>
              </View>
            </ScrollView>
          </View>
        </Modal>
        <Modal
          transparent={true}
          visible={modalSucceed}
          onRequestClose={() => {
            Alert.alert("Modal has been closed.");
            setModalSucceed(!modalSucceed);
          }}
        >
          <View style={styles.bgModal}>
            <View
              style={{
                width: size.width < 1280 ? "80%" : "45%",
                height: 270,
                borderWidth: 1,
                borderColor: "#0D88F0",
                backgroundColor: "#FFFFFF",
                alignSelf: "center",
                alignItems: "center",
              }}
            >
              <TouchableOpacity
                onPress={() => {
                  // setModalSucceed(!modalSucceed);
                  window.location.replace("Profile");
                }}
                style={{
                  position: "absolute",
                  alignSelf: "flex-end",
                  margin: 13,
                }}
              >
                <MaterialIcons name="clear" size={14} color="#0D88F0" />
              </TouchableOpacity>
              <View style={{ paddingVertical: 45, alignItems: "center" }}>
                <Text
                  style={{
                    fontSize: size.width < 1280 ? 30 : 35,
                    fontFamily: "Prompt",
                    color: "#74C745",
                  }}
                >
                  เสร็จสิ้น!
                </Text>
                <Text
                  style={[
                    styles.text20,
                    { marginTop: 10, fontSize: size.width < 1280 ? 16 : 20 },
                  ]}
                >
                  ระบบได้ทำการบันทึกคอร์สของท่าน
                </Text>
                <Text
                  style={[
                    styles.text25,
                    {
                      marginTop: 10,
                      fontSize: size.width < 1280 ? 20 : 25,
                      textAlign: size.width < 500 && "center",
                      marginHorizontal: size.width < 500 && 10,
                    },
                  ]}
                >
                  โปรด
                  <Text style={{ textDecorationLine: "underline" }}>
                    {size.width < 500 ? "รอการอนุมัติ\n" : "รอการอนุมัติ"}
                  </Text>
                  จากทางแอดมินภายใน 1 - 2 วัน
                </Text>
                <View
                  style={{
                    flexDirection: "row",
                    marginTop: 10,
                  }}
                >
                  <Text
                    style={[
                      styles.text18,
                      { fontSize: size.width < 1280 ? 14 : 18 },
                    ]}
                  >
                    หาก 1 - 2 วันยังไม่มีการตอบรับกรุณา
                  </Text>
                  <TouchableOpacity
                    style={{ alignSelf: size.width < 500 && "center" }}
                  >
                    <Text
                      style={[
                        styles.text18,
                        {
                          textDecorationLine: "underline",
                          color: "#E82626",
                          fontSize: size.width < 1280 ? 14 : 18,
                        },
                      ]}
                    >
                      ติดต่อเรา
                    </Text>
                  </TouchableOpacity>
                </View>
              </View>
            </View>
          </View>
        </Modal>
        <Modal
          transparent={true}
          visible={modalSucceed1}
          onRequestClose={() => {
            Alert.alert("Modal has been closed.");
            setModalSucceed1(!modalSucceed1);
          }}
        >
          <View style={styles.bgModal}>
            <View
              style={{
                width: size.width < 1280 ? "80%" : "45%",
                height: 220,
                borderWidth: 1,
                borderColor: "#0D88F0",
                backgroundColor: "#FFFFFF",
                alignSelf: "center",
                alignItems: "center",
              }}
            >
              <TouchableOpacity
                onPress={() => {
                  // setModalSucceed(!modalSucceed);
                  window.location.replace("Profile");
                }}
                style={{
                  position: "absolute",
                  alignSelf: "flex-end",
                  margin: 13,
                }}
              >
                <MaterialIcons name="clear" size={14} color="#0D88F0" />
              </TouchableOpacity>
              <View style={{ paddingVertical: 45, alignItems: "center" }}>
                <Text
                  style={{
                    fontSize: size.width < 1280 ? 30 : 35,
                    fontFamily: "Prompt",
                    color: "#74C745",
                  }}
                >
                  เสร็จสิ้น!
                </Text>
                <Text
                  style={[
                    styles.text20,
                    { marginTop: 10, fontSize: size.width < 1280 ? 16 : 20 },
                  ]}
                >
                  ระบบได้ทำการบันทึกคอร์สของท่านเรียบร้อย
                </Text>
              </View>
            </View>
          </View>
        </Modal>
        <Modal
          transparent={true}
          visible={modalTag}
          onRequestClose={() => {
            Alert.alert("Modal has been closed.");
            setModalTag(!modalTag);
          }}
        >
          <View style={styles.bgModal}>
            <View
              style={{
                width: size.width < 1280 ? "80%" : "40%",

                backgroundColor: "#FFFFFF",
                alignSelf: "center",
                alignItems: "center",
                borderRadius: 30,
                paddingVertical: 25,
              }}
            >
              <Text
                style={[
                  size.width < 500 ? stylesResponsive.text25 : styles.text25,
                ]}
              >
                เพิ่มแท็ค
              </Text>
              <TextInput
                autoComplete="off"
                value={tag}
                ref={ref}
                onChangeText={(text) => {
                  setTag(text);
                }}
                style={[
                  size.width < 500 ? stylesResponsive.text25O : styles.text25O,
                  {
                    width: size.width < 1280 ? "80%" : 476,
                    height: 50,
                    borderWidth: 1,
                    borderColor: "#E0E0E0",
                    backgroundColor: "#FFFFFF",
                    paddingHorizontal: 10,
                    marginTop: 20,
                  },
                ]}
              />
              <View style={{ flexDirection: "row", marginTop: 40 }}>
                <TouchableOpacity
                  onPress={() => {
                    setModalTag(!modalTag);
                    anotherFunc(tag);
                  }}
                  style={{
                    width: size.width < 1280 ? 100 : 200,
                    height: size.width < 1280 ? 40 : 50,
                    borderWidth: 1,
                    borderColor: "#0D88F0",
                    borderRadius: 5,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Text
                    style={[
                      size.width < 500
                        ? stylesResponsive.text25O
                        : styles.text25O,
                    ]}
                  >
                    ยกเลิก
                  </Text>
                </TouchableOpacity>
                <TouchableOpacity
                  onPress={async () => {
                    if (tag != undefined) {
                      setbody((val) => ({
                        ...val,
                        TAG: val.TAG.concat(tag),
                      }));

                      setTag("");
                      anotherFunc(tag);
                      setModalTag(false);
                    }
                  }}
                  style={{
                    width: size.width < 1280 ? 100 : 200,
                    height: size.width < 1280 ? 40 : 50,
                    backgroundColor: "#0D88F0",
                    borderRadius: 5,
                    justifyContent: "center",
                    alignItems: "center",
                    marginLeft: 15,
                  }}
                >
                  <Text
                    style={[
                      size.width < 500
                        ? stylesResponsive.text25W
                        : styles.text25W,
                    ]}
                  >
                    บันทึก
                  </Text>
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </Modal>
        <Modal
          transparent={true}
          visible={modalTeacher}
          onRequestClose={() => {
            Alert.alert("Modal has been closed.");
            setModalTeacher(!modalTeacher);
          }}
        >
          <View style={styles.bgModal}>
            <View
              style={{
                width: size.width < 1280 ? "80%" : "40%",

                backgroundColor: "#FFFFFF",
                alignSelf: "center",
                alignItems: "center",
                borderRadius: 30,
                paddingVertical: 25,
              }}
            >
              <Text
                style={[
                  size.width < 500 ? stylesResponsive.text25 : styles.text25,
                ]}
              >
                เพิ่มผู้ช่วยสอน
              </Text>
              <View style={{ backgroundColor: "#FFF" }}>
                <Autocomplete
                  placeholder={""}
                  data={data.filter(
                    (item) => item.user_accounts.id != profile.user_accounts.id
                  )}
                  style={[
                    size.width < 500
                      ? stylesResponsive.text25O
                      : styles.text25O,
                    {
                      width: size.width < 1280 ? "100%" : 476,
                      height: 50,
                      borderWidth: 1,
                      borderColor: "#E0E0E0",
                      backgroundColor: "#FFFFFF",
                      paddingHorizontal: 10,
                      marginTop: 20,
                    },
                  ]}
                  value={autoText}
                  containerStyle={
                    {
                      // width: size.width < 850 ? "50%" : "100%",
                      // maxWidth: 534,
                      // height: 40,
                      // borderWidth: 0,
                      // borderColor: "#FBD1C2",
                      // backgroundColor: "red",
                    }
                  }
                  inputContainerStyle={{
                    width: size.width < 1280 ? "100%" : 476,

                    height: 50,
                    borderWidth: 0,

                    backgroundColor: "#FFFFFF",
                  }}
                  listContainerStyle={{
                    marginTop: 20,
                  }}
                  onChangeText={(text) => {
                    setAutotext(text);
                  }}
                  flatListProps={{
                    keyExtractor: (_, idx) => idx,
                    renderItem: ({ item }) => (
                      <TouchableOpacity
                        style={{ zIndex: 99 }}
                        onPress={() => {
                          setTimeout(() => {
                            setAutotext(
                              item.user_accounts.firstName +
                                " " +
                                item.user_accounts.lastName
                            );
                            setWarningPerson(false);

                            setUser_accounts((val) => ({
                              ...val,
                              person: val.person.concat({
                                img: item.user_accounts.image_url,
                                firstName: item.user_accounts.firstName,
                                lastName: item.user_accounts.lastName,
                                id: item.id,
                              }),
                            }));

                            setData([]);
                          }, 800);
                        }}
                      >
                        <Text
                          style={{
                            fontSize: 18,
                            fontFamily: "Prompt",
                            color: "#0B2051",
                            backgroundColor: "#FFF",
                            width: "100%",
                            maxWidth: 534,
                          }}
                        >
                          {item.user_accounts.firstName}
                          {"  "}
                          {item.user_accounts.lastName}
                        </Text>
                      </TouchableOpacity>
                    ),
                  }}
                />
                {warningPerson && (
                  <Text
                    style={{
                      fontSize: 12,
                      fontFamily: "Prompt",
                      color: "red",
                      textAlign: "left",
                    }}
                  >
                    ไม่พบข้อมูลชื่ออาจารย์ในระบบ
                  </Text>
                )}
              </View>
              {/* <TextInput   autoComplete="off"
                defaultValue={teacher}
                onChangeText={(text) => {
                  setTeacher(text);
                }}
                style={[
                  size.width < 500 ? stylesResponsive.text25O : styles.text25O,
                  {
                    width: size.width < 1280 ? "80%" : 476,
                    height: 50,
                    borderWidth: 1,
                    borderColor: "#E0E0E0",
                    backgroundColor: "#FFFFFF",
                    paddingHorizontal: 10,
                    marginTop: 20,
                  },
                ]}
              /> */}
              <View style={{ flexDirection: "row", marginTop: 40, zIndex: -1 }}>
                <TouchableOpacity
                  onPress={() => {
                    setWarningPerson(false);
                    setAutotext("");
                    setModalTeacher(!modalTeacher);
                  }}
                  style={{
                    width: size.width < 1280 ? 100 : 200,
                    height: size.width < 1280 ? 40 : 50,
                    borderWidth: 1,
                    borderColor: "#0D88F0",
                    borderRadius: 5,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Text
                    style={[
                      size.width < 500
                        ? stylesResponsive.text25O
                        : styles.text25O,
                    ]}
                  >
                    ยกเลิก
                  </Text>
                </TouchableOpacity>
                <TouchableOpacity
                  onPress={async () => {
                    if (user_accounts.person.length > 0) {
                      setbody((val) => ({
                        ...val,
                        person: val.person.concat(user_accounts.person),
                      }));

                      setAutotext("");
                      setTimeout(() => {
                        setUser_accounts({ person: [] });

                        setModalTeacher(false);
                      }, 500);
                    } else {
                      setWarningPerson(true);
                    }
                  }}
                  style={{
                    width: size.width < 1280 ? 100 : 200,
                    height: size.width < 1280 ? 40 : 50,
                    backgroundColor: "#0D88F0",
                    borderRadius: 5,
                    justifyContent: "center",
                    alignItems: "center",
                    marginLeft: 15,
                  }}
                >
                  <Text
                    style={[
                      size.width < 500
                        ? stylesResponsive.text25W
                        : styles.text25W,
                    ]}
                  >
                    บันทึก
                  </Text>
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </Modal>
        <Modal
          transparent={true}
          visible={modalStudyModel}
          onRequestClose={() => {
            Alert.alert("Modal has been closed.");
            setmodalStudyModel(!modalStudyModel);
          }}
        >
          <View style={styles.bgModal}>
            <View
              style={{
                width: size.width < 1280 ? "80%" : "40%",

                backgroundColor: "#FFFFFF",
                alignSelf: "center",
                alignItems: "center",
                borderRadius: 30,
                paddingVertical: 25,
              }}
            >
              <View style={{ flexDirection: "row", alignItems: "center" }}>
                <Ionicons name="warning-outline" size={24} color="#E82626" />
                <Text
                  style={[
                    size.width < 500 ? stylesResponsive.text25 : styles.text25,
                    { color: "#E82626", marginLeft: 10 },
                  ]}
                >
                  ไม่สามารถสร้างคอร์สได้
                </Text>
              </View>
              <Text
                style={[
                  size.width < 500 ? stylesResponsive.text20 : styles.text20,
                ]}
              >
                กรุณาเพิ่มแบบฝึกหัดอย่างน้อย 1 บทเรียน
              </Text>

              <View style={{ marginTop: 20 }}>
                <TouchableOpacity
                  onPress={() => {
                    setmodalStudyModel(!modalStudyModel);
                  }}
                  style={{
                    width: size.width < 1280 ? 100 : 200,
                    height: size.width < 1280 ? 40 : 50,
                    borderWidth: 1,
                    borderColor: "#0D88F0",
                    borderRadius: 5,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Text
                    style={[
                      size.width < 500
                        ? stylesResponsive.text25O
                        : styles.text25O,
                    ]}
                  >
                    ตกลง
                  </Text>
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </Modal>
        <Modal
          transparent={true}
          visible={modalWarning}
          onRequestClose={() => {
            Alert.alert("Modal has been closed.");
            setmodalmodalWarning(!modalWarning);
          }}
        >
          <View style={styles.bgModal}>
            <View
              style={{
                width: size.width < 1280 ? "80%" : "40%",

                backgroundColor: "#FFFFFF",
                alignSelf: "center",
                alignItems: "center",
                borderRadius: 30,
                paddingVertical: 25,
              }}
            >
              <View style={{ flexDirection: "row", alignItems: "center" }}>
                <Ionicons name="warning-outline" size={24} color="#E82626" />
                <Text
                  style={[
                    size.width < 500 ? stylesResponsive.text25 : styles.text25,
                    { color: "#E82626", marginLeft: 10 },
                  ]}
                >
                  {"กรุณากรอกข้อมูลให้ครบถ้วน"}
                </Text>
              </View>

              <View style={{ marginTop: 20 }}>
                <TouchableOpacity
                  onPress={() => {
                    setmodalmodalWarning(!modalWarning);
                  }}
                  style={{
                    width: size.width < 1280 ? 100 : 200,
                    height: size.width < 1280 ? 40 : 50,
                    borderWidth: 1,
                    borderColor: "#0D88F0",
                    borderRadius: 5,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Text
                    style={[
                      size.width < 500
                        ? stylesResponsive.text25O
                        : styles.text25O,
                    ]}
                  >
                    ตกลง
                  </Text>
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </Modal>
        <Modal
          transparent={true}
          visible={modalWarningTime}
          onRequestClose={() => {
            Alert.alert("Modal has been closed.");
            setmodalmodalWarningTime(!modalWarningTime);
          }}
        >
          <View style={styles.bgModal}>
            <View
              style={{
                width: size.width < 1280 ? "80%" : "40%",

                backgroundColor: "#FFFFFF",
                alignSelf: "center",
                alignItems: "center",
                borderRadius: 30,
                paddingVertical: 25,
              }}
            >
              <View style={{ flexDirection: "row", alignItems: "center" }}>
                <Ionicons name="warning-outline" size={24} color="#E82626" />
                <Text
                  style={[
                    size.width < 500 ? stylesResponsive.text25 : styles.text25,
                    { color: "#E82626", marginLeft: 10 },
                  ]}
                >
                  {Clock}
                </Text>
              </View>

              <View style={{ marginTop: 20 }}>
                <TouchableOpacity
                  onPress={() => {
                    setmodalmodalWarningTime(!modalWarningTime);
                  }}
                  style={{
                    width: size.width < 1280 ? 100 : 200,
                    height: size.width < 1280 ? 40 : 50,
                    borderWidth: 1,
                    borderColor: "#0D88F0",
                    borderRadius: 5,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Text
                    style={[
                      size.width < 500
                        ? stylesResponsive.text25O
                        : styles.text25O,
                    ]}
                  >
                    ตกลง
                  </Text>
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </Modal>
        <Modal
          transparent={true}
          visible={modalRecord}
          onRequestClose={() => {
            Alert.alert("Modal has been closed.");
            setmodalRecord(!modalRecord);
          }}
        >
          <View style={styles.bgModal}>
            <View
              style={{
                width: size.width < 1280 ? "80%" : "45%",
                height: 270,
                borderWidth: 1,
                borderColor: "#0D88F0",
                backgroundColor: "#FFFFFF",
                alignSelf: "center",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Text
                style={{
                  fontSize: size.width < 1280 ? 30 : 35,
                  fontFamily: "Prompt",
                  color: "#74C745",
                }}
              >
                บันทึกแบบร่างเสร็จสิ้น!
              </Text>
              <Text
                style={{
                  fontSize: size.width < 1280 ? 22 : 25,
                  fontFamily: "Prompt",
                  color: "#0B2051",
                  marginTop: 20,
                }}
              >
                คอร์สแบบร่างจะอยู่ในการคอร์สของคุณ
              </Text>
            </View>
          </View>
        </Modal>
        <BottomPart navigation={navigation} />
      </ScrollView>
    </View>
  );
}
const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: "100%",
    backgroundColor: "#FFFFFF",
  },
  textButtonAdd: {
    color: "#0B2051",
    fontFamily: "Prompt",
    fontSize: 18,
    marginLeft: 10,
  },
  buttonAdd: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 30,
  },
  textOptionCourse: {
    fontFamily: "Prompt",
    fontSize: 25,
    color: "#0B2051",
    marginTop: 20,
  },
  viewAddLesson: {
    width: "100%",
    height: 39,
    borderRadius: 5,
    borderTopLeftRadius: 0,
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: 1,
    paddingVertical: 1,
    flexDirection: "row",
  },
  inputAddLesson: {
    width: "100%",
    height: 37,
    backgroundColor: "#FFFFFF",
    paddingHorizontal: 15,
    fontFamily: "Prompt",
    fontSize: 12,
    borderRadius: 5,
  },
  text18: {
    fontFamily: "Prompt",
    fontSize: 18,
    color: "#0B2051",
  },
  text16: {
    fontFamily: "Prompt",
    fontSize: 16,
    color: "#0B2051",
  },
  inputQA: {
    width: "100%",
    height: 35,
    borderWidth: 1,
    borderColor: "#E0E0E0",
    paddingHorizontal: 15,
    marginLeft: 10,
  },
  text25: {
    fontSize: 25,
    fontFamily: "Prompt",
    color: "#0B2051",
  },
  text20: {
    fontSize: 20,
    fontFamily: "Prompt",
    color: "#0B2051",
  },
  inputSetting: {
    borderWidth: 1,
    borderColor: "#E0E0E0",
    width: "100%",
    height: 40,
    fontSize: 20,
    fontFamily: "Prompt",
    color: "#0B2051",
    paddingHorizontal: 15,
  },
  viewDetailCourse: {
    width: "100%",
    height: 30,
    borderRadius: 5,
    borderWidth: 1,
    backgroundColor: "#FFFFFF",
    borderColor: "#5EB4AA",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: 6,
  },
  viewButtonDetailCourse: {
    width: 60,
    height: 20,
    borderRadius: 5,
    backgroundColor: "#0D88F0",
    justifyContent: "center",
    alignItems: "center",
  },
  text14: { fontSize: 14, fontFamily: "Prompt", color: "#0B2051" },
  text12W: {
    fontSize: 12,
    fontFamily: "Prompt",
    color: "#FFFFFF",
  },
  text30: {
    fontSize: 30,
    fontFamily: "Prompt",
    color: "#0B2051",
    alignSelf: "center",
  },
  text25: {
    fontSize: 25,
    fontFamily: "Prompt",
    color: "#0B2051",
  },
  fristTabModal: {
    width: "50%",
    height: 70,
    justifyContent: "center",
    alignItems: "center",
  },
  lastTabModal: {
    width: "50%",
    height: 70,
    borderWidth: 1,
    borderColor: "#0D88F0",
    justifyContent: "center",
    alignItems: "center",
  },
  inputWorkModal: {
    width: "100%",
    maxWidth: 470,
    height: 50,
    borderWidth: 1,
    borderColor: "#E0E0E0",
    paddingHorizontal: 15,
    marginTop: 15,
  },
  text12R: { fontSize: 12, fontFamily: "Prompt", color: "#E82626" },
  viewRecordModal: {
    flexDirection: "row",
    alignSelf: "center",
    height: "10%",
    alignItems: "flex-end",
  },
  buttonRecordModal: {
    width: 252,
    height: 66,
    borderRadius: 5,
    backgroundColor: "#0D88F0",
    justifyContent: "center",
    alignItems: "center",
  },
  text25W: {
    fontSize: 25,
    fontFamily: "Prompt",
    color: "#FFFFFF",
  },
  buttonBackModal: {
    width: 252,
    height: 66,
    borderRadius: 5,
    borderWidth: 1,
    borderColor: "#0D88F0",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: 40,
  },
  text25O: {
    fontSize: 25,
    fontFamily: "Prompt",
    color: "#0D88F0",
  },
  uploadImgModal: {
    width: "75%",
    height: 500,
    borderWidth: 1,
    borderColor: "#E0E0E0",
    justifyContent: "center",
    alignItems: "center",
  },
  text30Oo: {
    fontSize: 30,
    fontFamily: "Prompt",
    color: "#D1DDE5",
  },
  inputDetailModal: {
    width: 1024,
    marginTop: 25,
    height: 404,
    padding: 20,
    borderWidth: 1,
    borderColor: "#0D88F0",
    fontSize: 18,
    fontFamily: "Prompt",
    color: "#0B2051",
  },
  bgModal: {
    width: "100%",
    height: "100%",
    backgroundColor: "#000000bb",
    justifyContent: "center",
  },
  bgDetailModal: {
    width: "90%",
    minHeight: 1245,
    backgroundColor: "#FFFFFF",
    borderWidth: 1,
    borderColor: "#0D88F0",
    alignSelf: "center",
    padding: 25,
    marginVertical: "10%",
  },
  detailChoiceModal: {
    alignItems: "center",
    justifyContent: "center",
    marginTop: 10,
    backgroundColor: "#FFFFFF",
    height: 45,
  },
  textNoChoice: {
    position: "absolute",
    alignSelf: "flex-start",
    marginLeft: 10,
  },
  inputDetailChoiceModal: {
    width: "100%",
    height: 40,
    borderWidth: 1,
    borderColor: "#E0E0E0",
    paddingHorizontal: 20,
    fontFamily: "Prompt",
    fontSize: 16,
    color: "#0B2051",
  },
  selectAnsChoiceModal: {
    flexDirection: "row",
    alignItems: "center",
    position: "absolute",
    alignSelf: "flex-end",
    marginRight: 20,
  },
  viewQuestionModal: {
    width: 179,
    height: 50,
    backgroundColor: "#FFFFFF",
    borderTopRightRadius: 5,
    borderTopLeftRadius: 5,
    alignItems: "center",
    justifyContent: "center",
    marginTop: 25,
  },
  selectQuestionModal: {
    width: "100%",
    minHeight: 120,
    backgroundColor: "#FFFFFF",
    borderRadius: 5,
    borderTopLeftRadius: 0,
    paddingHorizontal: 30,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  inputQuestionModal: {
    width: "75%",
    height: 40,
    borderWidth: 1,
    borderColor: "#E0E0E0",
    paddingHorizontal: 15,
    fontFamily: "Prompt",
    fontSize: 16,
    color: "#0B2051",
  },
  buttonAddQuestionModal: {
    width: 60,
    height: 40,
    backgroundColor: "#0D88F0",
    justifyContent: "center",
    alignItems: "center",
  },
  buttonAddAnsModal: {
    width: "100%",
    height: 40,
    borderWidth: 1,
    borderColor: "#E0E0E0",
    flexDirection: "row",
    marginTop: 10,
    backgroundColor: "#FFFFFF",
    alignItems: "center",
  },
  viewCkEditor: {
    borderWidth: 1,
    borderColor: "#E0E0E0",
  },
  text14R: {
    fontSize: 14,
    color: "red",
    fontFamily: "Prompt",
  },
});
const stylesResponsive = StyleSheet.create({
  textButtonAdd: {
    color: "#0B2051",
    fontFamily: "Prompt",
    fontSize: 14,
    marginLeft: 10,
  },
  text30: {
    fontSize: 16,
    fontFamily: "Prompt",
    color: "#0B2051",
    alignSelf: "center",
  },
  text30Oo: {
    fontSize: 16,
    fontFamily: "Prompt",
    color: "#D1DDE5",
  },
  lastTabModal: {
    width: "50%",
    height: 50,
    borderWidth: 1,
    borderColor: "#0D88F0",
    justifyContent: "center",
    alignItems: "center",
  },
  fristTabModal: {
    width: "50%",
    height: 50,
    justifyContent: "center",
    alignItems: "center",
  },
  uploadImgModal: {
    width: "75%",
    height: 200,
    borderWidth: 1,
    borderColor: "#E0E0E0",
    justifyContent: "center",
    alignItems: "center",
  },
  bgDetailModal: {
    width: "90%",

    backgroundColor: "#FFFFFF",
    borderWidth: 1,
    borderColor: "#0D88F0",
    alignSelf: "center",
    padding: 25,
    marginVertical: "10%",
  },
  text25W: {
    fontSize: 14,
    fontFamily: "Prompt",
    color: "#FFFFFF",
  },
  buttonBackModal: {
    width: 100,
    height: 40,
    borderRadius: 5,
    borderWidth: 1,
    borderColor: "#0D88F0",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: 40,
  },
  text25O: {
    fontSize: 14,
    fontFamily: "Prompt",
    color: "#0D88F0",
  },
  buttonRecordModal: {
    width: 100,
    height: 40,
    borderRadius: 5,
    backgroundColor: "#0D88F0",
    justifyContent: "center",
    alignItems: "center",
  },
  text25: {
    fontSize: 16,
    fontFamily: "Prompt",
    color: "#0B2051",
  },
  text20: {
    fontSize: 14,
    fontFamily: "Prompt",
    color: "#0B2051",
  },
  viewQuestionModal: {
    width: 100,
    height: 50,
    backgroundColor: "#FFFFFF",
    borderTopRightRadius: 5,
    borderTopLeftRadius: 5,
    alignItems: "center",
    justifyContent: "center",
    marginTop: 25,
  },
  selectQuestionModal: {
    width: "100%",
    minHeight: 120,
    backgroundColor: "#FFFFFF",
    borderRadius: 5,
    borderTopLeftRadius: 0,

    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  inputQuestionModal: {
    width: "75%",
    height: 40,
    borderWidth: 1,
    borderColor: "#E0E0E0",
    paddingHorizontal: 15,
    fontFamily: "Prompt",
    fontSize: 12,
    color: "#0B2051",
  },
  buttonAddQuestionModal: {
    width: 50,
    height: 40,
    backgroundColor: "#0D88F0",
    justifyContent: "center",
    alignItems: "center",
  },
  textOptionCourse: {
    fontFamily: "Prompt",
    fontSize: 18,
    color: "#0B2051",
    marginTop: 20,
  },
  text10R: {
    fontSize: 10,
    color: "red",
    fontFamily: "Prompt",
  },
});
