import React, { useState, useRef, useEffect } from "react";
import {
  View,
  Text,
  Dimensions,
  TouchableOpacity,
  StyleSheet,
  ScrollView,
  FlatList,
  Image,
} from "react-native";
import Modal from "modal-react-native-web";
import { MaterialCommunityIcons, MaterialIcons } from "@expo/vector-icons";
import Header from "../components/header";
import { Video } from "expo-av";
import { useIsFocused } from "@react-navigation/native";
import { apiservice } from "../../service";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  courseState,
  lessonVideoState,
  tokenState,
  userState,
} from "../../reducer/recoil";
import { useWindowSize } from "../components/responsive";
import ReactPlayer from "react-player/youtube";
import HTMLRenderer from "react-html-renderer";
const { width, height } = Dimensions.get("window");
export default function lessonVideo({ navigation, route }) {
  const video = React.useRef(null);
  const lessonVideoStates = route.params;

  const [prev, setprev] = React.useState(true);
  const size = useWindowSize();
  const [show, setShow] = useState([]);
  const [show1, setShow1] = useState([]);
  const [modalScore, setModalScore] = useState(false);
  const [positionMillis, setpositionMillis] = useState(0);
  const [unLock, setUnLock] = useState(false);
  const [defaultsStatus, setStatus] = useState(true);
  const focus = useIsFocused();
  const DATA = route.params.document;
  const [finish, setFinish] = useState(false);
  const [play, setPlay] = useState(false);
  const token = useRecoilValue(tokenState);
  const [Oncecourse, myOncecourse] = useState(null);
  const fucus = useIsFocused();
  const [users, setUser] = useRecoilState(userState);
  const [courseStates, setCourseStates] = useRecoilState(courseState);

  async function Chanfd(params, Mainindex, i) {
    if (params.type == "EXAM") {
      const res = await apiservice({
        path: "/quiz/get/" + params.education_id,
        method: "get",
        token: token?.accessToken,
      });

      navigation.navigate("TestReview", {
        ...params,
        ...res.data,
        Mainindex,
        index: i,
        number: 0,
        dataStudymodel: route.params.dataStudymodel,
      });
    } else if (params.type == "DESCRIPTION") {
      const res = await apiservice({
        path: "/course/geteducation/" + params.education_id,
        method: "get",
        token: token?.accessToken,
      });
      if (res.status == 200) {
        if (res.data.TYPE == "VDO") {
          navigation.navigate("LessonVideoReview", {
            ...params,
            ...res.data,
            number: 0,
            Mainindex,
            dataStudymodel: route.params.dataStudymodel,
            index: i,
          });
        } else {
          navigation.navigate("LessonReview", {
            ...params,
            ...res.data,
            number: 0,
            Mainindex,
            dataStudymodel: route.params.dataStudymodel,
            index: i,
          });
        }
      }
    } else if (params.type == "WORK") {
      const res = await apiservice({
        path: "/course/getwork/" + params.education_id,
        method: "get",
        token: token?.accessToken,
      });

      navigation.push("SubjectiveTestReview", {
        ...res.data,
        ...params,
        number: 0,
        Mainindex,
        index: i,
        dataStudymodel: route.params.dataStudymodel,
      });
    } else {
      const res = await apiservice({
        path: "/quiz/get/" + params.education_id,
        method: "get",
        token: token?.accessToken,
      });

      navigation.push("SubjectiveTestReview", {
        ...res.data,
        ...params,
        dataStudymodel: route.params.dataStudymodel,
        number: 0,
        Mainindex,
        index: i,
      });
    }
  }

  useEffect(() => {
    checkStart();
  }, []);

  useEffect(() => {
    if (
      defaultsStatus &&
      route.params.dataStudymodel[route.params.Mainindex].listen_detail[
        route.params.index + 1
      ] == undefined
    ) {
      name1();
    }
  }, []);

  async function name1() {
    const respp = await apiservice({
      path: "/start-course/getalltotals/" + route.params.course_id,
      method: "get",
      token: token?.accessToken,
    });
    const resppt = await apiservice({
      path: "/start-course/getworktotals/" + route.params.course_id,
      method: "get",
      token: token?.accessToken,
    });
    if (respp.status == 200) {
      setShow1(resppt.data.results);
      setShow(respp.data.results);
      setModalScore(true);
    }
  }

  useEffect(() => {
    if (fucus == false) {
      // video.current.pauseAsync();
      setprev(false);
    }
  }, [fucus]);

  useEffect(() => {
    if (window.performance) {
      if (performance.navigation.type == 1) {
      } else {
      }
    }

    window.onbeforeunload = function () {
      video.current.pauseAsync();
      setPlay(true);
      return true;
    };

    return () => {
      window.onbeforeunload = null;
    };
  }, []);

  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  async function checkStart() {
    const response = await apiservice({
      path: "/start-course/myOncecourse?course_id=" + route.params.course_id,
      method: "get",
      token: token?.accessToken,
    });

    if (response.status == 200) {
      myOncecourse(response.data);
    }
  }
  async function checkStartFinish() {
    const response = await apiservice({
      path: "/start-course/myOncecourse?course_id=" + route.params.course_id,
      method: "get",
      token: token?.accessToken,
    });

    if (response.status == 200) {
      try {
        route.params.progress.map((items) => {
          items.listen_detail.map((val) => {
            if (val.education_id == route.params.education_id) {
              setStatus(true);
            }
          });
        });
      } catch (error) {}

      myOncecourse(response.data);
    }
  }
  const embed = route.params.detail.detail;

  const stringToHTML = function (str) {
    const domContainer = document.createElement("span");
    domContainer.innerHTML = str;
    return domContainer;
  };

  const parentEmbed = stringToHTML(embed);

  let oldIframe = parentEmbed.querySelectorAll("oembed");
  oldIframe = Array.from(oldIframe);

  for (const i in oldIframe) {
    //Get the url from oembed tag
    let url = oldIframe[i].getAttribute("url");
    //Replace 'watch?v' with 'embed/'
    url = url.replace("watch?v=", "embed/");

    //Create a iframe tag
    const newIframe = document.createElement("iframe");
    newIframe.setAttribute("width", "auto");
    newIframe.setAttribute("height", "auto");
    newIframe.setAttribute("allowFullScreen", "");
    newIframe.setAttribute("frameBorder", 0);
    if (url) {
      newIframe.setAttribute("src", url);
    }
    // replace oldIframe with newIframe
    oldIframe[i].parentNode.replaceChild(newIframe, oldIframe[i]);
  }
  const [too, setToo] = useState(null);

  const contentToRender = parentEmbed.outerHTML?.replace(
    /<img /g,
    `<img width="${width * 0.5}" height="auto" `
  );

  useEffect(() => {
    if (show.length > 0) {
      totalsss();
    }
  }, [show]);

  async function totalsss() {
    let toootals = 0;
    const re = await Promise.all(
      route.params.progress.map(async (item, index) => {
        const res = await apiservice({
          path:
            "/quiz/get/" +
            item.listen_detail.filter((items) => items.type == "EXAM")[0]
              .education_id,
          method: "get",
          token: token?.accessToken,
        });
        const question = res.data.question;
        const answers = item.listen_detail.filter(
          (items) => items.type == "EXAM"
        )[0].answer;
        toootals =
          (answers
            .filter(
              (ele, ind) =>
                ind === answers.findIndex((elem) => elem.number === ele.number)
            )
            .filter((items, mainIndex) => {
              // if(items)

              if (
                question.filter((val, indexs) => indexs == items.number)[0]
                  .type == "1"
              ) {
                return (
                  question.filter((val, indexs) => indexs == items.number)[0]
                    .answer == items.answer
                );
              }
              if (
                question.filter((val, indexs) => indexs == items.number)[0]
                  .type == "2"
              ) {
                return (
                  question
                    .filter((val, indexs) => indexs == items.number)[0]
                    .answer.filter(
                      (e) => items.answer.filter((ev) => e == ev).length > 0
                    ).length ==
                  question.filter((val, indexs) => indexs == items.number)[0]
                    .answer.length
                );
              }
              if (
                question.filter((val, indexs) => indexs == items.number)[0]
                  .type == "3"
              ) {
                return question.filter(
                  (val, indexs) => indexs == items.number
                )[0].answer != null
                  ? question.filter((val, indexs) => indexs == items.number)[0]
                      .answer == items.answer
                  : items.answer == 0
                  ? true
                  : false;
              }
              if (
                question.filter((val, indexs) => indexs == items.number)[0]
                  .type == "4"
              ) {
                return (
                  question
                    .filter((val, indexs) => indexs == items.number)[0]
                    .answer.filter(
                      (e) =>
                        items.answer.filter((ev) => e.answer == ev.answer)
                          .length > 0
                    ).length ==
                  question.filter((val, indexs) => indexs == items.number)[0]
                    .answer.length
                );
              }
              if (
                question.filter((val, indexs) => indexs == items.number)[0]
                  .type == "5"
              ) {
                return (
                  question.filter((val, indexs) => {
                    if (val.type == "5") {
                      return (
                        answers
                          .filter((val, indexss) => indexs == val.number)[0]
                          .answer.filter((e, i) => val.choice[i] == e.ans)
                          .length == val.choice.length
                      );
                    } else {
                      return false;
                    }
                  }).length > 0
                );
              }

              return false;
            }).length /
            answers.length) *
          100;
      })
    );

    setToo(toootals);
  }

  return (
    <View style={styles.container}>
      <Modal
        transparent={true}
        visible={modalScore}
        onRequestClose={() => {
          Alert.alert("Modal has been closed.");
          setModalScore(!modalScore);
        }}
      >
        <View style={styles.viewModal}>
          <View
            style={[styles.viewDetailModal, { maxWidth: 600, maxHeight: 500 }]}
          >
            <View style={{}}>
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  paddingTop: 20,
                }}
              >
                <Text
                  style={{
                    fontFamily: "Prompt",
                    fontSize: 30,
                    color: "#0B2051",
                  }}
                >
                  ผลของคุณคือ
                </Text>
                <Text
                  style={{
                    fontFamily: "Prompt",
                    fontSize: 80,
                    color: "#0B2051",
                    marginLeft: 43,
                  }}
                >
                  {too}%
                </Text>
              </View>
              <Text
                style={{
                  fontFamily: "Prompt",
                  fontSize: 20,
                  color: "#0B2051",
                  alignSelf: "center",
                  marginBottom: 40,
                }}
              >
                สรุปการเรียนของคุณ
              </Text>
              <View style={{ flexDirection: "row" }}>
                <View style={styles.viewDashedModal}>
                  <MaterialIcons
                    name="library-books"
                    size={40}
                    color="#0B2051"
                  />
                  <Text style={styles.text25}>
                    {show1.length}/{show1.length}
                  </Text>
                </View>
                <View
                  style={[
                    styles.viewDashedModal,
                    { justifyContent: "flex-end", borderRightWidth: 0 },
                  ]}
                >
                  <MaterialIcons name="archive" size={40} color="#0B2051" />
                  <Text style={styles.text25}>
                    {show.filter((item) => item.type == "TEST").length}/
                    {show.filter((item) => item.type == "TEST").length}
                  </Text>
                </View>
              </View>
              <View style={{ flexDirection: "row" }}>
                <View style={styles.viewDashedModal1}>
                  <MaterialIcons name="live-help" size={40} color="#0B2051" />
                  <Text style={styles.text25}>
                    {show.filter((item) => item.type == "WRITING").length}/
                    {show.filter((item) => item.type == "WRITING").length}
                  </Text>
                </View>
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    width: "50%",
                  }}
                >
                  <Text
                    style={{
                      fontFamily: "Prompt",
                      fontSize: 25,
                      color: "#0B2051",
                    }}
                  >
                    ผล
                  </Text>
                  <Text
                    style={{
                      fontFamily: "Prompt",
                      fontSize: 30,
                      color: "#74C745",
                      marginLeft: 25,
                    }}
                  >
                    ผ่าน
                  </Text>
                </View>
              </View>
            </View>
            <View
              style={{
                flexDirection: "row",
                justifyContent: "center",
                marginVertical: 30,
              }}
            >
              {/* <TouchableOpacity
                onPress={() => {
                  window.open(
                    `https://api.courseoncloud.com/api/image/export/${route.params.course_id}?uid=${users.uid}`
                  );
                  // https://api.courseoncloud.com/api/image/export/1?uid=32
                }}
                style={{
                  width: 141,
                  height: 44,
                  backgroundColor: "#0D88F0",
                  borderRadius: 5,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Text
                  style={{
                    fontSize: 20,
                    fontFamily: "Prompt",
                    color: "#FFFFFF",
                  }}
                >
                  Certificate
                </Text>
              </TouchableOpacity> */}
              <TouchableOpacity
                onPress={async () => {
                  setModalScore(!modalScore);
                  setUnLock(false);
                  const response = await apiservice({
                    path:
                      "/course/getall?page=1&lan=TH&course_id=" +
                      route.params.course_id,
                    method: "get",
                    //token: token?.accessToken,
                  });
                  setCourseStates(response.data.data4[0]);
                  setTimeout(() => {
                    navigation.navigate("Course");
                  }, 900);
                }}
                style={{
                  width: 141,
                  height: 44,
                  borderWidth: 1,
                  borderColor: "#0D88F0",
                  borderRadius: 5,
                  justifyContent: "center",
                  alignItems: "center",
                  // marginLeft: 60,
                }}
              >
                <Text
                  style={{
                    fontSize: 20,
                    fontFamily: "Prompt",
                    color: "#0D88F0",
                  }}
                >
                  ดูคอร์ส
                </Text>
              </TouchableOpacity>
            </View>
            <Text
              style={{
                fontSize: 12,
                fontFamily: "Prompt",
                color: "#E82626",
                alignSelf: "center",
              }}
            >
              กรุณาติดต่อผู้สอนกรณีต้องการปรึกษาเรื่องผลการเรียน
            </Text>
          </View>
        </View>
      </Modal>
      <ScrollView stickyHeaderIndices={[0]}>
        <Header navigation={navigation} />
        <View
          style={{
            width: size.width < 1280 ? "80%" : "50%",
            alignSelf: "center",
            marginTop: 35,
          }}
        >
          <Text
            style={{ fontFamily: "Prompt", fontSize: 11, color: "#0B2051" }}
          >
            {/* พุทธศาสตรบัณฑิต (การสอนพระพุทธศาสนาและจิตวิทยาแนะแนว) */}
          </Text>
          <Text
            style={{ fontFamily: "Prompt", fontSize: 25, color: "#0B2051" }}
          >
            {/* ทำความรู้จักงานแนะแนว2 */}
            {route.params.name}
          </Text>
          {prev &&
            (route.params.detail.image.includes("youtube") ? (
              <View>
                <ReactPlayer
                  config={{ youtube: { playerVars: { disablekb: 1 } } }}
                  url={route.params.detail.image}
                  width={"100%"}
                  height={574}
                  controls={false}
                  onEnded={() => {
                    setFinish(true);
                  }}
                />
                <View
                  style={{ width: "100%", height: 200, position: "absolute" }}
                />
              </View>
            ) : (
              <View style={{ alignItems: "center", justifyContent: "center" }}>
                <Video
                  ref={video}
                  style={styles.video}
                  useNativeControls={defaultsStatus ? true : false}
                  source={{
                    uri: route.params.detail.image,
                  }}
                  onLoadStart={() => {
                    video.current.playAsync();
                  }}
                  onFullscreenUpdate
                  resizeMode="contain"
                  onResponderEnd={() => {}}
                  onPlaybackStatusUpdate={(status) => {
                    setpositionMillis(status.positionMillis);
                    if (defaultsStatus == false) {
                      setFinish(status.didJustFinish);
                    }

                    // setStatus(() => status);
                  }}
                />
                {play && (
                  <TouchableOpacity
                    onPress={() => {
                      setPlay(false);
                      video.current.playAsync();
                    }}
                    style={{
                      width: 100,
                      height: 100,
                      position: "absolute",
                    }}
                  >
                    <Image
                      style={{
                        width: 100,
                        height: 100,
                      }}
                      source={require("../img/play-button.png")}
                    />
                  </TouchableOpacity>
                )}
              </View>
            ))}
          <Text
            style={{
              fontFamily: "Prompt",
              fontSize: size.width < 500 ? 14 : 20,
              color: "#0B2051",
            }}
          >
            <HTMLRenderer
              html={contentToRender}
              components={
                {
                  // h1: props => <Heading color="red" {...props} />,
                  // h2: Subheading,
                  // a: Link,
                }
              }
            />
          </Text>
          <FlatList
            data={DATA}
            numColumns={1}
            renderItem={({ index, item }) => {
              return (
                <View
                  style={{
                    borderTopWidth: index == 0 && 1,
                    borderBottomWidth: 1,
                    borderBottomColor: "#BBBBBB",
                    borderTopColor: "#BBBBBB",
                    paddingVertical: 15,
                  }}
                >
                  <TouchableOpacity
                    onPress={() => {
                      // Linking.openURL(
                      //   "https://api.courseoncloud.com/api/image/pdf/" +
                      //     item.files.replaceAll(".pdf", "")
                      // );
                      openInNewTab(
                        "https://api.courseoncloud.com/api/image/pdf/" +
                          item.files.replaceAll(".pdf", "")
                      );
                    }}
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Text style={[styles.textDetail, { marginTop: 0 }]}>
                      {item.name}
                    </Text>
                    <MaterialIcons
                      name="cloud-download"
                      size={24}
                      color="#0D88F0"
                    />
                  </TouchableOpacity>
                </View>
              );
            }}
          />
          <TouchableOpacity
            disabled={finish ? false : true}
            onPress={async () => {
              const response = await apiservice({
                path: "/start-course/update/" + route.params.id,
                method: "put",
                body: {
                  progress: route.params.progress.map((item, i) => {
                    if (i == 0) {
                      return {
                        ...item,
                        listen_detail: item.listen_detail.map((items, ins) => {
                          if (ins == 0) {
                            return { ...items, status: true };
                          } else if (
                            ins !== 0 &&
                            item.listen_detail[ins - 1].status == true
                          ) {
                            return { ...items, status: true };
                          } else {
                            return items;
                          }
                        }),
                      };
                    } else {
                      return {
                        ...item,
                        listen_detail: item.listen_detail.map((items, ins) => {
                          if (
                            ins == 0 &&
                            route.params.progress[i - 1].listen_detail[
                              route.params.progress[i - 1].listen_detail
                                .length - 1
                            ].status == true
                          ) {
                            return { ...items, status: true };
                          } else if (
                            ins !== 0 &&
                            item.listen_detail[ins - 1].status == true
                          ) {
                            return { ...items, status: true };
                          } else {
                            return items;
                          }
                        }),
                      };
                    }
                  }),
                },
                token: token?.accessToken,
              });
              setTimeout(() => {
                checkStart();
                checkStartFinish();
                setFinish(false);
              }, 500);
            }}
            style={{
              width: size.width < 500 ? 120 : 165,
              height: 57,
              borderRadius: 5,
              backgroundColor: finish ? "#0D88F0" : "#CCCCCC",
              justifyContent: "center",
              alignItems: "center",
              alignSelf: "center",
              marginVertical: 45,
            }}
          >
            <Text
              style={{
                fontFamily: "Prompt",
                fontSize: size.width < 500 ? 14 : 25,
                color: "#FFFFFF",
              }}
            >
              เสร็จสิ้น
            </Text>
          </TouchableOpacity>
        </View>
        <View
          style={{
            width: "100%",
            height: 70,
            backgroundColor: defaultsStatus ? "#0D88F0" : "#D9D9D9",
            justifyContent: "center",
          }}
        >
          <View
            style={[
              styles.viewProgress,
              { backgroundColor: defaultsStatus ? "#0D88F0" : "#D9D9D9" },
            ]}
          >
            {route.params.dataStudymodel[route.params.Mainindex].listen_detail[
              route.params.index - 1
            ] != undefined ? (
              <View style={styles.viewTextProgress}>
                <TouchableOpacity
                  onPress={async () => {
                    const Mainindex = route.params.Mainindex;
                    const i = route.params.index;
                    const newi = route.params.index - 1;

                    if (
                      route.params.dataStudymodel[Mainindex].listen_detail[
                        i - 1
                      ] != undefined
                    ) {
                      Chanfd(
                        route.params.dataStudymodel[Mainindex].listen_detail[
                          i - 1
                        ],
                        Mainindex,
                        newi
                      );
                    } else {
                      Chanfd(
                        route.params.dataStudymodel[Mainindex - 1]
                          .listen_detail[
                          route.params.dataStudymodel[Mainindex - 1]
                            .listen_detail.length - 1
                        ],
                        Mainindex - 1,
                        route.params.dataStudymodel[Mainindex - 1].listen_detail
                          .length - 1
                      );
                    }
                  }}
                  style={{ margin: 10 }}
                >
                  <MaterialCommunityIcons
                    name="arrow-left-drop-circle"
                    size={30}
                    color="#FFFFFF"
                  />
                </TouchableOpacity>
                <View>
                  <Text style={[styles.textProgress, { textAlign: "left" }]}>
                    บทก่อนหน้า
                  </Text>
                  <Text numberOfLines={1} style={styles.textProgress}>
                    {route.params.dataStudymodel[route.params.Mainindex]
                      .listen_detail[route.params.index - 1] != undefined
                      ? route.params.dataStudymodel[route.params.Mainindex]
                          .listen_detail[route.params.index - 1].name
                      : route.params.dataStudymodel[route.params.Mainindex]
                          .listen_detail[0].name}
                  </Text>
                </View>
              </View>
            ) : (
              <View style={styles.viewTextProgress}>
                <TouchableOpacity style={{ margin: 10 }}>
                  <MaterialCommunityIcons
                    name="arrow-left-drop-circle"
                    size={0}
                    color="#FFFFFF"
                  />
                </TouchableOpacity>
                <View>
                  <Text
                    style={[styles.textProgress, { textAlign: "left" }]}
                  ></Text>
                  <Text numberOfLines={1} style={styles.textProgress}></Text>
                </View>
              </View>
            )}
            <TouchableOpacity
              disabled={true}
              style={[
                styles.buttonProgress,
                {
                  backgroundColor: defaultsStatus ? "#E3F9D6" : "#FEF3CE",
                  width: size.width < 500 ? 120 : 150,
                },
              ]}
            >
              <Text style={styles.textSuccess}>
                {defaultsStatus ? "เสร็จสิ้น" : "กำลังดำเนินการ.."}
              </Text>
            </TouchableOpacity>
            {lessonVideoStates.dataStudymodel ? (
              <View
                style={[
                  styles.viewTextProgress,
                  { justifyContent: "flex-end" },
                ]}
              >
                <View>
                  <Text style={[styles.textProgress]}>บทถัดไป</Text>
                  <Text numberOfLines={1} style={styles.textProgress}>
                    {lessonVideoStates.dataStudymodel[
                      lessonVideoStates.Mainindex
                    ].listen_detail[lessonVideoStates.index + 1] != undefined
                      ? lessonVideoStates.dataStudymodel[
                          lessonVideoStates.Mainindex
                        ].listen_detail[lessonVideoStates.index + 1].name
                      : lessonVideoStates.dataStudymodel[
                          lessonVideoStates.Mainindex + 1
                        ] != undefined
                      ? lessonVideoStates.dataStudymodel[
                          lessonVideoStates.Mainindex + 1
                        ].listen_detail?.[0]?.name
                      : ""}
                  </Text>
                </View>
                <TouchableOpacity
                  onPress={async () => {
                    setTimeout(async () => {
                      const Mainindex = lessonVideoStates.Mainindex;
                      const i = lessonVideoStates.index;
                      const newi = lessonVideoStates.index + 1;

                      if (
                        lessonVideoStates.dataStudymodel[Mainindex]
                          .listen_detail[i + 1] != undefined
                      ) {
                        Chanfd(
                          lessonVideoStates.dataStudymodel[Mainindex]
                            .listen_detail[i + 1],
                          Mainindex,
                          newi,
                          true
                        );
                      } else if (
                        lessonVideoStates.dataStudymodel[Mainindex + 1]
                          .listen_detail?.[0] != undefined
                      ) {
                        Chanfd(
                          lessonVideoStates.dataStudymodel[Mainindex + 1]
                            .listen_detail?.[0],
                          Mainindex + 1,
                          0,
                          Oncecourse.progress[Mainindex + 1].listen_detail?.[0]
                            ?.status
                        );
                      } else {
                        const respp = await apiservice({
                          path:
                            "/start-course/getalltotals/" +
                            lessonVideoStates.course_id,
                          method: "get",
                          token: token?.accessToken,
                        });
                        const resppt = await apiservice({
                          path:
                            "/start-course/getworktotals/" +
                            lessonVideoStates.course_id,
                          method: "get",
                          token: token?.accessToken,
                        });
                        if (respp.status == 200) {
                          setShow1(resppt.data.results);
                          setShow(respp.data.results);
                          setModalScore(true);
                        }
                      }
                    }, 500);
                  }}
                  disabled={defaultsStatus ? false : true}
                  style={{ margin: 10 }}
                >
                  <MaterialCommunityIcons
                    name={defaultsStatus ? "arrow-right-drop-circle" : "lock"}
                    size={30}
                    color="#FFFFFF"
                  />
                </TouchableOpacity>
              </View>
            ) : (
              <View style={styles.viewTextProgress}>
                <TouchableOpacity style={{ margin: 10 }}>
                  <MaterialCommunityIcons
                    name="arrow-right-drop-circle"
                    size={0}
                    color="#FFFFFF"
                  />
                </TouchableOpacity>
                <View>
                  <Text
                    style={[styles.textProgress, { textAlign: "right" }]}
                  ></Text>
                  <Text numberOfLines={1} style={styles.textProgress}></Text>
                </View>
              </View>
            )}
          </View>
        </View>
      </ScrollView>
    </View>
  );
}
const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: "100%",
    backgroundColor: "#FFFFFF",
  },
  textDetail: {
    fontFamily: "Prompt",
    fontSize: 20,
    color: "#0B2051",
    marginTop: 40,
  },
  viewTextProgress: {
    flexDirection: "row",
    alignItems: "center",
    width: "30%",
  },
  textProgress: {
    fontFamily: "Prompt",
    fontSize: 12,
    color: "#FFFFFF",
    textAlign: "right",
  },
  viewProgress: {
    width: "100%",
    height: 70,
    flexDirection: "row",
    justifyContent: "space-between",
  },
  buttonProgress: {
    width: 150,
    height: 40,
    borderRadius: 20,
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "center",
  },
  textSuccess: {
    fontFamily: "Prompt",
    fontSize: 12,
    color: "#0B2051",
  },
  video: {
    width: "100%",
    //height: 574
  },
});
