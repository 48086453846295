import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  Dimensions,
  TouchableOpacity,
  StyleSheet,
  ScrollView,
  TextInput,
  FlatList,
  Image,
  Linking,
} from "react-native";
import { Stage, Layer } from "react-konva";
import { MaterialIcons } from "@expo/vector-icons";
import Draggable from "react-draggable";
import Header from "../components/header";
import { BottomPart, bottomPart } from "./home";
import RNPickerSelect from "react-native-picker-select";
import * as DocumentPicker from "expo-document-picker";
import { apiservice } from "../../service";
import { authActionCreateCreatecert } from "../../action/authAction";
import { useRecoilState } from "recoil";
import { tokenState } from "../../reducer/recoil";
import NumberFormat from "react-number-format";
import { useWindowSize } from "../components/responsive";
import Template, { SImage, useImages } from "./template";
import { SketchPicker } from "react-color";
import { useIsFocused } from "@react-navigation/native";
const { width, height } = Dimensions.get("window");
import {
  exportComponentAsJPEG,
  exportComponentAsPDF,
  exportComponentAsPNG,
} from "react-component-export-image";

export default function certificate({ navigation, route, color }) {
  const [image, setImage] = useState("");
  const [page, setPage] = useState(0);
  const [choiceIndex, setChoiceIndex] = useState(0);
  const [token, setToken] = useRecoilState(tokenState);
  const [courseName, setCourseName] = useState(false);
  const [TeacherName, setTeacherName] = useState(false);
  const [StudentName, setStudentName] = useState(false);
  const [dateStart, setDateStart] = useState(false);
  const [dateEnd, setDateEnd] = useState(false);
  const [openColor, setOpenColor] = useState(false);
  const [del, setDel] = useState(null);
  const [del1, setDel1] = useState(null);
  const foxus = useIsFocused();

  const ref = React.createRef();
  const [state, setstate] = useState({
    sizeDetail: "900X600",
    updatedAt: "2021-10-18T18:35:22.079Z",
    createdAt: "2021-10-18T18:35:22.079Z",
    imgRef: "1234567",
    courseInfo: {
      x: 100,
      y: 100,
      disabled: false,
    },
    teacherInfo: {
      x: 100,
      y: 100,
      disabled: false,
    },
    studentInfo: {
      x: 100,
      y: 100,
      disabled: false,
    },
    startEducationInfo: {
      x: 100,
      y: 100,
      disabled: false,
    },
    endEducationInfo: {
      x: 100,
      y: 100,
      disabled: false,
    },
    cssstyle: {
      fontFamily: "",
      fontSize: "",
      color: "#000",
    },
  });

  async function callApi() {
    const res = await apiservice({
      path: "/course/getcerbyid/" + route.params.id,
      method: "get",
      token: token?.accessToken,
    });
    if (res.status == 200) {
      setCourseName(res.data.courseInfo.disabled);
      setTeacherName(res.data.teacherInfo.disabled);
      setStudentName(res.data.studentInfo.disabled);
      setDateStart(res.data.startEducationInfo.disabled);
      setDateEnd(res.data.endEducationInfo.disabled);
      if (res.data.imgRef != "1234567") {
        setstate({
          ...res.data,
        });

        setImage(res.data.imgRef);
      } else {
        setstate({
          ...res.data,
        });
      }
    }
  }

  useEffect(() => {
    if (del && del1) {
      callApi();
    }
  }, [del, foxus, del1]);

  async function onPress() {
    if (state.imgRef != "") {
      delete state.id;
      const create = await authActionCreateCreatecert({
        state: {
          ...state,
        },
        token: token?.accessToken,
      });
      route.params.setcer(create.data.id);
      if (create) {
        navigation.goBack();
      }
    }
  }
  async function review() {
    if (state.imgRef != "") {
      delete state.id;
      const create = await authActionCreateCreatecert({
        state: {
          ...state,
        },
        token: token?.accessToken,
      });

      if (create) {
        window.open(
          `https://api.courseoncloud.com/api/image/examexport/${create.data.id}`
        );
      }
    }
  }
  let selectData = [
    <MaterialIcons name="format-align-left" size={18} color={color} />,
    <MaterialIcons name="format-align-center" size={18} color={color} />,
    <MaterialIcons name="format-align-right" size={18} color={color} />,
    <MaterialIcons name="format-align-justify" size={18} color={color} />,
  ];
  const choiceList = [
    {
      size: "900x600",
      img: (
        <View
          style={{
            width: 90,
            height: 60,
            borderWidth: 1,
            borderColor: "#E0E0E0",
            borderStyle: "dashed",
          }}
        />
      ),
    },
    {
      size: "600x900",
      img: (
        <View
          style={{
            width: 60,
            height: 90,
            borderWidth: 1,
            borderColor: "#E0E0E0",
            borderStyle: "dashed",
          }}
        />
      ),
    },
  ];

  const placeholder = {
    label: "",
    value: null,
    color: "#FCBDA6",
  };

  const [font, setFont] = useState([{ label: "Prompt", value: "Prompt" }]);
  const [selectColor, setColor] = useState("#000");
  const [size, setSize] = useState([
    { label: "Font1", value: "Font1" },
    { label: "Font2", value: "Font2" },
  ]);
  const choiceChangeHandler = (index, item) => {
    setstate({
      ...state,
      sizeDetail: item,
    });

    setChoiceIndex((preIndex) => index);
  };

  async function pickup() {
    let result = await DocumentPicker.getDocumentAsync({ type: "image/*" });

    const response = await apiservice({
      path: "/image/createpdf",
      method: "post",
      body: {
        name: result.name.replace(".png", ""),
        base64: result.uri,
      },
    });

    setImage(
      "https://api.courseoncloud.com/api/image/getimage/" +
        response.data.imageRefId.replace(".png", "")
    );

    setstate((val) => ({
      ...val,
      imgRef:
        "https://api.courseoncloud.com/api/image/getimage/" +
        response.data.imageRefId.replace(".png", ""),
    }));
  }
  const MAX_VAL = 99;
  const withValueLimit = (inputObj) => {
    const { value } = inputObj;
    if (value <= MAX_VAL) return true;
    return setstate({
      ...state,
      cssstyle: { ...state.cssstyle, fontSize: 99 },
    });
  };
  const responsive = useWindowSize();

  return (
    <View style={styles.container}>
      <ScrollView stickyHeaderIndices={[0]}>
        <Header navigation={navigation} />
        <View
          style={{
            width: "95%",
            alignSelf: "center",
            flexDirection: responsive.width < 1280 ? "column" : "row",
            paddingTop: responsive.width < 1280 ? 20 : height * 0.1,
            paddingHorizontal: responsive.width < 1280 && 20,
          }}
        >
          <View
            onLayout={(e) => {
              setDel1({
                width: e.nativeEvent.layout.width,
                height: e.nativeEvent.layout.height,
                x: e.nativeEvent.layout.x,
                y: e.nativeEvent.layout.y,
              });
            }}
            style={{
              width: responsive.width < 1280 ? "100%" : "15%",
            }}
          >
            <Text
              style={{ fontSize: 20, fontFamily: "Prompt", color: "#0B2051" }}
            >
              เลือกรูปแบบใบประกาศ
            </Text>
            <FlatList
              data={choiceList}
              numColumns={1}
              renderItem={({ index, item }) => {
                return (
                  <View style={{ marginTop: 15 }}>
                    <TouchableOpacity
                      key={item}
                      style={{
                        alignItems: "center",
                        flexDirection: "row",
                        justifyContent:
                          responsive.width < 1280
                            ? "flex-start"
                            : "space-between",
                      }}
                      onPress={choiceChangeHandler.bind(this, index, item.size)}
                    >
                      <MaterialIcons
                        name={
                          index === choiceIndex
                            ? "radio-button-checked"
                            : "radio-button-unchecked"
                        }
                        size={15}
                        color="#0D88F0"
                      />
                      {item.img}
                      <Text
                        style={{
                          fontSize: 16,
                          fontFamily: "Prompt",
                          color: "#0B2051",
                          paddingLeft: responsive.width < 1280 && 15,
                        }}
                      >
                        {item.size}
                      </Text>
                    </TouchableOpacity>
                  </View>
                );
              }}
            />
          </View>
          <View
            style={{
              width: responsive.width < 1280 ? "100%" : "65%",
              height: responsive.width < 1280 ? height / 3 : height,
              alignItems: "center",
              marginVertical: responsive.width < 1280 && 20,
            }}
          >
            <View
              onLayout={(e) => {
                setDel({
                  width: e.nativeEvent.layout.width,
                  height: e.nativeEvent.layout.height,
                  x: e.nativeEvent.layout.x,
                  y: e.nativeEvent.layout.y,
                });
              }}
              style={{
                width:
                  responsive.width < 1280
                    ? choiceIndex == 0
                      ? "100%"
                      : "70%"
                    : choiceIndex == 0
                    ? 900
                    : "50%",
                height:
                  responsive.width < 1280
                    ? choiceIndex == 0
                      ? "80%"
                      : "100%"
                    : choiceIndex == 0
                    ? 600
                    : "60%",
                borderWidth: 1,
                borderColor: "#E0E0E0",
                borderStyle: "dashed",
                alignItems: "center",
                justifyContent: "center",
                overflow: "hidden",
              }}
            >
              {image == "" && (
                <TouchableOpacity
                  style={{ position: "absolute", zIndex: 99 }}
                  onPress={pickup}
                >
                  <Text
                    style={{
                      fontSize: 18,
                      color: "#D1D1D1",
                      fontFamily: "Prompt",
                    }}
                  >
                    Upload your Certificate ..
                  </Text>
                </TouchableOpacity>
              )}
              {/* {image.length > 0 && (
                <Image
                  resizeMode="stretch"
                  source={{ uri: image }}
                  style={{
                    width: "100%",
                    height: "100%",
                    position: "absolute",
                  }}
                />
              )} */}
              <View ref={ref} style={{ width: 900, height: 600 }}>
                {image.length > 0 && (
                  <Image
                    resizeMode="stretch"
                    source={{ uri: image }}
                    style={{
                      width: "100%",
                      height: "100%",
                      position: "absolute",
                    }}
                  />
                )}
                <Stage width={900} height={600}>
                  <Layer>
                    {image.length > 0 && courseName && (
                      <Template
                        text={route?.params?.title}
                        x={state.courseInfo.x}
                        y={state.courseInfo.y}
                        align="center"
                        fontSize={state?.cssstyle?.fontSize || 12}
                        draggable
                        onDragEnd={(e) => {
                          setstate((val) => ({
                            ...val,
                            courseInfo: {
                              ...val.courseInfo,
                              x: e.target.x(),
                              y: e.target.y(),
                            },
                          }));
                        }}
                      />
                    )}
                    {image.length > 0 && TeacherName && (
                      <Template
                        text={route?.params?.teacherName}
                        x={state.teacherInfo.x}
                        y={state.teacherInfo.y}
                        fontSize={state?.cssstyle?.fontSize || 12}
                        draggable
                        onDragEnd={(e) => {
                          setstate((val) => ({
                            ...val,
                            teacherInfo: {
                              ...val.teacherInfo,
                              x: e.target.x(),
                              y: e.target.y(),
                            },
                          }));
                        }}
                      />
                    )}
                    {image.length > 0 && StudentName && (
                      <Template
                        text="ชื่อผู้เรียน นามสกุลผู้เรียน"
                        x={state.studentInfo.x}
                        y={state.studentInfo.y}
                        fontSize={state?.cssstyle?.fontSize || 12}
                        draggable
                        onDragEnd={(e) => {
                          setstate((val) => ({
                            ...val,
                            studentInfo: {
                              ...val.studentInfo,
                              x: e.target.x(),
                              y: e.target.y(),
                            },
                          }));
                        }}
                      />
                    )}
                    {image.length > 0 && dateStart && (
                      <Template
                        text="วันที่เริ่มเรียน"
                        x={state.startEducationInfo.x}
                        y={state.startEducationInfo.y}
                        fontSize={state?.cssstyle?.fontSize || 12}
                        draggable
                        onDragEnd={(e) => {
                          setstate((val) => ({
                            ...val,
                            startEducationInfo: {
                              ...val.startEducationInfo,
                              x: e.target.x(),
                              y: e.target.y(),
                            },
                          }));
                        }}
                      />
                    )}
                    {image.length > 0 && dateEnd && (
                      <Template
                        text="วันที่เรียนจบ"
                        x={state.endEducationInfo.x}
                        y={state.endEducationInfo.y}
                        fontSize={state?.cssstyle?.fontSize || 12}
                        draggable
                        onDragEnd={(e) => {
                          setstate((val) => ({
                            ...val,
                            endEducationInfo: {
                              ...val.endEducationInfo,
                              x: e.target.x(),
                              y: e.target.y(),
                            },
                          }));
                        }}
                      />
                    )}
                  </Layer>
                </Stage>
              </View>
              {/* {image.length > 0 && courseName && (
                <Draggable
                  defaultPosition={{
                    x: state.courseInfo.x,
                    y: state.courseInfo.y,
                  }}
                  onDrag={(e) => {
                    setstate((val) => ({
                      ...val,
                      courseInfo: { x: e.x, y: e.y },
                    }));
                  }}
                >
                  <Text style={styles.textButtonAdd}>{"ชื่อคอร์ส"}</Text>
                </Draggable>
              )}
              {image.length > 0 && TeacherName && (
                <Draggable
                  defaultPosition={{
                    x: state.teacherInfo.x,
                    y: state.teacherInfo.y,
                  }}
                  onDrag={(e) => {
                    setstate((val) => ({
                      ...val,
                      teacherInfo: { x: e.x, y: e.y },
                    }));
                  }}
                >
                  <Text style={styles.textButtonAdd}>{"ชื่อผู้สอน"}</Text>
                </Draggable>
              )}
              {image.length > 0 && StudentName && (
                <Draggable
                  defaultPosition={{
                    x: state.studentInfo.x,
                    y: state.studentInfo.y,
                  }}
                  onDrag={(e) => {
                    setstate((val) => ({
                      ...val,
                      studentInfo: { x: e.x, y: e.y },
                    }));
                  }}
                >
                  <Text style={styles.textButtonAdd}>{"ชื่อผู้เรียน"}</Text>
                </Draggable>
              )}
              {image.length > 0 && dateStart && (
                <Draggable
                  defaultPosition={{
                    x: state.startEducationInfo.x,
                    y: state.startEducationInfo.y,
                  }}
                  onDrag={(e) => {
                    setstate((val) => ({
                      ...val,
                      startEducationInfo: { x: e.x, y: e.y },
                    }));
                  }}
                >
                  <Text style={styles.textButtonAdd}>{"วันที่เริ่มเรียน"}</Text>
                </Draggable>
              )}
              {image.length > 0 && dateEnd && (
                <Draggable
                  defaultPosition={{
                    x: state.endEducationInfo.x,
                    y: state.endEducationInfo.y,
                  }}
                  onDrag={(e) => {
                    setstate((val) => ({
                      ...val,
                      endEducationInfo: { x: e.x, y: e.y },
                    }));
                  }}
                >
                  <Text style={styles.textButtonAdd}>{"วันที่เรียนจบ"}</Text>
                </Draggable>
              )} */}
            </View>
            {responsive.width >= 1280 && (
              <View style={{ flexDirection: "row", marginVertical: 50 }}>
                <TouchableOpacity
                  onPress={onPress}
                  style={{
                    width: 200,
                    height: 60,
                    backgroundColor: "#0D88F0",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: 5,
                  }}
                >
                  <Text
                    style={{
                      fontSize: 30,
                      fontFamily: "Prompt",
                      color: "#FFFFFF",
                    }}
                  >
                    ยืนยัน
                  </Text>
                </TouchableOpacity>
                <TouchableOpacity
                  onPress={() => {
                    navigation.goBack();
                  }}
                  style={{
                    width: 200,
                    height: 60,
                    borderWidth: 1,
                    borderColor: "#0D88F0",
                    justifyContent: "center",
                    alignItems: "center",
                    marginLeft: 65,
                    borderRadius: 5,
                  }}
                >
                  <Text
                    style={{
                      fontSize: 30,
                      fontFamily: "Prompt",
                      color: "#0D88F0",
                    }}
                  >
                    ยกเลิก
                  </Text>
                </TouchableOpacity>
              </View>
            )}
          </View>
          <View
            style={{
              width: responsive.width < 1280 ? "100%" : "17%",
            }}
          >
            <View>
              <Text
                style={{
                  fontSize: 20,
                  fontFamily: "Prompt",
                  color: "#0B2051",
                }}
              >
                เลือกข้อมูล
              </Text>
              <TouchableOpacity
                disabled={image.length > 0 ? false : true}
                onPress={() => {
                  setCourseName((val) => !val);
                  setstate((val) => ({
                    ...val,
                    courseInfo: {
                      ...val.courseInfo,
                      disabled: !val.courseInfo.disabled,
                    },
                  }));
                }}
                style={styles.buttonAdd}
              >
                <MaterialIcons
                  name={
                    courseName && image.length > 0
                      ? "remove-circle"
                      : "add-circle"
                  }
                  size={15}
                  color={courseName && image.length > 0 ? "#DA0000" : "#0B2051"}
                />
                <Text style={styles.textButtonAdd}>ชื่อคอร์ส</Text>
              </TouchableOpacity>
              <TouchableOpacity
                disabled={image.length > 0 ? false : true}
                onPress={() => {
                  setTeacherName((val) => !val);
                  setstate((val) => ({
                    ...val,
                    teacherInfo: {
                      ...val.teacherInfo,
                      disabled: !val.teacherInfo.disabled,
                    },
                  }));
                }}
                style={styles.buttonAdd}
              >
                <MaterialIcons
                  name={
                    TeacherName && image.length > 0
                      ? "remove-circle"
                      : "add-circle"
                  }
                  size={15}
                  color={
                    TeacherName && image.length > 0 ? "#DA0000" : "#0B2051"
                  }
                />
                <Text style={styles.textButtonAdd}>ชื่อผู้สอน</Text>
              </TouchableOpacity>
              <TouchableOpacity
                disabled={image.length > 0 ? false : true}
                onPress={() => {
                  setStudentName((val) => !val);
                  setstate((val) => ({
                    ...val,
                    studentInfo: {
                      ...val.studentInfo,
                      disabled: !val.studentInfo.disabled,
                    },
                  }));
                }}
                style={styles.buttonAdd}
              >
                <MaterialIcons
                  name={
                    StudentName && image.length > 0
                      ? "remove-circle"
                      : "add-circle"
                  }
                  size={15}
                  color={
                    StudentName && image.length > 0 ? "#DA0000" : "#0B2051"
                  }
                />
                <Text style={styles.textButtonAdd}>ชื่อผู้เรียน</Text>
              </TouchableOpacity>
              <TouchableOpacity
                disabled={image.length > 0 ? false : true}
                onPress={() => {
                  setDateStart((val) => !val);
                  setstate((val) => ({
                    ...val,
                    startEducationInfo: {
                      ...val.startEducationInfo,
                      disabled: !val.startEducationInfo.disabled,
                    },
                  }));
                }}
                style={styles.buttonAdd}
              >
                <MaterialIcons
                  name={
                    dateStart && image.length > 0
                      ? "remove-circle"
                      : "add-circle"
                  }
                  size={15}
                  color={dateStart && image.length > 0 ? "#DA0000" : "#0B2051"}
                />
                <Text style={styles.textButtonAdd}>วันที่เริ่มเรียน</Text>
              </TouchableOpacity>
              <TouchableOpacity
                disabled={image.length > 0 ? false : true}
                onPress={() => {
                  setDateEnd((val) => !val);
                  setstate((val) => ({
                    ...val,
                    endEducationInfo: {
                      ...val.endEducationInfo,
                      disabled: !val.endEducationInfo.disabled,
                    },
                  }));
                }}
                style={styles.buttonAdd}
              >
                <MaterialIcons
                  name={
                    dateEnd && image.length > 0 ? "remove-circle" : "add-circle"
                  }
                  size={15}
                  color={dateEnd && image.length > 0 ? "#DA0000" : "#0B2051"}
                />
                <Text style={styles.textButtonAdd}>วันที่เรียนจบ</Text>
              </TouchableOpacity>
            </View>
            <View>
              <Text style={[styles.text16, { marginTop: 20 }]}>ข้อความ</Text>

              <View
                style={{
                  width: "100%",
                  maxWidth: 335,

                  borderWidth: 1,
                  borderColor: "#E0E0E0",
                  padding: 19,
                }}
              >
                {/* <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Text style={[styles.text16]}>Front</Text>
                  <RNPickerSelect
                    placeholder={placeholder}
                    onValueChange={(val) => {
                      setstate({
                        ...state,
                        cssstyle: { ...state.cssstyle, fontFamily: val },
                      });
                    }}
                    items={font}
                    style={{
                      inputWeb: {
                        width:
                          responsive.width < 500 ? width * 0.5 : width * 0.1,
                        height: 30,
                        borderTopWidth: 0,
                        borderLeftWidth: 0,
                        borderRightWidth: 0,
                        borderColor: "#FF5A22",
                        backgroundColor: "#FFFFFF",
                        color: "#FF5A22",
                        fontFamily: "Prompt",
                        fontSize: responsive.width < 1280 ? 12 : 16,
                        textAlign: "center",
                      },
                    }}
                  />
                </View> */}
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",

                    width: "100%",
                  }}
                >
                  <Text style={[styles.text16]}>Size</Text>
                  <View
                    style={{
                      width: "80%",
                      height: 30,
                      borderBottomWidth: 1,
                      borderBottomColor: "#FF5A22",
                      // color: "#FF5A22",
                      // fontFamily: "Prompt",
                      // fontSize: responsive.width < 1280 ? 12 : 16,
                      // textAlign: "center",
                    }}
                  >
                    <NumberFormat
                      // value={state.cssstyle.fontSize}
                      isAllowed={withValueLimit}
                      onValueChange={(value) => {
                        setstate({
                          ...state,
                          cssstyle: {
                            ...state.cssstyle,
                            fontSize: value.floatValue,
                          },
                        });
                      }}
                      style={{
                        borderWidth: 0,
                        height: 30,
                        fontFamily: "Prompt",
                        paddingHorizontal: 15,
                        fontSize: 18,
                        color: "#0D88F0",
                        paddingLeft: 15,
                        borderBottomWidth: 1,
                        borderBottomColor: "#FF5A22",
                      }}
                    />
                  </View>

                  {/* <View style={{ width: "20%" }}>
                    <TouchableOpacity
                      onPress={() => {
                        setOpenColor(true);
                      }}
                      style={{
                        width: "100%",
                        height: 30,
                        borderRadius: 10,
                        backgroundColor: selectColor,
                      }}
                    ></TouchableOpacity>
                  </View> */}
                </View>
                {openColor && (
                  <View style={{ width: "100%", marginTop: 20 }}>
                    <TouchableOpacity
                      onPress={() => {
                        setOpenColor(false);
                      }}
                      style={{
                        position: "fixed",
                        top: "0px",
                        right: "0px",
                        bottom: "0px",
                        left: "0px",
                      }}
                    />
                    <SketchPicker
                      color={selectColor}
                      onChange={(val) => {
                        setColor(val.hex);
                        setstate({
                          ...state,
                          cssstyle: { ...state.cssstyle, color: val.hex },
                        });
                      }}
                    />
                  </View>
                )}
              </View>
            </View>

            <TouchableOpacity
              onPress={() => {
                exportComponentAsPNG(ref);
                // review()
              }}
              style={{
                width: 140,
                height: 40,
                backgroundColor: "#0D88F0",
                borderRadius: 5,
                justifyContent: "center",
                alignItems: "center",
                marginTop: 20,
              }}
            >
              <Text
                style={{ fontFamily: "Prompt", fontSize: 20, color: "#FFFFFF" }}
              >
                ดูตัวอย่าง
              </Text>
            </TouchableOpacity>
            {responsive.width < 1280 && (
              <View
                style={{
                  flexDirection: "row",
                  marginVertical: 50,
                  alignSelf: "center",
                }}
              >
                <TouchableOpacity
                  onPress={onPress}
                  style={{
                    width: responsive.width < 1920 ? 100 : 200,
                    height: responsive.width < 1920 ? 40 : 60,
                    backgroundColor: "#0D88F0",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: 5,
                  }}
                >
                  <Text
                    style={{
                      fontSize: responsive.width < 1920 ? 20 : 30,
                      fontFamily: "Prompt",
                      color: "#FFFFFF",
                    }}
                  >
                    ยืนยัน
                  </Text>
                </TouchableOpacity>
                <TouchableOpacity
                  onPress={() => {
                    navigation.goBack();
                  }}
                  style={{
                    width: responsive.width < 1920 ? 100 : 200,
                    height: responsive.width < 1920 ? 40 : 60,
                    borderWidth: 1,
                    borderColor: "#0D88F0",
                    justifyContent: "center",
                    alignItems: "center",
                    marginLeft: responsive.width < 1920 ? 30 : 65,
                    borderRadius: 5,
                  }}
                >
                  <Text
                    style={{
                      fontSize: responsive.width < 1920 ? 20 : 30,
                      fontFamily: "Prompt",
                      color: "#0D88F0",
                    }}
                  >
                    ยกเลิก
                  </Text>
                </TouchableOpacity>
              </View>
            )}
          </View>
        </View>
        <BottomPart navigation={navigation} />
      </ScrollView>
    </View>
  );
}
const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: "100%",
    backgroundColor: "#FFFFFF",
  },
  textButtonAdd: {
    color: "#0B2051",
    fontFamily: "Prompt",
    fontSize: 16,
    marginLeft: 20,
  },
  buttonAdd: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 10,
  },
  text16: {
    fontSize: 16,
    fontFamily: "Prompt",
    color: "#0B2051",
  },
});
const stylesResponsive = StyleSheet.create({
  text12: {
    fontSize: 12,
    fontFamily: "Prompt",
    color: "#0B2051",
  },
});
