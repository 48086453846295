import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  Image,
  Dimensions,
  Animated,
  TouchableOpacity,
  StyleSheet,
  ScrollView,
  TextInput,
  FlatList,
} from "react-native";
import {
  MaterialCommunityIcons,
  MaterialIcons,
  Feather,
  Ionicons,
} from "@expo/vector-icons";
import RNPickerSelect from "react-native-picker-select";
import Modal from "modal-react-native-web";
import { authActionUniverscitybanner } from "../../../action/authAction";
import { useRecoilState, useRecoilValue } from "recoil";
import { tokenState } from "../../../reducer/recoil";
import { useIsFocused } from "@react-navigation/native";
import { apiservice } from "../../../service";
import moment from "moment";
import * as DocumentPicker from "expo-document-picker";
import { add } from "react-native-reanimated";
import { useWindowSize } from "../../components/responsive";
const { width, height } = Dimensions.get("window");
export default function information({
  ongoBack,
  university_name,
  university_id,
  major,
  token,
}) {
  // const token = useRecoilValue(tokenState);
  const [image, setImage] = useState();
  const [data, setData] = useState({});
  const [state, setstate] = useState();
  const size = useWindowSize();
  const [description, setDescription] = useState(false);
  const [body, setBody] = useState({
    university_id: university_id,
    description: "",
  });

  async function pickup() {
    let result = await DocumentPicker.getDocumentAsync({
      type: "image/*",
    });

    const response = await apiservice({
      path: "/image/createpdf",
      method: "post",
      body: {
        name: result.name.replace(".png", ""),
        base64: result.uri,
      },
    });

    const create = await authActionUniverscitybanner({
      state: {
        ...state,
        img_header: response.data.imageRefId.replace(".png", ""),
      },
      token: token?.accessToken,
    });

    setData((val) => ({
      ...val,
      img_header: response.data.imageRefId.replace(".png", ""),
    }));

    setImage(response.data.imageRefId.replace(".png", ""));
    getUniversityBanner(token);
  }

  async function pickupCover() {
    let result = await DocumentPicker.getDocumentAsync({
      type: "image/*",
    });
    // setImage(result.uri)
    const response = await apiservice({
      path: "/image/createpdf",
      method: "post",
      body: {
        name: result.name.replace(".png", ""),
        base64: result.uri,
      },
    });
    const create = await authActionUniverscitybanner({
      state: {
        ...state,
        img_cover: response.data.imageRefId.replace(".png", ""),
      },
      token: token?.accessToken,
    });

    setImage(response.data.imageRefId.replace(".png", ""));
    getUniversityBanner(token);
  }

  async function pickupOne() {
    let result = await DocumentPicker.getDocumentAsync({
      type: "image/*",
    });
    // setImage(result.uri)
    const response = await apiservice({
      path: "/image/createpdf",
      method: "post",
      body: {
        name: result.name.replace(".png", ""),
        base64: result.uri,
      },
    });
    const create = await authActionUniverscitybanner({
      state: {
        ...state,
        one: response.data.imageRefId.replace(".png", ""),
      },
      token: token?.accessToken,
    });

    setImage(response.data.imageRefId.replace(".png", ""));
    getUniversityBanner(token);
  }
  async function pickupTwo() {
    let result = await DocumentPicker.getDocumentAsync({
      type: "image/*",
    });
    // setImage(result.uri)
    const response = await apiservice({
      path: "/image/createpdf",
      method: "post",
      body: {
        name: result.name.replace(".png", ""),
        base64: result.uri,
      },
    });
    const create = await authActionUniverscitybanner({
      state: {
        ...state,
        two: response.data.imageRefId.replace(".png", ""),
      },
      token: token?.accessToken,
    });

    setImage(response.data.imageRefId.replace(".png", ""));
    getUniversityBanner(token);
  }
  async function pickupThree() {
    let result = await DocumentPicker.getDocumentAsync({
      type: "image/*",
    });
    // setImage(result.uri)
    const response = await apiservice({
      path: "/image/createpdf",
      method: "post",
      body: {
        name: result.name.replace(".png", ""),
        base64: result.uri,
      },
    });
    const create = await authActionUniverscitybanner({
      state: {
        ...state,
        three: response.data.imageRefId.replace(".png", ""),
      },
      token: token?.accessToken,
    });

    setImage(response.data.imageRefId.replace(".png", ""));
    getUniversityBanner(token);
  }

  useEffect(() => {
    getUniversityBanner(token);
  }, [token]);

  async function getUniversityBanner(token) {
    try {
      const response = await apiservice({
        path: "/banner/getunivercitybanner/" + university_id,
        method: "get",
        token: token?.accessToken,
      });
      if (response.status == 200) {
        setstate({ ...response.data, university_id: university_id });
        setData(response.data);
      }
    } catch (error) {
      throw error;
    }
  }
  async function save() {
    const save = await authActionUniverscitybanner({
      state: body,
      token: token?.accessToken,
    });
    if (save) {
      setDescription(false);
    }
  }

  return (
    <View style={{ paddingBottom: height * 0.1 }}>
      <View style={{ flexDirection: "row", marginTop: 10 }}>
        <TouchableOpacity onPress={ongoBack}>
          <Text style={styles.text23}>คอร์สมหาวิทยาลัย</Text>
        </TouchableOpacity>
        <Text
          style={[
            styles.text23,
            {
              marginHorizontal: 18,
            },
          ]}
        >
          /
        </Text>
        <Text style={[styles.text23, { color: "#0D88F0" }]}>ข้อมูล</Text>
      </View>
      <Text
        style={{
          fontSize: 25,
          fontFamily: "Prompt",
          color: "#444444",
          marginVertical: 60,
        }}
      >
        {university_name}
      </Text>
      <View style={styles.viewAddImgMain}>
        {data != null && (
          <Image
            resizeMode={"stretch"}
            source={{
              uri:
                "https://api.courseoncloud.com/api/image/getimage/" +
                data.img_header,
            }}
            style={{
              width: "100%",
              height: 277,
            }}
          />
        )}
        <View style={{ position: "absolute", alignSelf: "center" }}>
          <TouchableOpacity onPress={pickup} style={{ alignSelf: "center" }}>
            <MaterialCommunityIcons
              name="plus-circle-outline"
              size={105}
              color="#E5E5E5"
            />
          </TouchableOpacity>
          <Text style={styles.text22}>ใส่รูป Header</Text>
        </View>
      </View>
      <View
        style={{
          flexDirection: size.width < 500 ? "column" : "row",
          justifyContent: "space-between",
          marginVertical: height * 0.08,
        }}
      >
        <View
          style={{
            width: size.width < 500 ? "100%" : "65%",
            justifyContent: "flex-end",
          }}
        >
          <TextInput
            autoComplete="off"
            onChangeText={(text) => {
              if (text.length + 1) {
                setDescription(true);
              }
              setBody({ ...body, description: text });
            }}
            defaultValue={data == null ? "" : data.description}
            placeholder={"กรอกรายละเอียด"}
            placeholderTextColor="#808D93"
            multiline
            style={styles.inputDetail}
          />
          <TouchableOpacity
            disabled={description ? false : true}
            onPress={save}
            style={{
              width: 100,
              height: 40,
              backgroundColor: description ? "#0D88F0" : "#CCCCCC",
              borderRadius: 5,
              position: "absolute",
              alignSelf: "flex-end",
              justifyContent: "center",
              alignItems: "center",
              margin: 20,
            }}
          >
            <Text
              style={{ fontSize: 14, fontFamily: "Prompt", color: "#FFFFFF" }}
            >
              บันทึก
            </Text>
          </TouchableOpacity>
        </View>
        <View
          style={{
            width: size.width < 500 ? "100%" : "30%",
            marginTop: size.width < 500 && 20,
          }}
        >
          <View style={styles.viewAddImg}>
            {data != null && (
              <Image
                source={{
                  uri:
                    "https://api.courseoncloud.com/api/image/getimage/" +
                    data.img_cover,
                }}
                style={[styles.imgAdd]}
              />
            )}
            <View style={{ position: "absolute", alignSelf: "center" }}>
              <TouchableOpacity
                onPress={pickupCover}
                style={{ alignSelf: "center" }}
              >
                <MaterialCommunityIcons
                  name="plus-circle-outline"
                  size={105}
                  color="#E5E5E5"
                />
              </TouchableOpacity>
              <Text style={styles.text22}>ใส่รูปปก</Text>
            </View>
          </View>
        </View>
      </View>
      <View
        style={{
          flexDirection: size.width < 500 ? "column" : "row",
          justifyContent: "space-between",
        }}
      >
        {major[0] == 0 && (
          <View style={{ width: size.width < 500 ? "100%" : "30%" }}>
            <Text style={styles.text23}>ปริญญาตรี</Text>
            <View style={styles.viewAddImg}>
              {data != null && (
                <Image
                  source={{
                    uri:
                      "https://api.courseoncloud.com/api/image/getimage/" +
                      data.one,
                  }}
                  style={styles.imgAdd}
                />
              )}
              <View style={{ position: "absolute", alignSelf: "center" }}>
                <TouchableOpacity onPress={pickupOne} style={{}}>
                  <MaterialCommunityIcons
                    name="plus-circle-outline"
                    size={105}
                    color="#E5E5E5"
                  />
                </TouchableOpacity>
                <Text style={styles.text22}>ใส่รูปปก</Text>
              </View>
            </View>
          </View>
        )}
        {major[1] == 1 && (
          <View
            style={{
              width: size.width < 500 ? "100%" : "30%",
              marginTop: size.width < 500 && 20,
            }}
          >
            <Text style={styles.text23}>ปริญญาโท</Text>
            <View style={styles.viewAddImg}>
              {data != null && (
                <Image
                  source={{
                    uri:
                      "https://api.courseoncloud.com/api/image/getimage/" +
                      data.two,
                  }}
                  style={styles.imgAdd}
                />
              )}
              <View style={{ position: "absolute", alignSelf: "center" }}>
                <TouchableOpacity onPress={pickupTwo} style={{}}>
                  <MaterialCommunityIcons
                    name="plus-circle-outline"
                    size={105}
                    color="#E5E5E5"
                  />
                </TouchableOpacity>
                <Text style={styles.text22}>ใส่รูปปก</Text>
              </View>
            </View>
          </View>
        )}
        {major[2] == 2 && (
          <View
            style={{
              width: size.width < 500 ? "100%" : "30%",
              marginTop: size.width < 500 && 20,
            }}
          >
            <Text style={styles.text23}>ปริญญาเอก</Text>
            <View style={styles.viewAddImg}>
              {data != null && (
                <Image
                  source={{
                    uri:
                      "https://api.courseoncloud.com/api/image/getimage/" +
                      data.three,
                  }}
                  style={styles.imgAdd}
                />
              )}
              <View style={{ position: "absolute", alignSelf: "center" }}>
                <TouchableOpacity onPress={pickupThree} style={{}}>
                  <MaterialCommunityIcons
                    name="plus-circle-outline"
                    size={105}
                    color="#E5E5E5"
                  />
                </TouchableOpacity>
                <Text style={styles.text22}>ใส่รูปปก</Text>
              </View>
            </View>
          </View>
        )}
      </View>
    </View>
  );
}
const styles = StyleSheet.create({
  text23: {
    fontSize: 23,
    fontFamily: "Prompt",
    color: "#808D93",
  },
  text22: {
    fontSize: 22,
    fontFamily: "Prompt",
    color: "#444444",
    textAlign: "center",
  },
  viewAddImg: {
    width: "100%",
    maxWidth: 474,
    height: 277,
    borderWidth: 1,
    borderColor: "#E5E5E5",
    justifyContent: "center",
  },
  imgAdd: {
    width: "100%",
    maxWidth: 474,
    height: 277,
  },
  viewAddImgMain: {
    width: "100%",
    height: 277,
    borderWidth: 1,
    borderColor: "#E5E5E5",
    justifyContent: "center",
  },
  inputDetail: {
    width: "100%",
    maxWidth: 1007,
    height: 277,
    borderWidth: 1,
    borderColor: "#E5E5E5",
    paddingHorizontal: 20,
    paddingVertical: 35,
    fontSize: 25,
    fontFamily: "Prompt",
    color: "#808D93",
    textAlignVertical: "top",
  },
});
