import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  Image,
  Dimensions,
  Animated,
  TouchableOpacity,
  StyleSheet,
  ScrollView,
  TextInput,
  FlatList,
} from "react-native";
import {
  MaterialCommunityIcons,
  FontAwesome,
  FontAwesome5,
  MaterialIcons,
  AntDesign,
} from "@expo/vector-icons";
import { useRecoilState } from "recoil";
import { courseState, tokenState } from "../../reducer/recoil";
import { apiservice } from "../../service";
import { useWindowSize } from "../components/responsive";
import NumberFormat from "react-number-format";
import Modal from "modal-react-native-web";
import { deleteCourse } from "../../action/authAction";
import { useIsFocused } from "@react-navigation/native";
const { width, height } = Dimensions.get("window");
export default function teacherCourse({ navigation, hide, data }) {
  const [token, setToken] = useRecoilState(tokenState);
  const [CourseStates, setCourseStates] = useRecoilState(courseState);
  const [id, setid] = useState();
  const [user, setUser] = useState();
  const [modalDelItem, setModalDelItem] = useState(false);
  const [paginations, setPaginations] = useState([]);
  const [page, setpage] = useState(1);
  const [total_page, setTotalpage] = useState(0);
  const [course, setCourse] = useState([]);
  const focus = useIsFocused();

  useEffect(() => {
    if (token) {
      getCourseId();
    }
  }, [token, focus]);

  async function getCourseId(params) {
    try {
      const usel = await apiservice({
        path: "/authen/user",
        method: "get",
        token: token?.accessToken,
      });

      if (usel.status == 200) {
        const response = await apiservice({
          path:
            "/course/getcouse?id=" +
            usel.data.result.user_accounts.uid +
            "&totalpage=1000&page=1",
          method: "get",
          token: token?.accessToken,
        });

        if (response.status == 200) {
          let pagination = [];
          for (let index = 0; index < response.data.total_page; index++) {
            pagination = pagination.concat({});
          }
          setUser(response.data.result.user);
          setCourse(data ? data : response.data.result);
          setTotalpage(response.data.totalpage);
          setPaginations(pagination);
        }
      } else {
      }
    } catch (error) {
      throw error;
    }
  }
  const size = useWindowSize();
  async function Delete() {
    const Delete = await deleteCourse({
      token: token?.accessToken,
      id: id,
    });
  }
  return (
    <View style={{ paddingHorizontal: 50, minHeight: 1070 }}>
      {hide != true && (
        <TouchableOpacity
          onPress={async () => {
            const response = await apiservice({
              path: "/authen/user",
              method: "get",
              token: token?.accessToken,
            });

            navigation.navigate("AddCourse", {
              ...response.data.result.user_accounts,
            });
          }}
          style={{
            width: "100%",
            maxWidth: size.width < 750 ? 250 : 331,
            height: 75,
            backgroundColor: "#0D88F0",
            borderRadius: 5,
            justifyContent: "center",
            alignItems: "center",
            alignSelf: "center",
            marginTop: 56,
            marginBottom: 31,
          }}
        >
          <Text
            style={{
              fontSize: 20,
              fontFamily: "Prompt",
              color: "#FFFFFF",
            }}
          >
            สร้างคอร์สเรียน
          </Text>
        </TouchableOpacity>
      )}
      <View style={{ alignItems: course.length < 4 ? "flex-start" : "center" }}>
        <FlatList
          data={data ? data : course}
          numColumns={
            (width > 1850 && 4) ||
            (width < 1850 && width >= 1280 && 3) ||
            (width < 1280 && width >= 750 && 2) ||
            (width < 750 && 1)
          }
          scrollEnabled={false}
          renderItem={({ index, item }) => {
            return (
              <TouchableOpacity
                disabled={item.available == "PENDING" ? true : false}
                onPress={() => {
                  setCourseStates(item);
                  setTimeout(() => {
                    navigation.navigate("Course");
                  }, 900);
                }}
                style={[
                  styles.viewDetail,
                  {
                    marginLeft: size.width < 750 ? 0 : 25,
                    marginTop: 25,
                    marginBottom: index > 8 && 65,
                    width: size.width < 1600 ? 240 : 278,
                    borderWidth: 1,
                    borderColor: "#BBB",
                  },
                ]}
              >
                {hide != true && (
                  <View
                    style={{
                      position: "absolute",
                      zIndex: 99,
                      alignSelf: "flex-end",
                      padding: 10,
                      flexDirection: "row",
                    }}
                  >
                    {item.available == "APPROVE" ||
                    item.available == "DRAFT" ||
                    item.available == "REJECT" ? (
                      <TouchableOpacity
                        onPress={async () => {
                          const response = await apiservice({
                            path: "/authen/user",
                            method: "get",
                            token: token?.accessToken,
                          });
                          setTimeout(() => {
                            navigation.navigate(
                              "AddCourse",
                              {
                                ...response.data.result.user_accounts,
                                editInfo: item,
                              },
                              500
                            );
                          });
                        }}
                        style={{
                          width: 40,
                          height: 40,
                          borderWidth: 1,
                          borderColor: "#787878",
                          backgroundColor: "#000000bb",
                          borderRadius: 20,
                          justifyContent: "center",
                          alignItems: "center",
                          marginRight: 5,
                        }}
                      >
                        <AntDesign name="edit" size={24} color="#FFFFFF" />
                      </TouchableOpacity>
                    ) : (
                      ""
                    )}
                    {(item.available == "DRAFT" ||
                      item.available == "REJECT") && (
                      <TouchableOpacity
                        onPress={() => {
                          setid(item.id);
                          setModalDelItem(true);
                        }}
                        style={{
                          width: 40,
                          height: 40,
                          borderWidth: 1,
                          borderColor: "#787878",
                          backgroundColor: "#000000bb",
                          borderRadius: 20,
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <MaterialCommunityIcons
                          name="delete-outline"
                          size={24}
                          color="#FFFFFF"
                        />
                      </TouchableOpacity>
                    )}
                  </View>
                )}
                <Image
                  style={[
                    styles.imgDetail,
                    {
                      width: width < 1600 ? 240 : 278,
                      backgroundColor: "#CCCCCC",
                    },
                  ]}
                  source={{
                    uri:
                      "https://api.courseoncloud.com/api/image/getimage/" +
                      item.img_content,
                  }}
                />
                <View
                  style={{
                    padding: 12,
                    // borderWidth: 1,
                    // borderColor: "#BBB",
                  }}
                >
                  <Text style={styles.textDetail} numberOfLines={1}>
                    {item.title}
                  </Text>
                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      minHeight: 70,
                    }}
                  >
                    <Image
                      style={{
                        width: 35,
                        height: 35,
                        borderRadius: 18,
                        marginRight: 15,
                        backgroundColor: "#CCC",
                      }}
                      source={{
                        uri:
                          "https://api.courseoncloud.com/api/image/getimage/" +
                          item?.user?.user_accounts?.image_url,
                      }}
                    />
                    <Text style={styles.textDetail}>
                      {item?.user?.user_accounts?.firstName}{" "}
                      {item?.user?.user_accounts?.lastName}
                    </Text>
                  </View>
                  <View style={styles.feedback}>
                    <View>
                      <View
                        style={{
                          width: 100,
                          height: 20,
                          backgroundColor: "#0B2051",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: 10,
                          marginVertical: 5,
                        }}
                      >
                        <Text
                          style={{
                            fontFamily: "Prompt",
                            fontSize: 12,
                            color: "#FFFFFF",
                          }}
                        >
                          {item.Type == "COURSESCHOOL" &&
                          item.available != "PENDING" &&
                          item.available == "APPROVE"
                            ? "เฉพาะนักศึกษา"
                            : item.Type != "COURSESCHOOL" &&
                              item.available != "PENDING" &&
                              item.available == "APPROVE"
                            ? "สาธารณะ"
                            : item.available == "PENDING"
                            ? "รอการอนุมัติ"
                            : item.available == "DRAFT"
                            ? "แบบร่าง"
                            : "โดนปฏิเสธ"}
                          {}
                        </Text>
                      </View>
                      <View
                        style={{
                          flexDirection: "row",
                          alignSelf: "flex-end",
                        }}
                      >
                        {/* <FontAwesome
                          name={item.rating > 0 ? "star" : "star-o"}
                          size={size.width < 500 ? 15 : 20}
                          color="#0D88F0"
                        />
                        <FontAwesome
                          name={item.rating > 1 ? "star" : "star-o"}
                          size={size.width < 500 ? 15 : 20}
                          color="#0D88F0"
                        />
                        <FontAwesome
                          name={item.rating > 2 ? "star" : "star-o"}
                          size={size.width < 500 ? 15 : 20}
                          color="#0D88F0"
                        />
                        <FontAwesome
                          name={item.rating > 3 ? "star" : "star-o"}
                          size={size.width < 500 ? 15 : 20}
                          color="#0D88F0"
                        />
                        <FontAwesome
                          name={item.rating > 4 ? "star" : "star-o"}
                          size={size.width < 500 ? 15 : 20}
                          color="#0D88F0"
                        /> */}
                      </View>
                    </View>
                    <View>
                      <View style={{ flexDirection: "row" }}>
                        <Text
                          style={[
                            styles.text,
                            {
                              textAlign: "right",
                              fontSize: size.width < 500 ? 12 : 16,
                              textDecorationLine:
                                item.discount != 0 ||
                                (item.discount != null && "line-through"),
                            },
                          ]}
                        >
                          <NumberFormat
                            value={item.price == 0 ? "" : item.price}
                            displayType={"text"}
                            thousandSeparator={true}
                          />{" "}
                          {item.price == 0
                            ? "ฟรี"
                            : item.discount == 0 ||
                              (item.discount == null && "บาท")}
                        </Text>
                        {item.discount != 0 ||
                          (item.discount != null && (
                            <Text
                              style={[
                                styles.text,
                                {
                                  textAlign: "right",
                                  fontSize: size.width < 500 ? 12 : 16,
                                },
                              ]}
                            >
                              {item.price -
                                (parseInt(item.discount) * item.price) / 100}
                              {item.discount != 0 && " บาท"}
                            </Text>
                          ))}
                      </View>
                      <Text
                        style={[
                          styles.text,
                          {
                            fontSize: size.width < 500 ? 12 : 16,
                            marginTop: -5,
                            textAlign: "right",
                          },
                        ]}
                      >
                        <NumberFormat
                          value={item.length}
                          displayType={"text"}
                          thousandSeparator={true}
                        />{" "}
                        <FontAwesome5
                          name="user-friends"
                          size={20}
                          color="#5D5D5D"
                        />
                      </Text>
                    </View>
                  </View>
                </View>
              </TouchableOpacity>
            );
          }}
        />
      </View>
      <View
        style={{
          width: width * 0.5,
          alignItems: "flex-end",
          height: 50,
        }}
      >
        <View
          style={{
            marginVertical: 10,
            width: "100%",
            alignItems: "flex-end",
          }}
        >
          <FlatList
            data={paginations.filter(
              (item, index) => page <= index && page + 3 >= index
            )}
            horizontal
            renderItem={({ item, index }) => {
              return (
                <View style={{ flexDirection: "row" }}>
                  {page != 1 && index == 0 && (
                    <>
                      <TouchableOpacity
                        onPress={() => {
                          getCourseId(page - 1);
                          setpage(page - 1);
                        }}
                        style={{
                          width: width * 0.026,
                          height: height * 0.046,
                          backgroundColor: "#ffff",
                          justifyContent: "center",
                          zIndex: 99,
                        }}
                      >
                        <AntDesign name="caretleft" size={18} color="black" />
                      </TouchableOpacity>
                      <TouchableOpacity
                        onPress={() => {
                          getCourseId(1);
                          setpage(1);
                        }}
                        style={{
                          width: width * 0.026,
                          height: height * 0.046,
                          backgroundColor: "#ffff",
                          justifyContent: "center",
                          zIndex: 99,
                        }}
                      >
                        <Text style={{ textAlign: "center", fontSize: 13 }}>
                          {1}
                        </Text>
                      </TouchableOpacity>
                      <TouchableOpacity
                        disabled
                        style={{
                          width: width * 0.026,
                          height: height * 0.046,
                          backgroundColor: "#ffff",
                          justifyContent: "center",
                          zIndex: 99,
                        }}
                      >
                        <Text style={{ textAlign: "center", fontSize: 13 }}>
                          ...
                        </Text>
                      </TouchableOpacity>
                    </>
                  )}
                  <TouchableOpacity
                    onPress={() => {
                      getCourseId(page + index);
                      setpage(page + index);
                    }}
                    style={{
                      width: width * 0.026,
                      height: height * 0.036,
                      backgroundColor:
                        page + index == page ? "#3D6B99" : "#ffff",
                      justifyContent: "center",
                      borderRadius: 150,
                      zIndex: 99,
                    }}
                  >
                    <Text
                      style={{
                        textAlign: "center",
                        color: page + index == page ? "#fff" : "#000",
                        fontSize: 13,
                        // fontFamily: "Kanit-Regular",
                      }}
                    >
                      {page + index}
                    </Text>
                  </TouchableOpacity>
                  {page != total_page &&
                    index ==
                      paginations.filter(
                        (item, index) => page <= index && page + 3 >= index
                      ).length -
                        1 && (
                      <>
                        <TouchableOpacity
                          disabled
                          style={{
                            width: width * 0.026,
                            height: height * 0.046,
                            backgroundColor: "#ffff",
                            justifyContent: "center",
                            zIndex: 99,
                          }}
                        >
                          <Text
                            style={{
                              textAlign: "center",
                              fontSize: 13,
                            }}
                          >
                            ...
                          </Text>
                        </TouchableOpacity>
                        <TouchableOpacity
                          onPress={() => {
                            getCourseId(total_page);
                            setpage(total_page - 3);
                          }}
                          style={{
                            width: width * 0.026,
                            height: height * 0.046,
                            backgroundColor: "#ffff",
                            justifyContent: "center",
                            zIndex: 99,
                          }}
                        >
                          <Text style={{ textAlign: "center", fontSize: 13 }}>
                            {total_page}
                          </Text>
                        </TouchableOpacity>
                        <TouchableOpacity
                          onPress={() => {
                            getCourseId(page + 1);
                            setpage(page + 1);
                          }}
                          style={{
                            width: width * 0.026,
                            height: height * 0.046,
                            backgroundColor: "#ffff",
                            justifyContent: "center",
                            zIndex: 99,
                          }}
                        >
                          <AntDesign
                            name="caretright"
                            size={18}
                            color="black"
                          />
                        </TouchableOpacity>
                      </>
                    )}
                </View>
              );
            }}
          />
        </View>
      </View>
      <Modal
        transparent={true}
        visible={modalDelItem}
        onRequestClose={() => {
          Alert.alert("Modal has been closed.");
          setModalDelItem(!modalDelItem);
        }}
      >
        <View style={styles.bgModal}>
          <View
            style={[
              styles.viewDetailModal,
              { width: "30%", height: 183, paddingVertical: 25 },
            ]}
          >
            <Text
              style={[
                styles.textButtonModal,
                { color: "#444444", alignSelf: "center" },
              ]}
            >
              คุณต้องการจะลบใช่รึไม่
            </Text>
            <View
              style={{
                flexDirection: "row",
                alignSelf: "center",
                marginTop: 39,
              }}
            >
              <TouchableOpacity
                onPress={() => setModalDelItem(!modalDelItem)}
                style={styles.buttonModal}
              >
                <Text
                  style={[
                    styles.textButtonModal,
                    {
                      color: "#444444",
                    },
                  ]}
                >
                  ยกเลิก
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => {
                  Delete();
                  setTimeout(() => {
                    getCourseId();
                  }, 500);
                  setModalDelItem(!modalDelItem);
                }}
                style={[
                  styles.buttonModal,
                  {
                    backgroundColor: "#0D88F0",
                    marginLeft: width * 0.05,
                  },
                ]}
              >
                <Text
                  style={[
                    styles.textButtonModal,
                    {
                      color: "#FFFFFF",
                    },
                  ]}
                >
                  ยืนยัน
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </Modal>
    </View>
  );
}
const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: "100%",
    backgroundColor: "#FFFFFF",
  },
  textTitle: {
    fontSize: 18,
    fontFamily: "Prompt",
    color: "#0B2051",
  },
  text: {
    fontSize: 20,
    fontFamily: "Prompt",
    color: "#5D5D5D",
    marginTop: 19,
  },
  textDetail: {
    fontSize: 16,
    fontFamily: "Prompt",
    color: "#5D5D5D",
  },
  viewDetail: {
    width: 278,
    // height: 385,
  },
  imgDetail: {
    width: 278,
    height: 195,
  },
  feedback: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  imgSocial: {
    width: 30,
    height: 30,
    borderRadius: 15,
    marginHorizontal: 10,
    marginTop: 10,
  },
  bgModal: {
    width: "100%",
    height: "100%",
    backgroundColor: "#000000bb",
    justifyContent: "center",
  },
  viewDetailModal: {
    width: "50%",
    height: 380,
    backgroundColor: "#FFFFFF",
    borderRadius: 20,
    alignSelf: "center",
    paddingHorizontal: width * 0.05,
    paddingVertical: 50,
  },
  buttonModal: {
    width: 102,
    height: 48,
    borderRadius: 4,
    backgroundColor: "#EFEFF3",
    justifyContent: "center",
    alignItems: "center",
  },
  textButtonModal: {
    fontSize: 25,
    fontFamily: "Prompt",
  },
});
