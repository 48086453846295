import { View, Text } from "react-native";
import React, { useState, useEffect } from "react";
import { useRecoilState } from "recoil";
import { courseState, otherStates } from "../../reducer/recoil";
import { apiservice } from "../../service";

export default function GetProfile({ route }) {
  const [otherState, setotherStates] = useRecoilState(otherStates);

  useEffect(() => {
    name();
  }, []);
  async function name() {
    const res = await apiservice({
      path: "/authen/getUserGeneral?id=" + route.params.id,
      method: "get",
    });

    if (res.status == 200) {
      setotherStates(res.data.result);
      setTimeout(() => {
        navigation.navigate("otherprofile");
      }, 1000);
    }
  }
  return <View></View>;
}
