import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  Image,
  Dimensions,
  Animated,
  TouchableOpacity,
  StyleSheet,
  ScrollView,
  TextInput,
  FlatList,
} from "react-native";
import {
  MaterialCommunityIcons,
  FontAwesome,
  FontAwesome5,
  MaterialIcons,
} from "@expo/vector-icons";
import { apiservice } from "../../service";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  courseState,
  lessonState,
  lessonVideoState,
  subjecttestState,
  testState,
  tokenState,
  userState,
} from "../../reducer/recoil";

import { useWindowSize } from "../components/responsive";
const { width, height } = Dimensions.get("window");

export default function cousePage1({ navigation, dataStudymodel, Oncecourse }) {
  const [user, setUser] = useState();
  const [courseStates, setCourseStates] = useRecoilState(courseState);

  const [testStates, setTestStates] = useRecoilState(testState);
  const [subjectTestStates, setSubjectTestStates] =
    useRecoilState(subjecttestState);
  const [lessonStates, setLessonStates] = useRecoilState(lessonState);
  const [lessonVideoStates, setLessonVideoStates] =
    useRecoilState(lessonVideoState);
  const [listen_detail, setListen_detail] = useState([]);
  const token = useRecoilValue(tokenState);
  const size = useWindowSize();
  useEffect(() => {
    getProfile(token);
  }, []);

  async function getProfile(token) {
    try {
      const response = await apiservice({
        path: "/authen/user",
        method: "get",
        token: token?.accessToken,
      });
      if (response.status == 200) {
        setUser(response.data.result.id);
      }
    } catch (error) {
      throw error;
    }
  }

  return (
    <View style={{ minHeight: 500 }}>
      <FlatList
        data={dataStudymodel}
        extraData={[Oncecourse]}
        numColumns={1}
        renderItem={({ index, item }) => {
          const MainItem = item;
          const Mainindex = index;

          return (
            <View>
              <Text
                style={[
                  size.width < 750
                    ? stylesResponsive.textTitle
                    : styles.textTitle,
                ]}
              >
                {item.listen_name}
              </Text>
              <FlatList
                data={item.listen_detail}
                extraData={[Oncecourse]}
                numColumns={1}
                renderItem={({ index, item }) => {
                  return (
                    <TouchableOpacity
                      disabled={
                        token?.role == "ADMIN" ||
                          token?.role == "SUPERADMIN" ||
                          token?.role == "SCHOOL" ||
                          (token?.role == "TEACHER" &&
                            courseStates?.user?.id == user)
                          ? false
                          : Oncecourse == null ||
                          (Oncecourse != null &&
                            Oncecourse.progress[Mainindex].listen_detail[
                              index
                            ]?.status == false)
                      }
                      onPress={async () => {
                        if (
                          token?.role != "ADMIN" &&
                          token?.role != "SUPERADMIN" &&
                          token?.role != "SCHOOL" &&
                          token?.role != "TEACHER"
                        ) {
                          if (item.type == "EXAM") {
                            const res = await apiservice({
                              path: "/quiz/get/" + item.education_id,
                              method: "get",
                              token: token?.accessToken,
                            });
                            setTestStates({
                              ...item,
                              ...res.data,
                              answer: Oncecourse.progress[Mainindex]
                                .listen_detail[index].answer
                                ? Oncecourse.progress[Mainindex].listen_detail[
                                  index
                                ].answer
                                : [],
                              MyQ: Oncecourse.progress[Mainindex].listen_detail[
                                index
                              ].MyQ
                                ? Oncecourse.progress[Mainindex].listen_detail[
                                  index
                                ].MyQ
                                : [],
                              status: true,
                              Mainindex,
                              index,
                              dataStudymodel: Oncecourse.progress,
                              course_id: Oncecourse.course_id,
                            });
                            setTimeout(() => {
                              navigation.navigate("Test");
                            }, 900);
                          } else if (item.type == "DESCRIPTION") {
                            const res = await apiservice({
                              path: "/course/geteducation/" + item.education_id,
                              method: "get",
                              token: token?.accessToken,
                            });
                            if (res.status == 200) {
                              if (res.data.TYPE == "VDO") {
                                setLessonVideoStates({
                                  ...item,
                                  ...res.data,
                                  ...Oncecourse,
                                  status: true,
                                  Mainindex,
                                  index,
                                  dataStudymodel: Oncecourse?.progress,
                                  course_id: Oncecourse.course_id,
                                });
                                setTimeout(() => {
                                  navigation.navigate("LessonVideo");
                                }, 900);
                              } else {
                                setLessonStates({
                                  ...item,
                                  ...res.data,
                                  ...Oncecourse,
                                  status: true,
                                  Mainindex,
                                  index,
                                  dataStudymodel: Oncecourse.progress,
                                  course_id: Oncecourse.course_id,
                                });
                                setTimeout(() => {
                                  navigation.navigate("Lesson");
                                }, 900);
                              }
                            }
                          } else if (item.type == "WORK") {
                            const res = await apiservice({
                              path: "/course/getwork/" + item.education_id,
                              method: "get",
                              token: token?.accessToken,
                            });
                            setSubjectTestStates({
                              ...res.data,
                              ...item,
                              ...Oncecourse,
                              Mainindex,
                              index,
                              status: true,
                              dataStudymodel: Oncecourse.progress,
                              course_id: Oncecourse.course_id,
                            });
                            setTimeout(() => {
                              navigation.push("SubjectiveTest");
                            }, 900);
                          } else {
                            const res = await apiservice({
                              path: "/quiz/get/" + item.education_id,
                              method: "get",
                              token: token?.accessToken,
                            });
                            setSubjectTestStates({
                              ...res.data,
                              ...item,
                              ...Oncecourse,
                              Mainindex,
                              index,
                              number: 0,
                              status: true,
                              dataStudymodel: Oncecourse.progress,
                              course_id: Oncecourse.course_id,
                            });
                            setTimeout(() => {
                              navigation.push("SubjectiveTest");
                            }, 500);
                          }
                        } else if (
                          token?.role == "TEACHER" &&
                          courseStates?.user?.id == user
                        ) {
                          if (item.type == "EXAM") {
                            const res = await apiservice({
                              path: "/quiz/get/" + item.education_id,
                              method: "get",
                              token: token?.accessToken,
                            });

                            navigation.navigate("TestReview", {
                              ...item,
                              ...res.data,
                              answer: [],
                              MyQ: [],
                              status: true,
                              Mainindex,
                              index,
                              dataStudymodel,
                            });
                          } else if (item.type == "DESCRIPTION") {
                            const res = await apiservice({
                              path: "/course/geteducation/" + item.education_id,
                              method: "get",
                              token: token?.accessToken,
                            });
                            if (res.status == 200) {
                              if (res.data.TYPE == "VDO") {
                                navigation.navigate("LessonVideoReview", {
                                  ...item,
                                  ...res.data,
                                  status: true,
                                  Mainindex,
                                  index,
                                  dataStudymodel,
                                });
                              } else {
                                navigation.navigate("LessonReview", {
                                  ...item,
                                  ...res.data,
                                  status: true,
                                  Mainindex,
                                  index,
                                  dataStudymodel,
                                });
                              }
                            }
                          } else if (item.type == "WORK") {
                            const res = await apiservice({
                              path: "/course/getwork/" + item.education_id,
                              method: "get",
                              token: token?.accessToken,
                            });
                            navigation.push("SubjectiveTestReview", {
                              ...res.data,
                              ...item,
                              ...Oncecourse,
                              Mainindex,
                              index,
                              status: true,
                              dataStudymodel,
                            });
                          } else {
                            const res = await apiservice({
                              path: "/quiz/get/" + item.education_id,
                              method: "get",
                              token: token?.accessToken,
                            });

                            navigation.push("SubjectiveTestReview", {
                              ...res.data,
                              ...item,
                              ...Oncecourse,
                              Mainindex,
                              index,
                              number: 0,
                              status: true,
                              dataStudymodel,
                            });
                          }
                        } else if (
                          token?.role == "ADMIN" ||
                          token?.role == "SUPERADMIN" ||
                          token?.role == "SCHOOL"
                        ) {
                          // ADMIN

                          if (item.type == "EXAM") {
                            const res = await apiservice({
                              path: "/quiz/get/" + item.education_id,
                              method: "get",
                              token: token?.accessToken,
                            });

                            navigation.navigate("TestReview", {
                              ...item,
                              ...res.data,
                              answer: [],
                              MyQ: [],
                              status: true,
                              Mainindex,
                              index,
                              dataStudymodel,
                            });
                          } else if (item.type == "DESCRIPTION") {
                            const res = await apiservice({
                              path: "/course/geteducation/" + item.education_id,
                              method: "get",
                              token: token?.accessToken,
                            });
                            if (res.status == 200) {
                              if (res.data.TYPE == "VDO") {
                                navigation.navigate("LessonVideoReview", {
                                  ...item,
                                  ...res.data,
                                  status: true,
                                  Mainindex,
                                  index,
                                  dataStudymodel,
                                });
                              } else {
                                navigation.navigate("LessonReview", {
                                  ...item,
                                  ...res.data,
                                  status: true,
                                  Mainindex,
                                  index,
                                  dataStudymodel,
                                });
                              }
                            }
                          } else if (item.type == "WORK") {
                            const res = await apiservice({
                              path: "/course/getwork/" + item.education_id,
                              method: "get",
                              token: token?.accessToken,
                            });
                            navigation.push("SubjectiveTestReview", {
                              ...res.data,
                              ...item,
                              ...Oncecourse,
                              Mainindex,
                              index,
                              status: true,
                              dataStudymodel,
                            });
                          } else {
                            const res = await apiservice({
                              path: "/quiz/get/" + item.education_id,
                              method: "get",
                              token: token?.accessToken,
                            });

                            navigation.push("SubjectiveTestReview", {
                              ...res.data,
                              ...item,
                              ...Oncecourse,
                              Mainindex,
                              index,
                              number: 0,
                              status: true,
                              dataStudymodel,
                            });
                          }
                        }
                      }}
                      style={[
                        styles.buttonCourse,
                        {
                          opacity:
                            token?.role == "ADMIN" ||
                              token?.role == "SUPERADMIN" ||
                              token?.role == "SCHOOL" ||
                              (token?.role == "TEACHER" &&
                                courseStates?.user?.id == user)
                              ? 1
                              : Oncecourse == null ||
                                (Oncecourse != null &&
                                  Oncecourse.progress[Mainindex].listen_detail[
                                    index
                                  ]?.status == false)
                                ? 0.3
                                : 1,
                        },
                      ]}
                    >
                      <MaterialCommunityIcons
                        name="file-multiple"
                        size={24}
                        color="#646464"
                      />
                      <Text
                        style={[
                          size.width < 750
                            ? stylesResponsive.textDetail
                            : styles.textDetail,
                        ]}
                      >
                        {item.name}
                      </Text>
                    </TouchableOpacity>
                  );
                }}
              />
            </View>
          );
        }}
      />
      {/* <TouchableOpacity style={styles.buttonCourse}>
        <MaterialIcons name="archive" size={24} color="#646464" />
        <Text style={styles.textDetail}>งานท้ายบท</Text>
      </TouchableOpacity>
      <View style={styles.viewWork}>
        <TouchableOpacity style={styles.buttonCourse}>
          <MaterialIcons name="live-help" size={24} color="#646464" />
          <Text style={styles.textDetail}>แบบทดสอบท้ายบท</Text>
        </TouchableOpacity>
        <Text style={[styles.textDetail, { marginLeft: 0 }]}>30 ข้อ</Text>
      </View> */}
    </View >
  );
}
const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: "100%",
    backgroundColor: "#FFFFFF",
  },
  textDetail: {
    fontSize: 20,
    fontFamily: "Prompt",
    color: "#646464",
    marginLeft: 25,
  },
  textTitle: {
    fontSize: 25,
    fontFamily: "Prompt",
    color: "#646464",
    marginTop: 20,
  },
  buttonCourse: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 15,
    marginLeft: 20,
  },

  viewWork: {
    flexDirection: "row",
    justifyContent: "space-between",
    borderBottomWidth: 1,
    borderBottomColor: "#0D88F0",
    paddingBottom: 45,
  },
});
const stylesResponsive = StyleSheet.create({
  textDetail: {
    fontSize: 16,
    fontFamily: "Prompt",
    color: "#646464",
    marginLeft: 25,
  },
  textTitle: {
    fontSize: 18,
    fontFamily: "Prompt",
    color: "#646464",
    marginTop: 20,
  },
});
