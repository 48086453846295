import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  Image,
  Dimensions,
  Animated,
  TouchableOpacity,
  StyleSheet,
  ScrollView,
  TextInput,
  FlatList,
  Alert,
} from "react-native";
import { MaterialIcons } from "@expo/vector-icons";
import RNPickerSelect from "react-native-picker-select";
import Modal from "modal-react-native-web";
import { authActionRegister, deleteAdmin } from "../../../action/authAction";
import { useRecoilState } from "recoil";
import { tokenState } from "../../../reducer/recoil";
import { apiservice } from "../../../service/index";
import moment from "moment";
import NumberFormat from "react-number-format";
import { useWindowSize } from "../../components/responsive";
const { width, height } = Dimensions.get("window");
export default function universityDetail({
  navigation,
  onPress,
  university_name,
  university_id,
}) {
  const [modalAddUniversity, setModalAddUniversity] = useState(false);
  const [token, setToken] = useRecoilState(tokenState);
  const [modalDelItem, setModalDelItem] = useState(false);
  const [confirmPass, setConfirmPass] = useState("");
  const [checkUser, setCheckUser] = useState(false);
  const [checkEmail, setCheckEmail] = useState(false);
  const [checkPass, setCheckPass] = useState(false);
  const [checkTel, setCheckTel] = useState(false);
  const [checkText, setCheckText] = useState(false);
  const [password, setPassword] = useState(false);
  const [status, setStatus] = useState();
  const [allAdmin, setAllAdmin] = useState([]);
  const [id, setId] = useState();

  const [state, setState] = useState({
    role: university_id ? "ADMIN" : "SUPERADMIN",
    sub_role: "MAIN",
    username: "",
    email: "",
    password: "",
    university_id: university_id,
    telNo: "",
  });
  async function register() {
    if (
      state.username.length > 0 &&
      state.email.length > 0 &&
      state.password.length > 0 &&
      state.telNo.length > 0
    ) {
      if (state.password != confirmPass) {
        setPassword(true);
      }
      if (state.password.length >= 8) {
        if (state.password == confirmPass) {
          const register = await authActionRegister(state);

          if (register) {
            setStatus();
            setPassword(false);
            setModalAddUniversity(!modalAddUniversity);
          }
          if (!register) {
            setStatus(register);
          }
        }
      }
    }

    if (state.username.length == 0) {
      setCheckUser(true);
    }
    if (state.email.length == 0) {
      setCheckEmail(true);
    }
    if (state.password.length == 0) {
      setCheckPass(true);
    }
    if (state.telNo.length == 0) {
      setCheckTel(true);
    }
  }
  const emailValid =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  function errors({ value, type }) {
    if (type == "email") {
      return emailValid.test(value);
    }
  }

  useEffect(() => {
    if (token) {
      if (university_id) {
        getAllAdmin(token);
      } else {
        getAllSuperAdmin(token);
      }
    }
  }, [token]);

  async function getAllSuperAdmin(token) {
    try {
      const response = await apiservice({
        path:
          "/authen/getadmin?university_id=" +
          university_id +
          "&page=1&totalpage=100",
        method: "get",
        // token: token?.accessToken,
      });

      if (response.status == 200) {
        let pagination = [];
        for (let index = 0; index < response.data.total_page; index++) {
          pagination = pagination.concat({});
        }
        setAllAdmin(response.data);
        getAllAdmin();
      }
    } catch (error) {
      throw error;
    }
  }

  async function getAllAdmin(token) {
    try {
      const response = await apiservice({
        path:
          "/authen/getadmin?university_id=" +
          university_id +
          "&page=1&totalpage=100",
        method: "get",
        // token: token?.accessToken,
      });

      if (response.status == 200) {
        let pagination = [];
        for (let index = 0; index < response.data.total_page; index++) {
          pagination = pagination.concat({});
        }
        setAllAdmin(response.data);
        getAllAdmin();
      }
    } catch (error) {
      throw error;
    }
  }

  async function Delete() {
    const Delete = await deleteAdmin({
      token: token?.accessToken,
      id: id,
    });
  }
  const size = useWindowSize();
  var dd = moment.locale("th");
  return (
    <View>
      <View
        style={{
          flexDirection: size.width < 500 ? "column" : "row",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: 10,
        }}
      >
        {university_id && (
          <Text
            style={{ fontSize: 23, fontFamily: "Prompt", color: "#808D93" }}
          >
            {university_name}
          </Text>
        )}
        {!university_id && (
          <View style={{ flexDirection: "row" }}>
            <TouchableOpacity onPress={onPress}>
              <Text
                style={{
                  fontSize: 23,
                  fontFamily: "Prompt",
                  color: "#808D93",
                  marginTop: 10,
                }}
              >
                มหาวิทยาลัย
              </Text>
            </TouchableOpacity>
            <TouchableOpacity style={{ marginLeft: 25 }}>
              <Text
                style={{
                  fontSize: 23,
                  fontFamily: "Prompt",
                  color: "#0D88F0",
                  marginTop: 10,
                }}
              >
                Super Admin
              </Text>
            </TouchableOpacity>
          </View>
        )}
        {token?.role == "SUPERADMIN" && (
          <TouchableOpacity
            onPress={() => {
              setModalAddUniversity(true);
            }}
            style={[styles.buttonAdd, { marginTop: size.width < 500 && 20 }]}
          >
            <Text style={styles.text16W}>+ Add New</Text>
          </TouchableOpacity>
        )}
      </View>
      <ScrollView horizontal>
        <View
          style={{
            width:
              size.width < 500
                ? size.width * 2.5
                : size.width < 1280
                ? size.width * 1.0
                : size.width * 0.75,
            // minHeight: height * 2.5,
          }}
        >
          <View style={styles.viewSubject}>
            <Text
              style={[
                styles.text16B,
                { width: "20%", textAlign: "left", paddingLeft: 67 },
              ]}
            >
              ชื่อแอดมิน
            </Text>
            <Text
              style={[styles.text16B, { width: "20%", textAlign: "center" }]}
            >
              สิทธิ์
            </Text>
            <Text
              style={[styles.text16B, { width: "25%", textAlign: "center" }]}
            >
              email
            </Text>
            <Text style={[styles.text16B, { width: "15%" }]}>เบอร์โทร</Text>
            <Text style={[styles.text16B, { width: "15%" }]}>วันที่สร้าง</Text>

            <Text style={[styles.text16B, { width: "5%" }]}></Text>
          </View>
          <FlatList
            numColumns={1}
            data={allAdmin.result}
            renderItem={({ item, index }) => {
              return (
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    borderBottomWidth: 1,
                    borderBottomColor: "#E5E5E5",
                    height: 50,
                    alignItems: "center",
                  }}
                >
                  <Text
                    style={[
                      styles.text16B,
                      {
                        width: "20%",
                        textAlign: "left",
                        paddingLeft: 67,
                        fontSize: size.width < 1400 ? 14 : 16,
                      },
                    ]}
                  >
                    {item.username}
                  </Text>
                  <Text
                    style={[
                      styles.text16B,
                      {
                        width: "20%",
                        textAlign: "center",
                        fontSize: size.width < 1400 ? 14 : 16,
                      },
                    ]}
                  >
                    {item.role}
                  </Text>
                  <Text
                    style={[
                      styles.text16B,
                      {
                        width: "25%",
                        textAlign: "center",
                        fontSize: size.width < 1400 ? 14 : 16,
                      },
                    ]}
                  >
                    {item.email}
                  </Text>

                  <Text
                    style={[
                      styles.text16B,
                      { width: "15%", fontSize: size.width < 1400 ? 14 : 16 },
                    ]}
                  >
                    {/* {item.user_accounts.telNo} */}
                    <NumberFormat
                      value={item.user_accounts.telNo}
                      displayType={"text"}
                      format="###-####-####"
                    />
                  </Text>
                  <Text
                    style={[
                      styles.text16B,
                      { width: "15%", fontSize: size.width < 1400 ? 14 : 16 },
                    ]}
                  >
                    {moment(item.user_accounts.createdAt)
                      .add(543, "year")
                      .format("DD MMMM YYYY")}
                  </Text>

                  <TouchableOpacity
                    onPress={() => {
                      setId(item.id);
                      setModalDelItem(true);
                    }}
                    style={{ width: "5%", alignItems: "center" }}
                  >
                    <MaterialIcons name="delete" size={24} color="black" />
                  </TouchableOpacity>
                </View>
              );
            }}
          />
        </View>
      </ScrollView>
      <Modal
        transparent={true}
        visible={modalAddUniversity}
        onRequestClose={() => {
          Alert.alert("Modal has been closed.");
          setModalAddUniversity(!modalAddUniversity);
        }}
      >
        <View style={styles.bgModal}>
          <View
            style={[
              styles.viewDetailModal,
              {
                width:
                  size.width < 500 ? "80%" : size.width < 1280 ? "70%" : "50%",
                paddingHorizontal: size.width < 500 ? 20 : width * 0.05,
              },
            ]}
          >
            <Text
              style={[
                styles.textButtonModal,
                {
                  color: "#444444",
                  fontSize: 17,
                  alignSelf: "center",
                },
              ]}
            >
              Create Admin
            </Text>
            <Text
              style={[
                styles.textButtonModal,
                {
                  color: "#444444",
                  fontSize: 17,
                },
              ]}
            >
              Username
            </Text>
            <TextInput
              autoComplete="off"
              defaultValue={state.username}
              onChangeText={(text) => {
                if (state != "") {
                  setCheckText(false);
                  setCheckUser(false);
                  setCheckEmail(false);
                  setCheckPass(false);
                }
                setState({ ...state, username: text });
              }}
              placeholder={"user"}
              placeholderTextColor={"#444444"}
              style={[styles.inputModal, { marginTop: 0 }]}
            />
            {checkText && (
              <Text style={styles.text14R}>กรุณากรอกข้อมูลให้ครบถ้วน</Text>
            )}
            {checkUser && (
              <Text style={styles.text14R}>กรุณากรอกข้อมูลให้ครบถ้วน</Text>
            )}
            <Text
              style={[
                styles.textButtonModal,
                {
                  color: "#444444",
                  fontSize: 17,
                  marginTop: size.height < 900 ? 10 : 40,
                },
              ]}
            >
              Password
            </Text>
            <TextInput
              autoComplete="off"
              defaultValue={state.password}
              onChangeText={(text) => {
                if (state != "") {
                  setCheckText(false);
                  setCheckUser(false);
                  setCheckEmail(false);
                  setCheckPass(false);
                }
                setState({ ...state, password: text });
              }}
              secureTextEntry
              placeholder={"password"}
              placeholderTextColor={"#444444"}
              style={[styles.inputModal, { marginTop: 0 }]}
            />
            {state.password.length > 0 && state.password.length < 8 && (
              <Text style={styles.text14R}>ต้องมีรหัสผ่านอย่างน้อย 8 ตัว</Text>
            )}
            {checkText && (
              <Text style={styles.text14R}>กรุณากรอกข้อมูลให้ครบถ้วน</Text>
            )}
            {checkPass && (
              <Text style={styles.text14R}>กรุณากรอกข้อมูลให้ครบถ้วน</Text>
            )}
            <Text
              style={[
                styles.textButtonModal,
                {
                  color: "#444444",
                  fontSize: 17,
                  marginTop: size.height < 900 ? 10 : 40,
                },
              ]}
            >
              Confirm-password
            </Text>
            <TextInput
              autoComplete="off"
              defaultValue={confirmPass}
              onChangeText={(text) => {
                if (state != "") {
                  setCheckText(false);
                  setCheckUser(false);
                  setCheckEmail(false);
                  setCheckPass(false);
                }
                setConfirmPass(text);
              }}
              secureTextEntry
              placeholder={"password again"}
              placeholderTextColor={"#444444"}
              style={[styles.inputModal, { marginTop: 0 }]}
            />
            {password && <Text style={styles.text14R}>รหัสผ่านไม่ตรงกัน</Text>}
            {checkText && (
              <Text style={styles.text14R}>กรุณากรอกข้อมูลให้ครบถ้วน</Text>
            )}
            {checkPass && (
              <Text style={styles.text14R}>กรุณากรอกข้อมูลให้ครบถ้วน</Text>
            )}
            <Text
              style={[
                styles.textButtonModal,
                {
                  color: "#444444",
                  fontSize: 17,
                  marginTop: size.height < 900 ? 10 : 40,
                },
              ]}
            >
              Email
            </Text>
            <TextInput
              autoComplete="off"
              defaultValue={state.email}
              onChangeText={(text) => {
                if (state != "") {
                  setCheckText(false);
                  setCheckUser(false);
                  setCheckEmail(false);
                  setCheckPass(false);
                }
                setState({ ...state, email: text });
              }}
              placeholder={"E-mail"}
              placeholderTextColor={"#444444"}
              style={[styles.inputModal, { marginTop: 0 }]}
            />
            {state.email.length > 0 &&
              !errors({ value: state.email, type: "email" }) && (
                <Text style={styles.text14R}>รูปแบบของอีเมลไม่ถูกต้อง</Text>
              )}
            {checkText && (
              <Text style={styles.text14R}>กรุณากรอกข้อมูลให้ครบถ้วน</Text>
            )}
            {checkEmail && (
              <Text style={styles.text14R}>กรุณากรอกข้อมูลให้ครบถ้วน</Text>
            )}
            <Text
              style={[
                styles.textButtonModal,
                {
                  color: "#444444",
                  fontSize: 17,
                  marginTop: size.height < 900 ? 10 : 40,
                },
              ]}
            >
              Phone number
            </Text>
            <TextInput
              autoComplete="off"
              defaultValue={state.telNo}
              onChangeText={(text) => {
                setState({ ...state, telNo: text });
              }}
              placeholder={"Phone"}
              placeholderTextColor={"#444444"}
              style={[styles.inputModal, { marginTop: 0 }]}
            />
            {checkTel && (
              <Text style={styles.text14R}>กรุณากรอกข้อมูลให้ครบถ้วน</Text>
            )}
            {!university_id && (
              <Text
                style={[
                  styles.textButtonModal,
                  {
                    color: "#444444",
                    fontSize: 17,
                    marginTop: size.height < 900 ? 10 : 40,
                  },
                ]}
              >
                Role
              </Text>
            )}
            {!university_id && (
              <RNPickerSelect
                placeholder={{
                  label: "ROLE ..",
                  value: null,
                  color: "#FFD7C9",
                }}
                onValueChange={(value) => {
                  setState((val) => ({ ...val, role: value }));
                }}
                value={state.role?.toString()}
                items={[
                  {
                    value: "SUPERADMIN",
                    label: "SUPERADMIN",
                  },
                  {
                    value: "GENERALADMIN",
                    label: "GENERALADMIN",
                  },
                  {
                    value: "ACCOUNTING",
                    label: "ACCOUNTING",
                  },
                ]}
                style={{
                  inputWeb: [styles.inputModal, { marginTop: 0 }],
                }}
              />
            )}

            <View
              style={{
                flexDirection: "row",
                alignSelf: "center",
                marginTop: 49,
              }}
            >
              <TouchableOpacity
                onPress={() => {
                  setModalAddUniversity(!modalAddUniversity);
                }}
                style={styles.buttonModal}
              >
                <Text
                  style={[
                    styles.textButtonModal,
                    {
                      color: "#444444",
                    },
                  ]}
                >
                  ยกเลิก
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={register}
                style={[
                  styles.buttonModal,
                  {
                    backgroundColor: "#0D88F0",
                    marginLeft: width * 0.05,
                  },
                ]}
              >
                <Text
                  style={[
                    styles.textButtonModal,
                    {
                      color: "#FFFFFF",
                    },
                  ]}
                >
                  ยืนยัน
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </Modal>
      <Modal
        transparent={true}
        visible={modalDelItem}
        onRequestClose={() => {
          Alert.alert("Modal has been closed.");
          setModalDelItem(!modalDelItem);
        }}
      >
        <View style={styles.bgModal}>
          <View
            style={[
              styles.viewDetailModal,
              {
                width:
                  size.width < 500 ? "80%" : size.width < 1280 ? "70%" : "30%",
                height: 183,
                paddingVertical: 25,
              },
            ]}
          >
            <Text
              style={[
                styles.textButtonModal,
                { color: "#444444", alignSelf: "center" },
              ]}
            >
              คุณต้องการจะลบใช่รึไม่
            </Text>
            <View
              style={{
                flexDirection: "row",
                alignSelf: "center",
                marginTop: 39,
              }}
            >
              <TouchableOpacity
                onPress={() => setModalDelItem(!modalDelItem)}
                style={styles.buttonModal}
              >
                <Text
                  style={[
                    styles.textButtonModal,
                    {
                      color: "#444444",
                    },
                  ]}
                >
                  ยกเลิก
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => {
                  Delete();
                  setTimeout(() => {
                    getAllAdmin(token);
                  }, 500);
                  setModalDelItem(!modalDelItem);
                }}
                style={[
                  styles.buttonModal,
                  {
                    backgroundColor: "#0D88F0",
                    marginLeft: width * 0.05,
                  },
                ]}
              >
                <Text
                  style={[
                    styles.textButtonModal,
                    {
                      color: "#FFFFFF",
                    },
                  ]}
                >
                  ยืนยัน
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </Modal>
    </View>
  );
}
const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: "100%",
    backgroundColor: "#FFFFFF",
  },
  text16W: {
    fontFamily: "Prompt",
    fontSize: 16,
    color: "#FFFFFF",
  },
  text16B: {
    fontFamily: "Prompt",
    fontSize: 16,
    color: "#000000",
  },
  buttonAdd: {
    width: 140,
    height: 40,
    backgroundColor: "#0D88F0",
    borderRadius: 20,
    alignItems: "center",
    justifyContent: "center",
    alignSelf: "flex-end",
  },
  viewSubject: {
    width: "100%",
    height: 51,
    backgroundColor: "#E5E5E5",
    marginTop: 35,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  bgModal: {
    width: "100%",
    height: "100%",
    backgroundColor: "#000000bb",
    justifyContent: "center",
  },
  viewDetailModal: {
    width: "50%",

    backgroundColor: "#FFFFFF",
    borderRadius: 20,
    alignSelf: "center",
    paddingHorizontal: width * 0.05,
    paddingVertical: 50,
  },
  buttonModal: {
    width: 102,
    height: 48,
    borderRadius: 4,
    backgroundColor: "#EFEFF3",
    justifyContent: "center",
    alignItems: "center",
  },
  textButtonModal: {
    fontSize: 25,
    fontFamily: "Prompt",
  },
  inputModal: {
    width: "100%",
    height: 45,
    borderRadius: 5,
    borderWidth: 1,
    borderColor: "#707070",
    fontSize: 18,
    fontFamily: "Prompt",
    color: "#444444",
    textAlign: "flex-start",
    marginTop: 40,
    paddingHorizontal: 10,
  },
  text14R: {
    fontSize: 14,
    color: "red",
    fontFamily: "Prompt",
  },
});
