import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  Image,
  Dimensions,
  Animated,
  TouchableOpacity,
  StyleSheet,
  ScrollView,
  TextInput,
  FlatList,
} from "react-native";
import {
  MaterialCommunityIcons,
  MaterialIcons,
  Feather,
  Ionicons,
  FontAwesome,
} from "@expo/vector-icons";
import RNPickerSelect from "react-native-picker-select";
import Modal from "modal-react-native-web";
import {
  authActionCreateFaculty,
  deleteFacuty,
} from "../../../action/authAction";
import { useRecoilState } from "recoil";
import { tokenState } from "../../../reducer/recoil";
import moment from "moment";
import { apiservice } from "../../../service";
import { useWindowSize } from "../../components/responsive";
const { width, height } = Dimensions.get("window");
export default function faculty({ ongoBack, university_id }) {
  const [modalDelItem, setModalDelItem] = useState(false);
  const [token, setToken] = useRecoilState(tokenState);
  const [modalAddItem, setModalAddItem] = useState(false);
  const [deGreeIndex, setDeGreeIndex] = useState(0);
  const [useFaculty, setUseFaculty] = useState([]);
  const [warning, setWarning] = useState(false);
  const [warningFaculty, setWarningFaculty] = useState(false);
  const [warningMajor, setWarningMojor] = useState(false);
  const size = useWindowSize();
  const [id, setId] = useState();
  const [search, setSearch] = useState("");
  const degreeList = ["ทั้งหมด", "ปริญญาตรี", "ปริญญาโท", "ปริญญาเอก"];
  const degreeList1 = ["ปริญญาตรี", "ปริญญาโท", "ปริญญาเอก"];
  const anotherFunc = (val) => {
    setState({ facuty_name_th: "", major_name_th: "" });
    setDeGreeIndexModal(-1);
  };
  const [state, setState] = useState({
    university_id: university_id,
    facuty_name_th: "",
    facuty_name_en: "",
    major_name_th: "",
    major_name_en: "",
    major: deGreeIndexModal,
  });
  async function getProfile(token) {
    try {
      const response = await apiservice({
        path: "/authen/user",
        method: "get",
        token: token?.accessToken,
      });
      if (response.status == 200) {
        getFaculty(response.data.result.university.id);
      }
    } catch (error) {
      throw error;
    }
  }
  async function addFacuty() {
    if (
      state.facuty_name_th.length > 0 &&
      state.major_name_th.length > 0 &&
      state.major != undefined &&
      state.major != -1
    ) {
      const add = await authActionCreateFaculty({
        state,
        token: token?.accessToken,
      });
      setWarning(false);
      setWarningMojor(false);
      setWarningFaculty(false);
      getFaculty();
      getProfile(token);
      setModalAddItem(!modalAddItem);

      if (add) {
        setDeGreeIndexModal(-1);
        setState({
          university_id: university_id,
          facuty_name_th: "",
          facuty_name_en: "",
          major_name_th: "",
          major_name_en: "",
          major: deGreeIndexModal,
        });
        anotherFunc(
          state.facuty_name_th,
          state.major_name_th,
          deGreeIndexModal
        );
      }
    }
    if (state.major == undefined || state.major == -1) {
      setWarning(true);
    }
    if (state.facuty_name_th.length == 0) {
      setWarningFaculty(true);
    }
    if (state.major_name_th.length == 0) {
      setWarningMojor(true);
    }
  }
  useEffect(() => {
    if (token) {
      getProfile(token);
    }
  }, [token]);

  async function getFaculty(params) {
    try {
      const response = await apiservice({
        path: "/course/getfaculty?page=1&totalpage=100&university_id=" + params,
        method: "get",
        token: token?.accessToken,
      });

      if (response.status == 200) {
        let pagination = [];
        for (let index = 0; index < response.data.total_page; index++) {
          pagination = pagination.concat({});
        }
        setFaculty(
          response.data.result.map((item) => {
            return {
              label: item.facuty_name_th + "/" + item.major_name_th,
              value: item.id.toString(),
            };
          })
        );
        setUseFaculty(response.data.result);
      }
    } catch (error) {
      throw error;
    }
  }

  const degreeChangeHandler = (index) => {
    if (index - 1 == -1) {
      getProfile(token);
    } else {
      getFaculty1(token, index - 1);
    }
    setDeGreeIndex((preIndex) => index);
  };
  const [deGreeIndexModal, setDeGreeIndexModal] = useState(-1);

  const degreeModalChangeHandler = (index) => {
    setState({ ...state, major: index });
    setDeGreeIndexModal((preIndex) => index);
  };

  const placeholder = {
    label: "คณะทั้งหมด",
    value: null,
    color: "#0D88F0",
  };
  const [faculty, setFaculty] = useState([
    { label: "เกษตรศาสตร์", value: "Agriculture" },
    { label: "สถาปัตยกรรมศาสตร์", value: "Architecture" },
    { label: "อักษรศาสตร์", value: "Arts" },
    { label: "บริหารธุกิจ", value: "BusinessAdministration" },
    { label: "นิเทศศาสตร์", value: "CommunicationArts" },
    { label: "ทันตแพทยศาสตร์", value: "Dentistry" },
    { label: "เศรษฐศาสตร์", value: "Economics" },
    { label: "ครุศาสตร์", value: "Education" },
    { label: "วิศวกรรมศาสตร์", value: "Engineering" },
    { label: "ศิลปกรรมศาสตร์", value: "FineAndAppliedArts" },
  ]);
  async function getFaculty1(token, val) {
    try {
      const response = await apiservice({
        path:
          "/course/getfaculty?page=1&totalpage=100&university_id=" +
          university_id +
          "&grade_id=" +
          val,
        method: "get",
        token: token?.accessToken,
      });

      if (response.status == 200) {
        let pagination = [];
        for (let index = 0; index < response.data.total_page; index++) {
          pagination = pagination.concat({});
        }
        setUseFaculty(response.data.result);
      }
    } catch (error) {
      throw error;
    }
  }
  async function Delete() {
    const Delete = await deleteFacuty({
      token: token?.accessToken,
      id: id,
    });
  }
  return (
    <View>
      <View
        style={{
          flexDirection: size.width < 500 ? "column" : "row",
          marginTop: 10,
          justifyContent: "space-between",
        }}
      >
        <View style={{ flexDirection: "row" }}>
          <TouchableOpacity onPress={ongoBack}>
            <Text style={styles.text23}>คอร์สมหาวิทยาลัย</Text>
          </TouchableOpacity>
          <Text
            style={[
              styles.text23,
              {
                marginHorizontal: 18,
              },
            ]}
          >
            /
          </Text>
          <Text style={[styles.text23, { color: "#0D88F0" }]}>คณะ</Text>
        </View>
        <TouchableOpacity
          onPress={() => {
            setModalAddItem(true);
          }}
          style={[styles.buttonAdd, { marginTop: size.width < 500 && 20 }]}
        >
          <Text style={styles.text16W}>+ Add New</Text>
        </TouchableOpacity>
      </View>

      <TextInput
        onChangeText={(text) => {
          setSearch(text);
        }}
        autoComplete="off"
        placeholder="ค้นหาชื่อคณะ"
        placeholderTextColor="#8C8896"
        style={{
          width: size.width < 500 ? "80%" : "30%",
          height: 48,
          borderRadius: 4,
          backgroundColor: "#EFEFF3",
          fontSize: 16,
          fontFamily: "Prompt",
          color: "#8C8896",
          paddingHorizontal: 25,
          marginTop: 20,
        }}
      />
      <View
        style={{
          flexDirection: size.width < 500 ? "column" : "row",
          marginTop: 30,
        }}
      >
        {degreeList.map((data, index) => (
          <TouchableOpacity
            key={data}
            style={{
              flexDirection: "row",
              alignItems: "center",
              marginLeft: index == 0 ? 0 : size.width < 500 ? 0 : width * 0.05,
            }}
            onPress={degreeChangeHandler.bind(this, index)}
          >
            <MaterialIcons
              name={
                index === deGreeIndex
                  ? "radio-button-checked"
                  : "radio-button-unchecked"
              }
              size={30}
              color={index === deGreeIndex ? "#0D88F0" : "#808D93"}
            />
            <Text
              style={[
                styles.text23,
                { color: index === deGreeIndex ? "#0D88F0" : "#808D93" },
              ]}
            >
              {data}
            </Text>
          </TouchableOpacity>
        ))}
      </View>
      {/* <RNPickerSelect
        placeholder={placeholder}
        onValueChange={(val) => {
        
        }}
        items={faculty}
        style={{
          inputWeb: {
            backgroundColor: "#FFFFFF",
            borderColor: "#0D88F0",
            width: 400,
            height: 32,
            color: "#0D88F0",
            fontFamily: "Prompt",
            fontSize: 16,
            paddingHorizontal: 10,
            marginVertical: height * 0.05,
          },
        }}
      /> */}
      <ScrollView horizontal>
        <View
          style={{
            width:
              size.width < 500
                ? size.width * 2.5
                : size.width < 1280
                ? size.width * 1.0
                : size.width * 0.78,
            // minHeight: height * 2.5,
          }}
        >
          <View style={styles.viewSubject}>
            <Text style={[styles.text16B, { paddingLeft: 67, width: "20%" }]}>
              ชื่อคณะ
            </Text>
            <Text style={[styles.text16B, { width: "20%" }]}>สาขา</Text>
            <Text style={[styles.text16B, { width: "20%" }]}>ระดับปริญญา</Text>
            <Text style={[styles.text16B, { width: "20%" }]}>วันที่สร้าง</Text>
            <Text style={[styles.text16B, { width: "15%" }]}>
              จำนวนคอร์สที่มี
            </Text>
            <Text style={[styles.text16B, { width: "5%" }]}></Text>
          </View>
          <FlatList
            numColumns={1}
            data={useFaculty
              .sort((a, b) => {
                if (
                  b.facuty_name_th + b.major + b.major_name_th >
                  a.facuty_name_th + a.major + a.major_name_th
                ) {
                  return -1;
                }
                if (
                  b.facuty_name_th + b.major + b.major_name_th <
                  a.facuty_name_th + a.major + a.major_name_th
                ) {
                  return 1;
                }
                return 0;
              })
              .filter((item) => {
                return item.facuty_name_th.includes(search);
              })}
            renderItem={({ item, index }) => {
              return (
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    borderBottomWidth: 1,
                    borderBottomColor: "#E5E5E5",
                    height: 50,
                    alignItems: "center",
                  }}
                >
                  <Text
                    style={[styles.text16B, { paddingLeft: 67, width: "20%" }]}
                  >
                    {item.facuty_name_th}
                  </Text>
                  <Text style={[styles.text16B, { width: "20%" }]}>
                    {item.major_name_th}
                  </Text>
                  <Text style={[styles.text16B, { width: "20%" }]}>
                    {item.major == 0 && "ปริญญาตรี"}
                    {item.major == 1 && "ปริญญาโท"}
                    {item.major == 2 && "ปริญญาเอก"}
                  </Text>
                  <Text style={[styles.text16B, { width: "20%" }]}>
                    {moment(item.createdAt)
                      .add(543, "year")
                      .format("DD MMMM YYYY")}
                  </Text>
                  <Text style={[styles.text16B, { width: "15%" }]}>
                    {item.course_length}
                  </Text>
                  <TouchableOpacity
                    onPress={() => {
                      setId(item.id);
                      setModalDelItem(true);
                    }}
                    style={{ width: "5%", alignItems: "center" }}
                  >
                    <MaterialIcons name="delete" size={24} color="black" />
                  </TouchableOpacity>
                </View>
              );
            }}
          />
        </View>
      </ScrollView>
      <Modal
        transparent={true}
        visible={modalAddItem}
        onRequestClose={() => {
          Alert.alert("Modal has been closed.");
          setModalAddItem(!modalAddItem);
        }}
      >
        <View style={styles.bgModal}>
          <View
            style={[
              styles.viewDetailModal1,
              {
                width:
                  size.width < 500 ? "90%" : size.width < 1280 ? "80%" : "50%",
              },
            ]}
          >
            <TextInput
              autoComplete="off"
              value={state.facuty_name_th}
              onChangeText={(text) => {
                if ((state.facuty_name_th = !"")) {
                  setWarningFaculty(false);
                  setWarningMojor(false);
                  setWarning(false);
                }
                setState({ ...state, facuty_name_th: text });
              }}
              placeholder={"กรอกชื่อคณะ"}
              placeholderTextColor={"#444444"}
              style={[
                styles.inputModal,
                { fontSize: size.width < 500 ? 18 : 35 },
              ]}
            />
            {warningFaculty && (
              <Text
                style={{
                  fontSize: 18,
                  fontFamily: "Prompt",
                  color: "#FF0000",
                }}
              >
                กรุณากรอกชื่อคณะ
              </Text>
            )}
            <TextInput
              autoComplete="off"
              value={state.major_name_th}
              onChangeText={(text) => {
                if ((state.major_name_th = !"")) {
                  setWarningFaculty(false);
                  setWarningMojor(false);
                  setWarning(false);
                }
                setState({ ...state, major_name_th: text });
              }}
              placeholder={"กรอกชื่อสาขา"}
              placeholderTextColor={"#444444"}
              style={[
                styles.inputModal,
                { marginTop: 30, fontSize: size.width < 500 ? 18 : 35 },
              ]}
            />
            {warningMajor && (
              <Text
                style={{
                  fontSize: 18,
                  fontFamily: "Prompt",
                  color: "#FF0000",
                }}
              >
                กรุณากรอกชื่อสาขา
              </Text>
            )}
            <Text
              style={{
                fontSize: 18,
                fontFamily: "Prompt",
                color: "#272D3B",
                marginTop: 30,
              }}
            >
              เลือกระดับปริญญา
            </Text>
            {warning && (
              <Text
                style={{
                  fontSize: 18,
                  fontFamily: "Prompt",
                  color: "#FF0000",
                }}
              >
                ***กรุณาเลือก***
              </Text>
            )}
            <View
              style={{
                flexDirection: size.width < 500 ? "column" : "row",
                marginTop: 40,
              }}
            >
              {degreeList1.map((data, index) => (
                <TouchableOpacity
                  key={data}
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    marginLeft:
                      index == 0 ? 0 : size.width < 500 ? 0 : width * 0.05,
                  }}
                  onPress={degreeModalChangeHandler.bind(this, index)}
                >
                  <MaterialIcons
                    name={
                      index === deGreeIndexModal
                        ? "radio-button-checked"
                        : "radio-button-unchecked"
                    }
                    size={30}
                    color={index === deGreeIndexModal ? "#0D88F0" : "#808D93"}
                  />
                  <Text
                    style={[
                      styles.text23,
                      {
                        color:
                          index === deGreeIndexModal ? "#0D88F0" : "#808D93",
                      },
                    ]}
                  >
                    {data}
                  </Text>
                </TouchableOpacity>
              ))}
            </View>
            <View
              style={{
                flexDirection: "row",
                alignSelf: "center",
                marginTop: 49,
              }}
            >
              <TouchableOpacity
                onPress={() => {
                  setState({
                    university_id: university_id,
                    facuty_name_th: "",
                    facuty_name_en: "",
                    major_name_th: "",
                    major_name_en: "",
                    major: deGreeIndexModal,
                  });
                  setModalAddItem(!modalAddItem);
                  setWarning(false);
                  setWarningMojor(false);
                  setWarningFaculty(false);
                }}
                style={styles.buttonModal}
              >
                <Text
                  style={[
                    styles.textButtonModal,
                    {
                      color: "#444444",
                    },
                  ]}
                >
                  ยกเลิก
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={addFacuty}
                style={[
                  styles.buttonModal,
                  {
                    backgroundColor: "#0D88F0",
                    marginLeft: width * 0.05,
                  },
                ]}
              >
                <Text
                  style={[
                    styles.textButtonModal,
                    {
                      color: "#FFFFFF",
                    },
                  ]}
                >
                  ยืนยัน
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </Modal>
      <Modal
        transparent={true}
        visible={modalDelItem}
        onRequestClose={() => {
          Alert.alert("Modal has been closed.");
          setModalDelItem(!modalDelItem);
        }}
      >
        <View style={styles.bgModal}>
          <View
            style={[
              styles.viewDetailModal,
              {
                width:
                  size.width < 500 ? "80%" : size.width < 1280 ? "70%" : "30%",
                height: 183,
                paddingVertical: 25,
              },
            ]}
          >
            <Text
              style={[
                styles.textButtonModal,
                { color: "#444444", alignSelf: "center" },
              ]}
            >
              คุณต้องการจะลบใช่รึไม่
            </Text>
            <View
              style={{
                flexDirection: "row",
                alignSelf: "center",
                marginTop: 39,
              }}
            >
              <TouchableOpacity
                onPress={() => setModalDelItem(!modalDelItem)}
                style={styles.buttonModal}
              >
                <Text
                  style={[
                    styles.textButtonModal,
                    {
                      color: "#444444",
                    },
                  ]}
                >
                  ยกเลิก
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => {
                  Delete();
                  setTimeout(() => {
                    getFaculty(token);
                    getProfile(token);
                  }, 500);
                  setModalDelItem(!modalDelItem);
                }}
                style={[
                  styles.buttonModal,
                  {
                    backgroundColor: "#0D88F0",
                    marginLeft: width * 0.05,
                  },
                ]}
              >
                <Text
                  style={[
                    styles.textButtonModal,
                    {
                      color: "#FFFFFF",
                    },
                  ]}
                >
                  ยืนยัน
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </Modal>
    </View>
  );
}
const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: "100%",
    backgroundColor: "#FFFFFF",
  },
  text23: {
    fontSize: 23,
    fontFamily: "Prompt",
    color: "#808D93",
  },
  text16B: {
    fontFamily: "Prompt",
    fontSize: 16,
    color: "#000000",
    width: "23%",
  },
  viewSubject: {
    width: "100%",
    height: 51,
    backgroundColor: "#E5E5E5",
    marginTop: 40,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  bgModal: {
    width: "100%",
    height: "100%",
    backgroundColor: "#000000bb",
    justifyContent: "center",
  },
  viewDetailModal: {
    width: "50%",
    height: 380,
    backgroundColor: "#FFFFFF",
    borderRadius: 20,
    alignSelf: "center",
    paddingHorizontal: width * 0.05,
    paddingVertical: 50,
  },
  viewDetailModal1: {
    width: "50%",

    backgroundColor: "#FFFFFF",
    borderRadius: 20,
    alignSelf: "center",
    paddingHorizontal: width * 0.05,
    paddingVertical: 50,
  },
  buttonModal: {
    width: 102,
    height: 48,
    borderRadius: 4,
    backgroundColor: "#EFEFF3",
    justifyContent: "center",
    alignItems: "center",
  },
  textButtonModal: {
    fontSize: 25,
    fontFamily: "Prompt",
  },
  text16W: {
    fontFamily: "Prompt",
    fontSize: 16,
    color: "#FFFFFF",
  },

  buttonAdd: {
    width: 140,
    height: 40,
    backgroundColor: "#0D88F0",
    borderRadius: 20,
    alignItems: "center",
    justifyContent: "center",
  },
  inputModal: {
    width: "100%",
    height: 67,
    borderRadius: 9,
    borderWidth: 1,
    borderColor: "#707070",
    fontSize: 35,
    fontFamily: "Prompt",
    color: "#444444",
    textAlign: "center",
  },
});
