import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  Image,
  Dimensions,
  Animated,
  TouchableOpacity,
  StyleSheet,
  ScrollView,
  TextInput,
  FlatList,
} from "react-native";
import {
  MaterialCommunityIcons,
  FontAwesome,
  FontAwesome5,
  MaterialIcons,
} from "@expo/vector-icons";
import Header from "../components/header";
import { BottomPart, bottomPart } from "./home";
import { useIsFocused } from "@react-navigation/native";
import { useRecoilState } from "recoil";
import { tokenState, UniversityState } from "../../reducer/recoil";
import { apiservice } from "../../service";
import { useWindowSize } from "../components/responsive";
const { width, height } = Dimensions.get("window");
export default function university({ navigation }) {
  const [universitys, setUniversitys] = useRecoilState(UniversityState);
  const [token, setToken] = useRecoilState(tokenState);
  const [img, setImg] = useState([]);
  const isfocused = useIsFocused();
  const [university, setUniversity] = useState([]);

  useEffect(() => {
    getUniversity();
  }, [token]);

  async function getUniversity() {
    try {
      const response = await apiservice({
        path: "/course/getuniversity?page=1&totalpage=100",
        method: "get",
      });
      if (response.status == 200) {
        // let pagination = [];
        // for (let index = 0; index < response.data.total_page; index++) {
        //   pagination = pagination.concat({});
        // }

        setUniversity(response.data.result);
        // setImg(response.data.result.img_univercity);
      }
    } catch (error) {
      throw error;
    }
  }

  const size = useWindowSize();
  return (
    <View style={styles.container}>
      <ScrollView stickyHeaderIndices={[0]}>
        <Header navigation={navigation} />
        <View
          style={{
            marginTop: 16,
            width: "100%",
            minHeight: height * 0.8,
            alignSelf: "center",
          }}
        >
          <View
            style={{
              width: "70%",

              alignSelf: "center",
            }}
          >
            <FlatList
              data={university}
              numColumns={width < 1280 ? 1 : 2}
              //scrollEnabled={false}
              renderItem={({ index, item }) => {
                return (
                  <TouchableOpacity
                    // disabled={item.img_univercity == "" ? true : false}
                    onPress={async () => {
                      const response = await apiservice({
                        path: "/banner/getunivercitybanner/" + item.id,
                        method: "get",
                        token: token?.accessToken,
                      });
                      if (response.status == 200) {
                        let data = {
                          data: response.data,
                          item,
                        };
                        setUniversitys(data);
                      }

                      setTimeout(() => {
                        navigation.navigate("RecommendUniversity");
                      }, 1000);
                    }}
                    style={{
                      alignItems: "center",
                      maxWidth: size.width < 1280 ? width * 0.8 : width * 0.3,
                      width: "100%",
                      marginHorizontal: size.width >= 1280 && 40,
                      marginVertical: 32,
                    }}
                  >
                    <Image
                      style={{
                        width: "100%",
                        height: 250,
                        backgroundColor: "#CCCCCC",
                      }}
                      resizeMode="stretch"
                      source={{
                        uri:
                          item.img_univercity != ""
                            ? "https://api.courseoncloud.com/api/image/getimage/" +
                              item.img_univercity
                            : "https://api.courseoncloud.com/api/image/getimage/" +
                              "missing02_11_2021",

                        //
                      }}
                    />
                    <Text
                      style={{
                        textAlign: "center",
                        fontSize: 20,
                        fontFamily: "Prompt",
                        color: "#0B2051",
                      }}
                    >
                      {item.university_name_th}
                    </Text>
                  </TouchableOpacity>
                );
              }}
            />
          </View>
        </View>

        <BottomPart navigation={navigation} />
      </ScrollView>
    </View>
  );
}
const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: "100%",
    backgroundColor: "#FFFFFF",
  },
});
