import React from "react";
import {
  View,
  Text,
  Image,
  Dimensions,
  Animated,
  TouchableOpacity,
  StyleSheet,
  ScrollView,
} from "react-native";
const { width, height } = Dimensions.get("window");
export default function menuHeader({
  Title,
  style,
  source,
  backgroundColor,
  onPress,
}) {
  return (
    <TouchableOpacity
      onPress={onPress}
      style={{
        flexDirection: "row",
        height: 65,
        width: "100%",
        backgroundColor: backgroundColor == "#FFFFFF" ? "#2B6AB1" : "#FFFFFF",
        borderRadius: 14,
        alignSelf: "center",
        alignItems: "center",
        paddingLeft: 40,
        marginTop: 10,
      }}
    >
      <View
        style={{
          maxWidth: 50,
          width: "100%",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <Image style={style} source={source} />
      </View>
      <Text
        style={{
          fontSize: 16,
          fontFamily: "Prompt",
          color: backgroundColor == "#FFFFFF" ? "#FFFFFF" : "#2B6AB1",
          marginLeft: 13,
        }}
      >
        {Title}
      </Text>
    </TouchableOpacity>
  );
}
