import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  Image,
  Dimensions,
  Animated,
  TouchableOpacity,
  StyleSheet,
  ScrollView,
  TextInput,
  FlatList,
} from "react-native";
import {
  MaterialCommunityIcons,
  FontAwesome,
  FontAwesome5,
  MaterialIcons,
} from "@expo/vector-icons";
import Header from "../components/header";
import { BottomPart, bottomPart } from "./home";
import { useRecoilState } from "recoil";
import { tokenState, UniversityState } from "../../reducer/recoil";
import { useIsFocused } from "@react-navigation/native";
import { apiservice } from "../../service";
import moment from "moment";
import { useWindowSize } from "../components/responsive";
const { width, height } = Dimensions.get("window");
export default function recommendUniversity({ navigation, route }) {
  useEffect(() => {
    // getUniversityBanner(token);
  }, [token]);
  const [token, setToken] = useRecoilState(tokenState);
  const [university, setUniversity] = useRecoilState(UniversityState);
  const universitys = university;

  const [data, setData] = useState({});
  const university_id = universitys.id;
  const university_name = university.university_name_th;
  const major = university.major;

  // async function getUniversityBanner(token) {
  //   try {
  //     const response = await apiservice({
  //       path: "/banner/getunivercitybanner/" + university.id,
  //       method: "get",
  //       token: token?.accessToken,
  //     });
  //     if (response.status == 200) {
  //       setData(response.data);

  //     }
  //   } catch (error) {
  //     throw error;
  //   }
  // }
  // if (universitys.major == undefined) {
  //   return null;
  // }
  const size = useWindowSize();
  return (
    <View style={styles.container}>
      <ScrollView stickyHeaderIndices={[0]}>
        <Header unShadow navigation={navigation} />
        <Image
          resizeMode={"stretch"}
          style={{
            width: "100%",
            height: size.width < 1280 ? 200 : 400,
            backgroundColor: "#CCCCCC",
          }}
          source={{
            uri:
              university.data != null &&
              "https://api.courseoncloud.com/api/image/getimage/" +
                university.data.img_header,
          }}
        />
        <Text
          style={[styles.textTitle, { fontSize: size.width < 1280 ? 22 : 40 }]}
        >
          หลักสูตรที่เปิดสอน
        </Text>
        {university?.data?.one != null && (
          <View
            style={[
              styles.viewCourse,
              { flexDirection: size.width < 500 ? "column" : "row" },
            ]}
          >
            <View
              style={{
                flexDirection: size.width < 500 ? "column" : "row",
                width: size.width < 500 ? "100%" : "80%",
              }}
            >
              <Image
                style={{
                  maxWidth: width < 1600 ? 450 : 520,
                  width: "100%",
                  height: width < 1600 ? 250 : 320,
                  backgroundColor: "#CCCCCC",
                }}
                source={{
                  uri:
                    university.data.one != null &&
                    "https://api.courseoncloud.com/api/image/getimage/" +
                      university.data.one,
                }}
              />
              <Text
                style={{
                  fontSize: width < 1600 ? 20 : 35,
                  fontFamily: "Prompt",
                  color: "#0B2051",
                  marginLeft: size.width > 500 && 80,
                  textAlign: size.width < 500 && "center",
                }}
              >
                หลักสูตร : ปริญญาตรี
              </Text>
            </View>
            <TouchableOpacity
              onPress={() => {
                let data = {
                  unniversity: universitys,
                };

                navigation.navigate("CourseUniversity", { data });
              }}
              style={[
                styles.buttonReadDetail,
                { alignSelf: size.width < 500 ? "center" : "flex-end" },
              ]}
            >
              <Text
                style={[
                  styles.text20O,
                  { fontSize: size.width < 500 ? 16 : 20 },
                ]}
              >
                วิชาที่เปิดสอน
              </Text>
            </TouchableOpacity>
          </View>
        )}
        {university?.data?.two != null && (
          <View
            style={[
              styles.viewCourse,
              {
                borderTopWidth: 0,
                flexDirection: size.width < 500 ? "column" : "row",
              },
            ]}
          >
            <View
              style={{
                flexDirection: size.width < 500 ? "column" : "row",
                width: size.width < 500 ? "100%" : "80%",
              }}
            >
              <Image
                style={{
                  maxWidth: width < 1600 ? 450 : 520,
                  width: "100%",
                  height: width < 1600 ? 250 : 320,
                  backgroundColor: "#CCCCCC",
                }}
                source={{
                  uri:
                    university.data.two != null &&
                    "https://api.courseoncloud.com/api/image/getimage/" +
                      university.data.two,
                }}
              />
              <Text
                style={{
                  fontSize: width < 1600 ? 30 : 35,
                  fontFamily: "Prompt",
                  color: "#0B2051",
                  marginLeft: size.width > 500 && 80,
                }}
              >
                หลักสูตร : ปริญญาโท
              </Text>
            </View>
            <TouchableOpacity
              onPress={() => {
                let data = {
                  unniversity: universitys,
                };

                navigation.navigate("CourseUniversity", { data });
              }}
              style={[
                styles.buttonReadDetail,
                { alignSelf: size.width < 500 ? "center" : "flex-end" },
              ]}
            >
              <Text
                style={[
                  styles.text20O,
                  { fontSize: size.width < 500 ? 16 : 20 },
                ]}
              >
                วิชาที่เปิดสอน
              </Text>
            </TouchableOpacity>
          </View>
        )}
        {university?.data?.three != null && (
          <View
            style={[
              styles.viewCourse,
              {
                borderTopWidth: 0,
                flexDirection: size.width < 500 ? "column" : "row",
              },
            ]}
          >
            <View
              style={{
                flexDirection: size.width < 500 ? "column" : "row",
                width: size.width < 500 ? "100%" : "80%",
              }}
            >
              <Image
                style={{
                  maxWidth: width < 1600 ? 450 : 520,
                  width: "100%",
                  height: width < 1600 ? 250 : 320,
                  backgroundColor: "#CCCCCC",
                }}
                source={{
                  uri:
                    university.data.three != null &&
                    "https://api.courseoncloud.com/api/image/getimage/" +
                      university.data.three,
                }}
              />
              <Text
                style={{
                  fontSize: width < 1600 ? 30 : 35,
                  fontFamily: "Prompt",
                  color: "#0B2051",
                  marginLeft: size.width > 500 && 80,
                }}
              >
                หลักสูตร : ปริญญาเอก
              </Text>
            </View>
            <TouchableOpacity
              onPress={() => {
                let data = {
                  unniversity: universitys,
                };

                navigation.navigate("CourseUniversity", { data });
              }}
              style={[
                styles.buttonReadDetail,
                { alignSelf: size.width < 500 ? "center" : "flex-end" },
              ]}
            >
              <Text
                style={[
                  styles.text20O,
                  { fontSize: size.width < 500 ? 16 : 20 },
                ]}
              >
                วิชาที่เปิดสอน
              </Text>
            </TouchableOpacity>
          </View>
        )}
        <View
          style={[
            styles.viewDescription,
            { flexDirection: size.width < 1280 ? "column" : "row" },
          ]}
        >
          <View style={{ width: size.width < 1280 ? "100%" : "60%" }}>
            <Text
              adjustsFontSizeToFit
              style={{
                fontSize: 30,
                fontFamily: "Prompt",
                color: "#0B2051",
                width: "100%",
              }}
            >
              {university?.item?.university_name_th}
            </Text>
            <Text
              adjustsFontSizeToFit
              style={{
                fontSize: 18,
                fontFamily: "Prompt",
                color: "#0B2051",
                marginTop: 23,
              }}
            >
              {university?.data?.description}
            </Text>
          </View>
          <Image
            style={{
              maxWidth: width < 1600 ? 400 : 520,
              width: "100%",
              height: width < 1600 ? 250 : 320,
              backgroundColor: "#CCCCCC",
            }}
            source={{
              uri:
                university?.data != null &&
                "https://api.courseoncloud.com/api/image/getimage/" +
                  university.data.img_cover,
            }}
          />
        </View>
        <BottomPart navigation={navigation} />
      </ScrollView>
    </View>
  );
}
const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: "100%",
    backgroundColor: "#FFFFFF",
  },
  viewCourse: {
    borderTopWidth: 1,
    borderTopColor: "#0D88F0",
    borderBottomWidth: 1,
    borderBottomColor: "#0D88F0",
    flexDirection: "row",
    width: "80%",
    alignSelf: "center",
    paddingVertical: 40,
    justifyContent: "space-between",
  },
  textTitle: {
    fontSize: 40,
    fontFamily: "Prompt",
    color: "#0B2051",
    alignSelf: "center",
    marginVertical: 30,
  },
  buttonReadDetail: {
    width: 180,
    height: 60,
    borderWidth: 1,
    borderColor: "#0D88F0",
    borderRadius: 5,
    alignItems: "center",
    justifyContent: "center",
    alignSelf: "flex-end",
  },
  viewDescription: {
    flexDirection: "row",
    width: "80%",
    marginTop: 65,
    justifyContent: "space-between",
    alignSelf: "center",
    marginBottom: height * 0.2,
  },
  text20O: { fontSize: 20, fontFamily: "Prompt", color: "#0D88F0" },
});
