import React, { useState, useEffect, useRef } from "react";
import {
  View,
  Text,
  Image,
  Dimensions,
  Animated,
  TouchableOpacity,
  StyleSheet,
  ScrollView,
  TextInput,
  FlatList,
} from "react-native";
import {
  MaterialCommunityIcons,
  FontAwesome,
  FontAwesome5,
  MaterialIcons,
} from "@expo/vector-icons";
import Header from "../components/header";
import { BottomPart, bottomPart } from "./home";
import { useRecoilState } from "recoil";
import { courseState, tokenState } from "../../reducer/recoil";
import { apiservice } from "../../service";
import { useWindowSize } from "../components/responsive";
import NumberFormat from "react-number-format";
import RBSheet from "react-native-raw-bottom-sheet";
import Modal from "modal-react-native-web";
const { width, height } = Dimensions.get("window");
export default function courseUniversity({ navigation, route }) {
  const [token, setToken] = useRecoilState(tokenState);
  const [courseStates, setCourseStates] = useRecoilState(courseState);
  const [rating, setrating] = useState();
  const [modalVisible, setModalVisible] = useState(false);
  const [course, setCourse] = useState([]);
  const [body, setBody] = useState({
    category: [],
    apt: 0,
    rating: 5,
  });

  useEffect(() => {
    if (route.params) {
      setCourse(route.params.main);
    } else {
      getAllCourse();
    }
    cateAll();
  }, [token]);

  async function cateAll() {
    const response = await apiservice({
      path: "/category/getall?page=1&totalpage=100",
      method: "get",
    });
    if (response.status == 200) {
      setData(response.data.result);
    }
  }

  async function getAllCourse() {
    try {
      const response = await apiservice({
        path: "/course/getall?page=1&lan=TH",
        method: "get",
        //token: token?.accessToken,
      });
      if (response.status == 200) {
        let pagination = [];
        for (let index = 0; index < response.data.total_page; index++) {
          pagination = pagination.concat({});
        }

        setCourse(response.data.data4);
      }
    } catch (error) {
      throw error;
    }
  }

  const [state, setstate] = useState();
  const [DATA, setData] = useState([]);
  const DATA1 = [
    { name: "Beginner", value: "BEGINNER" },
    { name: "Intermediate", value: "INTERMEDIATE" },
    { name: "Advanced", value: "ADVANCED" },
  ];
  const DATA2 = [{ name: "แพง > ถูก" }, { name: "ถูก > แพง" }];

  const DATA4 = [
    {
      name: (
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <FontAwesome
            name="star"
            size={width < 1366 ? 16 : 16}
            color="#646464"
          />
          <FontAwesome
            name="star"
            size={width < 1366 ? 16 : 16}
            color="#646464"
          />
          <FontAwesome
            name="star"
            size={width < 1366 ? 16 : 16}
            color="#646464"
          />
          <FontAwesome
            name="star"
            size={width < 1366 ? 16 : 16}
            color="#646464"
          />
          <FontAwesome
            name="star-o"
            size={width < 1366 ? 16 : 16}
            color="#646464"
          />
          <Text style={{ color: "#646464" }}>{" - "}</Text>
          <View style={{ flexDirection: "row" }}>
            <FontAwesome
              name="star"
              size={width < 1366 ? 16 : 16}
              color="#646464"
            />
            <FontAwesome
              name="star"
              size={width < 1366 ? 16 : 16}
              color="#646464"
            />
            <FontAwesome
              name="star"
              size={width < 1366 ? 16 : 16}
              color="#646464"
            />
            <FontAwesome
              name="star"
              size={width < 1366 ? 16 : 16}
              color="#646464"
            />
            <FontAwesome
              name="star"
              size={width < 1366 ? 16 : 16}
              color="#646464"
            />
          </View>
        </View>
      ),
    },
    {
      name: (
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <FontAwesome
            name="star"
            size={width < 1366 ? 16 : 16}
            color="#646464"
          />
          <FontAwesome
            name="star"
            size={width < 1366 ? 16 : 16}
            color="#646464"
          />
          <FontAwesome
            name="star"
            size={width < 1366 ? 16 : 16}
            color="#646464"
          />
          <FontAwesome
            name="star-o"
            size={width < 1366 ? 16 : 16}
            color="#646464"
          />
          <FontAwesome
            name="star-o"
            size={width < 1366 ? 16 : 16}
            color="#646464"
          />
          <Text style={{ color: "#646464" }}>{" - "}</Text>
          <View style={{ flexDirection: "row" }}>
            <FontAwesome
              name="star"
              size={width < 1366 ? 16 : 16}
              color="#646464"
            />
            <FontAwesome
              name="star"
              size={width < 1366 ? 16 : 16}
              color="#646464"
            />
            <FontAwesome
              name="star"
              size={width < 1366 ? 16 : 16}
              color="#646464"
            />
            <FontAwesome
              name="star-half-o"
              size={width < 1366 ? 16 : 16}
              color="#646464"
            />
            <FontAwesome
              name="star-o"
              size={width < 1366 ? 16 : 16}
              color="#646464"
            />
          </View>
        </View>
      ),
    },
    {
      name: (
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <FontAwesome
            name="star"
            size={width < 1366 ? 16 : 16}
            color="#646464"
          />
          <FontAwesome
            name="star"
            size={width < 1366 ? 16 : 16}
            color="#646464"
          />
          <FontAwesome
            name="star-o"
            size={width < 1366 ? 16 : 16}
            color="#646464"
          />
          <FontAwesome
            name="star-o"
            size={width < 1366 ? 16 : 16}
            color="#646464"
          />
          <FontAwesome
            name="star-o"
            size={width < 1366 ? 16 : 16}
            color="#646464"
          />
          <Text style={{ color: "#646464" }}>{" - "}</Text>
          <View style={{ flexDirection: "row" }}>
            <FontAwesome
              name="star"
              size={width < 1366 ? 16 : 16}
              color="#646464"
            />
            <FontAwesome
              name="star"
              size={width < 1366 ? 16 : 16}
              color="#646464"
            />
            <FontAwesome
              name="star-half-o"
              size={width < 1366 ? 16 : 16}
              color="#646464"
            />
            <FontAwesome
              name="star-o"
              size={width < 1366 ? 16 : 16}
              color="#646464"
            />
            <FontAwesome
              name="star-o"
              size={width < 1366 ? 16 : 16}
              color="#646464"
            />
          </View>
        </View>
      ),
    },
    {
      name: (
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <FontAwesome
            name="star"
            size={width < 1366 ? 16 : 16}
            color="#646464"
          />
          <FontAwesome
            name="star-o"
            size={width < 1366 ? 16 : 16}
            color="#646464"
          />
          <FontAwesome
            name="star-o"
            size={width < 1366 ? 16 : 16}
            color="#646464"
          />
          <FontAwesome
            name="star-o"
            size={width < 1366 ? 16 : 16}
            color="#646464"
          />
          <FontAwesome
            name="star-o"
            size={width < 1366 ? 16 : 16}
            color="#646464"
          />
          <Text style={{ color: "#646464" }}>{" - "}</Text>
          <View style={{ flexDirection: "row" }}>
            <FontAwesome
              name="star"
              size={width < 1366 ? 16 : 16}
              color="#646464"
            />
            <FontAwesome
              name="star-half-o"
              size={width < 1366 ? 16 : 16}
              color="#646464"
            />
            <FontAwesome
              name="star-o"
              size={width < 1366 ? 16 : 16}
              color="#646464"
            />
            <FontAwesome
              name="star-o"
              size={width < 1366 ? 16 : 16}
              color="#646464"
            />
            <FontAwesome
              name="star-o"
              size={width < 1366 ? 16 : 16}
              color="#646464"
            />
          </View>
        </View>
      ),
    },
    {
      name: (
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <FontAwesome
            name="star-o"
            size={width < 1366 ? 16 : 16}
            color="#646464"
          />
          <FontAwesome
            name="star-o"
            size={width < 1366 ? 16 : 16}
            color="#646464"
          />
          <FontAwesome
            name="star-o"
            size={width < 1366 ? 16 : 16}
            color="#646464"
          />
          <FontAwesome
            name="star-o"
            size={width < 1366 ? 16 : 16}
            color="#646464"
          />
          <FontAwesome
            name="star-o"
            size={width < 1366 ? 16 : 16}
            color="#646464"
          />
          <Text style={{ color: "#646464" }}>{" - "}</Text>
          <View style={{ flexDirection: "row" }}>
            <FontAwesome
              name="star-half-o"
              size={width < 1366 ? 16 : 16}
              color="#646464"
            />
            <FontAwesome
              name="star-o"
              size={width < 1366 ? 16 : 16}
              color="#646464"
            />
            <FontAwesome
              name="star-o"
              size={width < 1366 ? 16 : 16}
              color="#646464"
            />
            <FontAwesome
              name="star-o"
              size={width < 1366 ? 16 : 16}
              color="#646464"
            />
            <FontAwesome
              name="star-o"
              size={width < 1366 ? 16 : 16}
              color="#646464"
            />
          </View>
        </View>
      ),
    },
  ];
  const refRBSheet = useRef();
  const size = useWindowSize();

  return (
    <View style={styles.container}>
      <RBSheet
        ref={refRBSheet}
        closeOnDragDown={true}
        closeOnPressMask={false}
        customStyles={{
          wrapper: {
            backgroundColor: "transparent",
          },
          draggableIcon: {
            backgroundColor: "#000",
          },
        }}
      >
        <View>
          <Text>dfsf</Text>
        </View>
      </RBSheet>
      <ScrollView stickyHeaderIndices={[0]}>
        <Header navigation={navigation} />
        <View style={styles.view}>
          {size.width >= 1280 && (
            <View
              style={[
                styles.viewChoice,
                { maxHeight: size.width < 1500 ? 980 : 950 },
              ]}
            >
              <Text style={styles.textTitle}>หมวดหมู่</Text>
              <View>
                <FlatList
                  data={DATA}
                  numColumns={1}
                  extraData={[body]}
                  scrollEnabled={false}
                  renderItem={({ index, item }) => {
                    return (
                      <TouchableOpacity
                        onPress={() => {
                          setBody((val) => ({
                            ...val,
                            category:
                              val?.category?.filter((items) => items == item.id)
                                .length > 0
                                ? val?.category?.filter(
                                    (items) => items != item.id
                                  )
                                : val.category.concat(item.id),
                          }));
                        }}
                        style={{ flexDirection: "row" }}
                      >
                        <MaterialCommunityIcons
                          name={
                            body.category.filter((items) => items == item.id)
                              .length > 0
                              ? "checkbox-blank"
                              : "checkbox-blank-outline"
                          }
                          size={24}
                          color="#AAA"
                        />
                        <Text style={[styles.textTitle, { marginLeft: 7 }]}>
                          {item.title}
                        </Text>
                      </TouchableOpacity>
                    );
                  }}
                />
              </View>
              <Text style={[styles.textTitle, { marginTop: 20 }]}>
                ระดับความถนัด
              </Text>
              <View>
                <FlatList
                  data={DATA1}
                  extraData={[body]}
                  numColumns={1}
                  scrollEnabled={false}
                  renderItem={({ index, item }) => {
                    return (
                      <TouchableOpacity
                        onPress={() => {
                          if (index == 0) {
                            setBody((val) => ({ ...val, apt: "BEGINNER" }));
                          }
                          if (index == 1) {
                            setBody((val) => ({ ...val, apt: "INTERMEDIATE" }));
                          }
                          if (index == 2) {
                            setBody((val) => ({ ...val, apt: "ADVANCED" }));
                          }
                        }}
                        style={{ flexDirection: "row" }}
                      >
                        <MaterialCommunityIcons
                          name={
                            body.apt == item.value
                              ? "checkbox-blank"
                              : "checkbox-blank-outline"
                          }
                          size={24}
                          color="#AAA"
                        />
                        <Text style={[styles.textTitle, { marginLeft: 7 }]}>
                          {item.name}
                        </Text>
                      </TouchableOpacity>
                    );
                  }}
                />
              </View>
              <Text style={[styles.textTitle, { marginTop: 20 }]}>
                ความนิยม
              </Text>
              <View>
                <FlatList
                  data={DATA4}
                  extraData={[body]}
                  numColumns={1}
                  scrollEnabled={false}
                  renderItem={({ index, item }) => {
                    return (
                      <TouchableOpacity
                        onPress={() => {
                          setBody((val) => ({
                            ...val,
                            rating: index,
                          }));
                        }}
                        style={{ flexDirection: "row" }}
                      >
                        <MaterialCommunityIcons
                          name={
                            body.rating == index
                              ? "checkbox-blank"
                              : "checkbox-blank-outline"
                          }
                          size={24}
                          color="#AAA"
                        />
                        <Text style={[styles.textTitle, { marginLeft: 7 }]}>
                          {item.name}
                        </Text>
                      </TouchableOpacity>
                    );
                  }}
                />
              </View>
              <Text style={[styles.textTitle, { marginTop: 20 }]}>ราคา</Text>
              <View>
                <FlatList
                  data={DATA2}
                  numColumns={1}
                  scrollEnabled={false}
                  renderItem={({ index, item }) => {
                    return (
                      <TouchableOpacity
                        onPress={() => {
                          setBody((val) => ({ ...val, expensive: index == 0 }));
                        }}
                        style={{ flexDirection: "row" }}
                      >
                        {index == 0 && (
                          <MaterialCommunityIcons
                            name={
                              body.expensive
                                ? "checkbox-blank"
                                : "checkbox-blank-outline"
                            }
                            size={24}
                            color="#AAA"
                          />
                        )}
                        {index == 1 && (
                          <MaterialCommunityIcons
                            name={
                              body.expensive == false
                                ? "checkbox-blank"
                                : "checkbox-blank-outline"
                            }
                            size={24}
                            color="#AAA"
                          />
                        )}
                        <Text style={[styles.textTitle, { marginLeft: 7 }]}>
                          {item.name}
                        </Text>
                      </TouchableOpacity>
                    );
                  }}
                />
              </View>
              <TouchableOpacity
                onPress={async () => {
                  const response = await apiservice({
                    method: "post",
                    path: "/course/filtersearch?page=1",
                    body,
                    token: token?.accessToken,
                  });
                  if (response.status == 200) {
                    setCourse(response.data.main);
                  }
                }}
                style={styles.buttonSearch}
              >
                <Text style={styles.textSearch}>ค้นหา</Text>
              </TouchableOpacity>
            </View>
          )}
          <View
            style={[
              styles.viewSelectCourse,
              {
                width: size.width < 1280 ? "100%" : "80%",
                paddingHorizontal: size.width < 500 ? 0 : 40,
              },
            ]}
          >
            {size.width < 1280 && (
              <TouchableOpacity
                onPress={() => {
                  setModalVisible(true);
                }}
                style={{ position: "absolute", right: 10, marginTop: 10 }}
              >
                <FontAwesome name="filter" size={24} color="#484848" />
              </TouchableOpacity>
            )}
            <Text
              style={[
                styles.textAllCourse,
                { fontSize: width < 1280 ? 35 : 70, alignSelf: "center" },
              ]}
            >
              คอร์สทั้งหมด
            </Text>
            {width >= 500 ? (
              <FlatList
                data={course?.filter((item) => item.university_id == null)}
                numColumns={
                  (width >= 1600 && 4) ||
                  (width < 1600 && width >= 1280 && 3) ||
                  (width < 1280 && width >= 1025 && 4) ||
                  (width < 1025 && width >= 850 && 3) ||
                  (width < 850 && width >= 500 && 2) ||
                  (width < 500 && 1)
                }
                scrollEnabled={width < 500 ? true : false}
                renderItem={({ index, item }) => {
                  return (
                    <TouchableOpacity
                      onPress={() => {
                        setCourseStates(item);
                        setTimeout(() => {
                          navigation.navigate("Course");
                        }, 900);
                      }}
                      style={[
                        styles.viewDetail,
                        {
                          maxWidth: width <= 1700 ? 240 : 278,
                          marginLeft: 25,
                          marginTop: 25,
                          marginBottom: index > 8 && 65,
                          borderWidth: 1,
                          borderColor: "#BBB",
                        },
                      ]}
                    >
                      <Image
                        style={styles.imgDetail}
                        source={{
                          uri:
                            "https://api.courseoncloud.com/api/image/getimage/" +
                            item.img_content,
                        }}
                      />
                      <View style={styles.viewCourse}>
                        <Text
                          style={[styles.text, { color: "#0D88F0" }]}
                          numberOfLines={1}
                        >
                          {item.title}
                        </Text>
                        <View style={styles.viewImg}>
                          <Image
                            style={styles.img}
                            source={{
                              uri:
                                "https://api.courseoncloud.com/api/image/getimage/" +
                                item?.user?.user_accounts?.image_url,
                            }}
                          />
                          <Text style={styles.text}>
                            {item?.user?.user_accounts?.firstName}{" "}
                            {item?.user?.user_accounts?.lastName}
                          </Text>
                        </View>
                        <View style={styles.feedback}>
                          <View
                            style={{
                              flexDirection: "row",
                              alignSelf: "flex-end",
                            }}
                          >
                            {/* <FontAwesome
                              name={item.rating > 0 ? "star" : "star-o"}
                              size={size.width < 500 ? 15 : 20}
                              color="#0D88F0"
                            />
                            <FontAwesome
                              name={item.rating > 1 ? "star" : "star-o"}
                              size={size.width < 500 ? 15 : 20}
                              color="#0D88F0"
                            />
                            <FontAwesome
                              name={item.rating > 2 ? "star" : "star-o"}
                              size={size.width < 500 ? 15 : 20}
                              color="#0D88F0"
                            />
                            <FontAwesome
                              name={item.rating > 3 ? "star" : "star-o"}
                              size={size.width < 500 ? 15 : 20}
                              color="#0D88F0"
                            />
                            <FontAwesome
                              name={item.rating > 4 ? "star" : "star-o"}
                              size={size.width < 500 ? 15 : 20}
                              color="#0D88F0"
                            /> */}
                          </View>
                          <View>
                            {item.type == "COURSESCHOOL" ? (
                              <View
                                style={{
                                  height: 20,
                                  backgroundColor: "#646464",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  borderRadius: 10,
                                  marginVertical: 5,
                                  alignSelf: "flex-start",
                                }}
                              >
                                <Text
                                  style={{
                                    fontFamily: "Prompt",
                                    fontSize: 12,
                                    color: "#FFFFFF",
                                    paddingHorizontal: 10,
                                  }}
                                >
                                  เฉพาะนักศึกษา
                                </Text>
                              </View>
                            ) : (
                              <View style={{ flexDirection: "row" }}>
                                <Text
                                  style={[
                                    styles.text,
                                    {
                                      textAlign: "right",
                                      fontSize: size.width < 500 ? 12 : 16,
                                      textDecorationLine:
                                        item.discount != 0 ||
                                        (item.discount != null &&
                                          "line-through"),
                                    },
                                  ]}
                                >
                                  <NumberFormat
                                    value={item.price == 0 ? "" : item.price}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                  />{" "}
                                  {item.price == 0
                                    ? "ฟรี"
                                    : item.discount == 0 ||
                                      (item.discount == null && "บาท")}
                                </Text>
                                {item.discount != 0 ||
                                  (item.discount != null && (
                                    <Text
                                      style={[
                                        styles.text,
                                        {
                                          textAlign: "right",
                                          fontSize: size.width < 500 ? 12 : 16,
                                        },
                                      ]}
                                    >
                                      {item.price -
                                        (parseInt(item.discount) * item.price) /
                                          100}
                                      {item.discount != 0 && " บาท"}
                                    </Text>
                                  ))}
                              </View>
                            )}
                            <Text
                              style={[
                                styles.text,
                                {
                                  fontSize: size.width < 500 ? 12 : 16,
                                  textAlign: "right",
                                },
                              ]}
                            >
                              <NumberFormat
                                value={item.lengthdata}
                                displayType={"text"}
                                thousandSeparator={true}
                              />{" "}
                              <FontAwesome5
                                name="user-friends"
                                size={20}
                                color="#646464"
                              />
                            </Text>
                          </View>
                        </View>
                      </View>
                    </TouchableOpacity>
                  );
                }}
              />
            ) : (
              <View style={{ marginLeft: 10 }}>
                <FlatList
                  data={course.filter((item) => item.university_id == null)}
                  numColumns={1}
                  renderItem={({ index, item }) => {
                    return (
                      <TouchableOpacity
                        onPress={() => {
                          setCourseStates(item);
                          setTimeout(() => {
                            navigation.navigate("Course");
                          }, 900);
                        }}
                        style={[
                          styles.viewDetail,
                          {
                            maxWidth: width <= 1700 ? 240 : 278,
                            marginLeft: 40,
                            marginTop: 25,
                            marginBottom: index > 8 && 65,
                          },
                        ]}
                      >
                        <Image
                          style={styles.imgDetail}
                          source={{
                            uri:
                              "https://api.courseoncloud.com/api/image/getimage/" +
                              item.img_content,
                          }}
                        />
                        <View style={styles.viewCourse}>
                          <Text style={styles.text} numberOfLines={2}>
                            {item.title}
                          </Text>
                          <View style={styles.viewImg}>
                            <Image
                              style={styles.img}
                              source={{
                                uri:
                                  "https://api.courseoncloud.com/api/image/getimage/" +
                                  item?.user?.user_accounts?.image_url,
                              }}
                            />
                            <Text style={styles.text}>
                              {item?.user?.user_accounts?.firstName}{" "}
                              {item?.user?.user_accounts?.lastName}
                            </Text>
                          </View>
                          <View style={styles.feedback}>
                            <View
                              style={{
                                flexDirection: "row",
                                alignSelf: "flex-end",
                              }}
                            >
                              <FontAwesome
                                name="star"
                                size={20}
                                color="#0D88F0"
                              />
                              <FontAwesome
                                name="star"
                                size={20}
                                color="#0D88F0"
                              />
                              <FontAwesome
                                name="star"
                                size={20}
                                color="#0D88F0"
                              />
                              <FontAwesome
                                name="star"
                                size={20}
                                color="#0D88F0"
                              />
                              <FontAwesome
                                name="star"
                                size={20}
                                color="#0D88F0"
                              />
                            </View>
                            <View>
                              <Text
                                style={[
                                  styles.text,
                                  {
                                    textAlign: "right",
                                    fontSize: size.width < 500 ? 12 : 16,
                                  },
                                ]}
                              >
                                <NumberFormat
                                  value={item.price}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                />{" "}
                                {"บาท"}
                              </Text>
                              <Text
                                style={[
                                  styles.text,
                                  { fontSize: size.width < 500 ? 12 : 16 },
                                ]}
                              >
                                <NumberFormat
                                  value={item.lengthdata}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                />{" "}
                                <FontAwesome5
                                  name="user-friends"
                                  size={20}
                                  color="#646464"
                                />
                              </Text>
                            </View>
                          </View>
                        </View>
                      </TouchableOpacity>
                    );
                  }}
                />
              </View>
            )}
          </View>
        </View>
        <BottomPart navigation={navigation} />
      </ScrollView>
      <Modal
        transparent={true}
        visible={modalVisible}
        onRequestClose={() => {
          Alert.alert("Modal has been closed.");
          setModalVisible(!modalVisible);
        }}
      >
        <View
          style={{
            width: "100%",
            height: "100%",
            backgroundColor: "#000000bb",
            justifyContent: "center",
          }}
        >
          <View
            style={{
              width: "90%",

              backgroundColor: "#FFFFFF",
              borderWidth: 1,
              borderColor: "#0D88F0",
              alignSelf: "center",
              padding: 15,
            }}
          >
            <ScrollView>
              <TouchableOpacity
                onPress={() => {
                  setModalVisible(!modalVisible);
                }}
                style={{ position: "absolute", alignSelf: "flex-end" }}
              >
                <MaterialCommunityIcons
                  name="close"
                  size={24}
                  color="#0D88F0"
                />
              </TouchableOpacity>
              <View
                style={{
                  width: "100%",

                  borderWidth: 1,
                  borderColor: "#EEEEEE",
                  borderRadius: 5,
                  padding: 12,
                  marginTop: 20,
                }}
              >
                <Text style={styles.textTitle}>หมวดหมู่</Text>
                <View>
                  <FlatList
                    data={DATA}
                    numColumns={1}
                    extraData={[body]}
                    scrollEnabled={false}
                    renderItem={({ index, item }) => {
                      return (
                        <TouchableOpacity
                          onPress={() => {
                            setBody((val) => ({
                              ...val,
                              category:
                                val?.category?.filter(
                                  (items) => items == item.id
                                ).length > 0
                                  ? val?.category?.filter(
                                      (items) => items != item.id
                                    )
                                  : val.category.concat(item.id),
                            }));
                          }}
                          style={{ flexDirection: "row" }}
                        >
                          <MaterialCommunityIcons
                            name={
                              body.category.filter((items) => items == item.id)
                                .length > 0
                                ? "checkbox-blank"
                                : "checkbox-blank-outline"
                            }
                            size={24}
                            color="#AAA"
                          />
                          <Text style={[styles.textTitle, { marginLeft: 7 }]}>
                            {item.title}
                          </Text>
                        </TouchableOpacity>
                      );
                    }}
                  />
                </View>
                <Text style={[styles.textTitle, { marginTop: 20 }]}>
                  ระดับความถนัด
                </Text>
                <View>
                  <FlatList
                    data={DATA1}
                    extraData={[body]}
                    numColumns={1}
                    scrollEnabled={false}
                    renderItem={({ index, item }) => {
                      return (
                        <TouchableOpacity
                          onPress={() => {
                            if (index == 0) {
                              setBody((val) => ({ ...val, apt: "BEGINNER" }));
                            }
                            if (index == 1) {
                              setBody((val) => ({
                                ...val,
                                apt: "INTERMEDIATE",
                              }));
                            }
                            if (index == 2) {
                              setBody((val) => ({ ...val, apt: "ADVANCED" }));
                            }
                          }}
                          style={{ flexDirection: "row" }}
                        >
                          <MaterialCommunityIcons
                            name={
                              body.apt == item.value
                                ? "checkbox-blank"
                                : "checkbox-blank-outline"
                            }
                            size={24}
                            color="#AAA"
                          />
                          <Text style={[styles.textTitle, { marginLeft: 7 }]}>
                            {item.name}
                          </Text>
                        </TouchableOpacity>
                      );
                    }}
                  />
                </View>
                <Text style={[styles.textTitle, { marginTop: 20 }]}>
                  ความนิยม
                </Text>
                <View>
                  <FlatList
                    data={DATA4}
                    extraData={[body]}
                    numColumns={1}
                    scrollEnabled={false}
                    renderItem={({ index, item }) => {
                      return (
                        <TouchableOpacity
                          onPress={() => {
                            setBody((val) => ({
                              ...val,
                              rating: index,
                            }));
                          }}
                          style={{ flexDirection: "row" }}
                        >
                          <MaterialCommunityIcons
                            name={
                              body.rating == index
                                ? "checkbox-blank"
                                : "checkbox-blank-outline"
                            }
                            size={24}
                            color="#AAA"
                          />
                          <Text style={[styles.textTitle, { marginLeft: 7 }]}>
                            {item.name}
                          </Text>
                        </TouchableOpacity>
                      );
                    }}
                  />
                </View>
                <Text style={[styles.textTitle, { marginTop: 20 }]}>ราคา</Text>
                <View>
                  <FlatList
                    data={DATA2}
                    numColumns={1}
                    scrollEnabled={false}
                    renderItem={({ index, item }) => {
                      return (
                        <TouchableOpacity
                          onPress={() => {
                            setBody((val) => ({
                              ...val,
                              expensive: index == 0,
                            }));
                          }}
                          style={{ flexDirection: "row" }}
                        >
                          {index == 0 && (
                            <MaterialCommunityIcons
                              name={
                                body.expensive
                                  ? "checkbox-blank"
                                  : "checkbox-blank-outline"
                              }
                              size={24}
                              color="#AAA"
                            />
                          )}
                          {index == 1 && (
                            <MaterialCommunityIcons
                              name={
                                body.expensive == false
                                  ? "checkbox-blank"
                                  : "checkbox-blank-outline"
                              }
                              size={24}
                              color="#AAA"
                            />
                          )}
                          <Text style={[styles.textTitle, { marginLeft: 7 }]}>
                            {item.name}
                          </Text>
                        </TouchableOpacity>
                      );
                    }}
                  />
                </View>
                <TouchableOpacity
                  onPress={async () => {
                    const response = await apiservice({
                      method: "post",
                      path: "/course/filtersearch?page=1",
                      body,
                      token: token?.accessToken,
                    });
                    if (response.status == 200) {
                      setCourse(response.data.main);
                      setModalVisible(false);
                    }
                  }}
                  style={styles.buttonSearch}
                >
                  <Text style={styles.textSearch}>ค้นหา</Text>
                </TouchableOpacity>
              </View>
            </ScrollView>
          </View>
        </View>
      </Modal>
    </View>
  );
}
const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: "100%",
    backgroundColor: "#FFFFFF",
  },
  textTitle: {
    fontSize: 18,
    fontFamily: "Prompt",
    color: "#646464",
  },
  text: {
    fontSize: 16,
    fontFamily: "Prompt",
    color: "#646464",
  },
  viewDetail: {
    maxWidth: 278,
    width: "100%",
    // height: 385,
  },
  imgDetail: {
    maxWidth: 278,
    width: "100%",
    height: 195,
    backgroundColor: "#CCCCCC",
  },
  feedback: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  viewChoice: {
    width: "20%",
    maxWidth: "348",
    maxHeight: 950,
    height: "100%",
    borderWidth: 1,
    borderColor: "#EEEEEE",
    borderRadius: 5,
    padding: 12,
    marginTop: height * 0.1,
  },
  view: {
    flexDirection: "row",
    width: "90%",
    minHeight: "100%",
    alignSelf: "center",
  },
  buttonSearch: {
    width: "100%",
    maxWidth: 270,
    height: 60,
    backgroundColor: "#0D88F0",
    borderRadius: 5,
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "center",
    marginTop: 40,
  },
  textSearch: {
    fontFamily: "Prompt",
    fontSize: 25,
    color: "#FFFFFF",
  },
  viewCourse: {
    padding: 12,
    // borderWidth: 1,
    // borderColor: "#BBB",
  },
  viewSelectCourse: {
    width: "80%",
    paddingHorizontal: 40,
    paddingTop: 44,
  },
  textAllCourse: {
    fontSize: 70,
    fontFamily: "Prompt",
    color: "#646464",
  },
  viewImg: {
    flexDirection: "row",
    alignItems: "center",
    minHeight: 70,
  },
  img: {
    width: 35,
    height: 35,
    borderRadius: 18,
    marginRight: 15,
    backgroundColor: "#CCC",
  },
});
