import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  Image,
  Dimensions,
  Animated,
  TouchableOpacity,
  StyleSheet,
  ScrollView,
  TextInput,
  FlatList,
} from "react-native";
import {
  MaterialCommunityIcons,
  FontAwesome,
  FontAwesome5,
  MaterialIcons,
} from "@expo/vector-icons";
import RNPickerSelect from "react-native-picker-select";

import { authActionStartCourseCreate } from "../../action/authAction";
import { useRecoilState } from "recoil";
import { tokenState } from "../../reducer/recoil";
import { apiservice } from "../../service";
import Autocomplete from "react-native-autocomplete-input";
import { useWindowSize } from "../components/responsive";
import moment from "moment";
const { width, height } = Dimensions.get("window");
export default function courseUniversityStudent({
  navigation,
  university_id,
  faculti_id,
}) {
  const [token, setToken] = useRecoilState(tokenState);

  const [data, setData] = useState([]);
  const [courseDetail, setCourseDetail] = useState([]);
  const size = useWindowSize();
  const [warnningTerm, setWarnningTerm] = useState(false);
  const [warnningYear, setWarnningYear] = useState(false);
  const [warnningCourse, setWarnningCourse] = useState(false);
  const [warnningCourseInput, setWarnningCourseInput] = useState(false);
  const [name, setName] = useState();
  const [teacherName, setTeacherName] = useState();
  const [courseData, setCourseData] = useState({
    detail: [],
  });

  const [courseName, setCourseName] = useState({
    title: [],
  });

  const [state, setState] = useState({
    Type: "PENDING",
    progress: [],
    faculty_id: "",
    university_id: "",
    season: "",
    years: "",
    course_id: "",
  });

  const [autoText, setAutotext] = useState("");
  const anotherFunc = (val) => {
    setAutotext("");
  };
  useEffect(() => {
    getStartCourse(token);
    getMyCourse(token);
  }, [university_id, faculti_id, autoText]);

  async function getStartCourse() {
    const response = await apiservice({
      path:
        "/start-course/university_course?university_id=" +
        university_id +
        "&faculty_id=" +
        faculti_id +
        "&status=true",
      method: "get",
      token: token?.accessToken,
    });
    if (response.status == 200) {
      if (autoText.length > 0) {
        setData(response.data);
        setState({ ...state, course_id: response.data.id });
      }
    }
  }
  async function getMyCourse() {
    const response = await apiservice({
      path: "/start-course/mycourse?page=1&totalpage=100",
      method: "get",
      token: token?.accessToken,
    });
    if (response.status == 200) {
      setCourseDetail(response.data.results);
    }
  }

  async function Add() {
    const addCourse = await authActionStartCourseCreate({
      state,
      token: token?.accessToken,
    });

    window.location.reload(false);
  }

  async function addCourse() {
    if (
      state.season != "" &&
      state.years != "" &&
      state.course_id != undefined
    ) {
      setCourseData((val) => ({
        ...val,
        detail: val.detail.concat({
          Type: "PENDING",
          progress: [],
          faculty_id: state.faculty_id,
          university_id: state.university_id,
          season: state.season,
          years: state.years,
          course_id: state.course_id,
        }),
      }));
      setCourseName((val) => ({
        ...val,
        title: val.title.concat({
          name: name,
          teachername: teacherName,
        }),
      }));

      setName();
      setTeacherName();
      setState({ ...state, course_id: "" });
      anotherFunc({ autoText });

      return;
    }
    if (state.season == "") {
      setWarnningTerm(true);
    }
    if (state.years == "") {
      setWarnningYear(true);
    }
    if (autoText != "" && state.course_id == undefined) {
      setWarnningCourse(true);
    }
    if (state.course_id == "") {
      setWarnningCourseInput(true);
    }
  }

  const placeholder1 = {
    label: "",
    value: null,
    color: "#FBD1C2",
  };

  const [selectTerm, setSelectTerm] = useState([
    { label: "S", value: "0" },
    { label: "1", value: "1" },
    { label: "2", value: "2" },
  ]);

  let year = new Date().getFullYear() + 543;

  const [selectYear, setSelectTear] = useState([
    { label: year - 2501, value: year - 1 },
    { label: year - 2500, value: year },
    { label: year - 2499, value: year + 1 },
  ]);

  return (
    <View
      style={{ minHeight: 1070, paddingHorizontal: size.width < 750 ? 10 : 50 }}
    >
      <View>
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            marginTop: 40,
          }}
        >
          <Text
            style={[size.width < 750 ? stylesResponsive.text20 : styles.text20]}
          >
            ภาคเรียน :{" "}
          </Text>
          <View>
            <RNPickerSelect
              placeholder={placeholder1}
              onValueChange={(text) => {
                if (text != "") {
                  setWarnningTerm(false);
                }
                setState({ ...state, season: text });
              }}
              items={selectTerm}
              style={{
                inputWeb: {
                  width: size.width < 750 ? 50 : 70,
                  height: size.width < 750 ? 30 : 40,
                  borderWidth: 1,
                  borderColor: "#D1DDE5",
                  paddingHorizontal: 10,
                  textAlign: "center",
                  color: "#0B2051",
                  fontFamily: "Prompt",
                  fontSize: size.width < 750 ? 12 : 20,
                  backgroundColor: "#FFFFFF",
                },
              }}
            />
            <View>
              {warnningTerm && (
                <Text
                  style={[
                    size.width < 1280
                      ? stylesResponsive.text10R
                      : styles.text14R,
                    { position: "absolute" },
                  ]}
                >
                  กรุณาเลือก
                </Text>
              )}
            </View>
          </View>

          <Text
            style={[
              size.width < 750 ? stylesResponsive.text20 : styles.text20,
              { marginHorizontal: 10 },
            ]}
          >
            /
          </Text>
          <View>
            <RNPickerSelect
              placeholder={placeholder1}
              onValueChange={(text) => {
                if (text != "") {
                  setWarnningYear(false);
                }
                setState({ ...state, years: text });
              }}
              items={selectYear}
              style={{
                inputWeb: {
                  width: 70,
                  height: size.width < 750 ? 30 : 40,
                  borderWidth: 1,
                  borderColor: "#D1DDE5",
                  paddingHorizontal: 10,
                  textAlign: "center",
                  color: "#0B2051",
                  fontFamily: "Prompt",
                  fontSize: size.width < 750 ? 12 : 20,
                  backgroundColor: "#FFFFFF",
                },
              }}
            />
            <View>
              {warnningYear && (
                <Text
                  style={[
                    size.width < 1280
                      ? stylesResponsive.text10R
                      : styles.text14R,
                    { position: "absolute" },
                  ]}
                >
                  กรุณาเลือก
                </Text>
              )}
            </View>
          </View>
        </View>
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            marginTop: 20,
          }}
        >
          <Text
            style={[size.width < 750 ? stylesResponsive.text20 : styles.text20]}
          >
            ชื่อคอร์ส :{" "}
          </Text>
          <View style={{ width: size.width < 850 && "50%" }}>
            <Autocomplete
              placeholder={""}
              data={data
                ?.filter((item) => {
                  const { datetime, startDate, endDate } = item;

                  if (datetime) {
                    return (
                      moment().valueOf() >= moment(startDate).valueOf() &&
                      moment().valueOf() <= moment(endDate).valueOf()
                    );
                  } else {
                    return true;
                  }
                })
                .filter((item) => {
                  return item.title.toLowerCase().includes(autoText);
                })}
              style={[
                size.width < 750 ? stylesResponsive.text20 : styles.text20,
                styles.inputSeach,
              ]}
              value={autoText}
              containerStyle={{
                width: "100%",
                maxWidth: 534,
                height: 40,
                borderWidth: 0,
                borderColor: "#D1DDE5",
                backgroundColor: "#FFFFFF",
              }}
              inputContainerStyle={{
                width: "100%",
                maxWidth: 534,
                height: 40,
                borderWidth: 0,
                borderColor: "#D1DDE5",
                backgroundColor: "#FFFFFF",
              }}
              listContainerStyle={{ marginLeft: 5, paddingHorizontal: 10 }}
              onChangeText={(text) => {
                if (text != "") {
                  setWarnningCourseInput(false);
                  setWarnningCourse(false);
                }
                setAutotext(text);
              }}
              flatListProps={{
                keyExtractor: (_, idx) => idx,
                renderItem: ({ item }) => (
                  <TouchableOpacity
                    style={{}}
                    onPress={() => {
                      setAutotext(item.title);
                      setTimeout(() => {
                        setState((val) => ({
                          ...val,
                          university_id: item.university_id,
                          faculty_id: item.faculty_id,
                          course_id: item.id,
                        }));
                        setName(item.title);
                        setTeacherName(
                          item?.user?.user_accounts?.firstName +
                            " " +
                            item?.user?.user_accounts?.lastName
                        );
                      }, 500);

                      setTimeout(() => {
                        if (item.id == state.course_id) {
                        }
                        setData([]);
                      }, 500);
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 18,
                        fontFamily: "Prompt",
                        color: "#0B2051",
                        backgroundColor: "#FFFFFF",
                        width: "100%",
                        maxWidth: 534,
                      }}
                    >
                      {item.title}
                    </Text>
                  </TouchableOpacity>
                ),
              }}
            />
            <View>
              {warnningCourse && (
                <Text
                  style={[
                    size.width < 1280
                      ? stylesResponsive.text10R
                      : styles.text14R,
                    { marginLeft: 5, position: "absolute" },
                  ]}
                >
                  ไม่มีคอร์สนี้ในระบบ
                </Text>
              )}
              {warnningCourseInput && (
                <Text
                  style={[
                    size.width < 1280
                      ? stylesResponsive.text10R
                      : styles.text14R,
                    { marginLeft: 5, position: "absolute" },
                  ]}
                >
                  กรุณาเลือกชื่อคอร์สเรียน
                </Text>
              )}
            </View>
          </View>
          <TouchableOpacity
            disabled={state.course_id == "" ? true : false}
            onPress={addCourse}
            style={{
              width: 80,
              height: 40,
              borderRadius: 20,
              marginLeft: 25,
              backgroundColor: state.course_id == "" ? "#CCCCCC" : "#0D88F0",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Text
              style={[
                size.width < 750
                  ? stylesResponsive.textButton
                  : styles.textButton,
              ]}
            >
              เพิ่ม
            </Text>
          </TouchableOpacity>
        </View>
        {courseName.title.length > 0 && (
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
              marginTop: 30,
              zIndex: -1,
            }}
          >
            <Text
              style={[
                size.width < 750
                  ? stylesResponsive.textTopic
                  : styles.textTopic,
                { width: "40%", textAlign: "left" },
              ]}
            >
              คอร์ส
            </Text>
            <Text
              style={[
                size.width < 750
                  ? stylesResponsive.textTopic
                  : styles.textTopic,
                { width: "15%" },
              ]}
            >
              ผู้สอน
            </Text>
            <Text
              style={[
                size.width < 750
                  ? stylesResponsive.textTopic
                  : styles.textTopic,
                { width: "15%" },
              ]}
            >
              วันที่เริ่มเรียน
            </Text>
            <Text
              style={[
                size.width < 750
                  ? stylesResponsive.textTopic
                  : styles.textTopic,
                { width: "10%" },
              ]}
            >
              รายละเอียด
            </Text>
            <Text
              style={[
                size.width < 750
                  ? stylesResponsive.textTopic
                  : styles.textTopic,
                { width: "10%" },
              ]}
            >
              สถานะ
            </Text>
            <Text
              style={[
                size.width < 750
                  ? stylesResponsive.textTopic
                  : styles.textTopic,
                { width: "10%" },
              ]}
            ></Text>
          </View>
        )}
        <FlatList
          data={courseName.title}
          numColumns={1}
          style={{ marginTop: 14, zIndex: -1 }}
          scrollEnabled={size.width < 750 ? true : false}
          renderItem={({ index, item }) => {
            return (
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  width: "100%",
                  borderTopWidth: index == 0 ? 1 : 0,
                  borderBottomWidth: 1,
                  borderTopColor: "#BBBBBB",
                  borderBottomColor: "#BBBBBB",
                  paddingVertical: 23,
                  alignItems: "center",
                }}
              >
                <Text
                  style={[
                    size.width < 750
                      ? stylesResponsive.textDetail
                      : styles.textDetail,
                    { width: "40%", textAlign: "left" },
                  ]}
                >
                  {item != null && item.name}
                </Text>
                <Text
                  style={[
                    size.width < 750
                      ? stylesResponsive.textDetail
                      : styles.textDetail,
                    { width: "15%" },
                  ]}
                >
                  {item.teachername}
                </Text>
                <Text
                  style={[
                    size.width < 750
                      ? stylesResponsive.textDetail
                      : styles.textDetail,
                    { width: "15%" },
                  ]}
                >
                  -
                </Text>
                <Text
                  style={[
                    size.width < 750
                      ? stylesResponsive.textDetail
                      : styles.textDetail,
                    { width: "10%" },
                  ]}
                >
                  ยังไม่เริ่มเรียน
                </Text>
                <Text
                  style={[
                    size.width < 750
                      ? stylesResponsive.textDetail
                      : styles.textDetail,
                    { width: "10%" },
                  ]}
                >
                  รอดำเนินการ
                </Text>
                <View style={{ width: "10%", alignItems: "center" }}>
                  <TouchableOpacity
                    onPress={() => {
                      setCourseName((val) => ({
                        ...val,
                        title: val.title.filter((items, indexs) => {
                          return indexs != index;
                        }),
                      }));
                      setCourseData((val) => ({
                        ...val,
                        detail: val.detail.filter((items, indexs) => {
                          return indexs != index;
                        }),
                      }));
                    }}
                  >
                    <MaterialIcons name="cancel" size={20} color="#0B2051" />
                  </TouchableOpacity>
                </View>
              </View>
            );
          }}
        />
        {courseName.title.length > 0 && (
          <View
            style={{
              flexDirection: "row",
              marginTop: 35,
              alignSelf: "center",
            }}
          >
            <TouchableOpacity
              onPress={async () => {
                courseData.detail.map(async (item) => {
                  const addCourse = await authActionStartCourseCreate({
                    state: item,
                    token: token?.accessToken,
                  });
                  window.location.reload(false);
                });
              }}
              style={{
                width: 150,
                height: 60,
                backgroundColor: "#0D88F0",
                borderRadius: 10,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Text
                style={{
                  fontSize: 25,
                  fontFamily: "Prompt",
                  color: "#FFFFFF",
                }}
              >
                ยืนยัน
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => {
                window.location.reload(false);
              }}
              style={{
                width: 150,
                height: 60,
                borderWidth: 1,
                borderColor: "#0D88F0",
                borderRadius: 10,
                justifyContent: "center",
                alignItems: "center",
                marginLeft: 30,
              }}
            >
              <Text
                style={{
                  fontSize: 25,
                  fontFamily: "Prompt",
                  color: "#0D88F0",
                }}
              >
                ยกเลิก
              </Text>
            </TouchableOpacity>
          </View>
        )}
      </View>
    </View>
  );
}
const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: "100%",
    backgroundColor: "#FFFFFF",
  },
  textTopic: {
    color: "#0B2051",
    fontFamily: "Prompt",
    fontSize: 20,
    textAlign: "center",
  },
  text20: {
    color: "#0B2051",
    fontFamily: "Prompt",
    fontSize: 20,
  },
  textDetail: {
    color: "#0B2051",
    fontFamily: "Prompt",
    fontSize: 16,
    textAlign: "center",
  },
  textButton: {
    color: "#FFFFFF",
    fontFamily: "Prompt",
    fontSize: 16,
  },
  bgModal: {
    width: "100%",
    height: "100%",
    backgroundColor: "#000000bb",
    justifyContent: "center",
  },
  textDetailModal: {
    color: "#0B2051",
    fontFamily: "Prompt",
    fontSize: 20,
    marginTop: 10,
  },
  textTitleModal: {
    color: "#0B2051",
    fontFamily: "Prompt",
    fontSize: 25,
    alignSelf: "center",
  },
  inputSeach: {
    width: "100%",
    maxWidth: 534,
    height: 40,
    borderWidth: 1,
    borderColor: "#D1DDE5",
    paddingHorizontal: 10,
    marginLeft: 5,
  },
  text14R: {
    fontSize: 14,
    color: "red",
    fontFamily: "Prompt",
  },
});
const stylesResponsive = StyleSheet.create({
  textTopic: {
    color: "#0B2051",
    fontFamily: "Prompt",
    fontSize: 12,
    textAlign: "center",
  },
  textDetail: {
    color: "#0B2051",
    fontFamily: "Prompt",
    fontSize: 10,
    textAlign: "center",
  },
  text20: {
    color: "#0B2051",
    fontFamily: "Prompt",
    fontSize: 12,
  },
  textButton: {
    color: "#FFFFFF",
    fontFamily: "Prompt",
    fontSize: 12,
  },
  textDetailModal: {
    color: "#0B2051",
    fontFamily: "Prompt",
    fontSize: 12,
    marginTop: 10,
  },
  textTitleModal: {
    color: "#0B2051",
    fontFamily: "Prompt",
    fontSize: 16,
    alignSelf: "center",
  },
  text10R: {
    fontSize: 10,
    color: "red",
    fontFamily: "Prompt",
  },
});
