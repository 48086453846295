import { StyleSheet, Text, View } from "react-native";
import React from "react";
import { AntDesign } from "@expo/vector-icons";
export default function paymentSucces() {
  return (
    <View style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
      <View
        style={{
          borderWidth: 1,
          borderColor: "#484848",
          justifyContent: "center",
          alignItems: "center",
          width: "35%",
          height: "60%",
          borderRadius: 20,
        }}
      >
        <AntDesign name="checkcircle" size={100} color="#5ED228" />
        <Text
          style={{
            fontSize: 40,
            fontFamily: "Prompt",
            color: "#0B2051",
            marginTop: 40,
            textAlign: "center",
          }}
        >
          {"ชำระเงินสำเร็จ\nคุณสามารถเริ่มเรียนคอร์สได้ทันที"}
        </Text>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({});
