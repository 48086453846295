import React, { useState } from "react";
import {
  View,
  Text,
  Image,
  Dimensions,
  Animated,
  TouchableOpacity,
  StyleSheet,
  ScrollView,
  TextInput,
  FlatList,
} from "react-native";
import {
  MaterialCommunityIcons,
  MaterialIcons,
  Feather,
  Ionicons,
  FontAwesome,
} from "@expo/vector-icons";
import RNPickerSelect from "react-native-picker-select";
import Modal from "modal-react-native-web";
import { useWindowSize } from "../../components/responsive";

const { width, height } = Dimensions.get("window");
export default function studentDetail({
  ongoBack,
  nameStudent,
  lastNameStudent,
  allResult,
}) {
  const datauser = allResult;
  const size = useWindowSize();
  return (
    <View>
      <View style={{ flexDirection: "row", marginTop: 10 }}>
        <TouchableOpacity onPress={ongoBack}>
          <Text style={styles.text23}>คอร์สมหาวิทยาลัย</Text>
        </TouchableOpacity>
        <Text
          style={[
            styles.text23,
            {
              marginHorizontal: 18,
            },
          ]}
        >
          /
        </Text>
        <Text style={[styles.text23, { color: "#0D88F0" }]}>นักศึกษา</Text>
      </View>
      <Text
        style={{
          fontSize: 25,
          fontFamily: "Prompt",
          color: "#444444",
          marginVertical: 52,
        }}
      >
        {nameStudent} {lastNameStudent}{" "}
        {"(คณะ" + datauser.faculty[0]?.facuty_name_th}{" "}
        {"สาขา" + datauser.faculty[0]?.major_name_th}{" "}
        {"ชั้นปีที่ " + parseInt(datauser.user_accounts?.class) + ")"}
      </Text>
      <ScrollView horizontal>
        <View
          style={{
            width:
              size.width < 500
                ? size.width * 2.5
                : size.width < 1280
                ? size.width * 1.0
                : size.width * 0.78,
            // minHeight: height * 2.5,
          }}
        >
          <View style={styles.viewSubject}>
            <Text style={[styles.text16B, { width: "25%" }]}>
              จำนวนวิชาที่ลงเรียน
            </Text>
            <Text style={[styles.text16B, { width: "25%" }]}>
              จำนวนวิชาที่เรียนผ่าน
            </Text>
            <Text style={[styles.text16B, { width: "25%" }]}>
              จำนวนวิชาที่เรียนไม่ผ่าน
            </Text>
            <Text style={[styles.text16B, { width: "25%" }]}>
              จำนวนวิชาที่ยังเรียนอยู่
            </Text>
          </View>
          <FlatList
            numColumns={1}
            data={[allResult]}
            renderItem={({ item, index }) => {
              return (
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    borderBottomWidth: 1,
                    borderBottomColor: "#E5E5E5",
                    height: 50,
                    alignItems: "center",
                  }}
                >
                  <Text style={[styles.text16B, { width: "25%" }]}>
                    {item.length}
                  </Text>
                  <Text style={[styles.text16B, { width: "25%" }]}>
                    {item.approve}
                  </Text>
                  <Text style={[styles.text16B, { width: "25%" }]}>
                    {item.notprove}
                  </Text>
                  <Text style={[styles.text16B, { width: "25%" }]}>
                    {item.pending}
                  </Text>
                </View>
              );
            }}
          />
        </View>
      </ScrollView>
    </View>
  );
}
const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: "100%",
    backgroundColor: "#FFFFFF",
  },
  text23: {
    fontSize: 23,
    fontFamily: "Prompt",
    color: "#808D93",
  },
  text16B: {
    fontFamily: "Prompt",
    fontSize: 16,
    color: "#000000",
    width: "23%",
    textAlign: "center",
  },

  viewSubject: {
    width: "100%",
    height: 51,
    backgroundColor: "#E5E5E5",
    marginTop: 35,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
});
