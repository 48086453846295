import React, { useState, useRef, useEffect, useLayoutEffect } from "react";
import {
  View,
  Text,
  Image,
  Dimensions,
  Animated,
  TouchableOpacity,
  StyleSheet,
  ScrollView,
  TextInput,
  FlatList,
  Linking,
} from "react-native";
import {
  exportComponentAsJPEG,
  exportComponentAsPDF,
  exportComponentAsPNG,
} from "react-component-export-image";

import {
  MaterialCommunityIcons,
  AntDesign,
  FontAwesome,
  FontAwesome5,
  Foundation,
  MaterialIcons,
} from "@expo/vector-icons";
import Header from "../components/header";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { apiservice } from "../../service";
import { useIsFocused } from "@react-navigation/native";
import { useRecoilState } from "recoil";
import {
  courseState,
  pageState,
  pageTeacherState,
  tokenState,
} from "../../reducer/recoil";
import NumberFormat from "react-number-format";
import { useWindowSize } from "../components/responsive";
const { width, height } = Dimensions.get("window");
export const BottomPart = ({ navigation, style }) => {
  const size = useWindowSize();
  return (
    <View
      style={{
        width: "100%",
        paddingHorizontal:
          size.width < 1280 ? size.width * 0.05 : size.width * 0.12,
        paddingVertical: width * 0.015,
        backgroundColor: "#FFF",
        shadowColor: "#707070",
        shadowOffset: { width: -2, height: 2 },
        shadowRadius: 5,
        ...style,
      }}
    >
      <View
        style={{
          flexDirection: size.width < 1280 ? "column" : "row",
          justifyContent: "space-between",
        }}
      >
        <View>
          <Image
            resizeMode="stretch"
            style={{ width: 100, height: 65, marginLeft: 20 }}
            source={require("../img/logo.png")}
          />
          <Text style={styles.textButton}>
            {
              "สถาบันออนไลน์ สำหรับคนที่ต้องการเจริญก้าวหน้า\nสอนโดยผู้เชี่ยวชาญ จากประสบการณ์จริง\nⓒ 2022 courseoncloud.com All rights reserved."
            }
          </Text>
        </View>
        <View style={{}}>
          <Text style={styles.textTitleBottom}>คอร์สเรียนของเรา</Text>
          <TouchableOpacity
            onPress={() => {
              navigation.navigate("AllCourse");
            }}
          >
            <Text style={styles.textButton1}>คอร์สทั้งหมด</Text>
          </TouchableOpacity>

          <TouchableOpacity
            onPress={() => {
              navigation.navigate("University");
            }}
          >
            <Text style={styles.textButton1}>มหาวิทยาลัยที่เข้าร่วม</Text>
          </TouchableOpacity>
          <TouchableOpacity>
            <Text style={styles.textButton1}>หลักสูตรเก็บหน่วยกิต</Text>
          </TouchableOpacity>
        </View>
        <View style={{}}>
          <Text style={styles.textTitleBottom}>เกี่ยวกับเรา</Text>
          <TouchableOpacity
            onPress={() => {
              navigation.navigate("AboutMe");
            }}
          >
            <Text style={styles.textButton1}>เกี่ยวกับเรา</Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => {
              navigation.navigate("JoinMe");
            }}
          >
            <Text style={styles.textButton1}>เข้าร่วมกับเรา</Text>
          </TouchableOpacity>

          <TouchableOpacity
            onPress={() => {
              navigation.navigate("Docs");
            }}
          >
            <Text style={styles.textButton1}>นโยบายคุ้มครองข้อมูล</Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => {
              navigation.navigate("Privacy");
            }}
          >
            <Text style={styles.textButton1}>Privacy Policy</Text>
          </TouchableOpacity>
        </View>
        <View style={{}}>
          <Text style={styles.textTitleBottom}>ติดต่อสอบถาม</Text>

          <Text style={styles.textButton1}>
            <Foundation
              name="telephone"
              size={20}
              color="#646464"
              style={{ marginRight: 5 }}
            />
            02-026-3939
          </Text>

          <View
            style={{ flexDirection: "row", marginTop: 5, alignItems: "center" }}
          >
            <Foundation name="mail" size={20} color="#646464" />
            <Text style={[styles.textButton1, { marginLeft: 5 }]}>
              support@courseoncloud.com
            </Text>
          </View>
          {/* <View
            style={{ flexDirection: "row", marginTop: 5, alignItems: "center" }}
          >
            <MaterialIcons name="facebook" size={20} color="#646464" />
            <Text style={[styles.textButton1, { marginLeft: 5 }]}>CoC</Text>
          </View>
          <View
            style={{ flexDirection: "row", marginTop: 5, alignItems: "center" }}
          >
            <FontAwesome5 name="line" size={20} color="#646464" />
            <Text style={[styles.textButton1, { marginLeft: 5 }]}>@CoC</Text>
          </View> */}
          <Text style={styles.textButton1}>
            (เวลาทำการ จ.-ศ. 9:00 - 17:00 น. ยกเว้นวันหยุดนักขัตฤกษ์)
          </Text>
        </View>
      </View>
    </View>
  );
};

class ComponentToPrint extends React.Component {
  render() {
    return <div>Hello World</div>;
  }
}

export default function home({ navigation }) {
  const [name, setName] = useState();
  const [pageTeacher, setPageTeacher] = useRecoilState(pageTeacherState);
  const [pageStudent, setPageStudent] = useRecoilState(pageState);
  const [token, setToken] = useRecoilState(tokenState);
  const [course, setCourse] = useState([]);
  const [newCourse, setNewCourse] = useState([]);
  const [TopCourse, setTopCourse] = useState([]);
  const carouselRef = useRef();

  const isfocused = useIsFocused();
  const [slideBanner, setSlideBanner] = useState([]);
  const [slideBanner1, setSlideBanner1] = useState([]);
  const [courseStates, setCourseStates] = useRecoilState(courseState);

  let ref = React.createRef();

  function parseQueryStringToJson(queryString) {
    const jsonResult = {};

    // Split the query string by '&' to get individual key-value pairs
    const keyValuePairs = queryString.replace('?', '').split('&');

    // Iterate through key-value pairs and populate the JSON object
    keyValuePairs.forEach(pair => {
      const [key, value] = pair.split('=');
      jsonResult[key] = value;
    });

    return jsonResult;
  }

  const removeQuery = () => {
    if (window.history.pushState) {
      var newurl =
        window.location.protocol +
        "//" +
        window.location.host +
        window.location.pathname;
      window.history.pushState({ path: newurl }, "", newurl);
    }
  }

  useEffect(() => {
    const query = parseQueryStringToJson(window.location.search);
    if (query.accessToken) {
      setToken(query)
      removeQuery();
    }

    if (isfocused) {
      setPageTeacher(0);
      setPageStudent(0);
      getAllcourse(token);
      getSlideBanner(token);
      getDelcourse();
    }
  }, [isfocused]);

  async function getSlideBanner(token) {
    try {
      const response = await apiservice({
        path: "/banner/getslidebanner",
        method: "get",
        token: token?.accessToken,
      });
      if (response.status == 200) {
        setSlideBanner(
          response.data[0] != null &&
          response.data.filter((item) => {
            return item.TYPE == "Header1";
          })[0].img_list
        );
        setSlideBanner1(
          response.data[1] != null &&
          response.data[0] != null &&
          response.data.filter((item) => {
            return item.TYPE == "Header2";
          })[0].img_list
        );
        try {
          carouselRef.current.onClickNext();
        } catch (error) { }
      }
    } catch (error) {
      throw error;
    }
  }

  // const banner = slideBanner
  async function getAllcourse(token) {
    try {
      const response = await apiservice({
        path: "/course/getallmain?page=1&lan=TH",
        method: "get",
      });
      if (response.status == 200) {
        // setCourse(response.data);

        setNewCourse(
          response?.data?.new.filter((item) => item.university_id == null)
        );

        setTopCourse(
          response?.data?.recent.filter((item) => item.university_id == null)
        );
      }
    } catch (error) {
      throw error;
    }
  }
  async function getDelcourse() {
    try {
      const response = await apiservice({
        path: "/delete/list",
        method: "get",
      });
      if (response.status == 200) {
      }
    } catch (error) {
      throw error;
    }
  }

  const size = useWindowSize();
  if (slideBanner.length == 0) {
    return <View />;
  }
  return (
    <View style={styles.container}>
      <ScrollView stickyHeaderIndices={[0]}>
        <Header navigation={navigation} unShadow />
        {/* <Text>
          {size.width}px / {size.height}px
        </Text> */}
        {slideBanner != [] && (
          <Carousel
            ref={carouselRef}
            showArrows={false}
            showStatus={false}
            autoPlay
            infiniteLoop
          >
            {slideBanner.map((item) => {
              return (
                <TouchableOpacity
                  disabled={item.url == "" ? true : false}
                  onPress={() => {
                    Linking.openURL(item.url);
                  }}
                >
                  <Image
                    resizeMode={"stretch"}
                    style={{
                      width: "100%",
                      height: size.width < 800 ? height * 0.25 : height * 0.63,
                    }}
                    source={{
                      uri:
                        "https://api.courseoncloud.com/api/image/getimage/" +
                        item.img1,
                    }}
                  />
                </TouchableOpacity>
              );
            })}
          </Carousel>
        )}
        <View>
          <Text
            style={[
              styles.textTopCourse,
              {
                fontSize: size.width < 1280 ? 20 : 30,
                marginTop: size.width <= 1280 ? 25 : 25,
                marginLeft: size.width <= 1280 ? 25 : 50,
              },
            ]}
          >
            คอร์สยอดนิยม
          </Text>

          <FlatList
            data={TopCourse}
            horizontal
            initialNumToRender={2}
            showsHorizontalScrollIndicator={false}
            renderItem={({ index, item }) => {
              return (
                <TouchableOpacity
                  onPress={() => {
                    setCourseStates(item);
                    setTimeout(() => {
                      navigation.navigate("Course");
                    }, 900);
                  }}
                  style={[
                    {
                      marginLeft:
                        index == 0 ? (size.width < 500 ? 25 : 55) : 25,
                      marginRight:
                        index == 5 ? (size.width < 500 ? 25 : 55) : 0,
                      borderWidth: 1,
                      borderColor: "#BBB",
                    },
                  ]}
                >
                  <Image
                    style={[
                      styles.imgDetail,
                      {
                        width:
                          size.width < 500
                            ? 200
                            : size.width <= 1280
                              ? 270
                              : 278,
                        height: size.width < 500 ? 150 : 195,
                        backgroundColor: "#CCCCCC",
                      },
                    ]}
                    source={{
                      uri:
                        "https://api.courseoncloud.com/api/image/getimage/" +
                        item.img_content,
                    }}
                  />
                  <View
                    style={[
                      styles.viewCourseDetail,
                      {
                        width:
                          size.width < 500
                            ? 200
                            : size.width <= 1280
                              ? 270
                              : 278,
                      },
                    ]}
                  >
                    <Text
                      style={[
                        styles.text,
                        {
                          fontSize: size.width < 500 ? 14 : 18,
                          fontFamily: "PromptBold",
                        },
                      ]}
                      numberOfLines={1}
                    >
                      {item.title}
                    </Text>
                    <View style={styles.viewImg}>
                      <Image
                        style={[
                          styles.img,
                          {
                            width: size.width < 500 ? 25 : 35,
                            height: size.width < 500 ? 25 : 35,
                            backgroundColor: "#CCCCCC",
                          },
                        ]}
                        source={{
                          uri:
                            "https://api.courseoncloud.com/api/image/getimage/" +
                            item?.user?.user_accounts?.image_url,
                        }}
                      />
                      <Text
                        style={[
                          styles.text,
                          { fontSize: size.width < 500 ? 12 : 16 },
                        ]}
                      >
                        {item?.user?.user_accounts?.firstName}{" "}
                        {item?.user?.user_accounts?.lastName}
                      </Text>
                    </View>
                    <View style={styles.feedback}>
                      <View
                        style={{
                          flexDirection: "row",
                          alignSelf: "flex-end",
                        }}
                      >
                        {/* <FontAwesome
                          name={item.rating > 0 ? "star" : "star-o"}
                          size={size.width < 500 ? 15 : 20}
                          color="#0D88F0"
                        />
                        <FontAwesome
                          name={item.rating > 1 ? "star" : "star-o"}
                          size={size.width < 500 ? 15 : 20}
                          color="#0D88F0"
                        />
                        <FontAwesome
                          name={item.rating > 2 ? "star" : "star-o"}
                          size={size.width < 500 ? 15 : 20}
                          color="#0D88F0"
                        />
                        <FontAwesome
                          name={item.rating > 3 ? "star" : "star-o"}
                          size={size.width < 500 ? 15 : 20}
                          color="#0D88F0"
                        />
                        <FontAwesome
                          name={item.rating > 4 ? "star" : "star-o"}
                          size={size.width < 500 ? 15 : 20}
                          color="#0D88F0"
                        /> */}
                      </View>
                      <View>
                        {item.type == "COURSESCHOOL" ? (
                          <View
                            style={{
                              height: 20,
                              backgroundColor: "#0B2051",
                              justifyContent: "center",
                              alignItems: "center",
                              borderRadius: 10,
                              marginVertical: 5,
                              alignSelf: "flex-start",
                            }}
                          >
                            <Text
                              style={{
                                fontFamily: "Prompt",
                                fontSize: 12,
                                color: "#FFFFFF",
                                paddingHorizontal: 10,
                              }}
                            >
                              เฉพาะนักศึกษา
                            </Text>
                          </View>
                        ) : (
                          <View style={{ flexDirection: "row" }}>
                            <Text
                              style={[
                                styles.text,
                                {
                                  textAlign: "right",
                                  fontSize: size.width < 500 ? 12 : 16,
                                  textDecorationLine:
                                    item.discount != 0 ||
                                    (item.discount != null && "line-through"),
                                },
                              ]}
                            >
                              <NumberFormat
                                value={item.price == 0 ? "" : item.price}
                                displayType={"text"}
                                thousandSeparator={true}
                              />{" "}
                              {item.price == 0
                                ? "ฟรี"
                                : item.discount == 0 ||
                                (item.discount == null && "บาท")}
                            </Text>
                            {item.discount != 0 ||
                              (item.discount != null && (
                                <Text
                                  style={[
                                    styles.text,
                                    {
                                      textAlign: "right",
                                      fontSize: size.width < 500 ? 12 : 16,
                                    },
                                  ]}
                                >
                                  {item.price -
                                    (parseInt(item.discount) * item.price) /
                                    100}
                                  {item.discount != 0 && " บาท"}
                                </Text>
                              ))}
                          </View>
                        )}

                        <Text
                          style={[
                            styles.text,
                            {
                              fontSize: size.width < 500 ? 12 : 16,
                              textAlign: "right",
                            },
                          ]}
                        >
                          <NumberFormat
                            value={item.lengthdata}
                            displayType={"text"}
                            thousandSeparator={true}
                          />{" "}
                          <FontAwesome5
                            name="user-friends"
                            size={20}
                            color="#5D5D5D"
                          />
                        </Text>
                      </View>
                    </View>
                  </View>
                </TouchableOpacity>
              );
            }}
          />
        </View>
        <View style={{ marginBottom: 50 }}>
          <View style={styles.viewNewCourse}>
            <Text
              style={[
                styles.textNewCourse,
                {
                  fontSize: size.width < 1280 ? 20 : 30,
                  marginTop: size.width <= 1280 ? 25 : 50,
                  marginLeft: size.width <= 1280 ? 25 : 50,
                },
              ]}
            >
              คอร์สใหม่ล่าสุด
            </Text>
            <TouchableOpacity
              onPress={() => {
                navigation.navigate("AllCourse");
              }}
            >
              <Text
                style={[
                  styles.text,
                  {
                    fontSize: size.width <= 1280 ? 12 : 16,
                    marginRight: size.width <= 1280 ? 25 : 50,
                  },
                ]}
              >
                {"ดูทั้งหมด >>"}
              </Text>
            </TouchableOpacity>
          </View>
          <FlatList
            data={newCourse}
            horizontal
            showsHorizontalScrollIndicator={false}
            renderItem={({ index, item }) => {
              return (
                <TouchableOpacity
                  onPress={() => {
                    setCourseStates(item);
                    setTimeout(() => {
                      navigation.navigate("Course");
                    }, 900);
                  }}
                  style={[
                    styles.viewDetail,
                    {
                      marginLeft:
                        index == 0 ? (size.width < 500 ? 25 : 55) : 25,
                      marginRight:
                        index == 5 ? (size.width < 500 ? 25 : 55) : 0,
                      borderWidth: 1,
                      borderColor: "#BBB",
                    },
                  ]}
                >
                  <Image
                    style={[
                      styles.imgDetail,
                      {
                        width:
                          size.width < 500
                            ? 200
                            : size.width <= 1280
                              ? 270
                              : 278,
                        height: size.width < 500 ? 150 : 195,
                        backgroundColor: "#CCCCCC",
                      },
                    ]}
                    source={{
                      uri:
                        "https://api.courseoncloud.com/api/image/getimage/" +
                        item.img_content,
                    }}
                  />
                  <View
                    style={[
                      styles.viewCourseDetail,
                      {
                        width:
                          size.width < 500
                            ? 200
                            : size.width <= 1280
                              ? 270
                              : 278,
                      },
                    ]}
                  >
                    <Text
                      style={[
                        styles.text,
                        {
                          fontSize: size.width < 500 ? 14 : 18,
                          fontFamily: "PromptBold",
                        },
                      ]}
                      numberOfLines={1}
                    >
                      {item.title}
                    </Text>
                    <View style={styles.viewImg}>
                      <Image
                        style={[
                          styles.img,
                          {
                            width: size.width < 500 ? 25 : 35,
                            height: size.width < 500 ? 25 : 35,
                            backgroundColor: "#CCCCCC",
                          },
                        ]}
                        source={{
                          uri:
                            "https://api.courseoncloud.com/api/image/getimage/" +
                            item?.user?.user_accounts?.image_url,
                        }}
                      />
                      <Text
                        style={[
                          styles.text,
                          { fontSize: size.width < 500 ? 12 : 16 },
                        ]}
                      >
                        {item?.user?.user_accounts?.firstName}{" "}
                        {item?.user?.user_accounts?.lastName}
                      </Text>
                    </View>
                    <View style={styles.feedback}>
                      <View
                        style={{
                          flexDirection: "row",
                          alignSelf: "flex-end",
                        }}
                      >
                        {/* <FontAwesome
                          name={item.rating > 0 ? "star" : "star-o"}
                          size={size.width < 500 ? 15 : 20}
                          color="#0D88F0"
                        />
                        <FontAwesome
                          name={item.rating > 1 ? "star" : "star-o"}
                          size={size.width < 500 ? 15 : 20}
                          color="#0D88F0"
                        />
                        <FontAwesome
                          name={item.rating > 2 ? "star" : "star-o"}
                          size={size.width < 500 ? 15 : 20}
                          color="#0D88F0"
                        />
                        <FontAwesome
                          name={item.rating > 3 ? "star" : "star-o"}
                          size={size.width < 500 ? 15 : 20}
                          color="#0D88F0"
                        />
                        <FontAwesome
                          name={item.rating > 4 ? "star" : "star-o"}
                          size={size.width < 500 ? 15 : 20}
                          color="#0D88F0"
                        /> */}
                      </View>
                      <View>
                        {item.type == "COURSESCHOOL" ? (
                          <View
                            style={{
                              height: 20,
                              backgroundColor: "#0B2051",
                              justifyContent: "center",
                              alignItems: "center",
                              borderRadius: 10,
                              marginVertical: 5,
                              alignSelf: "flex-start",
                            }}
                          >
                            <Text
                              style={{
                                fontFamily: "Prompt",
                                fontSize: 12,
                                color: "#FFFFFF",
                                paddingHorizontal: 10,
                              }}
                            >
                              เฉพาะนักศึกษา
                            </Text>
                          </View>
                        ) : (
                          <View style={{ flexDirection: "row" }}>
                            <Text
                              style={[
                                styles.text,
                                {
                                  textAlign: "right",
                                  fontSize: size.width < 500 ? 12 : 16,
                                  textDecorationLine:
                                    item.discount != 0 ||
                                    (item.discount != null && "line-through"),
                                },
                              ]}
                            >
                              <NumberFormat
                                value={item.price == 0 ? "" : item.price}
                                displayType={"text"}
                                thousandSeparator={true}
                              />{" "}
                              {item.price == 0
                                ? "ฟรี"
                                : item.discount == 0 ||
                                (item.discount == null && "บาท")}
                            </Text>
                            {item.discount != 0 ||
                              (item.discount != null && (
                                <Text
                                  style={[
                                    styles.text,
                                    {
                                      textAlign: "right",
                                      fontSize: size.width < 500 ? 12 : 16,
                                    },
                                  ]}
                                >
                                  {item.price -
                                    (parseInt(item.discount) * item.price) /
                                    100}
                                  {item.discount != 0 && " บาท"}
                                </Text>
                              ))}
                          </View>
                        )}
                        <Text
                          style={[
                            styles.text,
                            {
                              fontSize: size.width < 500 ? 12 : 16,
                              textAlign: "right",
                            },
                          ]}
                        >
                          <NumberFormat
                            value={item.lengthdata}
                            displayType={"text"}
                            thousandSeparator={true}
                          />{" "}
                          <FontAwesome5
                            name="user-friends"
                            size={20}
                            color="#5D5D5D"
                          />
                        </Text>
                      </View>
                    </View>
                  </View>
                </TouchableOpacity>
              );
            }}
          />
        </View>
        {/* {size.width < 1280 && (
          <View
            style={{
              alignSelf: "center",
              alignItems: "center",
              paddingHorizontal: 40,
              marginBottom: 40,
            }}
          >
            <Text
              style={[
                styles.textUniver,
                { fontSize: size.width < 750 ? 25 : 40 },
              ]}
            >
              มหาวิทยาลัยที่เข้าร่วมกับ
            </Text>
            <Image
              style={[
                styles.imgLogo,
                {
                  width: size.width < 750 ? 200 : 256,
                  height: size.width < 750 ? 126 : 166,
                },
              ]}
              source={require("../img/logo_orange-1.png")}
            />
            <TouchableOpacity
              onPress={() => {
                navigation.navigate("University");
              }}
              style={{ alignSelf: "flex-end" }}
            >
              <Text
                style={[
                  styles.textSeeMore,
                  { fontSize: size.width < 750 ? 14 : 20 },
                ]}
              >
                {"ดูเพิ่มเติม >>"}
              </Text>
            </TouchableOpacity>
          </View>
        )} */}
        {slideBanner1 != [] && (
          <Carousel
            ref={carouselRef}
            showArrows={false}
            showStatus={false}
            autoPlay
            infiniteLoop
          >
            {slideBanner1.map((item) => {
              return (
                <TouchableOpacity
                  disabled={item.url == "" ? true : false}
                  onPress={() => {
                    Linking.openURL(item.url);
                  }}
                >
                  <Image
                    resizeMode={"stretch"}
                    style={{
                      width: "100%",
                      height:
                        size.width < 500
                          ? (size.width * 40) / 100
                          : height * 0.5,
                    }}
                    source={{
                      uri:
                        "https://api.courseoncloud.com/api/image/getimage/" +
                        item.img1,
                    }}
                  />
                </TouchableOpacity>
              );
            })}
          </Carousel>
        )}
        {/* <Carousel
          ref={carouselRef}
          showArrows={false}
          showStatus={false}
          autoPlay
          infiniteLoop
        >
          {slideBanner.map((item) => {
            return ( */}
        {/* {size.width >= 1280 && (
          <View style={{ justifyContent: "center" }}>
            <Image
              resizeMode="stretch"
              style={{ width: size.width, height: 475 }}
              source={require("../img/gdhlsjflksdf.png")}
            />

            <View style={styles.viewUniver}>
              <Text style={styles.textUniver}>มหาวิทยาลัยที่เข้าร่วมกับ</Text>
              <Image
                style={styles.imgLogo}
                source={require("../img/logo_orange-1.png")}
              />
              <TouchableOpacity
                onPress={() => {
                  navigation.navigate("University");
                }}
                style={styles.buttonUniver}
              >
                <Text style={styles.textSeeMore}>{"ดูเพิ่มเติม >>"}</Text>
              </TouchableOpacity>
            </View>
          </View>
        )} */}
        {/* );
          })}
        </Carousel> */}

        <BottomPart navigation={navigation} />
      </ScrollView>
    </View>
  );
}
const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: "100%",
    backgroundColor: "#FFFFFF",
  },
  text: {
    fontSize: 16,
    fontFamily: "Prompt",
    color: "#5D5D5D",
  },

  imgDetail: {
    width: 278,
    height: 195,
  },
  feedback: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  imgLogo: {
    width: 256,
    height: 166,
    marginTop: 10,
  },
  topCourse: {
    paddingHorizontal: 55,
  },
  textTopCourse: {
    fontSize: 30,
    fontFamily: "Prompt",
    color: "#646464",
  },
  viewCourseDetail: {
    padding: 12,
    // borderWidth: 1,
    // borderColor: "#BBB",
  },
  viewImg: {
    flexDirection: "row",
    alignItems: "center",
    minHeight: 70,
  },
  img: {
    width: 35,
    height: 35,
    borderRadius: 18,
    marginRight: 15,
  },
  viewNewCourse: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  imgNewCourse: {
    width: 35,
    height: 35,
    borderRadius: 18,
    marginRight: 15,
  },
  textNewCourse: {
    fontSize: 30,
    fontFamily: "Prompt",
    color: "#5D5D5D",
  },
  viewDetailNewCourse: {
    padding: 12,
    borderWidth: 1,
    borderColor: "#0D88F0",
  },
  viewUniver: {
    position: "absolute",
    alignItems: "center",
    paddingLeft: 85,
  },
  viewImgNewCourse: {
    flexDirection: "row",
    alignItems: "center",
    minHeight: 70,
  },
  textUniver: {
    fontSize: 40,
    fontFamily: "Prompt",
    color: "#0B2051",
  },
  buttonUniver: {
    marginTop: 10,
    alignSelf: "flex-end",
  },
  textSeeMore: {
    fontSize: 20,
    fontFamily: "Prompt",
    color: "#0B2051",
  },
  textButton: {
    fontSize: 14,
    fontFamily: "Prompt",
    color: "#646464",
    marginTop: 10,
  },
  textButton1: { fontSize: 14, fontFamily: "Prompt", color: "#646464" },
  textTitleBottom: {
    fontSize: 18,
    fontFamily: "Prompt",
    color: "#0D88F0",
    paddingBottom: 5,
  },
});
