import React, { useEffect, useState } from "react";
import {
  View,
  Text,
  Image,
  Dimensions,
  TouchableOpacity,
  StyleSheet,
  ScrollView,
  FlatList,
} from "react-native";
import { MaterialCommunityIcons, MaterialIcons } from "@expo/vector-icons";
import Header from "../components/header";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import HTMLRenderer from "react-html-renderer";
import { apiservice } from "../../service";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  courseState,
  lessonState,
  lessonVideoState,
  subjecttestState,
  testState,
  tokenState,
  userState,
} from "../../reducer/recoil";
import { useIsFocused } from "@react-navigation/native";

const { width, height } = Dimensions.get("window");
import Modal from "modal-react-native-web";
import { useWindowSize } from "../components/responsive";

export default function lesson({ navigation, route }) {
  const [lessonStates, setLessonStates] = useRecoilState(lessonState);
  const [testStates, setTestStates] = useRecoilState(testState);
  const [courseStates, setCourseStates] = useRecoilState(courseState);

  const [subjectTestStates, setSubjectTestStates] =
    useRecoilState(subjecttestState);
  const [lessonVideoStates, setLessonVideoStates] =
    useRecoilState(lessonVideoState);

  const data = lessonStates;
  const [Oncecourse, myOncecourse] = useState(null);
  const [modalScore, setModalScore] = useState(false);
  const [defaultsStatus, setStatus] = useState(data.status);
  const [embed, setembed] = useState(
    lessonStates.detail != undefined && lessonStates.detail.detail
  );

  const [show, setShow] = useState([]);
  const [show1, setShow1] = useState([]);
  const [unLock, setUnLock] = useState(false);
  const DATA = data.document;
  const [sumScore, setSumScore] = useState();
  const token = useRecoilValue(tokenState);
  const isFocus = useIsFocused();
  const [too, setToo] = useState(null);
  const [users, setUser] = useRecoilState(userState);
  useEffect(() => {
    if (lessonStates.detail != undefined) {
      setembed(lessonStates.detail.detail);
    }
  }, [lessonStates]);
  async function Chanfd(params, Mainindex, i, status) {
    if (params.type == "EXAM") {
      const res = await apiservice({
        path: "/quiz/get/" + params.education_id,
        method: "get",
        token: token?.accessToken,
      });
      setTestStates({
        ...params,
        ...res.data,
        ...Oncecourse,
        Mainindex,
        status,
        index: i,
        number: 0,
        dataStudymodel: Oncecourse.progress,

        course_id: Oncecourse.course_id,
      });

      setTimeout(() => {
        navigation.push("Test");
      }, 800);
    } else if (params.type == "DESCRIPTION") {
      const res = await apiservice({
        path: "/course/geteducation/" + params.education_id,
        method: "get",
        token: token?.accessToken,
      });
      if (res.status == 200) {
        if (res.data.TYPE == "VDO") {
          setLessonVideoStates({
            ...params,
            ...res.data,
            ...Oncecourse,
            status,
            number: 0,
            Mainindex,
            dataStudymodel: Oncecourse.progress,
            index: i,
            course_id: Oncecourse.course_id,
          });
          setTimeout(() => {
            navigation.push("LessonVideo");
          }, 500);
        } else {
          setLessonStates({
            ...params,
            ...res.data,
            ...Oncecourse,
            number: 0,
            Mainindex,
            dataStudymodel: Oncecourse.progress,
            status,

            index: i,
            course_id: Oncecourse.course_id,
          });

          setTimeout(() => {
            navigation.push("Lesson");
          }, 500);
        }
      }
    } else if (params.type == "WORK") {
      const res = await apiservice({
        path: "/course/getwork/" + params.education_id,
        method: "get",
        token: token?.accessToken,
      });
      setSubjectTestStates({
        ...res.data,
        ...params,
        ...Oncecourse,
        number: 0,
        Mainindex,
        status,
        index: i,
        dataStudymodel: Oncecourse.progress,
        course_id: Oncecourse.course_id,
      });
      setTimeout(() => {
        navigation.push("SubjectiveTest");
      }, 500);
    } else {
      const res = await apiservice({
        path: "/quiz/get/" + params.education_id,
        method: "get",
        token: token?.accessToken,
      });
      setSubjectTestStates({
        ...res.data,
        ...params,
        ...Oncecourse,
        dataStudymodel: Oncecourse.progress,
        status,
        number: 0,
        Mainindex,
        index: i,
        course_id: Oncecourse.course_id,
      });
      setTimeout(() => {
        navigation.push("SubjectiveTest");
      }, 500);
    }
  }

  const stringToHTML = function (str) {
    const domContainer = document.createElement("span");
    domContainer.innerHTML = str;
    return domContainer;
  };

  const parentEmbed = stringToHTML(embed);

  let oldIframe = parentEmbed.querySelectorAll("oembed");
  oldIframe = Array.from(oldIframe);

  for (const i in oldIframe) {
    let url = oldIframe[i].getAttribute("url");
    url = url.replace("watch?v=", "embed/");

    const newIframe = document.createElement("iframe");
    newIframe.setAttribute("width", "auto");
    newIframe.setAttribute("height", "auto");
    newIframe.setAttribute("allowFullScreen", "");
    newIframe.setAttribute("frameBorder", 0);
    if (url) {
      newIframe.setAttribute("src", url);
    }
    oldIframe[i].parentNode.replaceChild(newIframe, oldIframe[i]);
  }

  const contentToRender = parentEmbed.outerHTML?.replace(
    /<img /g,
    `<img width="${width * 0.5}" height="auto" `
  );

  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  async function checkStart() {
    const response = await apiservice({
      path: "/start-course/myOncecourse?course_id=" + lessonStates.course_id,
      method: "get",
      token: token?.accessToken,
    });

    if (response.status == 200) {
      myOncecourse(response.data);

      setStatus(
        response.data.progress[data.Mainindex].listen_detail[data.index].status
      );
    }
  }

  useEffect(() => {
    if (Oncecourse) {
      sum();
    }
  }, [Oncecourse]);

  async function sum() {
    const users = await apiservice({
      path: "/authen/user",
      method: "get",
      token: token?.accessToken,
    });
    if (users.status == 200) {
      const res = await apiservice({
        path:
          "/start-course/myscore?course_id=" +
          lessonStates.course_id +
          "&uid=" +
          users.data.result.id,
        method: "get",
      });

      if (res.status == 200) {
        setSumScore(res.data.results);
        conditionToo(res.data.results?.EXAM);
      }
    }
  }
  async function conditionToo(params) {
    try {
      if (
        defaultsStatus &&
        lessonStates.dataStudymodel[lessonStates.Mainindex].listen_detail[
          lessonStates.index + 1
        ] == undefined &&
        defaultsStatus &&
        lessonStates.dataStudymodel[lessonStates.Mainindex + 1] == undefined
      ) {
        if (Oncecourse.Type == "PENDING") {
          const reespon = await apiservice({
            path: "/start-course/update/" + Oncecourse.id,
            method: "put",
            body: {
              Type:
                params >= Oncecourse?.courses?.percent ? "APPROVE" : "REJECT",
            },
            token: token?.accessToken,
          });
          if (reespon.status == 200) {
            checkStart();
          }
        } else {
          checkStart();
        }
      }
    } catch (error) {}
  }

  async function checkStartFinish() {
    const response = await apiservice({
      path: "/start-course/myOncecourse?course_id=" + data.course_id,
      method: "get",
      token: token?.accessToken,
    });

    if (response.status == 200) {
      try {
        data.progress.map((items) => {
          items.listen_detail.map((val) => {
            if (val.education_id == data.education_id) {
              setStatus(true);
            }
          });
        });
      } catch (error) {}

      myOncecourse(response.data);
    }
  }
  useEffect(() => {
    checkStart();
  }, [isFocus, lessonStates, token]);

  useEffect(() => {
    if (
      defaultsStatus &&
      data.dataStudymodel[data.Mainindex].listen_detail[data.index + 1] ==
        undefined &&
      data.dataStudymodel[data.Mainindex + 1] == undefined
    ) {
      name1();
    }
  }, [defaultsStatus, data]);

  async function name1() {
    const respp = await apiservice({
      path: "/start-course/getalltotals/" + data.course_id,
      method: "get",
      token: token?.accessToken,
    });
    const resppt = await apiservice({
      path: "/start-course/getworktotals/" + data.course_id,
      method: "get",
      token: token?.accessToken,
    });
    if (respp.status == 200) {
      setShow1(resppt.data.results);
      setShow(respp.data.results);
      setModalScore(true);
    }
  }

  // async function Chanfd(params) {

  //   if (params.type == "EXAM") {
  //     const res = await apiservice({
  //       path: "/quiz/get/" + params.education_id,
  //       method: "get",
  //       token: token?.accessToken,
  //     });

  //     navigation.push("Test", {
  //       ...params,
  //       ...res.data,
  //       ...Oncecourse,
  //       number: 0,
  //       course_id: Oncecourse.course_id,
  //     });
  //   } else if (params.type == "DESCRIPTION") {
  //     const res = await apiservice({
  //       path: "/course/geteducation/" + params.education_id,
  //       method: "get",
  //       token: token?.accessToken,
  //     });
  //     if (res.status == 200) {
  //       if (res.data.TYPE == "VDO") {
  //         navigation.push("LessonVideo", {
  //           ...params,
  //           ...res.data,
  //           ...Oncecourse,
  //           number: 0,
  //           course_id: Oncecourse.course_id,
  //         });
  //       } else {
  //         navigation.push("Lesson", {
  //           ...params,
  //           ...res.data,
  //           ...Oncecourse,
  //           number: 0,
  //           course_id: Oncecourse.course_id,
  //         });
  //       }
  //     }
  //   } else if (params.type == "WORK") {
  //     const res = await apiservice({
  //       path: "/course/getwork/" + params.education_id,
  //       method: "get",
  //       token: token?.accessToken,
  //     });
  //     navigation.push("SubjectiveTest", {
  //       ...res.data,
  //       ...params,
  //       ...Oncecourse,
  //       number: 0,
  //       course_id: Oncecourse.course_id,
  //     });
  //   } else {
  //     navigation.push("SubjectiveTest", {
  //       ...params,
  //       ...Oncecourse,
  //       number: 0,
  //       course_id: Oncecourse.course_id,
  //     });
  //   }
  // }

  useEffect(() => {
    if (show.length > 0) {
      totalsss();
    }
  }, [show]);

  const size = useWindowSize();

  async function totalsss() {
    let toootals = 0;

    const re = await Promise.all(
      lessonStates.progress.map(async (item, index) => {
        if (
          item.listen_detail.filter((items) => items.type == "EXAM").length > 0
        ) {
          const question = item.MyQ;
          const answers = item.listen_detail.filter(
            (items) => items.type == "EXAM"
          )[0].answer;
          toootals =
            toootals +
            (answers
              .filter(
                (ele, ind) =>
                  ind ===
                  answers.findIndex((elem) => elem.number === ele.number)
              )
              .filter((items, mainIndex) => {
                if (typeof items.answer == "object") {
                  if (
                    question.filter((val, indexs) => indexs == items.number)[0]
                      .type == "1"
                  ) {
                    return (
                      question.filter(
                        (val, indexs) => indexs == items.number
                      )[0].answer == items.answer
                    );
                  }
                  if (
                    question.filter((val, indexs) => indexs == items.number)[0]
                      .type == "2"
                  ) {
                    return (
                      question
                        .filter((val, indexs) => indexs == items.number)[0]
                        .answer.filter((e) => {
                          return (
                            items.answer.filter((ev) => e == ev).length > 0
                          );
                        }).length ==
                      question.filter(
                        (val, indexs) => indexs == items.number
                      )[0].answer.length
                    );
                  }
                  if (
                    question.filter((val, indexs) => indexs == items.number)[0]
                      .type == "3"
                  ) {
                    return question.filter(
                      (val, indexs) => indexs == items.number
                    )[0].answer != null
                      ? question.filter(
                          (val, indexs) => indexs == items.number
                        )[0].answer == items.answer
                      : items.answer == 0
                      ? true
                      : false;
                  }
                  if (
                    question.filter((val, indexs) => indexs == items.number)[0]
                      .type == "4"
                  ) {
                    return (
                      question
                        .filter((val, indexs) => indexs == items.number)[0]
                        .answer.filter(
                          (e) =>
                            items.answer.filter((ev) => e.answer == ev.answer)
                              .length > 0
                        ).length ==
                      question.filter(
                        (val, indexs) => indexs == items.number
                      )[0].answer.length
                    );
                  }
                  if (
                    question.filter((val, indexs) => indexs == items.number)[0]
                      .type == "5"
                  ) {
                    return (
                      question.filter((val, indexs) => {
                        if (val.type == "5") {
                          return (
                            answers
                              .filter((val, indexss) => indexs == val.number)[0]
                              .answer.filter((e, i) => val.choice[i] == e.ans)
                              .length == val.choice.length
                          );
                        } else {
                          return false;
                        }
                      }).length > 0
                    );
                  }
                } else {
                  return false;
                }
              }).length /
              answers.length) *
              100;
        } else {
          toootals = toootals + 0;
        }
      })
    );

    setToo(toootals);
  }

  // if (
  //   defaultsStatus &&
  //   data.dataStudymodel[data.Mainindex].listen_detail[data.index + 1] ==
  //     undefined &&
  //   too == null &&
  //   data.dataStudymodel[data.Mainindex + 1] == undefined
  // ) {
  //   return <View />;
  // }

  // if (
  //   defaultsStatus &&
  //   data.dataStudymodel[data.Mainindex].listen_detail[data.index + 1] ==
  //     undefined &&
  //   show.length == 0 &&
  //   data.dataStudymodel[data.Mainindex + 1] == undefined
  // ) {
  //   return <View />;
  // }

  if (Oncecourse == null) {
    return null;
  }
  return (
    <View style={styles.container}>
      <Modal
        transparent={true}
        visible={modalScore}
        onRequestClose={() => {
          Alert.alert("Modal has been closed.");
          setModalScore(!modalScore);
        }}
      >
        <View style={styles.viewModal}>
          <View
            style={[styles.viewDetailModal, { maxWidth: 600, maxHeight: 520 }]}
          >
            <View style={{}}>
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  paddingTop: 20,
                }}
              >
                <Text
                  style={{
                    fontFamily: "Prompt",
                    fontSize: 30,
                    color: "#0B2051",
                  }}
                >
                  ผลของคุณคือ
                </Text>
                <Text
                  style={{
                    fontFamily: "Prompt",
                    fontSize: 80,
                    color: "#0B2051",
                    marginLeft: 43,
                  }}
                >
                  {sumScore?.EXAM}%
                </Text>
              </View>
              <Text
                style={{
                  fontFamily: "Prompt",
                  fontSize: 20,
                  color: "#0B2051",
                  alignSelf: "center",
                  marginBottom: 40,
                }}
              >
                สรุปการเรียนของคุณ
              </Text>
              <View style={{ flexDirection: "row" }}>
                <View style={styles.viewDashedModal}>
                  <MaterialIcons
                    name="library-books"
                    size={40}
                    color="#0B2051"
                  />
                  <Text style={styles.text25}>
                    {sumScore?.DESCRIPTION}/{sumScore?.DESCRIPTION}
                  </Text>
                </View>
                <View
                  style={[
                    styles.viewDashedModal,
                    { justifyContent: "flex-end", borderRightWidth: 0 },
                  ]}
                >
                  <MaterialIcons name="archive" size={40} color="#0B2051" />
                  <Text style={styles.text25}>
                    {sumScore?.WORK}/{sumScore?.WORK}
                  </Text>
                </View>
              </View>
              <View style={{ flexDirection: "row" }}>
                <View style={styles.viewDashedModal1}>
                  <MaterialIcons name="live-help" size={40} color="#0B2051" />
                  <Text style={styles.text25}>
                    {sumScore?.WRITING}/{sumScore?.WRITING}
                  </Text>
                </View>
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    width: "50%",
                  }}
                >
                  <Text
                    style={{
                      fontFamily: "Prompt",
                      fontSize: 25,
                      color: "#0B2051",
                    }}
                  >
                    ผล
                  </Text>
                  {Oncecourse.Type == "APPROVE" ? (
                    <Text
                      style={{
                        fontFamily: "Prompt",
                        fontSize: 30,
                        color: "#74C745",
                        marginLeft: 25,
                      }}
                    >
                      ผ่าน
                    </Text>
                  ) : Oncecourse.Type == "PENDING" ? (
                    <Text
                      style={{
                        fontFamily: "Prompt",
                        fontSize: 30,
                        color: "#EBE330",
                        marginLeft: 25,
                      }}
                    >
                      รอผล
                    </Text>
                  ) : (
                    <Text
                      style={{
                        fontFamily: "Prompt",
                        fontSize: 30,
                        color: "red",
                        marginLeft: 25,
                      }}
                    >
                      ไม่ผ่าน
                    </Text>
                  )}
                </View>
              </View>
            </View>
            <View
              style={{
                flexDirection: "row",
                justifyContent: "center",
                marginVertical: 30,
              }}
            >
              {/* {lessonStates.courses.certificate && (
                <TouchableOpacity
                  onPress={() => {
                    window.open(
                      `https://api.courseoncloud.com/api/image/export/${lessonStates.course_id}?uid=${users.uid}`
                    );
                    // https://api.courseoncloud.com/api/image/export/1?uid=32
                  }}
                  style={{
                    width: 141,
                    height: 44,
                    backgroundColor: "#0D88F0",
                    borderRadius: 5,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 20,
                      fontFamily: "Prompt",
                      color: "#FFFFFF",
                    }}
                  >
                    Certificate
                  </Text>
                </TouchableOpacity>
              )} */}
              <TouchableOpacity
                onPress={async () => {
                  setUnLock(false);
                  const response = await apiservice({
                    path:
                      "/course/getall?page=1&lan=TH&course_id=" +
                      data.course_id,
                    method: "get",
                    //token: token?.accessToken,
                  });

                  setCourseStates(response.data?.data4[0]);

                  setTimeout(() => {
                    setModalScore(!modalScore);
                    navigation.push("Course");
                  }, 900);
                }}
                style={{
                  width: 141,
                  height: 44,
                  borderWidth: 1,
                  borderColor: "#0D88F0",
                  borderRadius: 5,
                  justifyContent: "center",
                  alignItems: "center",
                  // marginLeft: 60,
                }}
              >
                <Text
                  style={{
                    fontSize: 20,
                    fontFamily: "Prompt",
                    color: "#0D88F0",
                  }}
                >
                  ดูคอร์ส
                </Text>
              </TouchableOpacity>
            </View>
            {Oncecourse?.comment != null && (
              <Text
                style={{
                  fontSize: 12,
                  fontFamily: "Prompt",
                  color: "#E82626",
                  alignSelf: "center",
                }}
              >
                {"***หมายเหตุ " + Oncecourse?.comment + " ***"}
              </Text>
            )}
            <Text
              style={{
                fontSize: 12,
                fontFamily: "Prompt",
                color: "#E82626",
                alignSelf: "center",
              }}
            >
              กรุณาติดต่อผู้สอนกรณีต้องการปรึกษาเรื่องผลการเรียน
            </Text>
          </View>
        </View>
      </Modal>
      <ScrollView stickyHeaderIndices={[0]}>
        <Header navigation={navigation} />
        <View
          style={{
            width: size.width < 500 ? "80%" : "50%",
            alignSelf: "center",
            marginTop: 35,
            minHeight: size.height - 155,
          }}
        >
          <Text
            style={{ fontFamily: "Prompt", fontSize: 11, color: "#0B2051" }}
          >
            {/* พุทธศาสตรบัณฑิต (การสอนพระพุทธศาสนาและจิตวิทยาแนะแนว) */}
          </Text>
          <Text
            style={{
              fontFamily: "Prompt",
              fontSize: size.width < 500 ? 16 : 25,
              color: "#646464",
            }}
          >
            {/* ทำความรู้จักงานแนะแนว */}
            {data.name}
          </Text>
          {data.detail.image != "" && (
            <Image
              style={{
                height: size.width < 500 ? 250 : (size.width * 25) / 100,
              }}
              source={{
                uri:
                  "https://api.courseoncloud.com/api/image/getimage/" +
                  data.detail.image.replace(".png", ""),
              }}
            />
          )}
          <Text
            style={[
              styles.textDetail,
              { fontSize: size.width < 500 ? 14 : 20 },
            ]}
          >
            <HTMLRenderer
              html={contentToRender}
              components={
                {
                  // h1: props => <Heading color="red" {...props} />,
                  // h2: Subheading,
                  // a: Link,
                }
              }
            />
          </Text>
          <FlatList
            data={DATA}
            numColumns={1}
            renderItem={({ index, item }) => {
              return (
                <View
                  style={{
                    borderTopWidth: index == 0 && 1,
                    borderBottomWidth: 1,
                    borderBottomColor: "#BBBBBB",
                    borderTopColor: "#BBBBBB",
                    paddingVertical: 15,
                  }}
                >
                  <TouchableOpacity
                    onPress={async () => {
                      // const response = await apiservice({
                      //   path: "/start-course/update/" + data.id,
                      //   method: "put",
                      //   body: {
                      //     progress:
                      //       data.progress.filter(
                      //         (e) => e.number == data.number
                      //       ).length > 0
                      //         ? data.progress.map((item) => {
                      //             if (item.number == data.number) {
                      //               return { ...item, pdf: item.pdf + 1 };
                      //             } else {
                      //               return item;
                      //             }
                      //           })
                      //         : data.progress.concat({
                      //             number: data.number,
                      //             pdf: 1,
                      //             pdflength: DATA.length,
                      //             status: false,
                      //           }),
                      //   },
                      //   token: token?.accessToken,
                      // });
                      // checkStart();
                      openInNewTab(
                        "https://api.courseoncloud.com/api/image/pdf/" +
                          item.files.replaceAll(".pdf", "")
                      );
                    }}
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Text
                      style={[
                        width < 500
                          ? stylesResponsive.textDetail
                          : styles.textDetail,
                        { marginTop: 0 },
                      ]}
                    >
                      {item.name}
                    </Text>
                    <MaterialIcons
                      name="cloud-download"
                      size={24}
                      color="#0D88F0"
                    />
                  </TouchableOpacity>
                </View>
              );
            }}
          />
          <TouchableOpacity
            disabled={defaultsStatus ? true : false}
            //disabled={data.status || finish ? true : false}
            onPress={async () => {
              const response = await apiservice({
                path: "/start-course/update/" + data.id,
                method: "put",
                body: {
                  Type: "PENDING",
                  progress: data.progress.map((item, i) => {
                    if (i == 0) {
                      return {
                        ...item,
                        listen_detail: item.listen_detail.map((items, ins) => {
                          if (ins == 0) {
                            return { ...items, status: true };
                          } else if (
                            ins !== 0 &&
                            item.listen_detail[ins - 1].status == true
                          ) {
                            return { ...items, status: true };
                          } else {
                            return items;
                          }
                        }),
                      };
                    } else {
                      return {
                        ...item,
                        listen_detail: item.listen_detail.map((items, ins) => {
                          if (
                            ins == 0 &&
                            data.progress[i - 1].listen_detail[
                              data.progress[i - 1].listen_detail.length - 1
                            ].status == true
                          ) {
                            return { ...items, status: true };
                          } else if (
                            ins !== 0 &&
                            item.listen_detail[ins - 1].status == true
                          ) {
                            return { ...items, status: true };
                          } else {
                            return items;
                          }
                        }),
                      };
                    }
                  }),
                },
                token: token?.accessToken,
              });
              setTimeout(() => {
                checkStartFinish();
                setUnLock(true);
              }, 500);
              setTimeout(() => {
                if (
                  data.dataStudymodel[data.Mainindex].listen_detail[
                    data.index + 1
                  ] == undefined &&
                  data.dataStudymodel[data.Mainindex + 1] == undefined
                ) {
                  name1();
                }
              }, 500);
            }}
            style={{
              width: 165,
              height: 57,
              borderRadius: 5,
              backgroundColor: defaultsStatus ? "#CCCCCC" : "#0D88F0",
              justifyContent: "center",
              alignItems: "center",
              alignSelf: "center",
              marginVertical: 45,
            }}
          >
            <Text
              style={{
                fontFamily: "Prompt",
                fontSize: size.width < 500 ? 14 : 25,
                color: "#FFFFFF",
              }}
            >
              เสร็จสิ้น
            </Text>
          </TouchableOpacity>
        </View>
        <View
          style={{
            width: "100%",
            height: 70,
            backgroundColor: defaultsStatus ? "#0D88F0" : "#D9D9D9",
            justifyContent: "center",
          }}
        >
          <View
            style={[
              styles.viewProgress,
              { backgroundColor: defaultsStatus ? "#0D88F0" : "#D9D9D9" },
            ]}
          >
            {data.Mainindex == 0 && data.index == 0 ? (
              <View style={styles.viewTextProgress}>
                <TouchableOpacity style={{ margin: 10 }}>
                  <MaterialCommunityIcons
                    name="arrow-left-drop-circle"
                    size={0}
                    color="#FFFFFF"
                  />
                </TouchableOpacity>
                <View>
                  <Text
                    style={[styles.textProgress, { textAlign: "left" }]}
                  ></Text>
                  <Text numberOfLines={1} style={styles.textProgress}></Text>
                </View>
              </View>
            ) : (
              <View style={styles.viewTextProgress}>
                <TouchableOpacity
                  onPress={async () => {
                    const Mainindex = data.Mainindex;
                    const i = data.index;
                    const newi = data.index - 1;

                    if (
                      data.dataStudymodel[Mainindex].listen_detail[i - 1] !=
                      undefined
                    ) {
                      Chanfd(
                        data.dataStudymodel[Mainindex].listen_detail[i - 1],
                        Mainindex,
                        newi,
                        true
                      );
                    } else {
                      Chanfd(
                        data.dataStudymodel[Mainindex - 1].listen_detail[
                          data.dataStudymodel[Mainindex - 1].listen_detail
                            .length - 1
                        ],
                        Mainindex - 1,
                        data.dataStudymodel[Mainindex - 1].listen_detail
                          .length - 1,
                        true
                      );
                    }
                  }}
                  style={{ margin: 10 }}
                >
                  <MaterialCommunityIcons
                    name="arrow-left-drop-circle"
                    size={30}
                    color="#FFFFFF"
                  />
                </TouchableOpacity>
                <View
                  style={{
                    width: size.width < 500 && "70%",
                  }}
                >
                  <Text style={[styles.textProgress, { textAlign: "left" }]}>
                    บทก่อนหน้า
                  </Text>
                  <Text numberOfLines={1} style={styles.textProgress}>
                    {data.dataStudymodel[data.Mainindex].listen_detail[
                      data.index - 1
                    ] != undefined
                      ? data.dataStudymodel[data.Mainindex].listen_detail[
                          data.index - 1
                        ].name
                      : data.dataStudymodel[data.Mainindex].listen_detail[0]
                          .name}
                  </Text>
                </View>
              </View>
            )}
            <TouchableOpacity
              disabled={true}
              style={[
                styles.buttonProgress,
                {
                  //backgroundColor: defaultsStatus ? "#E3F9D6" : "#FEF3CE",
                  width: size.width < 500 ? 100 : 150,
                },
              ]}
            >
              <Text
                style={[
                  styles.textSuccess,
                  { fontSize: size.width < 500 ? 14 : 20 },
                ]}
              >
                {defaultsStatus ? "เสร็จสิ้น" : "กำลังดำเนินการ.."}
              </Text>
            </TouchableOpacity>
            {data.dataStudymodel ? (
              <View
                style={[
                  styles.viewTextProgress,
                  { justifyContent: "flex-end" },
                ]}
              >
                <View
                  style={{
                    width: size.width < 500 && "70%",
                  }}
                >
                  <Text style={[styles.textProgress]}>บทถัดไป</Text>
                  <Text numberOfLines={1} style={[styles.textProgress]}>
                    {data.dataStudymodel[data.Mainindex].listen_detail[
                      data.index + 1
                    ] != undefined
                      ? data.dataStudymodel[data.Mainindex].listen_detail[
                          data.index + 1
                        ].name
                      : data.dataStudymodel[data.Mainindex + 1] != undefined
                      ? data.dataStudymodel[data.Mainindex + 1].listen_detail[0]
                          .name
                      : ""}
                  </Text>
                </View>
                <TouchableOpacity
                  onPress={async () => {
                    const Mainindex = data.Mainindex;
                    const i = data.index;
                    const newi = data.index + 1;

                    if (
                      data.dataStudymodel[Mainindex].listen_detail[i + 1] !=
                      undefined
                    ) {
                      Chanfd(
                        data.dataStudymodel[Mainindex].listen_detail[i + 1],
                        Mainindex,
                        newi,
                        data.dataStudymodel[Mainindex].listen_detail[i + 1]
                          .status
                      );
                    } else if (
                      data.dataStudymodel[Mainindex + 1].listen_detail[0] !=
                      undefined
                    ) {
                      Chanfd(
                        data.dataStudymodel[Mainindex + 1].listen_detail[0],
                        Mainindex + 1,
                        0,
                        data.dataStudymodel[Mainindex + 1].listen_detail[0]
                          .status
                      );
                    } else {
                      const respp = await apiservice({
                        path: "/start-course/getalltotals/" + data.course_id,
                        method: "get",
                        token: token?.accessToken,
                      });
                      const resppt = await apiservice({
                        path: "/start-course/getworktotals/" + data.course_id,
                        method: "get",
                        token: token?.accessToken,
                      });
                      if (respp.status == 200) {
                        setShow1(resppt.data.results);
                        setShow(respp.data.results);
                        setModalScore(true);
                      }
                    }
                  }}
                  disabled={defaultsStatus ? false : true}
                  style={{ margin: 10 }}
                >
                  <MaterialCommunityIcons
                    name={defaultsStatus ? "arrow-right-drop-circle" : "lock"}
                    size={30}
                    color="#FFFFFF"
                  />
                </TouchableOpacity>
              </View>
            ) : (
              <View style={styles.viewTextProgress}>
                <TouchableOpacity style={{ margin: 10 }}>
                  <MaterialCommunityIcons
                    name="arrow-right-drop-circle"
                    size={0}
                    color="#FFFFFF"
                  />
                </TouchableOpacity>
                <View>
                  <Text
                    style={[styles.textProgress, { textAlign: "right" }]}
                  ></Text>
                  <Text numberOfLines={1} style={styles.textProgress}></Text>
                </View>
              </View>
            )}
          </View>
        </View>
      </ScrollView>
    </View>
  );
}
const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: "100%",
    backgroundColor: "#FFFFFF",
  },
  text25: {
    fontFamily: "Prompt",
    fontSize: 25,
    color: "#0B2051",
    marginLeft: 25,
  },
  viewDashedModal1: {
    flexDirection: "row",
    borderRightWidth: 2,
    borderRightColor: "#0D88F0",
    width: "50%",
    borderStyle: "dashed",
    alignItems: "center",
    paddingVertical: 20,
  },
  viewDashedModal: {
    flexDirection: "row",
    borderRightWidth: 2,
    borderBottomWidth: 2,
    borderRightColor: "#0D88F0",
    borderBottomColor: "#0D88F0",
    width: "50%",
    borderStyle: "dashed",
    alignItems: "center",
    paddingVertical: 20,
  },
  textDetail: {
    fontFamily: "Prompt",
    fontSize: 20,
    color: "#646464",
    marginTop: 40,
  },
  viewModal: {
    width: "100%",
    height: "100%",
    backgroundColor: "#000000bb",
    justifyContent: "center",
  },
  viewDetailModal: {
    width: "100%",
    maxWidth: 650,
    height: "100%",
    maxHeight: 400,
    backgroundColor: "#FFFFFF",
    borderWidth: 1,
    borderColor: "#0D88F0",
    padding: 50,
    paddingHorizontal: width * 0.05,
    alignSelf: "center",
    justifyContent: "center",
  },
  buttonModal: {
    width: 150,
    height: 50,
    backgroundColor: "#0D88F0",
    borderRadius: 5,
    justifyContent: "center",
    alignItems: "center",
  },
  textButtonModal: {
    fontFamily: "Prompt",
    fontSize: 30,
    color: "#FFFFFF",
  },
  textTitleModal: {
    fontFamily: "Prompt",
    fontSize: 40,
    color: "#0B2051",
    alignSelf: "center",
  },
  viewProgress: {
    width: "100%",
    height: 70,
    flexDirection: "row",
    justifyContent: "space-between",
  },
  viewTextProgress: {
    flexDirection: "row",
    alignItems: "center",
    width: "30%",
  },
  textProgress: {
    fontFamily: "Prompt",
    fontSize: 12,
    color: "#FFFFFF",
    textAlign: "right",
  },
  buttonProgress: {
    width: 150,
    height: 40,
    borderRadius: 20,
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "center",
  },
  textSuccess: {
    fontFamily: "Prompt",
    fontSize: 20,
    color: "#fff",
  },
  viewModal: {
    width: "100%",
    height: "100%",
    backgroundColor: "#000000bb",
    justifyContent: "center",
  },
  viewDetailModal: {
    width: "100%",
    maxWidth: 650,
    height: "100%",
    maxHeight: 400,
    backgroundColor: "#FFFFFF",
    borderWidth: 1,
    borderColor: "#0D88F0",
    padding: 50,
    paddingHorizontal: width * 0.05,
    alignSelf: "center",
    justifyContent: "center",
  },
  buttonModal: {
    width: 150,
    height: 50,
    backgroundColor: "#0D88F0",
    borderRadius: 5,
    justifyContent: "center",
    alignItems: "center",
  },
  textButtonModal: {
    fontFamily: "Prompt",
    fontSize: 30,
    color: "#FFFFFF",
  },
  textTitleModal: {
    fontFamily: "Prompt",
    fontSize: 40,
    color: "#0B2051",
    alignSelf: "center",
  },
  buttonModal1: {
    width: 150,
    height: 50,
    borderWidth: 1,
    borderColor: "#0D88F0",
    borderRadius: 5,
    justifyContent: "center",
    alignItems: "center",
  },
  textDetailModal: {
    fontFamily: "Prompt",
    fontSize: 18,
    color: "#0B2051",
  },
  viewDashedModal: {
    flexDirection: "row",
    borderRightWidth: 2,
    borderBottomWidth: 2,
    borderRightColor: "#0D88F0",
    borderBottomColor: "#0D88F0",
    width: "50%",
    borderStyle: "dashed",
    alignItems: "center",
    paddingVertical: 20,
  },
  viewDashedModal1: {
    flexDirection: "row",
    borderRightWidth: 2,
    borderRightColor: "#0D88F0",
    width: "50%",
    borderStyle: "dashed",
    alignItems: "center",
    paddingVertical: 20,
  },
  text25: {
    fontFamily: "Prompt",
    fontSize: 25,
    color: "#0B2051",
    marginLeft: 25,
  },
  viewCkEditor: {
    borderWidth: 1,
    borderColor: "#0D88F0",
    marginTop: 55,
  },
});
const stylesResponsive = StyleSheet.create({
  textDetail: {
    fontFamily: "Prompt",
    fontSize: 14,
    color: "#0B2051",
    marginTop: 40,
  },
});
