import { View, Text } from "react-native";
import React, { useState, useEffect } from "react";
import { useRecoilState } from "recoil";
import { courseState } from "../../reducer/recoil";
import { apiservice } from "../../service";

export default function share({ route }) {
  const [courseStates, setCourseStates] = useRecoilState(courseState);

  useEffect(() => {
    name();
  }, []);
  async function name() {
    const response = await apiservice({
      path: "/course/getall?page=1&lan=TH",
      method: "get",
      //token: token?.accessToken,
    });

    if (response.status == 200) {
      let data = response?.data?.data4?.filter(
        (item) => item.id == route?.params?.course_id
      );

      setCourseStates(data[0]);
      setTimeout(() => {
        navigation.navigate("Course");
      }, 1000);
    }
  }
  return <View></View>;
}
