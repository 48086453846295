import React, { useState } from "react";
import {
  View,
  Text,
  Image,
  TouchableOpacity,
  TextInput,
  Alert,
} from "react-native";
import { apiservice } from "../../service";
import { useWindowSize } from "../components/responsive";
export default function newPassword({ navigation, route }) {
  const [body, setBody] = useState({
    email: route.params.email,
    password: "",
    newpassword: "",
  });

  const size = useWindowSize();
  return (
    <View
      style={{
        width: "100%",
        height: "100%",
        backgroundColor: "#FFFFFF",
      }}
    >
      <View
        style={{
          borderWidth: 1,
          borderRadius: 5,
          width: size.width < 1280 ? "80%" : "30%",
          borderColor: "#CCCCCC",
          alignSelf: "center",
          alignItems: "center",
          marginTop: 50,
          padding: 40,
        }}
      >
        <Image
          resizeMode={"stretch"}
          style={{ width: 100, height: 80 }}
          source={require("../img/logo.png")}
        />
        {/* <Text
          style={{
            fontSize: 18,
            fontFamily: "Prompt",
            marginTop: 20,
            color: "#0B2051",
          }}
        >
          ป้อนที่อยู่อีเมลเชื่อมโยงกับบัญชีของคุณ
        </Text> */}
        <View
          style={{
            width: size.width < 1280 ? "80%" : "60%",
            marginVertical: 20,
          }}
        >
          <Text
            style={{ fontSize: 18, fontFamily: "Prompt", color: "#0B2051" }}
          >
            รหัสผ่านใหม่
          </Text>
          <TextInput
            autoComplete="off"
            onChangeText={(text) => {
              setBody((val) => ({
                ...val,
                password: text,
              }));
            }}
            secureTextEntry
            value={body.password}
            style={{
              width: "100%",
              height: 40,
              borderWidth: 1,
              borderColor: "#CCCCCC",
              borderRadius: 5,
              fontSize: 18,
              fontFamily: "Prompt",
              color: "#0B2051",
              paddingHorizontal: 10,
            }}
          />
          <Text
            style={{
              fontSize: 18,
              fontFamily: "Prompt",
              color: "#0B2051",
              marginTop: 20,
            }}
          >
            ยืนยันรหัสผ่านใหม่
          </Text>
          <TextInput
            autoComplete="off"
            secureTextEntry
            onChangeText={(text) => {
              setBody((val) => ({
                ...val,
                newpassword: text,
              }));
            }}
            value={body.newpassword}
            style={{
              width: "100%",
              height: 40,
              borderWidth: 1,
              borderColor: "#CCCCCC",
              borderRadius: 5,
              fontSize: 18,
              fontFamily: "Prompt",
              color: "#0B2051",
              paddingHorizontal: 10,
            }}
          />
        </View>
        <TouchableOpacity
          onPress={async () => {
            if (body.newpassword == body.password) {
              const response = await apiservice({
                path: "/authen/updatepass",
                method: "put",
                body,
              });
              if (response.status == 200) {
                Alert.alert("สำเร็จ");
                navigation.navigate("Home");
              }
            } else {
              Alert.alert("รหัสใหม่ไม่ถูกต้อง");
            }
          }}
          style={{
            width: size.width < 1280 ? "80%" : "60%",
            height: 40,
            backgroundColor: "#0D88F0",
            borderRadius: 5,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Text
            style={{ fontSize: 18, fontFamily: "Prompt", color: "#FFFFFF" }}
          >
            ยืนยัน
          </Text>
        </TouchableOpacity>
      </View>
    </View>
  );
}
