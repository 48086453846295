import React, { Component } from "react"; 
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"; 
import { View } from "react-native";
// fake data generator

const getItems = (count) =>
  Array.from({ length: count }, (v, k) => k).map((k) => ({
    id: `item-${k}`,
    content: `item ${k}`,
  }));

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  if (startIndex < endIndex) {
    endIndex -= 1;
  }

  if (startIndex > endIndex) {
    endIndex += 1;
  }

  result.splice(endIndex, 0, removed);

  console.log(result ,startIndex ,endIndex);

  return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  color: "#FFFFFF",
  textAlign: "center",
  height: 35,
  marginTop: 10,
  paddingTop: 5,
  fontSize: 20,
  fontFamily: "Prompt",
  width: "100%",
  // change background colour if dragging
  background: isDragging ? "lightgreen" : "#0D88F0",
  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  //background: isDraggingOver ? "lightblue" : "lightgrey",

  width: "100%",
});

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: this.props.data.map((item) => ({ ...item, id: item.answer })),
      default: this.props.data.map((item) => ({ ...item, id: item.answer })),
    };
    this.onDragEnd = this.onDragEnd.bind(this);
  }

  componentDidMount() {
    this.props.setAnswer((val) =>
      val.concat({
        number: this.props.index,
        answer: this.state.default,
        totals: "",
      })
    );
  }

  onDragEnd(result) { 
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const index = this.props.index;

    const items = reorder(
      this.state.items,
      result.source.index,
      result.destination.index
    ); 

    this.setState({
      items: items
    });

    if (
      this.props.answer.filter((value) => {
        return value.number == index;
      }).length > 0
    ) {
      this.props.setAnswer((val) =>
        val.map((values) => {
          if (values.number == index) {
            return {
              ...values,
              answer: items?.reverse(),
            };
          } else {
            return values;
          }
        })
      );
    } else {
      this.props.setAnswer((val) =>
        val.concat({
          number: index,
          answer: items?.reverse(),
          totals: "",
        })
      );
    }
  }

  // Normally you would want to split things out into separate components.
  // But in this example everything is just done in one place for simplicity

  render() {
    return (
      <View>
        <DragDropContext onDragEnd={this.onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
              >
                {this.state.items.map((item, index) => {
                  console.log('====================================');
                  console.log(item.id, index);
                  console.log('====================================');
                  return <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}
                      >
                        {item.answer}
                      </div>
                    )}
                  </Draggable>
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </View>
    );
  }
}

// Put the thing into the DOM!
export default App;
