import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  Image,
  Dimensions,
  Animated,
  TouchableOpacity,
  StyleSheet,
  ScrollView,
  TextInput,
  FlatList,
} from "react-native";
import {
  MaterialCommunityIcons,
  MaterialIcons,
  Feather,
  Ionicons,
  FontAwesome,
} from "@expo/vector-icons";
import HeaderAdmin from "../../components/headerAdmin";
import ButtonMenuSuperAdmin from "../../components/buttonMenuSuperAdmin";
import ProfileAdmin from "../../components/profileAdmin";
import Bill from "./page1/Bill";
import BillDetail from "./page1/BillDetail";
import ListName from "./page1/ListName";
import Finance from "./page2/Finance";
import Detail from "./page2/Detail";
import ListNameP2 from "./page2/ListName";
import DetailP3 from "./page3/Detail";
import Student from "./page3/Student";
import { tokenState } from "../../../reducer/recoil";
import { useRecoilValue } from "recoil";
import { useWindowSize } from "../../components/responsive";
const { width, height } = Dimensions.get("window");
export default function main({ navigation, route }) {
  const [page, setPage] = useState(0);
  const [detailPage, setDetailPage] = useState(0);
  const [detailPage2, setDetailPage2] = useState(0);
  const [detailPage3, setDetailPage3] = useState(false);
  const [items, setItems] = useState({});
  const [itemss, setItemss] = useState({});
  const [itemsss, setItemsss] = useState({});
  const token = useRecoilValue(tokenState);
  const [openMenu, setOpenMenu] = useState(false);
  const size = useWindowSize();
  return (
    <View style={styles.container}>
      <HeaderAdmin
        onPress={() => {
          setOpenMenu((val) => !val);
        }}
        navigation={navigation}
        headerReport
        setPage={setPage}
      />
      <View style={{ width: "100%", flexDirection: "row", height: "100%" }}>
        {openMenu && (
          <View //menu
            style={{
              width: "13%",

              minWidth: 220,
              height: "100%",
              backgroundColor: "#444444",
              position: "absolute",
              zIndex: 99,
            }}
          >
            <View style={{ height: "80%" }}>
              {(token?.role == "SUPERADMIN" ||
                token?.role == "GENERALADMIN") && (
                <ButtonMenuSuperAdmin
                  p1
                  onPress={() => {
                    setOpenMenu(false);
                    navigation.navigate("UniversityCourseSA");
                  }}
                />
              )}
              {(token?.role == "SUPERADMIN" ||
                token?.role == "GENERALADMIN") && (
                <ButtonMenuSuperAdmin
                  p2
                  onPress={() => {
                    setOpenMenu(false);
                    navigation.navigate("GeneralCourseSA");
                  }}
                />
              )}
              {(token?.role == "SUPERADMIN" ||
                token?.role == "GENERALADMIN") && (
                <ButtonMenuSuperAdmin
                  p3
                  onPress={() => {
                    setOpenMenu(false);
                    navigation.navigate("AddAdminSA");
                  }}
                />
              )}
              {(token?.role == "ACCOUNTING" || token?.role == "SUPERADMIN") && (
                <ButtonMenuSuperAdmin
                  p4
                  color
                  border
                  onPress={() => {
                    setOpenMenu(false);
                    navigation.navigate("ReportSA");
                  }}
                />
              )}
              {(token?.role == "SUPERADMIN" ||
                token?.role == "GENERALADMIN") && (
                <ButtonMenuSuperAdmin
                  p5
                  onPress={() => {
                    setOpenMenu(false);
                    navigation.navigate("SlideBannerSA");
                  }}
                />
              )}
              {(token?.role == "SUPERADMIN" ||
                token?.role == "GENERALADMIN") && (
                <ButtonMenuSuperAdmin
                  p6
                  onPress={() => {
                    setOpenMenu(false);
                    navigation.navigate("Discount");
                  }}
                />
              )}
              {(token?.role == "SUPERADMIN" ||
                token?.role == "GENERALADMIN") && (
                <ButtonMenuSuperAdmin
                  p7
                  onPress={() => {
                    setOpenMenu(false);
                    navigation.navigate("Account");
                  }}
                />
              )}
            </View>
            <ProfileAdmin />
          </View>
        )}
        {size.width >= 1280 && (
          <View //menu
            style={{
              width: "13%",

              minWidth: 220,
              height: "100%",
              backgroundColor: "#444444",
            }}
          >
            <View style={{ height: "80%" }}>
              {(token?.role == "SUPERADMIN" ||
                token?.role == "GENERALADMIN") && (
                <ButtonMenuSuperAdmin
                  p1
                  onPress={() => {
                    navigation.navigate("UniversityCourseSA");
                  }}
                />
              )}
              {(token?.role == "SUPERADMIN" ||
                token?.role == "GENERALADMIN") && (
                <ButtonMenuSuperAdmin
                  p2
                  onPress={() => {
                    navigation.navigate("GeneralCourseSA");
                  }}
                />
              )}
              {(token?.role == "SUPERADMIN" ||
                token?.role == "GENERALADMIN") && (
                <ButtonMenuSuperAdmin
                  p3
                  onPress={() => {
                    navigation.navigate("AddAdminSA");
                  }}
                />
              )}
              {(token?.role == "ACCOUNTING" || token?.role == "SUPERADMIN") && (
                <ButtonMenuSuperAdmin
                  p4
                  color
                  border
                  onPress={() => {
                    navigation.navigate("ReportSA");
                  }}
                />
              )}
              {(token?.role == "SUPERADMIN" ||
                token?.role == "GENERALADMIN") && (
                <ButtonMenuSuperAdmin
                  p5
                  onPress={() => {
                    navigation.navigate("SlideBannerSA");
                  }}
                />
              )}
              {(token?.role == "SUPERADMIN" ||
                token?.role == "GENERALADMIN") && (
                <ButtonMenuSuperAdmin
                  p6
                  onPress={() => {
                    navigation.navigate("Discount");
                  }}
                />
              )}
              {(token?.role == "SUPERADMIN" ||
                token?.role == "GENERALADMIN") && (
                <ButtonMenuSuperAdmin
                  p7
                  onPress={() => {
                    navigation.navigate("Account");
                  }}
                />
              )}
            </View>
            <ProfileAdmin />
          </View>
        )}
        <ScrollView>
          {page == 0 && (
            <View style={styles.viewDetail}>
              {detailPage == 0 && (
                <Bill
                  onPress={(e) => {
                    setItems(e);
                    setDetailPage(1);
                  }}
                />
              )}
              {detailPage == 1 && (
                <BillDetail
                  items={items}
                  ongoBack={() => {
                    setDetailPage(0);
                  }}
                  onPress={(e) => {
                    setItemss(e);
                    setDetailPage(2);
                  }}
                />
              )}
              {detailPage == 2 && (
                <ListName
                  items={itemss}
                  ongoMain={() => {
                    setDetailPage(0);
                  }}
                  ongoBack={() => {
                    setDetailPage(1);
                  }}
                />
              )}
            </View>
          )}
          {page == 1 && (
            <View style={styles.viewDetail}>
              {detailPage2 == 0 && (
                <Finance
                  onPress={(e) => {
                    setItems(e);
                    setDetailPage2(1);
                  }}
                />
              )}
              {detailPage2 == 1 && (
                <Detail
                  items={items}
                  ongoBack={() => {
                    setDetailPage2(0);
                  }}
                  onPress={(e) => {
                    setItemsss(e);
                    setDetailPage2(2);
                  }}
                />
              )}
              {detailPage2 == 2 && (
                <ListNameP2
                  items={itemsss}
                  ongoMain={() => {
                    setDetailPage2(0);
                  }}
                  ongoBack={() => {
                    setDetailPage2(1);
                  }}
                />
              )}
            </View>
          )}
          {page == 2 && (
            <View style={styles.viewDetail}>
              {!detailPage3 ? (
                <Student
                  onPress={(e) => {
                    setItems(e);
                    setDetailPage3(true);
                  }}
                />
              ) : (
                <DetailP3
                  items={items}
                  ongoBack={() => {
                    setDetailPage3(false);
                  }}
                />
              )}
            </View>
          )}
        </ScrollView>
      </View>
    </View>
  );
}
const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: "100%",
    backgroundColor: "#FFFFFF",
  },
  viewDetail: {
    width: "100%",
    height: "100%",
    paddingHorizontal: 55,
    paddingVertical: 28,
  },
});
