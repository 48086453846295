import React, { useState } from "react";
import {
  View,
  Text,
  Dimensions,
  TouchableOpacity,
  StyleSheet,
  FlatList,
  ScrollView,
} from "react-native";
import { useWindowSize } from "../../components/responsive";

export default function courseName({ ongoBack, detail }) {
  const DATA = [
    // {
    //   study: "100",
    //   pass: "60",
    //   unPass: "20",
    //   studing: "10",
    // },
  ];
  const size = useWindowSize();
  return (
    <View>
      <View style={{ flexDirection: "row", marginTop: 10 }}>
        <TouchableOpacity onPress={ongoBack}>
          <Text style={styles.text23}>คอร์สทั่วไป</Text>
        </TouchableOpacity>
        <Text
          style={[
            styles.text23,
            {
              marginHorizontal: 18,
            },
          ]}
        >
          /
        </Text>
        <TouchableOpacity onPress={ongoBack}>
          <Text style={styles.text23}>คอร์สทั้งหมด</Text>
        </TouchableOpacity>
        {size.width >= 1280 && (
          <Text
            style={[
              styles.text23,
              {
                marginHorizontal: 18,
              },
            ]}
          >
            /
          </Text>
        )}
        {size.width >= 1280 && (
          <Text style={[styles.text23, { color: "#0D88F0" }]}>ชื่อคอร์ส</Text>
        )}
      </View>
      <Text
        style={{
          fontSize: 25,
          fontFamily: "Prompt",
          color: "#444444",
          marginTop: 52,
        }}
      >
        {detail.title}
      </Text>
      <ScrollView horizontal>
        <View
          style={{
            width:
              size.width < 500
                ? size.width * 2.5
                : size.width < 1280
                ? size.width * 1.0
                : size.width * 0.75,
            // minHeight: height * 2.5,
          }}
        >
          <View style={styles.viewSubject}>
            <Text style={[styles.text16B, { paddingLeft: 67 }]}>
              จำนวนผู้ที่ลงเรียน
            </Text>
            <Text style={[styles.text16B]}>จำนวนผู้ที่เรียนผ่าน</Text>
            <Text style={[styles.text16B]}>จำนวนผู้ที่เรียนไม่ผ่าน</Text>
            <Text style={[styles.text16B]}>จำนวนผู้ที่ยังเรียนอยู่</Text>
          </View>

          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              borderBottomWidth: 1,
              borderBottomColor: "#E5E5E5",
              height: 50,
              alignItems: "center",
            }}
          >
            <Text style={[styles.text16B, { paddingLeft: 67 }]}>
              {detail.length}
            </Text>
            <Text style={[styles.text16B]}>{detail.prove}</Text>
            <Text style={[styles.text16B]}>{detail.notprove}</Text>
            <Text style={[styles.text16B]}>{detail.pending}</Text>
          </View>
        </View>
      </ScrollView>
    </View>
  );
}
const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: "100%",
    backgroundColor: "#FFFFFF",
  },
  text23O: { fontSize: 23, fontFamily: "Prompt", color: "#0D88F0" },
  text16B: {
    fontFamily: "Prompt",
    fontSize: 16,
    color: "#000000",
    width: "23%",
  },

  viewSubject: {
    width: "100%",
    height: 51,
    backgroundColor: "#E5E5E5",
    marginTop: 50,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  text23: {
    fontSize: 23,
    fontFamily: "Prompt",
    color: "#808D93",
  },
});
