import {
  View,
  Text,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
} from "react-native";
import React from "react";
import Header from "../components/header";
import { BottomPart } from "./home";
export default function Privacy({ navigation }) {
  return (
    <View style={styles.container}>
      <ScrollView stickyHeaderIndices={[0]}>
        <Header navigation={navigation} unShadow />
        {/* <TouchableOpacity
            onPress={() => {
              setModalWarning(false);
            }}
            style={{ position: "absolute", right: 0, zIndex: 99 }}
          >
            <Ionicons name="close" size={30} color="#0D88F0" />
          </TouchableOpacity> */}
        <View style={{ width: "60%", alignSelf: "center", marginVertical: 40 }}>
          <Text style={styles.textTitle}>ข้อตกลงและเงื่อนไข ผู้เรียน</Text>
          <Text style={styles.text}>1. บทนำ</Text>
          <Text style={styles.text}>
            {"\t"}
            เงื่อนไขและข้อตกลงในการใช้บริการนี้เป็นข้อผูกพันระหว่าง บริษัท
            เอเชียคอนเน็คท์ คอร์ปอเรชั่น จำกัด (“บริษัท”)
            กับผู้ที่สมัครเรียนออนไลน์ของบริษัท ซึ่งทำการสมัครเรียนผ่านเว็บไซต์
            www.courseoncloud.com ซึ่งต่อไปในข้อตกลงนี้จะเรียกว่า “ผู้เรียน”
            บริษัทตกลงว่าผู้เรียนสามารถได้สิทธิต่าง ๆ ตามที่บริษัทจัดหาให้
            ซึ่งรวมถึงการเข้าร่วมกิจกรรม การถามตอบในห้องเรียน วิดีโอคอร์สเรียน
            เอกสารประกอบการเรียน เวิร์คช็อป ใบกระกาศนียบัตรออนไลน์ ทั้งนี้
            เมื่อผู้เรียนตกลงชำระเงิน และ/หรือล็อกอินเข้าสู่ระบบแล้ว
            ผู้เรียนตกลงที่จะปฏิบัติตามข้อกำหนดและเงื่อนไขทั้งหมดที่ระบุในเงื่อนไขและข้อตกลงในการใช้บริการฉบับนี้
            หรือที่จะมีการแก้ไขหรือเปลี่ยนแปลงในภายหลัง
          </Text>
          <Text style={styles.text}>
            {"\t"}
            หากประสงค์จะใช้งาน หรือเชื่อมข้อมูลผู้ใช้
            รวมถึงข้อมูลการชำระค่าบริการต่างๆ เพื่อให้ใช้งาน
            ท่านจะต้องสร้างบัญชีผู้ใช้ด้วย Facebook Google หรือ
            อีเมล์ด้วยระบบลงทะเบียนของ เว็บไซต์
            www.courseoncloud.comและเลือกชื่อผู้ใช้ของตนเอง
            สมาชิกควรให้ข้อมูลลงทะเบียนที่ถูกต้อง ครบถ้วน
            และให้ข้อมูลที่มีความทันสมัย (สำหรับผู้ใช้ที่เข้าสู่ระบบด้วย
            Facebook หรือ Google เราจะไม่มีข้อมูล password ของท่านในระบบ
            (นอกจากนี้ เราจะไม่โพสต์ข้อความใดๆ
            ลงบนโปรไฟล์ของท่านหากไม่ได้รับอนุญาต) (“บัญชี”)
          </Text>
          <Text style={styles.text}>
            {"\t"}ผู้ใช้สามารถลบบัญชีผู้ใช้ได้
            โดยส่งคำร้องขอมายังอีเมลของฝ่ายสนับสนุน support@courseoncloud.com
            เพื่อการดำเนินการต่อไป{"\n\n"}
          </Text>
          <Text style={styles.text}>2. นโยบายคุ้มครองข้อมูลส่วนบุคคล</Text>
          <Text style={styles.text}>
            {"\t"}ผู้เรียนจะถูกจัดเก็บ ใช้ประโยชน์ และกระทำการใด ๆ
            อันเป็นไปตามนโยบายคุ้มครองข้อมูลส่วนบุคคลของบริษัท ทั้งนี้
            ผู้เรียนสามารถอ่านและศึกษานโยบายคุ้มครองข้อมูลส่วนบุคคลได้ที่
            https://www.courseoncloud.com/privacy-policy{"\n\n"}
          </Text>
          <Text style={styles.text}>3. เงื่อนไขทั่วไป</Text>
          <Text style={styles.text}>
            {"\t"}3.1 บริษัทขอสงวนสิทธิในการเปลี่ยนแปลง แก้ไข หรือยกเลิก
            เงื่อนไขและข้อตกลงในการใช้บริการ และเปลี่ยนแปลง แก้ไข
            หรือยกเลิกช่วงเวลาในการบังคับใช้เงื่อนไขและข้อตกลงในการใช้บริการได้ทุกเมื่อ
            โดยไม่ต้องแจ้งให้แก่ผู้เรียนทราบล่วงหน้า
            เว้นแต่เป็นกรณีตามที่ระบุไว้ในข้อ 3.4
            ของเงื่อนไขและข้อตกลงในการใช้บริการฉบับนี้{"\n\t"}3.2
            ผู้เรียนตกลงและยอมรับว่าผู้เรียนมีหน้าที่ล็อกอินเข้าสู่เว็บไซต์
            www.courseoncloud.com
            อย่างสม่ำเสมอเพื่อศึกษาเงื่อนไขการใช้บริการต่าง ๆ
            ที่อาจมีการเปลี่ยนแปลง แก้ไข และ/หรือยกเลิก
            รวมถึงช่วงเวลาที่เงื่อนไขดังกล่าวจะมีผลใช้บังคับตามข้อ 3.1
            โดยความรับผิดชอบของผู้เรียนเองแต่เพียงฝ่ายเดียว{"\n\t"}3.3
            เมื่อบริษัทได้ทำการเปลี่ยนแปลง แก้ไข และ/หรือยกเลิก
            เงื่อนไขและข้อตกลงในการใช้บริการ
            หรือช่วงเวลาที่เงื่อนไขดังกล่าวจะมีผลใช้บังคับ
            และเงื่อนไขดังกล่าวมีผลใช้บังคับแล้ว
            ให้ถือว่าผู้เรียนตกลงยอมรับในการปฏิบัติตามเงื่อนไขที่ได้รับการเปลี่ยนแปลง
            แก้ไข หรือยกเลิกนั้น{"\n\t"}3.4
            ผู้เรียนตกลงรับผิดชอบค่าใช้จ่ายในการสมัครเรียน และค่าวัสดุ
            อุปกรณ์ในการเรียนการสอนด้วยค่าใช้จ่ายของตนเอง ทั้งนี้
            บริษัทขอสงวนสิทธิในการเปลี่ยนแปลง แก้ไข และ/หรือ ยกเลิกบริการใด ๆ
            เมื่อผู้เรียนได้ชำระค่าเรียนและค่าใช้จ่ายอื่น ๆ (หากมี)
            ให้แก่บริษัทแล้ว
            โดยบริษัทจะแจ้งให้ผู้เรียนทราบล่วงหน้าเป็นลายลักษณ์อักษรผ่านทางเว็บไซต์
            www.courseoncloud.com{"\n\n"}4.
            ผู้เรียนตกลงจะไม่ดำเนินการดังต่อไปนี้{"\n\t"}4.1 ดาวน์โหลด คัดลอก
            ทำซ้ำ เผยแพร่ ดัดแปลง reverse engineer ปรับเปลี่ยน interface ทำลาย
            ขโมยข้อมูล เอกสาร สื่อการเรียนการสอน หรือสิ่งอื่นใดที่เป็นทรัพย์
            และ/หรือทรัพย์สินของบริษัท
            หรือที่บริษัทจัดหามาให้แก่ผู้เรียนตามหลักสูตรของบริษัท
            โดยบริษัทจะถือว่าการฝ่าฝืนข้อกำหนดดังกล่าวเป็นความผิดอาญาตามกฎหมาย
            {"\n\t"}4.2 แสดงตนว่าเป็นบุคคลอื่น
            ให้ข้อมูลส่วนตัวที่ไม่เป็นความจริง
            หรือละเว้นการให้ข้อมูลที่สำคัญต่อการสมัครเรียน
            รวมถึงการเรียนหลักสูตรของบริษัท{"\n\t"}4.3 ปล่อย virus worm spyware
            computer code file program หรือสิ่งอื่นใด ที่อาจจะส่งผลเสีย
            หรือเข้าไปควบคุมการทำงานของเว็บไซต์ หรือเว็บไซต์อื่นใดของบริษัท
            และ/หรือบริษัทในกลุ่มหรือในเครือของบริษัท
            ไม่ว่าโดยจงใจหรือประสาทเลินเล่อ{"\n\t"}4.4
            กระทำการอื่นใดอันเป็นการขัดหรือแย้งต่อเงื่อนไขและข้อตกลงในการใช้บริการฉบับนี้
            {"\n\n"}5. สิทธิของบริษัท{"\n\t"}5.1
            บริษัทขอสงวนสิทธิในการเปลี่ยนแปลง แก้ไข
            ยกเลิกหลักสูตรและการสอนได้ทุกเมื่อ
            โดยจะมีการแจ้งให้ผู้เรียนทราบล่วงหน้าผ่านทางเว็บไซต์ ทั้งนี้
            ผู้เรียนจะต้องทำการล็อกอินเข้าสู่ระบบอย่างสม่ำเสมอเพื่อให้มั่นใจว่าผู้เรียนได้รับรู้ถึงเงื่อนไขล่าสุด
            ที่มีการประกาศและบังคับใช้ในแต่ละช่วงเวลาตามที่ระบุไว้ในข้อ 3
            ของเงื่อนไขและข้อตกลงในการใช้บริการฉบับนี้{"\n\t"}5.2
            บริษัทขอสงวนสิทธิในการคืนเงินกรณีที่ผู้เรียนต้องการยกเลิกการเรียนในหลักสูตรที่ได้ทำการสมัครและชำระเงินไว้แล้ว
            โดยบริษัทมีดุลพินิจแต่เพียงผู้เดียวในการพิจารณาการคืนเงินเป็นรายกรณี
            {"\n\t"}5.3
            บริษัทขอสงวนสิทธิในการอนุญาตให้ผู้เรียนเลื่อนการเรียนการสอนในหลักสูตรที่ได้ทำการสมัครและชำระเงินไว้แล้ว
            หากมีเหตุจำเป็นและสมควรที่ทำให้ผู้เรียนไม่สามารถมาเข้าเรียนหลักสูตรตามที่กำหนดไว้
            และผู้เรียนต้องการโอนคอร์สต่อให้ผู้อื่น
            ผู้เรียนตกลงจะแจ้งให้เจ้าหน้าที่ของบริษัทซึ่งเป็นผู้รับผิดชอบทราบ
            เป็นลายลักษณ์อักษร โดยบริษัทจะพิจารณาเป็นรายกรณี{"\n"}6.
            ทรัพย์สินทางปัญญา{"\n\t"}
            หลักสูตรและการพัฒนาเนื้อหาทั้งหมดที่ได้รับการจัดการ จัดทำ
            และจัดหามาให้ผู้เรียนโดยบริษัท รวมถึงแพลตฟอร์มออนไลน์ ข้อความ ภาพ
            เสียง วิดีโอ เนื้อหา ข้อมูล เครื่องหมายการค้า เครื่องหมายการบริการ
            ชื่อ ชื่อทางการค้า สิทธิบัตร ลิขสิทธิของบริษัท
            และ/หรือบุคคลที่บริษัทเข้าทำสัญญา
            หรือข้อตกลงอื่นใดเพื่อให้ได้มาซึ่งหลักสูตร
            และการพัฒนาเนื้อหาดังกล่าวนั้น เป็นงานอันได้รับความคุ้มครองทางกฎหมาย
            (“งานอันได้รับความคุ้มครอง”) ผู้เรียนจะไม่ลอกเลียน ปลอมแปลง ทำซ้ำ
            คัดลอก ดัดแปลง เผยแพร่ต่อสาธารณชน จำหน่าย หรือ กระทำการใด ๆ
            อันเป็นการแสวงหาประโยชน์โดยตรงหรือโดยอ้อม
            และไม่ว่าจะมีค่าตอบแทนหรือไม่
            โดยไม่ได้รับความยินยอมล่วงหน้าเป็นลายลักษณ์อักษรจากบริษัท ทั้งนี้
            การที่ผู้เรียนมีสิทธิเข้าถึงงานอันได้รับความคุ้มครองข้างต้น
            มิได้ทำให้ผู้เรียนมีสิทธิในความเป็นเจ้าของในงานอันได้รับความคุ้มครอง
            โดยผู้เรียนยอมรับว่างานอันได้รับความคุ้มครองยังคงเป็นของบริษัท
            และ/หรือบุคคลที่บริษัทเข้าทำสัญญา
            หรือข้อตกลงอื่นใดเพื่อให้ได้มาซึ่งหลักสูตร
            และการพัฒนาเนื้อหาดังกล่าว แล้วแต่กรณี{"\n\n"}7. การใช้งาน
            {"\n\t"}ผู้เรียนตกลงว่าจะใช้วัสดุ อุปกรณ์ หลักสูตร เนื้อหา
            และสื่อการเรียนการสอนต่าง ๆ เฉพาะเพื่อการศึกษาในหลักสูตรนั้น ๆ
            เท่านั้น
            และผู้เรียนตกลงว่าจะไม่นำเอาส่วนใดส่วนหนึ่งของสิ่งที่กล่าวข้างต้นไปใช้
            หรือเปิดเผยเพื่อวัตถุประสงค์อื่นใดโดยเด็ดขาด
            เว้นแต่จะรับความยินยอมล่วงหน้าเป็นลายลักษณ์อักษรจากบริษัท
            {"\n\n"}8. ขอบเขตความรับผิดชอบ{"\n\t"}
            บริษัทจะไม่รับผิดชอบในความสูญหายหรือเสียหายใด ๆ
            ซึ่งเกิดจากการที่ผู้เรียนสมัครเข้าเรียนหลักสูตรใด ๆ
            ชำระค่าเรียนและไม่เข้าเรียนตามหลักสูตรที่บริษัทกำหนด
            หรือการที่ผู้เรียนนำเนื้อหาส่วนใดส่วนหนึ่งหรือทั้งหมดของวัสดุ
            อุปกรณ์ สื่อการเรียนการสอน ไปใช้
            หรือเปิดเผยเพื่อวัตถุประสงค์อื่นที่ไม่ใช่เพื่อการศึกษาหาความรู้ในหลักสูตรนั้น
            ๆ หรือคัดลอก ทำซ้ำ หรือเปลี่ยนแปลงหลักสูตร
            และ/หรือเนื้อหาที่บริษัทจัดทำขึ้นไปใช้เพื่อแสวงหาผลประโยชน์อื่นใดไม่ว่าโดยตรงหรือโดยอ้อม
            ทั้งนี้ หากการกระทำอย่างใดอย่างหนึ่งของผู้เรียนทำให้บริษัท
            และ/หรือบริษัทในกลุ่มหรือในเครือของบริษัทถูกฟ้องร้องหรือได้รับความเสียหาย
            ผู้เรียนตกลงจะรับผิดชอบชดใช้ความเสียหายที่เกิดขึ้นตามจริงให้แก่บริษัท
            และ/หรือบริษัทในกลุ่มหรือในเครือของบริษัท{"\n\n"}9.
            การยกเลิกการสมัครเรียน{"\n\t"}
            หากผู้เรียนไม่ปฏิบัติตามเงื่อนไขและข้อตกลงในการใช้บริการข้อใดข้อหนึ่งหรือหลายข้อ
            บริษัทมีสิทธิเพิกถอนและ/หรือระงับการเรียนในหลักสูตรที่ผู้เรียนได้สมัครและชำระค่าเรียนให้แก่บริษัทแล้วได้ทันที
            โดยบริษัทจะแจ้งให้ทราบเป็นลายลักษณ์อักษรผ่านทางเว็บไซต์
            www.courseoncloud.com ทั้งนี้ บริษัทขอสงวนสิทธิในการไม่คืนค่าเรียน
            และ/หรือค่าใช้จ่ายอื่นใด (หากมี) ที่ผู้เรียนได้ชำระให้แก่บริษัทแล้ว
            ในกรณีที่บริษัทเห็นว่าการไม่ปฏิบัติตามเงื่อนไขและข้อตกลงในการใช้บริการข้อใดอาจทำให้บริษัทเสียหายอย่างร้ายแรง
            บริษัทมีสิทธิแต่เพียงผู้เดียวในการระงับชื่อบัญชีผู้ใช้งานและรหัสผ่านของผู้เรียน
            และบริษัทมีสิทธิปฏิเสธไม่ให้ผู้เรียน
            และ/หรือบุคคลที่เกี่ยวข้องกับการไม่ปฏิบัติตามเงื่อนไขดังกล่าวสมัครเรียนในหลักสูตรใดก็ตามของบริษัทในอนาคต
            ทั้งนี้ บริษัทจะไม่รับผิดชอบในความสูญหายหรือเสียหายใด ๆ
            ต่อผู้เรียนในกรณีดังกล่าว
          </Text>
        </View>
        <BottomPart navigation={navigation} />
      </ScrollView>
    </View>
  );
}
const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: "100%",
    backgroundColor: "#FFFFFF",
  },
  text: {
    fontSize: 18,
    fontFamily: "Prompt",
    color: "#484848",
  },
  textTitle: {
    fontSize: 30,
    fontFamily: "Prompt",
    color: "#484848",
    marginTop: 40,
    marginBottom: 20,
    fontWeight: "Bold",
  },
});
