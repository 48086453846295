import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  Image,
  Dimensions,
  Animated,
  TouchableOpacity,
  StyleSheet,
  ScrollView,
  TextInput,
  FlatList,
} from "react-native";
import {
  MaterialCommunityIcons,
  MaterialIcons,
  Feather,
  Ionicons,
  FontAwesome,
  FontAwesome5,
} from "@expo/vector-icons";
import Calendar from "react-calendar";
import RNPickerSelect from "react-native-picker-select";
import Modal from "modal-react-native-web";
import { useRecoilState } from "recoil";
import { tokenState } from "../../../../reducer/recoil";
import { useIsFocused } from "@react-navigation/native";
import { apiservice } from "../../../../service";
import moment from "moment";
import { useWindowSize } from "../../../components/responsive";
export default function Bill({ onPress }) {
  const [DATA, setData] = useState([]);
  const [ALLDATA, setAllData] = useState([]);
  const [show, setshow] = useState(false);
  const [show1, setshow1] = useState(false);
  const [search, setSearch] = useState("");
  const size = useWindowSize();

  const [data, setdata] = useState({});
  const week = [
    "อาทิตย์",
    "จันทร์",
    "อังคาร",
    "พุธ",
    "พฤหัสบดี",
    "ศุกร์",
    "เสาร์",
  ];

  const months = [
    "ม.ค.",
    "ก.พ.",
    "ม.ค.",
    "เม.ย.",
    "พ.ค.",
    "มิ.ย.",
    "ก.ค.",
    "ส.ค.",
    "ก.ย.",
    "ต.ค.",
    "พ.ย",
    "ธ.ค.",
  ];
  const [start, setstart] = useState(moment().add(-1, "months"));
  const [End, setend] = useState(moment().add(1, "days"));
  const [textstart, settextstart] = useState("ค้นหาจากเดือน ..");
  const [textend, settextend] = useState("เดือน ..");
  async function apiCall() {
    const res = await apiservice({
      path: "/chillpay/getallorder",
    });
    if (res.status == 200) {
      let abc = uniqKeepLast(res.data.data, (it) => it.teacher.id);
      let acbd = uniqKeepLast(abc, (it) =>
        moment(it.createdAt).format("YYYY-MM")
      );
      setData(abc);
      setAllData(res.data.data);
    }
  }

  function totalBefore({ params, name }) {
    let res = ALLDATA.filter((item) => {
      return (
        params == moment(item.createdAt).format("YYYY-MM") &&
        name == item.teacher.id
      );
    });

    let totals = 0;
    res.map((e) => (totals = totals + parseInt(e.netAmount)));
    return totals;
  }

  function uniqKeepLast(data, key) {
    return [...new Map(data.map((x) => [key(x), x])).values()];
  }

  useEffect(() => {
    apiCall();
  }, []);
  var dd = moment.locale("th");
  return (
    <View style={{ marginTop: 20 }}>
      <Text
        style={{
          fontFamily: "Prompt",
          fontSize: 23,
          color: "#0D88F0",
        }}
      >
        การเงินรอบบิล
      </Text>
      <TextInput
        onChangeText={(text) => {
          setSearch(text);
        }}
        autoComplete="off"
        placeholder={"ค้นหาจากชื่อผู้สอน .."}
        placeholderTextColor="#BBB"
        style={[styles.input, { marginVertical: 15 }]}
      />
      <View style={{ flexDirection: size.width < 500 ? "column" : "row" }}>
        <View
          style={{
            width:
              size.width < 500 ? "100%" : size.width < 1280 ? "45%" : "20%",
          }}
        >
          <TouchableOpacity
            onPress={() => {
              setshow((val) => !val);
            }}
            // autoComplete="off"
            // placeholder={"ค้นหาจากเดือน .."}
            // placeholderTextColor="#0D88F0"
            style={[styles.input, { justifyContent: "center" }]}
          >
            <Text
              style={{
                fontFamily: "Prompt",
                fontSize: 16,
                color: "#BBB",
              }}
            >
              {textstart}
            </Text>
          </TouchableOpacity>
          {show && (
            <Calendar
              //minDate={new Date()}
              onChange={(dd) => {
                setstart(moment(dd).add(-1, "days").format("YYYY-MM-DD"));
                settextstart(moment(dd).format("DD-MM-YYYY"));
                setshow(false);
              }}
              //value={""}
            />
          )}
        </View>
        <Text
          style={[
            styles.text16O,
            { marginHorizontal: 25, textAlign: "center" },
          ]}
        >
          -
        </Text>
        <View
          style={{
            width:
              size.width < 500 ? "100%" : size.width < 1280 ? "45%" : "20%",
          }}
        >
          <TouchableOpacity
            onPress={() => {
              setshow1((val) => !val);
            }}
            style={[styles.input, { justifyContent: "center" }]}
          >
            <Text
              style={{
                fontFamily: "Prompt",
                fontSize: 16,
                color: "#BBB",
              }}
            >
              {textend}
            </Text>
          </TouchableOpacity>
          {show1 && (
            <Calendar
              //minDate={new Date()}
              onChange={(dd) => {
                setend(moment(dd).add(-1, "days").format("YYYY-MM-DD"));
                settextend(moment(dd).format("DD-MM-YYYY"));
                setshow1(false);
              }}
              //value={""}
            />
          )}
        </View>
        {size.width >= 1280 && (
          <View style={{ position: "absolute", right: 0 }}>
            <TouchableOpacity
              onPress={() => {
                const newWindow = window.open(
                  "https://api.courseoncloud.com/api/image/xlxs"
                );
                if (newWindow) newWindow.opener = null;
              }}
              style={[styles.buttonPrint]}
            >
              <Text style={styles.printAll}>พิมพ์ทั้งหมด</Text>
            </TouchableOpacity>
          </View>
        )}
      </View>
      <ScrollView horizontal>
        <View
          style={{
            width:
              size.width < 500
                ? size.width * 2.5
                : size.width < 1280
                ? size.width * 1.0
                : size.width * 0.75,
            // minHeight: height * 2.5,
          }}
        >
          <View style={styles.viewSubject}>
            <Text
              style={[styles.text16B, { width: "10%", textAlign: "center" }]}
            >
              ลำดับ
            </Text>
            <Text style={[styles.text16B, { width: "20%" }]}>ชื่อผู้สอน</Text>
            <Text
              style={[styles.text16B, { width: "20%", textAlign: "center" }]}
            >
              ยอดเงินคงเหลือ
            </Text>
            <Text style={[styles.text16B, { width: "30%" }]}>บิลรอบเดือน</Text>
            <Text
              style={[styles.text16B, { width: "10%", textAlign: "center" }]}
            >
              รายละเอียด
            </Text>
            <Text style={[styles.text16B, { width: "10%" }]}></Text>
          </View>
          <FlatList
            numColumns={1}
            data={DATA.filter((item) => {
              return item.teacher?.user_accounts?.firstName?.includes(search);
            }).filter((item) => {
              return (
                moment(item.createdAt).valueOf() > moment(start).valueOf() &&
                moment(item.createdAt).valueOf() < moment(End).valueOf()
              );
            })}
            renderItem={({ item, index }) => {
              return (
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    borderBottomWidth: 1,
                    borderBottomColor: "#E5E5E5",
                    height: 50,
                    alignItems: "center",
                  }}
                >
                  <Text
                    style={[
                      styles.text16B,
                      { width: "10%", textAlign: "center" },
                    ]}
                  >
                    {index + 1}
                  </Text>
                  <Text style={[styles.text16B, { width: "20%" }]}>
                    {item?.teacher?.user_accounts?.firstName +
                      " " +
                      item?.teacher?.user_accounts?.lastName}
                  </Text>
                  <Text
                    style={[
                      styles.text16B,
                      { width: "20%", textAlign: "center" },
                    ]}
                  >
                    {totalBefore({
                      params: moment(item.createdAt).format("YYYY-MM"),
                      name: item?.teacher?.id,
                    })}
                  </Text>
                  <Text style={[styles.text16B, { width: "30%" }]}>
                    {moment(item.createdAt)
                      .add(543, "year")
                      .format("MMMM YYYY")}
                  </Text>
                  <TouchableOpacity
                    onPress={() =>
                      onPress({
                        item,
                        ALLDATA: ALLDATA?.filter((items) => {
                          return (
                            moment(item?.createdAt).format("YYYY-MM") ==
                              moment(items?.createdAt).format("YYYY-MM") &&
                            item?.teacher?.id == items?.teacher?.id
                          );
                        }),
                      })
                    }
                    style={{ width: "10%", alignItems: "center" }}
                  >
                    <FontAwesome5
                      name="clipboard-list"
                      size={24}
                      color="black"
                    />
                  </TouchableOpacity>

                  <TouchableOpacity
                    onPress={() => {
                      const newWindow = window.open(
                        "https://api.courseoncloud.com/api/image/xlxs?id=" +
                          item.course_owner_id +
                          "&start=" +
                          moment(start).format("YYYY-MM-DD") +
                          "&end=" +
                          moment(End).format("YYYY-MM-DD"),
                        "_blank",
                        "noopener,noreferrer"
                      );
                      if (newWindow) newWindow.opener = null;
                    }}
                    style={{ width: "10%", alignItems: "center" }}
                  >
                    <FontAwesome name="print" size={24} color="black" />
                  </TouchableOpacity>
                </View>
              );
            }}
          />
        </View>
      </ScrollView>
      {/* <Modal
        isVisible={modalVisible2}
        // onBackdropPress={() => setModalVisible((val) => !val)}
      >
        <View style={{ flex: 1, alignSelf: "center" }}>
          <View style={{ backgroundColor: "#fff" }}>
            <CalendarPicker
              weekdays={week}
              months={months}
              initialDate={start}
              nextTitle={<AntDesign name="right" size={24} />}
              previousTitle={<AntDesign name="left" size={24} />}
              maxDate={moment(new Date()).add(543, "year").format("LLLL")}
              selectedDayColor="#F8831C"
              selectedDayTextColor="#FFFFFF"
              onDateChange={(day) => {
                setstart(moment(day).add(-1, "days").format("YYYY-MM-DD"));
                //setdaytext(moment(day).format("YYYY-MM-DD"));

                setTimeout(() => {
                  // order(moment(day).add(-1, "days").format("YYYY-MM-DD"), End);
                  setModalVisible2(!modalVisible2);
                }, 1000);
              }}
            />
          </View>
        </View>
      </Modal> */}
    </View>
  );
}
const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: "100%",
    backgroundColor: "#FFFFFF",
  },
  input: {
    maxWidth: 400,
    width: "100%",
    height: 32,
    borderWidth: 1,
    borderColor: "#BBB",
    borderRadius: 4,
    paddingHorizontal: 10,
    fontFamily: "Prompt",
    fontSize: 16,
    color: "#BBB",
  },
  text16O: {
    fontFamily: "Prompt",
    fontSize: 16,
    color: "#0D88F0",
  },
  text16B: {
    fontFamily: "Prompt",
    fontSize: 16,
    color: "#000000",
  },
  viewSubject: {
    width: "100%",
    height: 51,
    backgroundColor: "#E5E5E5",
    marginTop: 35,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  printAll: {
    fontFamily: "Prompt",
    fontSize: 16,
    color: "#BBB",
  },
  buttonPrint: {
    width: 128,
    height: 40,
    borderWidth: 1,
    borderColor: "#BBB",
    borderRadius: 20,
    justifyContent: "center",
    alignItems: "center",
    marginLeft: 25,
  },
});
