import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  Image,
  Dimensions,
  TouchableOpacity,
  StyleSheet,
  ScrollView,
} from "react-native";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import Header from "../components/header";
import { bottomPart } from "./home";
import Modal from "modal-react-native-web";
import Exam from "./exam";
import { apiservice } from "../../service";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  lessonState,
  lessonVideoState,
  subjecttestState,
  testState,
  tokenState,
} from "../../reducer/recoil";
import { useWindowSize } from "../components/responsive";
import { useIsFocused } from "@react-navigation/native";
import HTMLRenderer from "react-html-renderer";
import ReactCountdownClock from "react-countdown-clock";
const { width, height } = Dimensions.get("window");
export default function test({ navigation, route }) {
  const token = useRecoilValue(tokenState);

  const [page, setPage] = useState(1);
  const [unLock, setUnLock] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalVisible1, setModalVisible1] = useState(false);
  const [modalVisible2, setModalVisible2] = useState(false);
  const [answer, setAnswer] = useState([]);
  const [Oncecourse, myOncecourse] = useState(null);
  const [defaultsStatus, setStatus] = useState(true);
  const isFocus = useIsFocused();
  const [MyQ, setMyQ] = useState(route.params.question);

  async function checkStart() {
    const response = await apiservice({
      path: "/start-course/myOncecourse?course_id=" + route.params.course_id,
      method: "get",
      token: token?.accessToken,
    });
    if (response.status == 200) {
      if (
        response.data.progress[route.params.Mainindex].listen_detail[
          route.params.index
        ].status == false &&
        route.params.conditions.randomquestion == false
      ) {
        setMyQ(route.params.question);
      } else if (
        response.data.progress[route.params.Mainindex].listen_detail[
          route.params.index
        ].status == false &&
        route.params.conditions.randomquestion
      ) {
        setMyQ(
          route.params.question
            .map((itens, index) => ({
              ...itens,
              random: Math.random(),
            }))
            .sort((a, b) => a.random - b.random)
        );
      }
      myOncecourse(response.data);
      setStatus(
        response.data.progress[route.params.Mainindex].listen_detail[
          route.params.index
        ].status
      );
      if (
        response.data.progress[route.params.Mainindex].listen_detail[
          route.params.index
        ].status
      ) {
        setModalVisible1(true);
        setPage(1);
        if (
          response.data.progress[route.params.Mainindex].listen_detail[
            route.params.index
          ].answer != undefined
        ) {
          setAnswer(
            response.data.progress[route.params.Mainindex].listen_detail[
              route.params.index
            ].answer
          );
        }
        if (
          response.data.progress[route.params.Mainindex].listen_detail[
            route.params.index
          ].MyQ != undefined
        ) {
          setMyQ(
            response.data.progress[route.params.Mainindex].listen_detail[
              route.params.index
            ].MyQ
          );
        }
        if (
          response.data.progress[route.params.Mainindex].listen_detail[
            route.params.index
          ].conditions != undefined &&
          response.data.progress[route.params.Mainindex].listen_detail[
            route.params.index
          ].conditions.randomquestion &&
          response.data.progress[route.params.Mainindex].listen_detail[
            route.params.index
          ].status == false
        ) {
          setMyQ(
            response.data.progress[route.params.Mainindex].listen_detail[
              route.params.index
            ].question
              .map((itens, index) => ({
                ...itens,
                random: Math.random(),
              }))
              .sort((a, b) => a.random - b.random)
          );
        }
      } else {
      }
    }
  }

  async function Chanfd(params, Mainindex, i) {
    if (params.type == "EXAM") {
      const res = await apiservice({
        path: "/quiz/get/" + params.education_id,
        method: "get",
        token: token?.accessToken,
      });

      navigation.navigate("TestReview", {
        ...params,
        ...res.data,
        Mainindex,
        index: i,
        number: 0,
        dataStudymodel: route.params.dataStudymodel,
      });
    } else if (params.type == "DESCRIPTION") {
      const res = await apiservice({
        path: "/course/geteducation/" + params.education_id,
        method: "get",
        token: token?.accessToken,
      });
      if (res.status == 200) {
        if (res.data.TYPE == "VDO") {
          navigation.navigate("LessonVideoReview", {
            ...params,
            ...res.data,
            number: 0,
            Mainindex,
            dataStudymodel: route.params.dataStudymodel,
            index: i,
          });
        } else {
          navigation.navigate("LessonReview", {
            ...params,
            ...res.data,
            number: 0,
            Mainindex,
            dataStudymodel: route.params.dataStudymodel,
            index: i,
          });
        }
      }
    } else if (params.type == "WORK") {
      const res = await apiservice({
        path: "/course/getwork/" + params.education_id,
        method: "get",
        token: token?.accessToken,
      });

      navigation.push("SubjectiveTestReview", {
        ...res.data,
        ...params,
        number: 0,
        Mainindex,
        index: i,
        dataStudymodel: route.params.dataStudymodel,
      });
    } else {
      const res = await apiservice({
        path: "/quiz/get/" + params.education_id,
        method: "get",
        token: token?.accessToken,
      });

      navigation.push("SubjectiveTestReview", {
        ...res.data,
        ...params,
        dataStudymodel: route.params.dataStudymodel,
        number: 0,
        Mainindex,
        index: i,
      });
    }
  }
  useEffect(() => {
    if (route.params) {
      // checkStart();
    }
  }, [testState, token]);
  useEffect(() => {
    if (isFocus) {
      // checkStart();
    }
    // if (defaultsStatus) {
    //   setPage(1);
    // }
    // if (route.params.status) {
    //   setPage(1);
    // }
  }, [isFocus, defaultsStatus]);

  async function checkStart() {
    const response = await apiservice({
      path: "/start-course/myOncecourse?course_id=" + route.params.course_id,
      method: "get",
      token: token?.accessToken,
    });
    if (response.status == 200) {
      myOncecourse(response.data);
    }
  }
  async function checkStartFinish() {
    const response = await apiservice({
      path: "/start-course/myOncecourse?course_id=" + route.params.course_id,
      method: "get",
      token: token?.accessToken,
    });

    if (response.status == 200) {
      try {
        route.params.progress.map((items) => {
          items.listen_detail.map((val) => {
            if (val.education_id == route.params.education_id) {
              setStatus(true);
            }
          });
        });
      } catch (error) { }

      myOncecourse(response.data);
    }
  }
  const embed = route.params.detail;

  const stringToHTML = function (str) {
    const domContainer = document.createElement("span");
    domContainer.innerHTML = str;
    return domContainer;
  };

  const parentEmbed = stringToHTML(embed);

  let oldIframe = parentEmbed.querySelectorAll("oembed");
  oldIframe = Array.from(oldIframe);

  for (const i in oldIframe) {
    //Get the url from oembed tag
    let url = oldIframe[i].getAttribute("url");
    //Replace 'watch?v' with 'embed/'
    url = url.replace("watch?v=", "embed/");

    //Create a iframe tag
    const newIframe = document.createElement("iframe");
    newIframe.setAttribute("width", "auto");
    newIframe.setAttribute("height", "auto");
    newIframe.setAttribute("allowFullScreen", "");
    newIframe.setAttribute("frameBorder", 0);
    if (url) {
      newIframe.setAttribute("src", url);
    }
    // replace oldIframe with newIframe
    oldIframe[i].parentNode.replaceChild(newIframe, oldIframe[i]);
  }

  const contentToRender = parentEmbed.outerHTML?.replace(
    /<img /g,
    `<img width="${width * 0.5}" height="auto" `
  );
  const size = useWindowSize();

  return (
    <View style={styles.container}>
      <ScrollView stickyHeaderIndices={[0]}>
        <Header navigation={navigation} />
        {page == 1 && !defaultsStatus && (
          <View
            style={{
              position: size.width < 1280 ? "relative" : "absolute",
              width: width < 1600 ? 250 : 300,
              height: width < 1600 ? 250 : 300,
              borderRadius: 150,
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#0D88F0",
              alignSelf: size.width < 1280 && "center",
              marginTop: size.width < 1280 ? 20 : height * 0.2,
              marginLeft: size.width < 1280 ? 0 : width * 0.03,
            }}
          >
            <Text
              style={{
                fontFamily: "Prompt",
                fontSize: 50,
                color: "#FFFFFF",
              }}
            >
              {
                answer
                  .filter(
                    (ele, ind) =>
                      ind ===
                      answer.findIndex((elem) => elem.number === ele.number)
                  )
                  .filter((items, mainIndex) => {
                    if (
                      MyQ.filter((val, indexs) => indexs == items.number)[0]
                        .type == "1"
                    ) {
                      return (
                        MyQ.filter((val, indexs) => indexs == items.number)[0]
                          .answer == items.answer
                      );
                    }
                    if (
                      MyQ.filter((val, indexs) => indexs == items.number)[0]
                        .type == "2"
                    ) {
                      return (
                        MyQ.filter(
                          (val, indexs) => indexs == items.number
                        )[0].answer.filter(
                          (e) => items.answer.filter((ev) => e == ev).length > 0
                        ).length ==
                        MyQ.filter((val, indexs) => indexs == items.number)[0]
                          .answer.length
                      );
                    }
                    if (
                      MyQ.filter((val, indexs) => indexs == items.number)[0]
                        .type == "3"
                    ) {
                      return MyQ.filter(
                        (val, indexs) => indexs == items.number
                      )[0].answer != null
                        ? MyQ.filter((val, indexs) => indexs == items.number)[0]
                          .answer == items.answer
                        : items.answer == 0
                          ? true
                          : false;
                    }
                    if (
                      MyQ.filter((val, indexs) => indexs == items.number)[0]
                        .type == "4"
                    ) {
                      return (
                        MyQ.filter(
                          (val, indexs) => indexs == items.number
                        )[0].answer.filter(
                          (e) =>
                            items.answer.filter((ev) => e.answer == ev.answer)
                              .length > 0
                        ).length ==
                        MyQ.filter((val, indexs) => indexs == items.number)[0]
                          .answer.length
                      );
                    }
                    if (
                      MyQ.filter((val, indexs) => indexs == items.number)[0]
                        .type == "5"
                    ) {
                      return (
                        MyQ.filter((val, indexs) => {
                          if (val.type == "5") {
                            return (
                              answer
                                .filter(
                                  (val, indexss) => indexs == val.number
                                )[0]
                                .answer.filter((e, i) => val.choice[i] == e.ans)
                                .length == val.choice.length
                            );
                          } else {
                            return false;
                          }
                        }).length > 0
                      );

                      // return MyQ
                      //   .filter((val, indexs) => indexs == items.number)[0]
                      //   .choice.filter((elu, idex) => {

                      //     return true;
                      //   });
                    }

                    return false;
                  }).length
              }
              /{MyQ.length}
            </Text>
          </View>
        )}
        <View style={{}}>
          <View
            style={{
              width: size.width < 1280 ? "80%" : "50%",
              alignSelf: "center",
              marginTop: 35,
              minHeight: size.height - 155,
            }}
          >
            <Text
              style={{ fontFamily: "Prompt", fontSize: 11, color: "#0B2051" }}
            >
              {/* พุทธศาสตรบัณฑิต (การสอนพระพุทธศาสนาและจิตวิทยาแนะแนว */}
            </Text>
            <Text
              style={{ fontFamily: "Prompt", fontSize: 25, color: "#0B2051" }}
            >
              {route.params.name}
            </Text>
            {route?.params?.img?.image != "" && (
              <Image
                style={{
                  height: size.width < 500 ? 250 : 500,
                  marginVertical: 20,
                }}
                source={{
                  uri:
                    "https://api.courseoncloud.com/api/image/getimage/" +
                    route?.params?.img?.image?.replace(".png", ""),
                }}
              />
            )}
            {page == 0 && (
              <View>
                <Text
                  style={{
                    fontFamily: "Prompt",
                    fontSize: size.width < 500 ? 16 : 20,
                    color: "#0B2051",
                  }}
                >
                  <HTMLRenderer
                    html={contentToRender}
                    components={
                      {
                        // h1: props => <Heading color="red" {...props} />,
                        // h2: Subheading,
                        // a: Link,
                      }
                    }
                  />
                </Text>
                <TouchableOpacity
                  disabled={defaultsStatus ? true : false}
                  onPress={() => {
                    setPage(1);
                  }}
                  style={{
                    width: size.width < 500 ? 120 : 170,
                    height: 50,
                    backgroundColor: defaultsStatus ? "#CCCCCC" : "#0D88F0",
                    borderRadius: 10,
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: 20,
                    marginBottom: 45,
                  }}
                >
                  <Text
                    style={{
                      fontFamily: "Prompt",
                      fontSize: size.width < 500 ? 14 : 18,
                      color: "#FFFFFF",
                    }}
                  >
                    เริ่มทำแบบทดสอบ
                  </Text>
                </TouchableOpacity>
              </View>
            )}
            {page == 1 && (
              <View>
                <Exam
                  data={MyQ}
                  answer={answer}
                  setAnswer={setAnswer}
                  status={false}
                  conditions={route.params.conditions}
                />
                {/* <TouchableOpacity
                disabled={defaultsStatus ? true : false}
                onPress={() => {
                  setModalVisible(true);
                }}
                style={{
                  width: size.width < 500 ? 120 : 165,
                  height: 57,
                  backgroundColor: defaultsStatus ? "#CCCCCC" : "#FF6C39",
                  borderRadius: 5,
                  justifyContent: "center",
                  alignItems: "center",
                  marginVertical: 50,
                  alignSelf: "center",
                }}
              >
                <Text
                  style={{
                    fontFamily: "Prompt",
                    fontSize: size.width < 500 ? 14 : 25,
                    color: "#FFFFFF",
                  }}
                >
                  เสร็จสิ้น
                </Text>
              </TouchableOpacity> */}
              </View>
            )}
          </View>
          <View
            style={{
              width: "100%",
              height: 70,
              backgroundColor: defaultsStatus ? "#0D88F0" : "#D9D9D9",
              justifyContent: "center",
            }}
          >
            <View
              style={[
                styles.viewProgress,
                { backgroundColor: defaultsStatus ? "#0D88F0" : "#D9D9D9" },
              ]}
            >
              {route.params.Mainindex == 0 && route.params.index == 0 ? (
                <View style={styles.viewTextProgress}>
                  <TouchableOpacity style={{ margin: 10 }}>
                    <MaterialCommunityIcons
                      name="arrow-left-drop-circle"
                      size={0}
                      color="#FFFFFF"
                    />
                  </TouchableOpacity>
                  <View>
                    <Text
                      style={[styles.textProgress, { textAlign: "left" }]}
                    ></Text>
                    <Text numberOfLines={1} style={styles.textProgress}></Text>
                  </View>
                </View>
              ) : (
                <View style={styles.viewTextProgress}>
                  <TouchableOpacity
                    onPress={async () => {
                      const Mainindex = route.params.Mainindex;
                      const i = route.params.index;
                      const newi = route.params.index - 1;

                      if (
                        route.params.dataStudymodel[Mainindex].listen_detail[
                        i - 1
                        ] != undefined
                      ) {
                        Chanfd(
                          route.params.dataStudymodel[Mainindex].listen_detail[
                          i - 1
                          ],
                          Mainindex,
                          newi
                        );
                      } else {
                        Chanfd(
                          route.params.dataStudymodel[Mainindex - 1]
                            .listen_detail[
                          route.params.dataStudymodel[Mainindex - 1]
                            .listen_detail.length - 1
                          ],
                          Mainindex - 1,
                          route.params.dataStudymodel[Mainindex - 1]
                            .listen_detail.length - 1
                        );
                      }
                    }}
                    style={{ margin: 10 }}
                  >
                    <MaterialCommunityIcons
                      name="arrow-left-drop-circle"
                      size={30}
                      color="#FFFFFF"
                    />
                  </TouchableOpacity>
                  <View>
                    <Text style={[styles.textProgress, { textAlign: "left" }]}>
                      บทก่อนหน้า
                    </Text>
                    <Text numberOfLines={1} style={styles.textProgress}>
                      {
                        route.params.dataStudymodel[route.params.Mainindex]
                          .listen_detail[route.params.index - 1]?.name
                      }
                    </Text>
                  </View>
                </View>
              )}
              <TouchableOpacity
                disabled={true}
                style={[
                  styles.buttonProgress,
                  {
                    backgroundColor: defaultsStatus ? "#E3F9D6" : "#FEF3CE",
                    width: size.width < 500 ? 100 : 150,
                  },
                ]}
              >
                <Text style={styles.textSuccess}>
                  {defaultsStatus ? "เสร็จสิ้น" : "กำลังดำเนินการ.."}
                </Text>
              </TouchableOpacity>{" "}
              {route.params?.dataStudymodel?.[route.params.Mainindex]
                ?.listen_detail?.[route.params.index + 1] != undefined
                ? route.params.dataStudymodel[route.params.Mainindex]
                  ?.listen_detail?.[route.params.index + 1].name
                : route.params.dataStudymodel[
                  route.params.Mainindex + 1
                ]?.listen_detail?.[0] != undefined ? (
                  <View
                    style={[
                      styles.viewTextProgress,
                      { justifyContent: "flex-end" },
                    ]}
                  >
                    <View
                      style={{
                        width: size.width < 500 && "70%",
                      }}
                    >
                      <Text style={[styles.textProgress]}>บทถัดไป</Text>
                      <Text numberOfLines={1} style={styles.textProgress}>
                        {route.params.dataStudymodel[route.params.Mainindex]
                          ?.listen_detail?.[route.params.index + 1] != undefined
                          ? route.params.dataStudymodel[route.params.Mainindex]
                            ?.listen_detail?.[route.params.index + 1].name
                          : route.params.dataStudymodel[
                            route.params.Mainindex + 1
                          ]?.listen_detail?.[0] != undefined
                            ? route.params.dataStudymodel[
                              route.params.Mainindex + 1
                            ]?.listen_detail?.[0].name
                            : ""}
                      </Text>
                    </View>
                    <TouchableOpacity
                      onPress={async () => {
                        const Mainindex = route.params.Mainindex;
                        const i = route.params.index;
                        const newi = route.params.index + 1;

                        if (
                          route.params.dataStudymodel[Mainindex].listen_detail[
                          i + 1
                          ] != undefined
                        ) {
                          Chanfd(
                            route.params.dataStudymodel[Mainindex].listen_detail[
                            i + 1
                            ],
                            Mainindex,
                            newi,
                            route.params.dataStudymodel[Mainindex].listen_detail[
                              i + 1
                            ].status
                          );
                        } else if (
                          route.params.dataStudymodel[Mainindex + 1]
                            .listen_detail[0] != undefined
                        ) {
                          Chanfd(
                            route.params.dataStudymodel[Mainindex + 1]
                              .listen_detail[0],
                            Mainindex + 1,
                            0,
                            route.params.dataStudymodel[Mainindex + 1]
                              .listen_detail[0].status
                          );
                        } else {
                          const respp = await apiservice({
                            path: "/start-course/getalltotals/" + data.course_id,
                            method: "get",
                            token: token?.accessToken,
                          });
                          const resppt = await apiservice({
                            path: "/start-course/getworktotals/" + data.course_id,
                            method: "get",
                            token: token?.accessToken,
                          });
                          if (respp.status == 200) {
                            setShow1(resppt.data.results);
                            setShow(respp.data.results);
                            setModalScore(true);
                          }
                        }
                      }}
                      disabled={defaultsStatus ? false : true}
                      style={{ margin: 10 }}
                    >
                      <MaterialCommunityIcons
                        name={defaultsStatus ? "arrow-right-drop-circle" : "lock"}
                        size={30}
                        color="#FFFFFF"
                      />
                    </TouchableOpacity>
                  </View>
                ) : (
                  <View style={styles.viewTextProgress}>
                    <TouchableOpacity style={{ margin: 10 }}>
                      <MaterialCommunityIcons
                        name="arrow-right-drop-circle"
                        size={0}
                        color="#FFFFFF"
                      />
                    </TouchableOpacity>
                    <View>
                      <Text
                        style={[styles.textProgress, { textAlign: "right" }]}
                      ></Text>
                      <Text numberOfLines={1} style={styles.textProgress}></Text>
                    </View>
                  </View>
                )}
            </View>
          </View>
        </View>
        <Modal
          transparent={true}
          visible={modalVisible}
          onRequestClose={() => {
            Alert.alert("Modal has been closed.");
            setModalVisible(!modalVisible);
          }}
        >
          <View style={styles.viewModal}>
            <View style={styles.viewDetailModal}>
              <Text style={styles.textTitleModal}>ยืนยันจะส่งคำตอบหรือไม่</Text>
              <View
                style={{
                  flexDirection: "row",
                  marginTop: 80,
                  justifyContent: "space-between",
                }}
              >
                <TouchableOpacity
                  onPress={async () => {
                    const response = await apiservice({
                      path: "/start-course/update/" + route.params.id,
                      method: "put",
                      body: {
                        progress: route.params.progress.map((item, i) => {
                          if (i == 0) {
                            return {
                              ...item,
                              MyQ,
                              listen_detail: item.listen_detail.map(
                                (items, ins) => {
                                  if (ins == route.params.index && ins == 0) {
                                    return {
                                      ...items,
                                      status: true,
                                      answer,
                                      MyQ,
                                    };
                                  } else if (
                                    ins !== 0 &&
                                    ins == route.params.index &&
                                    item.listen_detail[ins - 1].status == true
                                  ) {
                                    return {
                                      ...items,
                                      status: true,
                                      answer,
                                      MyQ,
                                    };
                                  } else {
                                    return items;
                                  }
                                }
                              ),
                            };
                          } else {
                            return {
                              ...item,
                              MyQ,
                              listen_detail: item.listen_detail.map(
                                (items, ins) => {
                                  if (
                                    ins == 0 &&
                                    ins == route.params.index &&
                                    route.params.progress[i - 1].listen_detail[
                                      route.params.progress[i - 1].listen_detail
                                        .length - 1
                                    ].status == true
                                  ) {
                                    return {
                                      ...items,
                                      status: true,
                                      answer,
                                      MyQ,
                                    };
                                  } else if (
                                    ins !== 0 &&
                                    ins == route.params.index &&
                                    item.listen_detail[ins - 1].status == true
                                  ) {
                                    return {
                                      ...items,
                                      status: true,
                                      answer,
                                      MyQ,
                                    };
                                  } else {
                                    return items;
                                  }
                                }
                              ),
                            };
                          }
                        }),
                      },
                      token: token?.accessToken,
                    });

                    const response1 = await apiservice({
                      path: "/start-course/createfinal",
                      method: "post",
                      body: {
                        course_id: route.params.course_id,
                        type: "TEST",
                        totals: answer.filter(
                          (items) =>
                            items.answer == items.totals || items.answer
                        ).length,
                      },
                      token: token?.accessToken,
                    });
                    setTimeout(() => {
                      checkStartFinish();
                      checkStart();
                    }, 500);

                    setModalVisible(!modalVisible);
                    setModalVisible1(true);
                  }}
                  style={styles.buttonModal}
                >
                  <Text style={styles.textButtonModal}>ตกลง</Text>
                </TouchableOpacity>
                <TouchableOpacity
                  onPress={() => {
                    setModalVisible(!modalVisible);
                  }}
                  style={styles.buttonModal1}
                >
                  <Text
                    style={{
                      fontFamily: "Prompt",
                      fontSize: 30,
                      color: "#0D88F0",
                    }}
                  >
                    ยกเลิก
                  </Text>
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </Modal>
        <Modal
          transparent={true}
          visible={modalVisible1}
          onRequestClose={() => {
            Alert.alert("Modal has been closed.");
            setModalVisible1(!modalVisible1);
          }}
        >
          <View style={styles.viewModal}>
            <View style={styles.viewDetailModal}>
              <View style={{ alignSelf: "center", alignItems: "center" }}>
                <Text
                  style={{
                    fontFamily: "Prompt",
                    fontSize: 25,
                    color: "#0B2051",
                  }}
                >
                  คุณทำได้
                </Text>
                <Text
                  style={{
                    fontFamily: "Prompt",
                    fontSize: 70,
                    color: "#0B2051",
                    marginVertical: 40,
                  }}
                >
                  {(
                    (answer
                      .filter(
                        (ele, ind) =>
                          ind ===
                          answer.findIndex((elem) => elem.number === ele.number)
                      )
                      .filter((items) => {
                        if (
                          MyQ.filter((val, indexs) => indexs == items.number)[0]
                            .type == "1"
                        ) {
                          return (
                            MyQ.filter(
                              (val, indexs) => indexs == items.number
                            )[0].answer == items.answer
                          );
                        }
                        if (
                          MyQ.filter((val, indexs) => indexs == items.number)[0]
                            .type == "2"
                        ) {
                          return (
                            MyQ.filter(
                              (val, indexs) => indexs == items.number
                            )[0].answer.filter(
                              (e) =>
                                items.answer.filter((ev) => e == ev).length > 0
                            ).length ==
                            MyQ.filter(
                              (val, indexs) => indexs == items.number
                            )[0].answer.length
                          );
                        }
                        if (
                          MyQ.filter((val, indexs) => indexs == items.number)[0]
                            .type == "3"
                        ) {
                          return MyQ.filter(
                            (val, indexs) => indexs == items.number
                          )[0].answer != null
                            ? MyQ.filter(
                              (val, indexs) => indexs == items.number
                            )[0].answer == items.answer
                            : items.answer == 0
                              ? true
                              : false;
                        }
                        if (
                          MyQ.filter((val, indexs) => indexs == items.number)[0]
                            .type == "4"
                        ) {
                          return (
                            MyQ.filter(
                              (val, indexs) => indexs == items.number
                            )[0].answer.filter(
                              (e) =>
                                items.answer.filter(
                                  (ev) => e.answer == ev.answer
                                ).length > 0
                            ).length ==
                            MyQ.filter(
                              (val, indexs) => indexs == items.number
                            )[0].answer.length
                          );
                        }
                        if (
                          MyQ.filter((val, indexs) => indexs == items.number)[0]
                            .type == "5"
                        ) {
                          return (
                            MyQ.filter(
                              (val, indexs) => indexs == items.number
                            )[0].choice.filter(
                              (e) =>
                                items.answer
                                  .sort((a, b) => b.number - a.number)
                                  .filter((ev) => e == ev.ans).length > 0
                            ).length ==
                            MyQ.filter(
                              (val, indexs) => indexs == items.number
                            )[0].answer.length
                          );
                        }

                        return false;
                      })?.length /
                      MyQ?.length) *
                    100
                  ).toFixed(0)}{" "}
                  %
                </Text>
                <TouchableOpacity
                  onPress={() => {
                    setUnLock(true);
                    setModalVisible1(!modalVisible1);
                  }}
                  style={{
                    width: 211,
                    height: 56,
                    backgroundColor: "#0D88F0",
                    borderRadius: 5,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Text style={styles.textButtonModal}>ยืนยัน</Text>
                </TouchableOpacity>
                <Text style={styles.textDetailModal}>
                  ผ่านแบบทดสอบ {route?.params?.conditions?.percent}%
                </Text>
              </View>
            </View>
          </View>
        </Modal>
        <Modal
          transparent={true}
          visible={modalVisible2}
          onRequestClose={() => {
            Alert.alert("Modal has been closed.");
            setModalVisible2(!modalVisible2);
          }}
        >
          <View style={styles.viewModal}>
            <View style={styles.viewDetailModal}>
              <View style={{ alignItems: "center" }}>
                <Image
                  style={{ width: 100, height: 100 }}
                  source={require("../img/Group 66.png")}
                />
                <Text style={[styles.textTitleModal, { marginTop: 40 }]}>
                  หมดเวลาทำแบบทดสอบ
                </Text>
                <TouchableOpacity
                  onPress={() => {
                    setModalVisible1(true);
                    setModalVisible2(false);
                  }}
                  style={[styles.buttonModal, { marginTop: 40 }]}
                >
                  <Text style={styles.textButtonModal}>ตกลง</Text>
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </Modal>
      </ScrollView>
    </View>
  );
}
const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: "100%",
    backgroundColor: "#FFFFFF",
  },
  viewProgress: {
    width: "100%",
    height: 70,
    flexDirection: "row",
    justifyContent: "space-between",
  },
  viewTextProgress: {
    flexDirection: "row",
    alignItems: "center",
    width: "30%",
  },
  textProgress: {
    fontFamily: "Prompt",
    fontSize: 12,
    color: "#FFFFFF",
    textAlign: "right",
  },
  buttonProgress: {
    width: 150,
    height: 40,
    borderRadius: 20,
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "center",
  },
  textSuccess: {
    fontFamily: "Prompt",
    fontSize: 12,
    color: "#0B2051",
  },
  textChoice: {
    fontFamily: "Prompt",
    fontSize: 20,
    color: "#0B2051",
  },
  textTitle: {
    fontFamily: "Prompt",
    fontSize: 30,
    color: "#0B2051",
  },
  viewDetail: {
    borderBottomWidth: 1,
    borderBottomColor: "#0D88F0",
    paddingVertical: 45,
    marginBottom: 49,
    alignItems: "center",
    flexDirection: "row",
  },
  flatlist: {
    borderBottomWidth: 1,
    borderBottomColor: "#0D88F0",
    paddingBottom: 45,
    marginBottom: 49,
  },
  viewModal: {
    width: "100%",
    height: "100%",
    backgroundColor: "#000000bb",
    justifyContent: "center",
  },
  viewDetailModal: {
    width: "100%",
    maxWidth: 650,
    height: "100%",
    maxHeight: 400,
    backgroundColor: "#FFFFFF",
    borderWidth: 1,
    borderColor: "#0D88F0",
    padding: 50,
    paddingHorizontal: width * 0.05,
    alignSelf: "center",
    justifyContent: "center",
  },
  buttonModal: {
    width: 150,
    height: 50,
    backgroundColor: "#0D88F0",
    borderRadius: 5,
    justifyContent: "center",
    alignItems: "center",
  },
  textButtonModal: {
    fontFamily: "Prompt",
    fontSize: 30,
    color: "#FFFFFF",
  },
  textTitleModal: {
    fontFamily: "Prompt",
    fontSize: 40,
    color: "#0B2051",
    alignSelf: "center",
  },
  buttonModal1: {
    width: 150,
    height: 50,
    borderWidth: 1,
    borderColor: "#0D88F0",
    borderRadius: 5,
    justifyContent: "center",
    alignItems: "center",
  },
  textDetailModal: {
    fontFamily: "Prompt",
    fontSize: 18,
    color: "#0B2051",
  },
});
