import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  Image,
  Dimensions,
  Animated,
  TouchableOpacity,
  StyleSheet,
  ScrollView,
  TextInput,
  FlatList,
} from "react-native";
import {
  MaterialCommunityIcons,
  FontAwesome,
  FontAwesome5,
  MaterialIcons,
  Ionicons,
} from "@expo/vector-icons";
import Header from "../components/header";
import { BottomPart, bottomPart } from "./home";
import ButtonLogin from "../components/buttonLogin";
import { authActionRegister } from "../../action/authAction";
import { useRecoilState } from "recoil";
import { registerState, tokenState } from "../../reducer/recoil";
import { useWindowSize } from "../components/responsive";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import GoogleLogin from "react-google-login";
import { apiservice } from "../../service";
import Modal from "modal-react-native-web";
const { width, height } = Dimensions.get("window");
export default function register({ navigation }) {
  const [confirmPass, setConfirmPass] = useState("");
  const [checkUser, setCheckUser] = useState(false);
  const [checkEmail, setCheckEmail] = useState(false);
  const [checkPass, setCheckPass] = useState(false);
  const [modalError, setModalError] = useState(false);
  const [modalWarning, setModalWarning] = useState(false);
  const [checkText, setCheckText] = useState(false);
  const [password, setPassword] = useState(false);
  const [status, setStatus] = useState();
  const [check1, setCheck1] = useState(false);
  const [dataRegis, setDataRegis] = useRecoilState(registerState);
  const [state, setState] = useState({
    role: "USER",
    sub_role: "MAIN",
    username: "",
    email: "",
    password: "",
  });
  async function onPress() {
    if (
      state.username.length > 0 &&
      state.email.length > 0 &&
      state.password.length > 0
    ) {
      if (state.password != confirmPass) {
        setPassword(true);
      }
      if (state.password.length >= 8) {
        if (state.password == confirmPass) {
          const register = await authActionRegister(state);

          if (register) {
            setStatus();
            setPassword(false);
            setState({
              role: "USER",
              sub_role: "MAIN",
              username: "",
              email: "",
              password: "",
            });
            setConfirmPass("");
            navigation.navigate("Login");
          }
          if (!register) {
            setModalError(true);
            setStatus(register);
          }
        }
      }
    }

    if (state.username.length == 0) {
      setCheckUser(true);
    }
    if (state.email.length == 0) {
      setCheckEmail(true);
    }
    if (state.password.length == 0) {
      setCheckPass(true);
    }
  }

  const emailValid =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  function errors({ value, type }) {
    if (type == "email") {
      return emailValid.test(value);
    }
  }
  const responseFacebook = async (response) => {
    const res = await apiservice({
      path: "/authen/loginfacebook",
      method: "POST",
      body: {
        telephoneNo: null,
        image_url: response.picture.data.url,
        firstName: response.name.split(" ")[0],
        lastName: response.name.split(" ")[1],
        email: response.email,
        password: response.id,
        gender: null,
        birthday: null,
        username: response.id,
        Type: "FACEBOOK",
      },
    });

    setToken(res.data);

    if (res.data) {
      setStatus();
      navigation.navigate("Home");
    }
  };
  const responseGoogle = async (response) => {
    const res = await apiservice({
      path: "/authen/logingoogle",
      method: "POST",
      body: {
        telephoneNo: null,
        image_url: response.profileObj.imageUrl,
        firstName: response.profileObj.givenName,
        lastName: response.profileObj.familyName,
        email: response.profileObj.email,
        password: response.profileObj.googleId,
        gender: null,
        birthday: null,
        username: response.profileObj.googleId,
        Type: "GOOGLE",
      },
    });

    setToken(res.data);

    if (res.data) {
      setStatus();
      navigation.navigate("Home");
    }
  };
  const size = useWindowSize();
  return (
    <View style={styles.container}>
      <ScrollView stickyHeaderIndices={[0]}>
        <Header registration navigation={navigation} login headertext />
        <View
          style={{
            flexDirection: size.width < 800 ? "column" : "row",
            height: size.width < 800 ? "60%" : "90%",
            width: "100%",
          }}
        >
          <View
            style={[
              styles.halfFristPage,
              {
                width: size.width < 800 ? "100%" : "50%",
                borderRightWidth: size.width < 800 ? 0 : 1,
                alignSelf: size.width < 800 && "center",
              },
            ]}
          >
            <Text
              style={[
                styles.textTitle,
                {
                  paddingTop: "10%",
                  marginBottom: 29,
                  fontSize: size.width < 800 ? 30 : 40,
                },
              ]}
            >
              สมัครสมาชิก
            </Text>
            <View style={styles.viewUsername}>
              <View style={{ marginHorizontal: 13, width: "35%" }}>
                <Text style={styles.textDetail}>ชื่อผู้ใช้</Text>
                <TextInput
                  autoComplete="off"
                  value={state.username}
                  onChangeText={(text) => {
                    if (state != "") {
                      setCheckText(false);
                      setCheckUser(false);
                      setCheckEmail(false);
                      setCheckPass(false);
                    }
                    setState({ ...state, username: text });
                  }}
                  style={styles.textInput}
                />
                {checkText && (
                  <Text style={styles.text14R}>กรุณากรอกข้อมูลให้ครบถ้วน</Text>
                )}
                {checkUser && (
                  <Text style={styles.text14R}>กรุณากรอกข้อมูลให้ครบถ้วน</Text>
                )}
              </View>
              <View style={{ marginHorizontal: 13, width: "35%" }}>
                <Text style={styles.textDetail}>อีเมล</Text>
                <TextInput
                  autoComplete="off"
                  value={state.email}
                  onChangeText={(text) => {
                    if (state != "") {
                      setCheckText(false);
                      setCheckUser(false);
                      setCheckEmail(false);
                      setCheckPass(false);
                    }
                    setState({ ...state, email: text });
                  }}
                  style={styles.textInput}
                />
                {state.email.length > 0 &&
                  !errors({ value: state.email, type: "email" }) && (
                    <Text style={styles.text14R}>รูปแบบของอีเมลไม่ถูกต้อง</Text>
                  )}
                {checkText && (
                  <Text style={styles.text14R}>กรุณากรอกข้อมูลให้ครบถ้วน</Text>
                )}
                {checkEmail && (
                  <Text style={styles.text14R}>กรุณากรอกข้อมูลให้ครบถ้วน</Text>
                )}
              </View>
            </View>
            <View style={{ width: "100%" }}>
              <View style={styles.viewPassWord}>
                <View style={{ marginHorizontal: 13, width: "35%" }}>
                  <Text style={styles.textDetail}>รหัสผ่าน</Text>
                  <TextInput
                    autoComplete="off"
                    value={state.password}
                    onChangeText={(text) => {
                      if (state != "") {
                        setCheckText(false);
                        setCheckUser(false);
                        setCheckEmail(false);
                        setCheckPass(false);
                      }
                      setState({ ...state, password: text });
                    }}
                    secureTextEntry
                    style={styles.textInput}
                  />
                  {state.password.length > 0 && state.password.length < 8 && (
                    <Text style={styles.text14R}>
                      ต้องมีรหัสผ่านอย่างน้อย 8 ตัว
                    </Text>
                  )}
                  {checkText && (
                    <Text style={styles.text14R}>
                      กรุณากรอกข้อมูลให้ครบถ้วน
                    </Text>
                  )}
                  {checkPass && (
                    <Text style={styles.text14R}>
                      กรุณากรอกข้อมูลให้ครบถ้วน
                    </Text>
                  )}
                </View>
                <View style={{ marginHorizontal: 13, width: "35%" }}>
                  <Text style={styles.textDetail}>ยืนยันรหัสผ่าน</Text>
                  <TextInput
                    autoComplete="off"
                    value={confirmPass}
                    onChangeText={(text) => {
                      if (state != "") {
                        setCheckText(false);
                        setCheckUser(false);
                        setCheckEmail(false);
                        setCheckPass(false);
                      }
                      setConfirmPass(text);
                    }}
                    secureTextEntry
                    style={styles.textInput}
                  />
                  {password && (
                    <Text style={styles.text14R}>รหัสผ่านไม่ตรงกัน</Text>
                  )}
                  {checkText && (
                    <Text style={styles.text14R}>
                      กรุณากรอกข้อมูลให้ครบถ้วน
                    </Text>
                  )}
                  {checkPass && (
                    <Text style={styles.text14R}>
                      กรุณากรอกข้อมูลให้ครบถ้วน
                    </Text>
                  )}
                </View>
              </View>
              <View
                style={{
                  flexDirection: "row",

                  marginHorizontal: 10,
                  width: "73%",

                  alignSelf: "center",
                }}
              >
                <TouchableOpacity
                  onPress={() => {
                    setCheck1((val) => !val);
                  }}
                >
                  <MaterialCommunityIcons
                    name={check1 ? "checkbox-marked" : "checkbox-blank-outline"}
                    size={30}
                    color="#BBBBBB"
                  />
                </TouchableOpacity>
                <Text
                  style={{
                    fontSize: size.width < 500 ? 14 : 20,
                    fontFamily: "Prompt",
                    color: "#646464",
                    marginLeft: 20,
                  }}
                >
                  ฉันอ่านและยอมรับ
                  <TouchableOpacity
                    onPress={() => {
                      navigation.navigate("LearnerConditions");
                    }}
                  >
                    <Text
                      style={{
                        color: "#E82626",
                        textDecoration: "underline #E82626",
                      }}
                    >
                      เงื่อนไขและข้อตกลง
                    </Text>
                  </TouchableOpacity>
                  ในการสมัครเป็นผู้เรียนกับCoC
                </Text>
              </View>
            </View>
            <TouchableOpacity
              disabled={check1 ? false : true}
              onPress={onPress}
              style={[
                styles.button,
                {
                  backgroundColor: check1 ? "#0D88F0" : "#CCCCCC",
                },
              ]}
            >
              <Text style={styles.textButton}>สมัครสมาชิก</Text>
            </TouchableOpacity>
            <TouchableOpacity
              disabled={check1 ? false : true}
              onPress={() => {
                if (
                  state.username.length > 0 &&
                  state.email.length > 0 &&
                  state.password.length >= 8
                ) {
                  if (state.password != confirmPass) {
                    setPassword(true);
                  } else {
                    setDataRegis(state);
                    setTimeout(() => {
                      navigation.navigate("Instructor");
                    }, 500);
                  }
                }
                if (state.username.length == 0) {
                  setCheckUser(true);
                }
                if (state.email.length == 0) {
                  setCheckEmail(true);
                }
                if (state.password.length == 0) {
                  setCheckPass(true);
                }
              }}
              style={[
                styles.button,
                {
                  backgroundColor: check1 ? "#0D88F0" : "#CCCCCC",
                },
              ]}
            >
              <Text style={styles.textButton}>สมัครเป็นผู้สอน</Text>
            </TouchableOpacity>
          </View>
          <View
            style={[
              styles.halfBackPage,
              {
                width: size.width < 800 ? "140%" : "50%",
                alignSelf: size.width < 800 && "center",
              },
            ]}
          >
            <Text
              style={[
                styles.textTitle,
                { paddingTop: "10%", fontSize: size.width < 800 ? 30 : 40 },
              ]}
            >
              สมัครสมาชิกผ่าน
            </Text>

            <FacebookLogin
              appId="1303789190134568"
              fields="birthday,name,email,picture"
              callback={responseFacebook}
              render={(renderProps) => (
                <ButtonLogin
                  onPress={renderProps.onClick}
                  img={require("../img/1024px-Facebook_Logo_(2019).png")}
                  name={"ดำเนินการต่อด้วย Facebook"}
                />
              )}
            />

            <GoogleLogin
              render={(renderProps) => (
                <ButtonLogin
                  disabled={renderProps.disabled}
                  onPress={renderProps.onClick}
                  img={require("../img/google_PNG19630.png")}
                  name={"ดำเนินการต่อด้วย Google"}
                />
              )}
              clientId="648121112048-2bn9ranmpnpf2qkiocd31hkd27qkbaob.apps.googleusercontent.com"
              buttonText="Login"
              onSuccess={responseGoogle}
              cookiePolicy={"single_host_origin"}
            />
          </View>
        </View>
        <Modal
          transparent={true}
          visible={modalError}
          onRequestClose={() => {
            Alert.alert("Modal has been closed.");
            setModalError(!modalError);
          }}
        >
          <View style={styles.bgModal}>
            <View
              style={{
                width: size.width < 1280 ? "80%" : "45%",
                height: 230,
                borderWidth: 1,
                borderColor: "#0D88F0",
                backgroundColor: "#FFFFFF",
                alignSelf: "center",
                alignItems: "center",
              }}
            >
              <TouchableOpacity
                onPress={() => {
                  setModalError(!modalError);
                }}
                style={{
                  position: "absolute",
                  alignSelf: "flex-end",
                  margin: 13,
                }}
              >
                <MaterialIcons name="clear" size={14} color="#0D88F0" />
              </TouchableOpacity>
              <View style={{ paddingVertical: 55, alignItems: "center" }}>
                <Text
                  style={{
                    fontSize: size.width < 1280 ? 30 : 35,
                    fontFamily: "Prompt",
                    color: "red",
                  }}
                >
                  เกิดข้อผิดพลาด
                </Text>
                <Text
                  style={{
                    fontSize: size.width < 1280 ? 30 : 35,
                    fontFamily: "Prompt",
                    color: "#0B2051",
                  }}
                >
                  มีบัญชีผู้ใช้หรืออีเมลอยู่ในระบบแล้ว
                </Text>
              </View>
            </View>
          </View>
        </Modal>
        <Modal
          transparent={true}
          visible={modalWarning}
          onRequestClose={() => {
            Alert.alert("Modal has been closed.");
            setModalWarning(!modalWarning);
          }}
        >
          <View style={styles.bgModal}>
            <View
              style={{
                width: "60%",
                height: "90%",
                borderWidth: 1,
                borderColor: "#0D88F0",
                backgroundColor: "#FFFFFF",
                alignSelf: "center",
              }}
            >
              <ScrollView>
                <TouchableOpacity
                  onPress={() => {
                    setModalWarning(false);
                  }}
                  style={{ position: "absolute", right: 0, zIndex: 99 }}
                >
                  <Ionicons name="close" size={30} color="#0D88F0" />
                </TouchableOpacity>
                <View style={{ paddingVertical: 40, paddingHorizontal: 20 }}>
                  <Text style={styles.textDetail}>
                    ข้อตกลงและเงื่อนไข ผู้เรียน
                  </Text>
                  <Text style={styles.textDetail}>1. บทนำ</Text>
                  <Text style={styles.textDetail}>
                    {"\t"}
                    เงื่อนไขและข้อตกลงในการใช้บริการนี้เป็นข้อผูกพันระหว่าง
                    บริษัท เอเชียคอนเน็คท์ คอร์ปอเรชั่น จำกัด (“บริษัท”)
                    กับผู้ที่สมัครเรียนออนไลน์ของบริษัท
                    ซึ่งทำการสมัครเรียนผ่านเว็บไซต์ www.courseoncloud.com
                    ซึ่งต่อไปในข้อตกลงนี้จะเรียกว่า “ผู้เรียน”
                    บริษัทตกลงว่าผู้เรียนสามารถได้สิทธิต่าง ๆ
                    ตามที่บริษัทจัดหาให้ ซึ่งรวมถึงการเข้าร่วมกิจกรรม
                    การถามตอบในห้องเรียน วิดีโอคอร์สเรียน เอกสารประกอบการเรียน
                    เวิร์คช็อป ใบกระกาศนียบัตรออนไลน์ ทั้งนี้
                    เมื่อผู้เรียนตกลงชำระเงิน และ/หรือล็อกอินเข้าสู่ระบบแล้ว
                    ผู้เรียนตกลงที่จะปฏิบัติตามข้อกำหนดและเงื่อนไขทั้งหมดที่ระบุในเงื่อนไขและข้อตกลงในการใช้บริการฉบับนี้
                    หรือที่จะมีการแก้ไขหรือเปลี่ยนแปลงในภายหลัง
                  </Text>
                  <Text style={styles.textDetail}>
                    {"\t"}
                    หากประสงค์จะใช้งาน หรือเชื่อมข้อมูลผู้ใช้
                    รวมถึงข้อมูลการชำระค่าบริการต่างๆ เพื่อให้ใช้งาน
                    ท่านจะต้องสร้างบัญชีผู้ใช้ด้วย Facebook Google หรือ
                    อีเมล์ด้วยระบบลงทะเบียนของ เว็บไซต์
                    www.courseoncloud.comและเลือกชื่อผู้ใช้ของตนเอง
                    สมาชิกควรให้ข้อมูลลงทะเบียนที่ถูกต้อง ครบถ้วน
                    และให้ข้อมูลที่มีความทันสมัย (สำหรับผู้ใช้ที่เข้าสู่ระบบด้วย
                    Facebook หรือ Google เราจะไม่มีข้อมูล password ของท่านในระบบ
                    (นอกจากนี้ เราจะไม่โพสต์ข้อความใดๆ
                    ลงบนโปรไฟล์ของท่านหากไม่ได้รับอนุญาต) (“บัญชี”)
                  </Text>
                  <Text style={styles.textDetail}>
                    {"\t"}ผู้ใช้สามารถลบบัญชีผู้ใช้ได้
                    โดยส่งคำร้องขอมายังอีเมลของฝ่ายสนับสนุน
                    support@courseoncloud.com เพื่อการดำเนินการต่อไป{"\n\n"}
                  </Text>
                  <Text style={styles.textDetail}>
                    2. นโยบายคุ้มครองข้อมูลส่วนบุคคล
                  </Text>
                  <Text style={styles.textDetail}>
                    {"\t"}ผู้เรียนจะถูกจัดเก็บ ใช้ประโยชน์ และกระทำการใด ๆ
                    อันเป็นไปตามนโยบายคุ้มครองข้อมูลส่วนบุคคลของบริษัท ทั้งนี้
                    ผู้เรียนสามารถอ่านและศึกษานโยบายคุ้มครองข้อมูลส่วนบุคคลได้ที่
                    https://www.courseoncloud.com/privacy-policy{"\n\n"}
                  </Text>
                  <Text style={styles.textDetail}>3. เงื่อนไขทั่วไป</Text>
                  <Text style={styles.textDetail}>
                    {"\t"}3.1 บริษัทขอสงวนสิทธิในการเปลี่ยนแปลง แก้ไข หรือยกเลิก
                    เงื่อนไขและข้อตกลงในการใช้บริการ และเปลี่ยนแปลง แก้ไข
                    หรือยกเลิกช่วงเวลาในการบังคับใช้เงื่อนไขและข้อตกลงในการใช้บริการได้ทุกเมื่อ
                    โดยไม่ต้องแจ้งให้แก่ผู้เรียนทราบล่วงหน้า
                    เว้นแต่เป็นกรณีตามที่ระบุไว้ในข้อ 3.4
                    ของเงื่อนไขและข้อตกลงในการใช้บริการฉบับนี้{"\n\t"}3.2
                    ผู้เรียนตกลงและยอมรับว่าผู้เรียนมีหน้าที่ล็อกอินเข้าสู่เว็บไซต์
                    www.courseoncloud.com
                    อย่างสม่ำเสมอเพื่อศึกษาเงื่อนไขการใช้บริการต่าง ๆ
                    ที่อาจมีการเปลี่ยนแปลง แก้ไข และ/หรือยกเลิก
                    รวมถึงช่วงเวลาที่เงื่อนไขดังกล่าวจะมีผลใช้บังคับตามข้อ 3.1
                    โดยความรับผิดชอบของผู้เรียนเองแต่เพียงฝ่ายเดียว{"\n\t"}3.3
                    เมื่อบริษัทได้ทำการเปลี่ยนแปลง แก้ไข และ/หรือยกเลิก
                    เงื่อนไขและข้อตกลงในการใช้บริการ
                    หรือช่วงเวลาที่เงื่อนไขดังกล่าวจะมีผลใช้บังคับ
                    และเงื่อนไขดังกล่าวมีผลใช้บังคับแล้ว
                    ให้ถือว่าผู้เรียนตกลงยอมรับในการปฏิบัติตามเงื่อนไขที่ได้รับการเปลี่ยนแปลง
                    แก้ไข หรือยกเลิกนั้น{"\n\t"}3.4
                    ผู้เรียนตกลงรับผิดชอบค่าใช้จ่ายในการสมัครเรียน และค่าวัสดุ
                    อุปกรณ์ในการเรียนการสอนด้วยค่าใช้จ่ายของตนเอง ทั้งนี้
                    บริษัทขอสงวนสิทธิในการเปลี่ยนแปลง แก้ไข และ/หรือ
                    ยกเลิกบริการใด ๆ
                    เมื่อผู้เรียนได้ชำระค่าเรียนและค่าใช้จ่ายอื่น ๆ (หากมี)
                    ให้แก่บริษัทแล้ว
                    โดยบริษัทจะแจ้งให้ผู้เรียนทราบล่วงหน้าเป็นลายลักษณ์อักษรผ่านทางเว็บไซต์
                    www.courseoncloud.com{"\n\n"}4.
                    ผู้เรียนตกลงจะไม่ดำเนินการดังต่อไปนี้{"\n\t"}4.1 ดาวน์โหลด
                    คัดลอก ทำซ้ำ เผยแพร่ ดัดแปลง reverse engineer ปรับเปลี่ยน
                    interface ทำลาย ขโมยข้อมูล เอกสาร สื่อการเรียนการสอน
                    หรือสิ่งอื่นใดที่เป็นทรัพย์ และ/หรือทรัพย์สินของบริษัท
                    หรือที่บริษัทจัดหามาให้แก่ผู้เรียนตามหลักสูตรของบริษัท
                    โดยบริษัทจะถือว่าการฝ่าฝืนข้อกำหนดดังกล่าวเป็นความผิดอาญาตามกฎหมาย
                    {"\n\t"}4.2 แสดงตนว่าเป็นบุคคลอื่น
                    ให้ข้อมูลส่วนตัวที่ไม่เป็นความจริง
                    หรือละเว้นการให้ข้อมูลที่สำคัญต่อการสมัครเรียน
                    รวมถึงการเรียนหลักสูตรของบริษัท{"\n\t"}4.3 ปล่อย virus worm
                    spyware computer code file program หรือสิ่งอื่นใด
                    ที่อาจจะส่งผลเสีย หรือเข้าไปควบคุมการทำงานของเว็บไซต์
                    หรือเว็บไซต์อื่นใดของบริษัท
                    และ/หรือบริษัทในกลุ่มหรือในเครือของบริษัท
                    ไม่ว่าโดยจงใจหรือประสาทเลินเล่อ{"\n\t"}4.4
                    กระทำการอื่นใดอันเป็นการขัดหรือแย้งต่อเงื่อนไขและข้อตกลงในการใช้บริการฉบับนี้
                    {"\n\n"}5. สิทธิของบริษัท{"\n\t"}5.1
                    บริษัทขอสงวนสิทธิในการเปลี่ยนแปลง แก้ไข
                    ยกเลิกหลักสูตรและการสอนได้ทุกเมื่อ
                    โดยจะมีการแจ้งให้ผู้เรียนทราบล่วงหน้าผ่านทางเว็บไซต์ ทั้งนี้
                    ผู้เรียนจะต้องทำการล็อกอินเข้าสู่ระบบอย่างสม่ำเสมอเพื่อให้มั่นใจว่าผู้เรียนได้รับรู้ถึงเงื่อนไขล่าสุด
                    ที่มีการประกาศและบังคับใช้ในแต่ละช่วงเวลาตามที่ระบุไว้ในข้อ
                    3 ของเงื่อนไขและข้อตกลงในการใช้บริการฉบับนี้{"\n\t"}5.2
                    บริษัทขอสงวนสิทธิในการคืนเงินกรณีที่ผู้เรียนต้องการยกเลิกการเรียนในหลักสูตรที่ได้ทำการสมัครและชำระเงินไว้แล้ว
                    โดยบริษัทมีดุลพินิจแต่เพียงผู้เดียวในการพิจารณาการคืนเงินเป็นรายกรณี
                    {"\n\t"}5.3
                    บริษัทขอสงวนสิทธิในการอนุญาตให้ผู้เรียนเลื่อนการเรียนการสอนในหลักสูตรที่ได้ทำการสมัครและชำระเงินไว้แล้ว
                    หากมีเหตุจำเป็นและสมควรที่ทำให้ผู้เรียนไม่สามารถมาเข้าเรียนหลักสูตรตามที่กำหนดไว้
                    และผู้เรียนต้องการโอนคอร์สต่อให้ผู้อื่น
                    ผู้เรียนตกลงจะแจ้งให้เจ้าหน้าที่ของบริษัทซึ่งเป็นผู้รับผิดชอบทราบ
                    เป็นลายลักษณ์อักษร โดยบริษัทจะพิจารณาเป็นรายกรณี{"\n"}6.
                    ทรัพย์สินทางปัญญา{"\n\t"}
                    หลักสูตรและการพัฒนาเนื้อหาทั้งหมดที่ได้รับการจัดการ จัดทำ
                    และจัดหามาให้ผู้เรียนโดยบริษัท รวมถึงแพลตฟอร์มออนไลน์
                    ข้อความ ภาพ เสียง วิดีโอ เนื้อหา ข้อมูล เครื่องหมายการค้า
                    เครื่องหมายการบริการ ชื่อ ชื่อทางการค้า สิทธิบัตร
                    ลิขสิทธิของบริษัท และ/หรือบุคคลที่บริษัทเข้าทำสัญญา
                    หรือข้อตกลงอื่นใดเพื่อให้ได้มาซึ่งหลักสูตร
                    และการพัฒนาเนื้อหาดังกล่าวนั้น
                    เป็นงานอันได้รับความคุ้มครองทางกฎหมาย
                    (“งานอันได้รับความคุ้มครอง”) ผู้เรียนจะไม่ลอกเลียน ปลอมแปลง
                    ทำซ้ำ คัดลอก ดัดแปลง เผยแพร่ต่อสาธารณชน จำหน่าย หรือ
                    กระทำการใด ๆ อันเป็นการแสวงหาประโยชน์โดยตรงหรือโดยอ้อม
                    และไม่ว่าจะมีค่าตอบแทนหรือไม่
                    โดยไม่ได้รับความยินยอมล่วงหน้าเป็นลายลักษณ์อักษรจากบริษัท
                    ทั้งนี้
                    การที่ผู้เรียนมีสิทธิเข้าถึงงานอันได้รับความคุ้มครองข้างต้น
                    มิได้ทำให้ผู้เรียนมีสิทธิในความเป็นเจ้าของในงานอันได้รับความคุ้มครอง
                    โดยผู้เรียนยอมรับว่างานอันได้รับความคุ้มครองยังคงเป็นของบริษัท
                    และ/หรือบุคคลที่บริษัทเข้าทำสัญญา
                    หรือข้อตกลงอื่นใดเพื่อให้ได้มาซึ่งหลักสูตร
                    และการพัฒนาเนื้อหาดังกล่าว แล้วแต่กรณี{"\n\n"}7. การใช้งาน
                    {"\n\t"}ผู้เรียนตกลงว่าจะใช้วัสดุ อุปกรณ์ หลักสูตร เนื้อหา
                    และสื่อการเรียนการสอนต่าง ๆ เฉพาะเพื่อการศึกษาในหลักสูตรนั้น
                    ๆ เท่านั้น
                    และผู้เรียนตกลงว่าจะไม่นำเอาส่วนใดส่วนหนึ่งของสิ่งที่กล่าวข้างต้นไปใช้
                    หรือเปิดเผยเพื่อวัตถุประสงค์อื่นใดโดยเด็ดขาด
                    เว้นแต่จะรับความยินยอมล่วงหน้าเป็นลายลักษณ์อักษรจากบริษัท
                    {"\n\n"}8. ขอบเขตความรับผิดชอบ{"\n\t"}
                    บริษัทจะไม่รับผิดชอบในความสูญหายหรือเสียหายใด ๆ
                    ซึ่งเกิดจากการที่ผู้เรียนสมัครเข้าเรียนหลักสูตรใด ๆ
                    ชำระค่าเรียนและไม่เข้าเรียนตามหลักสูตรที่บริษัทกำหนด
                    หรือการที่ผู้เรียนนำเนื้อหาส่วนใดส่วนหนึ่งหรือทั้งหมดของวัสดุ
                    อุปกรณ์ สื่อการเรียนการสอน ไปใช้
                    หรือเปิดเผยเพื่อวัตถุประสงค์อื่นที่ไม่ใช่เพื่อการศึกษาหาความรู้ในหลักสูตรนั้น
                    ๆ หรือคัดลอก ทำซ้ำ หรือเปลี่ยนแปลงหลักสูตร
                    และ/หรือเนื้อหาที่บริษัทจัดทำขึ้นไปใช้เพื่อแสวงหาผลประโยชน์อื่นใดไม่ว่าโดยตรงหรือโดยอ้อม
                    ทั้งนี้ หากการกระทำอย่างใดอย่างหนึ่งของผู้เรียนทำให้บริษัท
                    และ/หรือบริษัทในกลุ่มหรือในเครือของบริษัทถูกฟ้องร้องหรือได้รับความเสียหาย
                    ผู้เรียนตกลงจะรับผิดชอบชดใช้ความเสียหายที่เกิดขึ้นตามจริงให้แก่บริษัท
                    และ/หรือบริษัทในกลุ่มหรือในเครือของบริษัท{"\n\n"}9.
                    การยกเลิกการสมัครเรียน{"\n\t"}
                    หากผู้เรียนไม่ปฏิบัติตามเงื่อนไขและข้อตกลงในการใช้บริการข้อใดข้อหนึ่งหรือหลายข้อ
                    บริษัทมีสิทธิเพิกถอนและ/หรือระงับการเรียนในหลักสูตรที่ผู้เรียนได้สมัครและชำระค่าเรียนให้แก่บริษัทแล้วได้ทันที
                    โดยบริษัทจะแจ้งให้ทราบเป็นลายลักษณ์อักษรผ่านทางเว็บไซต์
                    www.courseoncloud.com ทั้งนี้
                    บริษัทขอสงวนสิทธิในการไม่คืนค่าเรียน
                    และ/หรือค่าใช้จ่ายอื่นใด (หากมี)
                    ที่ผู้เรียนได้ชำระให้แก่บริษัทแล้ว
                    ในกรณีที่บริษัทเห็นว่าการไม่ปฏิบัติตามเงื่อนไขและข้อตกลงในการใช้บริการข้อใดอาจทำให้บริษัทเสียหายอย่างร้ายแรง
                    บริษัทมีสิทธิแต่เพียงผู้เดียวในการระงับชื่อบัญชีผู้ใช้งานและรหัสผ่านของผู้เรียน
                    และบริษัทมีสิทธิปฏิเสธไม่ให้ผู้เรียน
                    และ/หรือบุคคลที่เกี่ยวข้องกับการไม่ปฏิบัติตามเงื่อนไขดังกล่าวสมัครเรียนในหลักสูตรใดก็ตามของบริษัทในอนาคต
                    ทั้งนี้ บริษัทจะไม่รับผิดชอบในความสูญหายหรือเสียหายใด ๆ
                    ต่อผู้เรียนในกรณีดังกล่าว
                  </Text>
                </View>
              </ScrollView>
            </View>
          </View>
        </Modal>
        <BottomPart navigation={navigation} />
      </ScrollView>
    </View>
  );
}
const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: "100%",
    backgroundColor: "#FFFFFF",
  },
  textDetail: {
    fontSize: 18,
    fontFamily: "Prompt",
    color: "#646464",
  },
  textInput: {
    width: "100%",
    maxWidth: 300,
    height: 60,
    borderWidth: 1,
    borderRadius: 5,
    borderColor: "#E0E0E0",
    fontSize: 20,
    fontFamily: "Prompt",
    color: "#646464",
    padding: 15,
  },
  button: {
    width: "100%",
    maxWidth: 300,
    height: 60,
    backgroundColor: "#0D88F0",
    borderRadius: 3,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 25,
  },
  textButton: {
    fontSize: 20,
    fontFamily: "Prompt",
    color: "#FFFFFF",
  },
  textTitle: {
    fontSize: 40,
    fontFamily: "Prompt",
    color: "#646464",
  },
  halfFristPage: {
    width: "50%",
    alignItems: "center",
    borderRightWidth: 0.5,
    borderRightColor: "#D1DDE5",
  },
  halfBackPage: {
    width: "50%",
    alignItems: "center",
  },
  viewPassWord: {
    flexDirection: "row",
    marginVertical: 40,
    width: "100%",
    justifyContent: "center",
  },
  viewUsername: {
    flexDirection: "row",
    justifyContent: "center",
    width: "100%",
  },
  text14R: {
    fontSize: 14,
    color: "red",
    fontFamily: "Prompt",
  },
  bgModal: {
    width: "100%",
    height: "100%",
    backgroundColor: "#000000bb",
    justifyContent: "center",
  },
  bgDetailModal: {
    width: "90%",
    minHeight: 1245,
    backgroundColor: "#FFFFFF",
    borderWidth: 1,
    borderColor: "#0D88F0",
    alignSelf: "center",
    padding: 25,
    marginVertical: "10%",
  },
});
