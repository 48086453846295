import React, { useEffect, useState } from "react";
import {
  View,
  Text,
  Image,
  Dimensions,
  Animated,
  TouchableOpacity,
  StyleSheet,
  ScrollView,
  TextInput,
  FlatList,
} from "react-native";
import {
  MaterialCommunityIcons,
  MaterialIcons,
  Feather,
  Ionicons,
  FontAwesome,
} from "@expo/vector-icons";
import RNPickerSelect from "react-native-picker-select";
import Modal from "modal-react-native-web";
import { useRecoilValue } from "recoil";
import { tokenState } from "../../../reducer/recoil";
import { apiservice } from "../../../service";
import moment from "moment";
import { deleteStudent } from "../../../action/authAction";
import { useWindowSize } from "../../components/responsive";

const { width, height } = Dimensions.get("window");
export default function studentAll({ onPress }) {
  const [modalDelItem, setModalDelItem] = useState(false);
  const [DATA, setData] = useState([]);
  const token = useRecoilValue(tokenState);
  const [search, setSearch] = useState("");
  const size = useWindowSize();
  const [id, setId] = useState();
  useEffect(() => {
    apiCall();
  }, [token]);

  async function apiCall() {
    const res = await apiservice({
      path: "/authen/getgeneralstudent?page=1&totalpage=100",
      method: "get",
      token: token?.accessToken,
    });

    if (res?.status == 200) {
      setData(res?.data?.result);
    }
  }

  async function Delete() {
    const Delete = await deleteStudent({
      token: token?.accessToken,
      id: id,
    });
    if (Delete) {
      setModalDelItem(!modalDelItem);
      apiCall();
    }
  }
  return (
    <View>
      <View
        style={{ flexDirection: "row", marginTop: 20, alignItems: "center" }}
      >
        <TextInput
          onChangeText={(text) => {
            setSearch(text);
          }}
          autoComplete="off"
          placeholder="ค้นหาชื่อผู้เรียน"
          placeholderTextColor="#8C8896"
          style={{
            width: size.width < 500 ? "80%" : "30%",
            height: 48,
            borderRadius: 4,
            backgroundColor: "#EFEFF3",
            fontSize: 16,
            fontFamily: "Prompt",
            color: "#8C8896",
            paddingHorizontal: 25,
          }}
        />
        <TouchableOpacity style={{ marginLeft: -40 }}>
          <MaterialCommunityIcons name="magnify" size={24} color="#0D88F0" />
        </TouchableOpacity>
      </View>
      <ScrollView horizontal>
        <View
          style={{
            width:
              size.width < 500
                ? size.width * 2.5
                : size.width < 1280
                ? size.width * 1.0
                : size.width * 0.75,
            // minHeight: height * 2.5,
          }}
        >
          <View style={styles.viewSubject}>
            <Text style={[styles.text16B, { paddingLeft: 67, width: "35%" }]}>
              ชื่อนักศึกษา
            </Text>
            <Text style={[styles.text16B, { width: "20%" }]}>email</Text>
            <Text style={[styles.text16B, { width: "20%" }]}>วันที่สร้าง</Text>
            <Text style={[styles.text16B, { width: "20%" }]}>
              จำนวนคอร์สที่เรียน
            </Text>
            <Text style={[styles.text16B, { width: "5%" }]}></Text>
          </View>

          <FlatList
            numColumns={1}
            data={DATA.filter((item) => {
              return item.user_accounts?.firstName?.includes(search);
            })}
            //user_accounts
            renderItem={({ item, index }) => {
              return (
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    borderBottomWidth: 1,
                    borderBottomColor: "#E5E5E5",
                    height: 50,
                    alignItems: "center",
                  }}
                >
                  {item?.user_accounts?.firstName != null ? (
                    <Text
                      style={[
                        styles.text16B,
                        { paddingLeft: 67, width: "35%" },
                      ]}
                    >
                      {item?.user_accounts?.firstName +
                        "  " +
                        item?.user_accounts?.lastName}
                    </Text>
                  ) : (
                    <Text
                      style={[
                        styles.text16B,
                        { paddingLeft: 67, width: "35%" },
                      ]}
                    ></Text>
                  )}
                  <Text style={[styles.text16B, { width: "20%" }]}>
                    {item?.email}
                  </Text>
                  <Text style={[styles.text16B, { width: "20%" }]}>
                    {moment(item?.user_accounts?.createdAt)
                      .locale("th")
                      .add(543, "years")
                      .format("DD MMMM พ.ศ. YYYY")}
                  </Text>
                  <Text style={[styles.text16B, { width: "20%" }]}>
                    {item?.allResult?.length}
                  </Text>

                  <TouchableOpacity
                    onPress={() => {
                      setId(item.id);
                      setModalDelItem(true);
                    }}
                    style={{ width: "5%", alignItems: "center" }}
                  >
                    <MaterialIcons name="delete" size={24} color="black" />
                  </TouchableOpacity>
                </View>
              );
            }}
          />
        </View>
      </ScrollView>
      <Modal
        transparent={true}
        visible={modalDelItem}
        onRequestClose={() => {
          Alert.alert("Modal has been closed.");
          setModalDelItem(!modalDelItem);
        }}
      >
        <View style={styles.bgModal}>
          <View
            style={[
              styles.viewDetailModal,
              {
                width:
                  size.width < 500 ? "80%" : size.width < 1280 ? "70%" : "30%",
                height: 183,
                paddingVertical: 25,
              },
            ]}
          >
            <Text
              style={[
                styles.textButtonModal,
                { color: "#444444", alignSelf: "center" },
              ]}
            >
              คุณต้องการจะลบใช่รึไม่
            </Text>
            <View
              style={{
                flexDirection: "row",
                alignSelf: "center",
                marginTop: 39,
              }}
            >
              <TouchableOpacity
                onPress={() => setModalDelItem(!modalDelItem)}
                style={styles.buttonModal}
              >
                <Text
                  style={[
                    styles.textButtonModal,
                    {
                      color: "#444444",
                    },
                  ]}
                >
                  ยกเลิก
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={Delete}
                style={[
                  styles.buttonModal,
                  {
                    backgroundColor: "#0D88F0",
                    marginLeft: width * 0.05,
                  },
                ]}
              >
                <Text
                  style={[
                    styles.textButtonModal,
                    {
                      color: "#FFFFFF",
                    },
                  ]}
                >
                  ยืนยัน
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </Modal>
    </View>
  );
}
const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: "100%",
    backgroundColor: "#FFFFFF",
  },
  text23O: { fontSize: 23, fontFamily: "Prompt", color: "#0D88F0" },
  text16B: {
    fontFamily: "Prompt",
    fontSize: 16,
    color: "#000000",
    width: "23%",
  },

  viewSubject: {
    width: "100%",
    height: 51,
    backgroundColor: "#E5E5E5",
    marginTop: 50,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  bgModal: {
    width: "100%",
    height: "100%",
    backgroundColor: "#000000bb",
    justifyContent: "center",
  },
  viewDetailModal: {
    width: "50%",
    height: 380,
    backgroundColor: "#FFFFFF",
    borderRadius: 20,
    alignSelf: "center",
    paddingHorizontal: width * 0.05,
    paddingVertical: 50,
  },
  buttonModal: {
    width: 102,
    height: 48,
    borderRadius: 4,
    backgroundColor: "#EFEFF3",
    justifyContent: "center",
    alignItems: "center",
  },
  textButtonModal: {
    fontSize: 25,
    fontFamily: "Prompt",
  },
});
