import React, { useState } from "react";
import {
  View,
  Text,
  Image,
  Dimensions,
  Animated,
  TouchableOpacity,
  StyleSheet,
  ScrollView,
  TextInput,
  FlatList,
} from "react-native";
import {
  MaterialCommunityIcons,
  MaterialIcons,
  Feather,
  Ionicons,
  FontAwesome,
} from "@expo/vector-icons";
import RNPickerSelect from "react-native-picker-select";
import Modal from "modal-react-native-web";
import { useWindowSize } from "../../components/responsive";

const { width, height } = Dimensions.get("window");
export default function teacherDetail({
  ongoBack,
  ongoMain,
  name,
  lastName,
  allResultTeacher,
}) {
  const [modalDelItem, setModalDelItem] = useState(false);
  const data = allResultTeacher.faculty[0];
  const size = useWindowSize();
  return (
    <View>
      <View style={{ flexDirection: "row", marginTop: 10 }}>
        <TouchableOpacity onPress={ongoMain}>
          <Text style={styles.text23}>คอร์สมหาวิทยาลัย</Text>
        </TouchableOpacity>
        <Text
          style={[
            styles.text23,
            {
              marginHorizontal: 18,
            },
          ]}
        >
          /
        </Text>
        <TouchableOpacity onPress={ongoBack}>
          <Text style={styles.text23}>อาจารย์ผู้สอน</Text>
        </TouchableOpacity>
        <Text
          style={[
            styles.text23,
            {
              marginHorizontal: 18,
            },
          ]}
        >
          /
        </Text>
        <Text style={[styles.text23, { color: "#0D88F0" }]}>อาจารย์</Text>
      </View>
      <Text
        style={{
          fontSize: 25,
          fontFamily: "Prompt",
          color: "#444444",
          marginVertical: 52,
        }}
      >
        {name} {lastName} {"(คณะ" + data.facuty_name_th}{" "}
        {"สาขา" + data.major_name_th + ")"}
      </Text>
      <ScrollView horizontal>
        <View
          style={{
            width:
              size.width < 500
                ? size.width * 2.5
                : size.width < 1280
                ? size.width * 1.0
                : size.width * 0.78,
            // minHeight: height * 2.5,
          }}
        >
          <View style={styles.viewSubject}>
            <Text style={[styles.text16B, { paddingLeft: 67, width: "30%" }]}>
              ชื่อวิชา
            </Text>
            <Text style={[styles.text16B, { width: "15%" }]}>
              จำนวนผู้ที่ลงเรียน
            </Text>
            <Text style={[styles.text16B, { width: "15%" }]}>
              จำนวนผู้ที่เรียนผ่าน
            </Text>
            <Text style={[styles.text16B, { width: "15%" }]}>
              จำนวนผู้ที่เรียนไม่ผ่าน
            </Text>
            <Text style={[styles.text16B, { width: "15%" }]}>
              จำนวนผู้ที่ยังเรียนอยู่
            </Text>
            <Text style={[styles.text16B, { width: "5%" }]}></Text>
          </View>
          <FlatList
            numColumns={1}
            data={allResultTeacher.allResult}
            renderItem={({ item, index }) => {
              return (
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    borderBottomWidth: 1,
                    borderBottomColor: "#E5E5E5",
                    height: 50,
                    alignItems: "center",
                  }}
                >
                  <Text
                    style={[styles.text16B, { paddingLeft: 67, width: "30%" }]}
                  >
                    {item.title}
                  </Text>
                  <Text style={[styles.text16B, { width: "15%" }]}>
                    {item.length}
                  </Text>
                  <Text style={[styles.text16B, { width: "15%" }]}>
                    {item.prove}
                  </Text>
                  <Text style={[styles.text16B, { width: "15%" }]}>
                    {item.notprove}
                  </Text>
                  <Text style={[styles.text16B, { width: "15%" }]}>
                    {item.pending}
                  </Text>
                  <TouchableOpacity
                    onPress={() => {
                      setModalDelItem(true);
                    }}
                    style={{ width: "5%", alignItems: "center" }}
                  >
                    <MaterialIcons name="delete" size={24} color="black" />
                  </TouchableOpacity>
                </View>
              );
            }}
          />
        </View>
      </ScrollView>
      <Modal
        transparent={true}
        visible={modalDelItem}
        onRequestClose={() => {
          Alert.alert("Modal has been closed.");
          setModalDelItem(!modalDelItem);
        }}
      >
        <View style={styles.bgModal}>
          <View
            style={[
              styles.viewDetailModal,
              {
                width:
                  size.width < 500 ? "80%" : size.width < 1280 ? "70%" : "30%",
                height: 200,
                paddingVertical: 25,
              },
            ]}
          >
            <Text
              style={[
                styles.textButtonModal,
                { color: "#444444", alignSelf: "center" },
              ]}
            >
              คุณต้องการจะลบใช่รึไม่
            </Text>
            <View
              style={{
                flexDirection: "row",
                alignSelf: "center",
                marginTop: 39,
              }}
            >
              <TouchableOpacity
                onPress={() => setModalDelItem(!modalDelItem)}
                style={styles.buttonModal}
              >
                <Text
                  style={[
                    styles.textButtonModal,
                    {
                      color: "#444444",
                    },
                  ]}
                >
                  ยกเลิก
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => setModalDelItem(!modalDelItem)}
                style={[
                  styles.buttonModal,
                  {
                    backgroundColor: "#0D88F0",
                    marginLeft: width * 0.05,
                  },
                ]}
              >
                <Text
                  style={[
                    styles.textButtonModal,
                    {
                      color: "#FFFFFF",
                    },
                  ]}
                >
                  ยืนยัน
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </Modal>
    </View>
  );
}
const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: "100%",
    backgroundColor: "#FFFFFF",
  },
  text23: {
    fontSize: 23,
    fontFamily: "Prompt",
    color: "#808D93",
  },
  text16B: {
    fontFamily: "Prompt",
    fontSize: 16,
    color: "#000000",
    width: "23%",
  },

  viewSubject: {
    width: "100%",
    height: 51,
    backgroundColor: "#E5E5E5",
    marginTop: 35,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  bgModal: {
    width: "100%",
    height: "100%",
    backgroundColor: "#000000bb",
    justifyContent: "center",
  },
  viewDetailModal: {
    width: "50%",
    height: 380,
    backgroundColor: "#FFFFFF",
    borderRadius: 20,
    alignSelf: "center",
    paddingHorizontal: width * 0.05,
    paddingVertical: 50,
  },
  buttonModal: {
    width: 102,
    height: 48,
    borderRadius: 4,
    backgroundColor: "#EFEFF3",
    justifyContent: "center",
    alignItems: "center",
  },
  textButtonModal: {
    fontSize: 25,
    fontFamily: "Prompt",
  },
});
