import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  Image,
  Dimensions,
  Animated,
  TouchableOpacity,
  StyleSheet,
  ScrollView,
  TextInput,
  FlatList,
} from "react-native";
import {
  MaterialCommunityIcons,
  FontAwesome,
  FontAwesome5,
  MaterialIcons,
} from "@expo/vector-icons";
import RNPickerSelect from "react-native-picker-select";
import Modal from "modal-react-native-web";
import { authActionStartCourseCreate } from "../../action/authAction";
import { useRecoilState } from "recoil";
import { courseState, tokenState } from "../../reducer/recoil";
import { apiservice } from "../../service";
import Autocomplete from "react-native-autocomplete-input";
import { useWindowSize } from "../components/responsive";
import moment from "moment";
const { width, height } = Dimensions.get("window");
export default function courseUniversityStudent({
  navigation,
  university_id,
  faculti_id,
  onPress,
}) {
  const [courseStates, setCourseStates] = useRecoilState(courseState);
  const [modalShow, setModalShow] = useState(false);
  const [token, setToken] = useRecoilState(tokenState);
  const [Texts, setText] = useState("");
  const [termmm, setTermmm] = useState("");

  const [courseDetail, setCourseDetail] = useState([]);
  const size = useWindowSize();

  useEffect(() => {
    getMyCourse(token);
  }, [university_id, faculti_id]);

  async function getMyCourse() {
    const response = await apiservice({
      path: "/start-course/mycourse?page=1&totalpage=100",
      method: "get",
      token: token?.accessToken,
    });
    if (response.status == 200) {
      setCourseDetail(response.data.results);
    }
  }

  async function getMyCoursesearch(search, season, yea) {
    const response = await apiservice({
      path:
        "/start-course/mycourse?page=1&totalpage=100&search=" +
        search +
        "&years=" +
        (yea ? yea : "") +
        "&season=" +
        (season && season != "ภาคเรียน .." ? season : ""),
      method: "get",
      token: token?.accessToken,
    });
    if (response.status == 200) {
      setCourseDetail(response.data.results);
    }
  }

  const placeholder = {
    label: "ภาคเรียน ..",
    value: null,
    color: "#D1DDE5",
  };

  const [term, setTerm] = useState([
    { label: "S", value: "0" },
    { label: "1", value: "1" },
    { label: "2", value: "2" },
  ]);

  const placeholder1 = {
    label: "",
    value: null,
    color: "#D1DDE5",
  };
  const placeholder2 = {
    label: "ปี ..",
    value: null,
    color: "#D1DDE5",
  };

  let year = new Date().getFullYear() + 543;

  const [selectYear, setSelectTear] = useState([
    { label: year - 2501, value: year - 1 },
    { label: year - 2500, value: year },
    { label: year - 2499, value: year + 1 },
  ]);
  return (
    <View
      style={{ minHeight: 1040, paddingHorizontal: size.width < 750 ? 10 : 50 }}
    >
      <Modal
        transparent={true}
        visible={modalShow}
        onRequestClose={() => {
          Alert.alert("Modal has been closed.");
          setModalShow(!modalShow);
        }}
      >
        <View style={styles.bgModal}>
          <View
            style={{
              backgroundColor: "#FFFFFF",
              width: size.width < 500 ? "80%" : "100%",
              maxWidth: 700,
              borderWidth: 1,
              borderColor: "#0D88F0",
              alignSelf: "center",
            }}
          >
            <TouchableOpacity
              onPress={() => {
                setModalShow(!modalShow);
              }}
              style={{
                alignSelf: "flex-end",
                padding: 10,
                position: "absolute",
                zIndex: 99,
              }}
            >
              <MaterialIcons name="clear" size={24} color="#0D88F0" />
            </TouchableOpacity>
            <View
              style={{
                paddingVertical: 30,
                paddingHorizontal: 45,
                justifyContent: "center",
              }}
            >
              <Text
                style={[
                  size.width < 500
                    ? stylesResponsive.textTitleModal
                    : styles.textTitleModal,
                ]}
              >
                ผลการเรียน
              </Text>
              <Text
                style={[
                  size.width < 500
                    ? stylesResponsive.textDetailModal
                    : styles.textDetailModal,
                ]}
              >
                คอร์ส : Brand Positioning การกำหนดตำแหน่ง Brand ให้โดดเด่น
              </Text>
              <Text
                style={[
                  size.width < 500
                    ? stylesResponsive.textDetailModal
                    : styles.textDetailModal,
                ]}
              >
                สถานะ : ผ่าน
              </Text>
              <Text
                style={[
                  size.width < 500
                    ? stylesResponsive.textDetailModal
                    : styles.textDetailModal,
                ]}
              >
                หมายเหตุจากผู้สอน :
              </Text>
              <View
                style={{
                  width: "100%",
                  maxWidth: 600,
                  height: 240,
                  borderWidth: 1,
                  borderColor: "#0D88F0",
                  padding: 10,
                  marginTop: 15,
                }}
              >
                <Text
                  style={[
                    size.width < 500
                      ? stylesResponsive.textDetailModal
                      : styles.textDetailModal,
                    { marginTop: 0 },
                  ]}
                ></Text>
              </View>
            </View>
          </View>
        </View>
      </Modal>

      <View>
        <View
          style={{
            flexDirection: size.width < 750 ? "column" : "row",
            marginTop: 45,
          }}
        >
          <TextInput
            onChangeText={(text) => {
              setText(text);
              getMyCoursesearch(text, "", "");
            }}
            autoComplete="off"
            placeholderTextColor="#646464"
            placeholder="ค้นหาชื่อ .."
            style={{
              width: "100%",
              maxWidth: 400,
              height: 40,
              borderWidth: 1,
              borderColor: "#D1DDE5",
              backgroundColor: "#FFFFFF",
              fontSize: size.width < 750 ? 14 : 18,
              fontFamily: "Prompt",
              color: "#646464",
              paddingHorizontal: 15,
            }}
          />
          <RNPickerSelect
            placeholder={placeholder}
            onValueChange={(val) => {
              setTermmm(val);
              getMyCoursesearch(Texts, val, "");
            }}
            items={term}
            style={{
              inputWeb: {
                width: size.width < 750 ? 235 : 250,
                height: 40,
                borderWidth: 1,
                borderColor: "#D1DDE5",
                backgroundColor: "#FFFFFF",
                color: "#646464",
                fontFamily: "Prompt",
                fontSize: size.width < 750 ? 14 : 18,
                paddingLeft: 15,
                marginLeft: size.width < 750 ? 0 : 10,
                marginTop: size.width < 750 && 10,
              },
            }}
          />
          <RNPickerSelect
            placeholder={placeholder2}
            onValueChange={(val) => {
              getMyCoursesearch(Texts, termmm, val);
            }}
            items={selectYear}
            style={{
              inputWeb: {
                width: size.width < 750 ? 235 : 250,
                height: 40,
                borderWidth: 1,
                borderColor: "#D1DDE5",
                backgroundColor: "#FFFFFF",
                color: "#646464",
                fontFamily: "Prompt",
                fontSize: size.width < 750 ? 14 : 18,
                paddingLeft: 15,
                marginLeft: size.width < 750 ? 0 : 10,
                marginTop: size.width < 750 && 10,
              },
            }}
          />
          <TouchableOpacity
            onPress={onPress}
            style={{
              width: 180,
              height: 40,
              backgroundColor: "#0D88F0",
              justifyContent: "center",
              alignItems: "center",
              marginLeft: size.width < 750 ? 0 : 30,
              marginTop: size.width < 750 && 10,
            }}
          >
            <Text
              style={[
                size.width < 750
                  ? stylesResponsive.textButton
                  : styles.textButton,
              ]}
            >
              เพิ่มคอร์สภาคเรียนล่าสุด
            </Text>
          </TouchableOpacity>
        </View>
        {courseDetail.length > 0 && (
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
              marginTop: 60,
              alignItems: "center",
            }}
          >
            <Text
              style={[
                size.width < 750
                  ? stylesResponsive.textTopic
                  : styles.textTopic,
                { width: "40%", textAlign: "left" },
              ]}
            >
              คอร์ส
            </Text>
            <Text
              style={[
                size.width < 750
                  ? stylesResponsive.textTopic
                  : styles.textTopic,
                { width: "10%" },
              ]}
            >
              ภาคเรียน
            </Text>
            <Text
              style={[
                size.width < 750
                  ? stylesResponsive.textTopic
                  : styles.textTopic,
                { width: "10%" },
              ]}
            >
              วันที่เริ่มเรียน
            </Text>
            <Text
              style={[
                size.width < 750
                  ? stylesResponsive.textTopic
                  : styles.textTopic,
                { width: "10%" },
              ]}
            >
              รายละเอียด
            </Text>
            <Text
              style={[
                size.width < 750
                  ? stylesResponsive.textTopic
                  : styles.textTopic,
                { width: "10%" },
              ]}
            >
              สถานะ
            </Text>
            <Text
              style={[
                size.width < 750
                  ? stylesResponsive.textTopic
                  : styles.textTopic,
                { width: "10%" },
              ]}
            ></Text>
          </View>
        )}
        <FlatList
          data={courseDetail}
          numColumns={1}
          style={{ marginTop: 14 }}
          scrollEnabled={size.width < 750 ? true : false}
          renderItem={({ index, item }) => {
            return (
              <TouchableOpacity
                onPress={async () => {
                  const response = await apiservice({
                    path:
                      "/course/getallmain?page=1&lan=TH&course_id=" +
                      item.course_id,
                    method: "get",
                  });
                  if (response.status == 200) {
                    setCourseStates(response.data.main[0]);
                    setTimeout(() => {
                      navigation.navigate("Course");
                    }, 900);
                  }
                }}
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  width: "100%",
                  borderTopWidth: index == 0 ? 1 : 0,
                  borderBottomWidth: 1,
                  borderTopColor: "#BBBBBB",
                  borderBottomColor: "#BBBBBB",
                  paddingVertical: 23,
                  alignItems: "center",
                }}
              >
                <Text
                  style={[
                    size.width < 750
                      ? stylesResponsive.textDetail
                      : styles.textDetail,
                    { width: "40%", textAlign: "left" },
                  ]}
                >
                  {item.courses != null && item.courses.title}
                </Text>
                <Text
                  style={[
                    size.width < 750
                      ? stylesResponsive.textDetail
                      : styles.textDetail,
                    { width: "10%" },
                  ]}
                >
                  {item.season == 0
                    ? "S" + "/" + item.years
                    : item.season + "/" + item.years}
                </Text>
                <Text
                  style={[
                    size.width < 750
                      ? stylesResponsive.textDetail
                      : styles.textDetail,
                    { width: "10%" },
                  ]}
                >
                  {item.start_date == null
                    ? "-"
                    : moment(item.start_date).format("DD/MM/YYYY")}
                </Text>
                <Text
                  style={[
                    size.width < 750
                      ? stylesResponsive.textDetail
                      : styles.textDetail,
                    { width: "10%" },
                  ]}
                >
                  {item.start_date == null && "ยังไม่เริ่มเรียน"}
                  {item.start_date != null &&
                    item.Type == "PENDING" &&
                    "กำลังเรียน"}
                  {item.start_date != null &&
                    item.Type == "APPROVE" &&
                    "เสร็จสิ้น"}
                </Text>
                <Text
                  style={[
                    size.width < 750
                      ? stylesResponsive.textDetail
                      : styles.textDetail,
                    { width: "10%" },
                  ]}
                >
                  {item.Type == "PENDING" && "รอดำเนินการ"}
                  {item.Type == "APPROVE" && "ผ่าน"}
                  {item.Type == "REJECT" && "ไม่ผ่าน"}
                </Text>
                <View style={{ width: "10%", alignItems: "center" }}>
                  {item.detail == "เสร็จสิ้น" && (
                    <TouchableOpacity
                      onPress={() => {
                        setModalShow(true);
                      }}
                      style={{
                        width: "100%",
                        maxWidth: 110,
                        height: 40,
                        backgroundColor: "#0D88F0",
                        borderRadius: 20,
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Text
                        style={[
                          size.width < 750
                            ? stylesResponsive.textButton
                            : styles.textButton,
                        ]}
                      >
                        หมายเหตุ
                      </Text>
                    </TouchableOpacity>
                  )}
                </View>
              </TouchableOpacity>
            );
          }}
        />
      </View>
    </View>
  );
}
const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: "100%",
    backgroundColor: "#FFFFFF",
  },
  textTopic: {
    color: "#0B2051",
    fontFamily: "Prompt",
    fontSize: 20,
    textAlign: "center",
  },
  text20: {
    color: "#0B2051",
    fontFamily: "Prompt",
    fontSize: 20,
  },
  textDetail: {
    color: "#0B2051",
    fontFamily: "Prompt",
    fontSize: 16,
    textAlign: "center",
  },
  textButton: {
    color: "#FFFFFF",
    fontFamily: "Prompt",
    fontSize: 16,
  },
  bgModal: {
    width: "100%",
    height: "100%",
    backgroundColor: "#000000bb",
    justifyContent: "center",
  },
  textDetailModal: {
    color: "#0B2051",
    fontFamily: "Prompt",
    fontSize: 20,
    marginTop: 10,
  },
  textTitleModal: {
    color: "#0B2051",
    fontFamily: "Prompt",
    fontSize: 25,
    alignSelf: "center",
  },
  inputSeach: {
    width: "100%",
    maxWidth: 534,
    height: 40,
    borderWidth: 1,
    borderColor: "#FBD1C2",
    paddingHorizontal: 10,
    marginLeft: 5,
  },
});
const stylesResponsive = StyleSheet.create({
  textTopic: {
    color: "#0B2051",
    fontFamily: "Prompt",
    fontSize: 12,
    textAlign: "center",
  },
  textDetail: {
    color: "#0B2051",
    fontFamily: "Prompt",
    fontSize: 10,
    textAlign: "center",
  },
  text20: {
    color: "#0B2051",
    fontFamily: "Prompt",
    fontSize: 12,
  },
  textButton: {
    color: "#FFFFFF",
    fontFamily: "Prompt",
    fontSize: 12,
  },
  textDetailModal: {
    color: "#0B2051",
    fontFamily: "Prompt",
    fontSize: 12,
    marginTop: 10,
  },
  textTitleModal: {
    color: "#0B2051",
    fontFamily: "Prompt",
    fontSize: 16,
    alignSelf: "center",
  },
});
