import React, { useEffect, useState } from "react";
import {
  View,
  Text,
  Image,
  Dimensions,
  Animated,
  TouchableOpacity,
  StyleSheet,
  ScrollView,
  TextInput,
  FlatList,
} from "react-native";
import {
  MaterialCommunityIcons,
  FontAwesome,
  FontAwesome5,
  MaterialIcons,
} from "@expo/vector-icons";
import RNPickerSelect from "react-native-picker-select";
import { useWindowSize } from "../components/responsive";
import { apiservice } from "../../service";
import { useRecoilValue } from "recoil";
import { tokenState } from "../../reducer/recoil";
import moment from "moment";
const { width, height } = Dimensions.get("window");

export default function notification({ navigation }) {
  const [DATA, setDATA] = useState([]);
  const token = useRecoilValue(tokenState);
  const size = useWindowSize();

  useEffect(() => {
    callApi();
  }, []);

  async function callApi() {
    const res = await apiservice({
      path: "/course/allnotification",
      method: "get",
      token: token?.accessToken,
    });
    if (res.status == 200) {
      setDATA(res.data);
    }
  }

  return (
    <View style={{ minHeight: 1040, paddingHorizontal: 50 }}>
      <Text
        style={{
          fontSize: size.width < 750 ? 20 : 22,
          fontFamily: "Prompt",
          color: "#0B2051",
          marginTop: 45,
        }}
      >
        {DATA.length > 0 ? "รายการการแจ้งเตือน" : "ไม่มีรายการแจ้งเตือน"}
      </Text>
      <FlatList
        numColumns={1}
        data={DATA.sort((a, b) => {
          if (b.id > a.id) {
            return 1;
          }
          if (b.id < a.id) {
            return -1;
          }
          return 0;
        })}
        style={{}}
        renderItem={({ item, index }) => {
          return (
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                borderTopWidth: index == 0 && 1,
                borderTopColor: "#BBBBBB",
                borderBottomWidth: 1,
                borderBottomColor: "#BBBBBB",
                paddingVertical: 20,
                marginTop: 20,
              }}
            >
              <Text
                style={[
                  styles.textDetail,
                  { fontSize: size.width < 750 ? 12 : 16 },
                ]}
              >
                {item.title}
              </Text>
              <Text
                style={[
                  styles.textDetail,
                  { fontSize: size.width < 750 ? 12 : 16 },
                ]}
              >
                {moment(item.createdAt).format("DD/MM/YYYY")}
              </Text>
            </View>
          );
        }}
      />
    </View>
  );
}
const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: "100%",
    backgroundColor: "#FFFFFF",
  },
  textDetail: {
    fontSize: 20,
    fontFamily: "Prompt",
    color: "#0B2051",
  },
});
