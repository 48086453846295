import React, { useState, useEffect, useCallback } from "react";
import {
  View,
  Text,
  Image,
  Dimensions,
  Animated,
  TouchableOpacity,
  StyleSheet,
  ScrollView,
  TextInput,
  FlatList,
  Alert,
  Linking,
  Clipboard,
} from "react-native";
import {
  MaterialCommunityIcons,
  FontAwesome,
  FontAwesome5,
  MaterialIcons,
} from "@expo/vector-icons";
import Header from "../components/header";
import { BottomPart, bottomPart } from "./home";
import TeacherCourse from "./teacherCourse";
import Work from "./work";
import GradeBook from "./gradeBook";
import Finance from "./finance";
import Notification from "./notification";
import WorkReceived from "./workReceived";
import WorkReceived1 from "./workReceived1";
import LearnerInformation from "./learnerInformation";
import Chat from "./chat";
import CourseProfile from "./courseProfile";
import Announcement from "./announcement";
import EditProfile from "./editProfile";
import { useRecoilState } from "recoil";
import {
  idGradebook,
  pageTeacherState,
  tokenState,
  workState,
} from "../../reducer/recoil";
import { apiservice } from "../../service";

import * as DocumentPicker from "expo-document-picker";
import { authActionPutUser } from "../../action/authAction";
import { useWindowSize } from "../components/responsive";
import moment from "moment";
import { useIsFocused } from "@react-navigation/native";
const { width, height } = Dimensions.get("window");
let selectData = [
  "คอร์สของฉัน",
  "งานและข้อสอบอัตนัย",
  "Gradebook",
  "การเงิน",
  "การแจ้งเตือน",
];
let selectDataSchool = [
  "คอร์สของฉัน",
  "งานและข้อสอบอัตนัย",
  "Gradebook",
  "แชท",
  "การแจ้งเตือน",
];
export default function profile({ navigation, route }) {
  const [notilength, setnotilength] = useState(0);
  const [other, setOther] = useState(false);
  const [editProfile, setEditProfile] = useState(false);
  const [data, setData] = useState({});

  const [image, setImage] = useState();
  const [token, setToken] = useRecoilState(tokenState);
  const [state, setstate] = useState({ image_url: "" });
  const [work, setWork] = useRecoilState(workState);
  const [dataGrade, setDataGrade] = useRecoilState(idGradebook);
  const [university_id, setUniversity_id] = useState();
  const [courseId, setCourseId] = useState([]);
  const [id_user, setId_user] = useState();
  const [university_name, setUniversity_name] = useState();
  const focus = useIsFocused();

  useEffect(() => {
    name();
  }, [token]);

  async function name() {
    const res = await apiservice({
      path: "/course/allnotificationlength",
      method: "get",
      token: token?.accessToken,
    });

    if (res.status == 200) {
      setnotilength(res.data);
    }
  }

  async function clearname() {
    const res = await apiservice({
      path: "/course/allnotificationupdate",
      method: "get",
      token: token?.accessToken,
    });

    if (res.status == 200) {
      name();
    }
  }

  useEffect(() => {
    if (token) {
      getProfile(token);
      getCourseId(token);
    }
  }, [token, focus]);

  async function getProfile(token) {
    try {
      const response = await apiservice({
        path: "/authen/user",
        method: "get",
        token: token?.accessToken,
      });
      if (response.status == 200) {
        setId_user(response.data.result.id);
        setImage(response.data.result.user_accounts.image_url);
        setData(response.data.result.user_accounts);
        if (response.data.result.user_accounts.university_id != null) {
          setUniversity_id(response.data.result.user_accounts.university_id);
          setUniversity_name(
            response.data.result.university.university_name_th
          );
        }
      }
    } catch (error) {
      throw error;
    }
  }

  async function getCourseId(token) {
    try {
      const response = await apiservice({
        path: "/course/get/1",
        method: "get",
        token: token?.accessToken,
      });

      if (response.status == 200) {
        let pagination = [];
        for (let index = 0; index < response.data.total_page; index++) {
          pagination = pagination.concat({});
        }

        setCourseId(response.data.result);
      }
    } catch (error) {
      throw error;
    }
  }

  async function pickup() {
    let result = await DocumentPicker.getDocumentAsync({ type: "image/*" });
    // setImage(result.uri)
    const response = await apiservice({
      path: "/image/createpdf",
      method: "post",
      body: {
        name:
          moment().format("DDMMYYYYHHmmss") + result.name.replace(".png", ""),
        base64: result.uri,
      },
    });
    const edit = await authActionPutUser({
      state: {
        image_url: response.data.imageRefId.replace(".png", ""),
      },
      token: token?.accessToken,
    });
    getProfile(token);
    setImage(response.data.imageRefId.replace(".png", ""));
    window.location.reload(false);
  }

  const size = useWindowSize();
  const [page, setPage] = useRecoilState(pageTeacherState);

  return (
    <View style={styles.container}>
      <ScrollView stickyHeaderIndices={[0]}>
        <Header navigation={navigation} />
        <View
          style={{
            flexDirection: size.width < 1280 ? "column" : "row",
            width: "90%",
            alignSelf: "center",
            justifyContent: "space-between",
          }}
        >
          <View
            style={{
              width: size.width < 1280 ? "100%" : "20%",
              maxWidth: 400,
              minWidth: 250,

              // height: size.width >= 1280 && "100%",
              backgroundColor: "#D1DDE5",
              borderTopLeftRadius: 100,
              borderTopRightRadius: 100,
              padding: 25,
              marginTop: 40,
              marginLeft: size.width >= 1280 && -20,
              alignSelf: size.width < 1280 && "center",
            }}
          >
            <View>
              <Image
                style={{
                  width: 200,
                  height: 200,
                  borderRadius: 100,
                  alignSelf: "center",
                  backgroundColor: "#CCCCCC",
                }}
                source={{
                  uri:
                    "https://api.courseoncloud.com/api/image/getimage/" + image,
                }}
              />
              <TouchableOpacity
                onPress={pickup}
                style={{
                  alignSelf: "center",
                  marginTop: -25,
                  marginLeft: size.width < 750 ? width * 0.2 : width * 0.05,
                }}
              >
                <FontAwesome name="camera" size={30} color="#FFFFFF" />
              </TouchableOpacity>
            </View>
            <Text
              numberOfLines={1}
              style={{
                fontSize: width < 1600 ? 22 : 25,
                fontFamily: "Prompt",
                color: "#646464",
                alignSelf: "center",
                marginTop: 45,
              }}
            >
              {data.firstName + " " + data.lastName}
            </Text>
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                alignSelf: "center",
              }}
            >
              {data.facebook_url != "" && (
                <TouchableOpacity
                  disabled={data.facebook_url != "" ? false : true}
                  onPress={() => {
                    window.open(data.facebook_url);
                  }}
                >
                  <Image
                    style={styles.imgSocial}
                    source={require("../img/1024px-Facebook_Logo_(2019).png")}
                  />
                </TouchableOpacity>
              )}
              {data.instagram_url != "" && (
                <TouchableOpacity
                  disabled={data.instagram_url != "" ? false : true}
                  onPress={() => {
                    window.open(data.instagram_url);
                  }}
                >
                  <Image
                    style={styles.imgSocial}
                    source={require("../img/instagram-logo-png-transparent-background-800x799.png")}
                  />
                </TouchableOpacity>
              )}
              {data.twitter_url != "" && (
                <TouchableOpacity
                  disabled={data.twitter_url != "" ? false : true}
                  onPress={() => {
                    window.open(data.twitter_url);
                  }}
                >
                  <Image
                    style={styles.imgSocial}
                    source={require("../img/Twitter.png")}
                  />
                </TouchableOpacity>
              )}
              <TouchableOpacity
                onPress={() => {
                  window.open("mailto:" + data.email);
                }}
              >
                <Image
                  style={styles.imgSocial}
                  source={require("../img/email_PNG14.png")}
                />
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => {
                  setPage(8);
                }}
              >
                <Image
                  style={[styles.imgSocial, { borderRadius: 0 }]}
                  source={require("../img/ic_sms_24px.png")}
                />
              </TouchableOpacity>
            </View>
            <TouchableOpacity
              onPress={() => {
                Clipboard.setString(
                  "https://courseoncloud.com/main/GetProfile?id=" + data.uid
                );

                alert("คัดลอกลิงก์แล้ว!");
              }}
              style={{
                width: 100,
                height: 30,
                borderWidth: 1,
                borderColor: "#0D88F0",
                borderRadius: 3,
                justifyContent: "center",
                alignItems: "center",

                alignSelf: "center",
                marginTop: 20,
              }}
            >
              <Text
                style={{ fontSize: 12, fontFamily: "Prompt", color: "#0D88F0" }}
              >
                แชร์โปรไฟล์
              </Text>
            </TouchableOpacity>
            <View>
              <Text style={styles.text}>ตำแหน่ง : {data.position}</Text>
              {university_name != null && (
                <Text style={styles.text}>มหาวิทยาลัย : {university_name}</Text>
              )}
              <Text style={styles.text}>เกี่ยวกับฉัน :</Text>
              <Text style={styles.text}>{data.about_us}</Text>
              <TouchableOpacity
                onPress={() => {
                  setEditProfile(true);
                }}
                style={{
                  width: "100%",
                  maxWidth: 150,
                  height: 50,
                  backgroundColor: "#0D88F0",
                  borderRadius: 17,
                  justifyContent: "center",
                  alignSelf: "center",
                  alignItems: "center",
                  marginTop: 36,
                }}
              >
                <Text style={styles.textWhite}>แก้ไขโปรไฟล์</Text>
              </TouchableOpacity>
            </View>
          </View>
          <View
            style={{
              width: size.width < 1280 ? "100%" : "75%",
              borderWidth: 1,
              borderColor: "#DDD",
              marginTop: 58,
              marginBottom: 14,
              marginLeft: size.width < 1280 ? 0 : width * 0.05,
            }}
          >
            {editProfile ? (
              <EditProfile id_user={id_user} />
            ) : (
              <View style={{ flexDirection: "row" }}>
                <FlatList
                  horizontal
                  data={token?.role == "SCHOOL" ? selectDataSchool : selectData}
                  showsHorizontalScrollIndicator={false}
                  style={{}}
                  renderItem={({ item, index }) => {
                    return (
                      <TouchableOpacity
                        style={{
                          backgroundColor:
                            page == index ? "#0D88F0" : "#FFFFFF",
                          width: "100%",
                          maxWidth: 262,
                          height: 60,
                          justifyContent: "center",
                          borderLeftWidth: 0,
                          borderRightWidth: 0,
                          borderWidth: 1,
                          borderColor: "#D9D9D9",
                        }}
                        onPress={() => {
                          if (index == 4) {
                            clearname();
                          }
                          setPage(index);
                          setOther(false);
                        }}
                      >
                        <Text
                          style={{
                            fontFamily: "Prompt",
                            fontSize: size.width < 1280 ? 14 : 20,
                            textAlign: "center",
                            width:
                              size.width < 1280
                                ? size.width / 5.6
                                : size.width / 7.4,

                            color: page == index ? "#FFFFFF" : "#0D88F0",
                          }}
                        >
                          {item}
                          {index == 4 && notilength > 0 && " " + notilength}
                        </Text>
                      </TouchableOpacity>
                    );
                  }}
                />
                {token?.role != "SCHOOL" && (
                  <TouchableOpacity
                    onPress={() => {
                      setOther((val) => !val);
                    }}
                    style={{
                      width: 10,
                      height: 60,
                      backgroundColor: "#0D88F0",
                      borderLeftWidth: 0.1,
                      borderLeftColor: "#FFFFFF",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <MaterialCommunityIcons
                      name="dots-vertical"
                      size={24}
                      color="#FFFFFF"
                    />
                  </TouchableOpacity>
                )}
              </View>
            )}
            {other && (
              <View style={styles.viewOuther}>
                <TouchableOpacity
                  onPress={() => {
                    setPage(8);
                    setOther(false);
                  }}
                  style={styles.buttonOther}
                >
                  <Text style={styles.textWhite}>แชท</Text>
                </TouchableOpacity>
                <TouchableOpacity
                  onPress={() => {
                    setPage(9);
                    setOther(false);
                  }}
                  style={styles.buttonOther}
                >
                  <Text style={styles.textWhite}>คอร์สที่เรียนทั้งหมด</Text>
                </TouchableOpacity>
                <TouchableOpacity
                  onPress={() => {
                    setPage(10);
                    setOther(false);
                  }}
                  style={styles.buttonOther}
                >
                  <Text style={styles.textWhite}>ใบประกาศทั้งหมด</Text>
                </TouchableOpacity>
              </View>
            )}

            {page == 0 && !editProfile && (
              <TeacherCourse navigation={navigation} />
            )}
            {page == 1 && !editProfile && (
              <Work
                setWork={setWork}
                onPress={() => {
                  setPage(5);
                }}
                onPress1={() => {
                  setPage(6);
                }}
              />
            )}
            {page == 2 && !editProfile && (
              <GradeBook
                setWork={setDataGrade}
                onPress={() => {
                  setPage(7);
                }}
              />
            )}
            {token?.role == "SCHOOL" ? (
              <View>
                {page == 3 && !editProfile && <Chat navigation={navigation} />}
              </View>
            ) : (
              <View>
                {page == 3 && !editProfile && <Finance data={data} />}
              </View>
            )}

            {page == 4 && !editProfile && <Notification />}
            {page == 5 && !editProfile && (
              <WorkReceived
                Work={work}
                onPress={() => {
                  setPage(1);
                }}
              />
            )}
            {page == 6 && !editProfile && (
              <WorkReceived1
                Work={work}
                onPress={() => {
                  setPage(1);
                }}
              />
            )}
            {page == 7 && !editProfile && <LearnerInformation Work={work} />}
            {page == 8 && !editProfile && <Chat navigation={navigation} />}
            {page == 9 && !editProfile && (
              <CourseProfile navigation={navigation} />
            )}
            {page == 10 && !editProfile && <Announcement />}
          </View>
        </View>
        <BottomPart navigation={navigation} />
      </ScrollView>
    </View>
  );
}
const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#FFFFFF",
  },
  textTitle: {
    fontSize: 18,
    fontFamily: "Prompt",
    color: "#0B2051",
  },
  text: {
    fontSize: 20,
    fontFamily: "Prompt",
    color: "#646464",
    marginTop: 19,
  },
  textDetail: {
    fontSize: 16,
    fontFamily: "Prompt",
    color: "#0B2051",
  },
  viewDetail: {
    width: 278,
    height: 385,
  },
  imgDetail: {
    width: 278,
    height: 195,
  },
  feedback: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  imgSocial: {
    width: 30,
    height: 30,
    borderRadius: 15,
    marginHorizontal: 10,
    marginTop: 10,
  },
  textWhite: {
    fontSize: 20,
    fontFamily: "Prompt",
    color: "#FFFFFF",
  },
  viewOuther: {
    width: "100%",
    maxWidth: 262,
    height: 180,
    position: "absolute",
    backgroundColor: "#0D88F0",
    alignSelf: "flex-end",
    marginTop: 60,
    zIndex: 99,
    borderTopWidth: 1,
    borderTopColor: "#FFFFFF",
  },
  buttonOther: {
    alignItems: "center",
    justifyContent: "center",
    height: 60,
  },
});
