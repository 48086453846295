import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  Image,
  Dimensions,
  Animated,
  TouchableOpacity,
  StyleSheet,
  ScrollView,
  TextInput,
  FlatList,
} from "react-native";
import {
  MaterialCommunityIcons,
  MaterialIcons,
  Feather,
  Ionicons,
  FontAwesome,
} from "@expo/vector-icons";
import RNPickerSelect from "react-native-picker-select";
import Modal from "modal-react-native-web";
import { useRecoilValue } from "recoil";
import { tokenState } from "../../../reducer/recoil";
import { apiservice } from "../../../service";
import { useWindowSize } from "../../components/responsive";
const { width, height } = Dimensions.get("window");
export default function subject({
  ongoBack,
  ongoMain,
  courseName1,
  courseName2,
}) {
  console.log("courseName1 ", courseName1.title);
  const token = useRecoilValue(tokenState);
  const size = useWindowSize();
  const DATA = [
    // {
    //   study: "100",
    //   pass: "60",
    //   unPass: "20",
    //   studing: "10",
    // },
  ];

  return (
    <View>
      <View style={{ flexDirection: "row", marginTop: 10 }}>
        <TouchableOpacity onPress={ongoMain}>
          <Text style={styles.text23}>คอร์สมหาวิทยาลัย</Text>
        </TouchableOpacity>
        <Text
          style={[
            styles.text23,
            {
              marginHorizontal: 18,
            },
          ]}
        >
          /
        </Text>
        <TouchableOpacity onPress={ongoBack}>
          <Text style={styles.text23}>คอร์สที่สอน</Text>
        </TouchableOpacity>
        <Text
          style={[
            styles.text23,
            {
              marginHorizontal: 18,
            },
          ]}
        >
          /
        </Text>
        <Text style={[styles.text23, { color: "#0D88F0" }]}>วิชา</Text>
      </View>
      <Text style={[styles.text25, { marginVertical: 52 }]}>{courseName1}</Text>
      <ScrollView horizontal>
        <View
          style={{
            width:
              size.width < 500
                ? size.width * 2.5
                : size.width < 1280
                ? size.width * 1.0
                : size.width * 0.78,
            // minHeight: height * 2.5,
          }}
        >
          <View style={styles.viewSubject}>
            <Text style={[styles.text16B, { paddingLeft: 67 }]}>
              จำนวนผู้ที่ลงเรียน
            </Text>
            <Text style={[styles.text16B]}>จำนวนผู้ที่เรียนผ่าน</Text>
            <Text style={[styles.text16B]}>จำนวนผู้ที่เรียนไม่ผ่าน</Text>
            <Text style={[styles.text16B]}>จำนวนผู้ที่ยังเรียนอยู่</Text>
          </View>

          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              borderBottomWidth: 1,
              borderBottomColor: "#E5E5E5",
              height: 50,
              alignItems: "center",
            }}
          >
            <Text style={[styles.text16B, { paddingLeft: 67 }]}>
              {courseName2.length}
            </Text>
            <Text style={[styles.text16B]}>{courseName2.prove}</Text>
            <Text style={[styles.text16B]}>{courseName2.notprove}</Text>
            <Text style={[styles.text16B]}>{courseName2.pending}</Text>
          </View>
        </View>
      </ScrollView>
    </View>
  );
}
const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: "100%",
    backgroundColor: "#FFFFFF",
  },
  text23: {
    fontSize: 23,
    fontFamily: "Prompt",
    color: "#808D93",
  },
  text25: {
    fontSize: 25,
    fontFamily: "Prompt",
    color: "#444444",
  },
  text16B: {
    fontFamily: "Prompt",
    fontSize: 16,
    color: "#000000",
    width: "25%",
  },
  viewSubject: {
    width: "100%",
    height: 51,
    backgroundColor: "#E5E5E5",
    marginTop: 35,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
});
