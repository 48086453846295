import { View, Text, TouchableOpacity, Dimensions } from "react-native";
import React from "react";
const { width } = Dimensions.get("window");

export default function bottom({
  totals,
  page,
  onPrev,
  onNext,
  onOne,
  onTwo,
  onThree,
  onFirst,
  onFinal,
}) {
  const total = parseInt(totals);

  return (
    <View
      style={{
        paddingVertical: 10,
        backgroundColor: "#fff",
        borderBottomRightRadius: 15,
        borderBottomLeftRadius: 15,
        flexDirection: "row",
        justifyContent: "flex-end",
        paddingHorizontal: 15,
        width: "100%",
        right: 55,
        marginBottom: 10,
      }}
    >
      <View style={{ flexDirection: "row" }}>
        <TouchableOpacity
          disabled={page == 0}
          onPress={onPrev}
          style={{
            backgroundColor: page == 0 ? "#ccc" : "#333E50",
            height: 30,
            minWidth: 30,
            alignItems: "center",
            justifyContent: "center",
            borderRadius: 30,
            marginRight: 10,
          }}
        >
          <Text
            style={{
              fontSize: width > 1020 ? 16 : 14,
              fontFamily: "Kanit-Regular",
              color: "#fff",
            }}
          >
            {"<"}
          </Text>
        </TouchableOpacity>
        {page != 0 && (
          <View style={{ flexDirection: "row" }}>
            <TouchableOpacity
              onPress={onFirst}
              style={{
                backgroundColor: "#fff",
                height: 30,
                minWidth: 30,
                alignItems: "center",
                justifyContent: "center",
                borderRadius: 30,
              }}
            >
              <Text
                style={{
                  fontSize: width > 1020 ? 16 : 14,
                  fontFamily: "Kanit-Regular",
                  color: "#333E50",
                }}
              >
                {1}
              </Text>
            </TouchableOpacity>
            <Text
              style={{
                fontSize: width > 1020 ? 16 : 14,
                fontFamily: "Kanit-Regular",
                color: "#333E50",
              }}
            >
              {" ...   "}
            </Text>
          </View>
        )}
        {page != 0 && page != 1 && (
          <TouchableOpacity
            onPress={onPrev}
            style={{
              backgroundColor: "#fff",
              height: 30,
              minWidth: 30,
              alignItems: "center",
              justifyContent: "center",
              borderRadius: 30,
              marginHorizontal: 2,
              paddingHorizontal: 2,
            }}
          >
            <Text
              style={{
                fontSize: width > 1020 ? 16 : 14,
                fontFamily: "Kanit-Regular",
                color: "#333E50",
              }}
            >
              {page}
            </Text>
          </TouchableOpacity>
        )}

        <TouchableOpacity
          onPress={onOne}
          style={{
            backgroundColor: "#333E50",
            height: 30,
            minWidth: 30,
            alignItems: "center",
            justifyContent: "center",
            borderRadius: 30,
            marginHorizontal: 2,
            paddingHorizontal: 2,
          }}
        >
          <Text
            style={{
              fontSize: width > 1020 ? 16 : 14,
              fontFamily: "Kanit-Regular",
              color: "#fff",
            }}
          >
            {page + 1}
          </Text>
        </TouchableOpacity>
        {page + 2 <= total && (
          <TouchableOpacity
            onPress={onTwo}
            style={{
              backgroundColor: "#fff",
              height: 30,
              minWidth: 30,
              alignItems: "center",
              justifyContent: "center",
              borderRadius: 30,
              marginHorizontal: 2,
              paddingHorizontal: 2,
            }}
          >
            <Text
              style={{
                fontSize: width > 1020 ? 16 : 14,
                fontFamily: "Kanit-Regular",
                color: "#333E50",
              }}
            >
              {page + 2}
            </Text>
          </TouchableOpacity>
        )}
        {page != 0 && page + 3 <= total && (
          <TouchableOpacity
            onPress={onThree}
            style={{
              backgroundColor: "#fff",
              height: 30,
              minWidth: 30,
              alignItems: "center",
              justifyContent: "center",
              borderRadius: 30,
              marginHorizontal: 2,
              paddingHorizontal: 2,
            }}
          >
            <Text
              style={{
                fontSize: width > 1020 ? 16 : 14,
                fontFamily: "Kanit-Regular",
                color: "#333E50",
              }}
            >
              {page + 3}
            </Text>
          </TouchableOpacity>
        )}
        {parseInt(total) != 3 &&
          page != parseInt(total) &&
          parseInt(total) != 0 && (
            <View style={{ flexDirection: "row" }}>
              <Text
                style={{
                  fontSize: width > 1020 ? 16 : 14,
                  fontFamily: "Kanit-Regular",
                  color: "#333E50",
                }}
              >
                {" ...     "}
              </Text>
              <TouchableOpacity
                onPress={onFinal}
                style={{
                  backgroundColor: "#fff",
                  height: 30,
                  minWidth: 30,
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: 30,
                }}
              >
                <Text
                  style={{
                    fontSize: width > 1020 ? 16 : 14,
                    fontFamily: "Kanit-Regular",
                    color: "#333E50",
                  }}
                >
                  {parseInt(total) + 1 + "  "}
                </Text>
              </TouchableOpacity>
            </View>
          )}
        {page == total ? (
          <TouchableOpacity
            disabled={true}
            onPress={onNext}
            style={{
              backgroundColor: "#ccc",
              height: 30,
              minWidth: 30,
              alignItems: "center",
              justifyContent: "center",
              borderRadius: 30,
              marginLeft: 10,
            }}
          >
            <Text
              style={{
                fontSize: width > 1020 ? 16 : 14,
                fontFamily: "Kanit-Regular",
                color: "#fff",
              }}
            >
              {">"}
            </Text>
          </TouchableOpacity>
        ) : (
          <TouchableOpacity
            disabled={
              (total == 1 && page >= total) || (total != 1 && page > total)
            }
            onPress={onNext}
            style={{
              backgroundColor:
                (total == 1 && page >= total) || (total != 1 && page > total)
                  ? "#ccc"
                  : "#333E50",
              height: 30,
              minWidth: 30,
              alignItems: "center",
              justifyContent: "center",
              borderRadius: 30,
              marginLeft: 10,
            }}
          >
            <Text
              style={{
                fontSize: width > 1020 ? 16 : 14,
                fontFamily: "Kanit-Regular",
                color: "#fff",
              }}
            >
              {">"}
            </Text>
          </TouchableOpacity>
        )}
      </View>
    </View>
  );
}
