import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  Image,
  Dimensions,
  Animated,
  TouchableOpacity,
  StyleSheet,
  ScrollView,
  TextInput,
  FlatList,
} from "react-native";
import {
  MaterialCommunityIcons,
  MaterialIcons,
  Feather,
  Ionicons,
  FontAwesome,
} from "@expo/vector-icons";
import "react-calendar/dist/Calendar.css";
import NumberFormat from "react-number-format";
import Calendar from "react-calendar";
import HeaderAdmin from "../../components/headerAdmin";
import ButtonMenuSuperAdmin from "../../components/buttonMenuSuperAdmin";
import Modal from "modal-react-native-web";
import {
  authActionCreateUniversity,
  CreateDiscount,
  deleteUnversity,
} from "../../../action/authAction";
import { useRecoilState } from "recoil";
import { dataUniversityState, tokenState } from "../../../reducer/recoil";
import { useIsFocused } from "@react-navigation/native";
import { apiservice } from "../../../service";
import moment from "moment";
import RNPickerSelect from "react-native-picker-select";
import ProfileAdmin from "../../components/profileAdmin";
import { useWindowSize } from "../../components/responsive";
const { width, height } = Dimensions.get("window");
export default function main({ navigation, route }) {
  const isfocused = useIsFocused();
  const [token, setToken] = useRecoilState(tokenState);
  const [data, setData] = useState();
  const [openMenu, setOpenMenu] = useState(false);

  const [state, setState] = useState({
    code: "",
    name: "",
    is_count: null,
    count: null,
    startDate: "",
    endDate: "",
    percent: null,
    owner_id: 1,
  });

  useEffect(() => {
    if (isfocused) {
      getProfile(token);
      getAll();
      getAllTeacher();
    }
  }, [token, isfocused]);
  async function getProfile(token) {
    try {
      const response = await apiservice({
        path: "/authen/user",
        method: "get",
        token: token?.accessToken,
      });

      if (response.status == 200) {
        setState((val) => ({ ...val, owner_id: response?.data?.result?.id }));
      }
    } catch (error) {
      throw error;
    }
  }
  async function getAll() {
    const response = await apiservice({
      path: "/authen/RQ_all",
      method: "get",
      token: token?.accessToken,
    });
    if (response.status == 200) {
      setData(response.data);
    }
  }
  async function getAllTeacher() {
    const response = await apiservice({
      path: "/authen/getgeneralteacher?page=1&totalpage=100",
      method: "get",
      token: token?.accessToken,
    });
    if (response.status == 200) {
    }
  }
  var dd = moment.locale("th");

  const size = useWindowSize();

  return (
    <View style={styles.container}>
      <HeaderAdmin
        onPress={() => {
          setOpenMenu((val) => !val);
        }}
        navigation={navigation}
      />
      <View style={{ width: "100%", flexDirection: "row", height: "100%" }}>
        {openMenu && (
          <View //menu
            style={{
              width: "13%",

              minWidth: 220,
              height: "100%",
              backgroundColor: "#444444",
              position: "absolute",
              zIndex: 99,
            }}
          >
            <View style={{ height: "80%" }}>
              {(token?.role == "SUPERADMIN" ||
                token?.role == "GENERALADMIN") && (
                <ButtonMenuSuperAdmin
                  p1
                  onPress={() => {
                    setOpenMenu(false);
                    navigation.navigate("UniversityCourseSA");
                  }}
                />
              )}
              {(token?.role == "SUPERADMIN" ||
                token?.role == "GENERALADMIN") && (
                <ButtonMenuSuperAdmin
                  p2
                  onPress={() => {
                    setOpenMenu(false);
                    navigation.navigate("GeneralCourseSA");
                  }}
                />
              )}
              {(token?.role == "SUPERADMIN" ||
                token?.role == "GENERALADMIN") && (
                <ButtonMenuSuperAdmin
                  p3
                  onPress={() => {
                    setOpenMenu(false);
                    navigation.navigate("AddAdminSA");
                  }}
                />
              )}
              {(token?.role == "ACCOUNTING" || token?.role == "SUPERADMIN") && (
                <ButtonMenuSuperAdmin
                  p4
                  onPress={() => {
                    setOpenMenu(false);
                    navigation.navigate("ReportSA");
                  }}
                />
              )}
              {(token?.role == "SUPERADMIN" ||
                token?.role == "GENERALADMIN") && (
                <ButtonMenuSuperAdmin
                  p5
                  onPress={() => {
                    setOpenMenu(false);
                    navigation.navigate("SlideBannerSA");
                  }}
                />
              )}
              {(token?.role == "SUPERADMIN" ||
                token?.role == "GENERALADMIN") && (
                <ButtonMenuSuperAdmin
                  p6
                  onPress={() => {
                    setOpenMenu(false);
                    navigation.navigate("Discount");
                  }}
                />
              )}
              {(token?.role == "SUPERADMIN" ||
                token?.role == "GENERALADMIN") && (
                <ButtonMenuSuperAdmin
                  p7
                  color
                  border
                  onPress={() => {
                    setOpenMenu(false);
                    navigation.navigate("Account");
                  }}
                />
              )}
            </View>
            <ProfileAdmin />
          </View>
        )}
        {size.width >= 1280 && (
          <View //menu
            style={{
              width: "13%",

              minWidth: 220,
              height: "100%",
              backgroundColor: "#444444",
            }}
          >
            <View style={{ height: "80%" }}>
              {(token?.role == "SUPERADMIN" ||
                token?.role == "GENERALADMIN") && (
                <ButtonMenuSuperAdmin
                  p1
                  onPress={() => {
                    navigation.navigate("UniversityCourseSA");
                  }}
                />
              )}
              {(token?.role == "SUPERADMIN" ||
                token?.role == "GENERALADMIN") && (
                <ButtonMenuSuperAdmin
                  p2
                  onPress={() => {
                    navigation.navigate("GeneralCourseSA");
                  }}
                />
              )}
              {(token?.role == "SUPERADMIN" ||
                token?.role == "GENERALADMIN") && (
                <ButtonMenuSuperAdmin
                  p3
                  onPress={() => {
                    navigation.navigate("AddAdminSA");
                  }}
                />
              )}
              {(token?.role == "ACCOUNTING" || token?.role == "SUPERADMIN") && (
                <ButtonMenuSuperAdmin
                  p4
                  onPress={() => {
                    navigation.navigate("ReportSA");
                  }}
                />
              )}
              {(token?.role == "SUPERADMIN" ||
                token?.role == "GENERALADMIN") && (
                <ButtonMenuSuperAdmin
                  p5
                  onPress={() => {
                    navigation.navigate("SlideBannerSA");
                  }}
                />
              )}
              {(token?.role == "SUPERADMIN" ||
                token?.role == "GENERALADMIN") && (
                <ButtonMenuSuperAdmin
                  p6
                  onPress={() => {
                    navigation.navigate("Discount");
                  }}
                />
              )}
              {(token?.role == "SUPERADMIN" ||
                token?.role == "GENERALADMIN") && (
                <ButtonMenuSuperAdmin
                  p7
                  color
                  border
                  onPress={() => {
                    navigation.navigate("Account");
                  }}
                />
              )}
            </View>
            <ProfileAdmin />
          </View>
        )}
        <View
          style={[
            styles.viewDetail,
            {
              paddingHorizontal: size.width < 500 ? 20 : 55,
              width: size.width < 1280 ? "100%" : "87%",
            },
          ]}
        >
          <View
            style={{ flexDirection: "row", justifyContent: "space-between" }}
          >
            <Text
              style={{ fontSize: 23, fontFamily: "Prompt", color: "#808D93" }}
            >
              คำร้องขอเปลี่ยนบัญชี
            </Text>
          </View>
          <ScrollView horizontal>
            <View
              style={{
                width:
                  size.width < 500
                    ? size.width * 2.5
                    : size.width < 1280
                    ? size.width * 1.0
                    : size.width * 0.78,
                // minHeight: height * 2.5,
              }}
            >
              <View style={styles.viewSubject}>
                <Text style={[styles.text16B, { width: "20%" }]}>
                  ชื่อ-นามสกุล
                </Text>
                <Text style={[styles.text16B, { width: "20%" }]}>
                  เลขบัญชีเดิม
                </Text>

                <Text style={[styles.text16B, { width: "20%" }]}>
                  เลขบัญชีใหม่
                </Text>

                <Text style={[styles.text16B, { width: "20%" }]}>
                  วันที่ร้องขอ
                </Text>
                <Text style={[styles.text16B, { width: "20%" }]}></Text>
              </View>
              <FlatList
                numColumns={1}
                data={data}
                renderItem={({ item, index }) => {
                  return (
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        borderBottomWidth: 1,
                        borderBottomColor: "#E5E5E5",
                        height: 50,
                        alignItems: "center",
                      }}
                    >
                      <Text style={[styles.text16B, { width: "20%" }]}>
                        {item?.user_info?.user_accounts?.firstName +
                          " " +
                          item?.user_info?.user_accounts?.lastName}
                      </Text>
                      <Text style={[styles.text16B, { width: "20%" }]}>
                        {item.dataValues.old_account +
                          "\n" +
                          item.dataValues.new_account_type}
                      </Text>
                      <Text style={[styles.text16B, { width: "20%" }]}>
                        {item.dataValues.new_account +
                          "\n" +
                          item.dataValues.new_account_type}
                      </Text>

                      <Text style={[styles.text16B, { width: "20%" }]}>
                        {moment(item?.dataValues.createdAt)
                          .locale("th")
                          .add(543, "years")
                          .format("DD MMMM YYYY")}
                      </Text>
                      <View
                        style={{
                          width: "20%",
                          flexDirection: "row",
                          justifyContent: "center",
                        }}
                      >
                        <TouchableOpacity
                          onPress={async () => {
                            const res = await apiservice({
                              path:
                                "/authen/RQ_accept?uid=" +
                                item.dataValues.uid +
                                "&id=" +
                                item.dataValues.id +
                                "&balance_info=" +
                                item.dataValues.new_account_type +
                                "&balance_no=" +
                                item.dataValues.new_account,

                              method: "get",
                            });

                            if (res?.status == 200) {
                              getAll();
                            }
                          }}
                          style={{
                            width: 75,
                            height: 30,
                            backgroundColor: "#0D88F0",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: 4,
                          }}
                        >
                          <Text
                            style={{
                              fontFamily: "Prompt",
                              fontSize: 16,
                              color: "#FFFFFF",
                            }}
                          >
                            ยืนยัน
                          </Text>
                        </TouchableOpacity>
                        <TouchableOpacity
                          onPress={async () => {
                            const res = await apiservice({
                              path:
                                "/authen/RQ_remove?uid=" +
                                item.dataValues.uid +
                                "&id=" +
                                item.dataValues.id,
                              method: "get",
                            });

                            if (res?.status == 200) {
                              getAll();
                            }
                          }}
                          style={{
                            width: 75,
                            height: 30,
                            backgroundColor: "#808D93",
                            justifyContent: "center",
                            alignItems: "center",
                            marginLeft: 22,
                            borderRadius: 4,
                          }}
                        >
                          <Text
                            style={{
                              fontFamily: "Prompt",
                              fontSize: 16,
                              color: "#FFFFFF",
                            }}
                          >
                            ยกเลิก
                          </Text>
                        </TouchableOpacity>
                      </View>
                    </View>
                  );
                }}
              />
            </View>
          </ScrollView>
        </View>
      </View>
    </View>
  );
}
const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: "100%",
    backgroundColor: "#FFFFFF",
  },
  text16W: {
    fontFamily: "Prompt",
    fontSize: 16,
    color: "#FFFFFF",
  },
  text16B: {
    fontFamily: "Prompt",
    fontSize: 16,
    color: "#000000",
    textAlign: "center",
  },
  buttonAdd: {
    width: 140,
    height: 40,
    backgroundColor: "#0D88F0",
    borderRadius: 20,
    alignItems: "center",
    justifyContent: "center",
    alignSelf: "flex-end",
  },
  viewSubject: {
    width: "100%",
    height: 51,
    backgroundColor: "#E5E5E5",
    marginTop: 35,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  viewDetail: {
    width: "87%",
    height: "100%",
    paddingHorizontal: 55,
    paddingVertical: 28,
  },
  bgModal: {
    width: "100%",
    height: "100%",
    backgroundColor: "#000000bb",
    justifyContent: "center",
  },
  viewDetailModal: {
    width: "50%",

    backgroundColor: "#FFFFFF",
    borderRadius: 20,
    alignSelf: "center",
    paddingHorizontal: width * 0.05,
    paddingVertical: 50,
  },
  buttonModal: {
    width: 102,
    height: 48,
    borderRadius: 4,
    backgroundColor: "#EFEFF3",
    justifyContent: "center",
    alignItems: "center",
  },
  textButtonModal: {
    fontSize: 25,
    fontFamily: "Prompt",
  },
  textModal: {
    fontSize: 22,
    fontFamily: "Prompt",
  },
});
