import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  Image,
  Dimensions,
  Animated,
  TouchableOpacity,
  StyleSheet,
  ScrollView,
  TextInput,
  FlatList,
} from "react-native";
import {
  MaterialCommunityIcons,
  MaterialIcons,
  Feather,
  Ionicons,
  FontAwesome,
} from "@expo/vector-icons";
import { AntDesign } from "@expo/vector-icons";
import RNPickerSelect from "react-native-picker-select";
import Modal from "modal-react-native-web";
import {
  authActionCreateTeacher,
  authActionPutUserByAdmin,
  deleteTeacher,
} from "../../../action/authAction";
const { width, height } = Dimensions.get("window");
import { CSVReader } from "react-papaparse";
import { apiservice } from "../../../service";
import moment from "moment";
import { useRecoilState } from "recoil";
import { tokenState } from "../../../reducer/recoil";
import { useWindowSize } from "../../components/responsive";
export default function teacher({
  ongoBack,
  onPress,
  setName,
  setLastName,
  university_id,
  setAllResultTeacher,
}) {
  const [modalDelItem, setModalDelItem] = useState(false);
  const [time, setTime] = useState("");
  const [modalAddItem, setModalAddItem] = useState(false);
  const [modalMail, setModalMail] = useState(false);
  const [teacher, setTeacher] = useState([]);
  const [paginations, setPaginations] = useState([]);
  const [page, setpage] = useState(1);
  const [total_page, setTotalpage] = useState(0);
  const [token, setToken] = useRecoilState(tokenState);
  const [search, setSearch] = useState("");
  const [datavalid, setdatavalid] = useState([]);
  const [valid, setvalid] = useState(false);
  const [valid1, setvalid1] = useState(false);
  const [id, setId] = useState();
  const [fac, setFac] = useState([]);
  const size = useWindowSize();
  const [body, setBody] = useState({
    firstName: "",
    lastName: "",
  });
  const placeholder = {
    label: "คณะทั้งหมด",
    value: null,
    color: "#0D88F0",
  };
  const [faculty, setFaculty] = useState([
    { label: "เกษตรศาสตร์", value: "Agriculture" },
    { label: "สถาปัตยกรรมศาสตร์", value: "Architecture" },
    { label: "อักษรศาสตร์", value: "Arts" },
    { label: "บริหารธุกิจ", value: "BusinessAdministration" },
    { label: "นิเทศศาสตร์", value: "CommunicationArts" },
    { label: "ทันตแพทยศาสตร์", value: "Dentistry" },
    { label: "เศรษฐศาสตร์", value: "Economics" },
    { label: "ครุศาสตร์", value: "Education" },
    { label: "วิศวกรรมศาสตร์", value: "Engineering" },
    { label: "ศิลปกรรมศาสตร์", value: "FineAndAppliedArts" },
  ]);
  async function getProfile(token) {
    try {
      const response = await apiservice({
        path: "/authen/user",
        method: "get",
        token: token?.accessToken,
      });
      if (response.status == 200) {
        getTeacher(response.data.result.university.id);
        getFaculty(response.data.result.university.id);
      }
    } catch (error) {
      throw error;
    }
  }
  const handleOpenDialog = (e) => {
    // Note that the ref is set async, so it might be null at some point
    if (buttonRef.current) {
      buttonRef.current.open(e);
    }
  };

  const handleOnFileLoad = async (data) => {
    const date = await Promise.all(
      data.map(async (item, index) => {
        if (index != 0 && item.data["9"] != undefined) {
          try {
            const response = await apiservice({
              path: "/authen/registerteacher",
              method: "POST",
              body: {
                code: item.data["0"],
                username: item.data["1"],
                email: item.data["2"],
                password: item.data["0"],
                firstName: item.data["3"],
                lastName: item.data["4"],
                telNo: item.data["5"],
                address: item.data["6"],
                balance_info: "KBANK",
                balance_no: "",
                promptpay: "",
                education: {},
                organization: "",
                position: item.data["7"],
                about_us: item.data["8"],
                facebook_url: "",
                instagram_url: "",
                twitter_url: "",
                approve: true,
                class: "",
                degree: "",
                faculti_id: fac.filter((items) => {
                  return (
                    items.facuty_name_th + "/" + items.major_name_th ==
                    item.data["9"] + "/" + item.data["10"]
                  );
                })[0].id,
                university_id: university_id,
              },
            });
            if (response.status != 200) {
              setdatavalid((val) =>
                val.concat(
                  " Username " +
                    item.data["1"] +
                    " " +
                    " Email " +
                    item.data["2"]
                )
              );
              setvalid(true);
            } else {
              setdatavalid((val) =>
                val.concat(
                  " Username " +
                    item.data["2"] +
                    " " +
                    " Email " +
                    item.data["2"]
                )
              );
              setvalid1(true);
            }
          } catch (error) {}
        }

        // return { ...item, ...response };
      })
    );
    getProfile(token);
    // const test = data1.map((item) => console.log(item));
  };

  const handleOnError = (err, file, inputElem, reason) => {};

  const handleOnRemoveFile = (data) => {};

  const buttonRef = React.createRef();
  useEffect(() => {
    if (token) {
      getProfile(token);
    }
  }, [token]);
  async function editname() {
    const edit = await authActionPutUserByAdmin({
      state: body,
      token: token?.accessToken,
      id: id,
    });
    if (edit) {
      setBody({
        firstName: "",
        lastName: "",
      });
      setModalAddItem(!modalAddItem);
      getProfile(token);
    }
  }
  async function sendMail(params) {
    const res = await apiservice({
      path: "/authen/resend/" + params,
      method: "get",
    });
  }
  async function getTeacher(params) {
    const response = await apiservice({
      path:
        "/authen/getteacher?university_id=" + params + "&page=1&totalpage=100",
      method: "get",
      //token: token?.accessToken,
    });
    if (response.status == 200) {
      let pagination = [];
      for (let index = 0; index < response.data.total_page; index++) {
        pagination = pagination.concat({});
      }

      setTotalpage(response.data.totalpage);
      setPaginations(pagination);
      setTeacher(response.data.result);
    } else {
    }
  }
  async function getTeacher1(params, val) {
    const response = await apiservice({
      path:
        `/authen/getteacher?university_id=${university_id}&page=${params}&totalpage=100&faculty_id=` +
        val,
      method: "get",
      //token: token?.accessToken,
    });
    //console.log(response.data.result.length);
    if (response.status == 200) {
      let pagination = [];
      for (let index = 0; index < response.data.total_page; index++) {
        pagination = pagination.concat({});
      }
      setTotalpage(response.data.total_page);
      setPaginations(pagination);
      setTeacher(response.data.result);
    } else {
    }
  }
  async function getFaculty(params) {
    try {
      const response = await apiservice({
        path: "/course/getfaculty?page=1&totalpage=100&university_id=" + params,
        method: "get",
        token: token?.accessToken,
      });

      setFac(response.data.result);
      setFaculty(
        response.data.result.map((item) => {
          return {
            label: item.facuty_name_th + "/" + item.major_name_th,
            value: item.id.toString(),
          };
        })
      );
    } catch (error) {
      throw error;
    }
  }
  async function Delete() {
    const Delete = await deleteTeacher({
      token: token?.accessToken,
      id: id,
    });
  }

  return (
    <View>
      <Modal
        animationType="none"
        transparent={true}
        visible={valid}
        onRequestClose={() => {
          setvalid(!valid);
        }}
      >
        {valid && (
          <View
            style={{
              width: width,
              height: height,
              backgroundColor: "#000000bb",
              justifyContent: "center",
            }}
          >
            <View
              style={{
                width: width * 0.5,
                paddingVertical: 25,
                backgroundColor: "#fff",
                paddingHorizontal: 20,
                justifyContent: "center",
                alignSelf: "center",
              }}
            >
              <Text
                style={[
                  styles.TextButton,
                  {
                    color: "#ff0000",
                    margin: 10,
                    alignSelf: "center",
                    textAlign: "center",
                  },
                ]}
              >
                {
                  "เกิดข้อผิดพลาดในการเพิ่มรายชื่อดังต่อไปนี้เนื่องจากอีเมลหรือชื่อผู้ใช้งานซ้ำ กรุณาตรวจสอบใหม่อีกครั้ง"
                }
              </Text>
              {datavalid.map((item) => {
                return (
                  <Text
                    style={[
                      styles.TextButton,
                      { color: "#ff0000", margin: 10, alignSelf: "center" },
                    ]}
                  >
                    {item}
                  </Text>
                );
              })}
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignSelf: "center",
                  marginTop: 20,
                }}
              >
                <TouchableOpacity
                  onPress={() => {
                    setdatavalid([]);
                    setvalid(false);
                  }}
                  style={{
                    width: 120,
                    height: 35,
                    borderWidth: 1,
                    overflow: "hidden",
                    borderColor: "#000",
                    borderRadius: 5,
                    justifyContent: "center",
                  }}
                >
                  <Text
                    style={{
                      fontFamily: "Prompt-Regular",
                      fontSize: 15,
                      alignSelf: "center",
                    }}
                  >
                    ปิด
                  </Text>
                </TouchableOpacity>
              </View>
            </View>
          </View>
        )}
      </Modal>

      <Modal
        animationType="none"
        transparent={true}
        visible={valid1}
        onRequestClose={() => {
          setvalid1(!valid1);
        }}
      >
        {valid1 && (
          <View
            style={{
              width: width,
              height: height,
              backgroundColor: "#000000bb",
              justifyContent: "center",
            }}
          >
            <View
              style={{
                width: width * 0.5,
                paddingVertical: 25,
                backgroundColor: "#fff",
                paddingHorizontal: 20,
                justifyContent: "center",
                alignSelf: "center",
              }}
            >
              <Text
                style={[
                  styles.TextButton,
                  { color: "black", margin: 10, alignSelf: "center" },
                ]}
              >
                {"เพิ่มรายของครูเรียบร้อย"}
              </Text>
              {datavalid.map((item) => {
                return (
                  <Text
                    style={[
                      styles.TextButton,
                      { color: "black", margin: 10, alignSelf: "center" },
                    ]}
                  >
                    {item}
                  </Text>
                );
              })}
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignSelf: "center",
                  marginTop: 20,
                }}
              >
                <TouchableOpacity
                  onPress={() => {
                    setdatavalid([]);
                    setvalid1(false);
                  }}
                  style={{
                    width: 120,
                    height: 35,
                    borderWidth: 1,
                    overflow: "hidden",
                    borderColor: "#000",
                    borderRadius: 5,
                    justifyContent: "center",
                  }}
                >
                  <Text
                    style={{
                      fontFamily: "Prompt-Regular",
                      fontSize: 15,
                      alignSelf: "center",
                    }}
                  >
                    ปิด
                  </Text>
                </TouchableOpacity>
              </View>
            </View>
          </View>
        )}
      </Modal>
      <View style={{ flexDirection: "row", marginTop: 10 }}>
        <TouchableOpacity onPress={ongoBack}>
          <Text style={styles.text23}>คอร์สมหาวิทยาลัย</Text>
        </TouchableOpacity>
        <Text
          style={[
            styles.text23,
            {
              marginHorizontal: 18,
            },
          ]}
        >
          /
        </Text>
        <Text style={[styles.text23, { color: "#0D88F0" }]}>อาจารย์ผู้สอน</Text>
      </View>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <TextInput
          onChangeText={(text) => {
            setSearch(text);
          }}
          autoComplete="off"
          placeholder="ค้นหาชื่ออาจารย์ผู้สอน"
          placeholderTextColor="#8C8896"
          style={{
            width: size.width < 500 ? "80%" : "30%",
            height: 48,
            borderRadius: 4,
            backgroundColor: "#EFEFF3",
            fontSize: 16,
            fontFamily: "Prompt",
            color: "#8C8896",
            paddingHorizontal: 25,
            marginTop: 30,
          }}
        />

        {/* <TouchableOpacity onPress={() => {}} style={styles.buttonAdd}>
          <Text style={styles.text16W}>+ Add New</Text>
        </TouchableOpacity> */}
        {size.width >= 1280 && (
          <CSVReader
            ref={buttonRef}
            onFileLoad={handleOnFileLoad}
            onError={handleOnError}
            noClick
            noDrag
            onRemoveFile={handleOnRemoveFile}
          >
            {({ file }) => (
              <aside
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginBottom: 10,
                }}
              >
                <button
                  type="button"
                  onClick={handleOpenDialog}
                  style={{
                    width: 140,
                    height: 40,
                    backgroundColor: "#0D88F0",
                    borderRadius: 20,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Text style={styles.text16W}>+ Add New</Text>
                </button>
              </aside>
            )}
          </CSVReader>
        )}
      </View>
      <RNPickerSelect
        placeholder={placeholder}
        onValueChange={(val) => {
          getTeacher1(1, val);
        }}
        items={faculty}
        style={{
          inputWeb: {
            borderColor: "#0D88F0",
            width: size.width < 500 ? 200 : 400,
            backgroundColor: "#FFFFFF",
            height: 32,
            color: "#0D88F0",
            fontFamily: "Prompt",
            fontSize: 16,
            paddingHorizontal: 10,
            marginTop: 40,
          },
        }}
      />
      <ScrollView horizontal>
        <View
          style={{
            width:
              size.width < 500
                ? size.width * 2.5
                : size.width < 1280
                ? size.width * 1.0
                : size.width * 0.78,
            // minHeight: height * 2.5,
          }}
        >
          <View style={styles.viewSubject}>
            <Text style={[styles.text16B, { paddingLeft: 67, width: "30%" }]}>
              ชื่ออาจารย์
            </Text>
            <Text style={[styles.text16B, { width: "20%" }]}>ประจำคณะ</Text>
            <Text style={[styles.text16B, { width: "20%" }]}>วันที่สร้าง</Text>
            <Text style={[styles.text16B, { width: "15%" }]}>
              จำนวนคอร์สที่สร้าง
            </Text>
            <Text style={[styles.text16B, { width: "5%" }]}></Text>
            <Text style={[styles.text16B, { width: "5%" }]}></Text>
          </View>

          <FlatList
            numColumns={1}
            data={teacher.filter((item) => {
              return (
                item.user_accounts.firstName.includes(search) ||
                item.user_accounts.lastName.includes(search)
              );
            })}
            extraData={teacher}
            renderItem={({ item, index }) => {
              // console.log(item);
              return (
                <TouchableOpacity
                  onPress={() => {
                    setName(item.user_accounts.firstName);
                    setLastName(item.user_accounts.lastName);
                    setAllResultTeacher(item.allResult);
                    onPress();
                  }}
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    borderBottomWidth: 1,
                    borderBottomColor: "#E5E5E5",
                    height: 50,
                    alignItems: "center",
                  }}
                >
                  <Text
                    style={[styles.text16B, { paddingLeft: 67, width: "30%" }]}
                  >
                    {item.user_accounts.firstName} {item.user_accounts.lastName}
                  </Text>
                  <Text style={[styles.text16B, { width: "20%" }]}>
                    {item.faculty[0].facuty_name_th}
                  </Text>
                  <Text style={[styles.text16B, { width: "20%" }]}>
                    {moment(item.user_accounts.createdAt)
                      .add(543, "year")
                      .format("DD MMMM YYYY")}
                  </Text>
                  <Text style={[styles.text16B, { width: "15%" }]}>
                    {item.user_accountss}
                  </Text>
                  <TouchableOpacity
                    onPress={() => {
                      sendMail(item.id);
                      setModalMail(true);
                      setTimeout(() => {
                        setModalMail(false);
                      }, 2000);
                    }}
                    style={{
                      width: "5%",
                      alignItems: "center",
                      backgroundColor: "#0D88F0",
                      borderRadius: 5,
                    }}
                  >
                    <Text style={[styles.text16W, { textAlign: "center" }]}>
                      ส่งอีเมล
                    </Text>
                  </TouchableOpacity>
                  <TouchableOpacity
                    onPress={() => {
                      setId(item.id);
                      setBody({
                        ...body,
                        firstName: item.user_accounts.firstName,
                        lastName: item.user_accounts.lastName,
                      });

                      setModalAddItem(true);
                    }}
                    style={{ width: "5%", alignItems: "flex-end" }}
                  >
                    <FontAwesome name="edit" size={24} color="black" />
                  </TouchableOpacity>
                  <TouchableOpacity
                    onPress={() => {
                      setId(item.id);
                      setModalDelItem(true);
                    }}
                    style={{ width: "5%", alignItems: "center" }}
                  >
                    <MaterialIcons name="delete" size={24} color="black" />
                  </TouchableOpacity>
                </TouchableOpacity>
              );
            }}
          />
        </View>
      </ScrollView>
      <View
        style={{
          width: "100%",

          height: 50,
          marginTop: 10,
        }}
      >
        <View
          style={{
            marginVertical: 10,
            width: "100%",
            alignItems: "center",
          }}
        >
          <FlatList
            data={paginations.filter(
              (item, index) => page <= index && page + 3 >= index
            )}
            horizontal
            renderItem={({ item, index }) => {
              return (
                <View style={{ flexDirection: "row" }}>
                  {page != 1 && index == 0 && (
                    <>
                      <TouchableOpacity
                        onPress={() => {
                          getTeacher(page - 1);
                          setpage(page - 1);
                        }}
                        style={{
                          width: width * 0.026,
                          height: height * 0.046,
                          backgroundColor: "#ffff",
                          justifyContent: "center",
                          zIndex: 99,
                        }}
                      >
                        <AntDesign name="caretleft" size={18} color="black" />
                      </TouchableOpacity>
                      <TouchableOpacity
                        onPress={() => {
                          getTeacher(1);
                          setpage(1);
                        }}
                        style={{
                          width: width * 0.026,
                          height: height * 0.046,
                          backgroundColor: "#ffff",
                          justifyContent: "center",
                          zIndex: 99,
                        }}
                      >
                        <Text style={{ textAlign: "center", fontSize: 13 }}>
                          {1}
                        </Text>
                      </TouchableOpacity>
                      <TouchableOpacity
                        disabled
                        style={{
                          width: width * 0.026,
                          height: height * 0.046,
                          backgroundColor: "#ffff",
                          justifyContent: "center",
                          zIndex: 99,
                        }}
                      >
                        <Text style={{ textAlign: "center", fontSize: 13 }}>
                          ...
                        </Text>
                      </TouchableOpacity>
                    </>
                  )}
                  <TouchableOpacity
                    onPress={() => {
                      getTeacher(page + index);
                      setpage(page + index);
                    }}
                    style={{
                      width: width * 0.026,
                      height: height * 0.036,
                      backgroundColor:
                        page + index == page ? "#3D6B99" : "#ffff",
                      justifyContent: "center",
                      borderRadius: 150,
                      zIndex: 99,
                    }}
                  >
                    <Text
                      style={{
                        textAlign: "center",
                        color: page + index == page ? "#fff" : "#000",
                        fontSize: 13,
                        // fontFamily: "Kanit-Regular",
                      }}
                    >
                      {page + index}
                    </Text>
                  </TouchableOpacity>
                  {page != total_page &&
                    index ==
                      paginations.filter(
                        (item, index) => page <= index && page + 3 >= index
                      ).length -
                        1 && (
                      <>
                        <TouchableOpacity
                          disabled
                          style={{
                            width: width * 0.026,
                            height: height * 0.046,
                            backgroundColor: "#ffff",
                            justifyContent: "center",
                            zIndex: 99,
                          }}
                        >
                          <Text
                            style={{
                              textAlign: "center",
                              fontSize: 13,
                            }}
                          >
                            ...
                          </Text>
                        </TouchableOpacity>
                        <TouchableOpacity
                          onPress={() => {
                            getTeacher(total_page);
                            setpage(total_page);
                          }}
                          style={{
                            width: width * 0.026,
                            height: height * 0.046,
                            backgroundColor: "#ffff",
                            justifyContent: "center",
                            zIndex: 99,
                          }}
                        >
                          <Text style={{ textAlign: "center", fontSize: 13 }}>
                            {total_page}
                          </Text>
                        </TouchableOpacity>
                        <TouchableOpacity
                          onPress={() => {
                            getTeacher(page + 1);
                            setpage(page + 1);
                          }}
                          style={{
                            width: width * 0.026,
                            height: height * 0.046,
                            backgroundColor: "#ffff",
                            justifyContent: "center",
                            zIndex: 99,
                          }}
                        >
                          <AntDesign
                            name="caretright"
                            size={18}
                            color="black"
                          />
                        </TouchableOpacity>
                      </>
                    )}
                </View>
              );
            }}
          />
        </View>
      </View>
      <Modal
        transparent={true}
        visible={modalDelItem}
        onRequestClose={() => {
          Alert.alert("Modal has been closed.");
          setModalDelItem(!modalDelItem);
        }}
      >
        <View style={styles.bgModal}>
          <View
            style={[
              styles.viewDetailModal,
              {
                width:
                  size.width < 500 ? "80%" : size.width < 1280 ? "70%" : "30%",
                height: 183,
                paddingVertical: 25,
              },
            ]}
          >
            <Text
              style={[
                styles.textButtonModal,
                { color: "#444444", alignSelf: "center" },
              ]}
            >
              คุณต้องการจะลบใช่รึไม่
            </Text>
            <View
              style={{
                flexDirection: "row",
                alignSelf: "center",
                marginTop: 39,
              }}
            >
              <TouchableOpacity
                onPress={() => setModalDelItem(!modalDelItem)}
                style={styles.buttonModal}
              >
                <Text
                  style={[
                    styles.textButtonModal,
                    {
                      color: "#444444",
                    },
                  ]}
                >
                  ยกเลิก
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => {
                  Delete();
                  setTimeout(() => {
                    getProfile(token);
                  }, 500);
                  setModalDelItem(!modalDelItem);
                }}
                style={[
                  styles.buttonModal,
                  {
                    backgroundColor: "#0D88F0",
                    marginLeft: width * 0.05,
                  },
                ]}
              >
                <Text
                  style={[
                    styles.textButtonModal,
                    {
                      color: "#FFFFFF",
                    },
                  ]}
                >
                  ยืนยัน
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </Modal>
      <Modal
        transparent={true}
        visible={modalMail}
        onRequestClose={() => {
          Alert.alert("Modal has been closed.");
          setModalMail(!modalMail);
        }}
      >
        <View style={styles.bgModal}>
          <View
            style={[
              styles.viewDetailModal,
              {
                height: 183,
                alignItems: "center",
                justifyContent: "center",
                width:
                  size.width < 500 ? "80%" : size.width < 1280 ? "70%" : "30%",
                paddingHorizontal: 20,
              },
            ]}
          >
            <Text
              style={[
                styles.text16B,
                {
                  color: "#69D200",
                  fontSize: 30,
                  width: "100%",
                  textAlign: "center",
                },
              ]}
            >
              ส่งอีเมลสำเร็จ
            </Text>
          </View>
        </View>
      </Modal>
      <Modal
        transparent={true}
        visible={modalAddItem}
        onRequestClose={() => {
          Alert.alert("Modal has been closed.");
          setModalAddItem(!modalAddItem);
        }}
      >
        <View style={styles.bgModal}>
          <View
            style={[
              styles.viewDetailModal1,
              {
                width:
                  size.width < 500
                    ? "80%"
                    : size.width < 1280
                    ? "70%"
                    : width < 1600
                    ? "65%"
                    : "50%",
              },
            ]}
          >
            <TextInput
              autoComplete="off"
              value={body.firstName}
              onChangeText={(text) => {
                setBody({ ...body, firstName: text });
              }}
              placeholder={"กรอกชื่อ"}
              placeholderTextColor={"#444444"}
              style={[
                styles.inputModal,
                { fontSize: size.width < 500 ? 18 : 35 },
              ]}
            />

            <TextInput
              value={body.lastName}
              onChangeText={(text) => {
                setBody({ ...body, lastName: text });
              }}
              placeholder={"กรอกนามสกุล"}
              placeholderTextColor={"#444444"}
              style={[
                styles.inputModal,
                { marginTop: 30, fontSize: size.width < 500 ? 18 : 35 },
              ]}
            />

            <View
              style={{
                flexDirection: "row",
                alignSelf: "center",
                marginTop: 49,
              }}
            >
              <TouchableOpacity
                onPress={() => {
                  setModalAddItem(!modalAddItem);
                }}
                style={styles.buttonModal}
              >
                <Text
                  style={[
                    styles.textButtonModal,
                    {
                      color: "#444444",
                    },
                  ]}
                >
                  ยกเลิก
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={editname}
                style={[
                  styles.buttonModal,
                  {
                    backgroundColor: "#0D88F0",
                    marginLeft: width * 0.05,
                  },
                ]}
              >
                <Text
                  style={[
                    styles.textButtonModal,
                    {
                      color: "#FFFFFF",
                    },
                  ]}
                >
                  ยืนยัน
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </Modal>
    </View>
  );
}
const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: "100%",
    backgroundColor: "#FFFFFF",
  },
  text23: {
    fontSize: 23,
    fontFamily: "Prompt",
    color: "#808D93",
  },
  text16W: {
    fontFamily: "Prompt",
    fontSize: 16,
    color: "#FFFFFF",
  },
  text16B: {
    fontFamily: "Prompt",
    fontSize: 16,
    color: "#000000",
    width: "23%",
  },
  buttonAdd: {
    width: 140,
    height: 40,
    backgroundColor: "#0D88F0",
    borderRadius: 20,
    alignItems: "center",
    justifyContent: "center",
  },
  viewSubject: {
    width: "100%",
    height: 51,
    backgroundColor: "#E5E5E5",
    marginTop: 35,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  bgModal: {
    width: "100%",
    height: "100%",
    backgroundColor: "#000000bb",
    justifyContent: "center",
  },
  viewDetailModal: {
    width: "50%",
    height: 380,
    backgroundColor: "#FFFFFF",
    borderRadius: 20,
    alignSelf: "center",
    paddingHorizontal: width * 0.05,
    paddingVertical: 50,
  },
  buttonModal: {
    width: 102,
    height: 48,
    borderRadius: 4,
    backgroundColor: "#EFEFF3",
    justifyContent: "center",
    alignItems: "center",
  },
  textButtonModal: {
    fontSize: 25,
    fontFamily: "Prompt",
  },
  viewDetailModal1: {
    width: "60%",

    backgroundColor: "#FFFFFF",
    borderRadius: 20,
    alignSelf: "center",
    paddingHorizontal: width * 0.05,
    paddingVertical: 50,
  },
  inputModal: {
    width: "100%",
    height: 67,
    borderRadius: 9,
    borderWidth: 1,
    borderColor: "#707070",
    fontSize: 35,
    fontFamily: "Prompt",
    color: "#444444",
    textAlign: "center",
  },
});
