import React, { useState } from "react";
import { View, Text, Dimensions, StyleSheet } from "react-native";
import { MaterialIcons } from "@expo/vector-icons";
const { width, height } = Dimensions.get("window");
import HTMLRenderer from "react-html-renderer";
import { useWindowSize } from "../components/responsive";
export default function cousePage2({ route, Oncecourse }) {
  const embed = route;

  const stringToHTML = function (str) {
    const domContainer = document.createElement("span");
    domContainer.innerHTML = str;
    return domContainer;
  };

  const parentEmbed = stringToHTML(embed);

  let oldIframe = parentEmbed.querySelectorAll("oembed");
  oldIframe = Array.from(oldIframe);

  for (const i in oldIframe) {
    //Get the url from oembed tag
    let url = oldIframe[i].getAttribute("url");
    //Replace 'watch?v' with 'embed/'
    url = url.replace("watch?v=", "embed/");

    //Create a iframe tag
    const newIframe = document.createElement("iframe");
    newIframe.setAttribute("width", "auto");
    newIframe.setAttribute("height", "auto");
    newIframe.setAttribute("allowFullScreen", "");
    newIframe.setAttribute("frameBorder", 0);
    if (url) {
      newIframe.setAttribute("src", url);
    }
    // replace oldIframe with newIframe
    oldIframe[i].parentNode.replaceChild(newIframe, oldIframe[i]);
  }

  const contentToRender = parentEmbed.outerHTML?.replace(
    /<img /g,
    `<img width="${width * 0.5}" height="auto" `
  );
  const size = useWindowSize();
  return (
    <View style={{ minHeight: 500 }}>
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          marginTop: 50,
          marginBottom: 20,
        }}
      >
        <MaterialIcons name="turned-in" size={40} color="#646464" />
        <Text
          style={[
            size.width < 750 ? stylesResponsive.text : styles.text,
            { marginLeft: 20 },
          ]}
        >
          ข้อมูลผู้สอน
        </Text>
      </View>
      <Text style={[size.width < 750 ? stylesResponsive.text : styles.text]}>
        <HTMLRenderer
          html={contentToRender}
          components={
            {
              // h1: props => <Heading color="red" {...props} />,
              // h2: Subheading,
              // a: Link,
            }
          }
        />
      </Text>
    </View>
  );
}
const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: "100%",
    backgroundColor: "#FFFFFF",
  },
  text: { fontSize: 20, fontFamily: "Prompt", color: "#646464" },
});
const stylesResponsive = StyleSheet.create({
  text: { fontSize: 16, fontFamily: "Prompt", color: "#646464" },
});
