import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  Image,
  Dimensions,
  Animated,
  TouchableOpacity,
  StyleSheet,
  ScrollView,
  TextInput,
  FlatList,
} from "react-native";
import {
  MaterialCommunityIcons,
  MaterialIcons,
  Feather,
  Ionicons,
  FontAwesome,
} from "@expo/vector-icons";
import RNPickerSelect from "react-native-picker-select";
import Modal from "modal-react-native-web";
import { useRecoilState } from "recoil";
import { dataUniversityState, tokenState } from "../../../reducer/recoil";
import { useIsFocused } from "@react-navigation/native";
import { apiservice } from "../../../service";
import HeaderAdmin from "../../components/headerAdmin";
import ButtonMenuSuperAdmin from "../../components/buttonMenuSuperAdmin";
import ProfileAdmin from "../../components/profileAdmin";
import moment from "moment";
import Teacher from "./teacher";
import TeacherDetail from "./teacherDetail";
import Subject from "./subject";
import Student from "./student";
import StudentDetail from "./studentDetail";
import Faculty from "./faculty";
import Information from "./information";
import { useWindowSize } from "../../components/responsive";
const { width, height } = Dimensions.get("window");
export default function CoursesTaught({ navigation, route }) {
  const [dataUniversity, setDataUniversity] =
    useRecoilState(dataUniversityState);
  const [openMenu, setOpenMenu] = useState(false);
  const data = dataUniversity;
  const major = dataUniversity.major;
  const [deGreeIndex, setDeGreeIndex] = useState(0);
  const [page, setPage] = useState(0);
  const [course, setCourse] = useState([]);
  const isfocused = useIsFocused();
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [nameStudent, setNameStudent] = useState("");
  const [lastNameStudent, setLastNameStudent] = useState("");
  const [teacherDetail, setTeacherDetail] = useState(false);
  const [subjectDetail, setSubjectDetail] = useState(false);
  const [studentDetail, setStudentDetail] = useState(false);
  const [value, labe] = useState(null);
  const [courseName1, setCourseName1] = useState("");

  const [courseName2, setCourseName2] = useState(null);

  const [allResult, setAllResult] = useState();
  const [allResultTeacher, setAllResultTeacher] = useState();

  const [editName, setEditName] = useState({
    university_name_en: "",
    university_description_en: "",
    university_name_th: "",
    university_description_th: "",
  });
  const [token, setToken] = useRecoilState(tokenState);
  const degreeList = ["ปริญญาตรี", "ปริญญาโท", "ปริญญาเอก"];
  const size = useWindowSize();
  useEffect(() => {
    if (isfocused) {
      if (dataUniversity != undefined) {
        setEditName({
          ...editName,
          university_name_th: dataUniversity.university_name_th,
        });
      }
      getUniversity(token);
    }
  }, [token, isfocused]);

  async function getUniversity(token) {
    try {
      const response = await apiservice({
        path:
          "/course/getallcouse?university_id=" +
          data.id +
          "&page=1&totalpage=100",
        method: "get",
        token: token?.accessToken,
      });

      if (response.status == 200) {
        let pagination = [];
        for (let index = 0; index < response.data.total_page; index++) {
          pagination = pagination.concat({});
        }

        setCourse(response.data);
        getFaculty(data.id);
      }
    } catch (error) {
      throw error;
    }
  }

  async function getUniversity1(token, val) {
    try {
      const response = await apiservice({
        path:
          "/course/getallcouse?university_id=" +
          data.id +
          "&page=1&totalpage=100&faculty_id=" +
          val,
        method: "get",
        token: token?.accessToken,
      });

      if (response.status == 200) {
        let pagination = [];
        for (let index = 0; index < response.data.total_page; index++) {
          pagination = pagination.concat({});
        }

        setCourse(response.data);
      }
    } catch (error) {
      throw error;
    }
  }

  async function getUniversity2(token, val) {
    try {
      const response = await apiservice({
        path:
          "/course/getallcouse?university_id=" +
          data.id +
          "&page=1&totalpage=100&grade_id=" +
          val,
        method: "get",
        token: token?.accessToken,
      });

      if (response.status == 200) {
        let pagination = [];
        for (let index = 0; index < response.data.total_page; index++) {
          pagination = pagination.concat({});
        }

        setCourse(response.data);
      }
    } catch (error) {
      throw error;
    }
  }

  async function authActionPutUniversity() {
    try {
      const response = await apiservice({
        path: "/course/updateuniversity/" + data.id,
        method: "put",
        body: editName,
        token: token?.accessToken,
      });
      if (response.status == 200) {
        return response.data;
      } else {
        return false;
      }
    } catch (error) {
      throw error;
    }
  }

  const degreeChangeHandler = (index) => {
    // console.log("index \t", index);
    getUniversity2(token, index);
    setDeGreeIndex((preIndex) => index);
  };
  const placeholder = {
    label: "คณะทั้งหมด",
    value: null,
    color: "#0D88F0",
  };
  const [faculty, setFaculty] = useState([
    { label: "เกษตรศาสตร์", value: "Agriculture" },
    { label: "สถาปัตยกรรมศาสตร์", value: "Architecture" },
    { label: "อักษรศาสตร์", value: "Arts" },
    { label: "บริหารธุกิจ", value: "BusinessAdministration" },
    { label: "นิเทศศาสตร์", value: "CommunicationArts" },
    { label: "ทันตแพทยศาสตร์", value: "Dentistry" },
    { label: "เศรษฐศาสตร์", value: "Economics" },
    { label: "ครุศาสตร์", value: "Education" },
    { label: "วิศวกรรมศาสตร์", value: "Engineering" },
    { label: "ศิลปกรรมศาสตร์", value: "FineAndAppliedArts" },
  ]);

  async function getFaculty(params) {
    try {
      const response = await apiservice({
        path: "/course/getfaculty?page=1&totalpage=100&university_id=" + params,
        method: "get",
        token: token?.accessToken,
      });

      if (response.status == 200) {
        let pagination = [];
        for (let index = 0; index < response.data.total_page; index++) {
          pagination = pagination.concat({});
        }
        setFaculty(
          response.data.result.map((item) => {
            return {
              label: item.facuty_name_th + "/" + item.major_name_th,
              value: item.id.toString(),
            };
          })
        );
      }
    } catch (error) {
      throw error;
    }
  }

  var dd = moment.locale("th");

  return (
    <View style={styles.container}>
      <HeaderAdmin
        onPress={() => {
          setOpenMenu((val) => !val);
        }}
        navigation={navigation}
        headerUniversity
        setPage={setPage}
      />

      <View style={{ width: "100%", flexDirection: "row", height: "100%" }}>
        {openMenu && (
          <View //menu
            style={{
              width: "13%",

              minWidth: 220,
              height: "100%",
              backgroundColor: "#444444",
              position: "absolute",
              zIndex: 99,
            }}
          >
            <View style={{ height: "80%" }}>
              {(token?.role == "SUPERADMIN" ||
                token?.role == "GENERALADMIN") && (
                <ButtonMenuSuperAdmin
                  p1
                  color
                  border
                  onPress={() => {
                    setOpenMenu(false);
                    navigation.navigate("UniversityCourseSA");
                  }}
                />
              )}
              {(token?.role == "SUPERADMIN" ||
                token?.role == "GENERALADMIN") && (
                <ButtonMenuSuperAdmin
                  p2
                  onPress={() => {
                    setOpenMenu(false);
                    navigation.navigate("GeneralCourseSA");
                  }}
                />
              )}
              {(token?.role == "SUPERADMIN" ||
                token?.role == "GENERALADMIN") && (
                <ButtonMenuSuperAdmin
                  p3
                  onPress={() => {
                    setOpenMenu(false);
                    navigation.navigate("AddAdminSA");
                  }}
                />
              )}
              {(token?.role == "ACCOUNTING" || token?.role == "SUPERADMIN") && (
                <ButtonMenuSuperAdmin
                  p4
                  onPress={() => {
                    setOpenMenu(false);
                    navigation.navigate("ReportSA");
                  }}
                />
              )}
              {(token?.role == "SUPERADMIN" ||
                token?.role == "GENERALADMIN") && (
                <ButtonMenuSuperAdmin
                  p5
                  onPress={() => {
                    setOpenMenu(false);
                    navigation.navigate("SlideBannerSA");
                  }}
                />
              )}
              {(token?.role == "SUPERADMIN" ||
                token?.role == "GENERALADMIN") && (
                <ButtonMenuSuperAdmin
                  p6
                  onPress={() => {
                    setOpenMenu(false);
                    navigation.navigate("Discount");
                  }}
                />
              )}
              {(token?.role == "SUPERADMIN" ||
                token?.role == "GENERALADMIN") && (
                <ButtonMenuSuperAdmin
                  p7
                  onPress={() => {
                    setOpenMenu(false);
                    navigation.navigate("Account");
                  }}
                />
              )}
            </View>
            <ProfileAdmin />
          </View>
        )}
        {size.width >= 1280 && (
          <View //menu
            style={{
              width: "13%",

              minWidth: 220,
              height: "100%",
              backgroundColor: "#444444",
            }}
          >
            <View style={{ height: "80%" }}>
              {(token?.role == "SUPERADMIN" ||
                token?.role == "GENERALADMIN") && (
                <ButtonMenuSuperAdmin
                  p1
                  color
                  border
                  onPress={() => {
                    navigation.navigate("UniversityCourseSA");
                  }}
                />
              )}
              {(token?.role == "SUPERADMIN" ||
                token?.role == "GENERALADMIN") && (
                <ButtonMenuSuperAdmin
                  p2
                  onPress={() => {
                    navigation.navigate("GeneralCourseSA");
                  }}
                />
              )}
              {(token?.role == "SUPERADMIN" ||
                token?.role == "GENERALADMIN") && (
                <ButtonMenuSuperAdmin
                  p3
                  onPress={() => {
                    navigation.navigate("AddAdminSA");
                  }}
                />
              )}
              {(token?.role == "ACCOUNTING" || token?.role == "SUPERADMIN") && (
                <ButtonMenuSuperAdmin
                  p4
                  onPress={() => {
                    navigation.navigate("ReportSA");
                  }}
                />
              )}
              {(token?.role == "SUPERADMIN" ||
                token?.role == "GENERALADMIN") && (
                <ButtonMenuSuperAdmin
                  p5
                  onPress={() => {
                    navigation.navigate("SlideBannerSA");
                  }}
                />
              )}
              {(token?.role == "SUPERADMIN" ||
                token?.role == "GENERALADMIN") && (
                <ButtonMenuSuperAdmin
                  p6
                  onPress={() => {
                    navigation.navigate("Discount");
                  }}
                />
              )}
              {(token?.role == "SUPERADMIN" ||
                token?.role == "GENERALADMIN") && (
                <ButtonMenuSuperAdmin
                  p7
                  onPress={() => {
                    navigation.navigate("Account");
                  }}
                />
              )}
            </View>
            <ProfileAdmin />
          </View>
        )}
        <ScrollView style={{ width: "100%" }}>
          {page == 0 && (
            <View
              style={[
                styles.viewDetail,
                {
                  width: "100%",
                  paddingHorizontal: size.width < 500 ? 20 : 55,
                },
              ]}
            >
              {!subjectDetail ? (
                <View>
                  <View style={{ flexDirection: "row", marginTop: 10 }}>
                    <TouchableOpacity onPress={() => navigation.goBack()}>
                      <Text
                        style={{
                          fontSize: 23,
                          fontFamily: "Prompt",
                          color: "#808D93",
                        }}
                      >
                        คอร์สมหาวิทยาลัย
                      </Text>
                    </TouchableOpacity>
                    <Text
                      style={{
                        fontSize: 23,
                        fontFamily: "Prompt",
                        color: "#808D93",
                        marginHorizontal: 18,
                      }}
                    >
                      /
                    </Text>
                    <Text
                      style={{
                        fontSize: 23,
                        fontFamily: "Prompt",
                        color: "#0D88F0",
                      }}
                    >
                      คอร์สที่สอน
                    </Text>
                  </View>
                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      marginTop: 39,
                    }}
                  >
                    <TextInput
                      autoComplete="off"
                      value={editName.university_name_th}
                      onChangeText={(text) => {
                        setEditName({ ...editName, university_name_th: text });
                      }}
                      placeholder=""
                      placeholderTextColor="#444444"
                      style={{
                        width: width < 1600 ? "60%" : "50%",
                        height: 64,
                        borderRadius: 4,
                        backgroundColor: "#EFEFF3",
                        fontSize: width < 1600 ? 20 : 25,
                        fontFamily: "Prompt",
                        color: "#444444",
                        paddingHorizontal: 23,
                      }}
                    />

                    <TouchableOpacity
                      onPress={authActionPutUniversity}
                      style={{ marginLeft: -50 }}
                    >
                      <FontAwesome name="edit" size={40} color="#444444" />
                    </TouchableOpacity>
                  </View>
                  <View
                    style={{
                      flexDirection: size.width < 500 ? "column" : "row",
                      marginTop: size.width < 500 ? 10 : 46,
                    }}
                  >
                    {degreeList.map((data, index) => (
                      <View>
                        <TouchableOpacity
                          key={data}
                          style={{
                            flexDirection: "row",
                            alignItems: "center",
                            marginLeft:
                              index == 0
                                ? 0
                                : size.width < 500
                                ? 0
                                : width * 0.05,
                          }}
                          onPress={degreeChangeHandler.bind(this, index)}
                        >
                          <MaterialIcons
                            name={
                              index === deGreeIndex
                                ? "radio-button-checked"
                                : "radio-button-unchecked"
                            }
                            size={30}
                            color={
                              index === deGreeIndex ? "#0D88F0" : "#808D93"
                            }
                          />
                          <Text
                            style={[
                              styles.text23,
                              {
                                color:
                                  index === deGreeIndex ? "#0D88F0" : "#808D93",
                              },
                            ]}
                          >
                            {data}
                          </Text>
                        </TouchableOpacity>
                      </View>
                    ))}
                  </View>
                  <RNPickerSelect
                    placeholder={placeholder}
                    onValueChange={(val) => {
                      labe(val);
                      if (val != null && val != "คณะทั้งหมด") {
                        getUniversity1(token, val);
                      } else {
                        getUniversity(token);
                      }
                    }}
                    value={value}
                    items={faculty}
                    style={{
                      inputWeb: {
                        borderColor: "#0D88F0",
                        width: size.width < 500 ? "60%" : "25%",
                        height: 32,
                        backgroundColor: "#FFFFFF",
                        color: "#0D88F0",
                        fontFamily: "Prompt",
                        fontSize: 16,
                        paddingHorizontal: 10,
                        marginVertical: 33,
                      },
                    }}
                  />
                  <ScrollView horizontal>
                    <View
                      style={{
                        width:
                          size.width < 500
                            ? size.width * 2.5
                            : size.width < 1280
                            ? size.width * 1.0
                            : size.width * 0.78,
                        // minHeight: height * 2.5,
                      }}
                    >
                      <View style={styles.viewSubject}>
                        <Text
                          style={[
                            styles.text16B,
                            { paddingLeft: 67, width: "30%" },
                          ]}
                        >
                          ชื่อวิชา
                        </Text>
                        <Text style={[styles.text16B, { width: "20%" }]}>
                          คณะ
                        </Text>
                        <Text style={[styles.text16B, { width: "12%" }]}>
                          สาขา
                        </Text>
                        <Text style={[styles.text16B, { width: "15%" }]}>
                          วันที่สร้าง
                        </Text>
                        <Text
                          style={[
                            styles.text16B,
                            { paddingLeft: 67, width: "23%" },
                          ]}
                        >
                          อาจารย์ที่สร้างคอร์ส
                        </Text>
                      </View>
                      <FlatList
                        numColumns={1}
                        data={course.result}
                        renderItem={({ item, index }) => {
                          return (
                            <TouchableOpacity
                              onPress={() => {
                                setCourseName1(item.title);
                                setCourseName2(item);
                                setSubjectDetail(true);
                              }}
                              style={{
                                flexDirection: "row",
                                justifyContent: "space-between",
                                borderBottomWidth: 1,
                                borderBottomColor: "#E5E5E5",
                                height: 50,
                                alignItems: "center",
                              }}
                            >
                              <Text
                                style={[
                                  styles.text16B,
                                  { paddingLeft: 67, width: "30%" },
                                ]}
                              >
                                {item.title}
                              </Text>
                              <Text style={[styles.text16B, { width: "20%" }]}>
                                {item.faculty != null &&
                                  item.faculty.facuty_name_th}
                              </Text>
                              <Text style={[styles.text16B, { width: "12%" }]}>
                                {item.faculty != null &&
                                  item.faculty.major_name_th}
                              </Text>
                              <Text style={[styles.text16B, { width: "15%" }]}>
                                {moment(item.updatedAt)
                                  .add(543, "year")
                                  .format("DD MMMM YYYY")}
                              </Text>
                              <Text
                                style={[
                                  styles.text16B,
                                  { paddingLeft: 67, width: "23%" },
                                ]}
                              >
                                {item.teacher.firstName} {item.teacher.lastName}
                              </Text>
                            </TouchableOpacity>
                          );
                        }}
                      />
                    </View>
                  </ScrollView>
                </View>
              ) : (
                <Subject
                  courseName1={courseName1}
                  courseName2={courseName2}
                  ongoMain={() => {
                    navigation.goBack();
                  }}
                  ongoBack={() => {
                    setSubjectDetail(false);
                  }}
                />
              )}
            </View>
          )}
          {page == 3 && (
            <View
              style={[
                styles.viewDetail,
                { paddingHorizontal: size.width < 500 ? 20 : 55 },
              ]}
            >
              {!teacherDetail ? (
                <Teacher
                  onPress={() => {
                    setTeacherDetail(true);
                  }}
                  university_id={data.id}
                  navigation={navigation}
                  setName={setName}
                  setlastName={setLastName}
                  setAllResultTeacher={setAllResultTeacher}
                />
              ) : (
                <TeacherDetail
                  name={name}
                  lastName={lastName}
                  allResultTeacher={allResultTeacher}
                  ongoMain={() => {
                    navigation.goBack();
                  }}
                  ongoBack={() => {
                    setTeacherDetail(false);
                  }}
                />
              )}
            </View>
          )}
          {page == 2 && (
            <View
              style={[
                styles.viewDetail,
                { paddingHorizontal: size.width < 500 ? 20 : 55 },
              ]}
            >
              {!studentDetail ? (
                <Student
                  onPress={() => {
                    setStudentDetail(true);
                  }}
                  university_id={data.id}
                  setAllResult={setAllResult}
                  setNameStudent={setNameStudent}
                  setLastNameStudent={setLastNameStudent}
                />
              ) : (
                <StudentDetail
                  ongoBack={() => {
                    setStudentDetail(false);
                  }}
                  allResult={allResult}
                  nameStudent={nameStudent}
                  lastNameStudent={lastNameStudent}
                />
              )}
            </View>
          )}
          {page == 1 && (
            <View
              style={[
                styles.viewDetail,
                { paddingHorizontal: size.width < 500 ? 20 : 55 },
              ]}
            >
              <Faculty
                university_id={data.id}
                ongoBack={() => {
                  navigation.goBack();
                }}
              />
            </View>
          )}
          {page == 4 && (
            <View
              style={[
                styles.viewDetail,
                { paddingHorizontal: size.width < 500 ? 20 : 55 },
              ]}
            >
              <Information
                token={token}
                major={major}
                university_id={data.id}
                university_name={data.university_name_th}
                ongoBack={() => {
                  navigation.goBack();
                }}
              />
            </View>
          )}
        </ScrollView>
      </View>
    </View>
  );
}
const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: "100%",
    backgroundColor: "#FFFFFF",
  },
  text16B: {
    fontFamily: "Prompt",
    fontSize: 16,
    color: "#000000",
  },
  viewSubject: {
    width: "100%",
    height: 51,
    backgroundColor: "#E5E5E5",
    marginTop: 35,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  text23: {
    fontSize: 23,
    fontFamily: "Prompt",
    color: "#808D93",
  },
  viewDetail: {
    width: "100%",
    height: "100%",
    paddingHorizontal: 55,
    paddingVertical: 28,
    paddingBottom: height * 0.1,
  },
});
