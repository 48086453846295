import React, { useEffect, useState } from "react";
import {
  View,
  Text,
  Image,
  Dimensions,
  Animated,
  TouchableOpacity,
  StyleSheet,
  ScrollView,
  TextInput,
  FlatList,
} from "react-native";
import {
  MaterialCommunityIcons,
  FontAwesome,
  FontAwesome5,
  MaterialIcons,
} from "@expo/vector-icons";
import RNPickerSelect from "react-native-picker-select";
import WorkReceived from "./workReceived";
import { apiservice } from "../../service";
import { useRecoilState, useRecoilValue } from "recoil";
import { pageWork, tokenState } from "../../reducer/recoil";
import moment from "moment";
import { useWindowSize } from "../components/responsive";
const { width, height } = Dimensions.get("window");
let selectData = ["งานที่มอบหมาย", "ข้อสอบอัตนัย"];
export default function work({ navigation, onPress, onPress1, setWork }) {
  const [page, setPage] = useRecoilState(pageWork);
  const [search, setSearch] = useState("");
  const [search1, setSearch1] = useState("");
  const token = useRecoilValue(tokenState);
  const placeholder = {
    label: "สถานะ ..",
    value: null,
    color: "#FCBDA6",
  };
  const placeholder1 = {
    label: "ค้นหาคอร์ส ..",
    value: null,
    color: "#FCBDA6",
  };
  const [status, setStatus] = useState([
    { label: "status1", value: "status1" },
    { label: "status2", value: "status2" },
  ]);
  const [course, setCourse] = useState([
    { label: "course1", value: "course1" },
    { label: "course2", value: "course2" },
  ]);
  const [DATA1, setData1] = useState([]);
  const [DATA, setData] = useState([]);

  useEffect(() => {
    getallworkByteacher();
    getallwritingByteacher();
  }, []);

  async function getallworkByteacher() {
    const res = await apiservice({
      path: "/start-course/getallworkByteacher",
      method: "get",
      token: token?.accessToken,
    });

    if (res.status == 200) {
      setData(res.data.results);
    }
  }

  async function getallwritingByteacher() {
    const res = await apiservice({
      path: "/start-course/getallwritingByteacher",
      method: "get",
      token: token?.accessToken,
    });

    if (res.status == 200) {
      setData1(res.data.results);
    }
  }
  const size = useWindowSize();
  return (
    <View style={{}}>
      <View
        style={{
          paddingHorizontal: 50,
          paddingTop: 45,
        }}
      >
        <FlatList
          horizontal
          data={selectData}
          showsHorizontalScrollIndicator={false}
          style={{}}
          renderItem={({ item, index }) => {
            return (
              <TouchableOpacity
                style={{
                  backgroundColor: page == index ? "#0D88F0" : "#FFFFFF",
                  width: "100%",
                  maxWidth: 262,
                  height: 60,
                  justifyContent: "center",
                  borderWidth: 1,
                  borderColor: "#0D88F0",
                }}
                onPress={() => {
                  setPage(index);
                }}
              >
                <Text
                  style={{
                    fontFamily: "Prompt",
                    fontSize: size.width < 750 ? 14 : 20,
                    textAlign: "center",
                    width:
                      (size.width >= 1280 && size.width / 8) ||
                      (size.width < 1280 &&
                        size.width >= 500 &&
                        size.width / 5) ||
                      (size.width < 500 && size.width / 3.3),

                    color: page == index ? "#FFFFFF" : "#0D88F0",
                  }}
                >
                  {item}
                </Text>
              </TouchableOpacity>
            );
          }}
        />
        {page == 0 && (
          <View style={{ minHeight: 1320 }}>
            <View
              style={{
                flexDirection: size.width < 750 ? "column" : "row",
                marginTop: 20,
              }}
            >
              <TextInput
                onChangeText={(text) => {
                  setSearch(text);
                }}
                autoComplete="off"
                style={[
                  styles.inputSearch,
                  { fontSize: size.width < 750 ? 14 : 20 },
                ]}
                placeholder="ค้นหาชื่อผู้ส่ง .."
                placeholderTextColor="#646464"
              />
              {/* <RNPickerSelect
                placeholder={placeholder}
                onValueChange={(val) => {}}
                items={status}
                style={{
                  inputWeb: {
                    width: 231,
                    height: 40,
                    borderWidth: 1,
                    borderColor: "#FCBDA6",
                    backgroundColor: "#FFFFFF",
                    color: "#FCBDA6",
                    fontFamily: "Prompt",
                    fontSize: size.width < 750 ? 14 : 20,
                    paddingLeft: 15,
                    marginHorizontal: size.width < 750 ? 0 : 15,
                    marginTop: size.width < 750 && 20,
                  },
                }}
              />
              <RNPickerSelect
                placeholder={placeholder1}
                onValueChange={(val) => {}}
                items={course}
                style={{
                  inputWeb: {
                    width: 231,
                    height: 40,
                    borderWidth: 1,
                    borderColor: "#FCBDA6",
                    backgroundColor: "#FFFFFF",
                    color: "#FCBDA6",
                    fontFamily: "Prompt",
                    fontSize: size.width < 750 ? 14 : 20,
                    paddingLeft: 15,
                    marginTop: size.width < 750 && 20,
                  },
                }}
              /> */}
            </View>
            {DATA.filter((item) => {
              return item.status == false;
            }).length > 0 && (
              <View style={styles.viewTitle}>
                <Text
                  style={[
                    size.width < 750
                      ? stylesResponsive.textTitle
                      : styles.textTitle,
                  ]}
                >
                  ชื่อ
                </Text>
                <Text
                  style={[
                    size.width < 750
                      ? stylesResponsive.textTitle
                      : styles.textTitle,
                    { width: "40%" },
                  ]}
                >
                  คอร์ส
                </Text>
                <Text
                  style={[
                    size.width < 750
                      ? stylesResponsive.textTitle
                      : styles.textTitle,
                  ]}
                >
                  ผู้ส่ง
                </Text>
                <Text
                  style={[
                    size.width < 750
                      ? stylesResponsive.textTitle
                      : styles.textTitle,
                  ]}
                >
                  วันที่ส่ง
                </Text>
                <Text
                  style={[
                    size.width < 750
                      ? stylesResponsive.textTitle
                      : styles.textTitle,
                    { textAlign: "center" },
                  ]}
                >
                  สถานะ
                </Text>
              </View>
            )}
            <FlatList
              numColumns={1}
              data={
                DATA.filter((item) => {
                  return item.status == false;
                })
                // .filter((item) => {
                //   return (
                //     item?.student?.user_accounts?.firstName?.includes(search) ||
                //     item.student.user_accounts?.email.includes(search)
                //   );
                // })
              }
              style={{}}
              renderItem={({ item, index }) => {
                return (
                  <View
                    style={[
                      styles.viewDetail,
                      {
                        borderTopWidth: index == 0 && 1,
                      },
                    ]}
                  >
                    <TouchableOpacity
                      disabled={item.status ? true : false}
                      onPress={() => {
                        onPress();
                        setWork(item);
                      }}
                      style={styles.buttonDetail}
                    >
                      <Text
                        numberOfLines={1}
                        style={[
                          size.width < 750
                            ? stylesResponsive.textDetail
                            : styles.textDetail,
                        ]}
                      >
                        {item?.work?.name}
                      </Text>
                      <Text
                        numberOfLines={1}
                        style={[
                          size.width < 750
                            ? stylesResponsive.textDetail
                            : styles.textDetail,
                          ,
                          { width: "40%" },
                        ]}
                      >
                        {item.course.title}
                      </Text>
                      {item.student.user_accounts?.firstName != null ? (
                        <Text
                          numberOfLines={1}
                          style={[
                            size.width < 750
                              ? stylesResponsive.textDetail
                              : styles.textDetail,
                          ]}
                        >
                          {item.student.user_accounts?.firstName + " "}
                          {item.student.user_accounts?.lastName}
                        </Text>
                      ) : (
                        <Text
                          numberOfLines={1}
                          style={[
                            size.width < 750
                              ? stylesResponsive.textDetail
                              : styles.textDetail,
                          ]}
                        >
                          {item.student.user_accounts?.email}
                        </Text>
                      )}
                      <Text
                        style={[
                          size.width < 750
                            ? stylesResponsive.textDetail
                            : styles.textDetail,
                        ]}
                      >
                        {moment(item.createdAt).format("DD-MM-YYYY")}
                      </Text>
                      <Text
                        style={[
                          size.width < 750
                            ? stylesResponsive.textDetail
                            : styles.textDetail,
                          ,
                          { textAlign: "center" },
                        ]}
                      >
                        {item.status ? "อนุมัติ" : "รออนุมัติ"}
                      </Text>
                    </TouchableOpacity>
                  </View>
                );
              }}
            />
          </View>
        )}
        {page == 1 && (
          <View style={{ minHeight: 1320 }}>
            <View
              style={{
                flexDirection: size.width < 750 ? "column" : "row",
                marginTop: 20,
              }}
            >
              <TextInput
                onChangeText={(text) => {
                  setSearch1(text);
                }}
                autoComplete="off"
                style={[
                  styles.inputSearch,
                  { fontSize: size.width < 750 ? 14 : 20 },
                ]}
                placeholder="ค้นหาชื่อผู้ส่ง .."
                placeholderTextColor="#646464"
              />
              {/* <RNPickerSelect
                placeholder={placeholder}
                onValueChange={(val) => {}}
                items={status}
                style={{
                  inputWeb: {
                    width: 231,
                    height: 40,
                    borderWidth: 1,
                    borderColor: "#FCBDA6",
                    backgroundColor: "#FFFFFF",
                    color: "#FCBDA6",
                    fontFamily: "Prompt",
                    fontSize: size.width < 750 ? 14 : 20,
                    paddingLeft: 15,
                    marginHorizontal: size.width < 750 ? 0 : 15,
                    marginTop: size.width < 750 && 20,
                  },
                }}
              />
              <RNPickerSelect
                placeholder={placeholder1}
                onValueChange={(val) => {}}
                items={course}
                style={{
                  inputWeb: {
                    width: 231,
                    height: 40,
                    borderWidth: 1,
                    borderColor: "#FCBDA6",
                    backgroundColor: "#FFFFFF",
                    color: "#FCBDA6",
                    fontFamily: "Prompt",
                    fontSize: size.width < 750 ? 14 : 20,
                    paddingLeft: 15,
                    marginTop: size.width < 750 && 20,
                  },
                }}
              /> */}
            </View>
            {DATA1.filter((item) => {
              return item.status == false;
            }).length > 0 && (
              <View style={styles.viewTitle}>
                <Text
                  style={[
                    size.width < 750
                      ? stylesResponsive.textTitle
                      : styles.textTitle,
                  ]}
                >
                  ชื่อ
                </Text>
                <Text
                  style={[
                    size.width < 750
                      ? stylesResponsive.textTitle
                      : styles.textTitle,
                    { width: "40%" },
                  ]}
                >
                  คอร์ส
                </Text>
                <Text
                  style={[
                    size.width < 750
                      ? stylesResponsive.textTitle
                      : styles.textTitle,
                  ]}
                >
                  ผู้ส่ง
                </Text>
                <Text
                  style={[
                    size.width < 750
                      ? stylesResponsive.textTitle
                      : styles.textTitle,
                  ]}
                >
                  วันที่ส่ง
                </Text>
                <Text
                  style={[
                    size.width < 750
                      ? stylesResponsive.textTitle
                      : styles.textTitle,
                    { textAlign: "center" },
                  ]}
                >
                  สถานะ
                </Text>
              </View>
            )}
            <FlatList
              numColumns={1}
              data={DATA1.filter((item) => {
                return item.status == false;
              }).filter((item) => {
                if(!search1){
                  return item
                }

                return (
                  item?.student?.firstName?.includes(search1) ||
                  item?.student?.email?.includes(search1)
                );
              })}
              style={{}}
              renderItem={({ item, index }) => {
                return (
                  <View
                    style={[
                      styles.viewDetail,
                      {
                        borderTopWidth: index == 0 && 1,
                      },
                    ]}
                  >
                    <TouchableOpacity
                      disabled={item.status ? true : false}
                      onPress={() => {
                        onPress1();
                        setWork(item);
                      }}
                      style={styles.buttonDetail}
                    >
                      <Text
                        numberOfLines={1}
                        style={[
                          size.width < 750
                            ? stylesResponsive.textDetail
                            : styles.textDetail,
                        ]}
                      >
                        {item?.work?.name}
                      </Text>
                      <Text
                        numberOfLines={1}
                        style={[
                          size.width < 750
                            ? stylesResponsive.textDetail
                            : styles.textDetail,
                          ,
                          { width: "40%" },
                        ]}
                      >
                        {item.course.title}
                      </Text>
                      {item.student.firstName != null ? (
                        <Text
                          numberOfLines={1}
                          style={[
                            size.width < 750
                              ? stylesResponsive.textDetail
                              : styles.textDetail,
                          ]}
                        >
                          {item.student.firstName + " "}
                          {item.student.lastName}
                        </Text>
                      ) : (
                        <Text
                          numberOfLines={1}
                          style={[
                            size.width < 750
                              ? stylesResponsive.textDetail
                              : styles.textDetail,
                          ]}
                        >
                          {item?.student?.email ?? `user-${item?.student?.id + 100000}`}
                        </Text>
                      )}
                      <Text
                        style={[
                          size.width < 750
                            ? stylesResponsive.textDetail
                            : styles.textDetail,
                        ]}
                      >
                        {moment(item.createdAt).format("DD-MM-YYYY")}
                      </Text>
                      <Text
                        style={[
                          size.width < 750
                            ? stylesResponsive.textDetail
                            : styles.textDetail,
                          ,
                          { textAlign: "center" },
                        ]}
                      >
                        {item.status ? "อนุมัติ" : "รออนุมัติ"}
                      </Text>
                    </TouchableOpacity>
                  </View>
                );
              }}
            />
          </View>
        )}
      </View>
    </View>
  );
}
const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: "100%",
    backgroundColor: "#FFFFFF",
  },
  textTitle: {
    fontFamily: "Prompt",
    fontSize: 20,
    color: "#0B2051",
    width: "15%",
  },
  textDetail: {
    fontFamily: "Prompt",
    fontSize: 16,
    color: "#0B2051",
    width: "15%",
  },
  inputSearch: {
    maxWidth: 400,
    width: "100%",
    height: 40,
    borderWidth: 1,
    borderColor: "#D9D9D9",
    paddingHorizontal: 15,
    fontSize: 20,
    fontFamily: "Prompt",
    color: "#646464",
  },
  viewTitle: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 20,
    marginBottom: 12,
  },
  viewDetail: {
    borderTopColor: "#BBBBBB",
    borderBottomColor: "#BBBBBB",
    borderBottomWidth: 1,
    paddingVertical: 25,
  },
  buttonDetail: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
});
const stylesResponsive = StyleSheet.create({
  textTitle: {
    fontFamily: "Prompt",
    fontSize: 12,
    color: "#0B2051",
    width: "15%",
  },
  textDetail: {
    fontFamily: "Prompt",
    fontSize: 10,
    color: "#0B2051",
    width: "15%",
  },
});
