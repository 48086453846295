import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  Image,
  Dimensions,
  Animated,
  TouchableOpacity,
  StyleSheet,
  ScrollView,
  TextInput,
  FlatList,
} from "react-native";
import {
  MaterialCommunityIcons,
  MaterialIcons,
  Feather,
  Ionicons,
  FontAwesome,
  Entypo,
} from "@expo/vector-icons";
import { useRecoilState } from "recoil";
import { tokenState } from "../../reducer/recoil";
import { apiservice } from "../../service";
import { useWindowSize } from "./responsive";
const { width, height } = Dimensions.get("window");
export default function headerAdmin({
  navigation,
  headerUniversity,
  setPage,
  headerGeneral,
  headerReport,
  onPress,
}) {
  const [profile, setProfile] = useState("");
  const [token, setToken] = useRecoilState(tokenState);
  const [CoursePage, setCoursePage] = useState(0);

  useEffect(() => {
    if (token) {
      getProfile(token);
    }
  }, [token]);

  async function getProfile(token) {
    try {
      const response = await apiservice({
        path: "/authen/user",
        method: "get",
        token: token?.accessToken,
      });
      setProfile(
        response.data !== undefined && response?.data?.result?.username
      );
    } catch (error) {
      throw error;
    }
  }
  function Logout() {
    setTimeout(() => {
      setToken("");
      window.location.replace("Home");
    }, 1000);
  }
  const DATA1 = [
    { name: "คอร์สที่สอน" },
    { name: "คณะ" },
    { name: "นักศึกษา" },
    { name: "อาจารย์ผู้สอน" },
    { name: "ข้อมูล" },
  ];
  const DATA2 = [
    { name: "คอร์สทั้งหมด" },
    { name: "ผู้สอน" },
    { name: "คำร้องขอเป็นผู้สอน" },
    { name: "ผู้เรียน" },
    { name: "หมวดหมู่" },
    { name: "คำร้องเพิ่มคอร์สเรียน" },
  ];
  const DATA3 = [
    { name: "ผู้สอน(รอบบิล)" },
    { name: "ผู้สอน(รวมทั้งหมด)" },
    { name: "ผู้เรียน" },
  ];
  const size = useWindowSize();
  return (
    <View
      style={[styles.header, { paddingHorizontal: size.width < 500 ? 20 : 70 }]}
    >
      <View style={{ flexDirection: "row", alignItems: "center" }}>
        {size.width < 1280 && (
          <TouchableOpacity onPress={onPress} style={{ marginRight: 20 }}>
            <Entypo name="menu" size={30} color="black" />
          </TouchableOpacity>
        )}
        <TouchableOpacity
          onPress={() => {
            navigation.navigate("Home");
          }}
        >
          <Image
            resizeMode="stretch"
            style={{
              width: size.width < 500 ? 69 : 80,
              height: size.width < 500 ? 45 : 50,
            }}
            source={require("../img/logo.png")}
          />
        </TouchableOpacity>
      </View>
      <FlatList
        horizontal
        style={{ marginLeft: 40 }}
        showsHorizontalScrollIndicator={false}
        data={
          (headerUniversity && DATA1) ||
          (headerGeneral && DATA2) ||
          (headerReport && DATA3)
        }
        renderItem={({ item, index }) => {
          return (
            <TouchableOpacity
              style={{
                borderTopWidth: CoursePage == index ? 3 : 0,
                borderTopColor: "#0D88F0",
                marginLeft: size.width < 1400 ? width * 0.04 : width * 0.05,
                height: 70,
                justifyContent: "center",
              }}
              onPress={() => {
                setPage(index);
                setCoursePage(index);
              }}
            >
              <Text
                style={[
                  {
                    fontSize: size.width < 1400 ? 14 : 16,
                    fontFamily: "Prompt",
                    color: CoursePage == index ? "#0D88F0" : "#A2A2A2",
                  },
                ]}
              >
                {item.name}
              </Text>
            </TouchableOpacity>
          );
        }}
      />
      <View style={{ flexDirection: "row" }}>
        {size.width >= 500 && (
          <TouchableOpacity style={{ marginHorizontal: 30 }}>
            <MaterialIcons
              name="notifications-none"
              size={30}
              color="#272D3B"
            />
          </TouchableOpacity>
        )}
        <TouchableOpacity
          onPress={Logout}
          style={{ marginHorizontal: size.width < 500 ? 5 : 30 }}
        >
          <MaterialIcons name="logout" size={30} color="#272D3B" />
        </TouchableOpacity>
      </View>
    </View>
  );
}
const styles = StyleSheet.create({
  header: {
    width: "100%",
    height: 70,
    backgroundColor: "#FFFFFF",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingHorizontal: 70,
    shadowColor: "#707070",
    shadowOffset: { width: -2, height: 2 },
    shadowRadius: 10,
  },
});
