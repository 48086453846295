import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  Image,
  Dimensions,
  Animated,
  TouchableOpacity,
  StyleSheet,
  ScrollView,
  TextInput,
  FlatList,
} from "react-native";
import {
  MaterialCommunityIcons,
  MaterialIcons,
  Feather,
  Ionicons,
  FontAwesome,
} from "@expo/vector-icons";
import HeaderAdmin from "../../components/headerAdmin";
import ButtonMenuSuperAdmin from "../../components/buttonMenuSuperAdmin";
import ProfileAdmin from "../../components/profileAdmin";
import { useRecoilState } from "recoil";
import { tokenState } from "../../../reducer/recoil";
import { useIsFocused } from "@react-navigation/native";
import { apiservice } from "../../../service";
import moment from "moment";
import Modal from "modal-react-native-web";
import {
  authActionCreateBanner,
  deleteSlideheader1,
  deleteSlideheader2,
} from "../../../action/authAction";
import * as DocumentPicker from "expo-document-picker";
import { useWindowSize } from "../../components/responsive";
const { width, height } = Dimensions.get("window");
export default function main({ navigation, route }) {
  const [token, setToken] = useRecoilState(tokenState);
  const [modalDelItem, setModalDelItem] = useState(false);
  const [modalDelItem2, setModalDelItem2] = useState(false);
  const [modalSave, setModalSave] = useState(false);
  const [imgHeader1, setImgHeader1] = useState();
  const [img, setImg] = useState();
  const [data, setData] = useState([]);
  const [id, setId] = useState();
  const [id2, setId2] = useState();
  const size = useWindowSize();
  const [openMenu, setOpenMenu] = useState(false);
  useEffect(() => {
    if (token) {
      getSlideBanner(token);
    }
  }, [token]);
  const [state, setstate] = useState({
    TYPE: "Header1",
    img_list: [],
  });
  const [state2, setstate2] = useState({
    TYPE: "Header2",
    img_list: [],
  });
  async function getSlideBanner(token) {
    try {
      const response = await apiservice({
        path: "/banner/getslidebanner",
        method: "get",
        token: token?.accessToken,
      });
      if (response.status == 200) {
        setData(response.data);

        // setImgHeader1(response.data[0].img_list.img1);
        if (response.data[0] != null) {
          setstate({
            ...state,
            img_list: response.data.filter((item) => {
              return item.TYPE == "Header1";
            })[0].img_list,
          });
        }
        if (response.data[1] != null) {
          setstate2({
            ...state2,
            img_list: response.data.filter((item) => {
              return item.TYPE == "Header2";
            })[0].img_list,
          });
        }
      }
    } catch (error) {
      throw error;
    }
  }

  async function pickup(params) {
    let result = await DocumentPicker.getDocumentAsync({ type: "image/*" });
    const response = await apiservice({
      path: "/image/createpdf",
      method: "post",
      body: {
        name: result.name.replace(".png", ".jpg", ""),
        base64: result.uri,
      },
    });
    setstate((val) => ({
      ...val,
      img_list: val.img_list.map((items, indexs) => {
        if (params == indexs) {
          return {
            ...items,
            img1: response.data.imageRefId.replace(".png", ""),
          };
        } else {
          return items;
        }
      }),
    }));

    // setImg(response.data.imageRefId.replace(".png", ""));

    //getSlideBanner(token);
    // // window.location.reload(false);
  }
  async function pickup1(params) {
    let result = await DocumentPicker.getDocumentAsync({ type: "image/*" });
    const response = await apiservice({
      path: "/image/createpdf",
      method: "post",
      body: {
        name: result.name.replace(".png", ".jpg", ""),
        base64: result.uri,
      },
    });
    setstate2((val) => ({
      ...val,
      img_list: val.img_list.map((items, indexs) => {
        if (params == indexs) {
          return {
            ...items,
            img1: response.data.imageRefId.replace(".png", ""),
          };
        } else {
          return items;
        }
      }),
    }));

    // setImg(response.data.imageRefId.replace(".png", ""));

    //getSlideBanner(token);
    // // window.location.reload(false);
  }
  async function addBanner() {
    setstate((val) => ({
      ...val,
      img_list: val.img_list.concat({
        img1: "",
        ขนาด: "1920*545",
        url: "",
      }),
    }));
  }
  async function addBanner2() {
    setstate2((val) => ({
      ...val,
      img_list: val.img_list.concat({
        img1: "",
        ขนาด: "1920*475",
        url: "",
      }),
    }));
  }
  async function save() {
    const edit = await authActionCreateBanner({
      state,
      token: token?.accessToken,
    });
    setModalSave(true);
    setTimeout(() => {
      setModalSave(false);
    }, 2000);
  }
  async function save2() {
    const edit = await authActionCreateBanner({
      state: state2,
      token: token?.accessToken,
    });
    setModalSave(true);
    setTimeout(() => {
      setModalSave(false);
    }, 2000);
  }

  async function Delete() {
    const Delete = await deleteSlideheader1({
      token: token?.accessToken,
      id: id,
    });
  }
  async function Delete2() {
    const Delete = await deleteSlideheader2({
      token: token?.accessToken,
      id: id2,
    });
  }

  return (
    <View style={styles.container}>
      <HeaderAdmin
        onPress={() => {
          setOpenMenu((val) => !val);
        }}
        navigation={navigation}
      />
      <View style={{ width: "100%", flexDirection: "row", height: "100%" }}>
        {openMenu && (
          <View //menu
            style={{
              width: "13%",

              minWidth: 220,
              height: "100%",
              backgroundColor: "#444444",
              position: "absolute",
              zIndex: 99,
            }}
          >
            <View style={{ height: "80%" }}>
              {(token?.role == "SUPERADMIN" ||
                token?.role == "GENERALADMIN") && (
                <ButtonMenuSuperAdmin
                  p1
                  onPress={() => {
                    setOpenMenu(false);
                    navigation.navigate("UniversityCourseSA");
                  }}
                />
              )}
              {(token?.role == "SUPERADMIN" ||
                token?.role == "GENERALADMIN") && (
                <ButtonMenuSuperAdmin
                  p2
                  onPress={() => {
                    setOpenMenu(false);
                    navigation.navigate("GeneralCourseSA");
                  }}
                />
              )}
              {(token?.role == "SUPERADMIN" ||
                token?.role == "GENERALADMIN") && (
                <ButtonMenuSuperAdmin
                  p3
                  onPress={() => {
                    setOpenMenu(false);
                    navigation.navigate("AddAdminSA");
                  }}
                />
              )}
              {(token?.role == "ACCOUNTING" || token?.role == "SUPERADMIN") && (
                <ButtonMenuSuperAdmin
                  p4
                  onPress={() => {
                    setOpenMenu(false);
                    navigation.navigate("ReportSA");
                  }}
                />
              )}
              {(token?.role == "SUPERADMIN" ||
                token?.role == "GENERALADMIN") && (
                <ButtonMenuSuperAdmin
                  p5
                  color
                  border
                  onPress={() => {
                    setOpenMenu(false);
                    navigation.navigate("SlideBannerSA");
                  }}
                />
              )}
              {(token?.role == "SUPERADMIN" ||
                token?.role == "GENERALADMIN") && (
                <ButtonMenuSuperAdmin
                  p6
                  onPress={() => {
                    setOpenMenu(false);
                    navigation.navigate("Discount");
                  }}
                />
              )}
              {(token?.role == "SUPERADMIN" ||
                token?.role == "GENERALADMIN") && (
                <ButtonMenuSuperAdmin
                  p7
                  onPress={() => {
                    setOpenMenu(false);
                    navigation.navigate("Account");
                  }}
                />
              )}
            </View>
            <ProfileAdmin />
          </View>
        )}
        {size.width >= 1280 && (
          <View //menu
            style={{
              width: "13%",

              minWidth: 220,
              height: "100%",
              backgroundColor: "#444444",
            }}
          >
            <View style={{ height: "80%" }}>
              {(token?.role == "SUPERADMIN" ||
                token?.role == "GENERALADMIN") && (
                <ButtonMenuSuperAdmin
                  p1
                  onPress={() => {
                    navigation.navigate("UniversityCourseSA");
                  }}
                />
              )}
              {(token?.role == "SUPERADMIN" ||
                token?.role == "GENERALADMIN") && (
                <ButtonMenuSuperAdmin
                  p2
                  onPress={() => {
                    navigation.navigate("GeneralCourseSA");
                  }}
                />
              )}
              {(token?.role == "SUPERADMIN" ||
                token?.role == "GENERALADMIN") && (
                <ButtonMenuSuperAdmin
                  p3
                  onPress={() => {
                    navigation.navigate("AddAdminSA");
                  }}
                />
              )}
              {(token?.role == "ACCOUNTING" || token?.role == "SUPERADMIN") && (
                <ButtonMenuSuperAdmin
                  p4
                  onPress={() => {
                    navigation.navigate("ReportSA");
                  }}
                />
              )}
              {(token?.role == "SUPERADMIN" ||
                token?.role == "GENERALADMIN") && (
                <ButtonMenuSuperAdmin
                  p5
                  color
                  border
                  onPress={() => {
                    navigation.navigate("SlideBannerSA");
                  }}
                />
              )}
              {(token?.role == "SUPERADMIN" ||
                token?.role == "GENERALADMIN") && (
                <ButtonMenuSuperAdmin
                  p6
                  onPress={() => {
                    navigation.navigate("Discount");
                  }}
                />
              )}
              {(token?.role == "SUPERADMIN" ||
                token?.role == "GENERALADMIN") && (
                <ButtonMenuSuperAdmin
                  p7
                  onPress={() => {
                    navigation.navigate("Account");
                  }}
                />
              )}
            </View>
            <ProfileAdmin />
          </View>
        )}
        <ScrollView>
          <View style={styles.viewDetail}>
            <View style={{ marginTop: 20, marginBottom: 40 }}>
              <Text
                style={{ fontFamily: "Prompt", fontSize: 40, color: "#808D93" }}
              >
                Header 1
              </Text>
              <FlatList
                data={state.img_list}
                renderItem={({ item, index }) => {
                  return (
                    <View
                      style={[
                        styles.viewHeader,
                        { flexDirection: size.width < 500 ? "column" : "row" },
                      ]}
                    >
                      <View
                        style={{ width: size.width < 500 ? "100%" : "30%" }}
                      >
                        <Image
                          resizeMode={"stretch"}
                          source={{
                            uri:
                              item.img1 == ""
                                ? "https://api.courseoncloud.com/api/image/getimage/" +
                                  "missing02_11_2021"
                                : "https://api.courseoncloud.com/api/image/getimage/" +
                                  item.img1,
                          }}
                          style={{
                            width: "100%",
                            maxWidth: 475,
                            height: 135,
                            backgroundColor: "#ccc",
                          }}
                        />
                      </View>
                      <Text
                        style={[
                          styles.text22,
                          {
                            width: size.width < 500 ? "100%" : "15%",
                            paddingLeft: 15,
                          },
                        ]}
                      >
                        1920 * 545
                      </Text>
                      <View
                        style={{
                          width: size.width < 500 ? "100%" : "10%",
                          marginVertical: size.width < 500 && 20,
                        }}
                      >
                        <TouchableOpacity
                          onPress={() => {
                            pickup(index);
                          }}
                          style={[
                            styles.buttonUp,
                            { alignSelf: size.width < 500 && "center" },
                          ]}
                        >
                          <Text
                            style={{
                              fontFamily: "Prompt",
                              fontSize: 22,
                              color: "#FFFFFF",
                            }}
                          >
                            อัพโหลด
                          </Text>
                        </TouchableOpacity>
                      </View>
                      <View
                        style={{
                          width: size.width < 500 ? "100%" : "30%",
                          alignItems: "center",
                          marginHorizontal: 10,
                        }}
                      >
                        {/* {item.img_list.map((e) => { */}
                        <TextInput
                          autoComplete="off"
                          defaultValue={item.url}
                          onChangeText={(text) => {
                            setstate((val) => ({
                              ...val,
                              img_list: val.img_list.map((items, indexs) => {
                                if (index == indexs) {
                                  return {
                                    ...items,
                                    url: text,
                                  };
                                } else {
                                  return items;
                                }
                              }),
                            }));
                          }}
                          placeholderTextColor="#808D93"
                          placeholder={"URL"}
                          style={styles.Url}
                        />
                        {/* })} */}
                      </View>
                      <View
                        style={{
                          width: size.width < 500 ? "100%" : "10%",
                          marginVertical: size.width < 500 && 20,
                        }}
                      >
                        <TouchableOpacity
                          onPress={() => {
                            // setstate((val) => ({
                            //   ...val,
                            //   img_list: val.img_list.concat(img_list),
                            // }));
                            save();
                          }}
                          style={[
                            styles.buttonSave,
                            { alignSelf: size.width < 500 && "center" },
                          ]}
                        >
                          <Text
                            style={{
                              fontFamily: "Prompt",
                              fontSize: 22,
                              color: "#FFFFFF",
                            }}
                          >
                            บันทึก
                          </Text>
                        </TouchableOpacity>
                      </View>
                      <View style={{ width: "5%" }}>
                        <TouchableOpacity
                          onPress={() => {
                            setId(index);
                            setModalDelItem(true);
                          }}
                        >
                          <MaterialCommunityIcons
                            name="delete"
                            size={24}
                            color="black"
                          />
                        </TouchableOpacity>
                      </View>
                    </View>
                  );
                }}
                ListFooterComponent={
                  <TouchableOpacity
                    // onPress={() => {
                    //   setData((val) => val.concat(""));
                    // }}
                    onPress={addBanner}
                    style={{ alignSelf: "center", marginVertical: 40 }}
                  >
                    <Feather name="plus-circle" size={54} color="#808D93" />
                  </TouchableOpacity>
                }
              />

              <Text
                style={{ fontFamily: "Prompt", fontSize: 40, color: "#808D93" }}
              >
                Header 2
              </Text>
              <FlatList
                data={state2.img_list}
                renderItem={({ item, index }) => {
                  return (
                    <View
                      style={[
                        styles.viewHeader,
                        {
                          marginTop: index == 0 ? 35 : 0,
                          flexDirection: size.width < 500 ? "column" : "row",
                        },
                      ]}
                    >
                      <View
                        style={{ width: size.width < 500 ? "100%" : "30%" }}
                      >
                        <Image
                          source={{
                            uri:
                              item.img1 == ""
                                ? "https://api.courseoncloud.com/api/image/getimage/" +
                                  "missing02_11_2021"
                                : "https://api.courseoncloud.com/api/image/getimage/" +
                                  item.img1,
                          }}
                          style={{
                            width: "100%",
                            maxWidth: 475,
                            height: 117,
                            backgroundColor: "#ccc",
                          }}
                        />
                      </View>
                      <Text
                        style={[
                          styles.text22,
                          {
                            width: size.width < 500 ? "100%" : "15%",
                            paddingLeft: 15,
                          },
                        ]}
                      >
                        1920 * 475
                      </Text>
                      <View
                        style={{
                          width: size.width < 500 ? "100%" : "10%",
                          marginVertical: size.width < 500 && 20,
                        }}
                      >
                        <TouchableOpacity
                          onPress={() => {
                            pickup1(index);
                          }}
                          style={[
                            styles.buttonUp,
                            { alignSelf: size.width < 500 && "center" },
                          ]}
                        >
                          <Text
                            style={{
                              fontFamily: "Prompt",
                              fontSize: 22,
                              color: "#FFFFFF",
                            }}
                          >
                            อัพโหลด
                          </Text>
                        </TouchableOpacity>
                      </View>
                      <View
                        style={{
                          width: size.width < 500 ? "100%" : "30%",
                          alignItems: "center",
                          marginHorizontal: 10,
                        }}
                      >
                        <TextInput
                          autoComplete="off"
                          defaultValue={item.url}
                          onChangeText={(text) => {
                            setstate2((val) => ({
                              ...val,
                              img_list: val.img_list.map((items, indexs) => {
                                if (index == indexs) {
                                  return {
                                    ...items,
                                    url: text,
                                  };
                                } else {
                                  return items;
                                }
                              }),
                            }));
                          }}
                          placeholderTextColor="#808D93"
                          placeholder={"URL"}
                          style={styles.Url}
                        />
                      </View>
                      <View
                        style={{
                          width: size.width < 500 ? "100%" : "10%",
                          marginVertical: size.width < 500 && 20,
                        }}
                      >
                        <TouchableOpacity
                          onPress={() => {
                            save2();
                          }}
                          style={[
                            styles.buttonSave,
                            { alignSelf: size.width < 500 && "center" },
                          ]}
                        >
                          <Text
                            style={{
                              fontFamily: "Prompt",
                              fontSize: 22,
                              color: "#FFFFFF",
                            }}
                          >
                            บันทึก
                          </Text>
                        </TouchableOpacity>
                      </View>
                      <View style={{ width: "5%" }}>
                        <TouchableOpacity
                          onPress={() => {
                            setId2(index);
                            setModalDelItem2(true);
                          }}
                        >
                          <MaterialCommunityIcons
                            name="delete"
                            size={24}
                            color="black"
                          />
                        </TouchableOpacity>
                      </View>
                    </View>
                  );
                }}
              />

              <TouchableOpacity
                onPress={addBanner2}
                style={{ alignSelf: "center", marginVertical: 40 }}
              >
                <Feather name="plus-circle" size={54} color="#808D93" />
              </TouchableOpacity>
            </View>
          </View>
        </ScrollView>
      </View>
      <Modal
        transparent={true}
        visible={modalDelItem}
        onRequestClose={() => {
          Alert.alert("Modal has been closed.");
          setModalDelItem(!modalDelItem);
        }}
      >
        <View style={styles.bgModal}>
          <View
            style={[
              styles.viewDetailModal,
              {
                width:
                  size.width < 500 ? "80%" : size.width < 1280 ? "70%" : "30%",
                height: 183,
                paddingVertical: 25,
              },
            ]}
          >
            <Text
              style={[
                styles.textButtonModal,
                { color: "#444444", alignSelf: "center" },
              ]}
            >
              คุณต้องการจะลบใช่รึไม่
            </Text>
            <View
              style={{
                flexDirection: "row",
                alignSelf: "center",
                marginTop: 39,
              }}
            >
              <TouchableOpacity
                onPress={() => setModalDelItem(!modalDelItem)}
                style={styles.buttonModal}
              >
                <Text
                  style={[
                    styles.textButtonModal,
                    {
                      color: "#444444",
                    },
                  ]}
                >
                  ยกเลิก
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => {
                  Delete();
                  setTimeout(() => {
                    getSlideBanner(token);
                  }, 500);
                  setModalDelItem(!modalDelItem);
                }}
                style={[
                  styles.buttonModal,
                  {
                    backgroundColor: "#0D88F0",
                    marginLeft: width * 0.05,
                  },
                ]}
              >
                <Text
                  style={[
                    styles.textButtonModal,
                    {
                      color: "#FFFFFF",
                    },
                  ]}
                >
                  ยืนยัน
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </Modal>
      <Modal
        transparent={true}
        visible={modalDelItem2}
        onRequestClose={() => {
          Alert.alert("Modal has been closed.");
          setModalDelItem2(!modalDelItem2);
        }}
      >
        <View style={styles.bgModal}>
          <View
            style={[
              styles.viewDetailModal,
              {
                width:
                  size.width < 500 ? "80%" : size.width < 1280 ? "70%" : "30%",
                height: 183,
                paddingVertical: 25,
              },
            ]}
          >
            <Text
              style={[
                styles.textButtonModal,
                { color: "#444444", alignSelf: "center" },
              ]}
            >
              คุณต้องการจะลบใช่รึไม่
            </Text>
            <View
              style={{
                flexDirection: "row",
                alignSelf: "center",
                marginTop: 39,
              }}
            >
              <TouchableOpacity
                onPress={() => setModalDelItem2(!modalDelItem2)}
                style={styles.buttonModal}
              >
                <Text
                  style={[
                    styles.textButtonModal,
                    {
                      color: "#444444",
                    },
                  ]}
                >
                  ยกเลิก
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => {
                  Delete2();
                  setTimeout(() => {
                    getSlideBanner(token);
                  }, 500);
                  setModalDelItem2(!modalDelItem2);
                }}
                style={[
                  styles.buttonModal,
                  {
                    backgroundColor: "#0D88F0",
                    marginLeft: width * 0.05,
                  },
                ]}
              >
                <Text
                  style={[
                    styles.textButtonModal,
                    {
                      color: "#FFFFFF",
                    },
                  ]}
                >
                  ยืนยัน
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </Modal>
      <Modal
        transparent={true}
        visible={modalSave}
        onRequestClose={() => {
          Alert.alert("Modal has been closed.");
          setModalSave(!modalSave);
        }}
      >
        <View style={styles.bgModal}>
          <View
            style={[
              {
                width: "30%",
                height: 183,
                paddingVertical: 25,
                backgroundColor: "#FFFFFF",
                borderRadius: 10,
                alignSelf: "center",
                justifyContent: "center",
              },
            ]}
          >
            <Text
              style={[
                styles.textButtonModal,
                {
                  color: "#64E117",
                  alignSelf: "center",
                  fontSize: 30,
                  fontFamily: "Prompt",
                },
              ]}
            >
              บันทึกสำเร็จ
            </Text>
          </View>
        </View>
      </Modal>
    </View>
  );
}
const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: "100%",
    backgroundColor: "#FFFFFF",
  },
  viewDetail: {
    width: "100%",
    height: "100%",
    paddingHorizontal: 55,
    paddingVertical: 28,
  },
  viewHeader: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottomColor: "#707070",
    borderBottomWidth: 1,
    paddingVertical: 40,
    marginTop: 11,
    width: "100%",
  },
  text22: {
    fontFamily: "Prompt",
    fontSize: 22,
    color: "#808D93",
  },
  buttonUp: {
    width: "100%",
    maxWidth: 202,
    height: 47,
    backgroundColor: "#0D88F0",
    justifyContent: "center",
    alignItems: "center",
  },
  Url: {
    width: "100%",
    maxWidth: 407,
    height: 47,
    borderWidth: 1,
    borderColor: "#E5E5E5",
    paddingHorizontal: 24,
    fontFamily: "Prompt",
    fontSize: 22,
    color: "#808D93",
  },
  buttonSave: {
    width: "100%",
    maxWidth: 99,
    height: 47,
    backgroundColor: "#0D88F0",
    justifyContent: "center",
    alignItems: "center",
  },
  bgModal: {
    width: "100%",
    height: "100%",
    backgroundColor: "#000000bb",
    justifyContent: "center",
  },
  viewDetailModal: {
    width: "50%",
    height: 380,
    backgroundColor: "#FFFFFF",
    borderRadius: 20,
    alignSelf: "center",
    paddingHorizontal: width * 0.05,
    paddingVertical: 50,
  },
  buttonModal: {
    width: 102,
    height: 48,
    borderRadius: 4,
    backgroundColor: "#EFEFF3",
    justifyContent: "center",
    alignItems: "center",
  },
  textButtonModal: {
    fontSize: 25,
    fontFamily: "Prompt",
  },
});
