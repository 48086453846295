import React, { useEffect, useState } from "react";
import {
  View,
  Text,
  Image,
  Dimensions,
  Animated,
  TouchableOpacity,
  StyleSheet,
  ScrollView,
  TextInput,
  FlatList,
} from "react-native";
import {
  MaterialCommunityIcons,
  FontAwesome,
  FontAwesome5,
  MaterialIcons,
} from "@expo/vector-icons";
import Modal from "modal-react-native-web";
import RNPickerSelect from "react-native-picker-select";
import { useWindowSize } from "../components/responsive";
import { apiservice } from "../../service";
import { tokenState, userState } from "../../reducer/recoil";
import { useRecoilState, useRecoilValue } from "recoil";
import moment from "moment";
const { width, height } = Dimensions.get("window");
export default function finance({ navigation, data }) {
  const [modalVisible, setModalVisible] = useState(false);
  const [modalVisible1, setModalVisible1] = useState(false);
  const [searchM, setsearchM] = useState("");
  const [searchY, setsearchY] = useState("");

  const users = useRecoilValue(userState);
  const [bankIndex, setBankIndex] = useState(0);
  const bankList = ["ธนาคารกสิกร", "ธนาคารกรุงไทย", "ธนาคารไทยพาณิชย์"];
  const bankChangeHandler = (index) => {
    setBankIndex((preIndex) => index);

    if (index == 0) {
      setBody({ ...body, new_account_type: "KBANK" });
    } else if (index == 1) {
      setBody({ ...body, new_account_type: "KTB" });
    } else if (index == 2) {
      setBody({ ...body, new_account_type: "SCB" });
    }
  };
  const placeholder = {
    label: "รอบเดือน ..",
    value: null,
    color: "#FCBDA6",
  };
  const placeholder1 = {
    label: "รอบปี ..",
    value: null,
    color: "#FCBDA6",
  };
  const [arrmonth, setarrMonth] = useState([]);
  const [month, setMonth] = useState([]);
  const [year, setYear] = useState([]);
  const [warning, setWarning] = useState(false);
  const [start, setstart] = useState(moment().add(-1, "months"));
  const [End, setend] = useState(moment());
  const [state, setState] = useState({
    password: "",
    balance_no: "",
    balance_info: "",
  });

  const [body, setBody] = useState({
    old_account: "",
    old_account_type: "",
    new_account: "",
    new_account_type: "",
    uid: null,
  });

  const [token, setToken] = useRecoilState(tokenState);

  useEffect(() => {
    for (let index = 0; index < 4; index++) {
      setYear((val) =>
        val.concat({
          label: moment().add(-index, "years").format("YYYY"),
          value: moment().add(-index, "years").format("YYYY"),
        })
      );
    }
    for (let index = 0; index < 12; index++) {
      setMonth((val) =>
        val.concat({
          label: moment().add(-index, "months").format("MM"),
          value: moment().add(-index, "months").format("MM"),
        })
      );
    }

    name();
  }, []);
  const anotherFunc = (val) => {};
  const [DATA, setData] = useState([]);

  async function name() {
    const res = await apiservice({
      path: "/chillpay/getorder/" + users?.uid,
    });

    setData(res.data.data);
    for (let index = 0; index < 5; index++) {
      setarrMonth((val) =>
        val.concat(
          moment().format(
            `${moment().add(-index, "months").format("YYYY-MM")}-DD`
          )
        )
      );
    }
  }
  async function edit() {
    const response = await apiservice({
      path: "/authen/update_balance",
      method: "put",
      body: state,
      token: token?.accessToken,
    });
    if (response.status == 200) {
      setWarning(false);
      setTimeout(async () => {
        const response = await apiservice({
          path: "/authen/RQcreate",
          method: "post",
          body: body,
        });
        if (response.status == 200) {
          setModalVisible(!modalVisible);
          setModalVisible1(true);
        }
      }, 800);
    } else if (response.status == 400) {
      setWarning(true);
    }
  }

  // {
  //   no: "3",
  //   totalBefore: "4,300",
  //   outstanding: "2,728.69",
  //   billing: "รอบบิล 3 เดือน\nxx ปี xxxx",
  //   bank: "กสิกร",
  //   accountNumber: "111-2-33333-4",
  // },

  function totalBefore(params) {
    let res = DATA.filter((item) => {
      return params == moment(item.createdAt).format("YYYY-MM");
    });
    let totals = 0;
    res.map((e) => (totals = totals + parseInt(e.netAmount)));
    return totals;
  }

  function totalAll() {
    let res = DATA;
    let totals = 0;
    res.map((e) => (totals = totals + parseInt(e.netAmount)));
    return totals;
  }

  // totalBefore("2022-02");
  const size = useWindowSize();

  if (DATA.length == 0) {
    return (
      <View style={{ minHeight: 1320, paddingHorizontal: 50 }}>
        <Text
          style={[styles.textPrice, , { fontSize: size.width < 850 ? 20 : 25 }]}
        >
          จำนวนเงินของฉัน {totalAll()} บาท
        </Text>
        <View style={styles.viewDetailPrice}>
          <Image
            style={{ width: 20, height: 20 }}
            source={require("../img/ic_info_outline_24px.png")}
          />
          <Text
            style={[
              styles.textWarning,
              { fontSize: size.width < 850 ? 10 : 16 },
            ]}
          >
            จำนวนเงินข้างต้นเป็นจำนวนเงินที่ยังไม่ผ่านการหักค่าใช้จ่ายต่างๆ
          </Text>
          <TouchableOpacity>
            <Text
              style={[
                styles.paymentDetail,
                {
                  fontSize: size.width < 850 ? 10 : 16,
                  textDecorationLine: "underline",
                  marginLeft: 5,
                },
              ]}
            >
              {"รายละเอียดการรับเงิน"}
            </Text>
          </TouchableOpacity>
        </View>
      </View>
    );
  }

  return (
    <View style={{ minHeight: 1320, paddingHorizontal: 50 }}>
      <Text
        style={[styles.textPrice, , { fontSize: size.width < 850 ? 20 : 25 }]}
      >
        จำนวนเงินของฉัน {totalAll()} บาท
      </Text>
      <View style={styles.viewDetailPrice}>
        <Image
          style={{ width: 20, height: 20 }}
          source={require("../img/ic_info_outline_24px.png")}
        />
        <Text
          style={[styles.textWarning, { fontSize: size.width < 850 ? 10 : 16 }]}
        >
          จำนวนเงินข้างต้นเป็นจำนวนเงินที่ยังไม่ผ่านการหักค่าใช้จ่ายต่างๆ
        </Text>
        <TouchableOpacity>
          <Text
            style={[
              styles.paymentDetail,
              {
                fontSize: size.width < 850 ? 10 : 16,
                textDecorationLine: "underline",
                marginLeft: 5,
              },
            ]}
          >
            {"รายละเอียดการรับเงิน"}
          </Text>
        </TouchableOpacity>
      </View>
      <View
        style={[
          styles.paymentOption,
          { marginHorizontal: size.width < 850 ? 0 : 45 },
        ]}
      >
        <View style={{ flexDirection: size.width < 850 ? "column" : "row" }}>
          <RNPickerSelect
            placeholder={placeholder}
            onValueChange={(val) => {
              if (val == "รอบเดือน ..") {
                setsearchM("");
              } else {
                setsearchM(val);
              }
            }}
            items={month}
            style={{
              inputWeb: {
                width: size.width < 850 ? size.width / 3 : size.width / 6.5,
                backgroundColor: "#FFFFFF",
                height: 40,
                borderWidth: 1,
                borderColor: "#D1DDE5",
                color: "#0D88F0",
                fontFamily: "Prompt",
                fontSize: 16,
                paddingLeft: 15,
              },
            }}
          />
          <RNPickerSelect
            placeholder={placeholder1}
            onValueChange={(val) => {
              if (val == "รอบปี ..") {
                setsearchY("");
              } else {
                setsearchY(val);
              }
            }}
            items={year}
            style={{
              inputWeb: {
                width: size.width < 850 ? size.width / 3 : size.width / 6.5,
                height: 40,
                borderWidth: 1,
                backgroundColor: "#FFFFFF",
                borderColor: "#D1DDE5",
                color: "#0D88F0",
                fontFamily: "Prompt",
                fontSize: 16,
                paddingLeft: 15,
                marginLeft: size.width < 850 ? 0 : 15,
                marginTop: size.width < 850 && 20,
              },
            }}
          />
          <TouchableOpacity
            onPress={() => {
              const newWindow = window.open(
                "https://api.courseoncloud.com/api/image/xlxs?id=" + users?.uid,
                "_blank",
                "noopener,noreferrer"
              );
              if (newWindow) newWindow.opener = null;
            }}
            style={[
              styles.buttonPrint,
              {
                marginLeft: size.width < 850 ? 0 : 25,
                marginTop: size.width < 850 && 20,
              },
            ]}
          >
            <Text style={styles.printAll}>พิมพ์ทั้งหมด</Text>
          </TouchableOpacity>
        </View>
        <View style={{ width: "25%", alignItems: "center" }}>
          <TouchableOpacity
            onPress={() => {
              setState({
                ...state,
                balance_no: data.balance_no,
                balance_info: data.balance_info,
              });
              setBody({
                ...body,
                old_account: data.balance_no,
                old_account_type: data.balance_info,
                uid: data.uid,
                new_account_type: data.balance_info,
              });
              if (data.balance_info == "KBANK") {
                setBankIndex(0);
              } else if (data.balance_info == "KTB") {
                setBankIndex(1);
              } else if (data.balance_info == "SCB") {
                setBankIndex(2);
              }
              setModalVisible(true);
            }}
            style={styles.changAccount}
          >
            <Text style={styles.textChangAccount}>
              {"คำร้องขอเปลี่ยนบัญชี"}
            </Text>
          </TouchableOpacity>
        </View>
      </View>
      <View
        style={{
          marginHorizontal: size.width < 850 ? 0 : 45,
          marginVertical: 30,
        }}
      >
        {/* <View style={styles.viewTitle}>
          <Text
            style={[
              size.width < 850 ? stylesResponsive.textTitle : styles.textTitle,
              { paddingRight: width * 0.05 },
            ]}
          >
            ลำดับที่
          </Text>
          <Text
            style={[
              size.width < 850 ? stylesResponsive.textTitle : styles.textTitle,
            ]}
          >
            ยอดรวมก่อนหักค่าใช้จ่าย
          </Text>
          <Text
            style={[
              size.width < 850 ? stylesResponsive.textTitle : styles.textTitle,
            ]}
          >
            รอบบิล
          </Text>
          <Text
            style={[
              size.width < 850 ? stylesResponsive.textTitle : styles.textTitle,
            ]}
          ></Text>
        </View> */}
        <FlatList
          numColumns={1}
          data={arrmonth
            .filter((item) =>
              searchM.length > 0
                ? moment(item).format("MM") == moment(searchM).format("MM")
                : true
            )
            .filter((item) =>
              searchY.length > 0
                ? moment(item).format("YYYY") == moment(searchY).format("YYYY")
                : true
            )}
          extraData={[DATA]}
          style={{}}
          renderItem={({ item, index }) => {
            return (
              <View
                style={[styles.payment, { borderTopWidth: index == 0 && 1 }]}
              >
                <Text
                  style={[
                    size.width < 850
                      ? stylesResponsive.textDetail
                      : styles.textDetail,
                    { paddingRight: width * 0.05 },
                  ]}
                >
                  {index + 1}
                </Text>

                <Text
                  style={[
                    size.width < 850
                      ? stylesResponsive.textDetail
                      : styles.textDetail,
                  ]}
                >
                  {totalBefore(moment(item).format("YYYY-MM"))}
                  {" บาท"}
                </Text>
                <Text
                  style={[
                    size.width < 850
                      ? stylesResponsive.textDetail
                      : styles.textDetail,
                  ]}
                >
                  {`รอบบิล เดือน\n${moment(item).format("MM")} ปี ${moment(
                    item
                  ).format("YYYY")}`}
                </Text>
                <View style={{ width: "25%", alignItems: "center" }}>
                  <TouchableOpacity
                    onPress={() => {
                      const newWindow = window.open(
                        "https://api.courseoncloud.com/api/image/xlxs?id=" +
                          users?.uid +
                          "&start=" +
                          moment(item).add(-1, "months").format("YYYY-MM-28") +
                          "&end=" +
                          moment(item).format("YYYY-MM-28"),
                        "_blank",
                        "noopener,noreferrer"
                      );
                      if (newWindow) newWindow.opener = null;
                    }}
                    style={{
                      width: size.width < 850 ? size.width / 10 : 128,
                      height: 40,
                      borderWidth: 1,
                      borderColor: "#0D88F0",
                      borderRadius: 20,
                      backgroundColor: "#FFFFFF",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Text
                      style={{
                        fontFamily: "Prompt",
                        fontSize: size.width < 850 ? 12 : 18,
                        color: "#0D88F0",
                      }}
                    >
                      พิมพ์
                    </Text>
                  </TouchableOpacity>
                </View>
              </View>
            );
          }}
        />
      </View>
      <Modal
        transparent={true}
        visible={modalVisible}
        onRequestClose={() => {
          Alert.alert("Modal has been closed.");
          setModalVisible(!modalVisible);
        }}
      >
        <View
          style={{
            width: "100%",
            height: "100%",
            backgroundColor: "#000000bb",
            justifyContent: "center",
          }}
        >
          <View
            style={{
              width: "100%",
              maxWidth: 875,
              minHeight: 400,
              backgroundColor: "#FFFFFF",
              borderWidth: 1,
              borderColor: "#0D88F0",
              padding: size.width < 500 ? 40 : 50,
              alignSelf: "center",
            }}
          >
            <Text
              style={{
                fontSize: 25,
                fontFamily: "Prompt",
                color: "#0B2051",
              }}
            >
              เลือกธนาคาร
            </Text>

            <View
              style={{
                flexDirection: size.width < 500 ? "column" : "row",
                marginTop: 12,
              }}
            >
              {bankList.map((data, index) => (
                <TouchableOpacity
                  key={data}
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    marginLeft: size.width < 500 ? 0 : index == 0 ? 0 : 10,
                  }}
                  onPress={bankChangeHandler.bind(this, index)}
                >
                  <MaterialCommunityIcons
                    name={
                      index === bankIndex
                        ? "checkbox-blank"
                        : "checkbox-blank-outline"
                    }
                    size={35}
                    color="#0D88F0"
                  />
                  <Text style={styles.textModal}>{data}</Text>
                </TouchableOpacity>
              ))}
            </View>
            <View style={styles.viewDetailModal}>
              <Text
                style={[
                  styles.textModal,
                  { width: size.width < 500 ? "30%" : "10%" },
                ]}
              >
                เลขบัญชี
              </Text>
              <View style={{ width: size.width < 500 ? "70%" : "90%" }}>
                <TextInput
                  maxLength={10}
                  defaultValue={state.balance_no}
                  autoComplete="off"
                  onChangeText={(text) => {
                    setBody({ ...body, new_account: text });
                  }}
                  style={[
                    styles.inputDetailModal,
                    { maxWidth: null, fontSize: size.width < 500 ? 14 : 20 },
                  ]}
                />
              </View>
            </View>
            <View style={styles.viewDetailModal}>
              <Text
                style={[
                  styles.textModal,
                  { width: size.width < 500 ? "30%" : "10%" },
                ]}
              >
                รหัสผ่าน
              </Text>
              <View style={{ width: size.width < 500 ? "70%" : "90%" }}>
                <TextInput
                  placeholder="กรอกรหัสผ่านในการเข้าสู่ระบบ"
                  placeholderTextColor={"#CCC"}
                  defaultValue={state.password}
                  autoComplete="off"
                  secureTextEntry
                  onChangeText={(text) => {
                    setState({ ...state, password: text });
                  }}
                  style={[
                    styles.inputDetailModal,
                    { maxWidth: null, fontSize: size.width < 500 ? 14 : 20 },
                  ]}
                />
                {warning && (
                  <Text
                    style={{
                      fontSize: 14,
                      fontFamily: "Prompt",
                      color: "red",
                      marginLeft: 20,
                      position: "absolute",
                      marginTop: 40,
                    }}
                  >
                    รหัสผ่านไม่ถูกต้อง
                  </Text>
                )}
              </View>
            </View>
            <View
              style={{
                flexDirection: "row",
                marginTop: 35,
                alignSelf: "center",
              }}
            >
              <TouchableOpacity
                disabled={state.password == "" ? true : false}
                onPress={() => {
                  edit();
                  anotherFunc(state.password, state.balance_no);
                }}
                style={{
                  width: 150,
                  height: 60,
                  backgroundColor: state.password == "" ? "#D1DDE5" : "#0D88F0",
                  borderRadius: 10,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Text
                  style={{
                    fontSize: 25,
                    fontFamily: "Prompt",
                    color: "#FFFFFF",
                  }}
                >
                  ยืนยัน
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => {
                  anotherFunc(state.password, state.balance_no);
                  // setState({ ...state, password: "" });
                  setModalVisible(!modalVisible);
                }}
                style={{
                  width: 150,
                  height: 60,
                  borderWidth: 1,
                  borderColor: "#0D88F0",
                  borderRadius: 10,
                  justifyContent: "center",
                  alignItems: "center",
                  marginLeft: 30,
                }}
              >
                <Text
                  style={{
                    fontSize: 25,
                    fontFamily: "Prompt",
                    color: "#0D88F0",
                  }}
                >
                  ยกเลิก
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </Modal>
      <Modal
        transparent={true}
        visible={modalVisible1}
        onRequestClose={() => {
          Alert.alert("Modal has been closed.");
          setModalVisible(!modalVisible1);
        }}
      >
        <View
          style={{
            width: "100%",
            height: "100%",
            backgroundColor: "#000000bb",
            justifyContent: "center",
          }}
        >
          <View
            style={{
              width: "100%",
              maxWidth: 875,
              height: "100%",
              maxHeight: 300,
              backgroundColor: "#FFFFFF",
              borderWidth: 1,
              borderColor: "#0D88F0",
              alignSelf: "center",
              padding: 15,
            }}
          >
            <TouchableOpacity
              onPress={() => {
                setModalVisible1(!modalVisible1);
                window.location.reload(false);
              }}
              style={{ position: "absolute", alignSelf: "flex-end" }}
            >
              <MaterialCommunityIcons name="close" size={24} color="#0D88F0" />
            </TouchableOpacity>
            <Text
              style={{
                fontSize: 35,
                fontFamily: "Prompt",
                color: "#74C745",
                alignSelf: "center",
                marginTop: 25,
              }}
            >
              เสร็จสิ้น!
            </Text>
            <Text
              style={[
                styles.textModal1,
                { marginTop: 32, alignSelf: "center" },
              ]}
            >
              ระบบได้ส่งคำร้องขอเปลี่ยนบัญชีธนาคารของคุณให้แอดมินตรวจสอบแล้ว
            </Text>
            <Text
              style={[
                styles.textModal1,
                { marginTop: 17, alignSelf: "center" },
              ]}
            >
              กรุณารอการตอบรับภายใน 1 - 2 วัน
            </Text>
            <Text
              style={{
                fontSize: 18,
                fontFamily: "Prompt",
                color: "#0B2051",
                marginTop: 19,
                alignSelf: "center",
              }}
            >
              หาก 1 - 2 วันยังไม่มีการตอบรับกรุณา
              <TouchableOpacity>
                <Text
                  style={{
                    fontSize: 18,
                    color: "#E82626",
                    textDecoration: "underline #E82626",
                  }}
                >
                  ติดต่อเรา
                </Text>
              </TouchableOpacity>
            </Text>
          </View>
        </View>
      </Modal>
    </View>
  );
}
const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: "100%",
    backgroundColor: "#FFFFFF",
  },
  textTitle: {
    fontFamily: "Prompt",
    fontSize: 20,
    color: "#0B2051",
    width: "25%",
    textAlign: "center",
  },
  textDetail: {
    fontFamily: "Prompt",
    fontSize: 18,
    color: "#0B2051",
    width: "25%",
    textAlign: "center",
  },
  textPrice: {
    fontFamily: "Prompt",
    fontSize: 25,
    color: "#0B2051",
    marginTop: 45,
  },
  viewDetailPrice: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 13,
  },
  textWarning: {
    fontFamily: "Prompt",
    fontSize: 16,
    color: "#E82626",
    marginLeft: 5,
  },
  paymentDetail: {
    fontFamily: "Prompt",
    fontSize: 16,
    color: "#E82626",
    textDecoration: "underline #E82626",
  },
  paymentOption: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 46,
    marginHorizontal: 45,
    alignItems: "center",
  },
  buttonPrint: {
    width: 128,
    height: 40,
    backgroundColor: "#0D88F0",
    borderRadius: 20,
    justifyContent: "center",
    alignItems: "center",
    marginLeft: 25,
  },
  buttonPrintSelect: {
    width: 128,
    height: 40,
    borderWidth: 1,
    borderColor: "#0D88F0",
    borderRadius: 20,
    justifyContent: "center",
    alignItems: "center",
    marginLeft: 20,
  },
  printAll: {
    fontFamily: "Prompt",
    fontSize: 16,
    color: "#FFFFFF",
  },
  textPrintSelect: {
    fontFamily: "Prompt",
    fontSize: 14,
    color: "#0D88F0",
    textAlign: "center",
  },
  changAccount: {
    width: 128,
    height: 40,
    borderWidth: 1,
    borderColor: "#E82626",
    borderRadius: 20,
    justifyContent: "center",
    alignItems: "center",
  },
  textChangAccount: {
    fontFamily: "Prompt",
    fontSize: 12,
    color: "#E82626",
    textAlign: "center",
  },
  payment: {
    borderTopColor: "#BBBBBB",
    borderBottomColor: "#BBBBBB",
    borderBottomWidth: 1,
    paddingVertical: 25,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  viewTitle: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  textModal: {
    fontSize: 20,
    fontFamily: "Prompt",
    color: "#0B2051",
  },
  viewDetailModal: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 35,
    justifyContent: "space-between",
  },
  inputDetailModal: {
    width: "100%",
    maxWidth: 675,
    height: 35,
    borderWidth: 1,
    borderColor: "#0D88F0",
    paddingHorizontal: 15,
    fontSize: 20,
    fontFamily: "Prompt",
    color: "#0B2051",
    marginLeft: 20,
  },
  textModal1: {
    fontSize: 25,
    fontFamily: "Prompt",
    color: "#0B2051",
  },
});
const stylesResponsive = StyleSheet.create({
  textTitle: {
    fontFamily: "Prompt",
    fontSize: 14,
    color: "#0B2051",
    width: "25%",
    textAlign: "center",
  },
  textDetail: {
    fontFamily: "Prompt",
    fontSize: 12,
    color: "#0B2051",
    width: "25%",
    textAlign: "center",
  },
});
