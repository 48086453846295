import React, { useState, useRef } from "react";
import {
  View,
  Text,
  Image,
  Dimensions,
  Animated,
  TouchableOpacity,
  StyleSheet,
  ScrollView,
  TextInput,
  FlatList,
} from "react-native";
import {
  MaterialCommunityIcons,
  FontAwesome,
  FontAwesome5,
  MaterialIcons,
} from "@expo/vector-icons";
import Header from "../components/header";
import { bottomPart } from "./home";

const { width, height } = Dimensions.get("window");
export default function lessonMeet({ navigation }) {
  const [unLock, setUnLock] = useState(false);
  const DATA = [
    { title: "เอกสารประกอบการเรียน.pdf" },
    { title: "เอกสารประกอบการเรียน2.pdf" },
  ];
  return (
    <View style={styles.container}>
      <ScrollView stickyHeaderIndices={[0]}>
        <Header navigation={navigation} />
        <View style={{ width: "50%", alignSelf: "center", marginTop: 35 }}>
          <Text
            style={{ fontFamily: "Prompt", fontSize: 11, color: "#0B2051" }}
          >
            พุทธศาสตรบัณฑิต (การสอนพระพุทธศาสนาและจิตวิทยาแนะแนว)
          </Text>
          <Text
            style={{ fontFamily: "Prompt", fontSize: 25, color: "#0B2051" }}
          >
            ทำความรู้จักงานแนะแนว3
          </Text>
          <Image
            style={{
              width: "100%",
              height: 465,
              marginVertical: 22,
            }}
            source={require("../img/online-courses-what-students-want.png")}
          />
          <Text style={styles.text20}>วันที่เรียน : </Text>
          <Text style={styles.text20}>เวลาในการเรียน : </Text>
          <Text style={styles.text20}>URL : </Text>
          <Text style={[styles.text20, { marginBottom: 20 }]}>
            รหัสเข้าร่วม :
          </Text>
          <FlatList
            data={DATA}
            numColumns={1}
            renderItem={({ index, item }) => {
              return (
                <View
                  style={{
                    borderTopWidth: index == 0 && 1,
                    borderBottomWidth: 1,
                    borderBottomColor: "#0D88F0",
                    borderTopColor: "#0D88F0",
                    paddingVertical: 15,
                  }}
                >
                  <TouchableOpacity
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Text style={[styles.textDetail, { marginTop: 0 }]}>
                      {item.title}
                    </Text>
                    <MaterialIcons
                      name="cloud-download"
                      size={24}
                      color="#0D88F0"
                    />
                  </TouchableOpacity>
                </View>
              );
            }}
          />
          <TouchableOpacity
            disabled={unLock ? true : false}
            onPress={() => {
              setUnLock(true);
            }}
            style={{
              width: 165,
              height: 57,
              borderRadius: 5,
              backgroundColor: unLock ? "#CCCCCC" : "#0D88F0",
              justifyContent: "center",
              alignItems: "center",
              alignSelf: "center",
              marginVertical: 45,
            }}
          >
            <Text
              style={{ fontFamily: "Prompt", fontSize: 25, color: "#FFFFFF" }}
            >
              เสร็จสิ้น
            </Text>
          </TouchableOpacity>
        </View>
        <View
          style={{
            width: "100%",
            height: 70,
            backgroundColor: unLock ? "#74C745" : "#FFD54A",
            justifyContent: "center",
          }}
        >
          <View
            style={[
              styles.viewProgress,
              { backgroundColor: unLock ? "#74C745" : "#FFD54A" },
            ]}
          >
            <View style={styles.viewTextProgress}>
              <TouchableOpacity
                onPress={() => {
                  setUnLock(false);
                  navigation.navigate("LessonVideo");
                }}
                style={{ margin: 10 }}
              >
                <MaterialCommunityIcons
                  name="arrow-left-drop-circle"
                  size={30}
                  color="#FFFFFF"
                />
              </TouchableOpacity>
              <View>
                <Text style={[styles.textProgress, { textAlign: "left" }]}>
                  บทก่อนหน้า
                </Text>
                <Text numberOfLines={1} style={styles.textProgress}>
                  {"ทำความรู้จักงานแนะแนว 2"}
                </Text>
              </View>
            </View>
            <TouchableOpacity
              disabled={true}
              style={[
                styles.buttonProgress,
                {
                  backgroundColor: unLock ? "#E3F9D6" : "#FEF3CE",
                },
              ]}
            >
              <Text style={styles.textSuccess}>
                {unLock ? "เสร็จสิ้น" : "กำลังดำเนินการ.."}
              </Text>
            </TouchableOpacity>
            <View style={styles.viewTextProgress}>
              <View>
                <Text style={[styles.textProgress]}>บทถัดไป</Text>
                <Text numberOfLines={1} style={styles.textProgress}>
                  {"แบบทดสอบหลังเรียน"}
                </Text>
              </View>
              <TouchableOpacity
                onPress={() => {
                  setUnLock(false);
                  navigation.navigate("Test");
                }}
                disabled={unLock ? false : true}
                style={{ margin: 10 }}
              >
                <MaterialCommunityIcons
                  name={unLock ? "arrow-right-drop-circle" : "lock"}
                  size={30}
                  color="#FFFFFF"
                />
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </ScrollView>
    </View>
  );
}
const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: "100%",
    backgroundColor: "#FFFFFF",
  },
  textDetail: {
    fontFamily: "Prompt",
    fontSize: 20,
    color: "#0B2051",
    marginTop: 40,
  },
  viewTextProgress: {
    flexDirection: "row",
    alignItems: "center",
  },
  textProgress: {
    fontFamily: "Prompt",
    fontSize: 12,
    color: "#FFFFFF",
    textAlign: "right",
  },
  viewProgress: {
    width: "100%",
    height: 70,
    flexDirection: "row",
    justifyContent: "space-between",
  },
  buttonProgress: {
    width: 150,
    height: 40,
    borderRadius: 20,
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "center",
  },
  textSuccess: {
    fontFamily: "Prompt",
    fontSize: 12,
    color: "#0B2051",
  },
  text20: {
    fontFamily: "Prompt",
    fontSize: 20,
    color: "#0B2051",
    marginTop: 20,
  },
});
