import React from "react";
import {
  View,
  Text,
  Image,
  Dimensions,
  Animated,
  TouchableOpacity,
  StyleSheet,
  ScrollView,
} from "react-native";
import MenuHeader from "./menuHeader";
import { useRecoilState } from "recoil";
import { tokenState } from "../../reducer/recoil";
const { width, height } = Dimensions.get("window");
export default function header({ navigation }) {
  const [token, setToken] = useRecoilState(tokenState);

  function Logout() {
    setToken("");
    setTimeout(() => {
      navigation.navigate("Home");
    }, 200);
  }
  return (
    <View
      style={{
        height: height,
        width: "100%",
        backgroundColor: "#2B6AB1",
      }}
    >
      <View style={{ marginHorizontal: 10 }}>
        <MenuHeader
          onPress={() => {
            navigation.navigate("Home");
          }}
          Title="HOME"
          style={{ width: 39, height: 35 }}
        />
      </View>
      <View style={{ marginHorizontal: 10 }}>
        <MenuHeader
          onPress={Logout}
          Title="LOG OUT"
          style={{ width: 39, height: 35 }}
        />
      </View>
    </View>
  );
}
