import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  Image,
  Dimensions,
  Animated,
  TouchableOpacity,
  StyleSheet,
  ScrollView,
  TextInput,
  FlatList,
} from "react-native";
import {
  MaterialCommunityIcons,
  FontAwesome,
  FontAwesome5,
  MaterialIcons,
} from "@expo/vector-icons";
import RNPickerSelect from "react-native-picker-select";
import { useRecoilState } from "recoil";
import { courseState, tokenState } from "../../reducer/recoil";
import { apiservice } from "../../service";
import { useWindowSize } from "../components/responsive";
import NumberFormat from "react-number-format";
const { width, height } = Dimensions.get("window");

export default function courseProfile({ navigation }) {
  const [token, setToken] = useRecoilState(tokenState);
  const [courseDetail, setCourseDetail] = useState([]);
  const [courseStates, setCourseStates] = useRecoilState(courseState);

  useEffect(() => {
    if (token) {
      getMyCourse(token);
    }
  }, [token]);
  async function getMyCourse() {
    const response = await apiservice({
      path: "/start-course/mycourse?page=1&totalpage=100",
      method: "get",
      token: token?.accessToken,
    });
    if (response.status == 200) {
      setCourseDetail(response.data.results);
    }
  }

  async function getMyCoursesearch(text) {
    const response = await apiservice({
      path: "/start-course/mycourse?page=1&totalpage=100&search=" + text,
      method: "get",
      token: token?.accessToken,
    });
    if (response.status == 200) {
      setCourseDetail(response.data.results);
    }
  }

  const placeholder = {
    label: "รูปแบบคอร์ส ..",
    value: null,
    color: "#FCBDA6",
  };

  const [status, setStatus] = useState([
    { label: "status1", value: "status1" },
    { label: "status2", value: "status2" },
  ]);
  const size = useWindowSize();
  return (
    <View
      style={{
        minHeight: 1040,
        paddingHorizontal: 50,
        marginBottom: size.width < 500 && 20,
      }}
    >
      <Text
        style={[
          size.width < 500 ? stylesResponsive.textTitle : styles.textTitle,
          { alignSelf: "center" },
        ]}
      >
        คอร์สที่เรียนทั้งหมด
      </Text>
      <View
        style={{
          flexDirection: size.width < 500 ? "column" : "row",
          marginTop: 14,
        }}
      >
        <TextInput
          onChangeText={(text) => {
            getMyCoursesearch(text);
          }}
          autoComplete="off"
          placeholder="ค้นหาชื่อ .."
          placeholderTextColor="#646464"
          style={[
            size.width < 500
              ? stylesResponsive.inputSearchName
              : styles.inputSearchName,
          ]}
        />
        {/* <RNPickerSelect
          placeholder={placeholder}
          onValueChange={(val) => {
          
          }}
          items={status}
          style={{
            inputWeb: {
              width: size.width < 1280 ? 220 : size.width / 6,
              height: 40,
              borderWidth: 1,
              borderColor: "#FCBDA6",
              backgroundColor: "#FFFFFF",
              color: "#FCBDA6",
              fontFamily: "Prompt",
              fontSize: size.width < 500 ? 14 : 20,
              paddingLeft: 15,
              marginHorizontal: size.width < 500 ? 0 : 15,
              marginTop: size.width < 500 && 10,
            },
          }}
        /> */}
      </View>
      <View style={{ alignSelf: width < 1280 ? "center" : "flex-start" }}>
        <FlatList
          data={courseDetail}
          numColumns={
            (width >= 1900 && 4) ||
            (width < 1900 && width >= 950 && 3) ||
            (width < 950 && width >= 500 && 2) ||
            (width < 500 && 1)
          }
          scrollEnabled={size.width < 500 ? true : false}
          style={{ marginLeft: -25 }}
          renderItem={({ index, item }) => {
            return (
              <TouchableOpacity
                onPress={async () => {
                  const response = await apiservice({
                    path:
                      "/course/getallmain?page=1&lan=TH&course_id=" +
                      item.course_id,
                    method: "get",
                  });
                  if (response.status == 200) {
                    setCourseStates(response.data.main[0]);
                    setTimeout(() => {
                      navigation.navigate("Course");
                    }, 900);
                  }
                }}
                style={[
                  styles.viewDetail,
                  {
                    marginLeft: 25,
                    width: width < 1600 ? 240 : 275,
                    marginTop: 25,
                    alignSelf: "flex-start",
                    borderWidth: 1,
                    borderColor: "#BBB",
                  },
                ]}
              >
                <Image
                  style={[
                    styles.imgDetail,
                    {
                      width: width < 1600 ? 240 : 275,
                      backgroundColor: "#CCC",
                    },
                  ]}
                  source={{
                    uri:
                      item.courses != null &&
                      "https://api.courseoncloud.com/api/image/getimage/" +
                        item.courses.img_content,
                  }}
                />
                <View style={styles.viewCourse}>
                  <Text style={styles.text} numberOfLines={1}>
                    {item.courses != null && item.courses.title}
                  </Text>
                  <View style={styles.viewImg}>
                    <Image
                      style={[styles.img, { backgroundColor: "#CCC" }]}
                      source={{
                        uri:
                          item.courses != null &&
                          "https://api.courseoncloud.com/api/image/getimage/" +
                            item.Teacher.user_accounts.image_url,
                      }}
                    />
                    <Text
                      style={[
                        styles.text,
                        { fontSize: size.width < 500 ? 12 : 16 },
                      ]}
                    >
                      {item.Teacher.user_accounts.firstName}{" "}
                      {item.Teacher.user_accounts.lastName}
                    </Text>
                  </View>
                  <View style={styles.feedback}>
                    <View
                      style={{
                        flexDirection: "row",
                        alignSelf: "flex-end",
                      }}
                    >
                      {/* <FontAwesome
                        name={item.courses.rating > 0 ? "star" : "star-o"}
                        size={size.width < 500 ? 15 : 20}
                        color="#0D88F0"
                      />
                      <FontAwesome
                        name={item.courses.rating > 1 ? "star" : "star-o"}
                        size={size.width < 500 ? 15 : 20}
                        color="#0D88F0"
                      />
                      <FontAwesome
                        name={item.courses.rating > 2 ? "star" : "star-o"}
                        size={size.width < 500 ? 15 : 20}
                        color="#0D88F0"
                      />
                      <FontAwesome
                        name={item.courses.rating > 3 ? "star" : "star-o"}
                        size={size.width < 500 ? 15 : 20}
                        color="#0D88F0"
                      />
                      <FontAwesome
                        name={item.courses.rating > 4 ? "star" : "star-o"}
                        size={size.width < 500 ? 15 : 20}
                        color="#0D88F0"
                      /> */}
                    </View>
                    <View>
                      {item.type == "COURSESCHOOL" ? (
                        <View
                          style={{
                            height: 20,
                            backgroundColor: "#0B2051",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: 10,
                            marginVertical: 5,
                            alignSelf: "flex-start",
                          }}
                        >
                          <Text
                            style={{
                              fontFamily: "Prompt",
                              fontSize: 12,
                              color: "#FFFFFF",
                              paddingHorizontal: 10,
                            }}
                          >
                            เฉพาะนักศึกษา
                          </Text>
                        </View>
                      ) : (
                        <View style={{ flexDirection: "row" }}>
                          <Text
                            style={[
                              styles.text,
                              {
                                textAlign: "right",
                                fontSize: size.width < 500 ? 12 : 16,
                                textDecorationLine:
                                  item?.courses?.discount != 0 ||
                                  (item?.courses?.discount != null &&
                                    "line-through"),
                              },
                            ]}
                          >
                            <NumberFormat
                              value={
                                item?.courses?.price == 0
                                  ? ""
                                  : item.courses?.price
                              }
                              displayType={"text"}
                              thousandSeparator={true}
                            />{" "}
                            {item?.courses?.price == 0
                              ? "ฟรี"
                              : item?.courses?.discount == 0 ||
                                (item?.courses?.discount == null && "บาท")}
                          </Text>
                          {item?.courses?.discount != 0 ||
                            (item?.courses?.discount != null && (
                              <Text
                                style={[
                                  styles.text,
                                  {
                                    textAlign: "right",
                                    fontSize: size.width < 500 ? 12 : 16,
                                  },
                                ]}
                              >
                                {item?.courses?.price -
                                  (parseInt(item?.courses?.discount) *
                                    item?.courses?.price) /
                                    100}
                                {item?.courses?.discount != 0 && " บาท"}
                              </Text>
                            ))}
                        </View>
                      )}
                      <Text
                        style={[
                          styles.text,
                          {
                            fontSize: size.width < 500 ? 12 : 16,
                            textAlign: "right",
                          },
                        ]}
                      >
                        <NumberFormat
                          value={item.length}
                          displayType={"text"}
                          thousandSeparator={true}
                        />{" "}
                        <FontAwesome5
                          name="user-friends"
                          size={20}
                          color="#5D5D5D"
                        />
                      </Text>
                    </View>
                  </View>
                </View>
              </TouchableOpacity>
            );
          }}
        />
      </View>
    </View>
  );
}
const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: "100%",
    backgroundColor: "#FFFFFF",
  },
  textTitle: {
    fontSize: 30,
    fontFamily: "Prompt",
    color: "#0B2051",
    marginTop: 45,
  },
  inputSearchName: {
    width: "100%",
    maxWidth: 400,
    minWidth: 150,
    height: 40,
    borderWidth: 1,
    borderColor: "#D9D9D9",
    paddingHorizontal: 15,
    fontFamily: "Prompt",
    fontSize: 20,
    color: "#646464",
  },
  viewDetail: {
    width: 275,
  },
  imgDetail: {
    width: 275,
    height: 195,
  },
  feedback: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  viewCourse: {
    padding: 12,
    // borderWidth: 1,
    // borderColor: "#BBB",
  },
  viewImg: {
    flexDirection: "row",
    alignItems: "center",
    minHeight: 70,
  },
  img: {
    width: 35,
    height: 35,
    borderRadius: 18,
    marginRight: 15,
  },
  text: {
    fontSize: 16,
    fontFamily: "Prompt",
    color: "#5D5D5D",
  },
});
const stylesResponsive = StyleSheet.create({
  textTitle: {
    fontSize: 22,
    fontFamily: "Prompt",
    color: "#0B2051",
    marginTop: 45,
  },
  inputSearchName: {
    width: "100%",
    maxWidth: 400,
    minWidth: 150,
    height: 40,
    borderWidth: 1,
    borderColor: "#D9D9D9",
    paddingHorizontal: 15,
    fontFamily: "Prompt",
    fontSize: 14,
    color: "#646464",
  },
});
