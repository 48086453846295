import { StyleSheet, Text, View,Image } from "react-native";
import React from "react";
import { useWindowSize } from "../components/responsive";
export default function loding() {
  const size = useWindowSize();
  return (
  
      <View style={{
    width: size.width,
    height: size.height,
    // aspectRatio: 0 * 1,
justifyContent:'center',backgroundColor:'#e8fafe'
    }}>
    <Image
    source={require('../img/1669001618978.jpg')}

    style={{

    resizeMode:size.width>=1500?'stretch': 'cover',
    width: '100%',
    height:size.width>=1500?'100%': size.width-size.width*0.45,

    }}
    />
</View>
   
  );
}

const styles = StyleSheet.create({});
