import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  Image,
  Dimensions,
  TouchableOpacity,
  StyleSheet,
  ScrollView,
  TextInput,
  FlatList,
  Linking,
  Clipboard,
} from "react-native";
import CryptoJS from "crypto-js";
import {
  MaterialCommunityIcons,
  MaterialIcons,
  FontAwesome5,
} from "@expo/vector-icons";
import Header from "../components/header";
import { BottomPart, bottomPart } from "./home";
import CoursePage0 from "./coursePage0";
import CoursePage1 from "./coursePage1";
import CousePage2 from "./coursePage2";
import CoursePage3 from "./coursePage3";
import CoursePage4 from "./coursePage4";
import Modal from "modal-react-native-web";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import GoogleLogin from "react-google-login";

import {
  authActionLogin,
  authActionRating,
  authActionRegister,
  authActionStartCourseCreate,
} from "../../action/authAction";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  courseState,
  detailLessonState,
  lessonState,
  lessonVideoState,
  otherStates,
  registerState,
  subjecttestState,
  testState,
  tokenState,
  userState,
} from "../../reducer/recoil";
import { apiservice } from "../../service";
import { useWindowSize } from "../components/responsive";
import NumberFormat from "react-number-format";
import ReactStars from "react-rating-stars-component";
import { useIsFocused } from "@react-navigation/native";
import axios from "axios";
import moment from "moment/moment";

const { width, height } = Dimensions.get("window");
let selectData = ["คำอธิบาย", "บทเรียน", "ข้อมูลผู้สอน", "FAQ", "รีวิว"];

export default function course({ navigation, route }) {
  const [courseStates, setCourseStates] = useRecoilState(courseState);
  const [testStates, setTestStates] = useRecoilState(testState);
  const [subjectTestStates, setSubjectTestStates] =
    useRecoilState(subjecttestState);
  const [lessonStates, setLessonStates] = useRecoilState(lessonState);
  const [otherState, setotherStates] = useRecoilState(otherStates);
  const [lessonVideoStates, setLessonVideoStates] =
    useRecoilState(lessonVideoState);
  const data = courseStates;

  const [confirmPass, setConfirmPass] = useState("");
  const [checkUser, setCheckUser] = useState(false);
  const [checkEmail, setCheckEmail] = useState(false);
  const [checkPass, setCheckPass] = useState(false);
  const [checkText, setCheckText] = useState(false);
  const [password, setPassword] = useState(false);
  const [modalError, setModalError] = useState(false);
  const [modalLogin, setModalLogin] = useState(false);
  const [check, setCheck] = useState(false);
  const [check1, setCheck1] = useState(false);
  const users = useRecoilValue(userState);
  const [checkcondition, setCheckcondition] = useState(false);
  const [Oncecourse, myOncecourse] = useState(null);
  const [page, setPage] = useState(0);
  const [pageModal, setPageModal] = useState(0);
  const [sumIndex, setSumIndex] = useState();
  const [modalReview, setModalReview] = useState(false);
  const [id, setId] = useState();
  const dataStudymodel = data.studymodel;
  const focus = useIsFocused();
  const [dataRegis, setDataRegis] = useRecoilState(registerState);
  const [DataRating, setDataRating] = useState([]);
  const [status, setStatus] = useState();
  const [checkStudent, setCheckStudent] = useState();
  const [end, setEnd] = useState(false);
  const [pending, setPending] = useState(false);
  const [rating, setrating] = useState();
  const [rating1, setrating1] = useState();
  const [rating2, setrating2] = useState();
  const [rating3, setrating3] = useState();
  const [rating4, setrating4] = useState();
  const [rating5, setrating5] = useState();
  const [person, setperson] = useState();
  const [TopCourse, setTopCourse] = useState([]);
  const [state, setState] = useState({
    username: "",
    password: "",
  });

  const [state1, setState1] = useState({
    role: "USER",
    sub_role: "MAIN",
    username: "",
    email: "",
    password: "",
  });
  const [token, setToken] = useRecoilState(tokenState);
  const emailValid =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  function errors({ value, type }) {
    if (type == "email") {
      return emailValid.test(value);
    }
  }

  useEffect(() => {
    setCheckcondition(false);
    if (token && courseStates && focus) {
      getProfile(token);
      checkStart();

      if (end) {
        name1();
      }
    }
    name();
    rate();
    if (route?.params?.status == "complete") {
      checkall();
    }
  }, [token, courseStates, focus]);
  useEffect(() => {
    getAllcourse(token);
  }, []);

  async function getAllcourse(token) {
    try {
      const response = await apiservice({
        path: "/course/getallmain?page=1&lan=TH",
        method: "get",
      });
      if (response.status == 200) {
        setTopCourse(
          response?.data?.recent.filter((item) => item.university_id == null)
        );
      }
    } catch (error) {
      throw error;
    }
  }
  async function checkall() {
    const res1 = await apiservice({
      path: "/chillpay/checkpayments?transNo=" + route?.params?.transNo,
    });

    const createpaystatus = await apiservice({
      path: "/chillpay/postpayment",
      method: "post",
      body: {
        netAmount: parseInt(res1?.data?.Amount) / 100,
        payLinkId: route?.params?.orderNo,
        transactionId: route?.params?.transNo,
        uid: users?.uid,
        course_id: courseStates?.id,
        course_owner_id: courseStates?.user?.user_accounts?.uid,
      },
    });

    if (createpaystatus.status == 200) {
      let state = {
        ...courseStates,
        course_id: courseStates?.id,
        Type: "PENDING",
      };

      delete state.id;

      const addCourse = await authActionStartCourseCreate({
        state,
        token: token?.accessToken,
      });
      setTimeout(() => {
        window.location.replace("https://courseoncloud.com/main/Course");
      }, 700);
      //
      // navigation.goBack();
    }
  }

  async function name1() {
    const response = await apiservice({
      path:
        "/start-course/myOncecourse?course_id=" + subjectTestStates.course_id,
      method: "get",
      token: token?.accessToken,
    });
    if (response.status == 200) {
      myOncecourse(response.data);
      setStatus(
        response.data.progress[subjectTestStates.Mainindex].listen_detail[
          subjectTestStates.index
        ].status
      );
    }
  }
  async function name() {
    const response = await apiservice({
      path: "/start-course/review/" + data.id,
      method: "get",
      // token: token?.accessToken,
    });
    if (response.status == 200) {
      setDataRating(response.data);
    }
  }
  async function rate() {
    const response = await apiservice({
      path: "/start-course/person?course_id=" + data.id,
      method: "get",
      // token: token?.accessToken,
    });
    if (response.status == 200) {
      setrating(response.data);
      setrating1((response.data.star?.["1"] * 100) / response.data.star.all);
      setrating2((response.data.star?.["2"] * 100) / response.data.star.all);
      setrating3((response.data.star?.["3"] * 100) / response.data.star.all);
      setrating4((response.data.star?.["4"] * 100) / response.data.star.all);
      setrating5((response.data.star?.["5"] * 100) / response.data.star.all);
    } else {
    }
  }
  async function checkStart() {
    const response = await apiservice({
      path: "/start-course/myOncecourse?course_id=" + data.id,
      method: "get",
      token: token?.accessToken,
    });

    if (response.status == 200) {
      setCheckcondition(true);
      myOncecourse(response.data);
      let num = 0;
      let num1 = 0;

      response.data.progress.map((items) => {
        items.listen_detail.map((e) => {
          if (e.status == true) {
            num = num + 1;
          }
        });
      });
      dataStudymodel.map((items) => {
        items.listen_detail.map((e) => {
          num1 = num1 + 1;
        });
      });
      setEnd(num == num1);
      setPending(num != 0 && num < num1);
    }
  }

  async function getProfile(token) {
    try {
      const response = await apiservice({
        path: "/authen/user",
        method: "get",
        token: token?.accessToken,
      });
      if (response.status == 200) {
        setCheckStudent(response.data.result?.user_accounts);
        setId(response.data.result.id);
      }
    } catch (error) {
      throw error;
    }
  }
  async function hidereview() {
    const response = await apiservice({
      path: "/start-course/hidereview/" + data.id + "?uid=",
      method: "get",
      token: token?.accessToken,
    });
    if (response.status == 200) {
    } else {
    }
  }

  async function onPress() {
    if (state.username.length > 0 && state.password.length > 0) {
      const login = await authActionLogin(state);
      setToken(login);
      if (login) {
        setStatus();
        setModalLogin(!modalLogin);
        // navigation.navigate("Lesson");
      }
      if (!login) {
        setStatus(login);
      }
    }
    if (state.username == "" || state.password == "") {
      setStatus(false);
    }
  }
  async function onPress1() {
    if (
      state1.username.length > 0 &&
      state1.email.length > 0 &&
      state1.password.length > 0
    ) {
      if (state1.password != confirmPass) {
        setPassword(true);
      }
      if (state1.password.length >= 8) {
        if (state1.password == confirmPass) {
          const register = await authActionRegister(state1);

          if (register) {
            setStatus();
            setPassword(false);
            setState1({
              role: "USER",
              sub_role: "MAIN",
              username: "",
              email: "",
              password: "",
            });
            setConfirmPass("");
            setCheck1(false);
            setPageModal(0);
          }
          if (!register) {
            setModalError(true);
            setStatus(register);
          }
        }
      }
    }
    if (state1.username.length == 0) {
      setCheckUser(true);
    }
    if (state1.email.length == 0) {
      setCheckEmail(true);
    }
    if (state1.password.length == 0) {
      setCheckPass(true);
    }
  }
  async function sendRating() {
    if (Rating.rating != "") {
    }
    const rating = await authActionRating(state);

    if (rating) {
      setModalReview(!modalReview);
    }
    if (!rating) {
    }
  }
  const [Rating, setRating] = useState({
    course_id: data.id,
    uid: users.uid,
    rating: "",
    about: "",
  });

  async function Chanfd(params, Mainindex, i) {
    if (params.type == "EXAM") {
      const res = await apiservice({
        path: "/quiz/get/" + params.education_id,
        method: "get",
        token: token?.accessToken,
      });
      setTestStates({
        ...params,
        ...res.data,
        ...Oncecourse,
        Mainindex,
        index: i,
        number: 0,
        dataStudymodel: data.studymodel,
      });
      setTimeout(() => {
        navigation.navigate("Test");
      }, 1000);
    } else if (params.type == "DESCRIPTION") {
      const res = await apiservice({
        path: "/course/geteducation/" + params.education_id,
        method: "get",
        token: token?.accessToken,
      });
      if (res.status == 200) {
        if (res.data.TYPE == "VDO") {
          setLessonVideoStates({
            ...params,
            ...res.data,
            ...Oncecourse,
            Mainindex,
            index: i,
            number: 0,
            dataStudymodel: data.studymodel,
          });
          setTimeout(() => {
            navigation.navigate("LessonVideo");
          }, 500);
        } else {
          setLessonStates({
            ...params,
            ...res.data,
            ...Oncecourse,
            Mainindex,
            index: i,
            number: 0,
            dataStudymodel: data.studymodel,
          });
          setTimeout(() => {
            navigation.navigate("Lesson");
          }, 900);
        }
      }
    } else if (params.type == "WORK") {
      const res = await apiservice({
        path: "/course/getwork/" + params.education_id,
        method: "get",
        token: token?.accessToken,
      });
      setSubjectTestStates({
        ...res.data,
        ...params,
        ...Oncecourse,
        Mainindex,
        index: i,
        number: 0,
        dataStudymodel: data.studymodel,
      });
      setTimeout(() => {
        navigation.push("SubjectiveTest");
      }, 500);
    } else {
      const res = await apiservice({
        path: "/quiz/get/" + params.education_id,
        method: "get",
        token: token?.accessToken,
      });
      setSubjectTestStates({
        ...res.data,
        ...params,
        ...Oncecourse,
        Mainindex,
        index: i,
        number: 0,
        dataStudymodel: data.studymodel,
      });
      setTimeout(() => {
        navigation.push("SubjectiveTest");
      }, 500);
    }
  }
  const responseFacebook = async (response) => {
    const res = await apiservice({
      path: "/authen/loginfacebook",
      method: "POST",
      body: {
        telephoneNo: null,
        image_url: response?.picture?.data?.url,
        firstName: response?.name?.split(" ")[0],
        lastName: response?.name?.split(" ")[1],
        email: response?.email,
        password: response?.id,
        gender: null,
        birthday: null,
        username: response?.id,
        Type: "FACEBOOK",
      },
    });

    setToken(res.data);

    if (res.data) {
      setStatus();
      navigation.navigate("Home");
    }
  };
  const responseGoogle = async (response) => {
    const res = await apiservice({
      path: "/authen/logingoogle",
      method: "POST",
      body: {
        telephoneNo: null,
        image_url: response.profileObj.imageUrl,
        firstName: response.profileObj.givenName,
        lastName: response.profileObj.familyName,
        email: response.profileObj.email,
        password: response.profileObj.googleId,
        gender: null,
        birthday: null,
        username: response.profileObj.googleId,
        Type: "GOOGLE",
      },
    });

    setToken(res.data);

    if (res.data) {
      setStatus();
      navigation.navigate("Home");
    }
  };
  async function getStart(id) {
    try {
      const response = await apiservice({
        path: "/course/getstart/" + id,
        method: "get",
        token: token?.accessToken,
      });
      if (response.status == 200) {
      } else {
      }
    } catch (error) {
      throw error;
    }
  }
  // function percentcondition() {
  //   rating?.star?.1 = rating?.star?.1+ 5 * 10
  // }
  const size = useWindowSize();
  if (data.studymodel == undefined) {
    return null;
  }
  return (
    <View style={styles.container}>
      <Modal
        transparent={true}
        visible={modalLogin}
        onRequestClose={() => {
          Alert.alert("Modal has been closed.");
          setModalLogin(!modalLogin);
        }}
      >
        <View style={styles.bgModal}>
          <TouchableOpacity
            onPress={() => {
              setModalLogin(false);
            }}
            style={{
              position: "fixed",
              top: "0px",
              right: "0px",
              bottom: "0px",
              left: "0px",
            }}
          />
          <View
            style={{
              width: size.width * 0.3,
              maxWidth: 600,

              alignSelf: "center",
              justifyContent: "center",
              backgroundColor: "transparent",
              paddingBottom: 20,
            }}
          >
            <View style={{ flexDirection: "row" }}>
              <TouchableOpacity
                onPress={() => {
                  setPageModal(0);
                }}
                style={{
                  height: 60,
                  alignItems: "center",
                  justifyContent: "center",
                  width: size.width * 0.165,

                  borderBottomWidth: 60,
                  borderBottomColor: pageModal == 0 ? "#0D88F0" : "#FFF",

                  backgroundColor: "transparent",

                  borderRightWidth: 60,
                  borderRightColor: "transparent",
                  borderStyle: "solid",
                  zIndex: pageModal == 0 ? 99 : 1,
                }}
              >
                <Text
                  style={{
                    fontSize: 30,
                    fontFamily: "Prompt",
                    color: pageModal == 0 ? "#FFF" : "#646464",
                    marginTop: 60,
                    // marginLeft: index == 1 && -size.width * 0.03,
                  }}
                >
                  {"เข้าสู่ระบบ"}
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => {
                  setPageModal(1);
                }}
                style={{
                  height: 60,
                  alignItems: "center",
                  justifyContent: "center",
                  width: size.width * 0.165,

                  borderBottomWidth: 60,
                  borderBottomColor: pageModal == 1 ? "#0D88F0" : "#FFF",
                  borderLeftWidth: 50,
                  borderLeftColor: "transparent",
                  backgroundColor: "transparent",
                  marginLeft: -size.width * 0.03,
                  borderStyle: "solid",
                  zIndex: pageModal == 1 ? 99 : 1,
                }}
              >
                <Text
                  style={{
                    fontSize: 30,
                    fontFamily: "Prompt",
                    color: pageModal == 1 ? "#FFF" : "#646464",
                    marginTop: 60,
                    // marginLeft: index == 1 && -size.width * 0.03,
                  }}
                >
                  {"สมัครสมาชิก"}
                </Text>
              </TouchableOpacity>
            </View>
            {pageModal == 0 && (
              <View
                style={{
                  paddingHorizontal: 50,
                  backgroundColor: "#FFF",
                  paddingVertical: 60,
                }}
              >
                <Text style={[styles.text18, { fontSize: 25 }]}>
                  ชื่อผู้ใช้งาน
                </Text>
                <TextInput
                  autoComplete="off"
                  defaultValue={state.username}
                  onChangeText={(text) =>
                    setState({ ...state, username: text })
                  }
                  style={[
                    styles.text18,
                    styles.inputLoginModal,
                    { borderColor: status == false ? "red" : "#EEEEEE" },
                  ]}
                />
                <Text style={[styles.text18, { marginTop: 24, fontSize: 25 }]}>
                  รหัสผ่าน
                </Text>
                <TextInput
                  autoComplete="off"
                  defaultValue={state.password}
                  onChangeText={(text) =>
                    setState({ ...state, password: text })
                  }
                  secureTextEntry
                  style={[
                    styles.text18,
                    styles.inputLoginModal,
                    { borderColor: status == false ? "red" : "#EEEEEE" },
                  ]}
                />
                {status == false && (
                  <Text
                    style={{
                      fontSize: 14,
                      fontFamily: "Prompt",
                      color: "red",
                    }}
                  >
                    ชื่อผู้ใช้งานหรือรหัสผ่านไม่ถูกต้อง
                  </Text>
                )}
                <TouchableOpacity
                  onPress={() => {
                    setCheck((val) => !val);
                  }}
                  style={{
                    flexDirection: "row",
                    marginTop: 15,
                    width: "50%",
                    alignItems: "center",
                  }}
                >
                  <MaterialCommunityIcons
                    name={check ? "checkbox-marked" : "checkbox-blank-outline"}
                    size={24}
                    color="#eee"
                  />
                  <Text style={[styles.text18, { marginLeft: 10 }]}>
                    จำชื่อผู้ใช้
                  </Text>
                </TouchableOpacity>
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <TouchableOpacity
                    onPress={() => {
                      setCheck1((val) => !val);
                    }}
                    style={{
                      flexDirection: "row",
                      marginTop: 15,
                      width: "50%",
                      alignItems: "center",
                    }}
                  >
                    <MaterialCommunityIcons
                      name={
                        check1 ? "checkbox-marked" : "checkbox-blank-outline"
                      }
                      size={24}
                      color="#eee"
                    />
                    <Text style={[styles.text18, { marginLeft: 10 }]}>
                      จำชื่อรหัสผ่าน
                    </Text>
                  </TouchableOpacity>
                  <TouchableOpacity
                    onPress={() => {
                      setModalLogin(!modalLogin);
                      navigation.navigate("NewPassword");
                    }}
                  >
                    <Text
                      style={[
                        styles.text18,
                        { textDecorationLine: "underline", marginTop: 15 },
                      ]}
                    >
                      ลืมรหัสผ่าน?
                    </Text>
                  </TouchableOpacity>
                </View>
                <TouchableOpacity
                  onPress={onPress}
                  style={{
                    width: "100%",

                    height: 40,
                    backgroundColor: "#0D88F0",
                    borderRadius: 5,
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: 21,
                    alignSelf: "center",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 20,
                      fontFamily: "Prompt",
                      color: "#FFFFFF",
                    }}
                  >
                    เข้าสู่ระบบ
                  </Text>
                </TouchableOpacity>
                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    marginTop: 30,

                    justifyContent: "space-between",
                  }}
                >
                  <View
                    style={{
                      height: 1,
                      backgroundColor: "#707070",
                      width: "30%",
                    }}
                  />
                  <Text
                    style={{
                      fontSize: 20,
                      fontFamily: "Prompt",
                      color: "#707070",
                      marginHorizontal: 10,
                    }}
                  >
                    เข้าสู่ระบบผ่าน
                  </Text>
                  <View
                    style={{
                      height: 1,
                      backgroundColor: "#707070",
                      width: "30%",
                    }}
                  />
                </View>

                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <FacebookLogin
                    appId="1303789190134568"
                    fields="birthday,name,email,picture"
                    cssClass="fbtn"
                    disableMobileRedirect={true}
                    autoLoad={false}
                    isMobile={false}
                    callback={responseFacebook}
                    render={(renderProps) => (
                      <TouchableOpacity
                        onPress={renderProps.onClick}
                        style={[
                          styles.button,
                          { marginTop: size.width < 800 ? 20 : 20 },
                        ]}
                      >
                        <Image
                          style={[
                            styles.img,
                            {
                              width: size.width < 800 ? 20 : 30,
                              height: size.width < 800 ? 20 : 30,
                            },
                          ]}
                          source={require("../img/1024px-Facebook_Logo_(2019).png")}
                        />
                        <Text
                          style={[
                            styles.text,
                            { fontSize: size.width < 1280 ? 14 : 20 },
                          ]}
                        >
                          Facebook
                        </Text>
                      </TouchableOpacity>
                    )}
                  />

                  <GoogleLogin
                    render={(renderProps) => (
                      <TouchableOpacity
                        onPress={renderProps.onClick}
                        style={[
                          styles.button,
                          { marginTop: size.width < 800 ? 20 : 20 },
                        ]}
                      >
                        <Image
                          style={[
                            styles.img,
                            {
                              width: size.width < 800 ? 20 : 30,
                              height: size.width < 800 ? 20 : 30,
                            },
                          ]}
                          source={require("../img/google_PNG19630.png")}
                        />
                        <Text
                          style={[
                            styles.text,
                            { fontSize: size.width < 1280 ? 14 : 20 },
                          ]}
                        >
                          Google
                        </Text>
                      </TouchableOpacity>
                    )}
                    clientId="648121112048-2bn9ranmpnpf2qkiocd31hkd27qkbaob.apps.googleusercontent.com"
                    buttonText="Login"
                    onSuccess={responseGoogle}
                    cookiePolicy={"single_host_origin"}
                  />
                </View>
              </View>
            )}
            {pageModal == 1 && (
              <View
                style={{
                  paddingHorizontal: 50,
                  backgroundColor: "#FFF",
                  paddingVertical: 60,
                }}
              >
                <View style={styles.viewUsername}>
                  <View style={{ marginHorizontal: 13, width: "45%" }}>
                    <Text style={styles.text18}>ชื่อผู้ใช้</Text>
                    <TextInput
                      autoComplete="off"
                      value={state1.username}
                      onChangeText={(text) => {
                        if (state1 != "") {
                          setCheckText(false);
                          setCheckUser(false);
                          setCheckEmail(false);
                          setCheckPass(false);
                        }
                        setState1({ ...state1, username: text });
                      }}
                      style={styles.textInput}
                    />
                    {checkText && (
                      <Text style={styles.text14R}>
                        กรุณากรอกข้อมูลให้ครบถ้วน
                      </Text>
                    )}
                    {checkUser && (
                      <Text style={styles.text14R}>
                        กรุณากรอกข้อมูลให้ครบถ้วน
                      </Text>
                    )}
                  </View>
                  <View style={{ marginHorizontal: 13, width: "45%" }}>
                    <Text style={styles.text18}>อีเมล</Text>
                    <TextInput
                      autoComplete="off"
                      value={state1.email}
                      onChangeText={(text) => {
                        if (state != "") {
                          setCheckText(false);
                          setCheckUser(false);
                          setCheckEmail(false);
                          setCheckPass(false);
                        }
                        setState1({ ...state1, email: text });
                      }}
                      style={styles.textInput}
                    />
                    {state1.email.length > 0 &&
                      !errors({ value: state1.email, type: "email" }) && (
                        <Text style={styles.text14R}>
                          รูปแบบของอีเมลไม่ถูกต้อง
                        </Text>
                      )}
                    {checkText && (
                      <Text style={styles.text14R}>
                        กรุณากรอกข้อมูลให้ครบถ้วน
                      </Text>
                    )}
                    {checkEmail && (
                      <Text style={styles.text14R}>
                        กรุณากรอกข้อมูลให้ครบถ้วน
                      </Text>
                    )}
                  </View>
                </View>
                <View style={{ width: "100%" }}>
                  <View style={styles.viewPassWord}>
                    <View style={{ marginHorizontal: 13, width: "45%" }}>
                      <Text style={styles.text18}>รหัสผ่าน</Text>
                      <TextInput
                        autoComplete="off"
                        value={state1.password}
                        onChangeText={(text) => {
                          if (state1 != "") {
                            setCheckText(false);
                            setCheckUser(false);
                            setCheckEmail(false);
                            setCheckPass(false);
                          }
                          setState1({ ...state1, password: text });
                        }}
                        secureTextEntry
                        style={styles.textInput}
                      />
                      {state1.password.length > 0 &&
                        state1.password.length < 8 && (
                          <Text style={styles.text14R}>
                            ต้องมีรหัสผ่านอย่างน้อย 8 ตัว
                          </Text>
                        )}
                      {checkText && (
                        <Text style={styles.text14R}>
                          กรุณากรอกข้อมูลให้ครบถ้วน
                        </Text>
                      )}
                      {checkPass && (
                        <Text style={styles.text14R}>
                          กรุณากรอกข้อมูลให้ครบถ้วน
                        </Text>
                      )}
                    </View>
                    <View style={{ marginHorizontal: 13, width: "45%" }}>
                      <Text style={styles.text18}>ยืนยันรหัสผ่าน</Text>
                      <TextInput
                        autoComplete="off"
                        value={confirmPass}
                        onChangeText={(text) => {
                          if (state1 != "") {
                            setCheckText(false);
                            setCheckUser(false);
                            setCheckEmail(false);
                            setCheckPass(false);
                          }
                          setConfirmPass(text);
                        }}
                        secureTextEntry
                        style={styles.textInput}
                      />
                      {password && (
                        <Text style={styles.text14R}>รหัสผ่านไม่ตรงกัน</Text>
                      )}
                      {checkText && (
                        <Text style={styles.text14R}>
                          กรุณากรอกข้อมูลให้ครบถ้วน
                        </Text>
                      )}
                      {checkPass && (
                        <Text style={styles.text14R}>
                          กรุณากรอกข้อมูลให้ครบถ้วน
                        </Text>
                      )}
                    </View>
                  </View>
                  <View
                    style={{
                      flexDirection: "row",

                      marginHorizontal: 10,
                      width: "100%",

                      alignSelf: "center",
                    }}
                  >
                    <TouchableOpacity
                      onPress={() => {
                        setCheck1((val) => !val);
                      }}
                    >
                      <MaterialCommunityIcons
                        name={
                          check1 ? "checkbox-marked" : "checkbox-blank-outline"
                        }
                        size={30}
                        color="#BBBBBB"
                      />
                    </TouchableOpacity>
                    <Text
                      style={{
                        fontSize: size.width < 500 ? 14 : 16,
                        fontFamily: "Prompt",
                        color: "#646464",
                        marginLeft: 20,
                      }}
                    >
                      ฉันอ่านและยอมรับ
                      <TouchableOpacity
                        onPress={() => {
                          setModalLogin(false);
                          navigation.navigate("LearnerConditions");
                        }}
                      >
                        <Text
                          style={{
                            color: "#E82626",
                            textDecoration: "underline #E82626",
                          }}
                        >
                          เงื่อนไขและข้อตกลง
                        </Text>
                      </TouchableOpacity>
                      ในการสมัครเป็นผู้เรียนกับCoC
                    </Text>
                  </View>
                </View>
                <TouchableOpacity
                  disabled={check1 ? false : true}
                  onPress={onPress1}
                  style={[
                    styles.button1,
                    {
                      backgroundColor: check1 ? "#0D88F0" : "#CCCCCC",
                    },
                  ]}
                >
                  <Text style={styles.textButton}>สมัครสมาชิก</Text>
                </TouchableOpacity>
                <TouchableOpacity
                  disabled={check1 ? false : true}
                  onPress={() => {
                    if (
                      state1.username.length > 0 &&
                      state1.email.length > 0 &&
                      state1.password.length >= 8
                    ) {
                      if (state1.password != confirmPass) {
                        setPassword(true);
                      } else {
                        setDataRegis(state1);
                        setModalLogin(false);
                        setTimeout(() => {
                          navigation.navigate("Instructor");
                        }, 500);
                      }
                    }
                    if (state1.username.length == 0) {
                      setCheckUser(true);
                    }
                    if (state1.email.length == 0) {
                      setCheckEmail(true);
                    }
                    if (state1.password.length == 0) {
                      setCheckPass(true);
                    }
                  }}
                  style={[
                    styles.button1,
                    {
                      backgroundColor: check1 ? "#0D88F0" : "#CCCCCC",
                    },
                  ]}
                >
                  <Text style={styles.textButton}>สมัครเป็นผู้สอน</Text>
                </TouchableOpacity>
                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    marginTop: 30,

                    justifyContent: "space-between",
                  }}
                >
                  <View
                    style={{
                      height: 1,
                      backgroundColor: "#707070",
                      width: "30%",
                    }}
                  />
                  <Text
                    style={{
                      fontSize: 20,
                      fontFamily: "Prompt",
                      color: "#707070",
                      marginHorizontal: 10,
                    }}
                  >
                    สมัครผ่าน
                  </Text>
                  <View
                    style={{
                      height: 1,
                      backgroundColor: "#707070",
                      width: "30%",
                    }}
                  />
                </View>
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <FacebookLogin
                    appId="1303789190134568"
                    fields="birthday,name,email,picture"
                    callback={responseFacebook}
                    render={(renderProps) => (
                      <TouchableOpacity
                        onPress={renderProps.onClick}
                        style={[
                          styles.button,
                          { marginTop: size.width < 800 ? 20 : 20 },
                        ]}
                      >
                        <Image
                          style={[
                            styles.img,
                            {
                              width: size.width < 800 ? 20 : 30,
                              height: size.width < 800 ? 20 : 30,
                            },
                          ]}
                          source={require("../img/1024px-Facebook_Logo_(2019).png")}
                        />
                        <Text
                          style={[
                            styles.text,
                            { fontSize: size.width < 1280 ? 14 : 20 },
                          ]}
                        >
                          Facebook
                        </Text>
                      </TouchableOpacity>
                    )}
                  />

                  <GoogleLogin
                    render={(renderProps) => (
                      <TouchableOpacity
                        onPress={renderProps.onClick}
                        style={[
                          styles.button,
                          { marginTop: size.width < 800 ? 20 : 20 },
                        ]}
                      >
                        <Image
                          style={[
                            styles.img,
                            {
                              width: size.width < 800 ? 20 : 30,
                              height: size.width < 800 ? 20 : 30,
                            },
                          ]}
                          source={require("../img/google_PNG19630.png")}
                        />
                        <Text
                          style={[
                            styles.text,
                            { fontSize: size.width < 1280 ? 14 : 20 },
                          ]}
                        >
                          Google
                        </Text>
                      </TouchableOpacity>
                    )}
                    clientId="648121112048-2bn9ranmpnpf2qkiocd31hkd27qkbaob.apps.googleusercontent.com"
                    buttonText="Login"
                    onSuccess={responseGoogle}
                    cookiePolicy={"single_host_origin"}
                  />
                </View>
              </View>
            )}
          </View>
        </View>
      </Modal>
      <ScrollView stickyHeaderIndices={[0]}>
        <Header navigation={navigation} />
        <View
          style={{
            width: "100%",
            paddingBottom: 20,
            backgroundColor: "#FFF",
            paddingHorizontal: "10%",
          }}
        >
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Text
              style={{
                fontSize: size.width < 500 ? 22 : 40,
                fontFamily: "Prompt",
                color: "#0D88F0",
                width: size.width < 1280 ? "100%" : "50%",
                marginTop: 40,
              }}
            >
              {data.title}
            </Text>

            <TouchableOpacity
              onPress={() => {
                Clipboard.setString(
                  "https://courseoncloud.com/main/Share?course_id=" + data.id
                );

                alert("คัดลอกลิงก์แล้ว!");
              }}
              style={{
                width: size.width < 500 ? "20%" : "10%",
                height: 30,
                borderWidth: 1,
                borderColor: "#0D88F0",
                borderRadius: 3,
                justifyContent: "center",
                alignItems: "center",
                marginRight: 20,
              }}
            >
              <Text
                style={{ fontSize: 12, fontFamily: "Prompt", color: "#0D88F0" }}
              >
                แชร์คอร์ส
              </Text>
            </TouchableOpacity>
          </View>
          <View style={{ flexDirection: "row" }}>
            <FlatList
              data={data.TAG}
              horizontal
              showsHorizontalScrollIndicator={false}
              renderItem={({ index, item }) => {
                return (
                  <View
                    style={{
                      height: 20,
                      backgroundColor: "#FFFFFF",
                      borderRadius: 11,
                      justifyContent: "center",
                      alignItems: "center",
                      marginLeft: index != 0 ? 10 : 0,
                      paddingHorizontal: 10,
                      borderWidth: 1,
                      borderColor: "#DCDCDC",
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 10,
                        fontFamily: "Prompt",
                        color: "#646464",
                      }}
                    >
                      {item}
                    </Text>
                  </View>
                );
              }}
            />
          </View>
          <View
            style={{
              flexDirection:
                sumIndex > 0 || size.width < 1280 ? "column" : "row",
              justifyContent: "space-between",
              marginTop: height * 0.08,
            }}
          >
            <TouchableOpacity
              onPress={() => {
                setotherStates(data.user);
                setTimeout(() => {
                  navigation.navigate("otherprofile");
                }, 500);
              }}
              style={{
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Image
                style={{
                  width: size.width < 500 ? 50 : 70,
                  height: size.width < 500 ? 50 : 70,
                  borderRadius: 35,
                  marginRight: 10,
                  backgroundColor: "#CCC",
                }}
                source={{
                  uri:
                    data.available == "PENDING"
                      ? "https://api.courseoncloud.com/api/image/getimage/" +
                      data.teacher.image_url
                      : // (data.available == "APPROVE" &&
                      "https://api.courseoncloud.com/api/image/getimage/" +
                      data.user.user_accounts.image_url,
                  // (data.available == "APPROVE" &&
                  //   "https://api.courseoncloud.com/api/image/getimage/" +
                  //     data.img_content),
                }}
              />
              <View>
                <Text
                  style={{
                    fontSize: size.width < 500 ? 14 : 18,
                    fontFamily: "Prompt",
                    color: "#646464",
                  }}
                >
                  {data.available == "PENDING"
                    ? data.teacher.firstName + " " + data.teacher.lastName
                    : data.user.user_accounts.firstName +
                    " " +
                    data.user.user_accounts.lastName}

                  {/* {data.available == "APPROVE" && */}
                </Text>
                <Text
                  style={{
                    fontSize: 10,
                    fontFamily: "Prompt",
                    color: "#646464",
                  }}
                >
                  ผู้สอน
                </Text>
              </View>
            </TouchableOpacity>

            {data.person != null && (
              <FlatList
                data={data.person}
                numColumns={1}
                horizontal={sumIndex < 2 ? false : true}
                showsHorizontalScrollIndicator={false}
                renderItem={({ index, item }) => {
                  setSumIndex(index);
                  return (
                    <View
                      style={{
                        flexDirection: "row",
                        alignItems: "center",
                        marginLeft: sumIndex >= 1 ? 0 : 10,
                        marginTop: sumIndex < 2 && 10,
                      }}
                    >
                      <Image
                        style={{
                          width: size.width < 500 ? 50 : 70,
                          height: size.width < 500 ? 50 : 70,
                          borderRadius: 35,
                          marginRight: 10,
                          backgroundColor: "#CCCCCC",
                        }}
                        source={{
                          uri:
                            "https://api.courseoncloud.com/api/image/getimage/" +
                            item.img,
                        }}
                      />
                      <View>
                        <Text
                          style={{
                            fontSize: size.width < 500 ? 14 : 18,
                            fontFamily: "Prompt",
                            color: "#646464",
                          }}
                        >
                          {item.firstName + " " + item.lastName}
                        </Text>
                        <Text
                          style={{
                            fontSize: 10,
                            fontFamily: "Prompt",
                            color: "#646464",
                          }}
                        >
                          ผู้ช่วยสอน
                        </Text>
                      </View>
                    </View>
                  );
                }}
              />
            )}
            <View
              style={{
                flexDirection: "row",
                width: size.width < 1280 ? "100%" : "50%",
                alignItems: "center",
                marginTop: (size.width < 1280 && 20) || (sumIndex < 2 && 20),
              }}
            >
              <MaterialIcons name="people" size={24} color="#646464" />
              <Text
                style={{
                  fontSize: size.width < 500 ? 14 : 16,
                  fontFamily: "Prompt",
                  color: "#646464",
                  marginLeft: 10,
                }}
              >
                {rating?.person == undefined
                  ? "0 คนเรียนคอร์สนี้"
                  : rating?.person + " คนเรียนคอร์สนี้"}
              </Text>
            </View>
          </View>
        </View>
        <View
          style={{
            flexDirection: size.width < 1280 ? "column" : "row",
            width: "80%",

            alignSelf: "center",
            justifyContent: "space-between",
          }}
        >
          <View
            style={{
              width: size.width < 1280 ? "100%" : "61%",
              marginTop: 40,
            }}
          >
            <View>
              <FlatList
                horizontal
                data={selectData}
                showsHorizontalScrollIndicator={false}
                style={{}}
                renderItem={({ item, index }) => {
                  return (
                    <TouchableOpacity
                      style={{
                        backgroundColor: "#FFFFFF",
                        width:
                          size.width < 1280
                            ? size.width / 6.25
                            : size.width / 10.25,
                        height: 60,
                        justifyContent: "center",
                        alignItems: "center",
                        borderBottomWidth: 2,
                        borderBottomColor:
                          page == index ? "#0D88F0" : "#C4C4C4",
                      }}
                      onPress={() => {
                        setPage(index);
                      }}
                    >
                      <Text
                        style={{
                          fontFamily: "Prompt",
                          fontSize: size.width < 1280 ? 14 : 20,

                          color: page == index ? "#0D88F0" : "#646464",
                        }}
                      >
                        {item}
                      </Text>
                    </TouchableOpacity>
                  );
                }}
              />
            </View>
            {page == 0 && (
              <CoursePage0
                source={{
                  uri:
                    "https://api.courseoncloud.com/api/image/getimage/" +
                    data.img_content,
                }}
                url={data.vdo_url}
                route={data.about}
              />
            )}
            {page == 1 && (
              <CoursePage1
                Oncecourse={Oncecourse}
                navigation={navigation}
                dataStudymodel={dataStudymodel}
              />
            )}
            {page == 2 && (
              <CousePage2 navigation={navigation} route={data.announcement} />
            )}
            {page == 3 && (
              <CoursePage3 navigation={navigation} route={data.FAQ} />
            )}
            {page == 4 && (
              <CoursePage4
                use={setTimeout(() => {
                  name();
                }, 2000)}
                rating1={rating1}
                rating2={rating2}
                rating3={rating3}
                rating4={rating4}
                rating5={rating5}
                rate={data.rating}
                rating={DataRating}
                navigation={navigation}
              />
            )}
          </View>

          <View
            style={{
              width: size.width < 1280 ? "100%" : "32%",
              // minHeight: size.width < 1280 ? 0 : size.height,
              borderWidth: size.width < 1280 ? 0 : 1,
              borderColor: "#FFF",
              borderRadius: 5,
              padding: size.width < 750 ? 0 : 20,
              backgroundColor: "#FFFFFF",
              marginTop: size.width < 1280 ? 0 : -height * 0.2,
            }}
          >
            {size.width >= 1280 && (
              <Image
                resizeMode="stretch"
                style={{
                  width: "100%",
                  backgroundColor: "#CCC",
                  height: "100%",
                  maxHeight: 269,
                }}
                source={{
                  uri:
                    "https://api.courseoncloud.com/api/image/getimage/" +
                    data.img_content,
                }}
              />
            )}
            {end &&
              DataRating.filter((e) => {
                return e.user_accounts?.id == checkStudent?.id;
              }).length >
              0 ==
              false && (
                <TouchableOpacity
                  onPress={() => {
                    setModalReview(true);
                  }}
                  style={{
                    width: "100%",
                    height: 55,
                    borderWidth: 1,
                    borderColor: "#0D88F0",
                    borderRadius: 3,
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: 18,
                  }}
                >
                  <Text
                    style={{
                      fontSize: 20,
                      fontFamily: "Prompt",
                      color: "#0D88F0",
                    }}
                  >
                    {"รีวิวคอร์ส"}
                  </Text>
                </TouchableOpacity>
              )}
            <View
              style={{
                borderWidth: 1,
                borderColor: "#D1DDE5",
                marginTop: 38,
                paddingVertical: 45,
                paddingHorizontal: 20,
                marginBottom: size.width < 1280 && 38,
              }}
            >
              <View
                style={{
                  marginBottom: size.width < 750 && 38,
                  width: size.width < 1280 ? "100%" : "80%",
                  alignSelf: "center",
                }}
              >
                <View style={{ flexDirection: "row" }}>
                  <MaterialIcons
                    name="person"
                    size={size.width < 750 ? 20 : 30}
                    color="#646464"
                  />
                  <Text
                    style={[
                      size.width < 750
                        ? stylesResponsive.textRequirements
                        : styles.textRequirements,
                    ]}
                  >
                    {data.Type == "COURSESCHOOL" ? "เฉพาะนักศึกษา" : "ทั่วไป"}
                  </Text>
                </View>
                {checkcondition ||
                  token.role == "SCHOOL" ||
                  token.role == "TEACHER" ? (
                  <View>
                    {data.workDate != "" && data.meetingurl != "" && (
                      <View style={{ flexDirection: "row", marginTop: 24 }}>
                        <MaterialCommunityIcons
                          name="clock-time-four"
                          size={size.width < 750 ? 20 : 30}
                          color="#646464"
                        />
                        <View style={{ width: "100%" }}>
                          <Text
                            style={[
                              size.width < 750
                                ? stylesResponsive.textRequirements
                                : styles.textRequirements,
                            ]}
                          >
                            {"กําหนดเวลาการประชุมทางวิดีโอ"}
                          </Text>

                          <FlatList
                            numColumns={1}
                            data={data.workDate}
                            renderItem={({ item, index }) => {
                              return (
                                <Text
                                  style={[
                                    styles.textRequirements,
                                    { fontSize: width < 750 ? 16 : 20 },
                                  ]}
                                >
                                  {item.date}
                                  {"\nเวลา " +
                                    item.starttimer +
                                    " - " +
                                    item.endtimer}
                                </Text>
                              );
                            }}
                          />
                        </View>
                      </View>
                    )}
                  </View>
                ) : (
                  ""
                )}
                {data.meetingurl?.length > 0 && (
                  <View>
                    <View>
                      {data.meetingurl?.length > 0 && (
                        <View
                          style={{
                            flexDirection: "row",
                            marginTop: 24,
                          }}
                        >
                          <MaterialCommunityIcons
                            name="message-video"
                            size={size.width < 750 ? 20 : 30}
                            color="#646464"
                          />
                          <View style={{ width: "100%" }}>
                            <TouchableOpacity
                              onPress={() => {
                                Linking.openURL(data.meetingurl);
                              }}
                            >
                              <Text
                                style={[
                                  size.width < 750
                                    ? stylesResponsive.textRequirements
                                    : styles.textRequirements,
                                ]}
                              >
                                {data.meetingurl}
                              </Text>
                            </TouchableOpacity>
                          </View>
                        </View>
                      )}
                    </View>
                  </View>
                )}
                <View style={{ flexDirection: "row", marginTop: 24 }}>
                  <MaterialIcons
                    name="business-center"
                    size={size.width < 750 ? 20 : 30}
                    color="#646464"
                  />

                  <NumberFormat
                    value={data.length_detail}
                    displayType={"text"}
                    format={
                      data.length_detail < 100
                        ? "ความยาวเนื้อหา ## ชั่วโมง"
                        : "ความยาวเนื้อหา ##:## ชั่วโมง"
                    }
                    style={{
                      borderWidth: 0,
                      fontSize: size.width < 750 ? 14 : 20,
                      fontFamily: "Prompt",
                      color: "#646464",
                      paddingLeft: 30,
                    }}
                  />
                  {/* ความยาวเนื้อหา {data.length_detail} ชั่วโมง */}
                </View>
                {data?.datetime == "" && (
                  <View style={{ flexDirection: "row", marginTop: 24 }}>
                    <MaterialIcons
                      name="import-contacts"
                      size={size.width < 750 ? 20 : 30}
                      color="#646464"
                    />
                    <Text
                      style={[
                        size.width < 750
                          ? stylesResponsive.textRequirements
                          : styles.textRequirements,
                      ]}
                    >
                      {"ไม่จำกัดระยะเวลาในการเรียน"}
                    </Text>
                  </View>
                )}
                <View style={{ flexDirection: "row", marginTop: 24 }}>
                  <MaterialIcons
                    name="trending-up"
                    size={size.width < 750 ? 20 : 30}
                    color="#646464"
                  />
                  <Text
                    style={[
                      size.width < 750
                        ? stylesResponsive.textRequirements
                        : styles.textRequirements,
                    ]}
                  >
                    {data.percent}% ขึ้นไปเพื่อผ่านคอร์ส
                  </Text>
                </View>
                <View style={{ flexDirection: "row", marginTop: 24 }}>
                  <Image
                    style={{
                      width: 20,
                      height: 30,
                      tintColor: "#646464",
                      marginLeft: 5,
                    }}
                    source={require("../../assets/ic_sort_24px.png")}
                  />
                  <Text
                    style={[
                      size.width < 750
                        ? stylesResponsive.textRequirements
                        : styles.textRequirements,
                    ]}
                  >
                    {data.level}
                  </Text>
                </View>
                {data.certificate == true && (
                  <View style={{ flexDirection: "row", marginTop: 24 }}>
                    <MaterialIcons
                      name="check-box"
                      size={size.width < 750 ? 20 : 30}
                      color="#646464"
                    />
                    <Text
                      style={[
                        size.width < 750
                          ? stylesResponsive.textRequirements
                          : styles.textRequirements,
                      ]}
                    >
                      ได้รับ Certificate
                    </Text>
                  </View>
                )}
              </View>
              {!end ? (
                !pending ? (
                  <TouchableOpacity
                    disabled={
                      (!checkcondition &&
                        checkStudent?.university_id != null) ||
                        (!checkcondition && token?.role == "SCHOOL") ||
                        (!checkcondition && token?.role == "ADMIN") ||
                        (!checkcondition && token?.role == "SUPERADMIN") ||
                        id == courseStates.user.id
                        ? true
                        : false
                    }
                    onPress={async () => {
                      if (token == "") {
                        setModalLogin(true);
                      } else if (!checkcondition && data.price != 0) {
                        navigation.navigate("Payment");
                      } else if (checkcondition && token?.role == "USER") {
                        Oncecourse.progress.map((item, id) => {
                          const Mainindex = id;
                          if (id == 0) {
                            return {
                              ...item,
                              listen_detail: item.listen_detail.map(
                                (items, ins) => {
                                  if (ins == 0 && items.status == false) {
                                    const i = ins;
                                    Chanfd(items, Mainindex, i);
                                  } else if (
                                    ins !== 0 &&
                                    item.listen_detail[ins - 1].status ==
                                    true &&
                                    item.listen_detail[ins].status == false
                                  ) {
                                    const i = ins;
                                    Chanfd(items, Mainindex, i);
                                  }
                                }
                              ),
                            };
                          } else {
                            return {
                              ...item,
                              listen_detail: item.listen_detail.map(
                                (items, ins) => {
                                  if (
                                    ins == 0 &&
                                    Oncecourse.progress[id - 1].listen_detail[
                                      Oncecourse.progress[id - 1].listen_detail
                                        .length - 1
                                    ].status == true
                                  ) {
                                    const i = ins;
                                    Chanfd(items, Mainindex, i);
                                  } else if (
                                    ins !== 0 &&
                                    item.listen_detail[ins - 1].status ==
                                    true &&
                                    item.listen_detail[ins].status == false
                                  ) {
                                    const i = ins;
                                    Chanfd(items, Mainindex, i);
                                  }
                                }
                              ),
                            };
                          }
                        });
                        setTimeout(() => {
                          getStart(data.id);
                        }, 1000);
                      } else if (!checkcondition && data.price == 0) {
                        const createpaystatus = await apiservice({
                          path: "/chillpay/postpayment",
                          method: "post",
                          body: {
                            netAmount: 0,
                            payLinkId: moment().format("DD-MM-YYYYHHmmss"),
                            transactionId: moment().format("DD-MM-YYYYHHmmss"),
                            uid: users?.uid,
                            course_id: courseStates?.id,
                            course_owner_id:
                              courseStates?.user?.user_accounts?.uid,
                          },
                        });

                        let state = {
                          ...courseStates,
                          course_id: courseStates?.id,
                          Type: "PENDING",
                        };

                        delete state.id;

                        const addCourse = await authActionStartCourseCreate({
                          state,
                          token: token?.accessToken,
                        });
                        if (addCourse) {
                          window.location.replace("");
                        }
                      } else if (checkcondition && data.price == 0) {
                        const createpaystatus = await apiservice({
                          path: "/chillpay/postpayment",
                          method: "post",
                          body: {
                            netAmount: 0,
                            payLinkId: moment().format("DD-MM-YYYYHHmmss"),
                            transactionId: moment().format("DD-MM-YYYYHHmmss"),
                            uid: users?.uid,
                            course_id: courseStates?.id,
                            course_owner_id:
                              courseStates?.user?.user_accounts?.uid,
                          },
                        });

                        Oncecourse.progress.map((item, id) => {
                          const Mainindex = id;
                          if (id == 0) {
                            return {
                              ...item,
                              listen_detail: item.listen_detail.map(
                                (items, ins) => {
                                  if (ins == 0 && items.status == false) {
                                    const i = ins;
                                    Chanfd(items, Mainindex, i);
                                  } else if (
                                    ins !== 0 &&
                                    item.listen_detail[ins - 1].status ==
                                    true &&
                                    item.listen_detail[ins].status == false
                                  ) {
                                    const i = ins;
                                    Chanfd(items, Mainindex, i);
                                  }
                                }
                              ),
                            };
                          } else {
                            return {
                              ...item,
                              listen_detail: item.listen_detail.map(
                                (items, ins) => {
                                  if (
                                    ins == 0 &&
                                    Oncecourse.progress[id - 1].listen_detail[
                                      Oncecourse.progress[id - 1].listen_detail
                                        .length - 1
                                    ].status == true
                                  ) {
                                    const i = ins;
                                    Chanfd(items, Mainindex, i);
                                  } else if (
                                    ins !== 0 &&
                                    item.listen_detail[ins - 1].status ==
                                    true &&
                                    item.listen_detail[ins].status == false
                                  ) {
                                    const i = ins;
                                    Chanfd(items, Mainindex, i);
                                  }
                                }
                              ),
                            };
                          }
                        });
                        setTimeout(() => {
                          getStart(data.id);
                        }, 1000);
                      }
                    }}
                    style={{
                      width: "100%",
                      height: 55,
                      backgroundColor:
                        checkcondition && token?.role == "USER"
                          ? "#0D88F0"
                          : !checkcondition &&
                            data.price != 0 &&
                            id != courseStates?.user?.id
                            ? "#0D88F0"
                            : "#B71C1C",
                      borderRadius: 3,
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: 18,
                    }}
                  >
                    <Text
                      style={{
                        fontSize: size.width < 1280 ? 16 : 20,
                        fontFamily: "Prompt",
                        color: "#FFFFFF",
                      }}
                    >
                      {checkcondition && data.university_id != null ? (
                        "เริ่มเรียน"
                      ) : (!checkcondition &&
                        checkStudent?.university_id != null) ||
                        (!checkcondition && token?.role == "SCHOOL") ||
                        (!checkcondition && token?.role == "ADMIN") ||
                        (!checkcondition && token?.role == "SUPERADMIN") ||
                        id == courseStates.user.id ? (
                        "ไม่สามารถเรียนได้"
                      ) : !checkcondition && data.price != 0 ? (
                        <View style={{ flexDirection: "row" }}>
                          <Text
                            style={[
                              styles.text,
                              {
                                fontSize: size.width < 500 ? 12 : 16,
                                fontSize: 20,
                                color: "#FFF",
                              },
                            ]}
                          >
                            {"ชำระ "}
                          </Text>
                          <Text
                            style={[
                              styles.text,
                              {
                                textAlign: "right",
                                fontSize: size.width < 500 ? 12 : 16,
                                textDecorationLine:
                                  data.discount != 0 ||
                                  (data.discount != null && "line-through"),

                                fontSize: 20,
                                color: "#FFF",
                              },
                            ]}
                          >
                            <NumberFormat
                              value={data.price}
                              displayType={"text"}
                              thousandSeparator={true}
                            />{" "}
                            {data.discount == 0 ||
                              (data.discount == null && "บาท")}
                          </Text>
                          {data.discount != 0 ||
                            (data.discount != nulll && (
                              <Text
                                style={[
                                  styles.text,
                                  {
                                    textAlign: "right",
                                    fontSize: size.width < 500 ? 12 : 16,
                                    fontSize: 20,
                                    color: "#FFF",
                                  },
                                ]}
                              >
                                {data.price -
                                  (parseInt(data.discount) * data.price) / 100}
                                {data.discount != 0 && " บาท"}
                              </Text>
                            ))}
                        </View>
                      ) : !checkcondition && data.price == 0 ? (
                        "เพิ่มคอร์ส"
                      ) : (
                        "เริ่มเรียน"
                      )}
                      {/* {!checkcondition && data.price == 0 && "เริ่มเรียน"} */}

                      {/* {token?.role != "USER" &&
                  data.Type == "COURSESCHOOL" &&
                  "ไม่สามารถเรียนได้"} */}
                      {/* {token?.role == "SCHOOL" ||
                    token?.role == "ADMIN" ||
                    token?.role == "SUPERADMIN" ||
                    checkStudent?.university_id != null
                      ? "ไม่สามารถเรียนได้"
                      : !checkcondition &&
                        data.price != 0 &&
                        "ชำระเงิน " + data.price + " บาท"} */}
                    </Text>
                  </TouchableOpacity>
                ) : (
                  <TouchableOpacity
                    disabled={!checkcondition ? true : false}
                    onPress={async () => {
                      if (token == "") {
                        setModalLogin(true);
                      } else if (
                        checkcondition &&
                        token?.role == "USER" &&
                        data.Type == "COURSESCHOOL"
                      ) {
                        Oncecourse.progress.map((item, id) => {
                          const Mainindex = id;
                          if (id == 0) {
                            return {
                              ...item,
                              listen_detail: item.listen_detail.map(
                                (items, ins) => {
                                  if (ins == 0 && items.status == false) {
                                    const i = ins;
                                    if (items.status == false) {
                                      Chanfd(items, Mainindex, i);
                                    }
                                  } else if (
                                    ins !== 0 &&
                                    item.listen_detail[ins - 1].status ==
                                    true &&
                                    item.listen_detail[ins].status == false
                                  ) {
                                    const i = ins;
                                    if (items.status == false) {
                                      Chanfd(items, Mainindex, i);
                                    }
                                  }
                                }
                              ),
                            };
                          } else {
                            return {
                              ...item,
                              listen_detail: item.listen_detail.map(
                                (items, ins) => {
                                  if (
                                    ins == 0 &&
                                    Oncecourse.progress[id - 1].listen_detail[
                                      Oncecourse.progress[id - 1].listen_detail
                                        .length - 1
                                    ].status == true &&
                                    Oncecourse.progress[id].listen_detail[
                                      Oncecourse.progress[id].listen_detail
                                        .length - 1
                                    ].status != true
                                  ) {
                                    const i = ins;
                                    if (items.status == false) {
                                      Chanfd(items, Mainindex, i);
                                    }
                                  } else if (
                                    ins !== 0 &&
                                    item.listen_detail[ins - 1].status ==
                                    true &&
                                    item.listen_detail[ins].status == false
                                  ) {
                                    const i = ins;
                                    if (items.status == false) {
                                      Chanfd(items, Mainindex, i);
                                    }
                                  }
                                }
                              ),
                            };
                          }
                        });
                      } else if (data.price != 0) {
                        Oncecourse.progress.map((item, id) => {
                          const Mainindex = id;
                          if (id == 0) {
                            return {
                              ...item,
                              listen_detail: item.listen_detail.map(
                                (items, ins) => {
                                  if (ins == 0 && items.status == false) {
                                    const i = ins;
                                    if (items.status == false) {
                                      Chanfd(items, Mainindex, i);
                                    }
                                  } else if (
                                    ins !== 0 &&
                                    item.listen_detail[ins - 1].status ==
                                    true &&
                                    item.listen_detail[ins].status == false
                                  ) {
                                    const i = ins;
                                    if (items.status == false) {
                                      Chanfd(items, Mainindex, i);
                                    }
                                  }
                                }
                              ),
                            };
                          } else {
                            return {
                              ...item,
                              listen_detail: item.listen_detail.map(
                                (items, ins) => {
                                  if (
                                    ins == 0 &&
                                    Oncecourse.progress[id - 1].listen_detail[
                                      Oncecourse.progress[id - 1].listen_detail
                                        .length - 1
                                    ].status == true &&
                                    Oncecourse.progress[id].listen_detail[
                                      Oncecourse.progress[id].listen_detail
                                        .length - 1
                                    ].status != true
                                  ) {
                                    const i = ins;
                                    if (items.status == false) {
                                      Chanfd(items, Mainindex, i);
                                    }
                                  } else if (
                                    ins !== 0 &&
                                    item.listen_detail[ins - 1].status ==
                                    true &&
                                    item.listen_detail[ins].status == false
                                  ) {
                                    const i = ins;
                                    if (items.status == false) {
                                      Chanfd(items, Mainindex, i);
                                    }
                                  }
                                }
                              ),
                            };
                          }
                        });
                      } else if (
                        data.price == 0 &&
                        data.Type != "COURSESCHOOL"
                      ) {
                        Oncecourse.progress.map((item, id) => {
                          const Mainindex = id;
                          if (id == 0) {
                            return {
                              ...item,
                              listen_detail: item.listen_detail.map(
                                (items, ins) => {
                                  if (ins == 0 && items.status == false) {
                                    const i = ins;
                                    if (items.status == false) {
                                      Chanfd(items, Mainindex, i);
                                    }
                                    return;
                                  } else if (
                                    ins !== 0 &&
                                    item.listen_detail[ins - 1].status ==
                                    true &&
                                    item.listen_detail[ins].status == false
                                  ) {
                                    const i = ins;
                                    if (items.status == false) {
                                      Chanfd(items, Mainindex, i);
                                    }
                                    return;
                                  }
                                }
                              ),
                            };
                          } else {
                            return {
                              ...item,
                              listen_detail: item.listen_detail.map(
                                (items, ins) => {
                                  if (
                                    ins == 0 &&
                                    Oncecourse.progress[id - 1].listen_detail[
                                      Oncecourse.progress[id - 1].listen_detail
                                        .length - 1
                                    ].status == true &&
                                    Oncecourse.progress[id - 1].listen_detail[
                                      Oncecourse.progress[id - 1].listen_detail
                                        .length - 1
                                    ].status != undefined &&
                                    Oncecourse.progress[id].listen_detail[
                                      Oncecourse.progress[id].listen_detail
                                        .length - 1
                                    ].status != true
                                  ) {
                                    const i = ins;
                                    if (items.status == false) {
                                      Chanfd(items, Mainindex, i);
                                    }

                                    return;
                                  } else if (
                                    ins !== 0 &&
                                    item.listen_detail[ins - 1].status ==
                                    true &&
                                    item.listen_detail[ins].status == false
                                  ) {
                                    const i = ins;
                                    if (items.status == false) {
                                      Chanfd(items, Mainindex, i);
                                    }
                                    return;
                                  }
                                }
                              ),
                            };
                          }
                        });
                      }
                    }}
                    style={{
                      width: "100%",
                      height: 55,
                      backgroundColor:
                        checkcondition && token?.role == "USER"
                          ? "#FFD54A"
                          : "#E82626",
                      borderRadius: 3,
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: 18,
                    }}
                  >
                    <Text
                      style={{
                        fontSize: size.width < 1280 ? 16 : 20,
                        fontFamily: "Prompt",
                        color: "#FFFFFF",
                      }}
                    >
                      {"ดำเนินการต่อ"}
                    </Text>
                  </TouchableOpacity>
                )
              ) : (
                <TouchableOpacity
                  disabled={true}
                  onPress={async () => {
                    if (token == "") {
                      setModalLogin(true);
                    } else if (
                      checkcondition &&
                      token?.role == "USER" &&
                      data.Type == "COURSESCHOOL" &&
                      data.price == 0
                    ) {
                      Oncecourse.progress.map((item, id) => {
                        const Mainindex = id;
                        if (id == 0) {
                          return {
                            ...item,
                            listen_detail: item.listen_detail.map(
                              (items, ins) => {
                                if (ins == 0 && items.status == false) {
                                  const i = ins;
                                  Chanfd(items, Mainindex, i);
                                } else if (
                                  ins !== 0 &&
                                  item.listen_detail[ins - 1].status == true &&
                                  item.listen_detail[ins].status == false
                                ) {
                                  const i = ins;
                                  Chanfd(items, Mainindex, i);
                                }
                              }
                            ),
                          };
                        } else {
                          return {
                            ...item,
                            listen_detail: item.listen_detail.map(
                              (items, ins) => {
                                if (
                                  ins == 0 &&
                                  Oncecourse.progress[id - 1].listen_detail[
                                    Oncecourse.progress[id - 1].listen_detail
                                      .length - 1
                                  ].status == true
                                ) {
                                  const i = ins;
                                  Chanfd(items, Mainindex, i);
                                } else if (
                                  ins !== 0 &&
                                  item.listen_detail[ins - 1].status == true &&
                                  item.listen_detail[ins].status == false
                                ) {
                                  const i = ins;
                                  Chanfd(items, Mainindex, i);
                                }
                              }
                            ),
                          };
                        }
                      });
                    }
                  }}
                  style={{
                    width: "100%",
                    height: 55,
                    backgroundColor: "#0D88F0",
                    borderRadius: 3,
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: 18,
                  }}
                >
                  <Text
                    style={{
                      fontSize: size.width < 1280 ? 16 : 20,
                      fontFamily: "Prompt",
                      color: "#FFFFFF",
                    }}
                  >
                    {"เสร็จสิ้น"}
                  </Text>
                </TouchableOpacity>
              )}
            </View>
            <Text
              style={[
                {
                  fontSize: 20,
                  marginTop: 25,
                  fontFamily: "Prompt",
                  color: "#646464",
                },
              ]}
            >
              คอร์สยอดนิยม
            </Text>
            <View>
              <FlatList
                data={TopCourse}
                // style={{
                //   maxHeight: 1420,
                // }}
                renderItem={({ index, item }) => {
                  return (
                    index < 5 && (
                      <TouchableOpacity
                        onPress={() => {
                          setCourseStates(item);
                          setTimeout(() => {
                            navigation.push("Course");
                          }, 900);
                        }}
                        style={[
                          {
                            borderWidth: 1,
                            borderColor: "#BBB",
                            marginTop: 10,
                            flexDirection: "row",
                          },
                        ]}
                      >
                        <Image
                          style={[
                            styles.imgDetail,
                            {
                              width: "50%",
                              height: size.width < 500 ? 150 : 157,
                              backgroundColor: "#CCCCCC",
                            },
                          ]}
                          source={{
                            uri:
                              "https://api.courseoncloud.com/api/image/getimage/" +
                              item.img_content,
                          }}
                        />
                        <View
                          style={[
                            styles.viewCourseDetail,
                            {
                              width: "50%",
                              padding: 10,
                            },
                          ]}
                        >
                          <Text
                            style={[
                              styles.text,
                              { fontSize: size.width < 500 ? 14 : 16 },
                            ]}
                            numberOfLines={1}
                          >
                            {item.title}
                          </Text>
                          <View
                            style={{
                              flexDirection: "row",
                              alignItems: "center",
                              minHeight: 70,
                            }}
                          >
                            <Image
                              style={[
                                styles.img,
                                {
                                  width: size.width < 500 ? 25 : 35,
                                  height: size.width < 500 ? 25 : 35,
                                  borderRadius: 35,
                                  backgroundColor: "#CCCCCC",
                                },
                              ]}
                              source={{
                                uri:
                                  "https://api.courseoncloud.com/api/image/getimage/" +
                                  item?.user?.user_accounts?.image_url,
                              }}
                            />
                            <Text
                              style={[
                                styles.text,
                                {
                                  fontSize: 12,
                                  fontFamily: "Prompt",
                                  color: "#5D5D5D",
                                },
                              ]}
                            >
                              {item?.user?.user_accounts?.firstName}{" "}
                              {item?.user?.user_accounts?.lastName}
                            </Text>
                          </View>
                          <View style={styles.feedback}>
                            <View
                              style={{
                                flexDirection: "row",
                                alignSelf: "flex-end",
                              }}
                            >
                              {/* <FontAwesome
                          name={item.rating > 0 ? "star" : "star-o"}
                          size={size.width < 500 ? 15 : 20}
                          color="#0D88F0"
                        />
                        <FontAwesome
                          name={item.rating > 1 ? "star" : "star-o"}
                          size={size.width < 500 ? 15 : 20}
                          color="#0D88F0"
                        />
                        <FontAwesome
                          name={item.rating > 2 ? "star" : "star-o"}
                          size={size.width < 500 ? 15 : 20}
                          color="#0D88F0"
                        />
                        <FontAwesome
                          name={item.rating > 3 ? "star" : "star-o"}
                          size={size.width < 500 ? 15 : 20}
                          color="#0D88F0"
                        />
                        <FontAwesome
                          name={item.rating > 4 ? "star" : "star-o"}
                          size={size.width < 500 ? 15 : 20}
                          color="#0D88F0"
                        /> */}
                            </View>
                            <View>
                              {item.type == "COURSESCHOOL" ? (
                                <View
                                  style={{
                                    height: 20,
                                    backgroundColor: "#0B2051",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    borderRadius: 10,
                                    marginVertical: 5,
                                    alignSelf: "flex-start",
                                  }}
                                >
                                  <Text
                                    style={{
                                      fontFamily: "Prompt",
                                      fontSize: 12,
                                      color: "#FFFFFF",
                                      paddingHorizontal: 10,
                                    }}
                                  >
                                    เฉพาะนักศึกษา
                                  </Text>
                                </View>
                              ) : (
                                <View style={{ flexDirection: "row" }}>
                                  <Text
                                    style={[
                                      styles.text,
                                      {
                                        textAlign: "right",
                                        fontSize: size.width < 500 ? 12 : 14,
                                        textDecorationLine:
                                          item.discount != 0 ||
                                          (item.discount != null &&
                                            "line-through"),
                                      },
                                    ]}
                                  >
                                    <NumberFormat
                                      value={item.price == 0 ? "" : item.price}
                                      displayType={"text"}
                                      thousandSeparator={true}
                                    />{" "}
                                    {item.price == 0
                                      ? "ฟรี"
                                      : item.discount == 0 ||
                                      (item.discount == null && "บาท")}
                                  </Text>
                                  {item.discount != 0 ||
                                    (item.discount != null && (
                                      <Text
                                        style={[
                                          styles.text,
                                          {
                                            textAlign: "right",
                                            fontSize:
                                              size.width < 500 ? 12 : 14,
                                          },
                                        ]}
                                      >
                                        {item.price -
                                          (parseInt(item.discount) *
                                            item.price) /
                                          100}
                                        {item.discount != 0 && " บาท"}
                                      </Text>
                                    ))}
                                </View>
                              )}

                              <Text
                                style={[
                                  styles.text,
                                  {
                                    fontSize: size.width < 500 ? 12 : 14,
                                    textAlign: "right",
                                  },
                                ]}
                              >
                                <NumberFormat
                                  value={item.lengthdata}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                />{" "}
                                <FontAwesome5
                                  name="user-friends"
                                  size={16}
                                  color="#5D5D5D"
                                />
                              </Text>
                            </View>
                          </View>
                        </View>
                      </TouchableOpacity>
                    )
                  );
                }}
              />
            </View>
          </View>
        </View>
        <Modal
          transparent={true}
          visible={modalReview}
          onRequestClose={() => {
            Alert.alert("Modal has been closed.");
            setModalReview(!modalReview);
          }}
        >
          <View style={styles.bgModal}>
            <View
              style={{
                width: size.width < 1280 ? "80%" : "30%",

                borderWidth: 1,
                borderColor: "#0D88F0",
                backgroundColor: "#FFFFFF",
                alignSelf: "center",
                alignItems: "center",
                padding: 20,
                borderRadius: 5,
              }}
            >
              <TouchableOpacity
                onPress={() => {
                  setModalReview(!modalReview);
                }}
                style={{
                  position: "absolute",
                  alignSelf: "flex-end",
                }}
              >
                <MaterialIcons name="clear" size={14} color="#0D88F0" />
              </TouchableOpacity>
              <Text
                style={{ fontSize: 22, fontFamily: "Prompt", color: "#646464" }}
              >
                ให้คะแนนคอร์สนี้
              </Text>
              <ReactStars
                count={5}
                onChange={(val) => {
                  setRating({ ...Rating, rating: val });
                }}
                size={40}
                activeColor="#0D88F0"
              />
              <View
                style={{
                  width: "70%",
                  alignSelf: "center",
                  marginVertical: 20,
                }}
              >
                <Text
                  style={{
                    alignSelf: "flex-start",
                    fontSize: 16,
                    fontFamily: "Prompt",
                    color: "#646464",
                  }}
                >
                  ความคิดเห็น
                </Text>
                <TextInput
                  onChangeText={(text) => {
                    setRating({ ...Rating, about: text });
                  }}
                  multiline
                  style={{
                    width: "100%",
                    minHeight: 120,
                    borderWidth: 1,
                    borderColor: "#BBBBBB",
                    borderRadius: 5,
                    padding: 10,
                    fontSize: 16,
                    fontFamily: "Prompt",
                    color: "#646464",
                  }}
                />
              </View>
              <TouchableOpacity
                onPress={async () => {
                  const response = await apiservice({
                    path: "/start-course/createreview",
                    method: "post",
                    body: Rating,
                    token: token?.accessToken,
                  });
                  if (response.status == 200) {
                    rate();
                    name();
                    setModalReview(!modalReview);
                  }
                }}
                style={{
                  width: 150,
                  height: 40,
                  backgroundColor: "#0D88F0",
                  borderRadius: 5,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Text
                  style={{
                    fontSize: 14,
                    fontFamily: "Prompt",
                    color: "#FFFFFF",
                  }}
                >
                  ส่ง
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </Modal>
        <Modal
          transparent={true}
          visible={modalError}
          onRequestClose={() => {
            Alert.alert("Modal has been closed.");
            setModalError(!modalError);
          }}
        >
          <View style={styles.bgModal}>
            <View
              style={{
                width: size.width < 1280 ? "80%" : "45%",
                height: 230,
                borderWidth: 1,
                borderColor: "#0D88F0",
                backgroundColor: "#FFFFFF",
                alignSelf: "center",
                alignItems: "center",
              }}
            >
              <TouchableOpacity
                onPress={() => {
                  setModalError(!modalError);
                }}
                style={{
                  position: "absolute",
                  alignSelf: "flex-end",
                  margin: 13,
                }}
              >
                <MaterialIcons name="clear" size={14} color="#0D88F0" />
              </TouchableOpacity>
              <View style={{ paddingVertical: 55, alignItems: "center" }}>
                <Text
                  style={{
                    fontSize: size.width < 1280 ? 30 : 35,
                    fontFamily: "Prompt",
                    color: "red",
                  }}
                >
                  เกิดข้อผิดพลาด
                </Text>
                <Text
                  style={{
                    fontSize: size.width < 1280 ? 30 : 35,
                    fontFamily: "Prompt",
                    color: "#0B2051",
                  }}
                >
                  มีบัญชีผู้ใช้หรืออีเมลอยู่ในระบบแล้ว
                </Text>
              </View>
            </View>
          </View>
        </Modal>
        <BottomPart
          navigation={navigation}
          style={{
            marginTop: (TopCourse.length * size.width < 500 ? 150 : 157) + 120,
          }}
        />
      </ScrollView>
    </View>
  );
}
const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#FFFFFF",
  },
  textRequirements: {
    fontSize: 20,
    fontFamily: "Prompt",
    color: "#646464",
    marginLeft: 30,
  },
  bgModal: {
    width: "100%",
    height: "100%",
    backgroundColor: "#000000bb",
    justifyContent: "center",
  },
  text18: {
    fontSize: 16,
    fontFamily: "Prompt",
    color: "#646464",
  },
  inputLoginModal: {
    width: "100%",
    height: 60,
    borderWidth: 1,
    borderColor: "#FED3C4",
    paddingHorizontal: 15,
    backgroundColor: "#EEEEEE",
    borderRadius: 5,
  },
  button: {
    width: "45%",
    maxWidth: 500,
    height: 40,
    borderWidth: 1,
    borderColor: "#eee",
    borderRadius: 5,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    // marginTop: 20,
  },
  img: {
    width: 40,
    height: 40,
    marginRight: 15,
  },
  text: {
    fontSize: 20,
    fontFamily: "Prompt",
    color: "#646464",
  },
  viewUsername: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },
  textInput: {
    width: "100%",

    height: 40,
    borderWidth: 1,
    borderRadius: 5,
    borderColor: "#EEEEEE",
    fontSize: 16,
    fontFamily: "Prompt",
    color: "#646464",
    padding: 15,
    backgroundColor: "#EEEEEE",
  },
  text14R: {
    fontSize: 14,
    color: "red",
    fontFamily: "Prompt",
  },
  viewPassWord: {
    flexDirection: "row",
    marginVertical: 40,
    width: "100%",
    justifyContent: "center",
  },
  button1: {
    width: "100%",

    height: 40,
    backgroundColor: "#0D88F0",
    borderRadius: 3,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 25,
  },
  textButton: {
    fontSize: 20,
    fontFamily: "Prompt",
    color: "#FFFFFF",
  },
  feedback: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
});
const stylesResponsive = StyleSheet.create({
  textRequirements: {
    fontSize: 14,
    fontFamily: "Prompt",
    color: "#646464",
    marginLeft: 30,
  },
});
