import React, { useEffect, useState } from "react";
import {
  View,
  Text,
  Image,
  Dimensions,
  TouchableOpacity,
  StyleSheet,
  ScrollView,
} from "react-native";
import * as DocumentPicker from "expo-document-picker";
import { MaterialCommunityIcons, MaterialIcons } from "@expo/vector-icons";
import Header from "../components/header";
import Modal from "modal-react-native-web";
import ReactCountdownClock from "react-countdown-clock";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { apiservice } from "../../service";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  courseState,
  lessonState,
  lessonVideoState,
  subjecttestState,
  testState,
  tokenState,
  userState,
} from "../../reducer/recoil";
import { useIsFocused } from "@react-navigation/native";
import { useWindowSize } from "../components/responsive";
import HTMLRenderer from "react-html-renderer";
const { width, height } = Dimensions.get("window");

export default function subjectiveTest({ navigation, route }) {
  const [subjectTestStates, setSubjectTestStates] =
    useRecoilState(subjecttestState);

  const [lessonStates, setLessonStates] = useRecoilState(lessonState);
  const [testStates, setTestStates] = useRecoilState(testState);
  const [lessonVideoStates, setLessonVideoStates] =
    useRecoilState(lessonVideoState);
  const [page, setPage] = useState(false);
  const [unLock, setUnLock] = useState(false);
  const [answer, setAnswer] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalVisible1, setModalVisible1] = useState(false);
  const [modalScore, setModalScore] = useState(false);
  const [check, setCheck] = useState(false);
  const [show, setShow] = useState([]);
  const [show1, setShow1] = useState([]);
  const [detail, setDetail] = useState("");
  const [sumScore, setSumScore] = useState();
  const [too, setToo] = useState(null);
  const [count, setCount] = useState();
  const [users, setUser] = useRecoilState(userState);

  const [defaultsStatus, setStatus] = useState(subjectTestStates.status);

  const [courseStates, setCourseStates] = useRecoilState(courseState);

  const [course, setCourse] = useState({
    status: false,
    comment: "",
    approve: "",
  });

  const [doc, setDoc] = useState("");
  const [Oncecourse, myOncecourse] = useState(null);

  const token = useRecoilValue(tokenState);
  const focus = useIsFocused();

  function myCallback() {
    if (subjectTestStates.type == "WRITING") {
      onSubmit1();
    } else {
      onSubmit();
    }
    setModalVisible1(true);
  }

  useEffect(() => {
    if (focus) {
      checkStart();
    }
  }, [focus, subjectTestStates, token]);

  useEffect(() => {
    getCount();
    if (Oncecourse) {
      getWorkStart();
    }
  }, [Oncecourse]);

  async function checkStart() {
    const response = await apiservice({
      path:
        "/start-course/myOncecourse?course_id=" + subjectTestStates.course_id,
      method: "get",
      token: token?.accessToken,
    });
    if (response.status == 200) {
      myOncecourse(response.data);

      setStatus(
        response.data.progress[subjectTestStates.Mainindex].listen_detail[
          subjectTestStates.index
        ].status
      );
    }
  }
  async function getCount() {
    const response = await apiservice({
      path:
        "/start-course/countwork?student_id=" +
        users.uid +
        "&work_id=" +
        subjectTestStates.education_id,
      method: "get",
      token: token?.accessToken,
    });
    if (response.status == 200) {
      setCount(response.data.results);
    }
  }

  async function checkStartFinish() {
    const response = await apiservice({
      path:
        "/start-course/myOncecourse?course_id=" + subjectTestStates.course_id,
      method: "get",
      token: token?.accessToken,
    });

    if (response.status == 200) {
      myOncecourse(response.data);
    }
  }

  useEffect(() => {
    if (
      defaultsStatus &&
      subjectTestStates.dataStudymodel[subjectTestStates.Mainindex]
        .listen_detail[subjectTestStates.index + 1] == undefined &&
      defaultsStatus &&
      subjectTestStates.dataStudymodel[subjectTestStates.Mainindex + 1] ==
        undefined
    ) {
      name1();
    }
  }, [defaultsStatus]);

  // useEffect(() => {
  //   conditionToo(too);
  // }, [too]);

  async function conditionToo(params) {
    try {
      if (
        defaultsStatus &&
        subjectTestStates.dataStudymodel[subjectTestStates.Mainindex]
          .listen_detail[subjectTestStates.index + 1] == undefined &&
        defaultsStatus &&
        subjectTestStates.dataStudymodel[subjectTestStates.Mainindex + 1] ==
          undefined
      ) {
        if (Oncecourse.Type == "PENDING") {
          const reespon = await apiservice({
            path: "/start-course/update/" + Oncecourse.id,
            method: "put",
            body: {
              Type:
                params >= Oncecourse?.courses?.percent ? "APPROVE" : "REJECT",
            },
            token: token?.accessToken,
          });
          if (reespon.status == 200) {
            checkStart();
          }
        }
      }
    } catch (error) {}
  }

  async function name1() {
    const respp = await apiservice({
      path: "/start-course/getalltotals/" + subjectTestStates.course_id,
      method: "get",
      token: token?.accessToken,
    });
    const resppt = await apiservice({
      path: "/start-course/getworktotals/" + subjectTestStates.course_id,
      method: "get",
      token: token?.accessToken,
    });
    if (respp.status == 200) {
      setShow1(resppt.data.results);
      setShow(respp.data.results);
      setModalScore(true);
    }
  }

  async function getWorkStart() {
    try {
      const users = await apiservice({
        path: "/authen/user",
        method: "get",
        token: token?.accessToken,
      });
      const response = await apiservice({
        path:
          "/start-course/" +
          (subjectTestStates.type == "WORK"
            ? "getworkBystudent"
            : "getwritingBystudent") +
          "?course_id=" +
          subjectTestStates.course_id +
          "&work_id=" +
          subjectTestStates.education_id +
          "&student_id=" +
          users.data.result.id,
        method: "get",
        token: token?.accessToken,
      });

      if (response.status == 200) {
        if (response.data.results != null) {
          setDoc(response.data.results.documets);
          setDetail(response.data.results.detail);

          setCourse(response.data.results);
          setAnswer(false);
          setUnLock(!response.data.results.status);
          setPage(response.data.results.status);
          setCheck(!response.data.results.status ? true : false);
          const res = await apiservice({
            path:
              "/start-course/myscore?course_id=" +
              response.data.results.course_id +
              "&uid=" +
              response.data.results.student_id,
            method: "get",
          });

          if (res.status == 200) {
            setSumScore(res.data.results);
            conditionToo(res.data.results?.EXAM);
          }
        }
      }
    } catch (error) {}
  }

  async function onSubmit() {
    const response = await apiservice({
      path: "/start-course/creatework",
      method: "post",
      body: {
        course_id: subjectTestStates.course_id,
        work_id: subjectTestStates.education_id,
        detail,
        documets: doc,
        status: false,
      },
      token: token?.accessToken,
    });

    if (response.status == 200) {
      setPage(false);
      setCheck(true);
    }
  }

  async function onSubmit1() {
    const response = await apiservice({
      path: "/start-course/createwriting",
      method: "post",
      body: {
        course_id: subjectTestStates.course_id,
        work_id: subjectTestStates.education_id,
        detail,
        documets: doc,
        status: false,
      },
      token: token?.accessToken,
    });

    if (response.status == 200) {
      setPage(false);
      setCheck(true);
    }
  }

  function name() {
    try {
      if (subjectTestStates.image) {
        return subjectTestStates.image;
      } else {
        return subjectTestStates.img.image;
      }
    } catch (error) {
      return "";
    }
  }

  async function onPress() {
    let result = await DocumentPicker.getDocumentAsync({
      type: "application/pdf,application/msword",
    });
    // setImage(result.uri)
    const response = await apiservice({
      path: "/image/createpdf",
      method: "post",
      body: {
        name: result.name,
        base64: result.uri,
      },
    });

    setDoc(response.data.imageRefId);
  }

  async function Chanfd(params, Mainindex, i, status) {
    if (params.type == "EXAM") {
      const res = await apiservice({
        path: "/quiz/get/" + params.education_id,
        method: "get",
        token: token?.accessToken,
      });
      setTestStates({
        ...params,
        ...res.data,
        ...Oncecourse,
        Mainindex,
        index: i,
        number: 0,
        dataStudymodel: Oncecourse.progress,
        status,
        course_id: Oncecourse.course_id,
      });
      setTimeout(() => {
        navigation.navigate("Test");
      }, 1200);
    } else if (params.type == "DESCRIPTION") {
      const res = await apiservice({
        path: "/course/geteducation/" + params.education_id,
        method: "get",
        token: token?.accessToken,
      });
      if (res.status == 200) {
        if (res.data.TYPE == "VDO") {
          setLessonVideoStates({
            ...params,
            ...res.data,
            ...Oncecourse,
            number: 0,
            Mainindex,
            dataStudymodel: Oncecourse.progress,
            status,
            index: i,
            course_id: Oncecourse.course_id,
          });
          setTimeout(() => {
            navigation.navigate("LessonVideo");
          }, 500);
        } else {
          setLessonStates({
            ...params,
            ...res.data,
            ...Oncecourse,
            number: 0,
            Mainindex,
            dataStudymodel: Oncecourse.progress,
            status,
            index: i,
            course_id: Oncecourse.course_id,
          });
          setTimeout(() => {
            navigation.navigate("Lesson");
          }, 500);
        }
      }
    } else if (params.type == "WORK") {
      const res = await apiservice({
        path: "/course/getwork/" + params.education_id,
        method: "get",
        token: token?.accessToken,
      });
      setSubjectTestStates({
        ...res.data,
        ...params,
        ...Oncecourse,
        number: 0,
        Mainindex,
        status,
        index: i,
        dataStudymodel: Oncecourse.progress,
        course_id: Oncecourse.course_id,
      });
      setTimeout(() => {
        navigation.push("SubjectiveTest");
      }, 500);
    } else {
      const res = await apiservice({
        path: "/quiz/get/" + params.education_id,
        method: "get",
        token: token?.accessToken,
      });
      setSubjectTestStates({
        ...res.data,
        ...params,
        ...Oncecourse,
        dataStudymodel: Oncecourse.progress,
        number: 0,
        Mainindex,
        index: i,
        status,
        course_id: Oncecourse.course_id,
      });
      setTimeout(() => {
        navigation.push("SubjectiveTest");
      }, 500);
    }
  }

  const ck = {
    toolbar: [
      "heading",
      "|",
      "bold",
      "italic",
      "link",
      "bulletedList",
      "numberedList",
      "blockQuote",
    ],
    heading: {
      options: [
        {
          model: "paragraph",
          title: "Paragraph",
          class: "ck-heading_paragraph",
        },
        {
          model: "heading1",
          view: "h1",
          title: "Heading 1",
          class: "ck-heading_heading1",
        },
        {
          model: "heading2",
          view: "h2",
          title: "Heading 2",
          class: "ck-heading_heading2",
        },
      ],
    },
  };
  const embed = subjectTestStates.detail;

  const stringToHTML = function (str) {
    const domContainer = document.createElement("span");
    domContainer.innerHTML = str;
    return domContainer;
  };

  const parentEmbed = stringToHTML(embed);

  let oldIframe = parentEmbed.querySelectorAll("oembed");
  oldIframe = Array.from(oldIframe);

  for (const i in oldIframe) {
    //Get the url from oembed tag
    let url = oldIframe[i].getAttribute("url");
    //Replace 'watch?v' with 'embed/'
    url = url.replace("watch?v=", "embed/");

    //Create a iframe tag
    const newIframe = document.createElement("iframe");
    newIframe.setAttribute("width", "auto");
    newIframe.setAttribute("height", "auto");
    newIframe.setAttribute("allowFullScreen", "");
    newIframe.setAttribute("frameBorder", 0);
    if (url) {
      newIframe.setAttribute("src", url);
    }
    // replace oldIframe with newIframe
    oldIframe[i].parentNode.replaceChild(newIframe, oldIframe[i]);
  }

  const contentToRender = parentEmbed.outerHTML?.replace(
    /<img /g,
    `<img width="${width * 0.5}" height="auto" `
  );
  const size = useWindowSize();

  useEffect(() => {
    if (show.length > 0) {
      totalsss();
    }
  }, [show]);

  async function totalsss() {
    let toootals = 0;
    const re = await Promise.all(
      subjectTestStates.progress.map(async (item, index) => {
        const question = item.MyQ;
        const answers = item?.listen_detail?.filter(
          (items) => items?.type == "EXAM"
        )?.[0]?.answer;
        toootals =
          (answers
            ?.filter(
              (ele, ind) =>
                ind === answers.findIndex((elem) => elem.number === ele.number)
            )
            .filter((items, mainIndex) => {
              if (
                question?.filter((val, indexs) => indexs == items.number)?.[0]
                  ?.type == "1"
              ) {
                return (
                  question?.filter((val, indexs) => indexs == items.number)?.[0]
                    ?.answer == items.answer
                );
              }
              if (
                question?.filter((val, indexs) => indexs == items.number)?.[0]
                  ?.type == "2"
              ) {
                return (
                  question
                    ?.filter((val, indexs) => indexs == items.number)?.[0]
                    ?.answer.filter(
                      (e) => items.answer.filter((ev) => e == ev).length > 0
                    ).length ==
                  question?.filter((val, indexs) => indexs == items.number)?.[0]
                    ?.answer.length
                );
              }
              if (
                question?.filter((val, indexs) => indexs == items.number)?.[0]
                  ?.type == "3"
              ) {
                return question?.filter(
                  (val, indexs) => indexs == items.number
                )?.[0]?.answer != null
                  ? question?.filter(
                      (val, indexs) => indexs == items.number
                    )?.[0]?.answer == items.answer
                  : items.answer == 0
                  ? true
                  : false;
              }
              if (
                question?.filter((val, indexs) => indexs == items.number)?.[0]
                  ?.type == "4"
              ) {
                return (
                  question
                    .filter((val, indexs) => indexs == items.number)?.[0]
                    ?.answer.filter(
                      (e) =>
                        items.answer.filter((ev) => e.answer == ev.answer)
                          .length > 0
                    ).length ==
                  question?.filter((val, indexs) => indexs == items.number)?.[0]
                    ?.answer.length
                );
              }
              if (
                question?.filter((val, indexs) => indexs == items.number)?.[0]
                  ?.type == "5"
              ) {
                return (
                  question?.filter((val, indexs) => {
                    if (val.type == "5") {
                      return (
                        answers
                          .filter((val, indexss) => indexs == val.number)?.[0]
                          ?.answer.filter((e, i) => val.choice[i] == e.ans)
                          .length == val.choice.length
                      );
                    } else {
                      return false;
                    }
                  }).length > 0
                );
              }

              return false;
            })?.length /
            answers?.length) *
          100;
      })
    );

    setToo(toootals);
  }

  // if (
  //   defaultsStatus &&
  //   subjectTestStates.dataStudymodel[subjectTestStates.Mainindex].listen_detail[
  //     subjectTestStates.index + 1
  //   ] == undefined &&
  //   too == null &&
  //   subjectTestStates.dataStudymodel[subjectTestStates.Mainindex + 1] ==
  //     undefined
  // ) {
  //   return <View />;
  // }

  // if (
  //   defaultsStatus &&
  //   subjectTestStates.dataStudymodel[subjectTestStates.Mainindex].listen_detail[
  //     subjectTestStates.index + 1
  //   ] == undefined &&
  //   too == null &&
  //   subjectTestStates.dataStudymodel[subjectTestStates.Mainindex + 1] ==
  //     undefined
  // ) {
  //   return <View />;
  // }

  if (subjectTestStates == undefined) {
    return <View />;
  }

  if (subjectTestStates.dataStudymodel == undefined) {
    return null;
  }
  if (Oncecourse == null) {
    return null;
  }

  return (
    <View style={styles.container}>
      <ScrollView stickyHeaderIndices={[0]}>
        <Header navigation={navigation} />
        {course.approve != "notapprove" &&
        !answer &&
        page &&
        subjectTestStates.type == "WRITING" &&
        size.width >= 1280
          ? parseInt(subjectTestStates?.conditions?.timer || 0) > 0 && (
              <View
                style={{
                  position: "absolute",
                  marginTop: height * 0.2,
                  marginLeft: width * 0.03,
                }}
              >
                <ReactCountdownClock
                  seconds={
                    subjectTestStates.conditions.timerType == "HOUR"
                      ? subjectTestStates.conditions.timer * 60 * 60
                      : subjectTestStates.conditions.timer * 60
                  }
                  paused={defaultsStatus}
                  color="#0D88F0"
                  alpha={0.9}
                  size={300}
                  onComplete={myCallback}
                />
              </View>
            )
          : ""}
        <View
          style={{
            width: size.width < 1280 ? "80%" : "50%",
            alignSelf: "center",
            marginTop: 35,
            minHeight: size.height - 155,
          }}
        >
          {course.approve != "notapprove" &&
          !answer &&
          page &&
          subjectTestStates.type == "WRITING" &&
          size.width < 1280
            ? parseInt(subjectTestStates?.conditions?.timer || 0) > 0 && (
                <View
                  style={{
                    marginVertical: 40,
                    marginLeft: width * 0.03,
                  }}
                >
                  <ReactCountdownClock
                    seconds={
                      subjectTestStates.conditions.timerType == "HOUR"
                        ? subjectTestStates.conditions.timer * 60 * 60
                        : subjectTestStates.conditions.timer * 60
                    }
                    paused={defaultsStatus}
                    color="#0D88F0"
                    alpha={0.9}
                    size={300}
                    onComplete={width < 1280 && myCallback}
                  />
                </View>
              )
            : ""}
          <Text
            style={{ fontFamily: "Prompt", fontSize: 11, color: "#0B2051" }}
          >
            {/* พุทธศาสตรบัณฑิต (การสอนพระพุทธศาสนาและจิตวิทยาแนะแนว) */}
          </Text>
          <Text
            style={{
              fontFamily: "Prompt",
              fontSize: size.width < 500 ? 16 : 25,
              color: "#646464",
            }}
          >
            {subjectTestStates.name}
          </Text>
          {name(subjectTestStates) && (
            <Image
              style={{
                height: size.width < 500 ? 250 : 500,
                marginVertical: 20,
              }}
              source={{
                uri:
                  "https://api.courseoncloud.com/api/image/getimage/" +
                  name(subjectTestStates.image).replace(".png", ""),
              }}
            />
          )}
          <View>
            {page && (
              <Text
                style={{
                  fontFamily: "Prompt",
                  fontSize: size.width < 500 ? 14 : 20,
                  color: "#646464",
                }}
              >
                <HTMLRenderer
                  html={contentToRender}
                  components={
                    {
                      // h1: props => <Heading color="red" {...props} />,
                      // h2: Subheading,
                      // a: Link,
                    }
                  }
                />
              </Text>
            )}
            {!page && (
              <TouchableOpacity
                disabled={defaultsStatus || check ? true : false}
                onPress={() => {
                  setPage(true);
                }}
                style={{
                  width: size.width < 500 ? 120 : 170,
                  height: 50,
                  backgroundColor:
                    defaultsStatus || check ? "#CCCCCC" : "#0D88F0",
                  borderRadius: 10,
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: 20,
                  marginBottom: 45,
                }}
              >
                <Text
                  style={{
                    fontFamily: "Prompt",
                    fontSize: size.width < 500 ? 14 : 18,
                    color: "#FFFFFF",
                  }}
                >
                  {subjectTestStates.type == "WRITING"
                    ? "เริ่มทำแบบทดสอบ"
                    : "เริ่มทำงานที่ได้รับ"}
                </Text>
              </TouchableOpacity>
            )}
          </View>

          {page && (
            <View>
              <View style={styles.viewCkEditor}>
                <CKEditor
                  editor={ClassicEditor}
                  data={detail}
                  config={ck}
                  onReady={(editor) => {
                    // You can store the "editor" and use when it is needed.
                  }}
                  onChange={async (event, editor) => {
                    const data = await editor.getData();

                    setDetail(data);
                  }}
                  onBlur={(event, editor) => {}}
                  onFocus={(event, editor) => {}}
                />
              </View>

              <View style={{ flexDirection: "row", marginTop: 24 }}>
                <TouchableOpacity
                  disabled={course.status == true ? true : false}
                  onPress={onPress}
                  style={{
                    width: size.width < 500 ? 120 : 200,
                    height: 50,
                    backgroundColor: "#0D88F0",
                    borderRadius: 20,
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "row",
                  }}
                >
                  <MaterialIcons name="attach-file" size={24} color="#FFFFFF" />
                  <Text
                    style={{
                      fontFamily: "Prompt",
                      fontSize: size.width < 500 ? 12 : 16,
                      color: "#FFFFFF",
                      marginLeft: 20,
                    }}
                  >
                    แนบไฟล์งาน
                  </Text>
                </TouchableOpacity>
                <Text
                  style={{
                    fontFamily: "Prompt",
                    fontSize: size.width < 500 ? 12 : 20,
                    color: "#646464",
                    alignSelf: "center",
                    marginLeft: 15,
                  }}
                >
                  {doc}
                </Text>
              </View>
              {course.status && (
                <View
                  style={{
                    width: "100%",
                    borderTopWidth: 1,
                    borderTopColor: "#BBB",
                    marginVertical: 40,
                  }}
                >
                  <View
                    style={{
                      width: size.width < 500 ? 150 : 200,
                      height: 50,
                      backgroundColor:
                        course.approve == "approve" ? "#74C745" : "#ff0000",
                      borderRadius: 20,
                      justifyContent: "center",
                      alignItems: "center",
                      marginVertical: 45,
                    }}
                  >
                    <Text
                      style={{
                        fontSize: size.width < 500 ? 14 : 20,
                        fontFamily: "Prompt",
                        color: "#FFFFFF",
                      }}
                    >
                      {course.approve == "approve" ? "คุณผ่าน" : "คุณไม่ผ่าน"}
                    </Text>
                  </View>
                  <View
                    style={{
                      width: "100%",
                      height: 50,
                      backgroundColor: "#0D88F0",
                      paddingLeft: 20,
                      justifyContent: "center",
                    }}
                  >
                    <Text
                      style={{
                        fontSize: size.width < 500 ? 16 : 20,
                        fontFamily: "Prompt",
                        color: "#FFFFFF",
                      }}
                    >
                      ความคิดเห็นของผู้สอน
                    </Text>
                  </View>
                  <View
                    style={{
                      width: "100%",
                      minHeight: 200,
                      borderWidth: 1,
                      borderColor: "#BBB",
                    }}
                  >
                    <Text
                      style={{
                        fontFamily: "Prompt",
                        fontSize: size.width < 500 ? 14 : 20,
                        color: "#646464",
                        // alignSelf: "center",
                        marginLeft: 15,
                      }}
                    >
                      {course.comment}
                    </Text>
                  </View>
                  {course.approve != "approve" &&
                    subjectTestStates.limit != 0 &&
                    subjectTestStates.status == false &&
                    subjectTestStates.type != "WRITING" && (
                      <Text
                        style={{
                          fontSize: 14,
                          fontFamily: "Prompt",
                          color: "red",
                          marginTop: 10,
                        }}
                      >
                        {"สิทธ์ในการทำข้อสอบใหม่เหลืออีก " +
                          (subjectTestStates.limit - count) +
                          " ครั้ง"}
                      </Text>
                    )}
                  {subjectTestStates.limit - count == 0 &&
                  defaultsStatus == false ? (
                    <TouchableOpacity
                      onPress={async () => {
                        const response = await apiservice({
                          path: "/start-course/update/" + subjectTestStates.id,
                          method: "put",
                          body: {
                            progress: subjectTestStates.progress.map(
                              (item, i) => {
                                if (i == 0) {
                                  return {
                                    ...item,
                                    listen_detail: item.listen_detail.map(
                                      (items, ins) => {
                                        if (ins == 0) {
                                          return { ...items, status: true };
                                        } else if (
                                          ins !== 0 &&
                                          item.listen_detail[ins - 1].status ==
                                            true
                                        ) {
                                          return { ...items, status: true };
                                        } else {
                                          return items;
                                        }
                                      }
                                    ),
                                  };
                                } else {
                                  return {
                                    ...item,
                                    listen_detail: item.listen_detail.map(
                                      (items, ins) => {
                                        if (
                                          ins == 0 &&
                                          subjectTestStates.progress[i - 1]
                                            .listen_detail[
                                            subjectTestStates.progress[i - 1]
                                              .listen_detail.length - 1
                                          ].status == true
                                        ) {
                                          return { ...items, status: true };
                                        } else if (
                                          ins !== 0 &&
                                          item.listen_detail[ins - 1].status ==
                                            true
                                        ) {
                                          return { ...items, status: true };
                                        } else {
                                          return items;
                                        }
                                      }
                                    ),
                                  };
                                }
                              }
                            ),
                          },
                          token: token?.accessToken,
                        });
                        setTimeout(() => {
                          checkStartFinish();
                          checkStart();
                          setUnLock(true);
                        }, 500);
                        setTimeout(() => {
                          if (
                            defaultsStatus &&
                            subjectTestStates.dataStudymodel[
                              subjectTestStates.Mainindex
                            ].listen_detail[data.index + 1] == undefined &&
                            subjectTestStates.dataStudymodel[data.Mainindex + 1]
                              .listen_detail[0] == undefined
                          ) {
                            setModalScore(true);
                          }
                        }, 500);
                      }}
                      style={{
                        width: size.width < 500 ? 120 : 165,
                        height: 57,
                        backgroundColor: "#0D88F0",
                        borderRadius: 5,
                        justifyContent: "center",
                        alignItems: "center",
                        marginVertical: 40,
                        alignSelf: "center",
                      }}
                    >
                      <Text
                        style={{
                          fontFamily: "Prompt",
                          fontSize: size.width < 500 ? 14 : 25,
                          color: "#FFFFFF",
                        }}
                      >
                        เสร็จสิ้น
                      </Text>
                    </TouchableOpacity>
                  ) : (
                    <TouchableOpacity
                      disabled={course.status}
                      onPress={() => {
                        setUnLock(true);
                      }}
                      style={{
                        width: size.width < 500 ? 120 : 165,
                        height: 57,
                        backgroundColor: course.status ? "#CCCCCC" : "#0D88F0",
                        borderRadius: 5,
                        justifyContent: "center",
                        alignItems: "center",
                        marginVertical: 40,
                        alignSelf: "center",
                      }}
                    >
                      <Text
                        style={{
                          fontFamily: "Prompt",
                          fontSize: size.width < 500 ? 14 : 25,
                          color: "#FFFFFF",
                        }}
                      >
                        เสร็จสิ้น
                      </Text>
                    </TouchableOpacity>
                  )}
                  {course.approve == "notapprove" &&
                    subjectTestStates.type != "WRITING" &&
                    subjectTestStates.limit - count != 0 && (
                      <TouchableOpacity
                        onPress={async () => {
                          setDetail("");
                          setCourse({
                            status: false,
                            comment: "",
                            approve: "",
                          });
                          setPage(false);
                        }}
                        style={{
                          width: size.width < 500 ? 120 : 165,
                          height: 57,
                          backgroundColor: "#0D88F0",
                          borderRadius: 5,
                          justifyContent: "center",
                          alignItems: "center",
                          marginVertical: 40,
                          alignSelf: "center",
                        }}
                      >
                        <Text
                          style={{
                            fontFamily: "Prompt",
                            fontSize: size.width < 500 ? 14 : 25,
                            color: "#FFFFFF",
                          }}
                        >
                          ส่งอีกครั้ง
                        </Text>
                      </TouchableOpacity>
                    )}
                </View>
              )}
              {!course.status && (
                <TouchableOpacity
                  onPress={() => {
                    setModalVisible(true);
                  }}
                  style={{
                    width: 150,
                    height: 50,
                    borderWidth: 1,
                    borderColor: "#0D88F0",
                    borderRadius: 5,
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "row",
                    marginVertical: height * 0.05,
                    alignSelf: "flex-end",
                    marginTop: height * 0.1,
                  }}
                >
                  <Text
                    style={{
                      fontFamily: "Prompt",
                      fontSize: size.width < 500 ? 14 : 18,
                      color: "#0D88F0",
                    }}
                  >
                    {subjectTestStates.type == "WRITING"
                      ? "ส่งข้อสอบ"
                      : "ส่งงาน"}
                  </Text>
                </TouchableOpacity>
              )}
            </View>
          )}
          {check && (
            <View
              onPress={() => {
                setAnswer(true);
                setCheck(false);
                setPage(true);
              }}
              style={{
                width: "100%",
                maxWidth: 698,
                height: 100,
                borderWidth: 1,
                borderColor: "#0D88F0",
                borderRadius: 50,
                alignItems: "center",
                flexDirection: "row",
                justifyContent: "center",
                marginBottom: height * 0.05,
              }}
            >
              <Image
                style={{ width: 80, height: 80 }}
                source={require("../img/Group 66.png")}
              />
              <Text
                style={{
                  fontFamily: "Prompt",
                  fontSize:
                    (size.width > 1600 && 40) ||
                    (size.width > 500 && 40) ||
                    (size.width < 500 && 14),
                  color: "#646464",
                  marginLeft: 15,
                }}
              >
                รอการตรวจและตอบกลับจากผู้สอน
              </Text>
            </View>
          )}

          <Modal
            transparent={true}
            visible={modalVisible}
            onRequestClose={() => {
              Alert.alert("Modal has been closed.");
              setModalVisible(!modalVisible);
            }}
          >
            <View style={styles.viewModal}>
              <View style={styles.viewDetailModal}>
                <Text style={styles.textTitleModal}>
                  ยืนยันจะส่งคำตอบหรือไม่
                </Text>
                <View
                  style={{
                    flexDirection: "row",
                    marginTop: 80,
                    justifyContent: "space-between",
                  }}
                >
                  <TouchableOpacity
                    onPress={() => {
                      if (subjectTestStates.type == "WRITING") {
                        onSubmit1();
                      } else {
                        onSubmit();
                      }
                      setPage(false);

                      setTimeout(() => {
                        checkStartFinish();
                        checkStart();
                      }, 500);
                      setModalVisible(!modalVisible);
                    }}
                    style={styles.buttonModal}
                  >
                    <Text style={styles.textButtonModal}>ตกลง</Text>
                  </TouchableOpacity>
                  <TouchableOpacity
                    onPress={() => {
                      setModalVisible(!modalVisible);
                    }}
                    style={styles.buttonModal1}
                  >
                    <Text
                      style={{
                        fontFamily: "Prompt",
                        fontSize: 30,
                        color: "#0D88F0",
                      }}
                    >
                      ยกเลิก
                    </Text>
                  </TouchableOpacity>
                </View>
              </View>
            </View>
          </Modal>
          <Modal
            transparent={true}
            visible={modalVisible1}
            onRequestClose={() => {
              Alert.alert("Modal has been closed.");
              setModalVisible1(!modalVisible1);
            }}
          >
            <View style={styles.viewModal}>
              <View style={styles.viewDetailModal}>
                <View style={{ alignItems: "center" }}>
                  <Image
                    style={{ width: 100, height: 100 }}
                    source={require("../img/Group 66.png")}
                  />
                  <Text style={[styles.textTitleModal, { marginTop: 40 }]}>
                    หมดเวลาทำแบบทดสอบ
                  </Text>
                  <TouchableOpacity
                    onPress={() => {
                      setTimeout(() => {
                        checkStartFinish();
                        checkStart();
                      }, 500);
                      setPage(false);
                      setCheck(true);
                      setModalVisible1(!modalVisible1);
                    }}
                    style={[styles.buttonModal, { marginTop: 40 }]}
                  >
                    <Text style={styles.textButtonModal}>ตกลง</Text>
                  </TouchableOpacity>
                </View>
              </View>
            </View>
          </Modal>
          <Modal
            transparent={true}
            visible={modalScore}
            onRequestClose={() => {
              Alert.alert("Modal has been closed.");
              setModalScore(!modalScore);
            }}
          >
            <View style={styles.viewModal}>
              <View
                style={[
                  styles.viewDetailModal,
                  { maxWidth: 600, maxHeight: 520 },
                ]}
              >
                <View style={{}}>
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      paddingTop: 20,
                    }}
                  >
                    <Text
                      style={{
                        fontFamily: "Prompt",
                        fontSize: 30,
                        color: "#0B2051",
                      }}
                    >
                      ผลของคุณคือ
                    </Text>
                    <Text
                      style={{
                        fontFamily: "Prompt",
                        fontSize: 80,
                        color: "#0B2051",
                        marginLeft: 43,
                      }}
                    >
                      {sumScore?.EXAM}%
                    </Text>
                  </View>
                  <Text
                    style={{
                      fontFamily: "Prompt",
                      fontSize: 20,
                      color: "#0B2051",
                      alignSelf: "center",
                      marginBottom: 40,
                    }}
                  >
                    สรุปการเรียนของคุณ
                  </Text>
                  <View style={{ flexDirection: "row" }}>
                    <View style={styles.viewDashedModal}>
                      <MaterialIcons
                        name="library-books"
                        size={40}
                        color="#0B2051"
                      />
                      <Text style={styles.text25}>
                        {sumScore?.DESCRIPTION}/{sumScore?.DESCRIPTION}
                      </Text>
                    </View>
                    <View
                      style={[
                        styles.viewDashedModal,
                        { justifyContent: "flex-end", borderRightWidth: 0 },
                      ]}
                    >
                      <MaterialIcons name="archive" size={40} color="#0B2051" />
                      <Text style={styles.text25}>
                        {sumScore?.WORK}/{sumScore?.WORK}
                      </Text>
                    </View>
                  </View>
                  <View style={{ flexDirection: "row" }}>
                    <View style={styles.viewDashedModal1}>
                      <MaterialIcons
                        name="live-help"
                        size={40}
                        color="#0B2051"
                      />
                      <Text style={styles.text25}>
                        {sumScore?.WRITING}/{sumScore?.WRITING}
                      </Text>
                    </View>
                    <View
                      style={{
                        flexDirection: "row",

                        justifyContent: "flex-end",
                        alignItems: "center",
                        width: "50%",
                      }}
                    >
                      <Text
                        style={{
                          fontFamily: "Prompt",
                          fontSize: 25,
                          color: "#0B2051",
                        }}
                      >
                        ผล
                      </Text>
                      {Oncecourse.Type == "APPROVE" ? (
                        <Text
                          style={{
                            fontFamily: "Prompt",
                            fontSize: 30,
                            color: "#74C745",
                            marginLeft: 25,
                          }}
                        >
                          ผ่าน
                        </Text>
                      ) : Oncecourse.Type == "PENDING" ? (
                        <Text
                          style={{
                            fontFamily: "Prompt",
                            fontSize: 30,
                            color: "#EBE330",
                            marginLeft: 25,
                          }}
                        >
                          รอผล
                        </Text>
                      ) : (
                        <Text
                          style={{
                            fontFamily: "Prompt",
                            fontSize: 30,
                            color: "red",
                            marginLeft: 25,
                          }}
                        >
                          ไม่ผ่าน
                        </Text>
                      )}
                    </View>
                  </View>
                </View>
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "center",
                    marginVertical: 30,
                  }}
                >
                  <TouchableOpacity
                    onPress={async () => {
                      setModalScore(!modalScore);
                      setUnLock(false);
                      const response = await apiservice({
                        path:
                          "/course/getall?page=1&lan=TH&course_id=" +
                          subjectTestStates.course_id,
                        method: "get",
                        //token: token?.accessToken,
                      });

                      setCourseStates(response.data.data4[0]);
                      setTimeout(() => {
                        navigation.navigate("Course");
                      }, 900);
                    }}
                    style={{
                      width: 141,
                      height: 44,
                      borderWidth: 1,
                      borderColor: "#0D88F0",
                      borderRadius: 5,
                      justifyContent: "center",
                      alignItems: "center",
                      // marginLeft: courseStates.certificate ? 60 : 0,
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 20,
                        fontFamily: "Prompt",
                        color: "#0D88F0",
                      }}
                    >
                      ดูคอร์ส
                    </Text>
                  </TouchableOpacity>
                </View>
                {Oncecourse?.comment != null && (
                  <Text
                    style={{
                      fontSize: 12,
                      fontFamily: "Prompt",
                      color: "#E82626",
                      alignSelf: "center",
                    }}
                  >
                    {"***หมายเหตุ " + Oncecourse?.comment + " ***"}
                  </Text>
                )}
                <Text
                  style={{
                    fontSize: 12,
                    fontFamily: "Prompt",
                    color: "#E82626",
                    alignSelf: "center",
                  }}
                >
                  กรุณาติดต่อผู้สอนกรณีต้องการปรึกษาเรื่องผลการเรียน
                </Text>
              </View>
            </View>
          </Modal>
        </View>
        <View
          style={{
            width: "100%",
            height: 70,
            backgroundColor: defaultsStatus ? "#0D88F0" : "#D9D9D9",
            justifyContent: "center",
          }}
        >
          <View
            style={[
              styles.viewProgress,
              { backgroundColor: defaultsStatus ? "#0D88F0" : "#D9D9D9" },
            ]}
          >
            {subjectTestStates.Mainindex == 0 &&
            subjectTestStates.index == 0 ? (
              <View style={styles.viewTextProgress}>
                <TouchableOpacity style={{ margin: 10 }}>
                  <MaterialCommunityIcons
                    name="arrow-left-drop-circle"
                    size={0}
                    color="#FFFFFF"
                  />
                </TouchableOpacity>
                <View>
                  <Text
                    style={[styles.textProgress, { textAlign: "left" }]}
                  ></Text>
                  <Text numberOfLines={1} style={styles.textProgress}></Text>
                </View>
              </View>
            ) : (
              <View style={styles.viewTextProgress}>
                <TouchableOpacity
                  onPress={async () => {
                    const Mainindex = subjectTestStates.Mainindex;
                    const i = subjectTestStates.index;
                    const newi = subjectTestStates.index - 1;

                    if (
                      subjectTestStates.dataStudymodel[Mainindex].listen_detail[
                        i - 1
                      ] != undefined
                    ) {
                      Chanfd(
                        subjectTestStates.dataStudymodel[Mainindex]
                          .listen_detail[i - 1],
                        Mainindex,
                        newi,
                        true
                      );
                    } else {
                      Chanfd(
                        subjectTestStates.dataStudymodel[Mainindex - 1]
                          .listen_detail[
                          subjectTestStates.dataStudymodel[Mainindex - 1]
                            .listen_detail.length - 1
                        ],
                        Mainindex - 1,
                        subjectTestStates.dataStudymodel[Mainindex - 1]
                          .listen_detail.length - 1,
                        true
                      );
                    }
                  }}
                  style={{ margin: 10 }}
                >
                  <MaterialCommunityIcons
                    name="arrow-left-drop-circle"
                    size={30}
                    color="#FFFFFF"
                  />
                </TouchableOpacity>
                <View
                  style={{
                    width: size.width < 500 && "70%",
                  }}
                >
                  <Text style={[styles.textProgress, { textAlign: "left" }]}>
                    บทก่อนหน้า
                  </Text>
                  <Text numberOfLines={1} style={styles.textProgress}>
                    {subjectTestStates.dataStudymodel[
                      subjectTestStates.Mainindex
                    ].listen_detail[subjectTestStates.index - 1] != undefined
                      ? subjectTestStates.dataStudymodel[
                          subjectTestStates.Mainindex
                        ].listen_detail[subjectTestStates.index - 1].name
                      : subjectTestStates.dataStudymodel[
                          subjectTestStates.Mainindex - 1
                        ].listen_detail[
                          subjectTestStates.dataStudymodel[
                            subjectTestStates.Mainindex - 1
                          ].listen_detail.length - 1
                        ].name}
                  </Text>
                </View>
              </View>
            )}
            <TouchableOpacity
              disabled={true}
              style={[
                styles.buttonProgress,
                {
                  //backgroundColor: defaultsStatus ? "#E3F9D6" : "#FEF3CE",
                  width: size.width < 500 ? 100 : 150,
                },
              ]}
            >
              <Text style={styles.textSuccess}>
                {defaultsStatus ? "เสร็จสิ้น" : "กำลังดำเนินการ.."}
              </Text>
            </TouchableOpacity>
            {subjectTestStates.dataStudymodel ? (
              <View
                style={[
                  styles.viewTextProgress,
                  { justifyContent: "flex-end" },
                ]}
              >
                <View
                  style={{
                    width: size.width < 500 && "70%",
                  }}
                >
                  <Text style={[styles.textProgress]}>บทถัดไป</Text>
                  <Text numberOfLines={1} style={styles.textProgress}>
                    {subjectTestStates.dataStudymodel[
                      subjectTestStates.Mainindex
                    ].listen_detail[subjectTestStates.index + 1] != undefined
                      ? subjectTestStates.dataStudymodel[
                          subjectTestStates.Mainindex
                        ].listen_detail[subjectTestStates.index + 1].name
                      : subjectTestStates.dataStudymodel[
                          subjectTestStates.Mainindex + 1
                        ] != undefined
                      ? subjectTestStates.dataStudymodel[
                          subjectTestStates.Mainindex + 1
                        ].listen_detail?.[0]?.name
                      : ""}
                  </Text>
                </View>
                <TouchableOpacity
                  onPress={async () => {
                    const Mainindex = subjectTestStates.Mainindex;
                    const i = subjectTestStates.index;
                    const newi = subjectTestStates.index + 1;

                    if (
                      subjectTestStates.dataStudymodel[Mainindex].listen_detail[
                        i + 1
                      ] != undefined
                    ) {
                      Chanfd(
                        subjectTestStates.dataStudymodel[Mainindex]
                          .listen_detail[i + 1],
                        Mainindex,
                        newi,
                        Oncecourse.progress[Mainindex].listen_detail[i + 1]
                          .status
                      );
                    } else if (
                      subjectTestStates.dataStudymodel[Mainindex + 1]
                        .listen_detail?.[0] != undefined
                    ) {
                      Chanfd(
                        subjectTestStates.dataStudymodel[Mainindex + 1]
                          .listen_detail?.[0],
                        Mainindex + 1,
                        0,
                        Oncecourse.progress[Mainindex + 1].listen_detail?.[0]
                          ?.status
                      );
                    } else {
                      const respp = await apiservice({
                        path:
                          "/start-course/getalltotals/" +
                          subjectTestStates.course_id,
                        method: "get",
                        token: token?.accessToken,
                      });
                      const resppt = await apiservice({
                        path:
                          "/start-course/getworktotals/" +
                          subjectTestStates.course_id,
                        method: "get",
                        token: token?.accessToken,
                      });
                      if (respp.status == 200) {
                        setShow1(resppt.data.results);
                        setShow(respp.data.results);
                        setModalScore(true);
                      }
                    }
                  }}
                  disabled={defaultsStatus ? false : true}
                  style={{ margin: 10 }}
                >
                  <MaterialCommunityIcons
                    name={defaultsStatus ? "arrow-right-drop-circle" : "lock"}
                    size={30}
                    color="#FFFFFF"
                  />
                </TouchableOpacity>
              </View>
            ) : (
              <View style={styles.viewTextProgress}>
                <TouchableOpacity style={{ margin: 10 }}>
                  <MaterialCommunityIcons
                    name="arrow-right-drop-circle"
                    size={0}
                    color="#FFFFFF"
                  />
                </TouchableOpacity>
                <View>
                  <Text
                    style={[styles.textProgress, { textAlign: "right" }]}
                  ></Text>
                  <Text numberOfLines={1} style={styles.textProgress}></Text>
                </View>
              </View>
            )}
          </View>
        </View>
      </ScrollView>
    </View>
  );
}
const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: "100%",
    backgroundColor: "#FFFFFF",
  },
  viewProgress: {
    width: "100%",
    height: 70,
    flexDirection: "row",
    justifyContent: "space-between",
  },
  viewTextProgress: {
    flexDirection: "row",
    alignItems: "center",
    width: "30%",
  },
  textProgress: {
    fontFamily: "Prompt",
    fontSize: 12,
    color: "#FFFFFF",
    textAlign: "right",
  },
  buttonProgress: {
    width: 150,
    height: 40,
    borderRadius: 20,
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "center",
  },
  textSuccess: {
    fontFamily: "Prompt",
    fontSize: 12,
    color: "#FFF",
  },
  viewModal: {
    width: "100%",
    height: "100%",
    backgroundColor: "#000000bb",
    justifyContent: "center",
  },
  viewDetailModal: {
    width: "100%",
    maxWidth: 650,
    height: "100%",
    maxHeight: 400,
    backgroundColor: "#FFFFFF",
    borderWidth: 1,
    borderColor: "#0D88F0",
    padding: 50,
    paddingHorizontal: width * 0.05,
    alignSelf: "center",
    justifyContent: "center",
  },
  buttonModal: {
    width: 150,
    height: 50,
    backgroundColor: "#0D88F0",
    borderRadius: 5,
    justifyContent: "center",
    alignItems: "center",
  },
  textButtonModal: {
    fontFamily: "Prompt",
    fontSize: 30,
    color: "#FFFFFF",
  },
  textTitleModal: {
    fontFamily: "Prompt",
    fontSize: 40,
    color: "#0B2051",
    alignSelf: "center",
  },
  buttonModal1: {
    width: 150,
    height: 50,
    borderWidth: 1,
    borderColor: "#0D88F0",
    borderRadius: 5,
    justifyContent: "center",
    alignItems: "center",
  },
  textDetailModal: {
    fontFamily: "Prompt",
    fontSize: 18,
    color: "#0B2051",
  },
  viewDashedModal: {
    flexDirection: "row",
    borderRightWidth: 2,
    borderBottomWidth: 2,
    borderRightColor: "#0D88F0",
    borderBottomColor: "#0D88F0",
    width: "50%",
    borderStyle: "dashed",
    alignItems: "center",
    paddingVertical: 20,
  },
  viewDashedModal1: {
    flexDirection: "row",
    borderRightWidth: 2,
    borderRightColor: "#0D88F0",
    width: "50%",
    borderStyle: "dashed",
    alignItems: "center",
    paddingVertical: 20,
  },
  text25: {
    fontFamily: "Prompt",
    fontSize: 25,
    color: "#0B2051",
    marginLeft: 25,
  },
  viewCkEditor: {
    borderWidth: 1,
    borderColor: "#BBB",
    marginTop: 55,
  },
});
