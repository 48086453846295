import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  Image,
  Dimensions,
  Animated,
  TouchableOpacity,
  StyleSheet,
  ScrollView,
  TextInput,
  FlatList,
} from "react-native";
import {
  MaterialCommunityIcons,
  FontAwesome,
  FontAwesome5,
  MaterialIcons,
} from "@expo/vector-icons";
import { apiservice } from "../../service";
import { useRecoilValue } from "recoil";
import { tokenState } from "../../reducer/recoil";
import { useWindowSize } from "../components/responsive";
const { width, height } = Dimensions.get("window");
export default function gradeBook({ navigation, onPress, setWork }) {
  const [select, setSelect] = useState();
  const token = useRecoilValue(tokenState);
  const [user, setUser] = useState();

  const [paginations, setPaginations] = useState([]);
  const [page, setpage] = useState(1);
  const [total_page, setTotalpage] = useState(0);
  const [list, setList] = useState();
  const [DATA, setCourse] = useState([]);
  const [DATA1, setData] = useState([]);

  useEffect(() => {
    if (token) {
      getCourseId();
    }
  }, [token]);

  async function getCourseId() {
    try {
      const usel = await apiservice({
        path: "/authen/user",
        method: "get",
        token: token?.accessToken,
      });

      if (usel.status == 200) {
        const response = await apiservice({
          path:
            "/course/getcouse?id=" +
            usel.data.result.user_accounts.uid +
            "&totalpage=100&page=1&available=true",
          method: "get",
          token: token?.accessToken,
        });

        if (response.status == 200) {
          let pagination = [];
          for (let index = 0; index < response.data.total_page; index++) {
            pagination = pagination.concat({});
          }
          setUser(response.data.result.user);
          setCourse(response.data.result);
          setTotalpage(response.data.totalpage);
          setPaginations(pagination);
          const datee = await Promise.all(
            response.data.result.map(async (item) => {
              const respon = await getgradebookstudent(item.id);
              return { ...item, ...respon };
            })
          );
          setData(datee);
        }
      } else {
      }
      // if (response.status == 200) {
      //   let pagination = [];
      //   for (let index = 0; index < response.data.total_page; index++) {
      //     pagination = pagination.concat({});
      //   }

      //   setUser(response.data.result.user);
      //   setCourse(response.data.result);
      // }
    } catch (error) {
      throw error;
    }
  }
  async function getgradebookstudent(id) {
    const response1 = await apiservice({
      path: "/start-course/getgradebookstudent/" + id,
      method: "get",
      token: token?.accessToken,
    });
    if (response1.status == 200) {
      return response1.data;
    }
  }
  function checktranfer(params) {
    let num = 0;
    params.map((item) => {
      item.listen_detail.map((e) => {
        if (
          e.type != "EXAM" &&
          e.type != "WRITING" &&
          e.type != "WORK" &&
          e.status == true
        )
          num = num + 1;
      });
    });
    return num;
  }
  function checktranfer1(params) {
    let num = 0;
    params.map((item) => {
      item.listen_detail.map((e) => {
        if (
          e.type != "DESCRIPTION" &&
          e.type != "EXAM" &&
          e.type != "WORK" &&
          e.status == true
        )
          num = num + 1;
      });
    });
    return num;
  }
  function checktranfer2(params) {
    let num = 0;
    params.map((item) => {
      item.listen_detail.map((e) => {
        if (
          e.type != "DESCRIPTION" &&
          e.type != "WRITING" &&
          e.type != "EXAM" &&
          e.status == true
        ) {
          num = num + 1;
        }
      });
    });

    return num;
  }
  function tranfer(params) {
    let num = 0;
    params.map((item) => {
      item.listen_detail.map((e) => {
        if (e.type != "EXAM" && e.type != "WRITING" && e.type != "WORK")
          num = num + 1;
      });
    });
    return num;
  }
  function tranfer1(params) {
    let num = 0;
    params.map((item) => {
      item.listen_detail.map((e) => {
        if (e.type != "DESCRIPTION" && e.type != "EXAM" && e.type != "WORK")
          num = num + 1;
      });
    });
    return num;
  }
  function tranfer2(params) {
    let num = 0;
    params.map((item) => {
      item.listen_detail.map((e) => {
        if (
          e.type != "DESCRIPTION" &&
          e.type != "EXAM" &&
          e.type != "WRITING"
        ) {
          num = num + 1;
        }
      });
    });
    return num;
  }
  const size = useWindowSize();
  return (
    <View
      style={{ minHeight: 1320, paddingHorizontal: size.width < 500 ? 20 : 50 }}
    >
      <Text
        style={[styles.textGradebook, { fontSize: size.width < 500 ? 20 : 25 }]}
      >
        GradeBook
      </Text>
      <View style={{ flexDirection: "row", marginTop: 12 }}>
        <View style={{ flexDirection: "row" }}>
          <TextInput
            autoComplete="off"
            placeholder="ค้นหาชื่อ .."
            placeholderTextColor="#646464"
            style={[
              styles.inputSearchName,
              {
                maxWidth: size.width < 500 ? 200 : 400,
                fontSize: size.width < 500 ? 14 : 20,
              },
            ]}
          />
          <TouchableOpacity style={styles.buttonSearch}>
            <MaterialCommunityIcons name="magnify" size={24} color="#FFFFFF" />
          </TouchableOpacity>
        </View>
      </View>
      {DATA.length != 0 && <Text style={styles.textName}>ชื่อ</Text>}

      <FlatList
        numColumns={1}
        data={DATA1}
        style={{}}
        renderItem={({ item, index }) => {
          return (
            <View
              style={[styles.viewDetail, { borderTopWidth: index == 0 && 1 }]}
            >
              <TouchableOpacity
                onPress={() => {
                  setList(index);
                  if (index == list) {
                    setList();
                  }
                }}
                style={styles.buttonDetail}
              >
                <Text style={styles.text}>{item.title}</Text>

                <FontAwesome5
                  name={index == list ? "caret-up" : "caret-down"}
                  size={24}
                  color="#0B2051"
                  style={{ marginRight: 15 }}
                />
              </TouchableOpacity>
              {index == list && (
                <View style={{ backgroundColor: "#FFF" }}>
                  <View style={styles.viewCourseDetail}>
                    <Text style={[styles.text, { width: "33%" }]}>
                      จำนวนผู้เข้าเรียนทั้งหมด : {item?.all?.length} คน
                    </Text>
                    <Text style={[styles.text, { width: "33%" }]}>
                      ผ่านคอร์สเรียนทั้งหมด :{" "}
                      {item?.approve == null
                        ? "0.00"
                        : item?.approve?.toFixed(2)}
                      %
                    </Text>
                    <Text style={[styles.text, { width: "33%" }]}></Text>
                  </View>
                  <View
                    style={[
                      styles.viewCourseDetail,
                      { borderTopWidth: 0, marginTop: 0 },
                    ]}
                  >
                    <Text style={[styles.text, { width: "33%" }]}>
                      ผ่านบทเรียนทั้งหมด :{" "}
                      {item?.all?.length > 0
                        ? (
                            (item?.all
                              .filter((e) => e != null)
                              .filter((e) => {
                                return (
                                  checktranfer(e?.progress) >=
                                  tranfer(e?.courses.studymodel)
                                );
                              })?.length /
                              item?.all?.length) *
                            100
                          )?.toFixed(2) + "%"
                        : "0.00%"}
                    </Text>
                    <Text style={[styles.text, { width: "33%" }]}>
                      ผ่านข้อสอบทั้งหมด :{" "}
                      {item?.all?.length > 0
                        ? (
                            (item?.all
                              .filter((e) => e != null)
                              .filter((e) => {
                                return (
                                  checktranfer1(e?.progress) >=
                                  tranfer1(e?.courses.studymodel)
                                );
                              })?.length /
                              item?.all?.length) *
                            100
                          )?.toFixed(2) + "%"
                        : "0.00%"}
                    </Text>
                    <Text style={[styles.text, { width: "33%" }]}>
                      ผ่านส่งงานทั้งหมด :{" "}
                      {item?.all?.length > 0
                        ? (
                            (item?.all
                              .filter((e) => e != null)
                              .filter((e) => {
                                console.log("progress >> ", e);
                                return checktranfer2(e?.progress) == 0
                                  ? false
                                  : checktranfer2(e?.progress) >=
                                      tranfer2(e?.courses.studymodel);
                              })?.length /
                              item?.all?.length) *
                            100
                          )?.toFixed(2) + "%"
                        : "0.00%"}
                    </Text>
                  </View>
                  <TouchableOpacity
                    disabled={item?.all?.length > 0 ? false : true}
                    onPress={() => {
                      setWork(item);
                      onPress();
                    }}
                    style={{ marginTop: 18 }}
                  >
                    <Text
                      style={[
                        styles.text,
                        { color: item?.all?.length > 0 ? "#0B2051" : "#CCC" },
                      ]}
                    >
                      ข้อมูลผู้เรียน
                    </Text>
                  </TouchableOpacity>
                </View>
              )}
            </View>
          );
        }}
      />
    </View>
  );
}
const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: "100%",
    backgroundColor: "#FFFFFF",
  },
  textGradebook: {
    fontFamily: "Prompt",
    fontSize: 25,
    color: "#0B2051",
    marginTop: 40,
  },
  inputSearchName: {
    width: "100%",
    maxWidth: 400,
    height: 40,
    borderWidth: 1,
    borderColor: "#D9D9D9",
    paddingHorizontal: 15,
    fontFamily: "Prompt",
    fontSize: 20,
    color: "#646464",
  },
  buttonSearch: {
    width: 40,
    height: 40,
    backgroundColor: "#0D88F0",
    justifyContent: "center",
    alignItems: "center",
  },
  textName: {
    fontFamily: "Prompt",
    fontSize: 20,
    color: "#0B2051",
    marginTop: 20,
    marginBottom: 12,
  },
  viewDetail: {
    borderTopColor: "#BBBBBB",
    borderBottomColor: "#BBBBBB",
    borderBottomWidth: 1,
    paddingVertical: 25,
  },
  buttonDetail: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  text: {
    fontFamily: "Prompt",
    fontSize: 16,
    color: "#0B2051",
  },
  viewCourseDetail: {
    width: "100%",
    minHeight: 40,
    borderWidth: 1,
    borderColor: "#E0E0E0",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 18,
  },
});
