import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  Image,
  Dimensions,
  Animated,
  TouchableOpacity,
  StyleSheet,
  ScrollView,
  TextInput,
  FlatList,
} from "react-native";
import {
  MaterialCommunityIcons,
  MaterialIcons,
  Feather,
  Ionicons,
  FontAwesome,
} from "@expo/vector-icons";
import RNPickerSelect from "react-native-picker-select";
import Modal from "modal-react-native-web";
import { apiservice } from "../../../service";
import { useRecoilState, useRecoilValue } from "recoil";
import { courseState, tokenState } from "../../../reducer/recoil";
import moment from "moment";
import { useWindowSize } from "../../components/responsive";

const { width, height } = Dimensions.get("window");
export default function addCourse({ onPress, navigation }) {
  const [courseStates, setCourseStates] = useRecoilState(courseState);
  const [DATA, setData] = useState([]);
  const token = useRecoilValue(tokenState);
  const size = useWindowSize();
  const [search, setSearch] = useState("");
  useEffect(() => {
    apiCall();
  }, [token]);

  async function apiCall() {
    const res = await apiservice({
      path: "/course/getcourseGeneralRequest?page=1&totalpage=100",
      method: "get",
      token: token?.accessToken,
    });

    if (res?.status == 200) {
      setData(res?.data?.result);
    }
  }

  return (
    <View>
      <View
        style={{ flexDirection: "row", marginTop: 20, alignItems: "center" }}
      >
        <TextInput
          onChangeText={(text) => {
            setSearch(text);
          }}
          autoComplete="off"
          placeholder="ค้นหาชื่อคอร์ส"
          placeholderTextColor="#8C8896"
          style={{
            width: size.width < 500 ? "80%" : "30%",
            height: 48,
            borderRadius: 4,
            backgroundColor: "#EFEFF3",
            fontSize: 16,
            fontFamily: "Prompt",
            color: "#8C8896",
            paddingHorizontal: 25,
          }}
        />
        <TouchableOpacity style={{ marginLeft: -40 }}>
          <MaterialCommunityIcons name="magnify" size={24} color="#0D88F0" />
        </TouchableOpacity>
      </View>
      <ScrollView horizontal>
        <View
          style={{
            width:
              size.width < 500
                ? size.width * 2.5
                : size.width < 1280
                ? size.width * 1.0
                : size.width * 0.75,
            // minHeight: height * 2.5,
          }}
        >
          <View style={styles.viewSubject}>
            <Text style={[styles.text16B, { paddingLeft: 67, width: "35%" }]}>
              ชื่อคอร์ส
            </Text>
            <Text style={[styles.text16B, { width: "20%" }]}>ชื่อผู้สอน</Text>
            <Text style={[styles.text16B, { width: "20%" }]}>
              วันที่ส่งคำขอ
            </Text>
            <Text
              style={[styles.text16B, { width: "25%", textAlign: "center" }]}
            >
              สถานะ
            </Text>
          </View>
          <FlatList
            numColumns={1}
            data={DATA.filter((item) => {
              return item.title.includes(search);
            })}
            renderItem={({ item, index }) => {
              return (
                <TouchableOpacity
                  onPress={() => {
                    setCourseStates(item);
                    setTimeout(() => {
                      navigation.navigate("Course");
                    }, 900);
                  }}
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    borderBottomWidth: 1,
                    borderBottomColor: "#E5E5E5",
                    height: 50,
                    alignItems: "center",
                  }}
                >
                  <Text
                    style={[styles.text16B, { paddingLeft: 67, width: "35%" }]}
                  >
                    {item?.title}
                  </Text>
                  <Text style={[styles.text16B, { width: "20%" }]}>
                    {item?.teacher?.firstName + " " + item?.teacher?.lastName}
                  </Text>
                  <Text style={[styles.text16B, { width: "20%" }]}>
                    {moment(item?.createdAt)
                      .locale("th")
                      .add(543, "years")
                      .format("DD MMMM พ.ศ. YYYY")}
                  </Text>
                  <View
                    style={{
                      flexDirection: "row",
                      width: "25%",
                      justifyContent: "center",
                    }}
                  >
                    <TouchableOpacity
                      onPress={async () => {
                        const res = await apiservice({
                          path: "/course/approvecourse/" + item?.id,
                        });

                        if (res?.status == 200) {
                          apiCall();
                        }
                      }}
                      style={{
                        width: 75,
                        height: 30,
                        backgroundColor: "#0D88F0",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: 4,
                      }}
                    >
                      <Text
                        style={{
                          fontFamily: "Prompt",
                          fontSize: 16,
                          color: "#FFFFFF",
                        }}
                      >
                        ยืนยัน
                      </Text>
                    </TouchableOpacity>
                    <TouchableOpacity
                      onPress={async () => {
                        const res = await apiservice({
                          path: "/course/rejectcourse/" + item?.id,
                        });

                        if (res?.status == 200) {
                          apiCall();
                        }
                      }}
                      style={{
                        width: 75,
                        height: 30,
                        backgroundColor: "#808D93",
                        justifyContent: "center",
                        alignItems: "center",
                        marginLeft: 22,
                        borderRadius: 4,
                      }}
                    >
                      <Text
                        style={{
                          fontFamily: "Prompt",
                          fontSize: 16,
                          color: "#FFFFFF",
                        }}
                      >
                        ยกเลิก
                      </Text>
                    </TouchableOpacity>
                  </View>
                </TouchableOpacity>
              );
            }}
          />
        </View>
      </ScrollView>
    </View>
  );
}
const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: "100%",
    backgroundColor: "#FFFFFF",
  },
  text23O: { fontSize: 23, fontFamily: "Prompt", color: "#0D88F0" },
  text16B: {
    fontFamily: "Prompt",
    fontSize: 16,
    color: "#000000",
    width: "23%",
  },

  viewSubject: {
    width: "100%",
    height: 51,
    backgroundColor: "#E5E5E5",
    marginTop: 50,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  bgModal: {
    width: "100%",
    height: "100%",
    backgroundColor: "#000000bb",
    justifyContent: "center",
  },
  viewDetailModal: {
    width: "50%",
    height: 380,
    backgroundColor: "#FFFFFF",
    borderRadius: 20,
    alignSelf: "center",
    paddingHorizontal: width * 0.05,
    paddingVertical: 50,
  },
  buttonModal: {
    width: 102,
    height: 48,
    borderRadius: 4,
    backgroundColor: "#EFEFF3",
    justifyContent: "center",
    alignItems: "center",
  },
  textButtonModal: {
    fontSize: 25,
    fontFamily: "Prompt",
  },
});
