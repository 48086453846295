import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  Image,
  Dimensions,
  Animated,
  TouchableOpacity,
  StyleSheet,
  ScrollView,
  TextInput,
  FlatList,
} from "react-native";
import {
  MaterialCommunityIcons,
  FontAwesome,
  FontAwesome5,
  MaterialIcons,
} from "@expo/vector-icons";
import Header from "../components/header";
import { bottomPart } from "./home";
import { apiservice } from "../../service";
import { useRecoilState } from "recoil";
import { tokenState, UniversityState } from "../../reducer/recoil";
import NumberFormat from "react-number-format";
import { useWindowSize } from "../components/responsive";
const { width, height } = Dimensions.get("window");
export default function courseUniversity({ navigation, route }) {
  const [university, setUniversity] = useRecoilState(UniversityState);
  const universitys = university;
  const [token, setToken] = useRecoilState(tokenState);
  const [course, setCourse] = useState([]);
  const [university_id, setuniversity_id] = useState();

  const [faculty, setFaculty] = useState([]);
  const [major, setMajor] = useState();

  const DATA = universitys;

  const size = useWindowSize();
  // let one =faculty.map((item, index) => {

  //   if (item.major == 0) {
  //     // setMajor((val) => {
  //     // ...val,
  //     // one: val.detail.concat({
  //     //   Type: "PENDING",
  //     //   progress: [],
  //     //   faculty_id: state.faculty_id,
  //     //   university_id: state.university_id,
  //     //   season: state.season,
  //     //   years: state.years,
  //     //   course_id: state.course_id,
  //     // }),
  //     // });
  //     // return item;

  //   }
  // }),

  useEffect(() => {
    getCourseUniversity(token);
    getFaculty(token);
  }, [token]);

  async function getCourseUniversity(token) {
    try {
      const response = await apiservice({
        path:
          "/course/getallcouseapprove?university_id=" +
          university?.data?.university_id +
          "&page=1&totalpage=100",
        method: "get",
        token: token?.accessToken,
      });
      if (response.status == 200) {
        setCourse(response.data.result);
        // setuniversity_id(response.data.result[0].university_id);
      }
    } catch (error) {
      throw error;
    }
  }

  async function getFaculty(token) {
    try {
      const response = await apiservice({
        path:
          "/course/getfaculty?page=1&totalpage=100&university_id=" +
          university?.data?.university_id,
        method: "get",
        token: token?.accessToken,
      });
      if (response.status == 200) {
        setFaculty(response.data.result);
      }
    } catch (error) {
      throw error;
    }
  }

  return (
    <View style={styles.container}>
      <ScrollView stickyHeaderIndices={[0]}>
        <Header navigation={navigation} />
        <View
          style={[
            styles.viewSelectCourse,
            { flexDirection: size.width < 500 ? "column" : "row" },
          ]}
        >
          <View
            style={[
              styles.viewAllCourse,
              { width: size.width < 500 ? "100%" : "20%" },
            ]}
          >
            <View>
              <Text style={[styles.textTitle]}>ปริญญาตรี</Text>
              <FlatList
                data={faculty.filter((item) => {
                  return item.major == 0;
                })}
                numColumns={1}
                scrollEnabled={false}
                renderItem={({ index, item }) => {
                  return (
                    <TouchableOpacity
                      onPress={() => {
                        setuniversity_id(item);
                      }}
                    >
                      <Text
                        style={[
                          styles.textTitle,
                          {
                            marginLeft: 7,
                            marginTop: 10,
                            color:
                              university_id?.facuty_name_th ==
                                item.facuty_name_th &&
                              university_id?.major_name_th ==
                                item.major_name_th &&
                              university_id?.major == 0
                                ? "#0D88F0"
                                : "#5D5D5D",
                          },
                        ]}
                      >
                        {"-" +
                          item.facuty_name_th +
                          " (" +
                          item.major_name_th +
                          ")"}
                      </Text>
                    </TouchableOpacity>
                  );
                }}
              />
            </View>
            {university?.data?.two != null && (
              <View>
                <Text style={[styles.textTitle, { marginTop: 32 }]}>
                  ปริญญาโท
                </Text>

                <FlatList
                  data={faculty.filter((item) => {
                    return item.major == 1;
                  })}
                  numColumns={1}
                  scrollEnabled={false}
                  renderItem={({ index, item }) => {
                    return (
                      <TouchableOpacity
                        onPress={() => {
                          setuniversity_id(item);
                        }}
                      >
                        <Text
                          style={[
                            styles.textTitle,
                            {
                              marginLeft: 7,
                              color:
                                university_id?.facuty_name_th ==
                                  item.facuty_name_th &&
                                university_id?.major_name_th ==
                                  item.major_name_th &&
                                university_id?.major == 1
                                  ? "#0D88F0"
                                  : "#5D5D5D",
                            },
                          ]}
                        >
                          {"-" +
                            item.facuty_name_th +
                            " (" +
                            item.major_name_th +
                            ")"}
                        </Text>
                      </TouchableOpacity>
                    );
                  }}
                />
              </View>
            )}
            {university?.data?.three != null && (
              <View>
                <Text style={[styles.textTitle, { marginTop: 32 }]}>
                  ปริญญาเอก
                </Text>

                <FlatList
                  data={faculty.filter((item) => {
                    return item.major == 2;
                  })}
                  numColumns={1}
                  scrollEnabled={false}
                  renderItem={({ index, item }) => {
                    return (
                      <TouchableOpacity
                        onPress={() => {
                          setuniversity_id(item);
                        }}
                      >
                        <Text
                          style={[
                            styles.textTitle,
                            {
                              marginLeft: 7,
                              color:
                                university_id?.facuty_name_th ==
                                  item.facuty_name_th &&
                                university_id?.major_name_th ==
                                  item.major_name_th &&
                                university_id?.major == 2
                                  ? "#0D88F0"
                                  : "#5D5D5D",
                            },
                          ]}
                        >
                          {"-" +
                            item.facuty_name_th +
                            " (" +
                            item.major_name_th +
                            ")"}
                        </Text>
                      </TouchableOpacity>
                    );
                  }}
                />
              </View>
            )}
          </View>
          <View
            style={[
              styles.viewCourse,
              { width: width < 1280 ? "100%" : "80%" },
            ]}
          >
            <Text
              style={[
                styles.textUniver,
                { fontSize: width < 1600 ? 50 : 70, alignSelf: "center" },
              ]}
            >
              {DATA.university_name_th}
            </Text>
            <View>
              <FlatList
                data={course.filter((item) => {
                  if (university_id == null) {
                    return true;
                  } else {
                    return item.faculty_id == university_id?.id;
                  }
                })}
                numColumns={width < 1280 ? 1 : width < 1600 ? 3 : 4}
                //scrollEnabled={false}
                renderItem={({ index, item }) => {
                  return (
                    <View
                      style={[
                        styles.viewDetail,
                        {
                          width: width < 1750 ? 250 : 278,
                          marginLeft: width >= 1280 && 25,
                          marginTop: 25,
                          marginBottom: index > 8 && 65,
                        },
                      ]}
                    >
                      <Image
                        style={[
                          styles.imgDetail,
                          {
                            width: width < 1750 ? 250 : 278,
                            backgroundColor: "#CCCCCC",
                          },
                        ]}
                        source={{
                          uri:
                            "https://api.courseoncloud.com/api/image/getimage/" +
                            item.img_content,
                        }}
                      />
                      <View style={styles.viewCourseDetail}>
                        <Text style={styles.text} numberOfLines={1}>
                          {item.title}
                        </Text>
                        <View style={styles.viewImg}>
                          <Image
                            style={styles.img}
                            source={{
                              uri:
                                "https://api.courseoncloud.com/api/image/getimage/" +
                                item.teacher.image_url,
                            }}
                          />
                          <Text style={styles.text}>
                            {item.teacher.firstName} {item.teacher.lastName}
                          </Text>
                        </View>
                        <View style={styles.feedback}>
                          <View style={styles.viewStar}>
                            <FontAwesome
                              name="star"
                              size={20}
                              color="#0D88F0"
                            />
                            <FontAwesome
                              name="star"
                              size={20}
                              color="#0D88F0"
                            />
                            <FontAwesome
                              name="star"
                              size={20}
                              color="#0D88F0"
                            />
                            <FontAwesome
                              name="star"
                              size={20}
                              color="#0D88F0"
                            />
                            <FontAwesome
                              name="star"
                              size={20}
                              color="#0D88F0"
                            />
                          </View>
                          <View>
                            {item.type != "COURSESCHOOL" ? (
                              <View
                                style={{
                                  height: 20,
                                  backgroundColor: "#0B2051",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  borderRadius: 10,
                                  marginVertical: 5,
                                  alignSelf: "flex-start",
                                }}
                              >
                                <Text
                                  style={{
                                    fontFamily: "Prompt",
                                    fontSize: 12,
                                    color: "#FFFFFF",
                                    paddingHorizontal: 10,
                                  }}
                                >
                                  เฉพาะนักศึกษา
                                </Text>
                              </View>
                            ) : (
                              <Text
                                style={[
                                  styles.text,
                                  {
                                    textAlign: "right",
                                    fontSize: size.width < 500 ? 12 : 16,
                                  },
                                ]}
                              >
                                <NumberFormat
                                  value={item.courses.price}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                />{" "}
                                {"บาท"}
                              </Text>
                            )}
                            <Text
                              style={[
                                styles.text,
                                {
                                  fontSize: size.width < 500 ? 12 : 16,
                                  textAlign: "right",
                                },
                              ]}
                            >
                              <NumberFormat
                                value={item.lengthdata}
                                displayType={"text"}
                                thousandSeparator={true}
                              />{" "}
                              <FontAwesome5
                                name="user-friends"
                                size={20}
                                color="#0B2051"
                              />
                            </Text>
                          </View>
                        </View>
                      </View>
                    </View>
                  );
                }}
              />
            </View>
          </View>
        </View>
      </ScrollView>
    </View>
  );
}
const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: "100%",
    backgroundColor: "#FFFFFF",
  },
  textTitle: {
    fontSize: 18,
    fontFamily: "Prompt",
    color: "#5D5D5D",
  },
  text: {
    fontSize: 16,
    fontFamily: "Prompt",
    color: "#0B2051",
  },
  viewDetail: {
    width: 278,
    height: 385,
  },
  imgDetail: {
    width: 278,
    height: 195,
  },
  feedback: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  viewAllCourse: {
    width: "20%",
    maxWidth: "348",
    // maxHeight: 638,
    borderWidth: 1,
    borderColor: "#EEEEEE",
    borderRadius: 5,
    padding: 12,
    marginVertical: height * 0.1,
  },
  viewSelectCourse: {
    flexDirection: "row",
    width: "90%",
    alignSelf: "center",
  },
  viewCourse: {
    width: "80%",
    // alignItems: "center",
    paddingTop: 44,
    paddingHorizontal: 40,
  },
  textUniver: {
    fontSize: 70,
    fontFamily: "Prompt",
    color: "#0B2051",
  },
  viewImg: {
    flexDirection: "row",
    alignItems: "center",
    minHeight: 70,
  },
  img: {
    width: 35,
    height: 35,
    borderRadius: 18,
    marginRight: 15,
  },
  viewStar: {
    flexDirection: "row",
    alignSelf: "flex-end",
  },
  viewCourseDetail: {
    padding: 12,
    borderWidth: 1,
    borderColor: "#0D88F0",
  },
});
