import React, { useState } from "react";
import {
  View,
  Text,
  Image,
  Dimensions,
  Animated,
  TouchableOpacity,
  StyleSheet,
  ScrollView,
  TextInput,
  Linking,
} from "react-native";
import { MaterialIcons } from "@expo/vector-icons";
import { apiservice } from "../../service";
import { useRecoilValue } from "recoil";
import { tokenState } from "../../reducer/recoil";
import HTMLRenderer from "react-html-renderer";
import moment from "moment";
const { width, height } = Dimensions.get("window");
export default function workReceived({ onPress, Work }) {
  const token = useRecoilValue(tokenState);
  const [comment, setcomment] = useState("");
  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };
  const embed = Work.detail;

  const stringToHTML = function (str) {
    const domContainer = document.createElement("span");
    domContainer.innerHTML = str;
    return domContainer;
  };

  const parentEmbed = stringToHTML(embed);

  let oldIframe = parentEmbed.querySelectorAll("oembed");
  oldIframe = Array.from(oldIframe);

  for (const i in oldIframe) {
    //Get the url from oembed tag
    let url = oldIframe[i].getAttribute("url");
    //Replace 'watch?v' with 'embed/'
    url = url.replace("watch?v=", "embed/");

    //Create a iframe tag
    const newIframe = document.createElement("iframe");
    newIframe.setAttribute("width", "auto");
    newIframe.setAttribute("height", "auto");
    newIframe.setAttribute("allowFullScreen", "");
    newIframe.setAttribute("frameBorder", 0);
    if (url) {
      newIframe.setAttribute("src", url);
    }
    // replace oldIframe with newIframe
    oldIframe[i].parentNode.replaceChild(newIframe, oldIframe[i]);
  }

  const contentToRender = parentEmbed.outerHTML?.replace(
    /<img /g,
    `<img width="${width * 0.5}" height="auto" `
  );
  return (
    <View style={{ minHeight: 1065, paddingHorizontal: 50 }}>
      <Text style={styles.textTitle}>{Work?.work?.name}</Text>
      <View style={{ flexDirection: "row" }}>
        {Work?.student?.user_accounts?.firstName != null ? (
          <Text style={styles.textDetail}>
            ผู้ส่ง :{" "}
            {Work.student.user_accounts.firstName +
              " " +
              Work.student.user_accounts.lastName}
          </Text>
        ) : (
          <Text style={styles.textDetail}>ผู้ส่ง : </Text>
        )}
        <Text style={[styles.textDetail, { marginLeft: 40 }]}>
          วันที่ส่ง : {moment(Work.createdAt).format("DD-MM-YYYY")}
        </Text>
      </View>
      <Text style={styles.textDetail}>คอร์ส : {Work.course.title}</Text>
      <View style={styles.viewDetail}>
        <Text style={styles.textDetail}>
          <HTMLRenderer
            html={contentToRender}
            components={
              {
                // h1: props => <Heading color="red" {...props} />,
                // h2: Subheading,
                // a: Link,
              }
            }
          />
        </Text>
      </View>
      {Work.documets != "" && (
        <TouchableOpacity
          onPress={async () => {
            openInNewTab(
              "https://api.courseoncloud.com/api/image/pdf/" +
                Work.documets.replaceAll(".pdf", "")
            );
          }}
          style={styles.buttonDownload}
        >
          <MaterialIcons name="file-download" size={24} color="#FFFFFF" />
          <Text style={styles.text}>ดาวน์โหลดเอกสาร</Text>
        </TouchableOpacity>
      )}
      <View
        style={[styles.viewComment, { marginTop: Work.documets == "" && 20 }]}
      >
        <Text style={styles.text}>ความคิดเห็นของผู้สอน</Text>
      </View>
      <TextInput
        autoComplete="off"
        defaultValue={comment}
        onChangeText={(text) => {
          setcomment(text);
        }}
        multiline
        style={styles.inputComment}
      />
      <View style={styles.viewButton}>
        <TouchableOpacity
          onPress={async () => {
            const res = await apiservice({
              path: "/start-course/updatework/" + Work.id,
              method: "put",
              body: {
                comment: comment,
                approve: "approve",
                status: true,
              },
              token: token?.accessToken,
            });
            if (res.status == 200) {
              onPress();
            }
          }}
          style={styles.buttonPass}
        >
          <Text style={styles.textButton}>ผ่าน</Text>
        </TouchableOpacity>
        <TouchableOpacity
          onPress={async () => {
            const res = await apiservice({
              path: "/start-course/updatework/" + Work.id,
              method: "put",
              body: {
                comment: comment,
                approve: "notapprove",
                status: true,
              },
              token: token?.accessToken,
            });
            if (res.status == 200) {
              onPress();
            }
          }}
          style={styles.buttonUnPass}
        >
          <Text style={styles.textButton}>ไม่ผ่าน</Text>
        </TouchableOpacity>
      </View>
    </View>
  );
}
const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: "100%",
    backgroundColor: "#FFFFFF",
  },
  textButton: {
    fontSize: 25,
    fontFamily: "Prompt",
    color: "#FFFFFF",
  },
  textTitle: {
    fontSize: 30,
    fontFamily: "Prompt",
    color: "#0B2051",
    marginTop: 45,
  },
  textDetail: {
    fontSize: 20,
    fontFamily: "Prompt",
    color: "#0B2051",
    marginTop: 10,
  },
  text: {
    fontSize: 20,
    fontFamily: "Prompt",
    color: "#FFFFFF",
  },
  viewDetail: {
    width: "100%",
    minHeight: 339,
    borderWidth: 1,
    borderColor: "#0D88F0",
    marginTop: 20,
    padding: 20,
  },
  buttonDownload: {
    width: "100%",
    maxWidth: 214,
    height: 54,
    backgroundColor: "#0D88F0",
    borderRadius: 5,
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    marginVertical: 30,
  },
  viewComment: {
    width: "100%",
    height: 50,
    backgroundColor: "#0D88F0",
    justifyContent: "center",
    paddingHorizontal: 20,
  },
  inputComment: {
    width: "100%",
    minHeight: 200,
    borderWidth: 1,
    borderColor: "#0D88F0",
    textAlignVertical: "top",
    padding: 15,
    fontFamily: "Prompt",
    fontSize: 20,
    color: "#0B2051",
  },
  viewButton: {
    flexDirection: "row",
    marginVertical: 45,
    alignSelf: "center",
  },
  buttonPass: {
    width: 155,
    height: 65,
    backgroundColor: "#74C745",
    borderRadius: 5,
    justifyContent: "center",
    alignItems: "center",
  },
  buttonUnPass: {
    width: 155,
    height: 65,
    backgroundColor: "#E82626",
    borderRadius: 5,
    justifyContent: "center",
    alignItems: "center",
    marginLeft: 40,
  },
});
