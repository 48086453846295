import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  Image,
  Dimensions,
  Animated,
  TouchableOpacity,
  StyleSheet,
  ScrollView,
  TextInput,
  FlatList,
} from "react-native";
import {
  MaterialCommunityIcons,
  MaterialIcons,
  Feather,
  Ionicons,
  FontAwesome,
} from "@expo/vector-icons";
import HeaderAdmin from "../../components/headerAdmin";
import ButtonMenuSuperAdmin from "../../components/buttonMenuSuperAdmin";
import ProfileAdmin from "../../components/profileAdmin";
import Modal from "modal-react-native-web";
import { useRecoilState } from "recoil";
import { tokenState } from "../../../reducer/recoil";
import { apiservice } from "../../../service";
import moment from "moment";
import UniversityDetail from "./universityDetail";
import { useWindowSize } from "../../components/responsive";
const { width, height } = Dimensions.get("window");
export default function main({ navigation, route }) {
  const [modalDelItem, setModalDelItem] = useState(false);
  const [token, setToken] = useRecoilState(tokenState);
  const [university, setUniversity] = useState([]);
  const [universityDetail, setUniversityDetail] = useState(false);
  const [university_id, setUniversity_id] = useState();
  const [university_name, setUniversity_name] = useState();
  const [openMenu, setOpenMenu] = useState(false);
  const size = useWindowSize();
  useEffect(() => {
    if (token) {
      getUniversity(token);
    }
  }, [token]);

  async function getUniversity(token) {
    try {
      const response = await apiservice({
        path: "/course/getuniversity?page=1&totalpage=100",
        method: "get",
        token: token?.accessToken,
      });

      if (response.status == 200) {
        let pagination = [];
        for (let index = 0; index < response.data.total_page; index++) {
          pagination = pagination.concat({});
        }
        setUniversity(response.data);
      }
    } catch (error) {
      throw error;
    }
  }
  return (
    <View style={styles.container}>
      <HeaderAdmin
        onPress={() => {
          setOpenMenu((val) => !val);
        }}
        navigation={navigation}
      />
      <View style={{ width: "100%", flexDirection: "row", height: "100%" }}>
        {openMenu && (
          <View //menu
            style={{
              width: "13%",

              minWidth: 220,
              height: "100%",
              backgroundColor: "#444444",
              position: "absolute",
              zIndex: 99,
            }}
          >
            <View style={{ height: "80%" }}>
              {(token?.role == "SUPERADMIN" ||
                token?.role == "GENERALADMIN") && (
                <ButtonMenuSuperAdmin
                  p1
                  onPress={() => {
                    setOpenMenu(false);
                    navigation.navigate("UniversityCourseSA");
                  }}
                />
              )}
              {(token?.role == "SUPERADMIN" ||
                token?.role == "GENERALADMIN") && (
                <ButtonMenuSuperAdmin
                  p2
                  onPress={() => {
                    setOpenMenu(false);
                    navigation.navigate("GeneralCourseSA");
                  }}
                />
              )}
              {(token?.role == "SUPERADMIN" ||
                token?.role == "GENERALADMIN") && (
                <ButtonMenuSuperAdmin
                  p3
                  color
                  border
                  onPress={() => {
                    setOpenMenu(false);
                    navigation.navigate("AddAdminSA");
                  }}
                />
              )}
              {(token?.role == "ACCOUNTING" || token?.role == "SUPERADMIN") && (
                <ButtonMenuSuperAdmin
                  p4
                  onPress={() => {
                    setOpenMenu(false);
                    navigation.navigate("ReportSA");
                  }}
                />
              )}
              {(token?.role == "SUPERADMIN" ||
                token?.role == "GENERALADMIN") && (
                <ButtonMenuSuperAdmin
                  p5
                  onPress={() => {
                    setOpenMenu(false);
                    navigation.navigate("SlideBannerSA");
                  }}
                />
              )}
              {(token?.role == "SUPERADMIN" ||
                token?.role == "GENERALADMIN") && (
                <ButtonMenuSuperAdmin
                  p6
                  onPress={() => {
                    setOpenMenu(false);
                    navigation.navigate("Discount");
                  }}
                />
              )}
              {(token?.role == "SUPERADMIN" ||
                token?.role == "GENERALADMIN") && (
                <ButtonMenuSuperAdmin
                  p7
                  onPress={() => {
                    setOpenMenu(false);
                    navigation.navigate("Account");
                  }}
                />
              )}
            </View>
            <ProfileAdmin />
          </View>
        )}
        {size.width >= 1280 && (
          <View //menu
            style={{
              width: "13%",

              minWidth: 220,
              height: "100%",
              backgroundColor: "#444444",
            }}
          >
            <View style={{ height: "80%" }}>
              {(token?.role == "SUPERADMIN" ||
                token?.role == "GENERALADMIN") && (
                <ButtonMenuSuperAdmin
                  p1
                  onPress={() => {
                    navigation.navigate("UniversityCourseSA");
                  }}
                />
              )}
              {(token?.role == "SUPERADMIN" ||
                token?.role == "GENERALADMIN") && (
                <ButtonMenuSuperAdmin
                  p2
                  onPress={() => {
                    navigation.navigate("GeneralCourseSA");
                  }}
                />
              )}
              {(token?.role == "SUPERADMIN" ||
                token?.role == "GENERALADMIN") && (
                <ButtonMenuSuperAdmin
                  p3
                  color
                  border
                  onPress={() => {
                    navigation.navigate("AddAdminSA");
                  }}
                />
              )}
              {(token?.role == "ACCOUNTING" || token?.role == "SUPERADMIN") && (
                <ButtonMenuSuperAdmin
                  p4
                  onPress={() => {
                    navigation.navigate("ReportSA");
                  }}
                />
              )}
              {(token?.role == "SUPERADMIN" ||
                token?.role == "GENERALADMIN") && (
                <ButtonMenuSuperAdmin
                  p5
                  onPress={() => {
                    navigation.navigate("SlideBannerSA");
                  }}
                />
              )}
              {(token?.role == "SUPERADMIN" ||
                token?.role == "GENERALADMIN") && (
                <ButtonMenuSuperAdmin
                  p6
                  onPress={() => {
                    navigation.navigate("Discount");
                  }}
                />
              )}
              {(token?.role == "SUPERADMIN" ||
                token?.role == "GENERALADMIN") && (
                <ButtonMenuSuperAdmin
                  p7
                  onPress={() => {
                    navigation.navigate("Account");
                  }}
                />
              )}
            </View>
            <ProfileAdmin />
          </View>
        )}
        <ScrollView>
          <View
            style={[
              styles.viewDetail,
              { paddingHorizontal: size.width < 500 ? 20 : 55 },
            ]}
          >
            {!universityDetail ? (
              <View>
                <View style={{ flexDirection: "row" }}>
                  <TouchableOpacity>
                    <Text
                      style={{
                        fontSize: 23,
                        fontFamily: "Prompt",
                        color: "#0D88F0",
                        marginTop: 10,
                      }}
                    >
                      มหาวิทยาลัย
                    </Text>
                  </TouchableOpacity>
                  <TouchableOpacity
                    onPress={() => {
                      setUniversity_id(null);
                      setUniversity_name(null);
                      setUniversityDetail(true);
                    }}
                    style={{ marginLeft: 25 }}
                  >
                    <Text
                      style={{
                        fontSize: 23,
                        fontFamily: "Prompt",
                        color: "#808D93",
                        marginTop: 10,
                      }}
                    >
                      Super Admin
                    </Text>
                  </TouchableOpacity>
                </View>
                <View style={styles.viewSubject}>
                  <Text
                    style={[styles.text16B, { width: "80%", paddingLeft: 67 }]}
                  >
                    ชื่อมหาวิทยาลัย
                  </Text>
                  <Text style={[styles.text16B, { width: "15%" }]}>แอดมิน</Text>

                  <Text style={[styles.text16B, { width: "5%" }]}></Text>
                </View>
                <FlatList
                  numColumns={1}
                  data={university.result}
                  renderItem={({ item, index }) => {
                    return (
                      <TouchableOpacity
                        onPress={() => {
                          setUniversity_id(item.id);
                          setUniversity_name(item.university_name_th);
                          setUniversityDetail(true);
                        }}
                        style={{
                          flexDirection: "row",
                          justifyContent: "space-between",
                          borderBottomWidth: 1,
                          borderBottomColor: "#E5E5E5",
                          height: 50,
                          alignItems: "center",
                        }}
                      >
                        <Text
                          style={[
                            styles.text16B,
                            { width: "80%", paddingLeft: 67 },
                          ]}
                        >
                          {item.university_name_th}
                        </Text>
                        <Text style={[styles.text16B, { width: "15%" }]}>
                          {item.admin_totals}
                        </Text>
                        <TouchableOpacity
                          onPress={() => {
                            setModalDelItem(true);
                          }}
                          style={{ width: "5%", alignItems: "center" }}
                        >
                          <MaterialIcons
                            name="delete"
                            size={24}
                            color="black"
                          />
                        </TouchableOpacity>
                      </TouchableOpacity>
                    );
                  }}
                />
                <Modal
                  transparent={true}
                  visible={modalDelItem}
                  onRequestClose={() => {
                    Alert.alert("Modal has been closed.");
                    setModalDelItem(!modalDelItem);
                  }}
                >
                  <View style={styles.bgModal}>
                    <View
                      style={[
                        styles.viewDetailModal,
                        {
                          width:
                            size.width < 500
                              ? "80%"
                              : size.width < 1280
                              ? "70%"
                              : "30%",
                          height: 183,
                          paddingVertical: 25,
                        },
                      ]}
                    >
                      <Text
                        style={[
                          styles.textButtonModal,
                          { color: "#444444", alignSelf: "center" },
                        ]}
                      >
                        คุณต้องการจะลบใช่รึไม่
                      </Text>
                      <View
                        style={{
                          flexDirection: "row",
                          alignSelf: "center",
                          marginTop: 39,
                        }}
                      >
                        <TouchableOpacity
                          onPress={() => setModalDelItem(!modalDelItem)}
                          style={styles.buttonModal}
                        >
                          <Text
                            style={[
                              styles.textButtonModal,
                              {
                                color: "#444444",
                              },
                            ]}
                          >
                            ยกเลิก
                          </Text>
                        </TouchableOpacity>
                        <TouchableOpacity
                          onPress={() => setModalDelItem(!modalDelItem)}
                          style={[
                            styles.buttonModal,
                            {
                              backgroundColor: "#0D88F0",
                              marginLeft: width * 0.05,
                            },
                          ]}
                        >
                          <Text
                            style={[
                              styles.textButtonModal,
                              {
                                color: "#FFFFFF",
                              },
                            ]}
                          >
                            ยืนยัน
                          </Text>
                        </TouchableOpacity>
                      </View>
                    </View>
                  </View>
                </Modal>
              </View>
            ) : (
              <UniversityDetail
                onPress={() => {
                  setUniversity_id(null);
                  setUniversity_name(null);
                  setUniversityDetail(false);
                }}
                university_id={university_id}
                university_name={university_name}
              />
            )}
          </View>
        </ScrollView>
      </View>
    </View>
  );
}
const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: "100%",
    backgroundColor: "#FFFFFF",
  },
  viewDetail: {
    width: "100%",
    height: "100%",
    paddingHorizontal: 55,
    paddingVertical: 28,
    paddingBottom: height * 0.1,
  },
  viewSubject: {
    width: "100%",
    height: 51,
    backgroundColor: "#E5E5E5",
    marginTop: 35,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  bgModal: {
    width: "100%",
    height: "100%",
    backgroundColor: "#000000bb",
    justifyContent: "center",
  },
  viewDetailModal: {
    width: "50%",
    height: 380,
    backgroundColor: "#FFFFFF",
    borderRadius: 20,
    alignSelf: "center",
    paddingHorizontal: width * 0.05,
    paddingVertical: 50,
  },
  buttonModal: {
    width: 102,
    height: 48,
    borderRadius: 4,
    backgroundColor: "#EFEFF3",
    justifyContent: "center",
    alignItems: "center",
  },
  textButtonModal: {
    fontSize: 25,
    fontFamily: "Prompt",
  },
  text16B: {
    fontFamily: "Prompt",
    fontSize: 16,
    color: "#000000",
  },
});
