import React, { useEffect, useState } from "react";
import {
  View,
  Text,
  Dimensions,
  TouchableOpacity,
  StyleSheet,
  TextInput,
  FlatList,
  ScrollView,
} from "react-native";
import {
  MaterialCommunityIcons,
  MaterialIcons,
  FontAwesome,
} from "@expo/vector-icons";

import Modal from "modal-react-native-web";
import { useRecoilValue } from "recoil";
import { tokenState } from "../../../reducer/recoil";
import { apiservice } from "../../../service";
import moment from "moment";
import {
  authActionPutUserByAdmin,
  deleteTeacher,
} from "../../../action/authAction";
import { useWindowSize } from "../../components/responsive";

const { width, height } = Dimensions.get("window");
export default function teacherGeneral({ onPress, setDetailTeacher }) {
  const [modalDelItem, setModalDelItem] = useState(false);
  const [search, setSearch] = useState("");
  const [modalAddItem, setModalAddItem] = useState(false);
  const size = useWindowSize();
  const [body, setBody] = useState({
    firstName: "",
    lastName: "",
  });
  const [teacher, setTeacher] = useState(false);
  // useEffect(() => {
  //   getTeacher();
  // }, []);

  const [DATA, setData] = useState([]);
  const token = useRecoilValue(tokenState);
  const [id, setId] = useState();
  const [idCourse, setIdCourse] = useState();
  useEffect(() => {
    apiCall();
  }, [token]);

  async function apiCall() {
    const res = await apiservice({
      path: "/authen/getgeneralteacher?page=1&totalpage=100",
      method: "get",
      token: token?.accessToken,
    });

    if (res?.status == 200) {
      console.log(res.data);
      setData(res?.data?.result);
    }
  }
  async function Delete() {
    const Delete = await deleteTeacher({
      token: token?.accessToken,
      id: id,
    });
    if (Delete) {
      setModalDelItem(!modalDelItem);
      apiCall();
    }
  }
  async function editname() {
    const edit = await authActionPutUserByAdmin({
      state: body,
      token: token?.accessToken,
      id: idCourse,
    });
    if (edit) {
      setBody({
        firstName: "",
        lastName: "",
      });
      setModalAddItem(!modalAddItem);
      apiCall();
    }
  }
  return (
    <View>
      <View
        style={{ flexDirection: "row", marginTop: 20, alignItems: "center" }}
      >
        <TextInput
          onChangeText={(text) => {
            setSearch(text);
          }}
          autoComplete="off"
          placeholder="ค้นหาชื่อผู้สอน"
          placeholderTextColor="#8C8896"
          style={{
            width: size.width < 500 ? "80%" : "30%",
            height: 48,
            borderRadius: 4,
            backgroundColor: "#EFEFF3",
            fontSize: 16,
            fontFamily: "Prompt",
            color: "#8C8896",
            paddingHorizontal: 25,
          }}
        />
        <TouchableOpacity style={{ marginLeft: -40 }}>
          <MaterialCommunityIcons name="magnify" size={24} color="#0D88F0" />
        </TouchableOpacity>
      </View>
      <ScrollView horizontal>
        <View
          style={{
            width:
              size.width < 500
                ? size.width * 2.5
                : size.width < 1280
                ? size.width * 1.0
                : size.width * 0.75,
            // minHeight: height * 2.5,
          }}
        >
          <View style={styles.viewSubject}>
            <Text style={[styles.text16B, { paddingLeft: 67, width: "35%" }]}>
              ชื่อผู้สอน
            </Text>
            <Text style={[styles.text16B, { width: "20%" }]}>email</Text>
            <Text style={[styles.text16B, { width: "20%" }]}>วันที่สร้าง</Text>
            <Text style={[styles.text16B, { width: "15%" }]}>
              จำนวนคอร์สที่สร้าง
            </Text>
            <Text style={[styles.text16B, { width: "5%" }]}></Text>
            <Text style={[styles.text16B, { width: "5%" }]}></Text>
          </View>
          <FlatList
            numColumns={1}
            data={DATA.filter((item) => {
              return item.user_accounts.firstName.includes(search);
            })}
            renderItem={({ item, index }) => {
              return (
                <TouchableOpacity
                  onPress={() => {
                    setDetailTeacher(item);
                    onPress();
                  }}
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    borderBottomWidth: 1,
                    borderBottomColor: "#E5E5E5",
                    height: 50,
                    alignItems: "center",
                  }}
                >
                  <Text
                    style={[styles.text16B, { paddingLeft: 67, width: "35%" }]}
                  >
                    {item?.user_accounts?.firstName +
                      " " +
                      item?.user_accounts?.lastName}
                  </Text>
                  <Text style={[styles.text16B, { width: "20%" }]}>
                    {item?.email}
                  </Text>
                  <Text style={[styles.text16B, { width: "20%" }]}>
                    {moment(item?.user_accounts?.createdAt)
                      .locale("th")
                      .add(543, "years")
                      .format("DD MMMM พ.ศ. YYYY")}
                  </Text>
                  <Text style={[styles.text16B, { width: "15%" }]}>
                    {item?.allResult?.length}
                  </Text>
                  <TouchableOpacity
                    onPress={() => {
                      setIdCourse(item.id);
                      setBody({
                        ...body,
                        firstName: item.user_accounts.firstName,
                        lastName: item.user_accounts.lastName,
                      });

                      setModalAddItem(true);
                    }}
                    style={{ width: "5%", alignItems: "flex-end" }}
                  >
                    <FontAwesome name="edit" size={24} color="black" />
                  </TouchableOpacity>
                  <TouchableOpacity
                    onPress={() => {
                      setId(item.id);
                      setModalDelItem(true);
                    }}
                    style={{ width: "5%", alignItems: "center" }}
                  >
                    <MaterialIcons name="delete" size={24} color="black" />
                  </TouchableOpacity>
                </TouchableOpacity>
              );
            }}
          />
        </View>
      </ScrollView>
      <Modal
        transparent={true}
        visible={modalDelItem}
        onRequestClose={() => {
          Alert.alert("Modal has been closed.");
          setModalDelItem(!modalDelItem);
        }}
      >
        <View style={styles.bgModal}>
          <View
            style={[
              styles.viewDetailModal,
              {
                width:
                  size.width < 500 ? "80%" : size.width < 1280 ? "70%" : "30%",
                height: 200,
                paddingVertical: 25,
              },
            ]}
          >
            <Text
              style={[
                styles.textButtonModal,
                { color: "#444444", alignSelf: "center" },
              ]}
            >
              คุณต้องการจะลบใช่รึไม่
            </Text>
            <View
              style={{
                flexDirection: "row",
                alignSelf: "center",
                marginTop: 39,
              }}
            >
              <TouchableOpacity
                onPress={() => setModalDelItem(!modalDelItem)}
                style={styles.buttonModal}
              >
                <Text
                  style={[
                    styles.textButtonModal,
                    {
                      color: "#444444",
                    },
                  ]}
                >
                  ยกเลิก
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={Delete}
                style={[
                  styles.buttonModal,
                  {
                    backgroundColor: "#0D88F0",
                    marginLeft: width * 0.05,
                  },
                ]}
              >
                <Text
                  style={[
                    styles.textButtonModal,
                    {
                      color: "#FFFFFF",
                    },
                  ]}
                >
                  ยืนยัน
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </Modal>
      <Modal
        transparent={true}
        visible={modalAddItem}
        onRequestClose={() => {
          Alert.alert("Modal has been closed.");
          setModalAddItem(!modalAddItem);
        }}
      >
        <View style={styles.bgModal}>
          <View
            style={[
              styles.viewDetailModal1,
              {
                width:
                  size.width < 500
                    ? "80%"
                    : size.width < 1280
                    ? "70%"
                    : width < 1600
                    ? "65%"
                    : "50%",
              },
            ]}
          >
            <TextInput
              autoComplete="off"
              value={body.firstName}
              onChangeText={(text) => {
                setBody({ ...body, firstName: text });
              }}
              placeholder={"กรอกชื่อ"}
              placeholderTextColor={"#444444"}
              style={[
                styles.inputModal,
                { fontSize: size.width < 500 ? 18 : 35 },
              ]}
            />

            <TextInput
              value={body.lastName}
              onChangeText={(text) => {
                setBody({ ...body, lastName: text });
              }}
              placeholder={"กรอกนามสกุล"}
              placeholderTextColor={"#444444"}
              style={[
                styles.inputModal,
                { marginTop: 30, fontSize: size.width < 500 ? 18 : 35 },
              ]}
            />

            <View
              style={{
                flexDirection: "row",
                alignSelf: "center",
                marginTop: 49,
              }}
            >
              <TouchableOpacity
                onPress={() => {
                  setModalAddItem(!modalAddItem);
                }}
                style={styles.buttonModal}
              >
                <Text
                  style={[
                    styles.textButtonModal,
                    {
                      color: "#444444",
                    },
                  ]}
                >
                  ยกเลิก
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={editname}
                style={[
                  styles.buttonModal,
                  {
                    backgroundColor: "#0D88F0",
                    marginLeft: width * 0.05,
                  },
                ]}
              >
                <Text
                  style={[
                    styles.textButtonModal,
                    {
                      color: "#FFFFFF",
                    },
                  ]}
                >
                  ยืนยัน
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </Modal>
    </View>
  );
}
const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: "100%",
    backgroundColor: "#FFFFFF",
  },
  text23O: { fontSize: 23, fontFamily: "Prompt", color: "#0D88F0" },
  text16B: {
    fontFamily: "Prompt",
    fontSize: 16,
    color: "#000000",
    width: "23%",
  },

  viewSubject: {
    width: "100%",
    height: 51,
    backgroundColor: "#E5E5E5",
    marginTop: 50,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  bgModal: {
    width: "100%",
    height: "100%",
    backgroundColor: "#000000bb",
    justifyContent: "center",
  },
  viewDetailModal: {
    width: "50%",
    height: 380,
    backgroundColor: "#FFFFFF",
    borderRadius: 20,
    alignSelf: "center",
    paddingHorizontal: width * 0.05,
    paddingVertical: 50,
  },
  buttonModal: {
    width: 102,
    height: 48,
    borderRadius: 4,
    backgroundColor: "#EFEFF3",
    justifyContent: "center",
    alignItems: "center",
  },
  textButtonModal: {
    fontSize: 25,
    fontFamily: "Prompt",
  },
  inputModal: {
    width: "100%",
    height: 67,
    borderRadius: 9,
    borderWidth: 1,
    borderColor: "#707070",
    fontSize: 35,
    fontFamily: "Prompt",
    color: "#444444",
    textAlign: "center",
  },
  viewDetailModal1: {
    width: "60%",

    backgroundColor: "#FFFFFF",
    borderRadius: 20,
    alignSelf: "center",
    paddingHorizontal: width * 0.05,
    paddingVertical: 50,
  },
});
