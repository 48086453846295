import React, { useState, useRef, useEffect } from "react";
import {
  View,
  Text,
  Dimensions,
  TouchableOpacity,
  StyleSheet,
  ScrollView,
  FlatList,
  Image,
} from "react-native";
import Modal from "modal-react-native-web";
import { MaterialCommunityIcons, MaterialIcons } from "@expo/vector-icons";
import Header from "../components/header";
import { Video } from "expo-av";
import { useIsFocused } from "@react-navigation/native";
import { apiservice } from "../../service";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  courseState,
  lessonState,
  lessonVideoState,
  subjecttestState,
  testState,
  tokenState,
  userState,
} from "../../reducer/recoil";
import { useWindowSize } from "../components/responsive";
import ReactPlayer from "react-player/youtube";
import HTMLRenderer from "react-html-renderer";
const { width, height } = Dimensions.get("window");
export default function lessonVideo({ navigation, route }) {
  const [lessonVideoStates, setLessonVideoStates] =
    useRecoilState(lessonVideoState);
  const courseStates = useRecoilValue(courseState);
  const [courseStatess, setCourseStates] = useRecoilState(courseState);
  const [lessonStates, setLessonStates] = useRecoilState(lessonState);
  const [testStates, setTestStates] = useRecoilState(testState);
  const [subjectTestStates, setSubjectTestStates] =
    useRecoilState(subjecttestState);
  const video = React.useRef(null);
  const [prev, setprev] = React.useState(true);
  const size = useWindowSize();
  const [show, setShow] = useState([]);
  const [show1, setShow1] = useState([]);
  const [modalScore, setModalScore] = useState(false);
  const [positionMillis, setpositionMillis] = useState(0);
  const [unLock, setUnLock] = useState(false);
  const [defaultsStatus, setStatus] = useState(lessonVideoStates.status);

  const DATA = lessonVideoStates.document;
  const [finish, setFinish] = useState(false);
  const [play, setPlay] = useState(false);
  const token = useRecoilValue(tokenState);
  const [Oncecourse, myOncecourse] = useState(null);
  const fucus = useIsFocused();
  const [sumScore, setSumScore] = useState();

  useEffect(() => {
    if (lessonVideoStates.detail != undefined) {
      setStatus(lessonVideoStates.status);
      setembed(lessonVideoStates.detail.detail);
    }
    if (lessonVideoStates.detail != undefined) {
      setImg(lessonVideoStates.detail.image);
    }
  }, [lessonVideoStates]);

  async function Chanfd(params, Mainindex, i) {
    if (params.type == "EXAM") {
      const res = await apiservice({
        path: "/quiz/get/" + params.education_id,
        method: "get",
        token: token?.accessToken,
      });

      setTestStates({
        ...params,
        ...res.data,
        ...Oncecourse,
        Mainindex,
        index: i,
        number: 0,
        dataStudymodel: Oncecourse.progress,
        course_id: Oncecourse.course_id,
      });
      setTimeout(() => {
        navigation.navigate("Test");
      }, 1000);
    } else if (params.type == "DESCRIPTION") {
      const res = await apiservice({
        path: "/course/geteducation/" + params.education_id,
        method: "get",
        token: token?.accessToken,
      });
      if (res.status == 200) {
        if (res.data.TYPE == "VDO") {
          setLessonVideoStates({
            ...params,
            ...res.data,
            ...Oncecourse,
            dataStudymodel: lessonVideoStates.dataStudymodel,
            number: 0,
            Mainindex,
            index: i,
            course_id: Oncecourse.course_id,
          });
          setTimeout(() => {
            navigation.navigate("LessonVideo");
          }, 1000);
        } else {
          setLessonStates({
            ...params,
            ...res.data,
            ...Oncecourse,
            dataStudymodel: lessonVideoStates.dataStudymodel,
            number: 0,
            Mainindex,

            index: i,
            course_id: Oncecourse.course_id,
          });
          setTimeout(() => {
            navigation.navigate("Lesson");
          }, 1000);
        }
      }
    } else if (params.type == "WORK") {
      const res = await apiservice({
        path: "/course/getwork/" + params.education_id,
        method: "get",
        token: token?.accessToken,
      });

      setSubjectTestStates({
        ...res.data,
        ...params,
        ...Oncecourse,
        dataStudymodel: lessonVideoStates.dataStudymodel,
        number: 0,
        Mainindex,
        index: i,
        course_id: Oncecourse.course_id,
      });
      setTimeout(() => {
        navigation.push("SubjectiveTest");
      }, 1000);
    } else {
      const res = await apiservice({
        path: "/quiz/get/" + params.education_id,
        method: "get",
        token: token?.accessToken,
      });

      setSubjectTestStates({
        ...res.data,
        ...params,
        ...Oncecourse,
        dataStudymodel: lessonVideoStates.dataStudymodel,

        number: 0,
        Mainindex,
        index: i,
        course_id: Oncecourse.course_id,
      });
      setTimeout(() => {
        navigation.push("SubjectiveTest");
      }, 1000);
    }
  }

  useEffect(() => {
    checkStart();
  }, [lessonVideoStates]);

  useEffect(() => {
    if (Oncecourse) {
      sum();
    }
  }, [Oncecourse]);

  useEffect(() => {
    if (
      defaultsStatus &&
      lessonVideoStates.dataStudymodel[lessonVideoStates.Mainindex]
        .listen_detail[lessonVideoStates.index + 1] == undefined
    ) {
      name1();
    }
  }, []);

  async function sum() {
    const users = await apiservice({
      path: "/authen/user",
      method: "get",
      token: token?.accessToken,
    });
    if (users.status == 200) {
      const res = await apiservice({
        path:
          "/start-course/myscore?course_id=" +
          lessonVideoStates.course_id +
          "&uid=" +
          users.data.result.id,
        method: "get",
      });

      if (res.status == 200) {
        setSumScore(res.data.results);

        conditionToo(res.data.results?.EXAM);
      }
    }
  }
  async function name1() {
    const respp = await apiservice({
      path: "/start-course/getalltotals/" + lessonVideoStates.course_id,
      method: "get",
      token: token?.accessToken,
    });
    const resppt = await apiservice({
      path: "/start-course/getworktotals/" + lessonVideoStates.course_id,
      method: "get",
      token: token?.accessToken,
    });
    if (respp.status == 200) {
      setShow1(resppt.data.results);
      setShow(respp.data.results);
      setModalScore(true);
    }
  }

  useEffect(() => {
    if (fucus == false) {
      // video.current.pauseAsync();
      setprev(false);
    }
  }, [fucus]);

  useEffect(() => {
    if (window.performance) {
      if (performance.navigation.type == 1) {
      } else {
      }
    }

    window.onbeforeunload = function () {
      video.current.pauseAsync();
      setPlay(true);
      return true;
    };

    return () => {
      window.onbeforeunload = null;
    };
  }, []);

  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  async function checkStart() {
    const response = await apiservice({
      path:
        "/start-course/myOncecourse?course_id=" + lessonVideoStates.course_id,
      method: "get",
      token: token?.accessToken,
    });

    if (response.status == 200) {
      myOncecourse(response.data);
      setStatus(
        response.data.progress[lessonVideoStates.Mainindex].listen_detail[
          lessonVideoStates.index
        ].status
      );
    }
  }

  // useEffect(() => {
  //   conditionToo(too);
  // }, [too]);

  async function conditionToo(params) {
    try {
      if (
        defaultsStatus &&
        lessonVideoStates.dataStudymodel[lessonVideoStates.Mainindex]
          .listen_detail[lessonVideoStates.index + 1] == undefined &&
        defaultsStatus &&
        lessonVideoStates.dataStudymodel[lessonVideoStates.Mainindex + 1] ==
          undefined
      ) {
        if (Oncecourse?.Type == "PENDING") {
          const reespon = await apiservice({
            path: "/start-course/update/" + Oncecourse.id,
            method: "put",
            body: {
              Type: params >= courseStates?.percent ? "APPROVE" : "REJECT",
            },
            token: token?.accessToken,
          });
          if (reespon.status == 200) {
            checkStart();
          }
        }
      }
    } catch (error) {}
  }

  async function checkStartFinish() {
    const response = await apiservice({
      path:
        "/start-course/myOncecourse?course_id=" + lessonVideoStates.course_id,
      method: "get",
      token: token?.accessToken,
    });

    if (response.status == 200) {
      try {
        lessonVideoStates.progress.map((items) => {
          items.listen_detail.map((val) => {
            if (val.education_id == lessonVideoStates?.education_id) {
              setStatus(true);
            }
          });
        });
      } catch (error) {}

      myOncecourse(response.data);
    }
  }
  const [embed, setembed] = useState(
    lessonVideoStates.detail != undefined && lessonVideoStates.detail.detail
  );
  const [img, setImg] = useState(
    lessonVideoStates.detail != undefined && lessonVideoStates.detail.image
  );
  const stringToHTML = function (str) {
    const domContainer = document.createElement("span");
    domContainer.innerHTML = str;
    return domContainer;
  };

  const parentEmbed = stringToHTML(embed);

  let oldIframe = parentEmbed.querySelectorAll("oembed");
  oldIframe = Array.from(oldIframe);

  for (const i in oldIframe) {
    //Get the url from oembed tag
    let url = oldIframe[i].getAttribute("url");
    //Replace 'watch?v' with 'embed/'
    url = url.replace("watch?v=", "embed/");

    //Create a iframe tag
    const newIframe = document.createElement("iframe");
    newIframe.setAttribute("width", "auto");
    newIframe.setAttribute("height", "auto");
    newIframe.setAttribute("allowFullScreen", "");
    newIframe.setAttribute("frameBorder", 0);
    if (url) {
      newIframe.setAttribute("src", url);
    }
    // replace oldIframe with newIframe
    oldIframe[i].parentNode.replaceChild(newIframe, oldIframe[i]);
  }
  const [too, setToo] = useState(null);

  const contentToRender = parentEmbed.outerHTML?.replace(
    /<img /g,
    `<img width="${width * 0.5}" height="auto" `
  );

  useEffect(() => {
    if (show.length > 0) {
      // totalsss();
    }
  }, [show]);

  async function totalsss() {
    let toootals = 0;
    const re = await Promise.all(
      lessonVideoStates.progress.map(async (item, index) => {
        const res = await apiservice({
          path:
            "/quiz/get/" +
            item.listen_detail.filter((items) => items.type == "EXAM")?.[0]
              ?.education_id,
          method: "get",
          token: token?.accessToken,
        });
        const question = res.data.question;
        const answers = item.listen_detail.filter(
          (items) => items.type == "EXAM"
        )[0].answer;
        toootals =
          (answers
            .filter(
              (ele, ind) =>
                ind === answers.findIndex((elem) => elem.number === ele.number)
            )
            .filter((items, mainIndex) => {
              // if(items)

              if (
                question.filter((val, indexs) => indexs == items.number)[0]
                  .type == "1"
              ) {
                return (
                  question.filter((val, indexs) => indexs == items.number)[0]
                    .answer == items.answer
                );
              }
              if (
                question.filter((val, indexs) => indexs == items.number)[0]
                  .type == "2"
              ) {
                return (
                  question
                    .filter((val, indexs) => indexs == items.number)[0]
                    .answer.filter(
                      (e) => items.answer.filter((ev) => e == ev).length > 0
                    ).length ==
                  question.filter((val, indexs) => indexs == items.number)[0]
                    .answer.length
                );
              }
              if (
                question.filter((val, indexs) => indexs == items.number)[0]
                  .type == "3"
              ) {
                return question.filter(
                  (val, indexs) => indexs == items.number
                )[0].answer != null
                  ? question.filter((val, indexs) => indexs == items.number)[0]
                      .answer == items.answer
                  : items.answer == 0
                  ? true
                  : false;
              }
              if (
                question.filter((val, indexs) => indexs == items.number)[0]
                  .type == "4"
              ) {
                return (
                  question
                    .filter((val, indexs) => indexs == items.number)[0]
                    .answer.filter(
                      (e) =>
                        items.answer.filter((ev) => e.answer == ev.answer)
                          .length > 0
                    ).length ==
                  question.filter((val, indexs) => indexs == items.number)[0]
                    .answer.length
                );
              }
              if (
                question.filter((val, indexs) => indexs == items.number)[0]
                  .type == "5"
              ) {
                return (
                  question.filter((val, indexs) => {
                    if (val.type == "5") {
                      return (
                        answers
                          .filter((val, indexss) => indexs == val.number)[0]
                          .answer.filter((e, i) => val.choice[i] == e.ans)
                          .length == val.choice.length
                      );
                    } else {
                      return false;
                    }
                  }).length > 0
                );
              }

              return false;
            }).length /
            answers.length) *
          100;
      })
    );

    setToo(toootals);
  }

  if (
    defaultsStatus &&
    lessonVideoStates.dataStudymodel[lessonVideoStates.Mainindex].listen_detail[
      lessonVideoStates.index
    ] == undefined &&
    lessonVideoStates.dataStudymodel[lessonVideoStates.Mainindex + 1] ==
      undefined
  ) {
    return <View style={{ flex: 1, backgroundColor: "#ff0000" }} />;
  }

  if (
    defaultsStatus &&
    lessonVideoStates.dataStudymodel[lessonVideoStates.Mainindex].listen_detail[
      lessonVideoStates.index
    ] == undefined &&
    too == null &&
    lessonVideoStates.dataStudymodel[lessonVideoStates.Mainindex + 1] ==
      undefined
  ) {
    return <View style={{ flex: 1, backgroundColor: "#f2f2f2" }} />;
  }

  if (lessonVideoStates.detail == undefined) {
    return <View style={{ flex: 1, backgroundColor: "#ff2" }} />;
  }
  // if (Oncecourse == null) {
  //   return null;
  // }
  return (
    <View style={styles.container}>
      <Modal
        transparent={true}
        visible={modalScore}
        onRequestClose={() => {
          Alert.alert("Modal has been closed.");
          setModalScore(!modalScore);
        }}
      >
        <View style={styles.viewModal}>
          <View
            style={[styles.viewDetailModal, { maxWidth: 600, maxHeight: 520 }]}
          >
            <View style={{}}>
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  paddingTop: 20,
                }}
              >
                <Text
                  style={{
                    fontFamily: "Prompt",
                    fontSize: 30,
                    color: "#0B2051",
                  }}
                >
                  ผลของคุณคือ
                </Text>
                <Text
                  style={{
                    fontFamily: "Prompt",
                    fontSize: 80,
                    color: "#0B2051",
                    marginLeft: 43,
                  }}
                >
                  {sumScore?.EXAM}%
                </Text>
              </View>
              <Text
                style={{
                  fontFamily: "Prompt",
                  fontSize: 20,
                  color: "#0B2051",
                  alignSelf: "center",
                  marginBottom: 40,
                }}
              >
                สรุปการเรียนของคุณ
              </Text>
              <View style={{ flexDirection: "row" }}>
                <View style={styles.viewDashedModal}>
                  <MaterialIcons
                    name="library-books"
                    size={40}
                    color="#0B2051"
                  />
                  <Text style={styles.text25}>
                    {sumScore?.DESCRIPTION}/{sumScore?.DESCRIPTION}
                  </Text>
                </View>
                <View
                  style={[
                    styles.viewDashedModal,
                    { justifyContent: "flex-end", borderRightWidth: 0 },
                  ]}
                >
                  <MaterialIcons name="archive" size={40} color="#0B2051" />
                  <Text style={styles.text25}>
                    {sumScore?.WORK}/{sumScore?.WORK}
                  </Text>
                </View>
              </View>
              <View style={{ flexDirection: "row" }}>
                <View style={styles.viewDashedModal1}>
                  <MaterialIcons name="live-help" size={40} color="#0B2051" />
                  <Text style={styles.text25}>
                    {sumScore?.WRITING}/{sumScore?.WRITING}
                  </Text>
                </View>
                <View
                  style={{
                    flexDirection: "row",

                    justifyContent: "flex-end",
                    alignItems: "center",
                    width: "50%",
                  }}
                >
                  <Text
                    style={{
                      fontFamily: "Prompt",
                      fontSize: 25,
                      color: "#0B2051",
                    }}
                  >
                    ผล
                  </Text>
                  {Oncecourse?.Type == "APPROVE" ? (
                    <Text
                      style={{
                        fontFamily: "Prompt",
                        fontSize: 30,
                        color: "#74C745",
                        marginLeft: 25,
                      }}
                    >
                      ผ่าน
                    </Text>
                  ) : Oncecourse?.Type == "PENDING" ? (
                    <Text
                      style={{
                        fontFamily: "Prompt",
                        fontSize: 30,
                        color: "#EBE330",
                        marginLeft: 25,
                      }}
                    >
                      รอผล
                    </Text>
                  ) : (
                    <Text
                      style={{
                        fontFamily: "Prompt",
                        fontSize: 30,
                        color: "red",
                        marginLeft: 25,
                      }}
                    >
                      ไม่ผ่าน
                    </Text>
                  )}
                </View>
              </View>
            </View>
            <View
              style={{
                flexDirection: "row",
                justifyContent: "center",
                marginVertical: 30,
              }}
            >
              {/* {Oncecourse?.Type == "APPROVE" && courseStates.certificate && (
                    <TouchableOpacity
                      onPress={() => {
                        window.open(
                          `https://api.courseoncloud.com/api/image/export/${subjectTestStates.course_id}?uid=${users.uid}`
                        );
                        // https://api.courseoncloud.com/api/image/export/1?uid=32
                      }}
                      style={{
                        width: 141,
                        height: 44,
                        backgroundColor: "#0D88F0",
                        borderRadius: 5,
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 20,
                          fontFamily: "Prompt",
                          color: "#FFFFFF",
                        }}
                      >
                        Certificate
                      </Text>
                    </TouchableOpacity>
                  )} */}
              <TouchableOpacity
                onPress={async () => {
                  setModalScore(!modalScore);
                  setUnLock(false);
                  const response = await apiservice({
                    path:
                      "/course/getall?page=1&lan=TH&course_id=" +
                      lessonVideoStates.course_id,
                    method: "get",
                    //token: token?.accessToken,
                  });

                  setCourseStates(response.data.data4[0]);
                  setTimeout(() => {
                    navigation.navigate("Course");
                  }, 900);
                }}
                style={{
                  width: 141,
                  height: 44,
                  borderWidth: 1,
                  borderColor: "#0D88F0",
                  borderRadius: 5,
                  justifyContent: "center",
                  alignItems: "center",
                  // marginLeft: courseStates.certificate ? 60 : 0,
                }}
              >
                <Text
                  style={{
                    fontSize: 20,
                    fontFamily: "Prompt",
                    color: "#0D88F0",
                  }}
                >
                  ดูคอร์ส
                </Text>
              </TouchableOpacity>
            </View>
            {Oncecourse?.comment != null && (
              <Text
                style={{
                  fontSize: 12,
                  fontFamily: "Prompt",
                  color: "#E82626",
                  alignSelf: "center",
                }}
              >
                {"***หมายเหตุ " + Oncecourse?.comment + " ***"}
              </Text>
            )}
            <Text
              style={{
                fontSize: 12,
                fontFamily: "Prompt",
                color: "#E82626",
                alignSelf: "center",
              }}
            >
              กรุณาติดต่อผู้สอนกรณีต้องการปรึกษาเรื่องผลการเรียน
            </Text>
          </View>
        </View>
      </Modal>
      <ScrollView stickyHeaderIndices={[0]}>
        <Header navigation={navigation} />
        <View
          style={{
            width: size.width < 1280 ? "80%" : "50%",
            alignSelf: "center",
            marginTop: 35,
          }}
        >
          <Text
            style={{ fontFamily: "Prompt", fontSize: 11, color: "#0B2051" }}
          >
            {/* พุทธศาสตรบัณฑิต (การสอนพระพุทธศาสนาและจิตวิทยาแนะแนว) */}
          </Text>
          <Text
            style={{ fontFamily: "Prompt", fontSize: 25, color: "#646464" }}
          >
            {/* ทำความรู้จักงานแนะแนว2 */}
            {lessonVideoStates.name}
          </Text>
          {prev &&
            (lessonVideoStates.detail.image.includes("youtube") ? (
              <View>
                <ReactPlayer
                  url={img}
                  width={"100%"}
                  height={574}
                  controls={defaultsStatus ? true : false}
                  onEnded={() => {
                    setFinish(true);
                  }}
                />
                <View
                  style={{ width: "100%", height: 150, position: "absolute" }}
                />
                <View
                  style={{ width: "100%", height: 150, position: "absolute", bottom: 0 }}
                />
              </View>
            ) : (
              <View style={{ alignItems: "center", justifyContent: "center" }}>
                <Video
                  ref={video}
                  style={styles.video}
                  useNativeControls={defaultsStatus ? true : false}
                  source={{
                    uri: img,
                  }}
                  onLoadStart={() => {
                    video.current.playAsync();
                  }}
                  onFullscreenUpdate
                  resizeMode="contain"
                  onResponderEnd={() => {}}
                  onPlaybackStatusUpdate={(status) => {
                    setpositionMillis(status.positionMillis);

                    setFinish(status.didJustFinish);

                    // setStatus(() => status);
                  }}
                />
                {play && (
                  <TouchableOpacity
                    onPress={() => {
                      setPlay(false);
                      video.current.playAsync();
                    }}
                    style={{
                      width: 100,
                      height: 100,
                      position: "absolute",
                    }}
                  >
                    <Image
                      style={{
                        width: 100,
                        height: 100,
                      }}
                      source={require("../img/play-button.png")}
                    />
                  </TouchableOpacity>
                )}
              </View>
            ))}
          <Text
            style={{
              fontFamily: "Prompt",
              fontSize: size.width < 500 ? 14 : 20,
              color: "#646464",
            }}
          >
            <HTMLRenderer
              html={contentToRender}
              components={
                {
                  // h1: props => <Heading color="red" {...props} />,
                  // h2: Subheading,
                  // a: Link,
                }
              }
            />
          </Text>
          <FlatList
            data={DATA}
            numColumns={1}
            renderItem={({ index, item }) => {
              return (
                <View
                  style={{
                    borderTopWidth: index == 0 && 1,
                    borderBottomWidth: 1,
                    borderBottomColor: "#BBBBBB",
                    borderTopColor: "#BBBBBB",
                    paddingVertical: 15,
                  }}
                >
                  <TouchableOpacity
                    onPress={() => {
                      // Linking.openURL(
                      //   "https://api.courseoncloud.com/api/image/pdf/" +
                      //     item.files.replaceAll(".pdf", "")
                      // );
                      openInNewTab(
                        "https://api.courseoncloud.com/api/image/pdf/" +
                          item.files.replaceAll(".pdf", "")
                      );
                    }}
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Text style={[styles.textDetail, { marginTop: 0 }]}>
                      {item.name}
                    </Text>
                    <MaterialIcons
                      name="cloud-download"
                      size={24}
                      color="#0D88F0"
                    />
                  </TouchableOpacity>
                </View>
              );
            }}
          />
          <TouchableOpacity
            disabled={finish ? false : true}
            onPress={async () => {
              const response = await apiservice({
                path: "/start-course/update/" + lessonVideoStates.id,
                method: "put",
                body: {
                  Type: "PENDING",
                  progress: lessonVideoStates.progress.map((item, i) => {
                    if (i == 0) {
                      return {
                        ...item,
                        listen_detail: item.listen_detail.map((items, ins) => {
                          if (ins == 0) {
                            return { ...items, status: true };
                          } else if (
                            ins !== 0 &&
                            item.listen_detail[ins - 1].status == true
                          ) {
                            return { ...items, status: true };
                          } else {
                            return items;
                          }
                        }),
                      };
                    } else {
                      return {
                        ...item,
                        listen_detail: item.listen_detail.map((items, ins) => {
                          if (
                            ins == 0 &&
                            lessonVideoStates.progress[i - 1].listen_detail[
                              lessonVideoStates.progress[i - 1].listen_detail
                                .length - 1
                            ].status == true
                          ) {
                            return { ...items, status: true };
                          } else if (
                            ins !== 0 &&
                            item.listen_detail[ins - 1].status == true
                          ) {
                            return { ...items, status: true };
                          } else {
                            return items;
                          }
                        }),
                      };
                    }
                  }),
                },
                token: token?.accessToken,
              });
              setTimeout(() => {
                checkStart();
                checkStartFinish();
                setFinish(false);
              }, 500);
              setTimeout(() => {
                if (
                  lessonVideoStates.dataStudymodel[lessonVideoStates.Mainindex]
                    .listen_detail[lessonVideoStates.index + 1] == undefined &&
                  lessonVideoStates.dataStudymodel[
                    lessonVideoStates.Mainindex + 1
                  ] == undefined
                ) {
                  name1();
                }
              }, 500);
            }}
            style={{
              width: size.width < 500 ? 120 : 165,
              height: 57,
              borderRadius: 5,
              backgroundColor: finish ? "#0D88F0" : "#CCCCCC",
              justifyContent: "center",
              alignItems: "center",
              alignSelf: "center",
              marginVertical: 45,
            }}
          >
            <Text
              style={{
                fontFamily: "Prompt",
                fontSize: size.width < 500 ? 14 : 25,
                color: "#FFFFFF",
              }}
            >
              เสร็จสิ้น
            </Text>
          </TouchableOpacity>
        </View>
        <View
          style={{
            width: "100%",
            height: 70,
            backgroundColor: defaultsStatus ? "#0D88F0" : "#D9D9D9",
            justifyContent: "center",
          }}
        >
          <View
            style={[
              styles.viewProgress,
              { backgroundColor: defaultsStatus ? "#0D88F0" : "#D9D9D9" },
            ]}
          >
            {lessonVideoStates.dataStudymodel[lessonVideoStates.Mainindex]
              .listen_detail[lessonVideoStates.index - 1] != undefined ? (
              <View style={styles.viewTextProgress}>
                <TouchableOpacity
                  onPress={async () => {
                    const Mainindex = lessonVideoStates.Mainindex;
                    const i = lessonVideoStates.index;
                    const newi = lessonVideoStates.index - 1;

                    if (
                      lessonVideoStates.dataStudymodel[Mainindex].listen_detail[
                        i - 1
                      ] != undefined
                    ) {
                      Chanfd(
                        lessonVideoStates.dataStudymodel[Mainindex]
                          .listen_detail[i - 1],
                        Mainindex,
                        newi,
                        true
                      );
                    } else {
                      Chanfd(
                        lessonVideoStates.dataStudymodel[Mainindex - 1]
                          .listen_detail[
                          lessonVideoStates.dataStudymodel[Mainindex - 1]
                            .listen_detail.length - 1
                        ],
                        Mainindex - 1,
                        lessonVideoStates.dataStudymodel[Mainindex - 1]
                          .listen_detail.length - 1,
                        true
                      );
                    }
                  }}
                  style={{ margin: 10 }}
                >
                  <MaterialCommunityIcons
                    name="arrow-left-drop-circle"
                    size={30}
                    color="#FFFFFF"
                  />
                </TouchableOpacity>
                <View>
                  <Text style={[styles.textProgress, { textAlign: "left" }]}>
                    บทก่อนหน้า
                  </Text>
                  <Text numberOfLines={1} style={styles.textProgress}>
                    {lessonVideoStates.dataStudymodel[
                      lessonVideoStates.Mainindex
                    ].listen_detail[lessonVideoStates.index - 1] != undefined
                      ? lessonVideoStates.dataStudymodel[
                          lessonVideoStates.Mainindex
                        ].listen_detail[lessonVideoStates.index - 1].name
                      : lessonVideoStates.dataStudymodel[
                          lessonVideoStates.Mainindex
                        ].listen_detail[0].name}
                  </Text>
                </View>
              </View>
            ) : (
              <View style={styles.viewTextProgress}>
                <TouchableOpacity style={{ margin: 10 }}>
                  <MaterialCommunityIcons
                    name="arrow-left-drop-circle"
                    size={0}
                    color="#FFFFFF"
                  />
                </TouchableOpacity>
                <View>
                  <Text
                    style={[styles.textProgress, { textAlign: "left" }]}
                  ></Text>
                  <Text numberOfLines={1} style={styles.textProgress}></Text>
                </View>
              </View>
            )}
            <TouchableOpacity
              disabled={true}
              style={[
                styles.buttonProgress,
                {
                  //backgroundColor: defaultsStatus ? "#E3F9D6" : "#FEF3CE",
                  width: size.width < 500 ? 120 : 150,
                },
              ]}
            >
              <Text style={styles.textSuccess}>
                {defaultsStatus ? "เสร็จสิ้น" : "กำลังดำเนินการ.."}
              </Text>
            </TouchableOpacity>
            {lessonVideoStates.dataStudymodel ? (
              <View
                style={[
                  styles.viewTextProgress,
                  { justifyContent: "flex-end" },
                ]}
              >
                <View>
                  <Text style={[styles.textProgress]}>บทถัดไป</Text>
                  <Text numberOfLines={1} style={styles.textProgress}>
                    {lessonVideoStates.dataStudymodel[
                      lessonVideoStates.Mainindex
                    ].listen_detail[lessonVideoStates.index + 1] != undefined
                      ? lessonVideoStates.dataStudymodel[
                          lessonVideoStates.Mainindex
                        ].listen_detail[lessonVideoStates.index + 1].name
                      : lessonVideoStates.dataStudymodel[
                          lessonVideoStates.Mainindex + 1
                        ] != undefined
                      ? lessonVideoStates.dataStudymodel[
                          lessonVideoStates.Mainindex + 1
                        ].listen_detail?.[0]?.name
                      : ""}
                  </Text>
                </View>
                <TouchableOpacity
                  onPress={async () => {
                    const Mainindex = lessonVideoStates.Mainindex;
                    const i = lessonVideoStates.index;
                    const newi = lessonVideoStates.index + 1;

                    if (
                      lessonVideoStates.dataStudymodel[Mainindex].listen_detail[
                        i + 1
                      ] != undefined
                    ) {
                      Chanfd(
                        lessonVideoStates.dataStudymodel[Mainindex]
                          .listen_detail[i + 1],
                        Mainindex,
                        newi,
                        Oncecourse.progress[Mainindex].listen_detail[i + 1]
                          .status
                      );
                    } else if (
                      lessonVideoStates.dataStudymodel[Mainindex + 1]
                        .listen_detail?.[0] != undefined
                    ) {
                      Chanfd(
                        lessonVideoStates.dataStudymodel[Mainindex + 1]
                          .listen_detail?.[0],
                        Mainindex + 1,
                        0,
                        Oncecourse.progress[Mainindex + 1].listen_detail?.[0]
                          ?.status
                      );
                    } else {
                      const respp = await apiservice({
                        path:
                          "/start-course/getalltotals/" +
                          lessonVideoStates.course_id,
                        method: "get",
                        token: token?.accessToken,
                      });
                      const resppt = await apiservice({
                        path:
                          "/start-course/getworktotals/" +
                          lessonVideoStates.course_id,
                        method: "get",
                        token: token?.accessToken,
                      });
                      if (respp.status == 200) {
                        setShow1(resppt.data.results);
                        setShow(respp.data.results);
                        setModalScore(true);
                      }
                    }
                  }}
                  disabled={defaultsStatus ? false : true}
                  style={{ margin: 10 }}
                >
                  <MaterialCommunityIcons
                    name={defaultsStatus ? "arrow-right-drop-circle" : "lock"}
                    size={30}
                    color="#FFFFFF"
                  />
                </TouchableOpacity>
              </View>
            ) : (
              <View style={styles.viewTextProgress}>
                <TouchableOpacity style={{ margin: 10 }}>
                  <MaterialCommunityIcons
                    name="arrow-right-drop-circle"
                    size={0}
                    color="#FFFFFF"
                  />
                </TouchableOpacity>
                <View>
                  <Text
                    style={[styles.textProgress, { textAlign: "right" }]}
                  ></Text>
                  <Text numberOfLines={1} style={styles.textProgress}></Text>
                </View>
              </View>
            )}
          </View>
        </View>
      </ScrollView>
    </View>
  );
}
const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: "100%",
    backgroundColor: "#FFFFFF",
  },
  textDetail: {
    fontFamily: "Prompt",
    fontSize: 20,
    color: "#0B2051",
    marginTop: 40,
  },
  viewTextProgress: {
    flexDirection: "row",
    alignItems: "center",
    width: "30%",
  },
  textProgress: {
    fontFamily: "Prompt",
    fontSize: 12,
    color: "#FFFFFF",
    textAlign: "right",
  },
  viewProgress: {
    width: "100%",
    height: 70,
    flexDirection: "row",
    justifyContent: "space-between",
  },
  buttonProgress: {
    width: 150,
    height: 40,
    borderRadius: 20,
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "center",
  },
  textSuccess: {
    fontFamily: "Prompt",
    fontSize: 20,
    color: "#FFF",
  },
  video: {
    width: "100%",
    //height: 574
  },
  viewModal: {
    width: "100%",
    height: "100%",
    backgroundColor: "#000000bb",
    justifyContent: "center",
  },
  viewDetailModal: {
    width: "100%",
    maxWidth: 650,
    height: "100%",
    maxHeight: 400,
    backgroundColor: "#FFFFFF",
    borderWidth: 1,
    borderColor: "#0D88F0",
    padding: 50,
    paddingHorizontal: width * 0.05,
    alignSelf: "center",
    justifyContent: "center",
  },
  viewDashedModal: {
    flexDirection: "row",
    borderRightWidth: 2,
    borderBottomWidth: 2,
    borderRightColor: "#0D88F0",
    borderBottomColor: "#0D88F0",
    width: "50%",
    borderStyle: "dashed",
    alignItems: "center",
    paddingVertical: 20,
  },
  text25: {
    fontFamily: "Prompt",
    fontSize: 25,
    color: "#0B2051",
    marginLeft: 25,
  },
  viewDashedModal1: {
    flexDirection: "row",
    borderRightWidth: 2,
    borderRightColor: "#0D88F0",
    width: "50%",
    borderStyle: "dashed",
    alignItems: "center",
    paddingVertical: 20,
  },
});
