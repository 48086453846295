import React, { useEffect, useState } from "react";
import {
  View,
  Text,
  Image,
  Dimensions,
  TouchableOpacity,
  StyleSheet,
  ScrollView,
  FlatList,
} from "react-native";
import { MaterialCommunityIcons, MaterialIcons } from "@expo/vector-icons";
import Header from "../components/header";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import HTMLRenderer from "react-html-renderer";
import { apiservice } from "../../service";
import { useRecoilState, useRecoilValue } from "recoil";
import { courseState, tokenState, userState } from "../../reducer/recoil";
import { useIsFocused } from "@react-navigation/native";

const { width, height } = Dimensions.get("window");
import Modal from "modal-react-native-web";
import { useWindowSize } from "../components/responsive";

export default function lesson({ navigation, route }) {
  const [Oncecourse, myOncecourse] = useState(null);
  const [modalScore, setModalScore] = useState(false);
  const [defaultsStatus, setStatus] = useState(true);
  const [show, setShow] = useState([]);
  const [show1, setShow1] = useState([]);
  const [unLock, setUnLock] = useState(false);
  const DATA = route.params.document;
  const embed = route.params.detail.detail;
  const token = useRecoilValue(tokenState);
  const isFocus = useIsFocused();
  const [too, setToo] = useState(null);
  const [users, setUser] = useRecoilState(userState);
  const [courseStates, setCourseStates] = useRecoilState(courseState);

  async function Chanfd(params, Mainindex, i) {
    if (params.type == "EXAM") {
      const res = await apiservice({
        path: "/quiz/get/" + params.education_id,
        method: "get",
        token: token?.accessToken,
      });

      navigation.navigate("TestReview", {
        ...params,
        ...res.data,
        Mainindex,
        index: i,
        number: 0,
        dataStudymodel: route.params.dataStudymodel,
      });
    } else if (params.type == "DESCRIPTION") {
      const res = await apiservice({
        path: "/course/geteducation/" + params.education_id,
        method: "get",
        token: token?.accessToken,
      });
      if (res.status == 200) {
        if (res.data.TYPE == "VDO") {
          navigation.navigate("LessonVideoReview", {
            ...params,
            ...res.data,
            number: 0,
            Mainindex,
            dataStudymodel: route.params.dataStudymodel,
            index: i,
          });
        } else {
          navigation.navigate("LessonReview", {
            ...params,
            ...res.data,
            number: 0,
            Mainindex,
            dataStudymodel: route.params.dataStudymodel,
            index: i,
          });
        }
      }
    } else if (params.type == "WORK") {
      const res = await apiservice({
        path: "/course/getwork/" + params.education_id,
        method: "get",
        token: token?.accessToken,
      });

      navigation.push("SubjectiveTestReview", {
        ...res.data,
        ...params,
        number: 0,
        Mainindex,
        index: i,
        dataStudymodel: route.params.dataStudymodel,
      });
    } else {
      const res = await apiservice({
        path: "/quiz/get/" + params.education_id,
        method: "get",
        token: token?.accessToken,
      });

      navigation.push("SubjectiveTestReview", {
        ...res.data,
        ...params,
        dataStudymodel: route.params.dataStudymodel,
        number: 0,
        Mainindex,
        index: i,
      });
    }
  }

  const stringToHTML = function (str) {
    const domContainer = document.createElement("span");
    domContainer.innerHTML = str;
    return domContainer;
  };

  const parentEmbed = stringToHTML(embed);

  let oldIframe = parentEmbed.querySelectorAll("oembed");
  oldIframe = Array.from(oldIframe);

  for (const i in oldIframe) {
    let url = oldIframe[i].getAttribute("url");
    url = url.replace("watch?v=", "embed/");

    const newIframe = document.createElement("iframe");
    newIframe.setAttribute("width", width);
    newIframe.setAttribute("height", "auto");
    newIframe.setAttribute("allowFullScreen", "");
    newIframe.setAttribute("frameBorder", 0);
    if (url) {
      newIframe.setAttribute("src", url);
    }
    oldIframe[i].parentNode.replaceChild(newIframe, oldIframe[i]);
  }

  const contentToRender = parentEmbed.outerHTML?.replace(
    /<img /g,
    `<img width="${width * 0.5}" height="auto" `
  );

  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  async function checkStart() {
    const response = await apiservice({
      path: "/start-course/myOncecourse?course_id=" + route.params.course_id,
      method: "get",
      token: token?.accessToken,
    });

    if (response.status == 200) {
      myOncecourse(response.data);
    }
  }

  async function checkStartFinish() {
    const response = await apiservice({
      path: "/start-course/myOncecourse?course_id=" + route.params.course_id,
      method: "get",
      token: token?.accessToken,
    });

    if (response.status == 200) {
      try {
        route.params.progress.map((items) => {
          items.listen_detail.map((val) => {
            if (val.education_id == route.params.education_id) {
              setStatus(true);
            }
          });
        });
      } catch (error) {}

      myOncecourse(response.data);
    }
  }

  async function name1() {
    const respp = await apiservice({
      path: "/start-course/getalltotals/" + route.params.course_id,
      method: "get",
      token: token?.accessToken,
    });
    const resppt = await apiservice({
      path: "/start-course/getworktotals/" + route.params.course_id,
      method: "get",
      token: token?.accessToken,
    });
    if (respp.status == 200) {
      setShow1(resppt.data.results);
      setShow(respp.data.results);
      setModalScore(true);
    }
  }

  useEffect(() => {
    if (isFocus) {
      checkStart();
      // if (
      //   route.params.dataStudymodel[route.params.Mainindex].listen_detail[
      //     route.params.index + 1
      //   ] == undefined &&
      //   defaultsStatus
      // ) {
      //   setModalScore(true);
      // }
    }
  }, [isFocus]);

  // async function Chanfd(params) {

  //   if (params.type == "EXAM") {
  //     const res = await apiservice({
  //       path: "/quiz/get/" + params.education_id,
  //       method: "get",
  //       token: token?.accessToken,
  //     });

  //     navigation.navigate("Test", {
  //       ...params,
  //       ...res.data,
  //       ...Oncecourse,
  //       number: 0,
  //       course_id: Oncecourse.course_id,
  //     });
  //   } else if (params.type == "DESCRIPTION") {
  //     const res = await apiservice({
  //       path: "/course/geteducation/" + params.education_id,
  //       method: "get",
  //       token: token?.accessToken,
  //     });
  //     if (res.status == 200) {
  //       if (res.data.TYPE == "VDO") {
  //         navigation.navigate("LessonVideo", {
  //           ...params,
  //           ...res.data,
  //           ...Oncecourse,
  //           number: 0,
  //           course_id: Oncecourse.course_id,
  //         });
  //       } else {
  //         navigation.navigate("Lesson", {
  //           ...params,
  //           ...res.data,
  //           ...Oncecourse,
  //           number: 0,
  //           course_id: Oncecourse.course_id,
  //         });
  //       }
  //     }
  //   } else if (params.type == "WORK") {
  //     const res = await apiservice({
  //       path: "/course/getwork/" + params.education_id,
  //       method: "get",
  //       token: token?.accessToken,
  //     });
  //     navigation.navigate("SubjectiveTest", {
  //       ...res.data,
  //       ...params,
  //       ...Oncecourse,
  //       number: 0,
  //       course_id: Oncecourse.course_id,
  //     });
  //   } else {
  //     navigation.navigate("SubjectiveTest", {
  //       ...params,
  //       ...Oncecourse,
  //       number: 0,
  //       course_id: Oncecourse.course_id,
  //     });
  //   }
  // }

  useEffect(() => {
    if (show.length > 0) {
      totalsss();
    }
  }, [show]);

  const size = useWindowSize();

  async function totalsss() {
    let toootals = 0;
    const re = await Promise.all(
      route.params.progress.map(async (item, index) => {
        const res = await apiservice({
          path:
            "/quiz/get/" +
            item.listen_detail.filter((items) => items.type == "EXAM")[0]
              .education_id,
          method: "get",
          token: token?.accessToken,
        });
        const question = res.data.question;
        const answers = item.listen_detail.filter(
          (items) => items.type == "EXAM"
        )[0].answer;
        toootals =
          (answers
            .filter(
              (ele, ind) =>
                ind === answers.findIndex((elem) => elem.number === ele.number)
            )
            .filter((items, mainIndex) => {
              // if(items)

              if (
                question.filter((val, indexs) => indexs == items.number)[0]
                  .type == "1"
              ) {
                return (
                  question.filter((val, indexs) => indexs == items.number)[0]
                    .answer == items.answer
                );
              }
              if (
                question.filter((val, indexs) => indexs == items.number)[0]
                  .type == "2"
              ) {
                return (
                  question
                    .filter((val, indexs) => indexs == items.number)[0]
                    .answer.filter(
                      (e) => items.answer.filter((ev) => e == ev).length > 0
                    ).length ==
                  question.filter((val, indexs) => indexs == items.number)[0]
                    .answer.length
                );
              }
              if (
                question.filter((val, indexs) => indexs == items.number)[0]
                  .type == "3"
              ) {
                return question.filter(
                  (val, indexs) => indexs == items.number
                )[0].answer != null
                  ? question.filter((val, indexs) => indexs == items.number)[0]
                      .answer == items.answer
                  : items.answer == 0
                  ? true
                  : false;
              }
              if (
                question.filter((val, indexs) => indexs == items.number)[0]
                  .type == "4"
              ) {
                return (
                  question
                    .filter((val, indexs) => indexs == items.number)[0]
                    .answer.filter(
                      (e) =>
                        items.answer.filter((ev) => e.answer == ev.answer)
                          .length > 0
                    ).length ==
                  question.filter((val, indexs) => indexs == items.number)[0]
                    .answer.length
                );
              }
              if (
                question.filter((val, indexs) => indexs == items.number)[0]
                  .type == "5"
              ) {
                return (
                  question.filter((val, indexs) => {
                    if (val.type == "5") {
                      return (
                        answers
                          .filter((val, indexss) => indexs == val.number)[0]
                          .answer.filter((e, i) => val.choice[i] == e.ans)
                          .length == val.choice.length
                      );
                    } else {
                      return false;
                    }
                  }).length > 0
                );
              }

              return false;
            }).length /
            answers.length) *
          100;
      })
    );

    setToo(toootals);
  }

  // if (
  //   defaultsStatus &&
  //   route.params.dataStudymodel[route.params.Mainindex].listen_detail[
  //     route.params.index + 1
  //   ] == undefined &&
  //   too == null &&
  //   route.params.dataStudymodel[route.params.Mainindex + 1] == undefined
  // ) {
  //   return <View />;
  // }

  // if (
  //   defaultsStatus &&
  //   route.params.dataStudymodel[route.params.Mainindex].listen_detail[
  //     route.params.index + 1
  //   ] == undefined &&
  //   show.length == 0 &&
  //   route.params.dataStudymodel[route.params.Mainindex + 1] == undefined
  // ) {
  //   return <View />;
  // }

  return (
    <View style={styles.container}>
      <Modal
        transparent={true}
        visible={modalScore}
        onRequestClose={() => {
          Alert.alert("Modal has been closed.");
          setModalScore(!modalScore);
        }}
      >
        <View style={styles.viewModal}>
          <View
            style={[styles.viewDetailModal, { maxWidth: 600, maxHeight: 500 }]}
          >
            <View style={{}}>
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  paddingTop: 20,
                }}
              >
                <Text
                  style={{
                    fontFamily: "Prompt",
                    fontSize: 30,
                    color: "#0B2051",
                  }}
                >
                  ผลของคุณคือ
                </Text>
                <Text
                  style={{
                    fontFamily: "Prompt",
                    fontSize: 80,
                    color: "#0B2051",
                    marginLeft: 43,
                  }}
                >
                  {too}%
                </Text>
              </View>
              <Text
                style={{
                  fontFamily: "Prompt",
                  fontSize: 20,
                  color: "#0B2051",
                  alignSelf: "center",
                  marginBottom: 40,
                }}
              >
                สรุปการเรียนของคุณ
              </Text>
              <View style={{ flexDirection: "row" }}>
                <View style={styles.viewDashedModal}>
                  <MaterialIcons
                    name="library-books"
                    size={40}
                    color="#0B2051"
                  />
                  <Text style={styles.text25}>
                    {show1.length}/{show1.length}
                  </Text>
                </View>
                <View
                  style={[
                    styles.viewDashedModal,
                    { justifyContent: "flex-end", borderRightWidth: 0 },
                  ]}
                >
                  <MaterialIcons name="archive" size={40} color="#0B2051" />
                  <Text style={styles.text25}>
                    {show.filter((item) => item.type == "TEST").length}/
                    {show.filter((item) => item.type == "TEST").length}
                  </Text>
                </View>
              </View>
              <View style={{ flexDirection: "row" }}>
                <View style={styles.viewDashedModal1}>
                  <MaterialIcons name="live-help" size={40} color="#0B2051" />
                  <Text style={styles.text25}>
                    {show.filter((item) => item.type == "WRITING").length}/
                    {show.filter((item) => item.type == "WRITING").length}
                  </Text>
                </View>
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    width: "50%",
                  }}
                >
                  <Text
                    style={{
                      fontFamily: "Prompt",
                      fontSize: 25,
                      color: "#0B2051",
                    }}
                  >
                    ผล
                  </Text>
                  <Text
                    style={{
                      fontFamily: "Prompt",
                      fontSize: 30,
                      color: "#74C745",
                      marginLeft: 25,
                    }}
                  >
                    ผ่าน
                  </Text>
                </View>
              </View>
            </View>
            <View
              style={{
                flexDirection: "row",
                justifyContent: "center",
                marginVertical: 30,
              }}
            >
              {/* <TouchableOpacity
                onPress={() => {
                  window.open(
                    `https://api.courseoncloud.com/api/image/export/${route.params.course_id}?uid=${users.uid}`
                  );
                  // https://api.courseoncloud.com/api/image/export/1?uid=32
                }}
                style={{
                  width: 141,
                  height: 44,
                  backgroundColor: "#0D88F0",
                  borderRadius: 5,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Text
                  style={{
                    fontSize: 20,
                    fontFamily: "Prompt",
                    color: "#FFFFFF",
                  }}
                >
                  Certificate
                </Text>
              </TouchableOpacity> */}
              <TouchableOpacity
                onPress={async () => {
                  setModalScore(!modalScore);
                  setUnLock(false);
                  const response = await apiservice({
                    path:
                      "/course/getall?page=1&lan=TH&course_id=" +
                      route.params.course_id,
                    method: "get",
                    //token: token?.accessToken,
                  });
                  setCourseStates(response.data.data4[0]);
                  setTimeout(() => {
                    navigation.navigate("Course");
                  }, 900);
                }}
                style={{
                  width: 141,
                  height: 44,
                  borderWidth: 1,
                  borderColor: "#0D88F0",
                  borderRadius: 5,
                  justifyContent: "center",
                  alignItems: "center",
                  // marginLeft: 60,
                }}
              >
                <Text
                  style={{
                    fontSize: 20,
                    fontFamily: "Prompt",
                    color: "#0D88F0",
                  }}
                >
                  ดูคอร์ส
                </Text>
              </TouchableOpacity>
            </View>
            <Text
              style={{
                fontSize: 12,
                fontFamily: "Prompt",
                color: "#E82626",
                alignSelf: "center",
              }}
            >
              กรุณาติดต่อผู้สอนกรณีต้องการปรึกษาเรื่องผลการเรียน
            </Text>
          </View>
        </View>
      </Modal>
      <ScrollView stickyHeaderIndices={[0]}>
        <Header navigation={navigation} />
        <View>
          <View
            style={{
              width: size.width < 500 ? "80%" : "50%",
              alignSelf: "center",
              marginTop: 35,
              minHeight: size.height - 155,
            }}
          >
            <Text
              style={{ fontFamily: "Prompt", fontSize: 11, color: "#0B2051" }}
            >
              {/* พุทธศาสตรบัณฑิต (การสอนพระพุทธศาสนาและจิตวิทยาแนะแนว) */}
            </Text>
            <Text
              style={{
                fontFamily: "Prompt",
                fontSize: size.width < 500 ? 16 : 25,
                color: "#0B2051",
              }}
            >
              {/* ทำความรู้จักงานแนะแนว */}
              {route.params.name}
            </Text>
            {route.params.detail.image != "" && (
              <Image
                style={{
                  height: size.width < 500 ? 250 : (size.width * 25) / 100,
                }}
                source={{
                  uri:
                    "https://api.courseoncloud.com/api/image/getimage/" +
                    route.params.detail.image.replace(".png", ""),
                }}
              />
            )}
            <Text
              style={[
                styles.textDetail,
                { fontSize: size.width < 500 ? 14 : 20 },
              ]}
            >
              <HTMLRenderer
                html={contentToRender}
                imagesMaxWidth={Dimensions.get("window").width - 40}
                components={
                  {
                    // h1: props => <Heading color="red" {...props} />,
                    // h2: Subheading,
                    // a: Link,
                  }
                }
              />
            </Text>
            <FlatList
              data={DATA}
              numColumns={1}
              renderItem={({ index, item }) => {
                return (
                  <View
                    style={{
                      borderTopWidth: index == 0 && 1,
                      borderBottomWidth: 1,
                      borderBottomColor: "#BBBBBB",
                      borderTopColor: "#BBBBBB",
                      paddingVertical: 15,
                    }}
                  >
                    <TouchableOpacity
                      onPress={async () => {
                        // const response = await apiservice({
                        //   path: "/start-course/update/" + route.params.id,
                        //   method: "put",
                        //   body: {
                        //     progress:
                        //       route.params.progress.filter(
                        //         (e) => e.number == route.params.number
                        //       ).length > 0
                        //         ? route.params.progress.map((item) => {
                        //             if (item.number == route.params.number) {
                        //               return { ...item, pdf: item.pdf + 1 };
                        //             } else {
                        //               return item;
                        //             }
                        //           })
                        //         : route.params.progress.concat({
                        //             number: route.params.number,
                        //             pdf: 1,
                        //             pdflength: DATA.length,
                        //             status: false,
                        //           }),
                        //   },
                        //   token: token?.accessToken,
                        // });
                        // checkStart();
                        openInNewTab(
                          "https://api.courseoncloud.com/api/image/pdf/" +
                            item.files.replaceAll(".pdf", "")
                        );
                      }}
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <Text
                        style={[
                          width < 500
                            ? stylesResponsive.textDetail
                            : styles.textDetail,
                          { marginTop: 0 },
                        ]}
                      >
                        {item.name}
                      </Text>
                      <MaterialIcons
                        name="cloud-download"
                        size={24}
                        color="#0D88F0"
                      />
                    </TouchableOpacity>
                  </View>
                );
              }}
            />
            <TouchableOpacity
              disabled={defaultsStatus ? true : false}
              //disabled={route.params.status || finish ? true : false}
              onPress={async () => {
                const response = await apiservice({
                  path: "/start-course/update/" + route.params.id,
                  method: "put",
                  body: {
                    progress: route.params.progress.map((item, i) => {
                      if (i == 0) {
                        return {
                          ...item,
                          listen_detail: item.listen_detail.map(
                            (items, ins) => {
                              if (ins == 0) {
                                return { ...items, status: true };
                              } else if (
                                ins !== 0 &&
                                item.listen_detail[ins - 1].status == true
                              ) {
                                return { ...items, status: true };
                              } else {
                                return items;
                              }
                            }
                          ),
                        };
                      } else {
                        return {
                          ...item,
                          listen_detail: item.listen_detail.map(
                            (items, ins) => {
                              if (
                                ins == 0 &&
                                route.params.progress[i - 1].listen_detail[
                                  route.params.progress[i - 1].listen_detail
                                    .length - 1
                                ].status == true
                              ) {
                                return { ...items, status: true };
                              } else if (
                                ins !== 0 &&
                                item.listen_detail[ins - 1].status == true
                              ) {
                                return { ...items, status: true };
                              } else {
                                return items;
                              }
                            }
                          ),
                        };
                      }
                    }),
                  },
                  token: token?.accessToken,
                });
                setTimeout(() => {
                  checkStartFinish();
                  setUnLock(true);
                }, 500);
                setTimeout(() => {
                  if (
                    defaultsStatus &&
                    route.params.dataStudymodel[route.params.Mainindex]
                      .listen_detail[route.params.index + 1] == undefined &&
                    route.params.dataStudymodel[route.params.Mainindex + 1]
                      .listen_detail[0] == undefined
                  ) {
                    setModalScore(true);
                  }
                }, 500);
              }}
              style={{
                width: 165,
                height: 57,
                borderRadius: 5,
                backgroundColor: defaultsStatus ? "#CCCCCC" : "#0D88F0",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center",
                marginVertical: 45,
              }}
            >
              <Text
                style={{
                  fontFamily: "Prompt",
                  fontSize: size.width < 500 ? 14 : 25,
                  color: "#FFFFFF",
                }}
              >
                เสร็จสิ้น
              </Text>
            </TouchableOpacity>
          </View>
          <View
            style={{
              width: "100%",
              height: 70,
              backgroundColor: defaultsStatus ? "#0D88F0" : "#D9D9D9",
              justifyContent: "center",
            }}
          >
            <View
              style={[
                styles.viewProgress,
                { backgroundColor: defaultsStatus ? "#0D88F0" : "#D9D9D9" },
              ]}
            >
              {route.params.Mainindex == 0 && route.params.index == 0 ? (
                <View style={styles.viewTextProgress}>
                  <TouchableOpacity style={{ margin: 10 }}>
                    <MaterialCommunityIcons
                      name="arrow-left-drop-circle"
                      size={0}
                      color="#FFFFFF"
                    />
                  </TouchableOpacity>
                  <View>
                    <Text
                      style={[styles.textProgress, { textAlign: "left" }]}
                    ></Text>
                    <Text numberOfLines={1} style={styles.textProgress}></Text>
                  </View>
                </View>
              ) : (
                <View style={styles.viewTextProgress}>
                  <TouchableOpacity
                    onPress={async () => {
                      const Mainindex = route.params.Mainindex;
                      const i = route.params.index;
                      const newi = route.params.index - 1;

                      if (
                        route.params.dataStudymodel[Mainindex].listen_detail[
                          i - 1
                        ] != undefined
                      ) {
                        Chanfd(
                          route.params.dataStudymodel[Mainindex].listen_detail[
                            i - 1
                          ],
                          Mainindex,
                          newi
                        );
                      } else {
                        Chanfd(
                          route.params.dataStudymodel[Mainindex - 1]
                            .listen_detail[
                            route.params.dataStudymodel[Mainindex - 1]
                              .listen_detail.length - 1
                          ],
                          Mainindex - 1,
                          route.params.dataStudymodel[Mainindex - 1]
                            .listen_detail.length - 1
                        );
                      }
                    }}
                    style={{ margin: 10 }}
                  >
                    <MaterialCommunityIcons
                      name="arrow-left-drop-circle"
                      size={30}
                      color="#FFFFFF"
                    />
                  </TouchableOpacity>
                  <View>
                    <Text style={[styles.textProgress, { textAlign: "left" }]}>
                      บทก่อนหน้า
                    </Text>
                    <Text numberOfLines={1} style={styles.textProgress}>
                      {route.params.dataStudymodel[route.params.Mainindex]
                        .listen_detail[route.params.index - 1] != undefined
                        ? route.params.dataStudymodel[route.params.Mainindex]
                            .listen_detail[route.params.index - 1].name
                        : route.params.dataStudymodel[route.params.Mainindex]
                            .listen_detail[0].name}
                    </Text>
                  </View>
                </View>
              )}
              <TouchableOpacity
                disabled={true}
                style={[
                  styles.buttonProgress,
                  {
                    backgroundColor: defaultsStatus ? "#E3F9D6" : "#FEF3CE",
                    width: size.width < 500 ? 100 : 150,
                  },
                ]}
              >
                <Text style={styles.textSuccess}>
                  {defaultsStatus ? "เสร็จสิ้น" : "กำลังดำเนินการ.."}
                </Text>
              </TouchableOpacity>
              {route.params.dataStudymodel ? (
                <View
                  style={[
                    styles.viewTextProgress,
                    { justifyContent: "flex-end" },
                  ]}
                >
                  <View>
                    <Text style={[styles.textProgress]}>บทถัดไป</Text>
                    <Text numberOfLines={1} style={styles.textProgress}>
                      {route.params.dataStudymodel[route.params.Mainindex]
                        .listen_detail[route.params.index + 1] != undefined
                        ? route.params.dataStudymodel[route.params.Mainindex]
                            .listen_detail[route.params.index + 1].name
                        : route.params.dataStudymodel[
                            route.params.Mainindex + 1
                          ] != undefined
                        ? route.params.dataStudymodel[
                            route.params.Mainindex + 1
                          ].listen_detail[0].name
                        : ""}
                    </Text>
                  </View>
                  <TouchableOpacity
                    onPress={async () => {
                      const Mainindex = route.params.Mainindex;
                      const i = route.params.index;
                      const newi = route.params.index + 1;

                      if (
                        route.params.dataStudymodel[Mainindex].listen_detail[
                          i + 1
                        ] != undefined
                      ) {
                        Chanfd(
                          route.params.dataStudymodel[Mainindex].listen_detail[
                            i + 1
                          ],
                          Mainindex,
                          newi
                        );
                      } else if (
                        route.params.dataStudymodel[Mainindex + 1]
                          .listen_detail[0] != undefined
                      ) {
                        Chanfd(
                          route.params.dataStudymodel[Mainindex + 1]
                            .listen_detail[0],
                          Mainindex + 1,
                          -1
                        );
                      } else {
                        const respp = await apiservice({
                          path:
                            "/start-course/getalltotals/" +
                            route.params.course_id,
                          method: "get",
                          token: token?.accessToken,
                        });
                        const resppt = await apiservice({
                          path:
                            "/start-course/getworktotals/" +
                            route.params.course_id,
                          method: "get",
                          token: token?.accessToken,
                        });
                        if (respp.status == 200) {
                          setShow1(resppt.data.results);
                          setShow(respp.data.results);
                          setModalScore(true);
                        }
                      }
                    }}
                    disabled={defaultsStatus ? false : true}
                    style={{ margin: 10 }}
                  >
                    <MaterialCommunityIcons
                      name={defaultsStatus ? "arrow-right-drop-circle" : "lock"}
                      size={30}
                      color="#FFFFFF"
                    />
                  </TouchableOpacity>
                </View>
              ) : (
                <View style={styles.viewTextProgress}>
                  <TouchableOpacity style={{ margin: 10 }}>
                    <MaterialCommunityIcons
                      name="arrow-right-drop-circle"
                      size={0}
                      color="#FFFFFF"
                    />
                  </TouchableOpacity>
                  <View>
                    <Text
                      style={[styles.textProgress, { textAlign: "right" }]}
                    ></Text>
                    <Text numberOfLines={1} style={styles.textProgress}></Text>
                  </View>
                </View>
              )}
            </View>
          </View>
        </View>
      </ScrollView>
    </View>
  );
}
const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: "100%",
    backgroundColor: "#FFFFFF",
  },
  text25: {
    fontFamily: "Prompt",
    fontSize: 25,
    color: "#0B2051",
    marginLeft: 25,
  },
  viewDashedModal1: {
    flexDirection: "row",
    borderRightWidth: 2,
    borderRightColor: "#0D88F0",
    width: "50%",
    borderStyle: "dashed",
    alignItems: "center",
    paddingVertical: 20,
  },
  viewDashedModal: {
    flexDirection: "row",
    borderRightWidth: 2,
    borderBottomWidth: 2,
    borderRightColor: "#0D88F0",
    borderBottomColor: "#0D88F0",
    width: "50%",
    borderStyle: "dashed",
    alignItems: "center",
    paddingVertical: 20,
  },
  textDetail: {
    fontFamily: "Prompt",
    fontSize: 20,
    color: "#0B2051",
    marginTop: 40,
  },
  viewModal: {
    width: "100%",
    height: "100%",
    backgroundColor: "#000000bb",
    justifyContent: "center",
  },
  viewDetailModal: {
    width: "100%",
    maxWidth: 650,
    height: "100%",
    maxHeight: 400,
    backgroundColor: "#FFFFFF",
    borderWidth: 1,
    borderColor: "#0D88F0",
    padding: 50,
    paddingHorizontal: width * 0.05,
    alignSelf: "center",
    justifyContent: "center",
  },
  buttonModal: {
    width: 150,
    height: 50,
    backgroundColor: "#0D88F0",
    borderRadius: 5,
    justifyContent: "center",
    alignItems: "center",
  },
  textButtonModal: {
    fontFamily: "Prompt",
    fontSize: 30,
    color: "#FFFFFF",
  },
  textTitleModal: {
    fontFamily: "Prompt",
    fontSize: 40,
    color: "#0B2051",
    alignSelf: "center",
  },
  viewProgress: {
    width: "100%",
    height: 70,
    flexDirection: "row",
    justifyContent: "space-between",
  },
  viewTextProgress: {
    flexDirection: "row",
    alignItems: "center",
    width: "30%",
  },
  textProgress: {
    fontFamily: "Prompt",
    fontSize: 12,
    color: "#FFFFFF",
    textAlign: "right",
  },
  buttonProgress: {
    width: 150,
    height: 40,
    borderRadius: 20,
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "center",
  },
  textSuccess: {
    fontFamily: "Prompt",
    fontSize: 12,
    color: "#0B2051",
  },
  viewModal: {
    width: "100%",
    height: "100%",
    backgroundColor: "#000000bb",
    justifyContent: "center",
  },
  viewDetailModal: {
    width: "100%",
    maxWidth: 650,
    height: "100%",
    maxHeight: 400,
    backgroundColor: "#FFFFFF",
    borderWidth: 1,
    borderColor: "#0D88F0",
    padding: 50,
    paddingHorizontal: width * 0.05,
    alignSelf: "center",
    justifyContent: "center",
  },
  buttonModal: {
    width: 150,
    height: 50,
    backgroundColor: "#0D88F0",
    borderRadius: 5,
    justifyContent: "center",
    alignItems: "center",
  },
  textButtonModal: {
    fontFamily: "Prompt",
    fontSize: 30,
    color: "#FFFFFF",
  },
  textTitleModal: {
    fontFamily: "Prompt",
    fontSize: 40,
    color: "#0B2051",
    alignSelf: "center",
  },
  buttonModal1: {
    width: 150,
    height: 50,
    borderWidth: 1,
    borderColor: "#0D88F0",
    borderRadius: 5,
    justifyContent: "center",
    alignItems: "center",
  },
  textDetailModal: {
    fontFamily: "Prompt",
    fontSize: 18,
    color: "#0B2051",
  },
  viewDashedModal: {
    flexDirection: "row",
    borderRightWidth: 2,
    borderBottomWidth: 2,
    borderRightColor: "#0D88F0",
    borderBottomColor: "#0D88F0",
    width: "50%",
    borderStyle: "dashed",
    alignItems: "center",
    paddingVertical: 20,
  },
  viewDashedModal1: {
    flexDirection: "row",
    borderRightWidth: 2,
    borderRightColor: "#0D88F0",
    width: "50%",
    borderStyle: "dashed",
    alignItems: "center",
    paddingVertical: 20,
  },
  text25: {
    fontFamily: "Prompt",
    fontSize: 25,
    color: "#0B2051",
    marginLeft: 25,
  },
  viewCkEditor: {
    borderWidth: 1,
    borderColor: "#0D88F0",
    marginTop: 55,
  },
});
const stylesResponsive = StyleSheet.create({
  textDetail: {
    fontFamily: "Prompt",
    fontSize: 14,
    color: "#0B2051",
    marginTop: 40,
  },
});
