import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  Image,
  Dimensions,
  TouchableOpacity,
  StyleSheet,
  ScrollView,
  Alert,
} from "react-native";
import { MaterialCommunityIcons, MaterialIcons } from "@expo/vector-icons";
import Header from "../components/header";
import { bottomPart } from "./home";
import Modal from "modal-react-native-web";
import Exam from "./exam";
import { apiservice } from "../../service";
import { useRecoilValue } from "recoil";
import {
  courseState,
  lessonState,
  lessonVideoState,
  subjecttestState,
  testState,
  tokenState,
} from "../../reducer/recoil";
import { useWindowSize } from "../components/responsive";
import { useIsFocused } from "@react-navigation/native";
import HTMLRenderer from "react-html-renderer";
import ReactCountdownClock from "react-countdown-clock";
import { useRecoilState } from "recoil";
const { width, height } = Dimensions.get("window");

export default function test({ navigation, route }) {
  const [testStates, setTestStates] = useRecoilState(testState);

  const [courseStates, setCourseStates] = useRecoilState(courseState);
  const [lessonStates, setLessonStates] = useRecoilState(lessonState);
  const [subjectTestStates, setSubjectTestStates] =
    useRecoilState(subjecttestState);
  const [lessonVideoStates, setLessonVideoStates] =
    useRecoilState(lessonVideoState);
  const token = useRecoilValue(tokenState);
  const [page, setPage] = useState(0);
  const [unLock, setUnLock] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalVisible1, setModalVisible1] = useState(false);
  const [modalVisible2, setModalVisible2] = useState(false);
  const [answer, setAnswer] = useState([]);

  const [Oncecourse, myOncecourse] = useState(null);
  const [defaultsStatus, setStatus] = useState(testStates.status);
  const [img, setImg] = useState(
    testStates.img != undefined && testStates.img.image.replace(".png", "")
  );
  const isFocus = useIsFocused();
  const [MyQ, setMyQ] = useState([]);
  const [modalScore, setModalScore] = useState(false);
  const [too, setToo] = useState(null);
  const [show, setShow] = useState([]);
  const [score, setScore] = useState();
  const [show1, setShow1] = useState([]);
  const [sumScore, setSumScore] = useState();
  const [first, setfirst] = useState(false);
  useEffect(() => {
    if (first) {
      setAnswer((val) =>
        val.map((e, i) => {
          return e?.type == "text"
            ? {
              ...e,
              answer: e?.answer?.reverse(),
              //  answer: e?.answer.sort((a, b) => {
              //     console.log("eeeeeeeeeeeeee", a, b);
              //     return a?.number - b?.number;
              //   }),
            }
            : { ...e };
        })
      );
      setfirst(false);
    }
  }, [answer]);
  useEffect(() => {
    if (testStates.img != undefined) {
      setImg(testStates.img.image.replace(".png", ""));
    }
  }, [testStates]);

  useEffect(() => {
    if (
      defaultsStatus &&
      testStates?.dataStudymodel[testStates.Mainindex]?.listen_detail?.[
      testStates.index + 1
      ] == undefined
    ) {
      if (testStates.dataStudymodel[testStates.Mainindex + 1] == undefined) {
        setModalScore(true);
      } else {
        if (
          testStates.dataStudymodel[testStates.Mainindex + 1]?.listen_detail
            ?.length == 0
        ) {
          setModalScore(true);
        }
      }
    }
  }, [testState, defaultsStatus]);

  useEffect(() => {
    if (too) {
    }
  }, [too]);

  async function resubmit(params) {
    if (params != null) {
      if (Oncecourse.Type == "PENDING") {
        const reespon = await apiservice({
          path: "/start-course/update/" + Oncecourse.id,
          method: "put",
          body: {
            Type: params >= Oncecourse?.courses?.percent ? "APPROVE" : "REJECT",
          },
          token: token?.accessToken,
        });
        if (reespon.status == 200) {
          checkStart();
        }
      }
    }
  }

  totalsss();

  useEffect(() => {
    if (Oncecourse) {
      sum();
    }
  }, [Oncecourse]);

  async function sum() {
    const users = await apiservice({
      path: "/authen/user",
      method: "get",
      token: token?.accessToken,
    });
    if (users.status == 200) {
      const res = await apiservice({
        path:
          "/start-course/myscore?course_id=" +
          testStates.course_id +
          "&uid=" +
          users.data.result.id,
        method: "get",
      });

      if (res.status == 200) {
        setSumScore(res.data.results);
        conditionToo(res.data.results?.EXAM);
        resubmit(res.data.results?.TOTAL_EXAM);
      }
    }
  }
  async function getScore() {
    const users = await apiservice({
      path: "/authen/user",
      method: "get",
      token: token?.accessToken,
    });
    if (users.status == 200) {
      const res = await apiservice({
        path:
          "/start-course/getMyscoretest?course_id=" +
          testStates.course_id +
          "&uid=" +
          users.data.result.id +
          "&education_id=" +
          testStates.education_id,
        method: "get",
        token: token?.accessToken,
      });
      if (res.status == 200) {
        setScore(res.data.results.EXAM);
      }
    }
  }
  async function totalsss() {
    let toootals = 0;

    if (Oncecourse?.progress) {
      const re = await Promise.all(
        Oncecourse.progress.map(async (item, index) => {
          const question = item.MyQ;
          const answers = item?.listen_detail?.filter(
            (items) => items?.type == "EXAM"
          )?.[0]?.answer;

          toootals =
            toootals +
            ((answers
              ?.filter(
                (ele, ind) =>
                  ind ===
                  answers.findIndex((elem) => elem.number === ele.number)
              )
              .filter((items, mainIndex) => {
                if (
                  question?.filter((val, indexs) => indexs == items.number)?.[0]
                    ?.type == "1"
                ) {
                  return (
                    question?.filter(
                      (val, indexs) => indexs == items.number
                    )?.[0]?.answer == items.answer
                  );
                }
                if (
                  question?.filter((val, indexs) => indexs == items.number)?.[0]
                    ?.type == "2"
                ) {
                  return (
                    question
                      ?.filter((val, indexs) => indexs == items.number)?.[0]
                      ?.answer.filter(
                        (e) => items.answer.filter((ev) => e == ev).length > 0
                      ).length ==
                    question?.filter(
                      (val, indexs) => indexs == items.number
                    )?.[0]?.answer.length
                  );
                }
                if (
                  question?.filter((val, indexs) => indexs == items.number)?.[0]
                    ?.type == "3"
                ) {
                  return question?.filter(
                    (val, indexs) => indexs == items.number
                  )?.[0]?.answer != null
                    ? question?.filter(
                      (val, indexs) => indexs == items.number
                    )?.[0]?.answer == items.answer
                    : items.answer == 0
                      ? true
                      : false;
                }
                if (
                  question?.filter((val, indexs) => indexs == items.number)?.[0]
                    ?.type == "4"
                ) {
                  return (
                    question
                      .filter((val, indexs) => indexs == items.number)?.[0]
                      ?.answer.filter(
                        (e) =>
                          items.answer.filter((ev) => e.answer == ev.answer)
                            .length > 0
                      ).length ==
                    question?.filter(
                      (val, indexs) => indexs == items.number
                    )?.[0]?.answer.length
                  );
                }
                if (
                  question?.filter((val, indexs) => indexs == items.number)?.[0]
                    ?.type == "5"
                ) {
                  return (
                    question?.filter((val, indexs) => {
                      if (val.type == "5") {
                        return (
                          answers
                            .filter((val, indexss) => indexs == val.number)?.[0]
                            ?.answer.filter((e, i) => val.choice[i] == e.ans)
                            .length == val.choice.length
                        );
                      } else {
                        return false;
                      }
                    }).length > 0
                  );
                }

                return false;
              })?.length || 0) /
              question?.length) *
            100;
        })
      );

      setToo(toootals);
    }
  }

  async function Chanfd(params, Mainindex, i, status) {
    setStatus(false);
    if (params.type == "EXAM") {
      const res = await apiservice({
        path: "/quiz/get/" + params.education_id,
        method: "get",
        token: token?.accessToken,
      });
      setTestStates({
        ...params,
        ...res.data,
        ...Oncecourse,
        Mainindex,
        index: i,
        number: 0,
        dataStudymodel: Oncecourse.progress,
        status,

        course_id: Oncecourse.course_id,
      });
      setTimeout(() => {
        navigation.navigate("Test");
      }, 1000);
    } else if (params.type == "DESCRIPTION") {
      const res = await apiservice({
        path: "/course/geteducation/" + params.education_id,
        method: "get",
        token: token?.accessToken,
      });
      if (res.status == 200) {
        if (res.data.TYPE == "VDO") {
          setLessonVideoStates({
            ...params,
            ...res.data,
            ...Oncecourse,
            number: 0,
            status,
            Mainindex,
            dataStudymodel: Oncecourse.progress,

            index: i,
            course_id: Oncecourse.course_id,
          });
          setTimeout(() => {
            navigation.navigate("LessonVideo");
          }, 1000);
        } else {
          setLessonStates({
            ...params,
            ...res.data,
            ...Oncecourse,
            status,
            number: 0,
            Mainindex,
            dataStudymodel: Oncecourse.progress,
            index: i,
            course_id: Oncecourse.course_id,
          });
          setTimeout(() => {
            navigation.navigate("Lesson");
          }, 1000);
        }
      }
    } else if (params.type == "WORK") {
      const res = await apiservice({
        path: "/course/getwork/" + params.education_id,
        method: "get",
        token: token?.accessToken,
      });
      setSubjectTestStates({
        ...res.data,
        ...params,
        ...Oncecourse,
        number: 0,
        Mainindex,
        status,
        index: i,
        dataStudymodel: Oncecourse.progress,
        course_id: Oncecourse.course_id,
      });
      setTimeout(() => {
        navigation.push("SubjectiveTest");
      }, 1000);
    } else {
      const res = await apiservice({
        path: "/quiz/get/" + params.education_id,
        method: "get",
        token: token?.accessToken,
      });

      // setTestStates({ ...testStates });
      setSubjectTestStates({
        ...res.data,
        ...params,
        ...Oncecourse,
        dataStudymodel: Oncecourse.progress,
        number: 0,
        Mainindex,
        status,
        index: i,
        course_id: Oncecourse.course_id,
      });
      setTimeout(() => {
        navigation.push("SubjectiveTest");
      }, 1000);
    }
  }

  useEffect(() => {
    if (testStates.conditions != undefined && testStates.status == false) {
      if (testStates.conditions.randomquestion == false) {
        setMyQ(testStates.question);
      } else {
      }
    }

    if (testStates.status) {
      setStatus(testStates.status);
    }

    if (defaultsStatus) {
      setPage(1);
    }
    if (testStates.status) {
      setPage(1);
    }
  }, [testStates]);

  useEffect(() => {
    if (testStates) {
      checkStart();
    }
  }, [testState, token]);

  async function checkStart() {
    getScore();
    const response = await apiservice({
      path: "/start-course/myOncecourse?course_id=" + testStates.course_id,
      method: "get",
      token: token?.accessToken,
    });
    if (response.status == 200) {
      if (
        response.data.progress[testStates.Mainindex].listen_detail[
          testStates.index
        ].status == false &&
        testStates.conditions.randomquestion == false
      ) {
        setMyQ(testStates.question);
      } else if (
        response.data.progress[testStates.Mainindex].listen_detail[
          testStates.index
        ].status == false &&
        testStates.conditions.randomquestion
      ) {
        setMyQ(
          testStates.question
            .map((itens, index) => ({
              ...itens,
              random: Math.random(),
            }))
            .sort((a, b) => a.random - b.random)
        );
      }
      myOncecourse(response.data);
      setStatus(
        response.data.progress[testStates.Mainindex].listen_detail[
          testStates.index
        ].status
      );
      if (
        response.data.progress[testStates.Mainindex].listen_detail[
          testStates.index
        ].status
      ) {
        setModalVisible1(true);
        setPage(1);
        if (
          response.data.progress[testStates.Mainindex].listen_detail[
            testStates.index
          ].answer != undefined
        ) {
          setAnswer(
            response.data.progress[testStates.Mainindex].listen_detail[
              testStates.index
            ].answer
          );
        }
        if (
          response.data.progress[testStates.Mainindex].listen_detail[
            testStates.index
          ].MyQ != undefined
        ) {
          setMyQ(
            response.data.progress[testStates.Mainindex].listen_detail[
              testStates.index
            ].MyQ
          );
        }
        if (
          response.data.progress[testStates.Mainindex].listen_detail[
            testStates.index
          ].conditions != undefined &&
          response.data.progress[testStates.Mainindex].listen_detail[
            testStates.index
          ].conditions.randomquestion &&
          response.data.progress[testStates.Mainindex].listen_detail[
            testStates.index
          ].status == false
        ) {
          setMyQ(
            response.data.progress[testStates.Mainindex].listen_detail[
              testStates.index
            ].question
              .map((itens, index) => ({
                ...itens,
                random: Math.random(),
              }))
              .sort((a, b) => a.random - b.random)
          );
        }
      } else {
      }
    }
  }

  async function checkStartFinish() {
    const response = await apiservice({
      path: "/start-course/myOncecourse?course_id=" + testStates.course_id,
      method: "get",
      token: token?.accessToken,
    });

    if (response.status == 200) {
      try {
        testStates.progress.map((items) => {
          items.listen_detail.map((val) => {
            if (val.education_id == testStates.education_id) {
              setStatus(true);
            }
          });
        });
      } catch (error) { }

      myOncecourse(response.data);
      setTestStates((val) => ({ ...val, ...response.data }));
    }
  }
  const embed = testStates.detail;

  const stringToHTML = function (str) {
    const domContainer = document.createElement("span");
    domContainer.innerHTML = str;
    return domContainer;
  };

  const parentEmbed = stringToHTML(embed);

  let oldIframe = parentEmbed.querySelectorAll("oembed");
  oldIframe = Array.from(oldIframe);

  for (const i in oldIframe) {
    let url = oldIframe[i].getAttribute("url");
    url = url.replace("watch?v=", "embed/");

    const newIframe = document.createElement("iframe");
    newIframe.setAttribute("width", "auto");
    newIframe.setAttribute("height", "auto");
    newIframe.setAttribute("allowFullScreen", "");
    newIframe.setAttribute("frameBorder", 0);
    if (url) {
      newIframe.setAttribute("src", url);
    }

    oldIframe[i].parentNode.replaceChild(newIframe, oldIframe[i]);
  }

  const contentToRender = parentEmbed.outerHTML?.replace(
    /<img /g,
    `<img width="${width * 0.5}" height="auto" `
  );
  const size = useWindowSize();

  // useEffect(() => {
  //   conditionToo(too);
  // }, [too]);

  async function conditionToo(params) {
    try {
      if (
        defaultsStatus &&
        testStates.dataStudymodel[testStates.Mainindex].listen_detail[
        testStates.index + 1
        ] == undefined &&
        defaultsStatus &&
        testStates.dataStudymodel[testStates.Mainindex + 1] == undefined
      ) {
        if (Oncecourse.Type == "PENDING") {
          const reespon = await apiservice({
            path: "/start-course/update/" + Oncecourse.id,
            method: "put",
            body: {
              Type:
                params >= Oncecourse?.courses?.percent ? "APPROVE" : "REJECT",
            },
            token: token?.accessToken,
          });
          if (reespon.status == 200) {
            checkStart();
          }
        }
      }
    } catch (error) { }
  }

  const TimeOut = async () => {
    // ยิงเมื่อหมดเวลา
    const response = await apiservice({
      path: "/start-course/update/" + testStates.id,
      method: "put",
      body: {
        progress: testStates.progress.map((item, i) => {
          if (i == 0) {
            return {
              ...item,
              MyQ,
              listen_detail: item.listen_detail.map((items, ins) => {
                if (ins == testStates.index && ins == 0) {
                  return {
                    ...items,
                    status: true,
                    answer,
                    MyQ,
                  };
                } else if (
                  ins !== 0 &&
                  ins == testStates.index &&
                  item.listen_detail[ins - 1].status == true
                ) {
                  return {
                    ...items,
                    status: true,
                    answer,
                    MyQ,
                  };
                } else {
                  return items;
                }
              }),
            };
          } else {
            return {
              ...item,
              MyQ,
              listen_detail: item.listen_detail.map((items, ins) => {
                if (
                  ins == 0 &&
                  ins == testStates.index &&
                  testStates.progress[i - 1].listen_detail[
                    testStates.progress[i - 1].listen_detail.length - 1
                  ].status == true
                ) {
                  return {
                    ...items,
                    status: true,
                    answer,
                    MyQ,
                  };
                } else if (
                  ins !== 0 &&
                  ins == testStates.index &&
                  item.listen_detail[ins - 1].status == true
                ) {
                  return {
                    ...items,
                    status: true,
                    answer,
                    MyQ,
                  };
                } else {
                  return items;
                }
              }),
            };
          }
        }),
      },
      token: token?.accessToken,
    });

    const response1 = await apiservice({
      path: "/start-course/createfinal",
      method: "post",
      body: {
        course_id: testStates.course_id,
        type: "TEST",
        totals: answer.filter(
          (items) => items.answer == items.totals || items.answer
        ).length,
      },
      token: token?.accessToken,
    });
    setTimeout(() => {
      checkStartFinish();
      checkStart();
    }, 500);

    setModalVisible2(!modalVisible2);

    // setModalVisible(!modalVisible);
  };
  function myCallback() {
    TimeOut();
  }

  if (testStates.question == undefined) {
    return null;
  }

  if (testStates.img == undefined) {
    return null;
  }

  if (testStates.dataStudymodel == undefined) {
    return null;
  }

  if (MyQ.length == 0) {
    return null;
  }
  if (Oncecourse == null) {
    return null;
  }

  return (
    <View style={styles.container}>
      <ScrollView stickyHeaderIndices={[0]}>
        <Header navigation={navigation} />
        {page &&
          defaultsStatus == false &&
          testStates.conditions.timer != 0 &&
          size.width >= 1280 ? (
          <View
            style={{
              position: "absolute",
              marginTop: height * 0.2,
              marginLeft: width * 0.03,
            }}
          >
            <ReactCountdownClock
              seconds={
                testStates.conditions.timerType == "HOUR"
                  ? testStates.conditions.timer * 60 * 60
                  : testStates.conditions.timer * 60
              }
              paused={defaultsStatus}
              color="#0D88F0"
              alpha={0.9}
              size={300}
              onComplete={myCallback}
            />
          </View>
        ) : (
          <View />
        )}
        {page == 1 && defaultsStatus && (
          <View
            style={{
              position: size.width < 1280 ? "relative" : "absolute",
              width: width < 1600 ? 250 : 300,
              height: width < 1600 ? 250 : 300,
              borderRadius: 150,
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#0D88F0",
              alignSelf: size.width < 1280 && "center",
              marginTop: size.width < 1280 ? 20 : height * 0.2,
              marginLeft: size.width < 1280 ? 0 : width * 0.03,
            }}
          >
            <Text
              style={{
                fontFamily: "Prompt",
                fontSize: 50,
                color: "#FFFFFF",
              }}
            >
              {answer.length > 0
                ? answer
                  .filter(
                    (ele, ind) =>
                      ind ===
                      answer.findIndex((elem) => elem.number === ele.number)
                  )
                  .filter((items, mainIndex) => {
                    if (
                      MyQ.filter((val, indexs) => indexs == items.number)[0]
                        .type == "1"
                    ) {
                      return (
                        MyQ.filter((val, indexs) => indexs == items.number)[0]
                          .answer == items.answer
                      );
                    }
                    if (
                      MyQ.filter((val, indexs) => indexs == items.number)[0]
                        .type == "2"
                    ) {
                      try {
                        return (
                          MyQ.filter(
                            (val, indexs) => indexs == items.number
                          )[0].answer.filter(
                            (e) =>
                              items.answer.filter((ev) => e == ev).length > 0
                          ).length ==
                          MyQ.filter(
                            (val, indexs) => indexs == items.number
                          )[0].answer.length
                        );
                      } catch (error) {
                        return false;
                      }
                    }
                    if (
                      MyQ.filter((val, indexs) => indexs == items.number)[0]
                        .type == "3"
                    ) {
                      return MyQ.filter(
                        (val, indexs) => indexs == items.number
                      )[0].answer != null
                        ? MyQ.filter(
                          (val, indexs) => indexs == items.number
                        )[0].answer == items.answer
                        : items.answer == 0
                          ? true
                          : false;
                    }
                    if (
                      MyQ.filter((val, indexs) => indexs == items.number)[0]
                        .type == "4"
                    ) {
                      try {
                        const ans_for_ = answer?.filter(
                          (item) => item?.number == items.number
                        )?.[0]?.answer;

                        return (
                          MyQ?.filter(
                            (e, i) => i == items.number
                          )?.[0]?.answer?.filter(
                            (item_answer, index_answer) =>
                              ans_for_?.[index_answer]?.answer ==
                              item_answer?.answer
                          )?.length ==
                          MyQ?.filter((e, i) => i == items.number)?.[0]
                            ?.answer?.length
                        );
                      } catch (error) {
                        return false;
                      }
                    }
                    if (
                      MyQ.filter((val, indexs) => indexs == items.number)[0]
                        .type == "5"
                    ) {
                      try {
                        return (
                          MyQ.filter(
                            (val, indexs) => indexs == items.number
                          )[0].choice.filter(
                            (e) =>
                              items.answer
                                .sort((a, b) => b.number - a.number)
                                .filter((ev) => e == ev.ans).length > 0
                          ).length ==
                          MyQ.filter(
                            (val, indexs) => indexs == items.number
                          )[0].choice.length
                        );
                      } catch (error) { }
                    }

                    return false;
                  }).length
                : 0}
              /{MyQ.length}
            </Text>
          </View>
        )}
        <View
          style={{
            width: size.width < 1280 ? "80%" : "50%",
            alignSelf: "center",
            marginTop: 35,
            minHeight: size.height - 155,
          }}
        >
          {page &&
            defaultsStatus == false &&
            testStates.conditions.timer != 0 &&
            size.width < 1280 ? (
            <View
              style={{
                // position: "absolute",
                marginVertical: 40,
                marginLeft: width * 0.03,
              }}
            >
              <ReactCountdownClock
                seconds={
                  testStates.conditions.timerType == "HOUR"
                    ? testStates.conditions.timer * 60 * 60
                    : testStates.conditions.timer * 60
                }
                paused={defaultsStatus}
                color="#0D88F0"
                alpha={0.9}
                size={300}
                onComplete={myCallback}
              />
            </View>
          ) : (
            <View />
          )}
          <Text
            style={{ fontFamily: "Prompt", fontSize: 11, color: "#0B2051" }}
          >
            {/* พุทธศาสตรบัณฑิต (การสอนพระพุทธศาสนาและจิตวิทยาแนะแนว */}
          </Text>
          <Text
            style={{ fontFamily: "Prompt", fontSize: 25, color: "#646464" }}
          >
            {testStates.name}
          </Text>
          {testStates.img.image != "" && (
            <Image
              style={{
                height: size.width < 500 ? 250 : 500,
                marginVertical: 20,
              }}
              source={{
                uri: "https://api.courseoncloud.com/api/image/getimage/" + img,
              }}
            />
          )}
          {page == 0 && (
            <View>
              <Text
                style={{
                  fontFamily: "Prompt",
                  fontSize: size.width < 500 ? 16 : 20,
                  color: "#646464",
                }}
              >
                <HTMLRenderer
                  html={contentToRender}
                  components={
                    {
                      // h1: props => <Heading color="red" {...props} />,
                      // h2: Subheading,
                      // a: Link,
                    }
                  }
                />
              </Text>
              <TouchableOpacity
                disabled={defaultsStatus ? true : false}
                onPress={() => {
                  setPage(1);
                }}
                style={{
                  width: size.width < 500 ? 120 : 170,
                  height: 50,
                  backgroundColor: defaultsStatus ? "#CCCCCC" : "#0D88F0",
                  borderRadius: 10,
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: 20,
                  marginBottom: 45,
                }}
              >
                <Text
                  style={{
                    fontFamily: "Prompt",
                    fontSize: size.width < 500 ? 14 : 18,
                    color: "#FFFFFF",
                  }}
                >
                  เริ่มทำแบบทดสอบ
                </Text>
              </TouchableOpacity>
            </View>
          )}
          {page == 1 && (
            <View>
              {
                <Exam
                  data={MyQ}
                  answer={answer}
                  setAnswer={setAnswer}
                  status={defaultsStatus}
                  conditions={testStates.conditions}
                />
              }
              <TouchableOpacity
                disabled={defaultsStatus ? true : false}
                onPress={() => {
                  setTimeout(() => {
                    setModalVisible(true);
                  }, 500);
                }}
                style={{
                  width: size.width < 500 ? 120 : 165,
                  height: 57,
                  backgroundColor: defaultsStatus ? "#CCCCCC" : "#0D88F0",
                  borderRadius: 5,
                  justifyContent: "center",
                  alignItems: "center",
                  marginVertical: 50,
                  alignSelf: "center",
                }}
              >
                <Text
                  style={{
                    fontFamily: "Prompt",
                    fontSize: size.width < 500 ? 14 : 25,
                    color: "#FFFFFF",
                  }}
                >
                  เสร็จสิ้น
                </Text>
              </TouchableOpacity>
            </View>
          )}
        </View>
        <View
          style={{
            width: "100%",
            height: 70,
            backgroundColor: defaultsStatus ? "#0D88F0" : "#D9D9D9",
            justifyContent: "center",
          }}
        >
          <View
            style={[
              styles.viewProgress,
              { backgroundColor: defaultsStatus ? "#0D88F0" : "#D9D9D9" },
            ]}
          >
            {testStates.Mainindex == 0 && testStates.index == 0 ? (
              <View style={styles.viewTextProgress}>
                <TouchableOpacity style={{ margin: 10 }}>
                  <MaterialCommunityIcons
                    name="arrow-left-drop-circle"
                    size={0}
                    color="#FFFFFF"
                  />
                </TouchableOpacity>
                <View>
                  <Text
                    style={[styles.textProgress, { textAlign: "left" }]}
                  ></Text>
                  <Text numberOfLines={1} style={styles.textProgress}></Text>
                </View>
              </View>
            ) : (
              <View style={styles.viewTextProgress}>
                <TouchableOpacity
                  onPress={async () => {
                    const Mainindex = testStates.Mainindex;
                    const i = testStates.index;
                    const newi = testStates.index - 1;

                    if (
                      testStates.dataStudymodel[Mainindex].listen_detail[
                      i - 1
                      ] != undefined
                    ) {
                      Chanfd(
                        testStates.dataStudymodel[Mainindex].listen_detail[
                        i - 1
                        ],
                        Mainindex,
                        newi,
                        true
                      );
                    } else {
                      Chanfd(
                        testStates.dataStudymodel[Mainindex - 1].listen_detail[
                        testStates.dataStudymodel[Mainindex - 1].listen_detail
                          .length - 1
                        ],
                        Mainindex - 1,
                        testStates.dataStudymodel[Mainindex - 1].listen_detail
                          .length - 1,
                        true
                      );
                    }
                  }}
                  style={{ margin: 10 }}
                >
                  <MaterialCommunityIcons
                    name="arrow-left-drop-circle"
                    size={30}
                    color="#FFFFFF"
                  />
                </TouchableOpacity>
                <View
                  style={{
                    width: size.width < 500 && "70%",
                  }}
                >
                  <Text style={[styles.textProgress, { textAlign: "left" }]}>
                    บทก่อนหน้า
                  </Text>
                  <Text numberOfLines={1} style={styles.textProgress}>
                    {testStates.index == 0
                      ? testStates?.dataStudymodel?.[testStates?.Mainindex - 1]
                        ?.listen_detail[
                        testStates?.dataStudymodel?.[
                          testStates?.Mainindex - 1
                        ]?.listen_detail?.length - 1
                      ]?.name
                      : testStates?.dataStudymodel?.[testStates?.Mainindex]
                        ?.listen_detail?.[testStates?.index - 1]?.name}
                  </Text>
                </View>
              </View>
            )}
            <TouchableOpacity
              disabled={true}
              style={[
                styles.buttonProgress,
                {
                  //backgroundColor: defaultsStatus ? "#E3F9D6" : "#FEF3CE",
                  width: size.width < 500 ? 100 : 150,
                },
              ]}
            >
              <Text style={styles.textSuccess}>
                {defaultsStatus ? "เสร็จสิ้น" : "กำลังดำเนินการ.."}
              </Text>
            </TouchableOpacity>
            {testStates.dataStudymodel ? (
              <View
                style={[
                  styles.viewTextProgress,
                  { justifyContent: "flex-end" },
                ]}
              >
                <View
                  style={{
                    width: size.width < 500 && "70%",
                  }}
                >
                  <Text style={[styles.textProgress]}>บทถัดไป</Text>
                  <Text numberOfLines={1} style={styles.textProgress}>
                    {testStates.dataStudymodel[testStates.Mainindex]
                      .listen_detail[testStates.index + 1] != undefined
                      ? testStates.dataStudymodel[testStates.Mainindex]
                        .listen_detail[testStates.index + 1].name
                      : testStates.dataStudymodel[testStates.Mainindex + 1] !=
                        undefined
                        ? testStates.dataStudymodel[testStates.Mainindex + 1]
                          .listen_detail?.[0]?.name
                        : ""}
                  </Text>
                </View>
                <TouchableOpacity
                  onPress={async () => {
                    const Mainindex = testStates.Mainindex;
                    const i = testStates.index;
                    const newi = testStates.index + 1;

                    if (
                      testStates.dataStudymodel[Mainindex].listen_detail[
                      i + 1
                      ] != undefined
                    ) {
                      Chanfd(
                        testStates.dataStudymodel[Mainindex].listen_detail[
                        i + 1
                        ],
                        Mainindex,
                        newi,
                        testStates.dataStudymodel[Mainindex].listen_detail[
                          i + 1
                        ].status
                      );
                    } else if (
                      testStates.dataStudymodel[Mainindex + 1]
                        .listen_detail[0] != undefined
                    ) {
                      Chanfd(
                        testStates.dataStudymodel[Mainindex + 1]
                          .listen_detail[0],
                        Mainindex + 1,
                        0,
                        testStates.dataStudymodel[Mainindex + 1]
                          .listen_detail[0].status
                      );
                    } else {
                      const respp = await apiservice({
                        path: "/start-course/getalltotals/" + data.course_id,
                        method: "get",
                        token: token?.accessToken,
                      });
                      const resppt = await apiservice({
                        path: "/start-course/getworktotals/" + data.course_id,
                        method: "get",
                        token: token?.accessToken,
                      });
                      if (respp.status == 200) {
                        setShow1(resppt.data.results);
                        setShow(respp.data.results);
                        setModalScore(true);
                      }
                    }
                  }}
                  disabled={defaultsStatus ? false : true}
                  style={{ margin: 10 }}
                >
                  <MaterialCommunityIcons
                    name={defaultsStatus ? "arrow-right-drop-circle" : "lock"}
                    size={30}
                    color="#FFFFFF"
                  />
                </TouchableOpacity>
              </View>
            ) : (
              <View style={styles.viewTextProgress}>
                <TouchableOpacity style={{ margin: 10 }}>
                  <MaterialCommunityIcons
                    name="arrow-right-drop-circle"
                    size={0}
                    color="#FFFFFF"
                  />
                </TouchableOpacity>
                <View>
                  <Text
                    style={[styles.textProgress, { textAlign: "right" }]}
                  ></Text>
                  <Text numberOfLines={1} style={styles.textProgress}></Text>
                </View>
              </View>
            )}
          </View>
        </View>
        <Modal
          transparent={true}
          visible={modalVisible}
          onRequestClose={() => {
            Alert.alert("Modal has been closed.");
            setModalVisible(!modalVisible);
          }}
        >
          <View style={styles.viewModal}>
            <View style={styles.viewDetailModal}>
              <Text style={styles.textTitleModal}>ยืนยันจะส่งคำตอบหรือไม่</Text>
              <View
                style={{
                  flexDirection: "row",
                  marginTop: 80,
                  justifyContent: "space-between",
                }}
              >
                <TouchableOpacity
                  onPress={async () => {

                    // const a = answer.map((e) => {
                    //   return e.type == "text"
                    //     ? {
                    //       ...e,
                    //       answer: e.answer.sort((a, b) => {
                    //         if (a.number > b.number) {
                    //           return 1;
                    //         } else if (a.number < b.number) {
                    //           return -1;
                    //         } else {
                    //           return 0;
                    //         }
                    //       }),
                    //     }
                    //     : { ...e };
                    // });

                    // testStates.progress.map((value, i) => {
                    //   if (i == testStates.Mainindex) {
                    //     return {
                    //       ...value,
                    //       MyQ,
                    //       listen_detail: value.listen_detail.map(
                    //         (item, index) => {
                    //           if (ins == testStates.index && ins == 0) {
                    //             return {
                    //               ...item,
                    //               status: true,
                    //               answer: a,
                    //               MyQ,
                    //             };
                    //           } else if (
                    //             ins !== 0 &&
                    //             ins == testStates.index &&
                    //             item.listen_detail[ins - 1].status == true
                    //           ) {
                    //             return {
                    //               ...item,
                    //               status: true,
                    //               answer: a,
                    //               MyQ,
                    //             };
                    //           } else {
                    //             return item;
                    //           }
                    //         }
                    //       ),
                    //     };
                    //   }

                    //   return item;
                    // })
                    // console.log('====================================');
                    const a = answer.map((e) => {
                      return e.type == "text"
                        ? {
                          ...e,
                          answer: e.answer.sort((a, b) => {
                            if (a.number > b.number) {
                              return 1;
                            } else if (a.number < b.number) {
                              return -1;
                            } else {
                              return 0;
                            }
                          }),
                        }
                        : { ...e };
                    });
                    setTimeout(async () => {
                      const response = await apiservice({
                        path: "/start-course/update/" + testStates.id,
                        method: "put",
                        body: {
                          progress: testStates.progress.map((item, i) => {
                            if (i == testStates.Mainindex) {
                              return {
                                ...item,
                                MyQ,
                                listen_detail: item.listen_detail.map(
                                  (items, ins) => {
                                    if (ins == testStates.index && ins == 0) {
                                      return {
                                        ...items,
                                        status: true,
                                        answer: a,
                                        MyQ,
                                      };
                                    } else if (
                                      ins !== 0 &&
                                      ins == testStates.index &&
                                      item.listen_detail[ins - 1].status == true
                                    ) {
                                      return {
                                        ...items,
                                        status: true,
                                        answer: a,
                                        MyQ,
                                      };
                                    } else {
                                      return items;
                                    }
                                  }
                                ),
                              };
                            } else {
                              return {
                                ...item,
                              };
                            }
                          }),
                        },
                        token: token?.accessToken,
                      });
                      const response1 = await apiservice({
                        path: "/start-course/createfinal",
                        method: "post",
                        body: {
                          course_id: testStates.course_id,
                          type: "TEST",
                          totals: a.filter(
                            (items) =>
                              items.answer == items.totals || items.answer
                          ).length,
                        },
                        token: token?.accessToken,
                      });
                      setTimeout(() => {
                        checkStartFinish();
                        checkStart();
                      }, 1000);
                      setModalVisible(!modalVisible);
                      setTimeout(() => {
                        setModalVisible1(true);
                      }, 1500);
                    }, 1500);
                  }}
                  style={styles.buttonModal}
                >
                  <Text style={styles.textButtonModal}>ตกลง</Text>
                </TouchableOpacity>
                <TouchableOpacity
                  onPress={() => {
                    setModalVisible(!modalVisible);
                  }}
                  style={styles.buttonModal1}
                >
                  <Text
                    style={{
                      fontFamily: "Prompt",
                      fontSize: 30,
                      color: "#0D88F0",
                    }}
                  >
                    ยกเลิก
                  </Text>
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </Modal>
        <Modal
          transparent={true}
          visible={modalVisible1}
          onRequestClose={() => {
            Alert.alert("Modal has been closed.");
            setModalVisible1(!modalVisible1);
          }}
        >
          <View style={styles.viewModal}>
            <View style={styles.viewDetailModal}>
              <View style={{ alignSelf: "center", alignItems: "center" }}>
                <Text
                  style={{
                    fontFamily: "Prompt",
                    fontSize: 25,
                    color: "#0B2051",
                  }}
                >
                  คุณทำได้
                </Text>
                <Text
                  style={{
                    fontFamily: "Prompt",
                    fontSize: 70,
                    color: "#0B2051",
                    marginVertical: 40,
                  }}
                >
                  {score == null ? "0 %" : score + " %"}
                </Text>
                <TouchableOpacity
                  onPress={async () => {
                    if (
                      testStates.conditions.retest &&
                      parseInt(testStates.conditions.percent) >
                      (
                        (answer
                          .filter(
                            (ele, ind) =>
                              ind ===
                              answer.findIndex(
                                (elem) => elem.number === ele.number
                              )
                          )
                          .filter((items) => {
                            if (
                              MyQ.filter(
                                (val, indexs) => indexs == items.number
                              )[0].type == "1"
                            ) {
                              return (
                                MyQ.filter(
                                  (val, indexs) => indexs == items.number
                                )[0].answer == items.answer
                              );
                            }
                            if (
                              MyQ.filter(
                                (val, indexs) => indexs == items.number
                              )[0].type == "2"
                            ) {
                              try {
                                return (
                                  MyQ.filter(
                                    (val, indexs) => indexs == items.number
                                  )[0].answer.filter(
                                    (e) =>
                                      items.answer.filter((ev) => e == ev)
                                        .length > 0
                                  ).length ==
                                  MyQ.filter(
                                    (val, indexs) => indexs == items.number
                                  )[0].answer.length
                                );
                              } catch (error) {
                                return false;
                              }
                            }
                            if (
                              MyQ.filter(
                                (val, indexs) => indexs == items.number
                              )[0].type == "3"
                            ) {
                              return MyQ.filter(
                                (val, indexs) => indexs == items.number
                              )[0].answer != null
                                ? MyQ.filter(
                                  (val, indexs) => indexs == items.number
                                )[0].answer == items.answer
                                : items.answer == 0
                                  ? true
                                  : false;
                            }
                            if (
                              MyQ.filter(
                                (val, indexs) => indexs == items.number
                              )[0].type == "4"
                            ) {
                              return (
                                MyQ.filter(
                                  (val, indexs) => indexs == items.number
                                )[0].answer.filter(
                                  (e) =>
                                    items.answer.filter(
                                      (ev) => e.answer == ev.answer
                                    ).length > 0
                                ).length ==
                                MyQ.filter(
                                  (val, indexs) => indexs == items.number
                                )[0].answer.length
                              );
                            }
                            if (
                              MyQ.filter(
                                (val, indexs) => indexs == items.number
                              )[0].type == "5"
                            ) {
                              try {
                                return (
                                  MyQ.filter(
                                    (val, indexs) => indexs == items.number
                                  )[0].choice.filter(
                                    (e) =>
                                      items.answer
                                        .sort((a, b) => b.number - a.number)
                                        .filter((ev) => e == ev.ans).length >
                                      0
                                  ).length ==
                                  MyQ.filter(
                                    (val, indexs) => indexs == items.number
                                  )[0].choice.length
                                );
                              } catch (error) { }
                            }

                            return false;
                          }).length /
                          MyQ.length) *
                        100
                      ).toFixed(0)
                    ) {
                      const response = await apiservice({
                        path: "/start-course/update/" + Oncecourse.id,
                        method: "put",
                        body: {
                          progress: Oncecourse.progress.map((item, i) => {
                            if (i == 0) {
                              return {
                                ...item,
                                listen_detail: item.listen_detail.map(
                                  (items, ins) => {
                                    if (ins == testStates.index && ins == 0) {
                                      delete items?.MyQ;
                                      delete items?.answer;
                                      return {
                                        ...items,
                                        status: false,
                                      };
                                    } else if (
                                      ins !== 0 &&
                                      ins == testStates.index &&
                                      item.listen_detail[ins - 1].status == true
                                    ) {
                                      delete items?.MyQ;
                                      delete items?.answer;

                                      return {
                                        ...items,
                                        status: false,
                                      };
                                    } else {
                                      return items;
                                    }
                                  }
                                ),
                              };
                            } else {
                              return {
                                ...item,
                                listen_detail: item.listen_detail.map(
                                  (items, ins) => {
                                    if (
                                      ins == 0 &&
                                      ins == testStates.index &&
                                      Oncecourse.progress[i - 1].listen_detail[
                                        Oncecourse.progress[i - 1].listen_detail
                                          .length - 1
                                      ].status == true
                                    ) {
                                      delete items.MyQ;
                                      delete items.answer;

                                      return {
                                        ...items,
                                        status: false,
                                      };
                                    } else if (
                                      ins !== 0 &&
                                      ins == testStates.index &&
                                      item.listen_detail[ins - 1].status == true
                                    ) {
                                      delete items.MyQ;
                                      delete items.answer;

                                      return {
                                        ...items,
                                        status: false,
                                      };
                                    } else {
                                      return items;
                                    }
                                  }
                                ),
                              };
                            }
                          }),
                        },
                        token: token?.accessToken,
                      });

                      if (response.status == 200) {
                        setAnswer([]);
                        setMyQ(
                          testStates.conditions.randomquestion
                            ? testStates.question
                              .map((itens, index) => ({
                                ...itens,
                                random: Math.random(),
                              }))
                              .sort((a, b) => a.random - b.random)
                            : testStates.question
                        );
                        setPage(0);
                        setStatus(false);
                        setModalVisible1(!modalVisible1);
                      }
                    } else {
                      setUnLock(true);
                      setModalVisible1(!modalVisible1);
                    }
                  }}
                  style={{
                    width: 211,
                    height: 56,
                    backgroundColor: "#0D88F0",
                    borderRadius: 5,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Text style={styles.textButtonModal}>
                    {testStates.conditions.retest &&
                      parseInt(testStates.conditions.percent) >
                      (
                        (answer
                          .filter(
                            (ele, ind) =>
                              ind ===
                              answer.findIndex(
                                (elem) => elem.number === ele.number
                              )
                          )
                          .filter((items) => {
                            if (
                              MyQ.filter(
                                (val, indexs) => indexs == items.number
                              )[0].type == "1"
                            ) {
                              return (
                                MyQ.filter(
                                  (val, indexs) => indexs == items.number
                                )[0].answer == items.answer
                              );
                            }
                            if (
                              MyQ.filter(
                                (val, indexs) => indexs == items.number
                              )[0].type == "2"
                            ) {
                              try {
                                return (
                                  MyQ.filter(
                                    (val, indexs) => indexs == items.number
                                  )[0].answer.filter(
                                    (e) =>
                                      items.answer.filter((ev) => e == ev)
                                        .length > 0
                                  ).length ==
                                  MyQ.filter(
                                    (val, indexs) => indexs == items.number
                                  )[0].answer.length
                                );
                              } catch (error) {
                                return false;
                              }
                            }
                            if (
                              MyQ.filter(
                                (val, indexs) => indexs == items.number
                              )[0].type == "3"
                            ) {
                              return MyQ.filter(
                                (val, indexs) => indexs == items.number
                              )[0].answer != null
                                ? MyQ.filter(
                                  (val, indexs) => indexs == items.number
                                )[0].answer == items.answer
                                : items.answer == 0
                                  ? true
                                  : false;
                            }
                            if (
                              MyQ.filter(
                                (val, indexs) => indexs == items.number
                              )[0].type == "4"
                            ) {
                              return (
                                MyQ.filter(
                                  (val, indexs) => indexs == items.number
                                )[0].answer.filter(
                                  (e) =>
                                    items.answer.filter(
                                      (ev) => e.answer == ev.answer
                                    ).length > 0
                                ).length ==
                                MyQ.filter(
                                  (val, indexs) => indexs == items.number
                                )[0].answer.length
                              );
                            }
                            if (
                              MyQ.filter(
                                (val, indexs) => indexs == items.number
                              )[0].type == "5"
                            ) {
                              try {
                                return (
                                  MyQ.filter(
                                    (val, indexs) => indexs == items.number
                                  )[0].choice.filter(
                                    (e) =>
                                      items.answer
                                        .sort((a, b) => b.number - a.number)
                                        .filter((ev) => e == ev.ans).length > 0
                                  ).length ==
                                  MyQ.filter(
                                    (val, indexs) => indexs == items.number
                                  )[0].choice.length
                                );
                              } catch (error) { }
                            }
                          }).length /
                          MyQ.length) *
                        100
                      ).toFixed(0)
                      ? "สอบอีกครั้ง"
                      : "ยืนยัน"}
                  </Text>
                </TouchableOpacity>
                <Text style={styles.textDetailModal}>
                  ผ่านแบบทดสอบ {testStates.conditions.percent}%
                </Text>
              </View>
            </View>
          </View>
        </Modal>
        <Modal
          transparent={true}
          visible={modalVisible2}
          onRequestClose={() => {
            Alert.alert("Modal has been closed.");
            setModalVisible2(!modalVisible2);
          }}
        >
          <View style={styles.viewModal}>
            <View style={styles.viewDetailModal}>
              <View style={{ alignItems: "center" }}>
                <Image
                  style={{ width: 100, height: 100 }}
                  source={require("../img/Group 66.png")}
                />
                <Text style={[styles.textTitleModal, { marginTop: 40 }]}>
                  หมดเวลาทำแบบทดสอบ
                </Text>
                <TouchableOpacity
                  onPress={() => {
                    setModalVisible1(true);
                    setModalVisible2(false);
                  }}
                  style={[styles.buttonModal, { marginTop: 40 }]}
                >
                  <Text style={styles.textButtonModal}>ตกลง</Text>
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </Modal>
        <Modal
          transparent={true}
          visible={modalScore}
          onRequestClose={() => {
            Alert.alert("Modal has been closed.");
            setModalScore(!modalScore);
          }}
        >
          <View
            // activeOpacity={2}
            // onPress={() => {
            //   setModalScore(!modalScore);
            // }}
            style={[styles.viewModal, { zIndex: 99 }]}
          >
            <View
              style={[
                styles.viewDetailModal,
                { maxWidth: 600, maxHeight: 520 },
              ]}
            >
              <View style={{}}>
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    paddingTop: 20,
                  }}
                >
                  <Text
                    style={{
                      fontFamily: "Prompt",
                      fontSize: 30,
                      color: "#0B2051",
                    }}
                  >
                    ผลของคุณคือ
                  </Text>
                  <Text
                    style={{
                      fontFamily: "Prompt",
                      fontSize: 80,
                      color: "#0B2051",
                      marginLeft: 43,
                    }}
                  >
                    {sumScore?.TOTAL_EXAM}%
                  </Text>
                </View>
                <Text
                  style={{
                    fontFamily: "Prompt",
                    fontSize: 20,
                    color: "#0B2051",
                    alignSelf: "center",
                    marginBottom: 40,
                  }}
                >
                  สรุปการเรียนของคุณ
                </Text>
                <View style={{ flexDirection: "row" }}>
                  <View style={styles.viewDashedModal}>
                    <MaterialIcons
                      name="library-books"
                      size={40}
                      color="#0B2051"
                    />
                    <Text style={styles.text25}>
                      {sumScore?.DESCRIPTION}/{sumScore?.DESCRIPTION}
                    </Text>
                  </View>
                  <View
                    style={[
                      styles.viewDashedModal,
                      { justifyContent: "flex-end", borderRightWidth: 0 },
                    ]}
                  >
                    <MaterialIcons name="archive" size={40} color="#0B2051" />
                    <Text style={styles.text25}>
                      {sumScore?.WORK}/{sumScore?.WORK}
                    </Text>
                  </View>
                </View>
                <View style={{ flexDirection: "row" }}>
                  <View style={styles.viewDashedModal1}>
                    <MaterialIcons name="live-help" size={40} color="#0B2051" />
                    <Text style={styles.text25}>
                      {sumScore?.WRITING}/{sumScore?.WRITING}
                    </Text>
                  </View>
                  <View
                    style={{
                      flexDirection: "row",

                      justifyContent: "flex-end",
                      alignItems: "center",
                      width: "50%",
                    }}
                  >
                    <Text
                      style={{
                        fontFamily: "Prompt",
                        fontSize: 25,
                        color: "#0B2051",
                      }}
                    >
                      ผล
                    </Text>
                    {Oncecourse.Type == "APPROVE" ? (
                      <Text
                        style={{
                          fontFamily: "Prompt",
                          fontSize: 30,
                          color: "#74C745",
                          marginLeft: 25,
                        }}
                      >
                        ผ่าน
                      </Text>
                    ) : Oncecourse.Type == "PENDING" ? (
                      <Text
                        style={{
                          fontFamily: "Prompt",
                          fontSize: 30,
                          color: "#EBE330",
                          marginLeft: 25,
                        }}
                      >
                        รอผล
                      </Text>
                    ) : (
                      <Text
                        style={{
                          fontFamily: "Prompt",
                          fontSize: 30,
                          color: "red",
                          marginLeft: 25,
                        }}
                      >
                        ไม่ผ่าน
                      </Text>
                    )}
                  </View>
                </View>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "center",
                  marginVertical: 30,
                }}
              >
                {/* {Oncecourse.Type == "APPROVE" && courseStates.certificate && (
                  <TouchableOpacity
                    onPress={() => {
                      window.open(
                        `https://api.courseoncloud.com/api/image/export/${subjectTestStates.course_id}?uid=${users.uid}`
                      );
                      // https://api.courseoncloud.com/api/image/export/1?uid=32
                    }}
                    style={{
                      width: 141,
                      height: 44,
                      backgroundColor: "#0D88F0",
                      borderRadius: 5,
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 20,
                        fontFamily: "Prompt",
                        color: "#FFFFFF",
                      }}
                    >
                      Certificate
                    </Text>
                  </TouchableOpacity>
                )} */}
                <TouchableOpacity
                  onPress={async () => {
                    setModalScore(!modalScore);
                    setModalVisible1(false);
                    setUnLock(false);
                    const response = await apiservice({
                      path:
                        "/course/getall?page=1&lan=TH&course_id=" +
                        testStates.course_id,
                      method: "get",
                      //token: token?.accessToken,
                    });

                    setCourseStates(response.data.data4[0]);
                    setTimeout(() => {
                      navigation.navigate("Course");
                    }, 900);
                  }}
                  style={{
                    width: 141,
                    height: 44,
                    borderWidth: 1,
                    borderColor: "#0D88F0",
                    borderRadius: 5,
                    justifyContent: "center",
                    alignItems: "center",
                    // marginLeft: courseStates.certificate ? 60 : 0,
                  }}
                >
                  <Text
                    style={{
                      fontSize: 20,
                      fontFamily: "Prompt",
                      color: "#0D88F0",
                    }}
                  >
                    ดูคอร์ส
                  </Text>
                </TouchableOpacity>
              </View>
              {Oncecourse?.comment != null && (
                <Text
                  style={{
                    fontSize: 12,
                    fontFamily: "Prompt",
                    color: "#E82626",
                    alignSelf: "center",
                  }}
                >
                  {"***หมายเหตุ " + Oncecourse?.comment + " ***"}
                </Text>
              )}
              <Text
                style={{
                  fontSize: 12,
                  fontFamily: "Prompt",
                  color: "#E82626",
                  alignSelf: "center",
                }}
              >
                กรุณาติดต่อผู้สอนกรณีต้องการปรึกษาเรื่องผลการเรียน
              </Text>
            </View>
          </View>
        </Modal>
      </ScrollView>
    </View>
  );
}
const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: "100%",
    backgroundColor: "#FFFFFF",
  },
  viewProgress: {
    width: "100%",
    height: 70,
    flexDirection: "row",
    justifyContent: "space-between",
  },
  viewTextProgress: {
    flexDirection: "row",
    alignItems: "center",
    width: "30%",
  },
  textProgress: {
    fontFamily: "Prompt",
    fontSize: 12,
    color: "#FFFFFF",
    textAlign: "right",
  },
  buttonProgress: {
    width: 150,
    height: 40,
    borderRadius: 20,
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "center",
  },
  textSuccess: {
    fontFamily: "Prompt",
    fontSize: 12,
    color: "#FFF",
  },
  textChoice: {
    fontFamily: "Prompt",
    fontSize: 20,
    color: "#0B2051",
  },
  textTitle: {
    fontFamily: "Prompt",
    fontSize: 30,
    color: "#0B2051",
  },
  viewDetail: {
    borderBottomWidth: 1,
    borderBottomColor: "#0D88F0",
    paddingVertical: 45,
    marginBottom: 49,
    alignItems: "center",
    flexDirection: "row",
  },
  flatlist: {
    borderBottomWidth: 1,
    borderBottomColor: "#0D88F0",
    paddingBottom: 45,
    marginBottom: 49,
  },
  viewModal: {
    width: "100%",
    height: "100%",
    backgroundColor: "#000000ee",
    justifyContent: "center",
  },
  viewDetailModal: {
    width: "100%",
    maxWidth: 650,
    height: "100%",
    maxHeight: 400,
    backgroundColor: "#FFFFFF",
    borderWidth: 1,
    borderColor: "#0D88F0",
    padding: 50,
    paddingHorizontal: width * 0.05,
    alignSelf: "center",
    justifyContent: "center",
  },
  buttonModal: {
    width: 150,
    height: 50,
    backgroundColor: "#0D88F0",
    borderRadius: 5,
    justifyContent: "center",
    alignItems: "center",
  },
  textButtonModal: {
    fontFamily: "Prompt",
    fontSize: 30,
    color: "#FFFFFF",
  },
  textTitleModal: {
    fontFamily: "Prompt",
    fontSize: 40,
    color: "#0B2051",
    alignSelf: "center",
    textAlign: "center",
  },
  buttonModal1: {
    width: 150,
    height: 50,
    borderWidth: 1,
    borderColor: "#0D88F0",
    borderRadius: 5,
    justifyContent: "center",
    alignItems: "center",
  },
  textDetailModal: {
    fontFamily: "Prompt",
    fontSize: 18,
    color: "#0B2051",
  },
  text25: {
    fontFamily: "Prompt",
    fontSize: 25,
    color: "#0B2051",
    marginLeft: 25,
  },
  viewDashedModal: {
    flexDirection: "row",
    borderRightWidth: 2,
    borderBottomWidth: 2,
    borderRightColor: "#0D88F0",
    borderBottomColor: "#0D88F0",
    width: "50%",
    borderStyle: "dashed",
    alignItems: "center",
    paddingVertical: 20,
  },
  viewDashedModal1: {
    flexDirection: "row",
    borderRightWidth: 2,
    borderRightColor: "#0D88F0",
    width: "50%",
    borderStyle: "dashed",
    alignItems: "center",
    paddingVertical: 20,
  },
});
