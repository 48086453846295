import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  Image,
  Dimensions,
  Animated,
  TouchableOpacity,
  StyleSheet,
  ScrollView,
  TextInput,
  FlatList,
} from "react-native";
import {
  MaterialCommunityIcons,
  MaterialIcons,
  Feather,
  Ionicons,
  FontAwesome,
} from "@expo/vector-icons";
import "react-calendar/dist/Calendar.css";
import NumberFormat from "react-number-format";
import Calendar from "react-calendar";
import HeaderAdmin from "../../components/headerAdmin";
import ButtonMenuSuperAdmin from "../../components/buttonMenuSuperAdmin";
import Modal from "modal-react-native-web";
import {
  authActionCreateUniversity,
  CreateDiscount,
  deleteUnversity,
} from "../../../action/authAction";
import { useRecoilState } from "recoil";
import { dataUniversityState, tokenState } from "../../../reducer/recoil";
import { useIsFocused } from "@react-navigation/native";
import { apiservice } from "../../../service";
import moment from "moment";
import RNPickerSelect from "react-native-picker-select";
import ProfileAdmin from "../../components/profileAdmin";
import { useWindowSize } from "../../components/responsive";
const { width, height } = Dimensions.get("window");
export default function main({ navigation, route }) {
  const [dataUniversity, setDataUniversity] =
    useRecoilState(dataUniversityState);
  const [modalAdd, setModalAdd] = useState(false);
  const [check, setCheck] = useState(false);
  const [percent, setPercent] = useState(false);
  const [start, setStart] = useState(false);
  const [dateStart, setDateStart] = useState(null);
  const [end, setEnd] = useState(false);
  const [dateEnd, setDateEnd] = useState(null);
  const isfocused = useIsFocused();
  const [token, setToken] = useRecoilState(tokenState);
  const [data, setData] = useState();

  const [openMenu, setOpenMenu] = useState(false);
  const [id, setId] = useState();
  const [modalDelItem, setModalDelItem] = useState(false);
  const [resultPercent, setResultPercent] = useState(null);
  const [state, setState] = useState({
    code: "",
    name: "",
    is_count: null,
    count: null,
    startDate: "",
    endDate: "",
    percent: null,
    owner_id: 1,
  });

  useEffect(() => {
    if (isfocused) {
      getCode();

      getProfile(token);
    }
  }, [token, isfocused]);
  async function getProfile(token) {
    try {
      const response = await apiservice({
        path: "/authen/user",
        method: "get",
        token: token?.accessToken,
      });

      if (response.status == 200) {
        setState((val) => ({ ...val, owner_id: response?.data?.result?.id }));
      }
    } catch (error) {
      throw error;
    }
  }
  async function getCode() {
    try {
      const response = await apiservice({
        path: "/quiz/discount/get",
        method: "get",
        // token: token?.accessToken,
      });

      if (response.status == 200) {
        setData(response.data);
      }
    } catch (error) {
      throw error;
    }
  }
  var dd = moment.locale("th");

  async function Add() {
    const add = await CreateDiscount({
      token: token?.accessToken,
      state,
    });
    if (add) {
      setModalAdd(false);
      getCode();
    }
  }
  const size = useWindowSize();
  const originalList = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
    22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40,
    41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59,
    60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78,
    79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97,
    98, 99, 100,
  ];

  async function Delete() {
    const response = await apiservice({
      path: "/quiz/discount/delete?id=" + id,
      method: "get",
      // token: token?.accessToken,
    });
  }

  return (
    <View style={styles.container}>
      <HeaderAdmin
        onPress={() => {
          setOpenMenu((val) => !val);
        }}
        navigation={navigation}
      />
      <View style={{ width: "100%", flexDirection: "row", height: "100%" }}>
        {openMenu && (
          <View //menu
            style={{
              width: "13%",

              minWidth: 220,
              height: "100%",
              backgroundColor: "#444444",
              position: "absolute",
              zIndex: 99,
            }}
          >
            <View style={{ height: "80%" }}>
              {(token?.role == "SUPERADMIN" ||
                token?.role == "GENERALADMIN") && (
                <ButtonMenuSuperAdmin
                  p1
                  onPress={() => {
                    setOpenMenu(false);
                    navigation.navigate("UniversityCourseSA");
                  }}
                />
              )}
              {(token?.role == "SUPERADMIN" ||
                token?.role == "GENERALADMIN") && (
                <ButtonMenuSuperAdmin
                  p2
                  onPress={() => {
                    setOpenMenu(false);
                    navigation.navigate("GeneralCourseSA");
                  }}
                />
              )}
              {(token?.role == "SUPERADMIN" ||
                token?.role == "GENERALADMIN") && (
                <ButtonMenuSuperAdmin
                  p3
                  onPress={() => {
                    setOpenMenu(false);
                    navigation.navigate("AddAdminSA");
                  }}
                />
              )}
              {(token?.role == "ACCOUNTING" || token?.role == "SUPERADMIN") && (
                <ButtonMenuSuperAdmin
                  p4
                  onPress={() => {
                    setOpenMenu(false);
                    navigation.navigate("ReportSA");
                  }}
                />
              )}
              {(token?.role == "SUPERADMIN" ||
                token?.role == "GENERALADMIN") && (
                <ButtonMenuSuperAdmin
                  p5
                  onPress={() => {
                    setOpenMenu(false);
                    navigation.navigate("SlideBannerSA");
                  }}
                />
              )}
              {(token?.role == "SUPERADMIN" ||
                token?.role == "GENERALADMIN") && (
                <ButtonMenuSuperAdmin
                  p6
                  color
                  border
                  onPress={() => {
                    setOpenMenu(false);
                    navigation.navigate("Discount");
                  }}
                />
              )}
              {(token?.role == "SUPERADMIN" ||
                token?.role == "GENERALADMIN") && (
                <ButtonMenuSuperAdmin
                  p7
                  onPress={() => {
                    setOpenMenu(false);
                    navigation.navigate("Account");
                  }}
                />
              )}
            </View>
            <ProfileAdmin />
          </View>
        )}
        {size.width >= 1280 && (
          <View //menu
            style={{
              width: "13%",

              minWidth: 220,
              height: "100%",
              backgroundColor: "#444444",
            }}
          >
            <View style={{ height: "80%" }}>
              {(token?.role == "SUPERADMIN" ||
                token?.role == "GENERALADMIN") && (
                <ButtonMenuSuperAdmin
                  p1
                  onPress={() => {
                    navigation.navigate("UniversityCourseSA");
                  }}
                />
              )}
              {(token?.role == "SUPERADMIN" ||
                token?.role == "GENERALADMIN") && (
                <ButtonMenuSuperAdmin
                  p2
                  onPress={() => {
                    navigation.navigate("GeneralCourseSA");
                  }}
                />
              )}
              {(token?.role == "SUPERADMIN" ||
                token?.role == "GENERALADMIN") && (
                <ButtonMenuSuperAdmin
                  p3
                  onPress={() => {
                    navigation.navigate("AddAdminSA");
                  }}
                />
              )}
              {(token?.role == "ACCOUNTING" || token?.role == "SUPERADMIN") && (
                <ButtonMenuSuperAdmin
                  p4
                  onPress={() => {
                    navigation.navigate("ReportSA");
                  }}
                />
              )}
              {(token?.role == "SUPERADMIN" ||
                token?.role == "GENERALADMIN") && (
                <ButtonMenuSuperAdmin
                  p5
                  onPress={() => {
                    navigation.navigate("SlideBannerSA");
                  }}
                />
              )}
              {(token?.role == "SUPERADMIN" ||
                token?.role == "GENERALADMIN") && (
                <ButtonMenuSuperAdmin
                  p6
                  color
                  border
                  onPress={() => {
                    navigation.navigate("Discount");
                  }}
                />
              )}
              {(token?.role == "SUPERADMIN" ||
                token?.role == "GENERALADMIN") && (
                <ButtonMenuSuperAdmin
                  p7
                  onPress={() => {
                    navigation.navigate("Account");
                  }}
                />
              )}
            </View>
            <ProfileAdmin />
          </View>
        )}
        <View
          style={[
            styles.viewDetail,
            {
              paddingHorizontal: size.width < 500 ? 20 : 55,
              width: size.width < 1280 ? "100%" : "87%",
            },
          ]}
        >
          <View
            style={{ flexDirection: "row", justifyContent: "space-between" }}
          >
            <Text
              style={{ fontSize: 23, fontFamily: "Prompt", color: "#808D93" }}
            >
              รายการโค้ดส่วนลด
            </Text>
            <TouchableOpacity
              onPress={() => {
                setModalAdd(true);
              }}
              style={styles.buttonAdd}
            >
              <Text style={styles.text16W}>+ Add New</Text>
            </TouchableOpacity>
          </View>
          <ScrollView horizontal>
            <View
              style={{
                width:
                  size.width < 500
                    ? size.width * 2.5
                    : size.width < 1280
                    ? size.width * 1.0
                    : size.width * 0.78,
                // minHeight: height * 2.5,
              }}
            >
              <View style={styles.viewSubject}>
                <Text
                  style={[
                    styles.text16B,
                    { width: "15%", textAlign: "left", paddingLeft: 67 },
                  ]}
                >
                  ชื่อ
                </Text>
                <Text
                  style={[
                    styles.text16B,
                    { width: "15%", textAlign: "left", paddingLeft: 67 },
                  ]}
                >
                  โค้ด
                </Text>
                <Text style={[styles.text16B, { width: "15%" }]}>
                  จำนวนคงเหลือ(ครั้ง)
                </Text>
                <Text style={[styles.text16B, { width: "25%" }]}>ระยะเวลา</Text>
                <Text style={[styles.text16B, { width: "15%" }]}>
                  เปอร์เซ็นการลด
                </Text>
                <Text style={[styles.text16B, { width: "10%" }]}>ผู้สร้าง</Text>
                <Text style={[styles.text16B, { width: "5%" }]}></Text>
              </View>
              <FlatList
                numColumns={1}
                data={data}
                renderItem={({ item, index }) => {
                  return (
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        borderBottomWidth: 1,
                        borderBottomColor: "#E5E5E5",
                        height: 50,
                        alignItems: "center",
                      }}
                    >
                      <Text
                        style={[
                          styles.text16B,
                          {
                            width: "15%",
                            textAlign: "left",
                            paddingLeft: 67,
                            fontSize: size.width < 1400 ? 14 : 16,
                          },
                        ]}
                      >
                        {item?.name}
                      </Text>
                      <Text
                        style={[
                          styles.text16B,
                          {
                            width: "15%",
                            textAlign: "left",
                            paddingLeft: 67,
                            fontSize: size.width < 1400 ? 14 : 16,
                          },
                        ]}
                      >
                        {item?.code}
                      </Text>
                      <Text
                        style={[
                          styles.text16B,
                          {
                            width: "15%",
                            fontSize: size.width < 1400 ? 14 : 16,
                          },
                        ]}
                      >
                        {item?.is_count == false ? "ไม่จำกัด" : item?.count}
                      </Text>
                      <Text
                        style={[
                          styles.text16B,
                          {
                            width: "25%",
                            fontSize: size.width < 1400 ? 14 : 16,
                          },
                        ]}
                      >
                        {moment(item?.startDate)
                          .add(543, "year")
                          .format("DD MMMM YYYY") +
                          " - " +
                          moment(item?.endDate)
                            .add(543, "year")
                            .format("DD MMMM YYYY")}
                      </Text>
                      <Text
                        style={[
                          styles.text16B,
                          {
                            width: "15%",
                            fontSize: size.width < 1400 ? 14 : 16,
                          },
                        ]}
                      >
                        {item?.percent + " %"}
                      </Text>
                      <Text
                        style={[
                          styles.text16B,
                          {
                            width: "10%",
                            fontSize: size.width < 1400 ? 14 : 16,
                          },
                        ]}
                      >
                        {item?.owner_info?.username}
                      </Text>
                      <View style={{ width: "5%" }}>
                        <TouchableOpacity
                          onPress={() => {
                            setId(item.id);
                            setModalDelItem(true);
                          }}
                        >
                          <MaterialCommunityIcons
                            name="delete"
                            size={24}
                            color="black"
                          />
                        </TouchableOpacity>
                      </View>
                    </View>
                  );
                }}
              />
            </View>
          </ScrollView>
        </View>
      </View>
      <Modal
        transparent={true}
        visible={modalAdd}
        onRequestClose={() => {
          Alert.alert("Modal has been closed.");
          setModalAdd(!modalAdd);
        }}
      >
        <View style={styles.bgModal}>
          <View
            style={[
              styles.viewDetailModal,
              {
                width:
                  size.width < 500 ? "90%" : size.width < 1280 ? "70%" : "50%",
              },
            ]}
          >
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Text style={styles.textModal}>ชื่อ</Text>
              <TextInput
                onChangeText={(text) => {
                  setState({ ...state, name: text });
                }}
                style={{
                  width: "75%",
                  height: 50,
                  borderWidth: 1,
                  borderColor: "#B8B8B8",
                  borderRadius: 5,
                  paddingHorizontal: 10,
                  fontSize: 16,
                  fontFamily: "Prompt",
                }}
              />
            </View>
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: 48,
              }}
            >
              <Text style={styles.textModal}>จำนวนครั้ง</Text>
              <View
                style={{
                  width: "75%",
                  flexDirection: size.width < 500 ? "column" : "row",
                  justifyContent: "space-between",
                }}
              >
                <FlatList
                  scrollEnabled={false}
                  showsHorizontalScrollIndicator={false}
                  horizontal
                  data={[
                    { name: "ไม่จำกัด", result: false },
                    { name: "จำกัด", result: true },
                  ]}
                  renderItem={({ item, index }) => {
                    return (
                      <TouchableOpacity
                        onPress={() => {
                          setState((val) => ({
                            ...val,
                            is_count: item.result,
                          }));
                          // setTimeout(() => {
                          //   if (state.is_count == false) {
                          //     setState((val) => ({
                          //       ...val,
                          //       count: null,
                          //     }));
                          //   }
                          // }, 300);
                        }}
                        style={{
                          flexDirection: "row",
                          alignItems: "center",
                          marginLeft: index == 0 ? 0 : 20,
                        }}
                      >
                        <MaterialIcons
                          name={
                            state.is_count == item.result
                              ? "radio-button-checked"
                              : "radio-button-unchecked"
                          }
                          size={24}
                          color="#B8B8B8"
                          style={{ marginRight: 5 }}
                        />
                        <Text style={styles.textModal}>{item.name}</Text>
                      </TouchableOpacity>
                    );
                  }}
                />
                <View
                  // value={state.count}
                  // editable={
                  //   state.is_count == false || state.is_count == null
                  //     ? false
                  //     : true
                  // }
                  // onChangeText={(text) =>
                  // setState((val) => ({
                  //   ...val,
                  //   count: text,
                  // }))
                  // }
                  style={{
                    width:
                      size.width < 500
                        ? "100%"
                        : size.width < 1500
                        ? "40%"
                        : "50%",
                    height: 50,
                    borderWidth: 1,
                    borderColor: "#B8B8B8",
                    borderRadius: 5,

                    fontSize: 16,
                    fontFamily: "Prompt",
                  }}
                >
                  <NumberFormat
                    disabled={
                      state.is_count == false || state.is_count == null
                        ? true
                        : false
                    }
                    value={state.count}
                    onValueChange={(values) => {
                      setState((val) => ({
                        ...val,
                        count: values.value,
                      }));
                    }}
                    thousandSeparator={true}
                    style={{
                      borderWidth: 0,
                      fontSize: 16,
                      fontFamily: "Prompt",
                      height: 50,
                      paddingLeft: 10,
                    }}
                  />
                </View>
              </View>
            </View>
            <View
              style={{
                flexDirection: size.width < 500 ? "column" : "row",
                justifyContent: "space-between",
                alignItems: size.width < 500 ? "flex-start" : "center",
                marginTop: 48,
              }}
            >
              <Text style={styles.textModal}>ระยะเวลา</Text>
              <View
                style={{
                  width: size.width < 500 ? "100%" : "75%",
                  alignItems: "center",
                  flexDirection: size.width < 500 ? "column" : "row",
                  justifyContent: "space-between",
                }}
              >
                <View style={{ width: size.width < 500 ? "100%" : "42%" }}>
                  <View
                    style={{
                      width: "100%",
                      height: 50,
                      borderWidth: 1,
                      borderColor: "#B8B8B8",
                      borderRadius: 5,
                      paddingHorizontal: 10,
                      justifyContent: "center",
                    }}
                  >
                    <Text style={[styles.textModal, { fontSize: 16 }]}>
                      {dateStart == null ? "" : dateStart}
                    </Text>
                    <TouchableOpacity
                      onPress={() => {
                        setStart((val) => !val);
                      }}
                      style={{ position: "absolute", right: 5 }}
                    >
                      <MaterialIcons
                        name="calendar-today"
                        size={24}
                        color="#4D4D4D"
                      />
                    </TouchableOpacity>
                  </View>
                  {start && (
                    <View
                      style={{
                        position: "absolute",
                        zIndex: 99,
                        width: "100%",
                        marginTop: 50,
                      }}
                    >
                      <Calendar
                        minDate={new Date()}
                        onChange={(dd) => {
                          setState((val) => ({
                            ...val,
                            startDate: moment(dd).toISOString(),
                          }));
                          setDateStart(moment(dd).format("DD/MM/YYYY"));
                          setStart(false);
                        }}
                        // value={body.startDate}
                      />
                    </View>
                  )}
                </View>
                <Text
                  style={{
                    marginHorizontal: 20,
                    fontFamily: "Prompt",
                    fontSize: 22,
                  }}
                >
                  ถึง
                </Text>
                <View
                  style={{
                    width: size.width < 500 ? "100%" : "42%",
                    zIndex: -1,
                  }}
                >
                  <View
                    style={{
                      width: "100%",
                      height: 50,
                      borderWidth: 1,
                      borderColor: "#B8B8B8",
                      borderRadius: 5,
                      paddingHorizontal: 10,
                      justifyContent: "center",
                    }}
                  >
                    <Text style={[styles.textModal, { fontSize: 16 }]}>
                      {dateEnd == null ? "" : dateEnd}
                    </Text>
                    <TouchableOpacity
                      onPress={() => {
                        setEnd((val) => !val);
                      }}
                      style={{ position: "absolute", right: 5 }}
                    >
                      <MaterialIcons
                        name="calendar-today"
                        size={24}
                        color="#4D4D4D"
                      />
                    </TouchableOpacity>
                  </View>
                  {end && (
                    <View
                      style={{
                        position: "absolute",
                        zIndex: 99,
                        width: "100%",
                        marginTop: 50,
                      }}
                    >
                      <Calendar
                        minDate={new Date()}
                        onChange={(dd) => {
                          setState((val) => ({
                            ...val,
                            endDate: moment(dd).toISOString(),
                          }));
                          setDateEnd(moment(dd).format("DD/MM/YYYY"));
                          setEnd(false);
                        }}
                        // value={body.startDate}
                      />
                    </View>
                  )}
                </View>
              </View>
            </View>
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: 48,
                zIndex: -1,
              }}
            >
              <Text style={styles.textModal}>เปอร์เซ็นการลด</Text>
              <View style={{ width: size.width < 500 ? "50%" : "75%" }}>
                <TouchableOpacity
                  onPress={() => {
                    setPercent((val) => !val);
                  }}
                  style={{
                    width: "100%",
                    height: 50,
                    borderWidth: 1,
                    borderColor: "#B8B8B8",
                    borderRadius: 5,
                    paddingHorizontal: 10,
                    justifyContent: "center",
                  }}
                >
                  <FontAwesome
                    name="caret-down"
                    size={24}
                    color="black"
                    style={{ position: "absolute", right: 10 }}
                  />
                  <Text style={{ fontSize: 16, fontFamily: "Prompt" }}>
                    {resultPercent == null ? "" : resultPercent + " %"}
                  </Text>
                </TouchableOpacity>
                {percent && (
                  <View
                    style={{
                      height: size.height / 4,
                      width: "100%",
                      position: "absolute",
                      backgroundColor: "#FFF",
                      borderWidth: 1,
                      borderColor: "#B8B8B8",
                      marginTop: 50,
                      zIndex: 99,
                    }}
                  >
                    <FlatList
                      numColumns={1}
                      data={originalList}
                      renderItem={({ item, index }) => {
                        return (
                          <TouchableOpacity
                            onPress={() => {
                              setState((val) => ({
                                ...val,
                                percent: item,
                              }));
                              setResultPercent(item);
                              setPercent(false);
                            }}
                            style={{
                              alignItems: "center",
                            }}
                          >
                            <Text style={styles.textModal}>{item} %</Text>
                          </TouchableOpacity>
                        );
                      }}
                    />
                  </View>
                )}
              </View>
            </View>
            <View
              style={{
                flexDirection: "row",
                alignSelf: "center",
                marginTop: 49,
                zIndex: -2,
              }}
            >
              <TouchableOpacity
                onPress={() => {
                  setModalAdd(!modalAdd);
                }}
                style={styles.buttonModal}
              >
                <Text
                  style={[
                    styles.textButtonModal,
                    {
                      color: "#444444",
                    },
                  ]}
                >
                  ยกเลิก
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={Add}
                style={[
                  styles.buttonModal,
                  {
                    backgroundColor: "#0D88F0",
                    marginLeft: width * 0.05,
                  },
                ]}
              >
                <Text
                  style={[
                    styles.textButtonModal,
                    {
                      color: "#FFFFFF",
                    },
                  ]}
                >
                  ยืนยัน
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </Modal>
      <Modal
        transparent={true}
        visible={modalDelItem}
        onRequestClose={() => {
          Alert.alert("Modal has been closed.");
          setModalDelItem(!modalDelItem);
        }}
      >
        <View style={styles.bgModal}>
          <View
            style={[
              styles.viewDetailModal,
              {
                width:
                  size.width < 500 ? "80%" : size.width < 1280 ? "70%" : "30%",
                height: 183,
                paddingVertical: 25,
              },
            ]}
          >
            <Text
              style={[
                styles.textButtonModal,
                { color: "#444444", alignSelf: "center" },
              ]}
            >
              คุณต้องการจะลบใช่รึไม่
            </Text>
            <View
              style={{
                flexDirection: "row",
                alignSelf: "center",
                marginTop: 39,
              }}
            >
              <TouchableOpacity
                onPress={() => setModalDelItem(!modalDelItem)}
                style={styles.buttonModal}
              >
                <Text
                  style={[
                    styles.textButtonModal,
                    {
                      color: "#444444",
                    },
                  ]}
                >
                  ยกเลิก
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => {
                  Delete();
                  setTimeout(() => {
                    getCode();
                  }, 500);
                  setModalDelItem(!modalDelItem);
                }}
                style={[
                  styles.buttonModal,
                  {
                    backgroundColor: "#0D88F0",
                    marginLeft: width * 0.05,
                  },
                ]}
              >
                <Text
                  style={[
                    styles.textButtonModal,
                    {
                      color: "#FFFFFF",
                    },
                  ]}
                >
                  ยืนยัน
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </Modal>
    </View>
  );
}
const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: "100%",
    backgroundColor: "#FFFFFF",
  },
  text16W: {
    fontFamily: "Prompt",
    fontSize: 16,
    color: "#FFFFFF",
  },
  text16B: {
    fontFamily: "Prompt",
    fontSize: 16,
    color: "#000000",
  },
  buttonAdd: {
    width: 140,
    height: 40,
    backgroundColor: "#0D88F0",
    borderRadius: 20,
    alignItems: "center",
    justifyContent: "center",
    alignSelf: "flex-end",
  },
  viewSubject: {
    width: "100%",
    height: 51,
    backgroundColor: "#E5E5E5",
    marginTop: 35,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  viewDetail: {
    width: "87%",
    height: "100%",
    paddingHorizontal: 55,
    paddingVertical: 28,
  },
  bgModal: {
    width: "100%",
    height: "100%",
    backgroundColor: "#000000bb",
    justifyContent: "center",
  },
  viewDetailModal: {
    width: "50%",

    backgroundColor: "#FFFFFF",
    borderRadius: 20,
    alignSelf: "center",
    paddingHorizontal: width * 0.05,
    paddingVertical: 50,
  },
  buttonModal: {
    width: 102,
    height: 48,
    borderRadius: 4,
    backgroundColor: "#EFEFF3",
    justifyContent: "center",
    alignItems: "center",
  },
  textButtonModal: {
    fontSize: 25,
    fontFamily: "Prompt",
  },
  textModal: {
    fontSize: 22,
    fontFamily: "Prompt",
  },
});
