import React, { useEffect, useState } from "react";
import {
  View,
  Text,
  Dimensions,
  TouchableOpacity,
  StyleSheet,
  FlatList,
  Image,
} from "react-native";
import { useWindowSize } from "../components/responsive";
import { apiservice } from "../../service";
import { tokenState, userState } from "../../reducer/recoil";
import { useRecoilState } from "recoil";
const { width, height } = Dimensions.get("window");
import Template, { SImage, useImages } from "../page/template";
import { Stage, Layer } from "react-konva";
import {
  exportComponentAsJPEG,
  exportComponentAsPDF,
  exportComponentAsPNG,
} from "react-component-export-image";
import moment from "moment";

export default function announcement({ navigation }) {
  const [token, setToken] = useRecoilState(tokenState);
  const [DATA3, setData3] = useState([]);
  const [image, setImage] = useState("");
  const [courseName, setCourseName] = useState("");
  const [StartName, setStartName] = useState("");
  const [EndName, setEndName] = useState("");
  const [Teachername, setTeacherName] = useState("");
  const [isCourseName, setisCourseName] = useState(false);
  const [isTeacherName, setisTeacherName] = useState(false);
  const [isStudentName, setisStudentName] = useState(false);
  const [isDateStart, setisDateStart] = useState(false);
  const [isDateEnd, setisDateEnd] = useState(false);

  const [state, setstate] = useState({
    sizeDetail: "900X600",
    updatedAt: "2021-10-18T18:35:22.079Z",
    createdAt: "2021-10-18T18:35:22.079Z",
    imgRef: "1234567",
    courseInfo: {
      x: 100,
      y: 100,
    },
    teacherInfo: {
      x: 100,
      y: 100,
    },
    studentInfo: {
      x: 100,
      y: 100,
    },
    startEducationInfo: {
      x: 100,
      y: 100,
    },
    endEducationInfo: {
      x: 100,
      y: 100,
    },
    cssstyle: {
      fontFamily: "",
      fontSize: "",
      color: "#000",
    },
  });
  const size = useWindowSize();
  const [users, setUser] = useRecoilState(userState);

  const ref = React.createRef();
  const [profile, setProfile] = useState();

  async function getProfile(token) {
    const response = await apiservice({
      path: "/authen/user",
      method: "get",
      token: token?.accessToken,
    });
    if (response.status == 200) {
      setProfile(response?.data?.result);
    }
  }
  async function callApi() {
    const res = await apiservice({
      path: "/course/getcer",
      method: "get",
      token: token?.accessToken,
    });
    if (res.status == 200) {
      setData3(res.data);
    }
  }

  useEffect(() => {
    callApi();
    getProfile(token);
  }, []);

  useEffect(() => {
    if (image.length > 0) {
      setTimeout(() => {
        exportComponentAsPNG(ref);
        setImage("");
      }, 300);
    }
  }, [image]);

  return (
    <View style={{ minHeight: 1040, paddingHorizontal: 50 }}>
      <Text
        style={[
          styles.textTitle,
          { marginTop: 45, fontSize: size.width < 750 ? 20 : 25 },
        ]}
      >
        {DATA3.length == 0 ? "คุณยังไม่มีใบประกาศ" : "ชื่อ"}
      </Text>
      <FlatList
        data={DATA3}
        numColumns={1}
        scrollEnabled={false}
        renderItem={({ index, item }) => {
          return (
            item.course.certificate && (
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  paddingVertical: 25,
                  borderTopWidth: index == 0 && 1,
                  borderBottomWidth: 1,
                  borderTopColor: "#BBBBBB",
                  borderBottomColor: "#BBBBBB",
                }}
              >
                <Text
                  style={[
                    styles.textTitle,
                    {
                      fontSize: size.width < 750 ? 12 : 16,
                    },
                  ]}
                >
                  {item.course.title}
                </Text>
                <TouchableOpacity
                  onPress={() => {
                    setisCourseName(item.cer.courseInfo.disabled);
                    setisTeacherName(item.cer.teacherInfo.disabled);
                    setisStudentName(item.cer.studentInfo.disabled);
                    setisDateStart(item.cer.startEducationInfo.disabled);
                    setisDateEnd(item.cer.endEducationInfo.disabled);
                    setCourseName(item?.course?.title);
                    setStartName(item?.start_date);
                    setEndName(item?.updatedAt);
                    setTeacherName(
                      item?.user_info?.firstName +
                        " " +
                        item?.user_info?.lastName
                    );
                    setEndName(item?.updatedAt);
                    setstate(item.cer);
                    setImage(item.cer.imgRef);
                  }}
                  style={{
                    maxWidth: 177,
                    width: size.width < 1280 ? "40%" : "100%",
                    height: 48,
                    backgroundColor: "#0D88F0",
                    borderRadius: 24,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Text
                    style={[
                      styles.textButton,
                      { fontSize: size.width < 1280 ? 16 : 18 },
                    ]}
                  >
                    ดาวน์โหลด
                  </Text>
                </TouchableOpacity>
              </View>
            )
          );
        }}
      />
      <View
        ref={ref}
        style={{ width: 900, height: 600, position: "absolute", top: -900 }}
      >
        {image.length > 0 && (
          <Image
            resizeMode="stretch"
            source={{ uri: image }}
            style={{
              width: "100%",
              height: "100%",
              position: "absolute",
            }}
          />
        )}
        <Stage width={900} height={600}>
          <Layer>
            {image.length > 0 && isCourseName && (
              <Template
                // width={300}
                // align="center"
                text={courseName}
                x={state.courseInfo.x}
                y={state.courseInfo.y}
                fontSize={state?.cssstyle?.fontSize || 12}
                draggable
                onDragEnd={(e) => {
                  setstate((val) => ({
                    ...val,
                    courseInfo: { x: e.target.x(), y: e.target.y() },
                  }));
                }}
              />
            )}
            {image.length > 0 && isTeacherName && (
              <Template
                text={Teachername}
                x={state.teacherInfo.x}
                y={state.teacherInfo.y}
                fontSize={state?.cssstyle?.fontSize || 12}
                draggable
                onDragEnd={(e) => {
                  setstate((val) => ({
                    ...val,
                    teacherInfo: { x: e.target.x(), y: e.target.y() },
                  }));
                }}
              />
            )}
            {image.length > 0 && isStudentName && (
              <Template
                text={
                  users.firstName == null
                    ? profile.username
                    : users.firstName + " " + users.lastName
                }
                x={state.studentInfo.x}
                y={state.studentInfo.y}
                fontSize={state?.cssstyle?.fontSize || 12}
                draggable
                onDragEnd={(e) => {
                  setstate((val) => ({
                    ...val,
                    studentInfo: { x: e.target.x(), y: e.target.y() },
                  }));
                }}
              />
            )}
            {image.length > 0 && isDateStart && (
              <Template
                text={moment(StartName).format("DD MMMM YYYY")}
                x={state.startEducationInfo.x}
                y={state.startEducationInfo.y}
                fontSize={state?.cssstyle?.fontSize || 12}
                draggable
                onDragEnd={(e) => {
                  setstate((val) => ({
                    ...val,
                    startEducationInfo: {
                      x: e.target.x(),
                      y: e.target.y(),
                    },
                  }));
                }}
              />
            )}
            {image.length > 0 && isDateEnd && (
              <Template
                text={moment(EndName).format("DD MMMM YYYY")}
                x={state.endEducationInfo.x}
                y={state.endEducationInfo.y}
                fontSize={state?.cssstyle?.fontSize || 12}
                draggable
                onDragEnd={(e) => {
                  setstate((val) => ({
                    ...val,
                    endEducationInfo: {
                      x: e.target.x(),
                      y: e.target.y(),
                    },
                  }));
                }}
              />
            )}
          </Layer>
        </Stage>
      </View>
    </View>
  );
}
const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: "100%",
    backgroundColor: "#FFFFFF",
  },
  textTitle: {
    fontSize: 25,
    fontFamily: "Prompt",
    color: "#0B2051",
  },
  textButton: {
    fontSize: 25,
    fontFamily: "Prompt",
    color: "#FFFFFF",
  },
});
