import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  TouchableOpacity,
  StyleSheet,
  FlatList,
  Image,
  Dimensions,
} from "react-native";
import Drag from "./drag.js";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { DragDropContainer, DropTarget } from "react-drag-drop-container";
import { TextInput } from "react-native-gesture-handler";
import { useWindowSize } from "../components/responsive.js";
const { width, height } = Dimensions.get("window");
export default function exam({ data, answer, setAnswer, status, conditions }) {
  const DATA = ["ถูก", "ผิด"];
  const choiceList = data;
  const size = useWindowSize();
  const [first, setfirst] = useState(false);

  const condition = (item) => {
    try {
      return item.answer
        .map((a, b) => {
          return { ...a, index: Math.random() };
        })
        .sort((a, b) => b.index - a.index);
    } catch (error) {
      return [];
    }
  };

  return (
    <View style={{ width: "110%" }}>
      <FlatList
        data={choiceList}
        numColumns={1}
        style={{
          borderBottomWidth: 1,
          borderBottomColor: "#0D88F0",
          paddingBottom: 45,
          marginBottom: 49,
        }}
        renderItem={({ index, item }) => {
          const mainIndex = index;
          const mainItem = item;
          return (
            <View style={{ marginTop: 45 }}>
              {item?.image?.length > 0 && (
                <Image
                  style={{
                    width: size.width < 1280 ? width * 0.8 : width * 0.5,
                    height: size.width < 1280 ? width * 0.5 : width * 0.3,
                  }}
                  resizeMode="contain"
                  source={{
                    uri:
                      "https://api.courseoncloud.com/api/image/getimage/" +
                      item?.image.replace(".png", ""),
                  }}
                />
              )}
              <Text
                style={{
                  fontFamily: "Prompt",
                  fontSize: size.width < 500 ? 16 : 30,
                  color: "#0B2051",
                  // marginBottom: index == 0 && 49,
                }}
              >
                {item.type !== "5" ? item.title : "เติมคำ"}
              </Text>
              {item.type == "1" && (
                <View style={styles.viewChoice}>
                  {item.choice.map((items, indexs) => {
                    return (
                      <TouchableOpacity
                        disabled={status ? true : false}
                        key={item}
                        style={{
                          alignItems: "center",
                          flexDirection: "row",
                          marginTop: indexs != 0 ? 15 : 0,
                          backgroundColor: conditions.showanswer
                            ? status
                              ? answer.filter((value) => {
                                  return (
                                    value.number == index &&
                                    value.answer == indexs
                                  );
                                }).length > 0 // เลือก
                                ? answer.filter((value) => {
                                    return value.number == index;
                                  })?.[0]?.answer == item.answer
                                  ? "#D1DDE5"
                                  : "#E82626"
                                : indexs == item.answer
                                ? "#D1DDE5"
                                : "#FFF"
                              : "#FFF"
                            : "#FFF",
                        }}
                        onPress={() => {
                          if (
                            answer.filter((value) => {
                              return value.number == index;
                            }).length > 0
                          ) {
                            setAnswer((val) =>
                              val.map((values) => {
                                if (values.number == index) {
                                  return {
                                    ...values,
                                    answer: indexs,
                                  };
                                } else {
                                  return values;
                                }
                              })
                            );
                          } else {
                            setAnswer((val) =>
                              val.concat({
                                number: index,
                                answer: indexs,
                                totals: item.answer,
                              })
                            );
                          }
                        }}
                      >
                        <MaterialCommunityIcons
                          name={
                            answer.filter((value) => {
                              return (
                                value.number == index && value.answer == indexs
                              );
                            }).length > 0
                              ? "checkbox-intermediate"
                              : "checkbox-blank-outline"
                          }
                          size={30}
                          color="#83BEF0"
                        />
                        <View style={{ marginLeft: 25 }}>
                          <Text
                            style={[
                              size.width < 500
                                ? stylesResponsive.textChoice
                                : styles.textChoice,
                            ]}
                          >
                            {items}
                          </Text>
                        </View>
                      </TouchableOpacity>
                    );
                  })}
                </View>
              )}
              {item.type == "2" && (
                <View style={styles.viewChoice}>
                  {item.choice.map((items, indexs) => {
                    return (
                      <TouchableOpacity
                        disabled={status ? true : false}
                        key={item}
                        style={{
                          alignItems: "center",
                          flexDirection: "row",
                          marginTop: indexs != 0 ? 15 : 0,
                          backgroundColor: conditions.showanswer
                            ? status
                              ? answer
                                  .filter((value) => {
                                    return value?.number == index;
                                  })?.[0]
                                  ?.answer?.filter((ev) => ev == indexs)
                                  .length > 0 &&
                                item?.answer?.filter((ev) => ev == indexs)
                                  .length > 0
                                ? "#D1DDE5"
                                : answer
                                    .filter((value) => {
                                      return value.number == index;
                                    })?.[0]
                                    ?.answer?.filter((ev) => ev == indexs)
                                    .length > 0
                                ? "#E82626"
                                : "#FFFFFF"
                              : "#FFF"
                            : "#FFF",
                          //#D1DDE5
                        }}
                        onPress={() => {
                          if (
                            answer.filter((value) => {
                              return value.number == index;
                            }).length > 0
                          ) {
                            setAnswer((val) =>
                              val.map((values) => {
                                if (values.number == index) {
                                  if (
                                    values?.answer?.filter((ev) => ev == indexs)
                                      .length > 0
                                  ) {
                                    return {
                                      ...values,
                                      answer: values?.answer?.filter(
                                        (ev) => ev != indexs
                                      ),
                                    };
                                  } else {
                                    return {
                                      ...values,
                                      answer: values?.answer?.concat(indexs),
                                    };
                                  }
                                } else {
                                  return values;
                                }
                              })
                            );
                          } else {
                            setAnswer((val) =>
                              val.concat({
                                number: index,
                                answer: [indexs],
                                totals: item?.answer,
                              })
                            );
                          }
                        }}
                      >
                        <MaterialCommunityIcons
                          name={
                            answer.filter((value) => {
                              return (
                                value.number == index &&
                                value.answer?.filter((ev) => ev == indexs)
                                  .length > 0
                              );
                            }).length > 0
                              ? "checkbox-intermediate"
                              : "checkbox-blank-outline"
                          }
                          size={30}
                          color="#83BEF0"
                        />

                        <View style={{ marginLeft: 25 }}>
                          <Text
                            style={[
                              size.width < 500
                                ? stylesResponsive.textChoice
                                : styles.textChoice,
                            ]}
                          >
                            {items}
                          </Text>
                        </View>
                      </TouchableOpacity>
                    );
                  })}
                </View>
              )}
              {item.type == "3" && (
                <View style={styles.viewChoice}>
                  {DATA.map((items, indexs) => {
                    if (item.answer == null) {
                      item.answer = 0;
                    }
                    return (
                      <TouchableOpacity
                        disabled={status ? true : false}
                        key={item}
                        style={{
                          alignItems: "center",
                          flexDirection: "row",
                          marginTop: indexs != 0 ? 15 : 0,
                          backgroundColor: conditions.showanswer
                            ? status
                              ? answer?.filter((value) => {
                                  return (
                                    value?.number == index &&
                                    value?.answer == indexs
                                  );
                                }).length > 0 // เลือก
                                ? answer?.filter((value) => {
                                    return value?.number == index;
                                  })?.[0]?.answer == item?.answer
                                  ? "#D1DDE5"
                                  : "#E82626"
                                : indexs == item?.answer
                                ? "#D1DDE5"
                                : "#FFF"
                              : "#FFF"
                            : "#FFF",
                        }}
                        onPress={() => {
                          if (
                            answer.filter((value) => {
                              return value.number == index;
                            }).length > 0
                          ) {
                            setAnswer((val) =>
                              val.map((values) => {
                                if (values?.number == index) {
                                  return { ...values, answer: indexs };
                                } else {
                                  return values;
                                }
                              })
                            );
                          } else {
                            setAnswer((val) =>
                              val.concat({
                                number: index,
                                answer: indexs,
                                totals: item.answer,
                              })
                            );
                          }
                        }}
                      >
                        <MaterialCommunityIcons
                          name={
                            answer.filter((value) => {
                              return (
                                value?.number == index &&
                                value?.answer == indexs
                              );
                            }).length > 0
                              ? "checkbox-intermediate"
                              : "checkbox-blank-outline"
                          }
                          size={30}
                          color="#83BEF0"
                        />
                        <View style={{ marginLeft: 25 }}>
                          <Text
                            style={[
                              size.width < 500
                                ? stylesResponsive.textChoice
                                : styles.textChoice,
                            ]}
                          >
                            {items}
                          </Text>
                        </View>
                      </TouchableOpacity>
                    );
                  })}
                </View>
              )}
              {item.type == "4" && (
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    width: "100%",
                    paddingVertical: 45,
                    borderBottomWidth: 1,
                    borderColor: "#BBBBBB",
                  }}
                >
                  <View style={{ width: "53%" }}>
                    {item.choice.map((items, indexs) => {
                      return (
                        <View
                          style={{
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <View style={[styles.viewQuestion, { width: "90%" }]}>
                            <Text style={[styles.textChoice]}>
                              {items.question}
                            </Text>
                          </View>
                          {/* {conditions.showanswer &&
                            status &&
                            answer
                              .filter((e) => e?.number == index)?.[0]
                              ?.answer?.filter(
                                (ev) => ev.question == items.question
                              )?.[0]?.answer == items?.answer && (
                              <MaterialCommunityIcons
                                name="check-circle"
                                //cancel
                                size={28}
                                color="#D1DDE5"
                              />
                            )}
                          {conditions.showanswer &&
                            status &&
                            answer
                              .filter((e) => e.number == index)?.[0]
                              ?.answer?.filter(
                                (ev) => ev.question == items.question
                              )?.[0].answer != items?.answer && (
                              <MaterialCommunityIcons
                                name="close-circle"
                                //cancel
                                size={28}
                                color="#ff0000"
                              />
                            )} */}
                        </View>
                      );
                    })}
                  </View>

                  <View style={{ width: "45%" }}>
                    {status && (
                      <FlatList
                        data={
                          answer?.filter((e) => e.number == index).length > 0
                            ? answer?.filter((e) => e.number == index)?.[0]
                                .answer
                            : []
                        }
                        renderItem={({ item, index }) => {
                          return (
                            <View
                              style={{
                                flexDirection: "row",
                                alignItems: "center",
                              }}
                            >
                              {mainItem?.choice?.[index]?.answer ==
                              item.answer ? (
                                <MaterialCommunityIcons
                                  name="check-circle"
                                  //cancel
                                  size={28}
                                  color="#D1DDE5"
                                />
                              ) : (
                                <MaterialCommunityIcons
                                  name="close-circle"
                                  //cancel
                                  size={28}
                                  color="#ff0000"
                                />
                              )}
                              <View
                                style={[
                                  ,
                                  {
                                    color: "#FFFFFF",

                                    height: 40,
                                    marginTop: 10,

                                    fontFamily: "Prompt",
                                    width: "100%",
                                    // change background colour if dragging
                                    background: "#0D88F0",
                                  },
                                ]}
                              >
                                <Text
                                  style={{
                                    fontFamily: "Prompt",
                                    color: "#FFF",
                                    textAlign: "center",
                                    paddingTop: 5,
                                    fontSize: 20,
                                  }}
                                >
                                  {item?.answer}
                                </Text>
                              </View>
                            </View>
                          );
                        }}
                      />
                    )}
                    {!status && (
                      <Drag
                        answer={answer}
                        setAnswer={setAnswer}
                        index={index}
                        data={
                          // item.answer
                          condition(item)
                        }
                      />
                    )}
                  </View>
                </View>
              )}
              {item.type == "5" && (
                <View style={[styles.viewChoice, { borderBottomWidth: 0 }]}>
                  <View
                    style={{
                      borderWidth: 1,
                      borderColor: "#BBBBBB",
                      width: "100%",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      padding: 20,
                    }}
                  >
                    <View style={{ width: "45%" }}>
                      <Text
                        style={[
                          size.width < 500
                            ? stylesResponsive.textChoice
                            : styles.textChoice,
                        ]}
                      >
                        {item?.answer}
                      </Text>
                    </View>
                    <View
                      style={{
                        width: "45%",
                      }}
                    >
                      <FlatList
                        extraData={[answer]}
                        numColumns={1}
                        data={item.choice}
                        renderItem={({ item, index }) => {
                          return (
                            <View
                              style={{
                                flexDirection: "row",
                                alignItems: "center",
                              }}
                            >
                              <Text
                                style={[
                                  size.width < 500
                                    ? stylesResponsive.textChoice
                                    : styles.textChoice,
                                  { width: "10%" },
                                ]}
                              >
                                {index + 1}
                                {"."}
                              </Text>
                              <View
                                style={{
                                  width: "90%",
                                }}
                              >
                                {conditions.showanswer &&
                                  status &&
                                  typeof answer?.filter((value) => {
                                    return value?.number == 4;
                                  })?.[0]?.answer == "object" &&
                                  answer
                                    ?.filter((value) => {
                                      return value?.number == 4;
                                    })?.[0]
                                    ?.answer?.sort(
                                      (a, b) => a.number - b.number
                                    )
                                    ?.filter((e) => e.number == index)?.[0]
                                    ?.ans?.toLowerCase() ==
                                    item?.toLowerCase() && (
                                    <MaterialCommunityIcons
                                      name="check-circle"
                                      //cancel
                                      size={28}
                                      color="#D1DDE5"
                                      style={{
                                        position: "absolute",
                                        right: 5,
                                        marginTop: index != 0 ? 10 : 0,
                                      }}
                                    />
                                  )}
                                {conditions.showanswer &&
                                  status &&
                                  answer
                                    ?.filter((value) => {
                                      return value.number == mainIndex;
                                    })?.[0]
                                    ?.answer?.sort(
                                      (a, b) => a?.number - b?.number
                                    )
                                    .filter((e) => e?.number == index)?.[0]
                                    ?.ans?.toLowerCase() !=
                                    item?.toLowerCase() && (
                                    <MaterialCommunityIcons
                                      name="close-circle"
                                      //cancel
                                      size={28}
                                      color="#ff0000"
                                      style={{
                                        position: "absolute",
                                        right: 5,
                                        marginTop: index != 0 ? 15 : 0,
                                      }}
                                    />
                                  )}
                                <TextInput
                                  disabled={status ? true : false}
                                  value={
                                    answer.filter((value) => {
                                      return value.number == mainIndex;
                                    }).length > 0
                                      ? answer
                                          .filter((value) => {
                                            return value.number == mainIndex;
                                          })?.[0]
                                          ?.answer?.filter(
                                            (e) => e.number == index
                                          )?.[0]?.ans
                                      : ""
                                  }
                                  onChangeText={(text) => {
                                    if (
                                      answer.filter((value) => {
                                        return value.number == mainIndex;
                                      }).length > 0
                                    ) {
                                      setAnswer((val) =>
                                        val.map((values) => {
                                          if (values.number == mainIndex) {
                                            return {
                                              ...values,
                                              answer:
                                                values?.answer?.filter(
                                                  (e) => e.number == index
                                                ).length > 0
                                                  ? values?.answer?.map(
                                                      (items) => {
                                                        if (
                                                          items.number == index
                                                        ) {
                                                          return {
                                                            ...items,
                                                            ans: text,
                                                          };
                                                        } else {
                                                          return items;
                                                        }
                                                      }
                                                    )
                                                  : values?.answer?.concat({
                                                      number: index,
                                                      ans: text,
                                                    }),
                                            };
                                          } else {
                                            return values;
                                          }
                                        })
                                      );
                                    } else {
                                      setAnswer((val) =>
                                        val.concat({
                                          type: "text",
                                          number: mainIndex,
                                          answer: [
                                            { number: index, ans: text },
                                          ],
                                          totals: mainItem.choice,
                                        })
                                      );
                                    }
                                    setfirst(true);
                                  }}
                                  style={[
                                    styles.viewQuestion,
                                    size.width < 500
                                      ? stylesResponsive.textChoice
                                      : styles.textChoice,
                                    {
                                      marginLeft: 10,
                                      paddingHorizontal: 10,
                                      marginTop: index != 0 ? 10 : 0,
                                      maxWidth: null,
                                      width: "100%",
                                    },
                                  ]}
                                />
                              </View>
                            </View>
                          );
                        }}
                      />
                      {conditions.showanswer && status && (
                        <View style={{ marginTop: 10 }}>
                          <Text
                            style={[
                              size.width < 500
                                ? stylesResponsive.textChoice
                                : styles.textChoice,
                            ]}
                          >
                            เฉลย
                          </Text>
                          <Text
                            style={[
                              size.width < 500
                                ? stylesResponsive.textChoice
                                : styles.textChoice,
                            ]}
                          >
                            {item.choice + " "}
                          </Text>
                        </View>
                      )}
                    </View>
                  </View>
                </View>
              )}
            </View>
          );
        }}
      />
    </View>
  );
}
const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: "100%",
    backgroundColor: "#FFFFFF",
  },
  textChoice: {
    fontFamily: "Prompt",
    fontSize: 20,
    color: "#646464",
  },

  flatlist: {
    borderBottomWidth: 1,
    borderBottomColor: "#0D88F0",
    paddingBottom: 45,
    marginBottom: 49,
  },
  viewChoice: {
    paddingVertical: 45,
    borderBottomWidth: 1,
    borderColor: "#BBBBBB",
  },
  viewQuestion: {
    width: "100%",
    maxWidth: 412,
    height: 40,
    borderWidth: 1,
    borderColor: "#0D88F0",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 10,
  },
});
const stylesResponsive = StyleSheet.create({
  textChoice: {
    fontFamily: "Prompt",
    fontSize: 14,
    color: "#646464",
  },
});
