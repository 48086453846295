import React, { useState, useRef, useEffect, useLayoutEffect } from "react";
import {
  View,
  Text,
  Image,
  Dimensions,
  Animated,
  TouchableOpacity,
  StyleSheet,
  ScrollView,
  TextInput,
  FlatList,
  Linking,
} from "react-native";
import md5 from "md5";
import renderHTML from "react-html-renderer";

import Header from "../components/header";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { apiservice } from "../../service";
import CryptoJS from "crypto-js";
import { useWindowSize } from "../components/responsive";
import axios from "axios";
import moment from "moment";
import {
  MaterialCommunityIcons,
  MaterialIcons,
  Ionicons,
  FontAwesome,
  FontAwesome5,
} from "@expo/vector-icons";
import { useRecoilState, useRecoilValue } from "recoil";
import { courseState, tokenState, userState } from "../../reducer/recoil";
import { authActionStartCourseCreate, UseCode } from "../../action/authAction";
import Modal from "modal-react-native-web";
const { width, height } = Dimensions.get("window");
export default function payment({ navigation }) {
  const [courseStates, setCourseStates] = useRecoilState(courseState);
  const token = useRecoilValue(tokenState);
  const [user, setUser] = useRecoilState(userState);
  const [modalError, setModalError] = useState(false);
  const [modal, setModal] = useState(false);
  const [modalSucceed, setModalSucceed] = useState(false);
  const [code, setCode] = useState();
  const [price, setPrice] = useState(courseStates.price);

  const [state, setState] = useState({ code: "" });
  const [warningCode, setWarningCode] = useState(false);
  const [warningCode1, setWarningCode1] = useState(false);
  const [warningPrice, setWarningPrice] = useState(false);
  let loopTodo = useRef();
  const size = useWindowSize();
  // var __html = require("./payment.html");
  // var template = { __html: __html };
  const [items, setItem] = useState({
    ProductImage: "",
    ProductName: "ProductName",
    ProductDescription: "ProductDescription",
    PaymentLimit: "0",
    StartDate: moment().format("DD/MM/YYYY HH:mm:ss"),
    ExpiredDate: moment().add(15, "minutes").format("DD/MM/YYYY HH:mm:ss"),
    Currency: "THB",
  });
  async function Code() {
    const use = await UseCode({
      state,
    });

    if (use) {
    }
  }
  if (courseStates.user == null) {
    return null;
  }
  return (
    <View style={styles.container}>
      <ScrollView stickyHeaderIndices={[0]}>
        <Header navigation={navigation} />
        <View style={{ marginVertical: 50 }}>
          <View
            style={{
              width: "100%",

              paddingLeft: size.width / 16,
              borderBottomWidth: 1,
              borderBottomColor: "#CFCFCF",
            }}
          >
            <View
              style={{
                flexDirection: size.width < 1280 ? "column" : "row",
                paddingBottom: 40,
              }}
            >
              <Image
                style={{
                  width: size.width < 500 ? size.width * 0.9 : 500,
                  height: size.width < 500 ? size.height * 0.3 : 300,
                  backgroundColor: "#CCC",
                }}
                source={{
                  uri:
                    "https://api.courseoncloud.com/api/image/getimage/" +
                    courseStates.img_content,
                }}
              />
              <View style={{ paddingHorizontal: 40, paddingVertical: 10 }}>
                <Text style={[styles.textTitle, { fontSize: 30 }]}>
                  {courseStates.title}
                </Text>
                <Text style={[styles.textDetail, { fontSize: 18 }]}>
                  {"ผู้สอน  " +
                    courseStates.user.user_accounts.firstName +
                    " " +
                    courseStates.user.user_accounts.lastName}
                </Text>

                <Text style={[styles.textDetail, { fontSize: 18 }]}>
                  ราคา {courseStates.price} บาท
                </Text>
                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Text style={[styles.textDetail, { fontSize: 18 }]}>
                    โค้ดส่วนลด
                  </Text>
                  <View>
                    <TextInput
                      onChangeText={(text) => {
                        if (state.code != text) {
                          setWarningCode(false);
                          setWarningCode1(false);
                          setWarningPrice(false);
                        }
                        setState({ ...state, code: text });
                      }}
                      style={[
                        styles.textDetail,
                        {
                          width: size.width < 1280 ? 150 : 200,
                          height: 30,
                          borderWidth: 1,
                          borderColor: "#BBBBBB",
                          marginLeft: 10,
                          paddingHorizontal: 10,
                          marginTop: 10,
                          fontSize: 16,
                          borderRadius: 5,
                        },
                      ]}
                    />
                    {warningCode && (
                      <Text
                        style={{
                          position: "absolute",
                          marginTop: 40,
                          fontSize: 14,
                          marginLeft: 10,
                          color: "red",
                          fontFamily: "Prompt",
                        }}
                      >
                        รหัสโค้ดส่วนลดไม่ถูกต้อง
                      </Text>
                    )}
                    {warningCode1 && (
                      <Text
                        style={{
                          position: "absolute",
                          marginTop: 40,
                          fontSize: 14,
                          marginLeft: 10,
                          color: "red",
                          fontFamily: "Prompt",
                        }}
                      >
                        รหัสโค้ดส่วนลดหมดอายุ
                      </Text>
                    )}
                    {warningPrice && (
                      <Text
                        style={{
                          position: "absolute",
                          marginTop: 40,
                          fontSize: 14,
                          marginLeft: 10,
                          color: "red",
                          fontFamily: "Prompt",
                        }}
                      >
                        โค้ดส่วนลดนี้ใช้สิทธิ์ครบแล้ว
                      </Text>
                    )}
                  </View>
                  <TouchableOpacity
                    disabled={state.code.length > 0 ? false : true}
                    onPress={async () => {
                      setModal(true);
                    }}
                    style={{
                      width: 70,
                      height: 30,
                      borderRadius: 5,
                      backgroundColor:
                        state.code.length > 0 ? "#0D88F0" : "#CCC",
                      alignItems: "center",
                      justifyContent: "center",
                      marginTop: 10,
                      marginLeft: 10,
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 16,
                        fontFamily: "Prompt",
                        color: "#FFF",
                      }}
                    >
                      ใช้โค้ด
                    </Text>
                  </TouchableOpacity>
                </View>
                <Text style={[styles.textDetail, { fontSize: 18 }]}>
                  ราคาสุทธิ{" "}
                  <Text style={{ fontSize: 30, color: "#0D88F0" }}>
                    {code == undefined
                      ? courseStates.price
                      : (
                        (courseStates.price * (100 - code?.percent)) /
                        100
                      ).toFixed(0)}
                  </Text>{" "}
                  บาท
                </Text>
                <TouchableOpacity
                  onPress={async () => {
                    const res = await apiservice({
                      path: "/chillpay/MD5",
                      method: "POST",
                      body: {
                        ...items,
                        Amount: parseFloat(price) + "00",
                        Checksum: CryptoJS.MD5(
                          items.ProductImage +
                          items.ProductName +
                          items.ProductDescription +
                          items.PaymentLimit +
                          items.StartDate +
                          items.ExpiredDate +
                          items.Currency +
                          parseFloat(price) +
                          "00" +
                          "UP5ybMV90iS3rUC45plCI6hlWznubb58wpOiYJLZxyF1xFhJZMoXC5uS16rdeRkXKxKuqLOhlGbnPgmBbqIdjaOwfqe8CyEH43NCCLnNHGxmMwK6OFvvp6pQYML6ZC8CNlLi8zfvrBXNvAH4g2Cao7LLkkeXMTsJqq9dh"
                        ).toString(CryptoJS.enc.Hex),
                      },
                    });

                    if (res.status == 200) {
                      Linking.openURL(res.data.data.paymentUrl);
                    }
                  }}
                  style={{
                    width: 170,
                    height: 50,
                    borderRadius: 5,
                    backgroundColor: "#0D88F0",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: 10,
                  }}
                >
                  <Text
                    style={{
                      fontSize: 22,
                      fontFamily: "Prompt",
                      color: "#FFF",
                    }}
                  >
                    ชำระเงิน
                  </Text>
                </TouchableOpacity>
              </View>
            </View>
          </View>
          <View
            style={{
              width: "87%",
              marginTop: 40,
              marginBottom: 10,
              alignSelf: "center",
            }}
          >
            <Text
              style={{
                fontSize: size.width < 1280 ? 20 : 30,
                fontFamily: "Prompt",
                color: "#0D88F0",
              }}
            >
              วิธีชำระเงิน
            </Text>
            <View
              style={{
                width: "100%",
                flexDirection: size.width < 1650 ? "column" : "row",
                marginTop: 10,
              }}
            >
              <View
                style={{
                  width: size.width < 1280 ? "100%" : "50%",
                }}
              >
                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <View
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                      marginRight: 30,
                    }}
                  >
                    <Image
                      style={{ width: 40, height: 40, position: "absolute" }}
                      source={require("../img/Rectangle 413.png")}
                    />
                    <Text
                      style={{
                        fontSize: 20,
                        fontFamily: "Prompt",
                        color: "#FFF",
                        zIndex: 99,
                      }}
                    >
                      1
                    </Text>
                  </View>
                  <Text
                    style={{
                      fontSize: 20,
                      fontFamily: "Prompt",
                      color: "#646464",
                    }}
                  >
                    ทำการเข้าสู่ระบบ
                    หากยังไม่ได้เป็นสมาชิกให้ทำการสมัครสมาชิกก่อน
                  </Text>
                </View>
                <View
                  style={{
                    flexDirection: size.width < 700 ? "column" : "row",
                    alignItems: "center",
                    marginTop: 20,
                  }}
                >
                  <Image
                    style={{ width: 179, height: 299 }}
                    source={require("../img/Image 41.png")}
                  />
                  <Text
                    style={{
                      fontSize: 12,
                      fontFamily: "Prompt",
                      color: "#646464",
                      marginHorizontal: 15,
                    }}
                  >
                    หรือ
                  </Text>
                  <Image
                    style={{ width: 332, height: 221 }}
                    source={require("../img/Image 42.png")}
                  />
                </View>
              </View>
              <View
                style={{
                  width: size.width < 1280 ? "100%" : "50%",
                  marginTop: size.width < 1650 && 20,
                }}
              >
                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <View
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                      marginRight: 30,
                      marginTop: -30,
                    }}
                  >
                    <Image
                      style={{ width: 40, height: 40, position: "absolute" }}
                      source={require("../img/Rectangle 413.png")}
                    />
                    <Text
                      style={{
                        fontSize: 20,
                        fontFamily: "Prompt",
                        color: "#FFF",
                        zIndex: 99,
                      }}
                    >
                      2
                    </Text>
                  </View>
                  <Text
                    style={{
                      fontSize: 20,
                      fontFamily: "Prompt",
                      color: "#646464",
                    }}
                  >
                    กดชำระเงินจากหน้าคอร์สเรียนที่ท่านเลือก
                    ระบบจะนำท่านมาที่หน้าคำนวณราคาสุทธิที่ท่านต้องชำระ
                    (หากมีโค้ดส่วนลดสามารถใส่ได้ในหน้านี้)
                  </Text>
                </View>
                <View
                  style={{
                    flexDirection: size.width < 900 ? "column" : "row",

                    marginTop: 20,
                  }}
                >
                  <Image
                    style={{ width: 378, height: 221 }}
                    source={require("../img/Image 43.png")}
                  />
                  <View>
                    <Image
                      style={{ width: 436, height: 111 }}
                      source={require("../img/Image 44.png")}
                    />
                    {size.width >= 900 && (
                      <Image
                        style={{ width: 178, height: 63, marginTop: 10 }}
                        source={require("../img/Group 206.png")}
                      />
                    )}
                  </View>
                </View>
              </View>
            </View>
            <View
              style={{
                width: "100%",
                flexDirection: size.width < 1500 ? "column" : "row",
                marginTop: size.width < 1500 ? 20 : 50,
              }}
            >
              <View
                style={{
                  width: size.width < 1280 ? "100%" : "50%",
                }}
              >
                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <View
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                      marginRight: 30,
                    }}
                  >
                    <Image
                      style={{ width: 40, height: 40, position: "absolute" }}
                      source={require("../img/Rectangle 413.png")}
                    />
                    <Text
                      style={{
                        fontSize: 20,
                        fontFamily: "Prompt",
                        color: "#FFF",
                        zIndex: 99,
                      }}
                    >
                      3
                    </Text>
                  </View>
                  <Text
                    style={{
                      fontSize: 20,
                      fontFamily: "Prompt",
                      color: "#646464",
                      marginRight: 20,
                    }}
                  >
                    เลือกวิธีการชำระที่ต้องการใช้
                    ทำการกรอกข้อมูลและทำการชำระเงินตามช่องทาง
                    ที่เลือกไว้จนเสร็จสิ้นตามระยะเวลาที่กำหนด
                  </Text>
                </View>

                <Image
                  resizeMode="stretch"
                  style={{
                    width: size.width < 700 ? size.width * 0.9 : 590,
                    height: size.width < 700 ? size.height * 0.3 : 374,
                    marginTop: 20,
                  }}
                  source={require("../img/Image 45.png")}
                />
              </View>
              <View
                style={{
                  width: size.width < 1280 ? "100%" : "50%",
                  marginTop: size.width < 1500 && 20,
                }}
              >
                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <View
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                      marginRight: 30,
                    }}
                  >
                    <Image
                      style={{ width: 40, height: 40, position: "absolute" }}
                      source={require("../img/Rectangle 413.png")}
                    />
                    <Text
                      style={{
                        fontSize: 20,
                        fontFamily: "Prompt",
                        color: "#FFF",
                        zIndex: 99,
                      }}
                    >
                      4
                    </Text>
                  </View>
                  <Text
                    style={{
                      fontSize: 20,
                      fontFamily: "Prompt",
                      color: "#646464",
                    }}
                  >
                    เมื่อทำการชำระเงินเสร็จสิ้น
                    สามารถเรียนคอร์สที่เลือกไว้ได้ทันที
                  </Text>
                </View>

                <Image
                  resizeMode="stretch"
                  style={{
                    width: size.width < 700 ? size.width * 0.9 : 625,
                    height: size.width < 700 ? size.height * 0.3 : 374,
                    marginTop: 40,
                  }}
                  source={require("../img/Image 46.png")}
                />
              </View>
            </View>
          </View>
        </View>
      </ScrollView>
      <Modal
        transparent={true}
        visible={modalSucceed}
        onRequestClose={() => {
          Alert.alert("Modal has been closed.");
          setModalSucceed(!modalSucceed);
        }}
      >
        <View style={styles.bgModal}>
          <View
            style={{
              width: size.width < 1280 ? "80%" : "45%",
              height: 200,
              borderWidth: 1,
              borderColor: "#0D88F0",
              backgroundColor: "#FFFFFF",
              alignSelf: "center",
              alignItems: "center",
            }}
          >
            <TouchableOpacity
              onPress={() => {
                setState({ code: "" });
                setModalSucceed(false);
              }}
              style={{
                position: "absolute",
                alignSelf: "flex-end",
                margin: 13,
              }}
            >
              <MaterialIcons name="clear" size={14} color="#0D88F0" />
            </TouchableOpacity>
            <View style={{ paddingVertical: 45, alignItems: "center" }}>
              <Text
                style={{
                  fontSize: size.width < 1280 ? 30 : 35,
                  fontFamily: "Prompt",
                  color: "#74C745",
                }}
              >
                Code : {code?.code}
              </Text>
              <Text
                style={[
                  styles.text20,
                  { marginTop: 10, fontSize: size.width < 1280 ? 16 : 20 },
                ]}
              >
                คุณได้รับส่วนลด {code?.percent} %
              </Text>
            </View>
          </View>
        </View>
      </Modal>
      <Modal
        transparent={true}
        visible={modal}
        onRequestClose={() => {
          Alert.alert("Modal has been closed.");
          setModal(!modal);
        }}
      >
        <View style={styles.bgModal}>
          <View
            style={[
              styles.viewDetailModal,
              { width: "30%", height: 220, paddingVertical: 25 },
            ]}
          >
            <Text
              style={[
                styles.textButtonModal,
                { color: "#444444", alignSelf: "center", textAlign: "center" },
              ]}
            >
              ยืนยันเพื่อใช้โค้ด
              หากยืนยันแล้วจะใช้สิทธิทันทีแม้ท่านไม่กดชำระเงิน
            </Text>
            <View
              style={{
                flexDirection: "row",
                alignSelf: "center",
                marginTop: 39,
              }}
            >
              <TouchableOpacity
                onPress={() => setModal(!modal)}
                style={styles.buttonModal}
              >
                <Text
                  style={[
                    styles.textButtonModal,
                    {
                      color: "#444444",
                    },
                  ]}
                >
                  ยกเลิก
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={async () => {
                  setModal(false);
                  const res = await apiservice({
                    path: "/quiz/discount/usecode",
                    method: "post",
                    body: state,
                  });

                  if (res.status == 200) {
                    if (res.data.count == 0) {
                      setWarningPrice(true);
                    } else {
                      setCode(res.data);
                      setPrice(
                        (
                          (courseStates.price * (100 - res.data.percent)) /
                          100
                        ).toFixed(0)
                      );
                      setModalSucceed(true);
                    }
                  }
                  if (res.data.message == "Code expire") {
                    setWarningCode1(true);
                  }
                  if (res.data.message == "Not found") {
                    setWarningCode(true);
                  }
                }}
                style={[
                  styles.buttonModal,
                  {
                    backgroundColor: "#0D88F0",
                    marginLeft: width * 0.05,
                  },
                ]}
              >
                <Text
                  style={[
                    styles.textButtonModal,
                    {
                      color: "#FFFFFF",
                    },
                  ]}
                >
                  ยืนยัน
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </Modal>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: "100%",
    backgroundColor: "#FFFFFF",
  },
  textTitle: {
    fontSize: 20,
    fontFamily: "Prompt",
    color: "#0D88F0",
  },
  textDetail: {
    fontSize: 18,
    fontFamily: "Prompt",
    color: "#646464",
    marginTop: 15,
  },
  Radius: {
    width: 43,
    height: 43,
    borderRadius: 30,
    backgroundColor: "#0D88F0",
  },
  text25: {
    fontSize: 25,
    fontFamily: "Prompt",
    color: "#646464",
    marginLeft: 20,
  },
  viewPay: {
    width: "30%",
    borderWidth: 1,
    borderColor: "#0D88F0",
    paddingHorizontal: 43,
    paddingVertical: 16,
    paddingBottom: 49,
  },
  viewImg: {
    flexDirection: "row",
    alignItems: "center",
    borderLeftWidth: 3,
    borderColor: "#0D88F0",
    minHeight: 128,
    marginLeft: 20,
  },
  bgModal: {
    width: "100%",
    height: "100%",
    backgroundColor: "#000000bb",
    justifyContent: "center",
  },
  text20: {
    fontSize: 20,
    fontFamily: "Prompt",
    color: "#0B2051",
  },
  viewDetailModal: {
    width: "50%",
    height: 380,
    backgroundColor: "#FFFFFF",
    borderRadius: 20,
    alignSelf: "center",
    paddingHorizontal: width * 0.05,
    paddingVertical: 50,
  },
  buttonModal: {
    width: 102,
    height: 48,
    borderRadius: 4,
    backgroundColor: "#EFEFF3",
    justifyContent: "center",
    alignItems: "center",
  },
  textButtonModal: {
    fontSize: 25,
    fontFamily: "Prompt",
  },
});
