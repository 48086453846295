import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  Image,
  Dimensions,
  Animated,
  TouchableOpacity,
  StyleSheet,
  ScrollView,
  TextInput,
  FlatList,
} from "react-native";
import {
  MaterialCommunityIcons,
  MaterialIcons,
  Feather,
  Ionicons,
  FontAwesome,
} from "@expo/vector-icons";
import RNPickerSelect from "react-native-picker-select";
import Modal from "modal-react-native-web";
import { useRecoilState } from "recoil";
import { courseState, tokenState } from "../../../reducer/recoil";

import { apiservice } from "../../../service";
import moment from "moment";
import { useWindowSize } from "../../components/responsive";
const { width, height } = Dimensions.get("window");
export default function addCourse({ onPress, navigation }) {
  const [modalWarning, setModalWarning] = useState(false);
  const [token, setToken] = useRecoilState(tokenState);
  const [course, setCourse] = useState();
  const [id, setId] = useState(0);
  const [courseStates, setCourseStates] = useRecoilState(courseState);

  const [university_id, setUniversity_id] = useState(null);
  const size = useWindowSize();
  const [title, setTitle] = useState();
  useEffect(() => {
    if (token) {
      getuser(token);
    }
  }, [token]);
  async function getuser(token) {
    try {
      const response = await apiservice({
        path: "/authen/user",
        method: "get",
        token: token?.accessToken,
      });

      if (response.status == 200) {
        let pagination = [];
        for (let index = 0; index < response.data.total_page; index++) {
          pagination = pagination.concat({});
        }

        setUniversity_id(response.data.result.user_accounts.university_id);

        getUniversity(response.data.result.user_accounts.university_id);
      }
    } catch (error) {
      throw error;
    }
  }
  async function getUniversity(params) {
    try {
      const response = await apiservice({
        path:
          "/course/getallcouse?university_id=" +
          params +
          "&page=1&totalpage=100",
        method: "get",
        token: token?.accessToken,
      });

      if (response.status == 200) {
        let pagination = [];
        for (let index = 0; index < response.data.total_page; index++) {
          pagination = pagination.concat({});
        }

        setCourse(response.data.result);
      }
    } catch (error) {
      throw error;
    }
  }

  async function getUniversitysearch(params, search) {
    try {
      const response = await apiservice({
        path:
          "/course/getallcouse?university_id=" +
          params +
          "&page=1&totalpage=100&search=" +
          search,
        method: "get",
        token: token?.accessToken,
      });

      if (response.status == 200) {
        let pagination = [];
        for (let index = 0; index < response.data.total_page; index++) {
          pagination = pagination.concat({});
        }

        setCourse(response.data.result);
      }
    } catch (error) {
      throw error;
    }
  }

  async function getApprove() {
    try {
      const response = await apiservice({
        path: "/course/approvecourse/" + id,
        method: "get",
        token: token?.accessToken,
      });

      if (response.status == 200) {
        getuser(token);
        setModalWarning(!modalWarning);
      }
    } catch (error) {
      throw error;
    }
  }
  async function getReject(id) {
    try {
      const response = await apiservice({
        path: "/course/rejectcourse/" + id,
        method: "get",
        token: token?.accessToken,
      });
      if (response.status == 200) {
        getuser(token);
      }
    } catch (error) {
      throw error;
    }
  }

  return (
    <View>
      <View
        style={{ flexDirection: "row", marginTop: 20, alignItems: "center" }}
      >
        <TextInput
          onChangeText={(text) => {
            getUniversitysearch(university_id, text);
          }}
          autoComplete="off"
          placeholder="ค้นหาชื่อคอร์ส"
          placeholderTextColor="#8C8896"
          style={{
            width: size.width < 500 ? "80%" : "30%",
            height: 48,
            borderRadius: 4,
            backgroundColor: "#EFEFF3",
            fontSize: 16,
            fontFamily: "Prompt",
            color: "#8C8896",
            paddingHorizontal: 25,
          }}
        />
        <TouchableOpacity style={{ marginLeft: -40 }}>
          <MaterialCommunityIcons name="magnify" size={24} color="#0D88F0" />
        </TouchableOpacity>
      </View>
      <ScrollView horizontal>
        <View
          style={{
            width:
              size.width < 500
                ? size.width * 2.5
                : size.width < 1280
                ? size.width * 1.0
                : size.width * 0.78,
            // minHeight: height * 2.5,
          }}
        >
          <View style={styles.viewSubject}>
            <Text style={[styles.text16B, { paddingLeft: 67, width: "35%" }]}>
              ชื่อคอร์ส
            </Text>
            <Text style={[styles.text16B, { width: "20%" }]}>ชื่อผู้สอน</Text>
            <Text style={[styles.text16B, { width: "20%" }]}>
              วันที่ส่งคำขอ
            </Text>
            <Text
              style={[styles.text16B, { width: "25%", textAlign: "center" }]}
            >
              สถานะ
            </Text>
          </View>
          <FlatList
            numColumns={1}
            data={course}
            renderItem={({ item, index }) => {
              return (
                <TouchableOpacity
                  disabled={item.available == "APPROVE" ? true : false}
                  onPress={() => {
                    setCourseStates(item);
                    setTimeout(() => {
                      navigation.navigate("Course");
                    }, 900);
                  }}
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    borderBottomWidth: 1,
                    borderBottomColor: "#E5E5E5",
                    height: 50,
                    alignItems: "center",
                  }}
                >
                  <Text
                    style={[styles.text16B, { paddingLeft: 67, width: "35%" }]}
                  >
                    {item.title}
                  </Text>
                  <Text style={[styles.text16B, { width: "20%" }]}>
                    {item.teacher.firstName} {item.teacher.lastName}
                  </Text>
                  <Text style={[styles.text16B, { width: "20%" }]}>
                    {moment(item.createdAt)
                      .add(543, "year")
                      .format("DD MMMM YYYY")}
                  </Text>
                  {item.available == "PENDING" && (
                    <View
                      style={{
                        flexDirection: "row",
                        width: "25%",
                        justifyContent: "center",
                      }}
                    >
                      <TouchableOpacity
                        onPress={() => {
                          setId(item.id);
                          setTitle(item.title);
                          setModalWarning(true);
                        }}
                        style={{
                          width: 75,
                          height: 30,
                          backgroundColor: "#0D88F0",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: 4,
                        }}
                      >
                        <Text
                          style={{
                            fontFamily: "Prompt",
                            fontSize: 16,
                            color: "#FFFFFF",
                          }}
                        >
                          ยืนยัน
                        </Text>
                      </TouchableOpacity>
                      <TouchableOpacity
                        onPress={() => getReject(item.id)}
                        style={{
                          width: 75,
                          height: 30,
                          backgroundColor: "#808D93",
                          justifyContent: "center",
                          alignItems: "center",
                          marginLeft: 22,
                          borderRadius: 4,
                        }}
                      >
                        <Text
                          style={{
                            fontFamily: "Prompt",
                            fontSize: 16,
                            color: "#FFFFFF",
                          }}
                        >
                          ยกเลิก
                        </Text>
                      </TouchableOpacity>
                    </View>
                  )}
                  {item.available == "DARF" && (
                    <View
                      style={{
                        flexDirection: "row",
                        width: "25%",
                        justifyContent: "center",
                      }}
                    >
                      <Text
                        style={{
                          fontFamily: "Prompt",
                          fontSize: 16,
                          color: "#FFFFFF",
                        }}
                      >
                        ยืนยันแล้ว
                      </Text>
                    </View>
                  )}
                  {item.available == "APPROVE" && (
                    <View
                      style={{
                        flexDirection: "row",
                        width: "25%",
                        justifyContent: "center",
                      }}
                    >
                      <Text
                        style={{
                          fontFamily: "Prompt",
                          fontSize: 16,
                          color: "#ACFFA1",
                        }}
                      >
                        ยืนยันแล้ว
                      </Text>
                    </View>
                  )}
                  {item.available == "REJECT" && (
                    <View
                      style={{
                        flexDirection: "row",
                        width: "25%",
                        justifyContent: "center",
                      }}
                    >
                      <Text
                        style={{
                          fontFamily: "Prompt",
                          fontSize: 16,
                          color: "#FFA1A1",
                        }}
                      >
                        ถูกยกเลิก
                      </Text>
                    </View>
                  )}
                </TouchableOpacity>
              );
            }}
          />
        </View>
      </ScrollView>
      <Modal
        transparent={true}
        visible={modalWarning}
        onRequestClose={() => {
          Alert.alert("Modal has been closed.");
          setModalWarning(!modalWarning);
        }}
      >
        <View style={styles.bgModal}>
          <View
            style={[
              styles.viewDetailModal,
              {
                width:
                  size.width < 500 ? "80%" : size.width < 1280 ? "70%" : "25%",
              },
            ]}
          >
            <Text style={styles.text25}>
              {"ต้องการยืนยันคอร์สเรียน\n" + title + "?"}
            </Text>
            <View
              style={{
                flexDirection: "row",
                alignSelf: "center",
                marginTop: 19,
              }}
            >
              <TouchableOpacity
                onPress={() => setModalWarning(!modalWarning)}
                style={styles.buttonModal}
              >
                <Text
                  style={[
                    styles.textButtonModal,
                    {
                      color: "#444444",
                    },
                  ]}
                >
                  ยกเลิก
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={getApprove}
                style={[
                  styles.buttonModal,
                  {
                    backgroundColor: "#0D88F0",
                    marginLeft: 30,
                  },
                ]}
              >
                <Text
                  style={[
                    styles.textButtonModal,
                    {
                      color: "#FFFFFF",
                    },
                  ]}
                >
                  ยืนยัน
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </Modal>
    </View>
  );
}
const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: "100%",
    backgroundColor: "#FFFFFF",
  },
  text23O: { fontSize: 23, fontFamily: "Prompt", color: "#0D88F0" },
  text16B: {
    fontFamily: "Prompt",
    fontSize: 16,
    color: "#000000",
    width: "23%",
  },
  text25: {
    fontFamily: "Prompt",
    fontSize: 25,
    color: "#444444",
    textAlign: "center",
  },
  viewSubject: {
    width: "100%",
    height: 51,
    backgroundColor: "#E5E5E5",
    marginTop: 50,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  bgModal: {
    width: "100%",
    height: "100%",
    backgroundColor: "#000000bb",
    justifyContent: "center",
  },
  viewDetailModal: {
    width: "25%",
    height: 183,
    backgroundColor: "#FFFFFF",
    borderRadius: 20,
    alignSelf: "center",
    alignItems: "center",
    paddingVertical: 15,
  },
  buttonModal: {
    width: 102,
    height: 48,
    borderRadius: 4,
    backgroundColor: "#EFEFF3",
    justifyContent: "center",
    alignItems: "center",
  },
  textButtonModal: {
    fontSize: 25,
    fontFamily: "Prompt",
  },
});
