// In App.js in a new project

import * as React from "react";
import { View, Text, Image, Linking, Dimensions } from "react-native";
import { NavigationContainer } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import { createDrawerNavigator } from "@react-navigation/drawer";
import Home from "../page/home";
import Login from "../page/login";
import Register from "../page/register";
import Instructor from "../page/instructor";
import University from "../page/university";
import RecommendUniversity from "../page/recommendUniversity";
import CourseUniversity from "../page/courseUniversity";
import AllCourse from "../page/allCourse";
import Profile from "../page/profile";
import WorkReceived from "../page/workReceived";
import Course from "../page/course";
import Lesson from "../page/lesson";
import Payment from "../page/payment";
import LessonVideo from "../page/lessonVideo";
import LessonMeet from "../page/lessonMeet";
import Test from "../page/test";
import SubjectiveTest from "../page/subjectiveTest";
import AddCourse from "../page/addCourse";
import Certificate from "../page/certificate";
import ProfileStudent from "../page/profileUser";
import Main from "../PageSuperAdmin1/UniversityCourse/main";
import Admin from "../pageAdmin/main";
import GeneralCourseSA from "../PageSuperAdmin1/GeneralCourse/main";
import AddAdminSA from "../PageSuperAdmin1/AddAdmin/main";
import ReportSA from "../PageSuperAdmin1/Report/main";
import SlideBannerSA from "../PageSuperAdmin1/SlideBanner/main";
import UniversityCourseSA from "../PageSuperAdmin1/UniversityCourse/main";
import CourseTaughtSA from "../PageSuperAdmin1/UniversityCourse/CoursesTaught";
import NewPassword from "../page/newPassword";
import NewPassword1 from "../page/newPassword1";
import Menu from "../components/menu";
import Otherprofile from "../page/otherProfile";
import { useRecoilState } from "recoil";
import { winds } from "../../reducer/recoil";
const { width, height } = Dimensions.get("window");
import ReviewLesson from "../page/lessonReview";
import ReviewLessonVideo from "../page/lessonVideoReview";
import ReviewTest from "../page/testReview";
import ReviewSubjectiveTest from "../page/subjectiveTestReview";
import paymentSucces from "../page/paymentSucces";
import AboutMe from "../page/aboutMe";
import JoinMe from "../page/joinMe";
import Docs from "../page/docs";
import Privacy from "../page/PrivacyPolicy";
import Discount from "../PageSuperAdmin1/Discount/index";
import LearnerConditions from "../page/learnerConditions";
import InstructorConditions from "../page/InstructorConditions";
import loading from "../page/loding";
import Share from "../page/Share";
import GetProfile from "../page/GetProfile";
import Account from "../PageSuperAdmin1/Account/index";
import Authen from "../page/line";

const Stack = createStackNavigator();
function debounce(fn, ms) {
  let timer;
  return (_) => {
    clearTimeout(timer);
    timer = setTimeout((_) => {
      timer = null;
      fn.apply(this, arguments);
    }, ms);
  };
}

function main() {
  const [dimensions, setDimensions] = useRecoilState(winds);

  React.useEffect(() => {
    const debouncedHandleResize = debounce(function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    }, 1000);

    window.addEventListener("resize", debouncedHandleResize);

    return (_) => {
      window.removeEventListener("resize", debouncedHandleResize);
    };
  }, []);

  return (
    <Stack.Navigator initialRouteName="Home" headerMode="none" mode="modal">
      <Stack.Screen name="loading" component={loading} />
      <Stack.Screen name="Home" component={Home} />
      <Stack.Screen name="Login" component={Login} />
      <Stack.Screen name="Register" component={Register} />
      <Stack.Screen name="Instructor" component={Instructor} />
      <Stack.Screen name="University" component={University} />
      <Stack.Screen
        name="RecommendUniversity"
        component={RecommendUniversity}
      />
      <Stack.Screen name="CourseUniversity" component={CourseUniversity} />
      <Stack.Screen name="AllCourse" component={AllCourse} />
      <Stack.Screen name="Profile" component={Profile} />
      <Stack.Screen name="WorkReceived" component={WorkReceived} />
      <Stack.Screen name="Course" component={Course} />
      <Stack.Screen name="Lesson" component={Lesson} />
      <Stack.Screen name="Test" component={Test} />
      <Stack.Screen name="SubjectiveTest" component={SubjectiveTest} />
      <Stack.Screen name="AddCourse" component={AddCourse} />
      <Stack.Screen name="Certificate" component={Certificate} />
      <Stack.Screen name="ProfileStudent" component={ProfileStudent} />
      <Stack.Screen name="LessonVideo" component={LessonVideo} />
      <Stack.Screen name="LessonMeet" component={LessonMeet} />
      <Stack.Screen name="Admin" component={Admin} />
      <Stack.Screen name="GeneralCourseSA" component={GeneralCourseSA} />
      <Stack.Screen name="AddAdminSA" component={AddAdminSA} />
      <Stack.Screen name="ReportSA" component={ReportSA} />
      <Stack.Screen name="SlideBannerSA" component={SlideBannerSA} />
      <Stack.Screen name="UniversityCourseSA" component={UniversityCourseSA} />
      <Stack.Screen name="CourseTaughtSA" component={CourseTaughtSA} />
      <Stack.Screen name="NewPassword" component={NewPassword} />
      <Stack.Screen name="NewPassword1" component={NewPassword1} />
      <Stack.Screen name="LessonVideoReview" component={ReviewLessonVideo} />
      <Stack.Screen name="LessonReview" component={ReviewLesson} />
      <Stack.Screen name="TestReview" component={ReviewTest} />
      <Stack.Screen name="otherprofile" component={Otherprofile} />
      <Stack.Screen name="Payment" component={Payment} />
      <Stack.Screen name="PaymentSuccess" component={paymentSucces} />
      <Stack.Screen name="AboutMe" component={AboutMe} />
      <Stack.Screen name="JoinMe" component={JoinMe} />
      <Stack.Screen name="Docs" component={Docs} />
      <Stack.Screen name="Privacy" component={Privacy} />
      <Stack.Screen name="Discount" component={Discount} />
      <Stack.Screen name="Share" component={Share} />
      <Stack.Screen name="LearnerConditions" component={LearnerConditions} />
      <Stack.Screen name="Account" component={Account} />
      <Stack.Screen name="GetProfile" component={GetProfile} />
      <Stack.Screen name="Authen" component={Authen} />
      <Stack.Screen
        name="InstructorConditions"
        component={InstructorConditions}
      />
      <Stack.Screen
        name="SubjectiveTestReview"
        component={ReviewSubjectiveTest}
      />
    </Stack.Navigator>
  );
}
const DrawerStack = createDrawerNavigator();
function Drawer() {
  return (
    <DrawerStack.Navigator
      edgeWidth={0}
      drawerStyle={{
        width: "70%",
      }}
      headerMode="none"
      initialRouteName={"Home"}
      screenOptions={{ headerShown: false }}
      drawerContent={(props) => <Menu {...props} />}
    >
      <DrawerStack.Screen name="main" component={main} />
    </DrawerStack.Navigator>
  );
}
function web() {
  const config = {
    screens: {
      main: {
        path: "main",
        screens: {
          loding: "/Loading",
          Home: "/Home",
          ProfileStudent: "/ProfileStudent",
          Login: "/Login",
          Register: "/Register",
          Instructor: "/Instructor",
          University: "/University",
          otherprofile: "/otherprofile",
          RecommendUniversity: "/RecommendUniversity",
          CourseUniversity: "/CourseUniversity",
          AllCourse: "/AllCourse",
          Profile: "/Profile",
          WorkReceived: "/WorkReceived",
          Course: "/Course",
          Lesson: "/Lesson",
          Test: "/Test",
          SubjectiveTest: "/SubjectiveTest",
          AddCourse: "/AddCourse",
          Certificate: "/Certificate",
          LessonVideo: "/LessonVideo",
          Admin: "/Admin",
          GeneralCourseSA: "/GeneralCourse",
          AddAdminSA: "/AddAdmin",
          ReportSA: "/Report",
          SlideBannerSA: "/SlideBanner",
          UniversityCourseSA: "/UniversityCourse",
          NewPassword: "/NewPassword",
          NewPassword1: "/redirectpass",
          Payment: "/Payment",
          CourseTaughtSA: "/CourseTaughtSA",
          PaymentSuccess: "/PaymentSuccess",
          AboutMe: "/AboutMe",
          JoinMe: "/JoinMe",
          Docs: "/Docs",
          Privacy: "/Privacy",
          Discount: "/Discount",
          LearnerConditions: "/LearnerConditions",
          InstructorConditions: "/InstructorConditions",
          Share: "/Share",
          Account: "/Account",
          GetProfile: "/GetProfile",
          Authen: "/authen/line",
        },
      },
    },
  };
  const linking = {
    prefixes: ["", ""],
    config,
  };

  return (
    <NavigationContainer linking={linking} fallback={<Text>Loading...</Text>}>
      {Drawer()}
    </NavigationContainer>
  );
}

export default web;
