import React, { useEffect, useState } from "react";
import {
  View,
  Text,
  Image,
  Dimensions,
  Animated,
  TouchableOpacity,
  StyleSheet,
  ScrollView,
  TextInput,
  FlatList,
} from "react-native";
import {
  MaterialCommunityIcons,
  FontAwesome,
  FontAwesome5,
  MaterialIcons,
  AntDesign,
} from "@expo/vector-icons";
import Modal from "modal-react-native-web";
import { useRecoilValue } from "recoil";
import {
  idGradebook,
  pageTeacherState,
  tokenState,
  workState,
} from "../../reducer/recoil";
import { apiservice } from "../../service";
import BottomBar from "../components/bottombar";
import moment from "moment";
import { useWindowSize } from "../components/responsive";
import axios from "axios";
import { useIsFocused } from "@react-navigation/native";
const { width, height } = Dimensions.get("window");
export default function learnerInformation({ navigation, Work }) {
  const [modalVisible, setModalVisible] = useState(false);
  const [mm, setMM] = useState(false);
  const [yy, setYY] = useState(false);
  const [scoreIndex, setScoreIndex] = useState();
  const [dataModal, setDataModal] = useState({});
  const [comment, setcomment] = useState("");
  const [search, setSearch] = useState("");
  const [searchm, setSearchm] = useState("");
  const [searchy, setSearchy] = useState("");
  const [sumScore, setSumScore] = useState();
  const focus = useIsFocused();

  const scoreList = ["ผ่าน", "ไม่ผ่าน"];
  const size = useWindowSize();
  const scoreChangeHandler = (index) => {
    setScoreIndex((preIndex) => index);
  };

  const [DATA, setData] = useState([]);
  const [DATA1, setData1] = useState([]);
  const token = useRecoilValue(tokenState);
  const dataGrade = useRecoilValue(idGradebook);

  const [page, setPage] = useState(0);
  const [totals, setTotals] = useState(0);
  useEffect(() => {
    sum();
  }, [focus, modalVisible]);
  async function sum(params) {
    const response1 = await apiservice({
      path:
        params == undefined
          ? "/start-course/getgradebookstudent/" + dataGrade.id + "?page=" + "0"
          : "/start-course/getgradebookstudent/" +
            dataGrade.id +
            "?page=" +
            params,
      method: "get",
      token: token?.accessToken,
    });
    if (response1.status == 200) {
      setTotals(parseInt(response1.data.total - 1));
      // return response1.data;
      const data = await Promise.all(
        response1.data.results.map(async (i) => {
          const res = await apiservice({
            path:
              "/start-course/myscore?course_id=" +
              dataGrade.id +
              "&uid=" +
              i?.uid,
            method: "get",
          });
          if (res.status == 200) {
            return { ...i, results: res.data.results };
          }
        })
      );
      setData(data.filter((e) => e.results));
    }
  }
  async function getgradebookstudent() {
    const response1 = await apiservice({
      path: "/start-course/getgradebookstudent/" + Work.id,
      method: "get",
      token: token?.accessToken,
    });
    if (response1.status == 200) {
      setData1(response1.data.results);
    }
  }

  function tranfer(params) {
    let num = 0;
    params.map((item) => {
      item.listen_detail.map((e) => {
        if (e.type != "EXAM" && e.type != "WRITING" && e.type != "WORK")
          num = num + 1;
      });
    });
    return num;
  }

  function checktranfer(params) {
    let num = 0;
    params.map((item) => {
      item.listen_detail.map((e) => {
        if (
          e.type != "EXAM" &&
          e.type != "WRITING" &&
          e.type != "WORK" &&
          e.status == true
        )
          num = num + 1;
      });
    });
    return num;
  }

  function checktranfer1(params) {
    let num = 0;
    params.map((item) => {
      item.listen_detail.map((e) => {
        if (e.type == "WORK" && e.status == true) num = num + 1;
      });
    });
    return num;
  }

  function percentcondition(params) {
    let num = 0;
    params
      ?.reverse()
      ?.filter(
        (ele, ind) =>
          ind === params?.findIndex((elem) => elem?.type === ele.type)
      )
      ?.map((item) => {
        if (item.type == "WRITING") {
          num = num + item.totals;
        } else {
          num = num + item.totals * 10;
        }
        // item.listen_detail.map((e) => {
        //   if (e.type == "WORK" && e.status == true) num = num + 1;
        // });
      });
    return num;
  }

  function tranferWork(params) {
    let num = 0;
    params.map((item) => {
      item.listen_detail.map((e) => {
        if (e.type == "WORK") num = num + 1;
      });
    });
    return num;
  }
  const m = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
  ];
  const y = [
    moment(new Date()).add(+543, "y").format("YYYY"),
    moment(new Date()).add(-1, "y").add(+543, "y").format("YYYY"),
    moment(new Date()).add(-2, "y").add(+543, "y").format("YYYY"),
    moment(new Date()).add(-3, "y").add(+543, "y").format("YYYY"),
  ];

  return (
    <View style={{ minHeight: 1320, paddingHorizontal: 50 }}>
      <Text style={styles.textGradebook}>รายชื่อผู้เรียนทั้งหมด</Text>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <View
          style={{
            flexDirection: size.width < 500 ? "column" : "row",
            marginTop: 12,
            width: size.width < 500 ? "90%" : "70%",
          }}
        >
          <View style={{ flexDirection: "row" }}>
            <TextInput
              onChangeText={(text) => {
                setSearch(text);
              }}
              autoComplete="off"
              placeholder="ค้นหาชื่อ .."
              placeholderTextColor="#646464"
              style={[
                styles.inputSearchName,
                { maxWidth: size.width < 1280 ? 200 : 400 },
              ]}
            />
            <TouchableOpacity style={styles.buttonSearch}>
              <MaterialCommunityIcons
                name="magnify"
                size={24}
                color="#FFFFFF"
              />
            </TouchableOpacity>
          </View>
          <View style={{ width: size.width < 500 ? "100%" : "20%" }}>
            <TouchableOpacity
              onPress={() => {
                setMM((val) => !val);
              }}
              style={{
                width: "100%",
                maxWidth: 400,
                height: 40,
                borderWidth: 1,
                borderColor: "#D1DDE5",
                paddingHorizontal: 15,
                justifyContent: "center",
                marginLeft: size.width < 500 ? 0 : 10,
                marginTop: size.width < 500 ? 20 : 0,
              }}
            >
              <Text
                style={{ fontSize: 20, fontFamily: "Prompt", color: "#646464" }}
              >
                {searchm == "" ? "เดือน" : searchm}
              </Text>
              <AntDesign
                name="caretdown"
                size={18}
                color="#CCC"
                style={{ position: "absolute", right: 10 }}
              />
            </TouchableOpacity>
            {mm && (
              <View
                style={{
                  position: "absolute",
                  width: "100%",
                  marginTop: size.width < 500 ? 60 : 40,
                  backgroundColor: "#FFF",
                  borderWidth: 1,
                  borderColor: "#CCC",
                  marginLeft: size.width < 500 ? 0 : 10,
                  zIndex: 99,
                }}
              >
                {m.map((item) => {
                  return (
                    <TouchableOpacity
                      onPress={() => {
                        setSearchm(item);
                        setMM(false);
                      }}
                      style={{ backgroundColor: "#FFF", paddingLeft: 10 }}
                    >
                      <Text
                        style={[
                          styles.textDetail,
                          { width: null, textAlign: "left" },
                        ]}
                      >
                        {item}
                      </Text>
                    </TouchableOpacity>
                  );
                })}
              </View>
            )}
          </View>
          <View
            style={{
              width: size.width < 500 ? "100%" : "20%",
              marginLeft: size.width < 500 ? 0 : 10,
              zIndex: -1,
            }}
          >
            <TouchableOpacity
              onPress={() => {
                setYY((val) => !val);
              }}
              style={{
                width: "100%",
                maxWidth: 400,
                height: 40,
                borderWidth: 1,
                borderColor: "#D1DDE5",
                paddingHorizontal: 15,
                justifyContent: "center",
                marginLeft: size.width < 500 ? 0 : 10,
                marginTop: size.width < 500 ? 20 : 0,
              }}
            >
              <Text
                style={{ fontSize: 20, fontFamily: "Prompt", color: "#646464" }}
              >
                {searchy == "" ? "ปี" : searchy}
              </Text>
              <AntDesign
                name="caretdown"
                size={18}
                color="#CCC"
                style={{ position: "absolute", right: 10 }}
              />
            </TouchableOpacity>
            {yy && (
              <View
                style={{
                  position: "absolute",
                  width: "100%",
                  marginTop: size.width < 500 ? 60 : 40,
                  backgroundColor: "#FFF",
                  borderWidth: 1,
                  borderColor: "#CCC",
                  marginLeft: size.width < 500 ? 0 : 10,
                  zIndex: 98,
                }}
              >
                {y.map((item) => {
                  return (
                    <TouchableOpacity
                      onPress={() => {
                        setSearchy(item);
                        setYY(false);
                      }}
                      style={{ backgroundColor: "#FFF", paddingLeft: 10 }}
                    >
                      <Text
                        style={[
                          styles.textDetail,
                          { width: null, textAlign: "left" },
                        ]}
                      >
                        {item}
                      </Text>
                    </TouchableOpacity>
                  );
                })}
              </View>
            )}
          </View>
        </View>
        {size.width >= 1280 && (
          <TouchableOpacity
            onPress={async () => {
              const res = await axios.post(
                "https://api.courseoncloud.com/api/image/exportExcel",
                {
                  data: DATA.filter((item) => {
                    return item != null;
                  }).filter((item) => {
                    return (
                      item?.user?.user_accounts?.firstName?.includes(search) ||
                      item?.user?.username?.includes(search) ||
                      item?.user?.user_accounts?.lastName?.includes(search)
                    );
                  }),
                }
              );

              if (res.status == 200) {
                window.open(
                  "https://api.courseoncloud.com/api/image/readXLXS?time=" +
                    res?.data
                );
              }
            }}
            style={{
              maxWidth: 177,
              width:
                size.width < 500
                  ? "50%"
                  : size.width < 1280
                  ? size.width / 6
                  : "100%",
              height: 40,
              backgroundColor: "#0D88F0",
              borderRadius: 24,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text
              style={[
                styles.textButton,
                { fontSize: size.width < 1280 ? 16 : 18 },
              ]}
            >
              ดาวน์โหลดข้อมูล
            </Text>
          </TouchableOpacity>
        )}
      </View>
      <ScrollView horizontal={true} style={{ zIndex: -1 }}>
        <View
          style={{
            width:
              size.width < 500
                ? size.width * 2.5
                : size.width < 1280
                ? size.width * 0.75
                : size.width * 0.6,
          }}
        >
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              marginTop: 36,
              marginBottom: 12,
              alignItems: "center",
              zIndex: -1,
            }}
          >
            <Text style={{ width: "5%" }}></Text>
            <Text
              numberOfLines={1}
              style={[styles.textTitle, { width: "15%" }]}
            >
              ชื่อ
            </Text>
            <Text style={styles.textTitle}>วันที่เริ่มเรียน</Text>
            <Text style={[styles.textTitle]}>บทเรียน</Text>
            <Text style={[styles.textTitle, { width: "15%" }]}>
              งานที่มอบหมาย
            </Text>
            <Text style={[styles.textTitle]}>แบบทดสอบ</Text>
            <Text style={[styles.textTitle, { width: "15%" }]}>
              เปอร์เซ็นการเรียน
            </Text>
            <Text style={[styles.textTitle]}>ผลการเรียน</Text>
            <Text style={{ width: "7%" }}></Text>
          </View>

          <FlatList
            numColumns={1}
            data={DATA.filter((item) => {
              return item != null;
            })
              .filter((item) => {
                return (
                  item?.user?.user_accounts?.firstName?.includes(search) ||
                  item?.user?.username?.includes(search) ||
                  item?.user?.user_accounts.lastName?.includes(search)
                );
              })
              .filter((item) => {
                return searchm == ""
                  ? true
                  : moment(item.start_date).format("MM") == searchm;
              })
              .filter((item) => {
                return searchy == ""
                  ? true
                  : moment(item.start_date).add(543, "y").format("YYYY") ==
                      searchy;
              })
              .filter((e, i) => {
                return i < 10;
              })}
            style={{ zIndex: -1 }}
            renderItem={({ item, index }) => {
              return (
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    borderTopWidth: index == 0 && 1,
                    borderTopColor: "#BBBBBB",
                    borderBottomWidth: 1,
                    borderBottomColor: "#BBBBBB",
                    paddingVertical: 15,
                    alignItems: "center",
                  }}
                >
                  <View style={{ width: "5%" }}>
                    <Image
                      resizeMode="cover"
                      style={{
                        width: 60,
                        height: 60,
                        backgroundColor: "#CCCCCC",
                      }}
                      source={{
                        uri:
                          "https://api.courseoncloud.com/api/image/getimage/" +
                          item?.user?.user_accounts?.image_url,
                      }}
                    />
                  </View>
                  <Text
                    numberOfLines={1}
                    style={[styles.textDetail, { width: "15%" }]}
                  >
                    {item?.user?.user_accounts?.firstName != null &&
                      item?.user?.user_accounts?.firstName + " "}
                    {item?.user?.user_accounts?.firstName != null &&
                      item?.user?.user_accounts?.lastName}
                    {item?.user?.user_accounts?.firstName == null &&
                      item.user.username}
                  </Text>
                  <Text style={styles.textDetail}>
                    {item.start_date
                      ? moment(item.start_date).format("DD-MM-YYYY")
                      : "-"}
                  </Text>
                  <Text style={[styles.textDetail]}>
                    {checktranfer(item.progress)}/
                    {tranfer(item.courses.studymodel)}
                  </Text>
                  <Text style={[styles.textDetail, { width: "15%" }]}>
                    {checktranfer1(item.progress)}/
                    {tranferWork(item.courses.studymodel)}
                  </Text>
                  <Text style={[styles.textDetail]}>
                    {item?.results?.TOTAL_EXAM == null
                      ? "-"
                      : item?.results?.TOTAL_EXAM + "%"}
                  </Text>
                  <Text style={[styles.textDetail, { width: "15%" }]}>
                    {item?.results?.EXAM == null
                      ? "-"
                      : item?.results?.EXAM + "%"}
                  </Text>
                  <Text style={styles.textDetail}>
                    {!item.results.APPROVE ? "ไม่ผ่าน" : "ผ่าน"}
                  </Text>
                  <View
                    style={{
                      width: "7%",
                    }}
                  >
                    <TouchableOpacity
                      onPress={() => {
                        setDataModal(item);
                        setcomment(item.comment);
                        setScoreIndex(
                          item.results.APPROVE ? "ผ่าน" : "ไม่ผ่าน"
                        );

                        setModalVisible(true);
                      }}
                      style={{
                        width: "100%",
                        maxWidth: 68,
                        height: 32,
                        backgroundColor: "#0D88F0",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: 16,
                        alignSelf: "center",
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 16,
                          fontFamily: "Prompt",
                          color: "#FFFFFF",
                        }}
                      >
                        แก้ไข
                      </Text>
                    </TouchableOpacity>
                  </View>
                </View>
              );
            }}
            ListFooterComponent={
              <BottomBar
                onPrev={() => {
                  sum(page - 1);
                  setPage((val) => val - 1);
                }}
                onFirst={() => {
                  sum(0);
                  setPage(0);
                }}
                onFinal={() => {
                  sum(parseInt(totals));
                  setPage(parseInt(totals));
                }}
                onNext={() => {
                  sum(page + 1);
                  setPage((val) => val + 1);
                }}
                onOne={() => {
                  sum(page);
                  setPage((val) => val);
                }}
                onTwo={() => {
                  sum(page + 1);
                  setPage((val) => val + 1);
                }}
                onThree={() => {
                  sum(page + 2);
                  setPage((val) => val + 2);
                }}
                page={page}
                totals={totals}
              />
            }
            // data={data?.filter((e, i) => {
            //   return i < 10;
            // })}
          />
        </View>
      </ScrollView>

      <Modal
        animationType="slide"
        transparent={true}
        visible={modalVisible}
        onRequestClose={() => {
          Alert.alert("Modal has been closed.");
          setModalVisible(!modalVisible);
        }}
      >
        <View
          style={{
            width: "100%",
            height: "100%",
            backgroundColor: "#000000bb",
            justifyContent: "center",
          }}
        >
          <View
            style={{
              width: "100%",
              maxWidth: 902,
              height: "100%",
              maxHeight: 706,
              backgroundColor: "#FFFFFF",
              borderWidth: 1,
              borderColor: "#0D88F0",
              padding: 50,
              alignSelf: "center",
            }}
          >
            <Text
              style={{
                fontSize: 25,
                fontFamily: "Prompt",
                color: "#0B2051",
                alignSelf: "center",
              }}
            >
              แก้ไขผลการเรียน
            </Text>
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                marginTop: 25,
              }}
            >
              <Text style={styles.textModal}>
                ชื่อ :{" "}
                {dataModal.user != undefined &&
                  dataModal.user.user_accounts.firstName + " "}
                {dataModal.user != undefined &&
                  dataModal.user.user_accounts.lastName}
              </Text>
              <Text style={styles.textModal}>
                เปอร์เซ็นการเรียน :{" "}
                {dataModal.user != undefined && dataModal.courses.percent}%
              </Text>
              <Text style={styles.textModal}>
                ผลการเรียนเดิม :{" "}
                {dataModal.Type != "APPROVE" ? "ไม่ผ่าน" : "ผ่าน"}
              </Text>
            </View>
            <View style={{ flexDirection: "row", marginTop: 30 }}>
              <Text style={styles.textModal}>แก้ผลการเรียนเป็น : </Text>
              {scoreList.map((data, index) => (
                <TouchableOpacity
                  key={data}
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    marginLeft: 10,
                  }}
                  onPress={() => {
                    setScoreIndex(data);
                  }}
                >
                  <MaterialCommunityIcons
                    name={
                      data === scoreIndex
                        ? "checkbox-blank"
                        : "checkbox-blank-outline"
                    }
                    size={30}
                    color="#0D88F0"
                  />
                  <Text style={styles.textModal}>{data}</Text>
                </TouchableOpacity>
              ))}
              {/* <TouchableOpacity
                style={{
                  width: 30,
                  height: 30,
                  borderRadius: 5,
                  backgroundColor: "#0D88F0",
                }}
              ></TouchableOpacity>
              <Text>ผ่าน</Text> */}
            </View>
            <Text style={[styles.textModal, { marginTop: 30 }]}>
              หมายเหตุจากผู้สอน
            </Text>
            <TextInput
              autoComplete="off"
              defaultValue={dataModal.comment == null ? "" : dataModal.comment}
              onChangeText={(text) => {
                setcomment(text);
              }}
              multiline
              style={{
                width: "100%",
                minHeight: 200,
                borderWidth: 1,
                borderColor: "#0D88F0",
                padding: 15,
                marginTop: 30,
                textAlignVertical: "top",
                fontFamily: "Prompt",
                fontSize: 16,
                color: "#0B2051",
              }}
            />
            <View
              style={{
                flexDirection: "row",
                marginTop: 35,
                alignSelf: "center",
              }}
            >
              <TouchableOpacity
                onPress={async () => {
                  const res = await apiservice({
                    path: "/start-course/update/" + dataModal.id,
                    method: "put",
                    body: {
                      Type: scoreIndex == "ผ่าน" ? "APPROVE" : "REJECT",
                      comment,
                    },
                    token: token?.accessToken,
                  });
                  if (res.status == 200) {
                    sum();

                    setTimeout(() => {
                      setModalVisible(!modalVisible);
                    }, 500);
                  }
                }}
                style={{
                  width: 150,
                  height: 60,
                  backgroundColor: "#0D88F0",
                  borderRadius: 10,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Text
                  style={{
                    fontSize: 25,
                    fontFamily: "Prompt",
                    color: "#FFFFFF",
                  }}
                >
                  ตกลง
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => setModalVisible(!modalVisible)}
                style={{
                  width: 150,
                  height: 60,
                  borderWidth: 1,
                  borderColor: "#0D88F0",
                  borderRadius: 10,
                  justifyContent: "center",
                  alignItems: "center",
                  marginLeft: 30,
                }}
              >
                <Text
                  style={{
                    fontSize: 25,
                    fontFamily: "Prompt",
                    color: "#0D88F0",
                  }}
                >
                  ยกเลิก
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </Modal>
    </View>
  );
}
const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: "100%",
    backgroundColor: "#FFFFFF",
  },
  textGradebook: {
    fontFamily: "Prompt",
    fontSize: 25,
    color: "#0B2051",
    marginTop: 40,
  },
  inputSearchName: {
    width: "100%",
    maxWidth: 400,
    height: 40,
    borderWidth: 1,
    borderColor: "#D1DDE5",
    paddingHorizontal: 15,
    fontFamily: "Prompt",
    fontSize: 20,
    color: "#646464",
  },
  buttonSearch: {
    width: 40,
    height: 40,
    backgroundColor: "#0D88F0",
    justifyContent: "center",
    alignItems: "center",
  },
  textTitle: {
    fontFamily: "Prompt",
    fontSize: 20,
    color: "#0B2051",
    width: "10%",
    textAlign: "center",
  },
  textDetail: {
    fontFamily: "Prompt",
    fontSize: 16,
    color: "#0B2051",
    width: "10%",
    textAlign: "center",
  },
  textModal: {
    fontSize: 20,
    fontFamily: "Prompt",
    color: "#0B2051",
  },
  textButton: {
    fontSize: 25,
    fontFamily: "Prompt",
    color: "#FFFFFF",
  },
});
