import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";
const { persistAtom } = recoilPersist({
  key: "recoil-persist",
  storage: localStorage,
});

export const tokenState = atom({
  key: "token",
  default: {},
  effects_UNSTABLE: [persistAtom],
});

export const userState = atom({
  key: "user",
  default: {},
  effects_UNSTABLE: [persistAtom],
});
export const courseState = atom({
  key: "course",
  default: {},
  effects_UNSTABLE: [persistAtom],
});
export const lessonState = atom({
  key: "lesson",
  default: {},
  effects_UNSTABLE: [persistAtom],
});
export const otherStates = atom({
  key: "otherStates",
  default: {},
  effects_UNSTABLE: [persistAtom],
});
export const lessonVideoState = atom({
  key: "lessonVideo",
  default: {},
  effects_UNSTABLE: [persistAtom],
});
export const UniversityState = atom({
  key: "university",
  default: {},
  effects_UNSTABLE: [persistAtom],
});
export const testState = atom({
  key: "test",
  default: {},
  effects_UNSTABLE: [persistAtom],
});
export const subjecttestState = atom({
  key: "subjecttest",
  default: {},
  effects_UNSTABLE: [persistAtom],
});
export const workState = atom({
  key: "work",
  default: {},
  effects_UNSTABLE: [persistAtom],
});
export const registerState = atom({
  key: "register",
  default: {},
  effects_UNSTABLE: [persistAtom],
});
export const dataUniversityState = atom({
  key: "dataUniversity",
  default: {},
  effects_UNSTABLE: [persistAtom],
});
export const pageState = atom({
  key: "page",
  default: 0,
  effects_UNSTABLE: [persistAtom],
});
export const pageTeacherState = atom({
  key: "pageteacher",
  default: 0,
  effects_UNSTABLE: [persistAtom],
});
export const idGradebook = atom({
  key: "idGrade",
  default: {},
  effects_UNSTABLE: [persistAtom],
});
export const courseDetail = atom({
  key: "courseDe",
  default: {},
  effects_UNSTABLE: [persistAtom],
});
export const courseAdd = atom({
  key: "courseAd",
  default: {},
  effects_UNSTABLE: [persistAtom],
});
export const cername = atom({
  key: "cer",
  default: {},
  effects_UNSTABLE: [persistAtom],
});
export const dataGradebook = atom({
  key: "dataGrade",
  default: [],
  effects_UNSTABLE: [persistAtom],
});
export const pageWork = atom({
  key: "pageWork",
  default: 0,
  effects_UNSTABLE: [persistAtom],
});
export const pageAdminState = atom({
  key: "pageadmin",
  default: 0,
  effects_UNSTABLE: [persistAtom],
});
export const pageSuperAdminState = atom({
  key: "pagesuperadmin",
  default: 0,
  effects_UNSTABLE: [persistAtom],
});
export const winds = atom({
  key: "winds",
  default: {
    height: window.innerHeight,
    width: window.innerWidth,
  },
  effects_UNSTABLE: [persistAtom],
});
