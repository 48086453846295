import React, { useEffect, useState } from "react";
import { View, Text, TouchableOpacity, Image, StyleSheet } from "react-native";
import { useWindowSize } from "./responsive";

export default function buttonLogin({ img, name, onPress, disabled }) {
  const size = useWindowSize();
  return (
    <TouchableOpacity
      disabled={disabled}
      onPress={onPress}
      style={[styles.button, { marginTop: size.width < 800 ? 20 : 50 }]}
    >
      <Image
        style={[
          styles.img,
          {
            width: size.width < 800 ? 30 : 40,
            height: size.width < 800 ? 30 : 40,
          },
        ]}
        source={img}
      />
      <Text style={[styles.text, { fontSize: size.width < 1280 ? 14 : 20 }]}>
        {name}
      </Text>
    </TouchableOpacity>
  );
}
const styles = StyleSheet.create({
  button: {
    width: "50%",
    maxWidth: 500,
    height: 60,
    borderWidth: 1,
    borderColor: "#E0E0E0",
    borderRadius: 5,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 50,
    paddingHorizontal: 15,
  },
  img: {
    width: 40,
    height: 40,
    marginRight: 15,
  },
  text: {
    fontSize: 20,
    fontFamily: "Prompt",
    color: "#646464",
    textAlign: "center",
  },
});
