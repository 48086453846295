import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  Dimensions,
  TouchableOpacity,
  StyleSheet,
  ScrollView,
  TextInput,
  FlatList,
} from "react-native";
import { MaterialCommunityIcons, MaterialIcons } from "@expo/vector-icons";
import HeaderAdmin from "../../components/headerAdmin";
import ButtonMenuSuperAdmin from "../../components/buttonMenuSuperAdmin";
import ProfileAdmin from "../../components/profileAdmin";
import Modal from "modal-react-native-web";
import CourseName from "./courseName";
import TeacherGeneral from "./teacherGeneral";
import CourseTaughtGeneral from "./courseTaughtGeneral";
import TeacherApply from "./teacherApply";
import AllStudent from "./allStudent";
import Category from "./category";
import AddCourse from "./addCourse";
import { apiservice } from "../../../service";
import { tokenState } from "../../../reducer/recoil";
import { useRecoilValue } from "recoil";
import moment from "moment";
import { deleteCourse } from "../../../action/authAction";
import { useWindowSize } from "../../components/responsive";
const { width, height } = Dimensions.get("window");

export default function main({ navigation, route }) {
  const [modalDelItem, setModalDelItem] = useState(false);
  const [page, setPage] = useState(0);
  const [courseName, setCourseName] = useState(false);
  const [detailTeach, setDetailTeach] = useState();
  const [teacherDetail, setTeacherDetail] = useState(false);
  const token = useRecoilValue(tokenState);
  const [DATA, setData] = useState([]);
  const [search, setSearch] = useState("");
  const [detail, setDetail] = useState();
  const [openMenu, setOpenMenu] = useState(false);
  const size = useWindowSize();
  const [id, setId] = useState();
  useEffect(() => {
    apiCall();
  }, [token]);

  async function apiCall() {
    const res = await apiservice({
      path: "/course/getallgeneralcouse?page=1&totalpage=100",
      method: "get",
      token: token?.accessToken,
    });

    if (res?.status == 200) {
      setData(res?.data?.result);
    }
  }
  async function Delete() {
    const Delete = await deleteCourse({
      token: token?.accessToken,
      id: id,
    });
    if (Delete) {
      setModalDelItem(!modalDelItem);
      apiCall();
    }
  }
  return (
    <View style={styles.container}>
      <HeaderAdmin
        onPress={() => {
          setOpenMenu((val) => !val);
        }}
        navigation={navigation}
        headerGeneral
        setPage={setPage}
      />
      <View style={{ width: "100%", flexDirection: "row", height: "100%" }}>
        {openMenu && (
          <View //menu
            style={{
              width: "13%",

              minWidth: 220,
              height: "100%",
              backgroundColor: "#444444",
              position: "absolute",
              zIndex: 99,
            }}
          >
            <View style={{ height: "80%" }}>
              {(token?.role == "SUPERADMIN" ||
                token?.role == "GENERALADMIN") && (
                <ButtonMenuSuperAdmin
                  p1
                  onPress={() => {
                    setOpenMenu(false);
                    navigation.navigate("UniversityCourseSA");
                  }}
                />
              )}
              {(token?.role == "SUPERADMIN" ||
                token?.role == "GENERALADMIN") && (
                <ButtonMenuSuperAdmin
                  p2
                  color
                  border
                  onPress={() => {
                    setOpenMenu(false);
                    navigation.navigate("GeneralCourseSA");
                  }}
                />
              )}
              {(token?.role == "SUPERADMIN" ||
                token?.role == "GENERALADMIN") && (
                <ButtonMenuSuperAdmin
                  p3
                  onPress={() => {
                    setOpenMenu(false);
                    navigation.navigate("AddAdminSA");
                  }}
                />
              )}
              {(token?.role == "ACCOUNTING" || token?.role == "SUPERADMIN") && (
                <ButtonMenuSuperAdmin
                  p4
                  onPress={() => {
                    setOpenMenu(false);
                    navigation.navigate("ReportSA");
                  }}
                />
              )}
              {(token?.role == "SUPERADMIN" ||
                token?.role == "GENERALADMIN") && (
                <ButtonMenuSuperAdmin
                  p5
                  onPress={() => {
                    setOpenMenu(false);
                    navigation.navigate("SlideBannerSA");
                  }}
                />
              )}
              {(token?.role == "SUPERADMIN" ||
                token?.role == "GENERALADMIN") && (
                <ButtonMenuSuperAdmin
                  p6
                  onPress={() => {
                    setOpenMenu(false);
                    navigation.navigate("Discount");
                  }}
                />
              )}
              {(token?.role == "SUPERADMIN" ||
                token?.role == "GENERALADMIN") && (
                <ButtonMenuSuperAdmin
                  p7
                  onPress={() => {
                    setOpenMenu(false);
                    navigation.navigate("Account");
                  }}
                />
              )}
            </View>
            <ProfileAdmin />
          </View>
        )}
        {size.width >= 1280 && (
          <View //menu
            style={{
              width: "13%",

              minWidth: 220,
              height: "100%",
              backgroundColor: "#444444",
            }}
          >
            <View style={{ height: "80%" }}>
              {(token?.role == "SUPERADMIN" ||
                token?.role == "GENERALADMIN") && (
                <ButtonMenuSuperAdmin
                  p1
                  onPress={() => {
                    navigation.navigate("UniversityCourseSA");
                  }}
                />
              )}
              {(token?.role == "SUPERADMIN" ||
                token?.role == "GENERALADMIN") && (
                <ButtonMenuSuperAdmin
                  p2
                  color
                  border
                  onPress={() => {
                    navigation.navigate("GeneralCourseSA");
                  }}
                />
              )}
              {(token?.role == "SUPERADMIN" ||
                token?.role == "GENERALADMIN") && (
                <ButtonMenuSuperAdmin
                  p3
                  onPress={() => {
                    navigation.navigate("AddAdminSA");
                  }}
                />
              )}
              {(token?.role == "ACCOUNTING" || token?.role == "SUPERADMIN") && (
                <ButtonMenuSuperAdmin
                  p4
                  onPress={() => {
                    navigation.navigate("ReportSA");
                  }}
                />
              )}
              {(token?.role == "SUPERADMIN" ||
                token?.role == "GENERALADMIN") && (
                <ButtonMenuSuperAdmin
                  p5
                  onPress={() => {
                    navigation.navigate("SlideBannerSA");
                  }}
                />
              )}
              {(token?.role == "SUPERADMIN" ||
                token?.role == "GENERALADMIN") && (
                <ButtonMenuSuperAdmin
                  p6
                  onPress={() => {
                    navigation.navigate("Discount");
                  }}
                />
              )}
              {(token?.role == "SUPERADMIN" ||
                token?.role == "GENERALADMIN") && (
                <ButtonMenuSuperAdmin
                  p7
                  onPress={() => {
                    navigation.navigate("Account");
                  }}
                />
              )}
            </View>
            <ProfileAdmin />
          </View>
        )}
        <ScrollView>
          {page == 0 && (
            <View
              style={[
                styles.viewDetail,
                { paddingHorizontal: size.width < 500 ? 20 : 55 },
              ]}
            >
              {!courseName ? (
                <View>
                  <View
                    style={{
                      flexDirection: "row",
                      marginTop: 20,
                      alignItems: "center",
                    }}
                  >
                    <TextInput
                      onChangeText={(text) => {
                        setSearch(text);
                      }}
                      autoComplete="off"
                      placeholder="ค้นหาชื่อคอร์ส"
                      placeholderTextColor="#8C8896"
                      style={{
                        width: size.width < 500 ? "80%" : "30%",
                        height: 48,
                        borderRadius: 4,
                        backgroundColor: "#EFEFF3",
                        fontSize: 16,
                        fontFamily: "Prompt",
                        color: "#8C8896",
                        paddingHorizontal: 25,
                      }}
                    />
                    <TouchableOpacity style={{ marginLeft: -40 }}>
                      <MaterialCommunityIcons
                        name="magnify"
                        size={24}
                        color="#0D88F0"
                      />
                    </TouchableOpacity>
                  </View>
                  <ScrollView horizontal>
                    <View
                      style={{
                        width:
                          size.width < 500
                            ? size.width * 2.5
                            : size.width < 1280
                            ? size.width * 1.0
                            : size.width * 0.75,
                        // minHeight: height * 2.5,
                      }}
                    >
                      <View style={styles.viewSubject}>
                        <Text
                          style={[
                            styles.text16B,
                            { paddingLeft: 67, width: "35%" },
                          ]}
                        >
                          ชื่อคอร์ส
                        </Text>
                        <Text style={[styles.text16B, { width: "20%" }]}>
                          หมวดหมู่
                        </Text>
                        <Text style={[styles.text16B, { width: "20%" }]}>
                          วันที่สร้าง
                        </Text>
                        <Text style={[styles.text16B, { width: "20%" }]}>
                          ผู้ที่สร้างคอร์ส
                        </Text>
                        <Text style={[styles.text16B, { width: "5%" }]}></Text>
                      </View>
                      <FlatList
                        numColumns={1}
                        data={DATA.filter((item) => {
                          return (
                            item.available == "APPROVE" &&
                            item.title.includes(search)
                          );
                        })}
                        renderItem={({ item, index }) => {
                          return (
                            <TouchableOpacity
                              onPress={() => {
                                setDetail(item);
                                setCourseName(true);
                              }}
                              style={{
                                flexDirection: "row",
                                justifyContent: "space-between",
                                borderBottomWidth: 1,
                                borderBottomColor: "#E5E5E5",
                                height: 50,
                                alignItems: "center",
                              }}
                            >
                              <Text
                                style={[
                                  styles.text16B,
                                  { paddingLeft: 67, width: "35%" },
                                ]}
                              >
                                {item?.title}
                              </Text>
                              <Text style={[styles.text16B, { width: "20%" }]}>
                                {item?.category?.title}
                              </Text>
                              <Text style={[styles.text16B, { width: "20%" }]}>
                                {moment(item?.createdAt)
                                  .locale("th")
                                  .add(543, "years")
                                  .format("DD MMMM พ.ศ. YYYY")}
                              </Text>
                              <Text style={[styles.text16B, { width: "20%" }]}>
                                {item?.teacher?.firstName +
                                  "  " +
                                  item?.teacher?.lastName}
                              </Text>

                              <TouchableOpacity
                                onPress={() => {
                                  setId(item.id);
                                  setModalDelItem(true);
                                }}
                                style={{ width: "5%", alignItems: "center" }}
                              >
                                <MaterialIcons
                                  name="delete"
                                  size={24}
                                  color="black"
                                />
                              </TouchableOpacity>
                            </TouchableOpacity>
                          );
                        }}
                      />
                    </View>
                  </ScrollView>
                  <Modal
                    transparent={true}
                    visible={modalDelItem}
                    onRequestClose={() => {
                      Alert.alert("Modal has been closed.");
                      setModalDelItem(!modalDelItem);
                    }}
                  >
                    <View style={styles.bgModal}>
                      <View
                        style={[
                          styles.viewDetailModal,
                          {
                            width:
                              size.width < 500
                                ? "80%"
                                : size.width < 1280
                                ? "70%"
                                : "30%",
                            height: 200,
                            paddingVertical: 25,
                          },
                        ]}
                      >
                        <Text
                          style={[
                            styles.textButtonModal,
                            { color: "#444444", alignSelf: "center" },
                          ]}
                        >
                          คุณต้องการจะลบใช่รึไม่
                        </Text>
                        <View
                          style={{
                            flexDirection: "row",
                            alignSelf: "center",
                            marginTop: 39,
                          }}
                        >
                          <TouchableOpacity
                            onPress={() => setModalDelItem(!modalDelItem)}
                            style={styles.buttonModal}
                          >
                            <Text
                              style={[
                                styles.textButtonModal,
                                {
                                  color: "#444444",
                                },
                              ]}
                            >
                              ยกเลิก
                            </Text>
                          </TouchableOpacity>
                          <TouchableOpacity
                            onPress={Delete}
                            style={[
                              styles.buttonModal,
                              {
                                backgroundColor: "#0D88F0",
                                marginLeft: width * 0.05,
                              },
                            ]}
                          >
                            <Text
                              style={[
                                styles.textButtonModal,
                                {
                                  color: "#FFFFFF",
                                },
                              ]}
                            >
                              ยืนยัน
                            </Text>
                          </TouchableOpacity>
                        </View>
                      </View>
                    </View>
                  </Modal>
                </View>
              ) : (
                <CourseName
                  detail={detail}
                  ongoBack={() => {
                    setCourseName(false);
                  }}
                />
              )}
            </View>
          )}
          {page == 1 && (
            <View
              style={[
                styles.viewDetail,
                { paddingHorizontal: size.width < 500 ? 20 : 55 },
              ]}
            >
              {!teacherDetail ? (
                <TeacherGeneral
                  setDetailTeacher={setDetailTeach}
                  onPress={() => {
                    setTeacherDetail(true);
                  }}
                />
              ) : (
                <CourseTaughtGeneral
                  navigation={navigation}
                  detailTeach={detailTeach}
                  ongoBack={() => {
                    setTeacherDetail(false);
                  }}
                />
              )}
            </View>
          )}
          {page == 2 && (
            <View
              style={[
                styles.viewDetail,
                { paddingHorizontal: size.width < 500 ? 20 : 55 },
              ]}
            >
              <TeacherApply navigation={navigation} />
            </View>
          )}
          {page == 3 && (
            <View
              style={[
                styles.viewDetail,
                { paddingHorizontal: size.width < 500 ? 20 : 55 },
              ]}
            >
              <AllStudent />
            </View>
          )}
          {page == 4 && (
            <View
              style={[
                styles.viewDetail,
                { paddingHorizontal: size.width < 500 ? 20 : 55 },
              ]}
            >
              <Category />
            </View>
          )}
          {page == 5 && (
            <View
              style={[
                styles.viewDetail,
                { paddingHorizontal: size.width < 500 ? 20 : 55 },
              ]}
            >
              <AddCourse navigation={navigation} />
            </View>
          )}
        </ScrollView>
      </View>
    </View>
  );
}
const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: "100%",
    backgroundColor: "#FFFFFF",
  },
  text23O: { fontSize: 23, fontFamily: "Prompt", color: "#0D88F0" },
  text16B: {
    fontFamily: "Prompt",
    fontSize: 16,
    color: "#000000",
    width: "23%",
  },

  viewSubject: {
    width: "100%",
    height: 51,
    backgroundColor: "#E5E5E5",
    marginTop: 50,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  bgModal: {
    width: "100%",
    height: "100%",
    backgroundColor: "#000000bb",
    justifyContent: "center",
  },
  viewDetailModal: {
    width: "50%",
    height: 380,
    backgroundColor: "#FFFFFF",
    borderRadius: 20,
    alignSelf: "center",
    paddingHorizontal: width * 0.05,
    paddingVertical: 50,
  },
  buttonModal: {
    width: 102,
    height: 48,
    borderRadius: 4,
    backgroundColor: "#EFEFF3",
    justifyContent: "center",
    alignItems: "center",
  },
  textButtonModal: {
    fontSize: 25,
    fontFamily: "Prompt",
  },
  viewDetail: {
    width: "100%",
    height: "100%",
    paddingHorizontal: 55,
    paddingVertical: 28,
    paddingBottom: height * 0.1,
  },
});
