import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  Image,
  Dimensions,
  Animated,
  TouchableOpacity,
  StyleSheet,
  ScrollView,
  TextInput,
  FlatList,
} from "react-native";
import {
  MaterialCommunityIcons,
  MaterialIcons,
  Feather,
  Ionicons,
  FontAwesome,
} from "@expo/vector-icons";
import RNPickerSelect from "react-native-picker-select";
import Modal from "modal-react-native-web";
import { CSVReader } from "react-papaparse";
import { apiservice } from "../../../service";
import { useRecoilState } from "recoil";
import { tokenState } from "../../../reducer/recoil";
import moment from "moment";
import {
  authActionPutUserByAdmin,
  deleteStudent,
} from "../../../action/authAction";
import { useWindowSize } from "../../components/responsive";
const { width, height } = Dimensions.get("window");
export default function student({
  onPress,
  university_id,
  setNameStudent,
  setLastNameStudent,
  setAllResult,
}) {
  const [modalDelItem, setModalDelItem] = useState(false);
  const [modalMail, setModalMail] = useState(false);
  const [modalAddItem, setModalAddItem] = useState(false);
  const [fac, setFac] = useState([]);
  const [token, setToken] = useRecoilState(tokenState);
  const [deGreeIndex, setDeGreeIndex] = useState(0);
  const [student, setStudent] = useState([]);

  const degreeList = ["ปริญญาตรี", "ปริญญาโท", "ปริญญาเอก"];
  const [id, setId] = useState();
  const [idStudent, setidStudent] = useState();
  const [body, setBody] = useState({
    firstName: "",
    lastName: "",
  });
  const degreeChangeHandler = (index) => {
    setDeGreeIndex(index);
  };
  const [classIndex, setClassIndex] = useState(0);
  const classList = ["ทุกชั้นปี", "ปี1", "ปี2", "ปี3", "ปี4"];
  const [datavalid, setdatavalid] = useState([]);
  const [valid, setvalid] = useState(false);
  const [valid1, setvalid1] = useState(false);
  const [faculti_id, setfaculti_id] = useState(null);
  const [search, setSearch] = useState("");
  const size = useWindowSize();
  const classChangeHandler = (index) => {
    // console.log("index \t", index);

    if (index == 0) {
      getStudent();
    } else {
      getStudent3(token, index);
    }
    setClassIndex((preIndex) => index);
  };
  const placeholder = {
    label: "คณะทั้งหมด",
    value: null,
    color: "#0D88F0",
  };
  const [faculty, setFaculty] = useState([
    { label: "เกษตรศาสตร์", value: "Agriculture" },
    { label: "สถาปัตยกรรมศาสตร์", value: "Architecture" },
    { label: "อักษรศาสตร์", value: "Arts" },
    { label: "บริหารธุกิจ", value: "BusinessAdministration" },
    { label: "นิเทศศาสตร์", value: "CommunicationArts" },
    { label: "ทันตแพทยศาสตร์", value: "Dentistry" },
    { label: "เศรษฐศาสตร์", value: "Economics" },
    { label: "ครุศาสตร์", value: "Education" },
    { label: "วิศวกรรมศาสตร์", value: "Engineering" },
    { label: "ศิลปกรรมศาสตร์", value: "FineAndAppliedArts" },
  ]);

  const handleOpenDialog = (e) => {
    // Note that the ref is set async, so it might be null at some point
    if (buttonRef.current) {
      buttonRef.current.open(e);
    }
  };

  const handleOnFileLoad = async (data) => {
    const date = await Promise.all(
      data.map(async (item, index) => {
        if (index != 0 && item.data["10"] != undefined) {
          if (
            fac.filter((item) => {
              return item.facuty_name_th + "/" + item.major_name_th;
            }).length > 0
          ) {
            try {
              const response = await apiservice({
                path: "/authen/registerstudent",
                method: "POST",
                body: {
                  code: item.data["0"],
                  username: item.data["1"],
                  email: item.data["2"],
                  password: item.data["0"],
                  firstName: item.data["3"],
                  lastName: item.data["4"],
                  telNo: item.data["5"],
                  address: item.data["6"],
                  balance_info: "KBANK",
                  balance_no: "0",
                  promptpay: "0",
                  education: {},
                  organization: item.data["7"],
                  position: item.data["8"],
                  about_us: item.data["9"],
                  facebook_url: "",
                  instagram_url: "",
                  twitter_url: "",
                  approve: true,
                  class: item.data["12"],
                  degree:
                    item.data["13"] == "ตรี"
                      ? 0
                      : item.data["13"] == "โท"
                      ? 1
                      : 2,
                  faculti_id: fac.filter((items) => {
                    return (
                      items.facuty_name_th + "/" + items.major_name_th ==
                      item.data["10"] + "/" + item.data["11"]
                    );
                  })[0].id,
                  university_id: university_id,
                },
              });

              getStudent(1);
              if (response.status != 200) {
                setdatavalid((val) =>
                  val.concat(
                    " Username " +
                      item.data["1"] +
                      " " +
                      " Email " +
                      item.data["2"]
                  )
                );
                setvalid(true);
              } else {
                setdatavalid((val) =>
                  val.concat(
                    " Username " +
                      item.data["1"] +
                      " " +
                      " Email " +
                      item.data["2"]
                  )
                );
                setvalid1(true);
              }
              return { ...item, ...response };
            } catch (error) {}
          }
        }
      })
    );
    getStudent();
  };

  const handleOnError = (err, file, inputElem, reason) => {};

  const handleOnRemoveFile = (data) => {};
  useEffect(() => {
    if (token) {
      getStudent();
      getFaculty(token);
    }
  }, [token]);
  async function sendMail(params) {
    const res = await apiservice({
      path: "/authen/resend/" + params,
      method: "get",
    });
  }
  async function getStudent(token) {
    try {
      const response = await apiservice({
        path:
          "/authen/getstudent?university_id=" +
          university_id +
          "&page=1&totalpage=100",
        method: "get",
        //token: token?.accessToken,
      });

      setStudent(response.data !== undefined && response.data.result);
    } catch (error) {
      throw error;
    }
  }

  async function getStudent1(token, value) {
    try {
      const response = await apiservice({
        path:
          "/authen/getstudent?university_id=" +
          university_id +
          "&page=1&totalpage=100&grade_id=" +
          value,
        method: "get",
        //token: token?.accessToken,
      });

      setStudent(response.data !== undefined && response.data.result);
    } catch (error) {
      throw error;
    }
  }
  async function getStudent2(token, value) {
    try {
      if (value != null && value != "คณะทั้งหมด") {
        const response = await apiservice({
          path:
            "/authen/getstudent?university_id=" +
            university_id +
            "&page=1&totalpage=100&faculty_id=" +
            value,
          method: "get",
          //token: token?.accessToken,
        });

        setStudent(response.data !== undefined && response.data.result);
      } else {
        getStudent(1);
      }
    } catch (error) {
      throw error;
    }
  }
  async function getStudent3(token, value) {
    try {
      const response = await apiservice({
        path:
          "/authen/getstudent?university_id=" +
          university_id +
          "&page=1&totalpage=100&year_id=" +
          value,
        method: "get",
        //token: token?.accessToken,
      });

      setStudent(response.data !== undefined && response.data.result);
    } catch (error) {
      throw error;
    }
  }

  async function getFaculty(token) {
    try {
      const response = await apiservice({
        path:
          "/course/getfaculty?page=1&totalpage=100&university_id=" +
          university_id,
        method: "get",
        token: token?.accessToken,
      });

      setFac(response.data.result);
      setFaculty(
        response.data.result.map((item) => {
          return {
            label: item.facuty_name_th + "/" + item.major_name_th,
            value: item.id.toString(),
          };
        })
      );
    } catch (error) {
      throw error;
    }
  }

  async function editname() {
    const edit = await authActionPutUserByAdmin({
      state: body,
      token: token?.accessToken,
      id: id,
    });
    if (edit) {
      setBody({
        firstName: "",
        lastName: "",
      });
      setModalAddItem(!modalAddItem);
      getStudent();
    }
  }
  async function Delete() {
    const Delete = await deleteStudent({
      token: token?.accessToken,
      id: id,
    });
  }
  const buttonRef = React.createRef();
  return (
    <View>
      <Modal
        animationType="none"
        transparent={true}
        visible={valid}
        onRequestClose={() => {
          setvalid(!valid);
        }}
      >
        {valid && (
          <View
            style={{
              width: width,
              height: height,
              backgroundColor: "#000000bb",
              justifyContent: "center",
            }}
          >
            <View
              style={{
                width: width * 0.5,
                paddingVertical: 25,
                backgroundColor: "#fff",
                paddingHorizontal: 20,
                justifyContent: "center",
                alignSelf: "center",
              }}
            >
              <Text
                style={[
                  styles.TextButton,
                  {
                    color: "#ff0000",
                    fontFamily: "Prompt",
                    fontSize: 20,
                    margin: 10,
                    alignSelf: "center",
                    textAlign: "center",
                  },
                ]}
              >
                {
                  "เกิดข้อผิดพลาดในการเพิ่มรายชื่อดังต่อไปนี้เนื่องจากอีเมลหรือชื่อผู้ใช้งานซ้ำ\nกรุณาตรวจสอบใหม่อีกครั้ง"
                }
              </Text>
              {datavalid.map((item) => {
                return (
                  <Text
                    style={[
                      styles.TextButton,
                      {
                        color: "#ff0000",
                        margin: 10,
                        alignSelf: "center",
                        fontFamily: "Prompt",
                      },
                    ]}
                  >
                    {item}
                  </Text>
                );
              })}
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignSelf: "center",
                  marginTop: 20,
                }}
              >
                <TouchableOpacity
                  onPress={() => {
                    setdatavalid([]);
                    setvalid(false);
                  }}
                  style={{
                    width: 120,
                    height: 35,
                    borderWidth: 1,
                    overflow: "hidden",
                    borderColor: "#000",
                    borderRadius: 5,
                    justifyContent: "center",
                  }}
                >
                  <Text
                    style={{
                      fontFamily: "Prompt",
                      fontSize: 15,
                      alignSelf: "center",
                    }}
                  >
                    ปิด
                  </Text>
                </TouchableOpacity>
              </View>
            </View>
          </View>
        )}
      </Modal>
      <Modal
        animationType="none"
        transparent={true}
        visible={valid1}
        onRequestClose={() => {
          setvalid1(!valid1);
        }}
      >
        {valid1 && (
          <View
            style={{
              width: width,
              height: height,
              backgroundColor: "#000000bb",
              justifyContent: "center",
            }}
          >
            <View
              style={{
                width: width * 0.5,
                paddingVertical: 25,
                backgroundColor: "#fff",
                paddingHorizontal: 20,
                justifyContent: "center",
                alignSelf: "center",
              }}
            >
              <Text
                style={[
                  styles.TextButton,
                  {
                    color: "#74C745",
                    fontFamily: "Prompt",
                    margin: 10,
                    alignSelf: "center",
                    fontSize: 20,
                  },
                ]}
              >
                {"เพิ่มรายชื่อนักเรียนสำเร็จ"}
              </Text>
              {datavalid.map((item) => {
                return (
                  <Text
                    style={[
                      styles.TextButton,
                      {
                        color: "black",
                        margin: 10,
                        alignSelf: "center",
                        fontFamily: "Prompt",
                      },
                    ]}
                  >
                    {item}
                  </Text>
                );
              })}
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignSelf: "center",
                  marginTop: 20,
                }}
              >
                <TouchableOpacity
                  onPress={() => {
                    setdatavalid([]);
                    setvalid1(false);
                  }}
                  style={{
                    width: 120,
                    height: 35,
                    borderWidth: 1,
                    overflow: "hidden",
                    borderColor: "#000",
                    borderRadius: 5,
                    justifyContent: "center",
                  }}
                >
                  <Text
                    style={{
                      fontFamily: "Prompt-Regular",
                      fontSize: 15,
                      alignSelf: "center",
                      fontFamily: "Prompt",
                    }}
                  >
                    ปิด
                  </Text>
                </TouchableOpacity>
              </View>
            </View>
          </View>
        )}
      </Modal>
      <View
        style={{
          flexDirection: "row",
          marginTop: 20,
          justifyContent: "space-between",
        }}
      >
        <View style={{ flexDirection: size.width < 500 ? "column" : "row" }}>
          {degreeList.map((data, index) => (
            <TouchableOpacity
              key={data}
              style={{
                flexDirection: "row",
                alignItems: "center",
                marginLeft: size.width < 500 ? 0 : 40,
              }}
              onPress={degreeChangeHandler.bind(this, index)}
            >
              <MaterialIcons
                name={
                  index === deGreeIndex
                    ? "radio-button-checked"
                    : "radio-button-unchecked"
                }
                size={30}
                color={index === deGreeIndex ? "#0D88F0" : "#808D93"}
              />
              <Text
                style={[
                  styles.text23O,
                  { color: index === deGreeIndex ? "#0D88F0" : "#808D93" },
                ]}
              >
                {data}
              </Text>
            </TouchableOpacity>
          ))}
        </View>
        {size.width >= 1280 && (
          <CSVReader
            ref={buttonRef}
            onFileLoad={handleOnFileLoad}
            onError={handleOnError}
            noClick
            noDrag
            onRemoveFile={handleOnRemoveFile}
          >
            {({ file }) => (
              <aside
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginBottom: 10,
                }}
              >
                <button
                  type="button"
                  onClick={handleOpenDialog}
                  style={{
                    width: 140,
                    height: 40,
                    backgroundColor: "#0D88F0",
                    borderRadius: 20,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Text style={styles.text16W}>+ Add New</Text>
                </button>
              </aside>
            )}
          </CSVReader>
        )}
      </View>
      <TextInput
        onChangeText={(text) => {
          setSearch(text);
        }}
        autoComplete="off"
        placeholder="ค้นหาชื่อนักศึกษา"
        placeholderTextColor="#8C8896"
        style={{
          width: size.width < 500 ? "80%" : "30%",
          height: 48,
          borderRadius: 4,
          backgroundColor: "#EFEFF3",
          fontSize: 16,
          fontFamily: "Prompt",
          color: "#8C8896",
          paddingHorizontal: 25,
          marginTop: 30,
        }}
      />
      <View
        style={{
          flexDirection: size.width < 500 ? "column" : "row",
          marginTop: height * 0.05,
        }}
      >
        <RNPickerSelect
          placeholder={placeholder}
          onValueChange={(val) => {
            setfaculti_id(val);
          }}
          items={faculty}
          style={{
            inputWeb: {
              borderColor: "#0D88F0",
              backgroundColor: "#FFFFFF",
              width: size.width < 1280 ? 200 : 400,
              height: 32,
              color: "#0D88F0",
              fontFamily: "Prompt",
              fontSize: 16,
              paddingHorizontal: 10,
              marginBottom: size.width < 500 && 20,
            },
          }}
        />
        {classList.map((data, index) => (
          <TouchableOpacity
            key={data}
            style={{
              flexDirection: "row",
              alignItems: "center",
              marginLeft: size.width < 500 ? 0 : size.width < 1280 ? 20 : 40,
            }}
            onPress={classChangeHandler.bind(this, index)}
          >
            <MaterialIcons
              name={
                index === classIndex
                  ? "radio-button-checked"
                  : "radio-button-unchecked"
              }
              size={30}
              color={index === classIndex ? "#0D88F0" : "#808D93"}
            />
            <Text
              style={[
                styles.text23O,
                { color: index === classIndex ? "#0D88F0" : "#808D93" },
              ]}
            >
              {data}
            </Text>
          </TouchableOpacity>
        ))}
      </View>
      <ScrollView horizontal>
        <View
          style={{
            width:
              size.width < 500
                ? size.width * 2.5
                : size.width < 1280
                ? size.width * 1.0
                : size.width * 0.78,
            // minHeight: height * 2.5,
          }}
        >
          <View style={styles.viewSubject}>
            <Text style={[styles.text16B, { paddingLeft: 67, width: "30%" }]}>
              ชื่อนักศึกษา
            </Text>
            <Text style={[styles.text16B, { width: "20%" }]}>ประจำคณะ</Text>
            <Text style={[styles.text16B, { width: "20%" }]}>วันที่สร้าง</Text>
            <Text style={[styles.text16B, { width: "15%" }]}>
              จำนวนคอร์สที่ลงเรียน
            </Text>
            <Text style={[styles.text16B, { width: "5%" }]}></Text>
            <Text style={[styles.text16B, { width: "5%" }]}></Text>
            <Text style={[styles.text16B, { width: "5%" }]}></Text>
          </View>
          <FlatList
            numColumns={1}
            data={student
              .filter((item) => {
                return parseInt(item?.user_accounts?.degree) == deGreeIndex;
              })
              .filter((item) => {
                if (classIndex == 0) {
                  return true;
                } else {
                  return parseInt(item?.user_accounts?.class) == classIndex;
                }
              })
              .filter((item) => {
                if (faculti_id != null && faculti_id != "คณะทั้งหมด") {
                  return item?.user_accounts?.faculti_id == faculti_id;
                } else {
                  return true;
                }
              })
              .sort((a, b) => {
                if (b.faculty[0].facuty_name_th > a.faculty[0].facuty_name_th) {
                  return -1;
                }
                if (b.faculty[0].facuty_name_th < a.faculty[0].facuty_name_th) {
                  return 1;
                }
                return 0;
              })
              .filter((item) => {
                return (
                  item.user_accounts.firstName.includes(search) ||
                  item.user_accounts.lastName.includes(search)
                );
              })}
            renderItem={({ item, index }) => {
              return (
                <TouchableOpacity
                  onPress={() => {
                    setNameStudent(item.user_accounts.firstName);
                    setLastNameStudent(item.user_accounts.lastName);
                    setAllResult(item);
                    onPress();
                  }}
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    borderBottomWidth: 1,
                    borderBottomColor: "#E5E5E5",
                    height: 50,
                    alignItems: "center",
                  }}
                >
                  <Text
                    style={[styles.text16B, { paddingLeft: 67, width: "30%" }]}
                  >
                    {item.user_accounts.firstName} {item.user_accounts.lastName}
                  </Text>
                  <Text style={[styles.text16B, { width: "20%" }]}>
                    {item.faculty[0].facuty_name_th}
                  </Text>
                  <Text style={[styles.text16B, { width: "20%" }]}>
                    {moment(item.user_accounts.createdAt)
                      .add(543, "year")
                      .format("DD MMMM YYYY")}
                  </Text>
                  <Text style={[styles.text16B, { width: "15%" }]}>
                    {item.length}
                  </Text>

                  <TouchableOpacity
                    onPress={() => {
                      sendMail(item.id);
                      setModalMail(true);
                      setTimeout(() => {
                        setModalMail(false);
                      }, 2000);
                    }}
                    style={{
                      width: "5%",
                      alignItems: "center",
                      backgroundColor: "#0D88F0",
                      borderRadius: 5,
                    }}
                  >
                    <Text style={[styles.text16W, { textAlign: "center" }]}>
                      ส่งอีเมล
                    </Text>
                  </TouchableOpacity>
                  <TouchableOpacity
                    onPress={() => {
                      setId(item.id);
                      setBody({
                        ...body,
                        firstName: item.user_accounts.firstName,
                        lastName: item.user_accounts.lastName,
                      });

                      setModalAddItem(true);
                    }}
                    style={{ width: "5%", alignItems: "flex-end" }}
                  >
                    <FontAwesome name="edit" size={24} color="black" />
                  </TouchableOpacity>
                  <TouchableOpacity
                    onPress={() => {
                      setId(item.id);
                      setModalDelItem(true);
                    }}
                    style={{ width: "5%", alignItems: "center" }}
                  >
                    <MaterialIcons name="delete" size={24} color="black" />
                  </TouchableOpacity>
                </TouchableOpacity>
              );
            }}
          />
        </View>
      </ScrollView>
      <Modal
        transparent={true}
        visible={modalDelItem}
        onRequestClose={() => {
          Alert.alert("Modal has been closed.");
          setModalDelItem(!modalDelItem);
        }}
      >
        <View style={styles.bgModal}>
          <View
            style={[
              styles.viewDetailModal,
              {
                width:
                  size.width < 500 ? "80%" : size.width < 1280 ? "70%" : "30%",
                height: 200,
                paddingVertical: 25,
              },
            ]}
          >
            <Text
              style={[
                styles.textButtonModal,
                { color: "#444444", alignSelf: "center" },
              ]}
            >
              คุณต้องการจะลบใช่รึไม่
            </Text>
            <View
              style={{
                flexDirection: "row",
                alignSelf: "center",
                marginTop: 39,
              }}
            >
              <TouchableOpacity
                onPress={() => setModalDelItem(!modalDelItem)}
                style={styles.buttonModal}
              >
                <Text
                  style={[
                    styles.textButtonModal,
                    {
                      color: "#444444",
                    },
                  ]}
                >
                  ยกเลิก
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => {
                  Delete();
                  setTimeout(() => {
                    getStudent();
                  }, 500);
                  setModalDelItem(!modalDelItem);
                }}
                style={[
                  styles.buttonModal,
                  {
                    backgroundColor: "#0D88F0",
                    marginLeft: width * 0.05,
                  },
                ]}
              >
                <Text
                  style={[
                    styles.textButtonModal,
                    {
                      color: "#FFFFFF",
                    },
                  ]}
                >
                  ยืนยัน
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </Modal>
      <Modal
        transparent={true}
        visible={modalMail}
        onRequestClose={() => {
          Alert.alert("Modal has been closed.");
          setModalMail(!modalMail);
        }}
      >
        <View style={styles.bgModal}>
          <View
            style={[
              styles.viewDetailModal,
              {
                height: 200,
                alignItems: "center",
                justifyContent: "center",
                width:
                  size.width < 500 ? "80%" : size.width < 1280 ? "70%" : "30%",
                paddingHorizontal: 20,
              },
            ]}
          >
            <Text
              style={[
                styles.text16B,
                {
                  color: "#69D200",
                  fontSize: 30,
                  width: "100%",
                  textAlign: "center",
                },
              ]}
            >
              ส่งอีเมลสำเร็จ
            </Text>
          </View>
        </View>
      </Modal>
      <Modal
        transparent={true}
        visible={modalAddItem}
        onRequestClose={() => {
          Alert.alert("Modal has been closed.");
          setModalAddItem(!modalAddItem);
        }}
      >
        <View style={styles.bgModal}>
          <View
            style={[
              styles.viewDetailModal1,
              {
                width:
                  size.width < 500
                    ? "80%"
                    : size.width < 1280
                    ? "70%"
                    : width < 1600
                    ? "65%"
                    : "50%",
              },
            ]}
          >
            <TextInput
              autoComplete="off"
              value={body.firstName}
              onChangeText={(text) => {
                setBody({ ...body, firstName: text });
              }}
              placeholder={"กรอกชื่อ"}
              placeholderTextColor={"#444444"}
              style={[
                styles.inputModal,
                { fontSize: size.width < 500 ? 18 : 35 },
              ]}
            />

            <TextInput
              value={body.lastName}
              onChangeText={(text) => {
                setBody({ ...body, lastName: text });
              }}
              placeholder={"กรอกนามสกุล"}
              placeholderTextColor={"#444444"}
              style={[
                styles.inputModal,
                { marginTop: 30, fontSize: size.width < 500 ? 18 : 35 },
              ]}
            />

            <View
              style={{
                flexDirection: "row",
                alignSelf: "center",
                marginTop: 49,
              }}
            >
              <TouchableOpacity
                onPress={() => {
                  setModalAddItem(!modalAddItem);
                }}
                style={styles.buttonModal}
              >
                <Text
                  style={[
                    styles.textButtonModal,
                    {
                      color: "#444444",
                    },
                  ]}
                >
                  ยกเลิก
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={editname}
                style={[
                  styles.buttonModal,
                  {
                    backgroundColor: "#0D88F0",
                    marginLeft: width * 0.05,
                  },
                ]}
              >
                <Text
                  style={[
                    styles.textButtonModal,
                    {
                      color: "#FFFFFF",
                    },
                  ]}
                >
                  ยืนยัน
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </Modal>
    </View>
  );
}
const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: "100%",
    backgroundColor: "#FFFFFF",
  },
  text23O: { fontSize: 23, fontFamily: "Prompt", color: "#0D88F0" },
  text16B: {
    fontFamily: "Prompt",
    fontSize: 16,
    color: "#000000",
    width: "23%",
  },

  viewSubject: {
    width: "100%",
    height: 51,
    backgroundColor: "#E5E5E5",
    marginTop: 35,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  bgModal: {
    width: "100%",
    height: "100%",
    backgroundColor: "#000000bb",
    justifyContent: "center",
  },
  viewDetailModal: {
    width: "50%",
    height: 380,
    backgroundColor: "#FFFFFF",
    borderRadius: 20,
    alignSelf: "center",
    paddingHorizontal: width * 0.05,
    paddingVertical: 50,
  },
  buttonModal: {
    width: 102,
    height: 48,
    borderRadius: 4,
    backgroundColor: "#EFEFF3",
    justifyContent: "center",
    alignItems: "center",
  },
  textButtonModal: {
    fontSize: 25,
    fontFamily: "Prompt",
  },
  text16W: {
    fontFamily: "Prompt",
    fontSize: 16,
    color: "#FFFFFF",
  },
  viewDetailModal1: {
    width: "60%",

    backgroundColor: "#FFFFFF",
    borderRadius: 20,
    alignSelf: "center",
    paddingHorizontal: width * 0.05,
    paddingVertical: 50,
  },
  inputModal: {
    width: "100%",
    height: 67,
    borderRadius: 9,
    borderWidth: 1,
    borderColor: "#707070",
    fontSize: 35,
    fontFamily: "Prompt",
    color: "#444444",
    textAlign: "center",
  },
});
