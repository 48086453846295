import { StatusBar } from "expo-status-bar";
import React, { useState } from "react";
import { StyleSheet, Text, View } from "react-native";
import Routes from "./src/routes";
import AppLoading from "expo-app-loading";
import * as Font from "expo-font";
import { useEffect } from "react";
import * as SplashScreen from "expo-splash-screen";
import { RecoilRoot } from "recoil";

import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyC3djOVguhM9JTPdsSJd5M8j-m9WTMXWPE",
  authDomain: "course-on-clouds.firebaseapp.com",
  projectId: "course-on-clouds",
  storageBucket: "course-on-clouds.appspot.com",
  messagingSenderId: "655951624288",
  appId: "1:655951624288:web:d39639e8e13aea47c7ab05",
  measurementId: "G-K6BNHFY4KW",
};

firebase.initializeApp(firebaseConfig);

export default function App() {
  const [fontsLoaded, setFont] = useState(false);

  useEffect(() => {
    loadFonts();
    SplashScreen.hideAsync();
  }, []);

  async function loadFonts() {
    await Font.loadAsync({
      Prompt: require("./assets/font/Prompt-Regular.ttf"),
      PromptBold: require("./assets/font/Prompt-Bold.ttf"),
    });
    setFont(true);
  }

  if (!fontsLoaded) {
    return <AppLoading />;
  } else {
    return (
      <RecoilRoot>
        <View style={styles.container}>
          <Routes />
        </View>
      </RecoilRoot>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    overflow: "hidden",
  },
});
