import React, { useState, useEffect, useCallback } from "react";
import {
  View,
  Text,
  Image,
  Dimensions,
  Animated,
  TouchableOpacity,
  StyleSheet,
  ScrollView,
  TextInput,
  FlatList,
  Alert,
  Linking,
} from "react-native";
import {
  MaterialCommunityIcons,
  FontAwesome,
  FontAwesome5,
  MaterialIcons,
} from "@expo/vector-icons";
import Header from "../components/header";
import { BottomPart, bottomPart } from "./home";
import TeacherCourse from "./teacherCourse";
import Work from "./work";
import GradeBook from "./gradeBook";
import Finance from "./finance";
import Notification from "./notification";
import WorkReceived from "./workReceived";
import WorkReceived1 from "./workReceived1";
import LearnerInformation from "./learnerInformation";
import Chat from "./chat";
import CourseProfile from "./courseProfile";
import Announcement from "./announcement";
import EditProfile from "./editProfile";
import CourseUniversityStudent from "./courseUniversityStudent";
import { useRecoilState } from "recoil";
import { pageState, tokenState } from "../../reducer/recoil";
import { apiservice } from "../../service";
import * as DocumentPicker from "expo-document-picker";
import { authActionPutUser } from "../../action/authAction";
import { useWindowSize } from "../components/responsive";
import CourseUniversityStudentAdd from "./courseUniversityStudentAdd";
import moment from "moment";
const { width, height } = Dimensions.get("window");

export default function profileUser({ navigation, route }) {
  const [other, setOther] = useState(false);
  const [editProfile, setEditProfile] = useState(false);
  const [token, setToken] = useRecoilState(tokenState);
  const [image, setImage] = useState();
  const [data, setData] = useState({});
  const [facuty, setFacuty] = useState({});
  const [university, setUniversity] = useState({});
  const [university_id, setUniversity_id] = useState("");
  const [faculti_id, setFaculti_id] = useState("");
  const [id_user, setId_user] = useState();
  const [notilength, setnotilength] = useState(0);

  const [addCourse, setAddCourse] = useState(false);
  const size = useWindowSize();
  {
  }
  let selectData =
    university != null && facuty != null
      ? [
          "รายการคอร์สมหาวิทยาลัย",
          "คอร์สที่เรียนทั้งหมด",
          "แชท",
          "การแจ้งเตือน",
          "ใบประกาศทั้งหมด",
        ]
      : ["คอร์สที่เรียนทั้งหมด", "แชท", "การแจ้งเตือน", "ใบประกาศทั้งหมด"];

  useEffect(() => {
    name();
  }, [token]);

  async function name() {
    const res = await apiservice({
      path: "/course/allnotificationlength",
      method: "get",
      token: token?.accessToken,
    });

    if (res.status == 200) {
      setnotilength(res.data);
    }
  }

  async function clearname() {
    const res = await apiservice({
      path: "/course/allnotificationupdate",
      method: "get",
      token: token?.accessToken,
    });

    if (res.status == 200) {
      name();
    }
  }

  async function getProfile() {
    try {
      const response = await apiservice({
        path: "/authen/user",
        method: "get",
        token: token?.accessToken,
      });

      if (response.status == 200) {
        setId_user(response.data.result.id);
        setImage(response.data.result.user_accounts.image_url);
        setData(response.data.result.user_accounts);
        setFacuty(response.data.result.faculty);
        setUniversity(response.data.result.university);
        setUniversity_id(response.data.result.user_accounts.university_id);
        setFaculti_id(response.data.result.user_accounts.faculti_id);
      }
    } catch (error) {
      throw error;
    }
  }
  useEffect(() => {
    if (token) {
      getProfile();
    }
  }, [token]);

  async function pickup() {
    let result = await DocumentPicker.getDocumentAsync({ type: "image/*" });
    // setImage(result.uri)
    const response = await apiservice({
      path: "/image/createpdf",
      method: "post",
      body: {
        name:
          moment().format("DDMMYYYYHHmmss") + result.name.replace(".png", ""),
        base64: result.uri,
      },
    });
    const edit = await authActionPutUser({
      state: { image_url: response.data.imageRefId.replace(".png", "") },
      token: token?.accessToken,
    });
    getProfile();

    setImage(response.data.imageRefId.replace(".png", ""));
    window.location.reload(false);
  }
  const supportedURL = "https://google.com";

  const unsupportedURL = "slack://open?team=123456";
  const OpenURLButton = ({ url, children }) => {
    const handlePress = useCallback(async () => {
      // Checking if the link is supported for links with custom URL scheme.
      const supported = await Linking.canOpenURL(url);

      if (supported) {
        // Opening the link with some app, if the URL scheme is "http" the web link should be opened
        // by some browser in the mobile
        await Linking.openURL(url);
      } else {
        Alert.alert(`Don't know how to open this URL: ${url}`);
      }
    }, [url]);

    return (
      <TouchableOpacity
        title={children}
        onPress={handlePress}
      ></TouchableOpacity>
    );
  };

  const [page, setPage] = useRecoilState(pageState);
  return (
    <View style={styles.container}>
      <ScrollView stickyHeaderIndices={[0]}>
        <Header navigation={navigation} />
        <View
          style={{
            flexDirection: size.width < 1280 ? "column" : "row",
            width: "90%",
            minHeight: size.width >= 800 && "90%",
            alignSelf: "center",
            justifyContent: "space-between",
          }}
        >
          <View
            style={{
              width: size.width < 1280 ? "100%" : "24%",
              maxWidth: "400",

              backgroundColor: "#D1DDE5",
              borderTopLeftRadius: 100,
              borderTopRightRadius: 100,
              padding: 25,
              marginTop: 40,
              marginLeft: size.width < 1280 ? 0 : -40,
            }}
          >
            <View>
              <Image
                style={{
                  width: 200,

                  height: 200,
                  borderRadius: 100,
                  alignSelf: "center",
                  backgroundColor: "#CCCCCC",
                }}
                source={{
                  uri:
                    "https://api.courseoncloud.com/api/image/getimage/" + image,
                }}
              />
              <TouchableOpacity
                onPress={pickup}
                style={{
                  alignSelf: "center",
                  marginTop: -25,
                  marginLeft: size.width < 750 ? width * 0.2 : width * 0.05,
                }}
              >
                <FontAwesome name="camera" size={30} color="#FFFFFF" />
              </TouchableOpacity>
            </View>
            <Text
              numberOfLines={1}
              style={{
                fontSize: width < 1600 ? 22 : 25,
                fontFamily: "Prompt",
                color: "#646464",
                alignSelf: "center",
                marginTop: 45,
              }}
            >
              {data.firstName != null && data.firstName}{" "}
              {data.lastName != null && data.lastName}
            </Text>
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                alignSelf: "center",
              }}
            >
              {data.facebook_url != "" && data.facebook_url != null && (
                <TouchableOpacity
                  disabled={data.facebook_url != null ? false : true}
                  onPress={() => {
                    window.open(data.facebook_url);
                  }}
                >
                  <Image
                    style={styles.imgSocial}
                    source={require("../img/1024px-Facebook_Logo_(2019).png")}
                  />
                </TouchableOpacity>
              )}
              {data.instagram_url != "" && data.instagram_url != null && (
                <TouchableOpacity
                  disabled={data.instagram_url != null ? false : true}
                  onPress={() => {
                    window.open(data.instagram_url);
                  }}
                >
                  <Image
                    style={styles.imgSocial}
                    source={require("../img/instagram-logo-png-transparent-background-800x799.png")}
                  />
                </TouchableOpacity>
              )}
              {data.twitter_url != "" && data.instagram_url != null && (
                <TouchableOpacity
                  disabled={data.twitter_url != null ? false : true}
                  onPress={() => {
                    window.open(data.twitter_url);
                  }}
                >
                  <Image
                    style={styles.imgSocial}
                    source={require("../img/Twitter.png")}
                  />
                </TouchableOpacity>
              )}
              <TouchableOpacity
                onPress={() => {
                  window.open("mailto:" + data.email);
                }}
              >
                <Image
                  style={styles.imgSocial}
                  source={require("../img/email_PNG14.png")}
                />
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => {
                  university != null && facuty != null
                    ? setPage(2)
                    : setPage(1);
                }}
              >
                <Image
                  style={[styles.imgSocial, { borderRadius: 0 }]}
                  source={require("../img/ic_sms_24px.png")}
                />
              </TouchableOpacity>
            </View>
            {university != null && facuty != null && (
              <View>
                <Text style={styles.text}>คณะ : {facuty.facuty_name_th}</Text>
                <Text style={styles.text}>สาขา : {facuty.major_name_th}</Text>
                <View style={{ flexDirection: "row" }}>
                  <Text style={styles.text}>นักศึกษาชั้นปี : {data.class}</Text>
                  <Text style={[styles.text, { marginLeft: 30 }]}>
                    รหัส : {data.code}
                  </Text>
                </View>
                <Text style={styles.text}>
                  มหาวิทยาลัย : {university.university_name_th}
                </Text>
              </View>
            )}
            {/* <Text style={styles.text}>{university.university_name_th}</Text> */}
            <Text style={styles.text}>เกี่ยวกับฉัน :</Text>
            <Text style={styles.text}>{data.about_us}</Text>
            <TouchableOpacity
              onPress={() => {
                setEditProfile(true);
              }}
              style={{
                width: "100%",
                maxWidth: 150,
                height: 50,
                backgroundColor: "#0B2051",
                borderRadius: 17,
                justifyContent: "center",
                alignSelf: "center",
                alignItems: "center",
                marginVertical: 36,
              }}
            >
              <Text style={styles.textWhite}>แก้ไขโปรไฟล์</Text>
            </TouchableOpacity>
          </View>
          <View
            style={{
              width: size.width < 1280 ? "100%" : "75%",
              borderWidth: 1,
              borderColor: "#DDD",
              marginTop: 58,
              marginBottom: 14,
              marginLeft: size.width < 1280 ? 0 : width * 0.05,
            }}
          >
            {editProfile ? (
              <EditProfile
                id_user={id_user}
                route={route}
                onPress={() => {
                  setEditProfile(false);
                  setPage(0);
                }}
              />
            ) : (
              <View style={{ flexDirection: "row" }}>
                <FlatList
                  horizontal
                  data={selectData}
                  showsHorizontalScrollIndicator={false}
                  style={{}}
                  renderItem={({ item, index }) => {
                    return (
                      <TouchableOpacity
                        style={{
                          backgroundColor:
                            page == index ? "#FFFFFF" : "#0D88F0",
                          width: "100%",
                          maxWidth: university != null && facuty != null && 262,
                          height: size.width >= 500 && 60,
                          justifyContent: "center",
                        }}
                        onPress={() => {
                          setAddCourse(false);
                          setPage(index);
                          setOther(false);
                          if (index == 3) {
                            clearname();
                          }
                        }}
                      >
                        <Text
                          style={{
                            fontFamily: "Prompt",
                            fontSize: size.width < 1280 ? 14 : 20,
                            textAlign: "center",
                            width:
                              size.width < 1280
                                ? university != null && facuty != null
                                  ? size.width / 5.5
                                  : size.width / 4.4
                                : university != null && facuty != null
                                ? size.width / 7.4
                                : size.width / 5.9,

                            color: page == index ? "#0D88F0" : "#FFFFFF",
                          }}
                        >
                          {item}
                          {university != null && facuty != null
                            ? index == 3
                            : index == 2 && notilength > 0 && " " + notilength}
                        </Text>
                      </TouchableOpacity>
                    );
                  }}
                />
              </View>
            )}
            {/* {other && (
              <View style={styles.viewOuther}>
                <TouchableOpacity
                  onPress={() => {
                    setPage(8);
                    setOther(false);
                  }}
                  style={styles.buttonOther}
                >
                  <Text style={styles.textWhite}>แชท</Text>
                </TouchableOpacity>
                <TouchableOpacity
                  onPress={() => {
                    setPage(9);
                    setOther(false);
                  }}
                  style={styles.buttonOther}
                  style={styles.buttonOther}
                >
                  <Text style={styles.textWhite}>คอร์สที่เรียนทั้งหมด</Text>
                </TouchableOpacity>
                <TouchableOpacity
                  onPress={() => {
                    setPage(10);
                    setOther(false);
                  }}
                  style={styles.buttonOther}
                >
                  <Text style={styles.textWhite}>ใบประกาศทั้งหมด</Text>
                </TouchableOpacity>
              </View>
            )} */}
            {university != null && facuty != null && (
              <View>
                {page == 0 && !editProfile && (
                  <View>
                    {!addCourse ? (
                      <CourseUniversityStudent
                        navigation={navigation}
                        university_id={university_id}
                        faculti_id={faculti_id}
                        onPress={() => {
                          setAddCourse(true);
                        }}
                      />
                    ) : (
                      <CourseUniversityStudentAdd
                        university_id={university_id}
                        navigation={navigation}
                        faculti_id={faculti_id}
                      />
                    )}
                  </View>
                )}
              </View>
            )}
            {university != null && facuty != null ? (
              <View>
                {page == 1 && !editProfile && (
                  <CourseProfile navigation={navigation} />
                )}
              </View>
            ) : (
              <View>
                {page == 0 && !editProfile && (
                  <CourseProfile navigation={navigation} />
                )}
              </View>
            )}
            {university != null && facuty != null ? (
              <View>
                {page == 2 && !editProfile && <Chat navigation={navigation} />}
              </View>
            ) : (
              <View>{page == 1 && !editProfile && <Chat />}</View>
            )}
            {university != null && facuty != null ? (
              <View>{page == 3 && !editProfile && <Notification />}</View>
            ) : (
              <View>{page == 2 && !editProfile && <Notification />}</View>
            )}
            {university != null && facuty != null ? (
              <View>{page == 4 && !editProfile && <Announcement />}</View>
            ) : (
              <View>{page == 3 && !editProfile && <Announcement />}</View>
            )}
          </View>
        </View>
        <BottomPart navigation={navigation} />
      </ScrollView>
    </View>
  );
}
const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: "100%",
    backgroundColor: "#FFFFFF",
  },
  textTitle: {
    fontSize: 18,
    fontFamily: "Prompt",
    color: "#0B2051",
  },
  text: {
    fontSize: 20,
    fontFamily: "Prompt",
    color: "#646464",
    marginTop: 19,
  },
  textDetail: {
    fontSize: 16,
    fontFamily: "Prompt",
    color: "#0B2051",
  },
  viewDetail: {
    width: 278,
    height: 385,
  },
  imgDetail: {
    width: 278,
    height: 195,
  },
  feedback: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  imgSocial: {
    width: 30,
    height: 30,
    borderRadius: 15,
    marginHorizontal: 10,
    marginTop: 10,
  },
  textWhite: {
    fontSize: 20,
    fontFamily: "Prompt",
    color: "#FFFFFF",
  },
  viewOuther: {
    width: "100%",
    maxWidth: 262,
    height: 180,
    position: "absolute",
    backgroundColor: "#0D88F0",
    alignSelf: "flex-end",
    marginTop: 60,
    zIndex: 99,
    borderTopWidth: 1,
    borderTopColor: "#FFFFFF",
  },
  buttonOther: {
    alignItems: "center",
    justifyContent: "center",
    height: 60,
  },
});
