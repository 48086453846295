import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  Image,
  Dimensions,
  TouchableOpacity,
  StyleSheet,
  FlatList,
} from "react-native";
import { MaterialIcons, Ionicons, Entypo } from "@expo/vector-icons";
import RNPickerSelect from "react-native-picker-select";
import CourseTaught from "./courseUniversity/coursesTaught";
import Subject from "./courseUniversity/subject";
import Teacher from "./courseUniversity/teacher";
import TeacherDetail from "./courseUniversity/teacherDetail";
import Student from "./courseUniversity/student";
import StudentDetail from "./courseUniversity/studentDetail";
import Faculty from "./courseUniversity/faculty";
import AddCourse from "./addCourse/index";
import { useRecoilState } from "recoil";
import { apiservice } from "../../service";
import { pageAdminState, tokenState } from "../../reducer/recoil";
import { useWindowSize } from "../components/responsive";
const { width, height } = Dimensions.get("window");

export default function main({ navigation }) {
  const [token, setToken] = useRecoilState(tokenState);
  const [page, setPage] = useRecoilState(pageAdminState);
  const [universityCourse, setUniversityCourse] = useState(false);
  const [universityCoursePage, setUniversityCoursePage] = useState(0);
  const [teacherDetail, setTeacherDetail] = useState(false);
  const [studentDetail, setStudentDetail] = useState(false);
  const [university_id, setUniversity_id] = useState();
  const [profile, setProfile] = useState("");
  const [name, setName] = useState();
  const [lastName, setLastName] = useState();
  const [nameStudent, setNameStudent] = useState();
  const [lastNameStudent, setLastNameStudent] = useState();
  const [courseName, setCourseName] = useState();
  const [courseName1, setCourseName1] = useState();
  const [openMenu, setOpenMenu] = useState(false);
  const [allResultTeacher, setAllResultTeacher] = useState();
  const [allResultStudent, setAllResultStudent] = useState();
  const DATA = [
    { name: "คอร์สเรียนมหาวิทยาลัย" },
    { name: "คำร้องเพิ่มคอร์สเรียน" },
  ];
  const DATA1 = [
    { name: "คอร์สที่สอน" },
    { name: "คณะ" },
    { name: "อาจารย์ผู้สอน" },
    { name: "นักศึกษา" },
  ];
  const status = {
    label: "Active",
    value: "Active",
    color: "#A2A2A2",
  };
  const [status1, setstatus1] = useState([
    { label: "InActive", value: "InActive" },
  ]);

  function Logout() {
    setTimeout(() => {
      setToken("");
      window.location.replace("Home");
    }, 1000);
  }

  useEffect(() => {
    if (token) {
      getAllCourse(token);
      getProfile(token);
    }
  }, [token]);

  async function getAllCourse(token) {
    try {
      const response = await apiservice({
        path: "/course/getallcouse?university_id=1&page=1&totalpage=100",
        method: "get",
        token: token?.accessToken,
      });
      if (response.status == 200) {
        let pagination = [];
        for (let index = 0; index < response.data.total_page; index++) {
          pagination = pagination.concat({});
        }
      }
    } catch (error) {
      throw error;
    }
  }

  async function getProfile(token) {
    try {
      const response = await apiservice({
        path: "/authen/user",
        method: "get",
        token: token?.accessToken,
      });
      if (response.status == 200) {
        let pagination = [];
        for (let index = 0; index < response.data.total_page; index++) {
          pagination = pagination.concat({});
        }

        setUniversity_id(response.data.result.user_accounts.university_id);
        setProfile(response.data.result.username);
      }
    } catch (error) {
      throw error;
    }
  }
  const size = useWindowSize();
  return (
    <View style={styles.container}>
      <View
        style={[
          styles.header,
          { paddingHorizontal: size.width < 500 ? 20 : 70 },
        ]}
      >
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          {size.width < 1280 && (
            <TouchableOpacity
              onPress={() => {
                setOpenMenu((val) => !val);
              }}
              style={{ marginRight: 20 }}
            >
              <Entypo name="menu" size={30} color="black" />
            </TouchableOpacity>
          )}
          <TouchableOpacity
            onPress={() => {
              navigation.navigate("Home");
            }}
          >
            <Image
              resizeMode="stretch"
              style={{
                width: size.width < 500 ? 69 : 80,
                height: size.width < 500 ? 45 : 50,
              }}
              source={require("../img/logo.png")}
            />
          </TouchableOpacity>
        </View>
        {page == 0 && (
          <FlatList
            horizontal
            style={{ marginLeft: 40 }}
            showsHorizontalScrollIndicator={false}
            data={DATA1}
            renderItem={({ item, index }) => {
              return (
                <TouchableOpacity
                  style={{
                    borderTopWidth: universityCoursePage == index ? 3 : 0,
                    borderTopColor: "#0D88F0",
                    marginLeft:
                      size.width < 500 && index == 0 ? 0 : width * 0.05,
                    height: 70,
                    justifyContent: "center",
                  }}
                  onPress={() => {
                    setUniversityCourse(false);
                    setTeacherDetail(false);
                    setStudentDetail(false);
                    setUniversityCoursePage(index);
                  }}
                >
                  <Text
                    style={[
                      {
                        fontSize: 16,
                        fontFamily: "Prompt",
                        color:
                          universityCoursePage == index ? "#0D88F0" : "#A2A2A2",
                      },
                    ]}
                  >
                    {item.name}
                  </Text>
                </TouchableOpacity>
              );
            }}
          />
        )}
        <View style={{ flexDirection: "row" }}>
          {size.width > 500 && (
            <TouchableOpacity style={{ marginHorizontal: 30 }}>
              <MaterialIcons
                name="notifications-none"
                size={30}
                color="#272D3B"
              />
            </TouchableOpacity>
          )}
          <TouchableOpacity
            onPress={Logout}
            style={{ marginHorizontal: size.width < 500 ? 5 : 30 }}
          >
            <MaterialIcons name="logout" size={30} color="#272D3B" />
          </TouchableOpacity>
        </View>
      </View>
      <View style={{ width: "100%", flexDirection: "row" }}>
        {openMenu && (
          <View style={[styles.viewMenu, { position: "absolute", zIndex: 99 }]}>
            <View style={{ height: "80%" }}>
              <FlatList
                numColumns={1}
                data={DATA}
                renderItem={({ item, index }) => {
                  return (
                    <TouchableOpacity
                      style={[
                        styles.buttonTitle,
                        { borderLeftWidth: page == index ? 3 : 0 },
                      ]}
                      onPress={() => {
                        setUniversityCourse(false);
                        setUniversityCoursePage(0);
                        setPage(index);
                        setOpenMenu(false);
                      }}
                    >
                      <Ionicons //folder-open-outline
                        name={
                          (index == 0 && "home-outline") ||
                          (index == 1 && "warning-outline")
                        }
                        size={30}
                        color={page == index ? "#0D88F0" : "#A2A2A2"}
                      />
                      <Text
                        style={[
                          styles.textTitle,
                          {
                            color: page == index ? "#0D88F0" : "#A2A2A2",
                          },
                        ]}
                      >
                        {item.name}
                      </Text>
                    </TouchableOpacity>
                  );
                }}
              />
            </View>
            <View
              style={[
                styles.viewProfile,
                { marginTop: height < 800 ? 10 : 30 },
              ]}
            >
              <View
                style={{
                  flexDirection: "row",
                  alignSelf: "center",
                  marginHorizontal: 10,
                }}
              >
                <Image
                  style={styles.imgProfile}
                  source={require("../img/Mask Group 9.png")}
                />
                <View style={{ marginLeft: 16 }}>
                  <Text numberOfLines={1} style={styles.text16W}>
                    {profile}
                  </Text>
                  <RNPickerSelect
                    placeholder={status}
                    onValueChange={(val) => {}}
                    items={status1}
                    style={{
                      inputWeb: {
                        borderWidth: 0,
                        width: 90,
                        backgroundColor: "#444444",
                        color: "#A2A2A2",
                        fontFamily: "Prompt",
                        fontSize: 16,
                        marginLeft: -5,
                      },
                    }}
                  />
                </View>
              </View>
            </View>
          </View>
        )}
        {size.width >= 1280 && (
          <View style={styles.viewMenu}>
            <View style={{ height: "80%" }}>
              <FlatList
                numColumns={1}
                data={DATA}
                renderItem={({ item, index }) => {
                  return (
                    <TouchableOpacity
                      style={[
                        styles.buttonTitle,
                        { borderLeftWidth: page == index ? 3 : 0 },
                      ]}
                      onPress={() => {
                        setUniversityCourse(false);
                        setUniversityCoursePage(0);
                        setPage(index);
                      }}
                    >
                      <Ionicons //folder-open-outline
                        name={
                          (index == 0 && "home-outline") ||
                          (index == 1 && "warning-outline")
                        }
                        size={30}
                        color={page == index ? "#0D88F0" : "#A2A2A2"}
                      />
                      <Text
                        style={[
                          styles.textTitle,
                          {
                            color: page == index ? "#0D88F0" : "#A2A2A2",
                          },
                        ]}
                      >
                        {item.name}
                      </Text>
                    </TouchableOpacity>
                  );
                }}
              />
            </View>
            <View
              style={[
                styles.viewProfile,
                { marginTop: height < 800 ? 10 : 30 },
              ]}
            >
              <View
                style={{
                  flexDirection: "row",
                  alignSelf: "center",
                  marginHorizontal: 10,
                }}
              >
                <Image
                  style={styles.imgProfile}
                  source={require("../img/Mask Group 9.png")}
                />
                <View style={{ marginLeft: 16 }}>
                  <Text numberOfLines={1} style={styles.text16W}>
                    {profile}
                  </Text>
                  <RNPickerSelect
                    placeholder={status}
                    onValueChange={(val) => {}}
                    items={status1}
                    style={{
                      inputWeb: {
                        borderWidth: 0,
                        width: 90,
                        backgroundColor: "#444444",
                        color: "#A2A2A2",
                        fontFamily: "Prompt",
                        fontSize: 16,
                        marginLeft: -5,
                      },
                    }}
                  />
                </View>
              </View>
            </View>
          </View>
        )}
        <View
          style={{
            width: size.width < 1280 ? "100%" : "87%",
            paddingHorizontal: size.width < 500 ? 20 : 50,
            paddingVertical: 28,
          }}
        >
          {page == 0 && (
            <View>
              {universityCoursePage == 0 && (
                <View>
                  {!universityCourse ? (
                    <CourseTaught
                      setCourseName={setCourseName}
                      setCourseName1={setCourseName1}
                      onPress={() => {
                        setUniversityCourse(true);
                      }}
                    />
                  ) : (
                    <Subject
                      courseName={courseName}
                      courseName1={courseName1}
                      ongoBack={() => {
                        setUniversityCourse(false);
                      }}
                    />
                  )}
                </View>
              )}
              {universityCoursePage == 2 && (
                <View>
                  {!teacherDetail ? (
                    <Teacher
                      university_id={university_id}
                      setName={setName}
                      setLastName={setLastName}
                      setAllResultTeacher={setAllResultTeacher}
                      onPress={() => {
                        setTeacherDetail(true);
                      }}
                    />
                  ) : (
                    <TeacherDetail
                      name={name}
                      lastName={lastName}
                      allResultTeacher={allResultTeacher}
                      ongoBack={() => {
                        setTeacherDetail(false);
                      }}
                    />
                  )}
                </View>
              )}
              {universityCoursePage == 3 && (
                <View>
                  {!studentDetail ? (
                    <Student
                      university_id={university_id}
                      setNameStudent={setNameStudent}
                      setLastNameStudent={setLastNameStudent}
                      setAllResultStudent={setAllResultStudent}
                      onPress={() => {
                        setStudentDetail(true);
                      }}
                    />
                  ) : (
                    <StudentDetail
                      nameStudent={nameStudent}
                      lastNameStudent={lastNameStudent}
                      allResultStudent={allResultStudent}
                      ongoBack={() => {
                        setStudentDetail(false);
                      }}
                    />
                  )}
                </View>
              )}
              {universityCoursePage == 1 && (
                <Faculty university_id={university_id} />
              )}
            </View>
          )}
          {page == 1 && <AddCourse navigation={navigation} />}
        </View>
      </View>
    </View>
  );
}
const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: "100%",
    backgroundColor: "#FFFFFF",
  },
  header: {
    width: "100%",
    height: 70,
    backgroundColor: "#FFFFFF",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingHorizontal: 70,
    shadowColor: "#707070",
    shadowOffset: { width: -2, height: 2 },
    shadowRadius: 10,
  },
  viewMenu: {
    width: "13%",
    minWidth: 220,
    height: height,
    backgroundColor: "#444444",
  },
  text16W: {
    fontFamily: "Prompt",
    fontSize: 16,
    color: "#FFFFFF",
  },
  text16B: {
    fontFamily: "Prompt",
    fontSize: 16,
    color: "#000000",
    textAlign: "center",
  },
  viewProfile: {
    width: "100%",
    height: "20%",

    marginTop: 30,
  },
  imgProfile: {
    width: 50,
    height: 50,
    borderRadius: 25,
  },
  buttonTitle: {
    paddingLeft: 20,
    flexDirection: "row",
    alignItems: "center",
    marginTop: 50,

    borderLeftColor: "#0D88F0",
  },
  textTitle: {
    fontFamily: "Prompt",
    fontSize: 16,
    marginLeft: 32,
  },
});
