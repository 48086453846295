import {
  View,
  Text,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
} from "react-native";
import React from "react";
import Header from "../components/header";
import { BottomPart } from "./home";
import { useWindowSize } from "../components/responsive";
export default function Privacy({ navigation }) {
  const size = useWindowSize();
  return (
    <View style={styles.container}>
      <ScrollView stickyHeaderIndices={[0]}>
        <Header navigation={navigation} unShadow />
        {/* <TouchableOpacity
              onPress={() => {
                setModalWarning(false);
              }}
              style={{ position: "absolute", right: 0, zIndex: 99 }}
            >
              <Ionicons name="close" size={30} color="#0D88F0" />
            </TouchableOpacity> */}
        <View
          style={{
            width: size.width < 500 ? "90%" : "60%",
            alignSelf: "center",
            marginVertical: size.width < 500 ? 0 : 40,
          }}
        >
          <Text
            style={[styles.textTitle, { marginTop: size.width < 500 ? 0 : 40 }]}
          >
            ข้อกำหนดและเงื่อนไขสำหรับการสมัครเป็นผู้สอน ผ่านเว็บไซต์
            www.courseoncloud.com ของ บริษัท เอเชีย คอนเน็คท์ คอร์ปอเรชั่น จำกัด
          </Text>
          <Text style={styles.text}>
            {"\t"}
            ผู้สมัครสมาชิกตกลงยินยอมตาม
            ข้อกำหนดและเงื่อนไขสำหรับการสมัครเป็นผู้สอนของ บริษัท เอเชีย
            คอนเน็คท์ คอร์ปอเรชั่น จำกัด ดังต่อไปนี้{"\n\n\t"}1.
            ต้องเป็นผู้สมัคร ผ่าน www.courseoncloud.com
            เท่านั้นโดยแนบเอกสารหลักฐานประกอบการสมัครให้ครบถ้วน เมื่อบริษัท
            เอเชีย คอนเน็คท์ คอร์ปอเรชั่น จำกัด ตรวจสอบหลักฐานต่างๆ
            เห็นว่าถูกต้องครบถ้วนและผ่านเกณฑ์การคัดเลือกตามระเบียบของบริษัท
            เอเชีย คอนเน็คท์ คอร์ปอเรชั่น จำกัดแล้ว บริษัท เอเชีย คอนเน็คท์
            คอร์ปอเรชั่น จำกัด จะทำการส่งข้อความยืนยันไปยัง e-mail
            ที่ผู้สมัครได้ระบุไว้ ผู้สมัครจึงจะสามารถใช้ Username และ Password
            เพื่อเข้าใช้ระบบ (Log in)
            มิฉะนั้นบัญชีของผู้สมัครจะถูกยกเลิกโดยมิต้องแจ้งให้ทราบล่วงหน้า
            {"\n\t"}2. ผู้สมัครต้องระวัง และ รักษา Username และ Password
            เพื่อเข้าใช้ระบบ (Log in) โดยไม่ให้ผู้ใดใช้แอบอ้างหรือกระทำการใดๆ
            อันเป็นการละเมิดสิทธิส่วนบุคคล และ ก่อให้เกิดความเสียหายต่อบริษัท
            เอเชีย คอนเน็คท์ คอร์ปอเรชั่น จำกัด โดยใช้ข้อมูลของผู้สมัครมาแอบอ้าง
            ถือเป็นความผิดของผู้สมัคร และ ต้องรับโทษตามที่กฎหมายกำหนดไว้{"\n\t"}
            3. ข้อมูลภาพ เสียง วิดีโอ และเนื้อหาต่างๆที่ผู้สอนนำมาสอน
            จะต้องไม่ละเมิดลิขสิทธิ์ผู้ใด หากพบมีการละเมิดลิขสิทธิ์
            มีข้อร้องเรียน หรือฟ้องร้อง ผู้สอนต้องรับผิดชอบแต่เพียงผู้เดียว
            โดยไม่เกี่ยวข้องกับบริษัทฯแต่อย่างใด และบริษัทฯ
            สามารถยกเลิกการเป็นผู้สอนได้โดยไม่ต้องแจ้งล่วงหน้า{"\n\t"}
            4.เมื่อผู้สมัครได้รับการแต่งตั้งเป็นผู้สอนของบริษัทเอเชีย คอนเน็คท์
            คอร์ปอเรชั่น จำกัด แล้ว จะได้รับส่วนแบ่งจากรายได้ที่บริษัทฯ
            เรียกเก็บค่าให้คำปรึกษาจากลูกค้าแต่ละรายที่ตนให้คำปรึกษา
            ในอัตราที่บริษัทกำหนด{"\n\t"}ทั้งนี้ บริษัท เอเชีย คอนเน็คท์
            คอร์ปอเรชั่น จำกัด จะตัดยอดทุกสิ้นเดือน
            และโอนเงินให้กับผู้สอนภายในวันที่ 10 ของเดือนถัดไป
            โดยจำนวนเงินที่ได้นั้นเป็นจำนวนหลังหักค่าโอน
            หักภาษีมูลค่าเพิ่มหักภาษี ณ ที่จ่าย (ในกรณีรายได้เกิน1,000
            บาทต่อรอบบิล) และค่าภาษีต่างๆตามที่กฎหมายกำหนดแล้ว{"\n\t"}
            5.เมื่อผู้สมัครได้รับการแต่งตั้งเป็นผู้สอนของบริษัท เอเชีย คอนเน็คท์
            คอร์ปอเรชั่น จำกัด ต้องรักษาความลับของลูกค้า และ
            ความลับต่างๆของบริษัทฯ
            โดยไม่นำไปเปิดเผยต่อบุคคลภายนอกที่ไม่มีส่วนเกี่ยวข้องใด ๆ กับบริษัท
            เอเชีย คอนเน็คท์ คอร์ปอเรชั่น จำกัด โดยเด็ดขาด
            {"\n\t"}
            6.เมื่อผู้สมัครได้รับการแต่งตั้งเป็นผู้สอนของบริษัท เอเชีย คอนเน็คท์
            คอร์ปอเรชั่น จำกัด ต้องให้ข้อมูลที่ถูกต้องแก่ลูกค้า และตรงไปตรงมา
            ตามหลักการและทฤษฏีที่เป็นที่ยอมรับทั่วไป{"\n\t"}7.
            ข้อมูลส่วนบุคคลของผู้สมัครที่ได้ลงทะเบียน
            หรือผ่านการใช้งานของเว็บไซต์สำหรับการเป็นผู้สอนของบริษัท ทั้งหมดนั้น
            ผู้สมัครยอมรับและตกลงว่าเป็นสิทธิของบริษัท
            ในการใช้ข้อมูลเกี่ยวข้องกับการเป็นผู้สอน ของบริษัท เอเชีย คอนเน็คท์
            คอร์ปอเรชั่น จำกัดเท่านั้น{"\n\t"}8.
            ผู้สมัครยอมรับและยินยอมให้เป็นเอกสิทธิ์ของ บริษัท
            ในการยกเลิกการเป็นผู้สอนของ บริษัท หาก บริษัทฯ พบว่ามีการกระทำผิด
            ข้อกำหนด และเงื่อนไขของ บริษัทฯ{"\n\t"}
            9. ข้าพเจ้าผู้สมัคร ได้อ่านเงื่อนไขการสมัครและยินยอมสมัคร เป็นผู้สอน
            ของบริษัท เอเชีย คอนเน็คท์ คอร์ปอเรชั่น จำกัด
            ผู้สมัครตกลงยินยอมและปฏิบัติตามข้อตกลง และเงื่อนไขต่าง ๆ
            ตามที่ระบุไว้ในข้อตกลงดังกล่าว รวมทั้งข้อตกลงและเงื่อนไขอื่น ๆ
            ตามที่บริษัท เอเชีย คอนเน็คท์ คอร์ปอเรชั่น จำกัด
            ที่จะกำหนดขึ้นในภายหน้าด้วย
            {"\n\n"}หมายเหตุ{"\t\t"}ราคาที่แสดงหน้าเว็บไชด์
            www.courseoncloud.com เป็นราคาที่รวมภาษีมูลค่าเพิ่มแล้ว
          </Text>
        </View>
        <BottomPart navigation={navigation} />
      </ScrollView>
    </View>
  );
}
const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: "100%",
    backgroundColor: "#FFFFFF",
  },
  text: {
    fontSize: 18,
    fontFamily: "Prompt",
    color: "#484848",
  },
  textTitle: {
    fontSize: 30,
    fontFamily: "Prompt",
    color: "#484848",
    marginTop: 40,

    fontWeight: "Bold",
  },
});
