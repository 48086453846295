import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  Image,
  Dimensions,
  TouchableOpacity,
  StyleSheet,
  TextInput,
} from "react-native";

import Modal from "modal-react-native-web";
import { useRecoilState } from "recoil";
import { tokenState } from "../../reducer/recoil";
import {
  authActionForgetPassword,
  authActionPutUser,
} from "../../action/authAction";
import { apiservice } from "../../service";
import { useWindowSize } from "../components/responsive";
const { width, height } = Dimensions.get("window");

export default function editProfile({ onPress, route, id_user }) {
  const [modalPass, setModalPass] = useState(false);
  const [status, setStatus] = useState();
  const [token, setToken] = useRecoilState(tokenState);
  const [check, setCheck] = useState();
  const [checkUser, setCheckUser] = useState(false);
  const [checkEmail, setCheckEmail] = useState(false);
  const [warningPass, setWarningPass] = useState(false);
  const [warning, setWarning] = useState(false);
  const [state, setState] = useState({
    role: "",
    sub_role: "MAIN",
    username: "",
    email: "",
    password: "",
    firstName: "",
    lastName: "",
    telNo: "",
    address: "",
    balance_info: "",
    balance_no: "",
    promptpay: "",
    education: {},
    organization: "",
    position: "",
    about_us: "",
    facebook_url: "",
    instagram_url: "",
    twitter_url: "",
  });
  const [body, setBody] = useState({
    id: id_user,
    nowpassword: "",
    newpassword: "",
  });

  useEffect(() => {
    if (token) {
      getProfile(token);
    }
  }, [token]);

  async function getProfile(token) {
    try {
      const response = await apiservice({
        path: "/authen/user",
        method: "get",
        token: token?.accessToken,
      });

      if (response?.status == 200) {
        setCheck(response?.data?.result?.university);
        setState({
          ...state,
          role: response?.data?.result?.role,
          username: response?.data?.result?.username,
          email: response?.data?.result?.email,
          password: "",
          firstName: response?.data?.result?.user_accounts?.firstName,
          lastName: response?.data?.result?.user_accounts?.lastName,
          telNo: response?.data?.result?.user_accounts?.telNo,
          address: response?.data?.result?.user_accounts?.address,
          balance_info: response?.data?.result?.user_accounts?.balance_info,
          balance_no: response?.data?.result?.user_accounts?.balance_no,
          promptpay: response?.data?.result?.user_accounts?.promptpay,
          education: response?.data?.result?.user_accounts?.education,
          organization: response?.data?.result?.user_accounts?.organization,
          position: response?.data?.result?.user_accounts?.position,
          about_us: response?.data?.result?.user_accounts?.about_us,
          facebook_url: response?.data?.result?.user_accounts?.facebook_url,
          instagram_url: response?.data?.result?.user_accounts?.instagram_url,
          twitter_url: response?.data?.result?.user_accounts?.twitter_url,
        });
      }
    } catch (error) {
      throw error;
    }
  }
  async function Edit() {
    if (state.username.length > 0 && state.email.length > 0) {
      const edit = await authActionPutUser({
        state: {
          ...state,
          facebook_url:
            state?.facebook_url?.length > 0
              ? "https://" +
                state?.facebook_url
                  ?.replace("https://", "")
                  ?.replace("http://", "")
              : "",
          instagram_url:
            state?.instagram_url?.length > 0
              ? "https://" +
                state?.instagram_url
                  ?.replace("https://", "")
                  ?.replace("http://", "")
              : "",
          twitter_url:
            state?.twitter_url?.length > 0
              ? "https://" +
                state?.twitter_url
                  ?.replace("https://", "")
                  ?.replace("http://", "")
              : "",
        },
        token: token?.accessToken,
      });

      if (edit) {
        setStatus();
        window.location.reload(false);
      }
      if (!edit) {
        setStatus(edit);
      }
    }

    if (state.username.length == 0) {
      setCheckUser(true);
    }
    if (state.email.length == 0) {
      setCheckEmail(true);
    }
  }
  async function forgetPassword() {
    if (body.nowpassword.length > 0 && body.newpassword.length > 0) {
      const rePassword = await authActionForgetPassword({
        state: body,
        token: token?.accessToken,
      });
      if (rePassword) {
        setModalPass(!modalPass);
      }
      if (!rePassword) {
        setWarningPass(true);
      }
    }
    if (body.nowpassword.length == 0) {
      setWarning(true);
    }
    if (body.newpassword.length == 0) {
      setWarning(true);
    }
  }

  const size = useWindowSize();
  return (
    <View style={{ minHeight: 1090 }}>
      <Modal
        transparent={true}
        visible={modalPass}
        onRequestClose={() => {
          Alert.alert("Modal has been closed.");
          setModalPass(!modalPass);
        }}
      >
        <View style={styles.bgModal}>
          <View
            style={{
              alignSelf: "center",
              width: width < 1600 ? "70%" : "50%",
              paddingBottom: 40,
              backgroundColor: "#FFFFFF",
              borderWidth: 1,
              borderColor: "#0D88F0",
            }}
          >
            <Text
              style={[
                size.width < 1280
                  ? stylesResponsive.textTitle
                  : styles.textTitle,
                ,
                { alignSelf: "center" },
              ]}
            >
              เปลี่ยนรหัสผ่าน
            </Text>
            <View
              style={{
                flexDirection: "row",
                width: "100%",
                paddingHorizontal: (size.width * 5) / 100,
                marginTop: 40,
                alignItems: "center",
              }}
            >
              <Text
                style={[
                  size.width < 1280
                    ? stylesResponsive.textDetail
                    : styles.textDetail,
                  { fontSize: size.width < 500 ? 14 : 25 },
                ]}
              >
                รหัสผ่านเดิม
              </Text>
              <View style={{ width: size.width < 500 ? "50%" : "70%" }}>
                <TextInput
                  autoComplete="off"
                  onChangeText={(text) => {
                    if (body.nowpassword != "") {
                      setWarningPass(false);
                      setWarning(false);
                    }
                    setBody({ ...body, nowpassword: text });
                  }}
                  secureTextEntry
                  style={[
                    size.width < 1280
                      ? stylesResponsive.inputPass
                      : styles.inputPass,
                    { marginLeft: 30 },
                  ]}
                />
                {warningPass && (
                  <Text
                    style={[
                      size.width < 1280
                        ? stylesResponsive.text10R
                        : styles.text14R,
                      ,
                      { marginLeft: 40 },
                    ]}
                  >
                    รหัสผ่านผิด
                  </Text>
                )}
              </View>
            </View>
            <View
              style={{
                flexDirection: "row",
                width: "100%",
                paddingHorizontal: (size.width * 5) / 100,
                marginTop: 40,
                alignItems: "center",
              }}
            >
              <Text
                style={[
                  size.width < 1280
                    ? stylesResponsive.textDetail
                    : styles.textDetail,
                  { fontSize: size.width < 500 ? 14 : 25 },
                ]}
              >
                รหัสผ่านใหม่
              </Text>
              <View style={{ width: size.width < 500 ? "50%" : "70%" }}>
                <TextInput
                  autoComplete="off"
                  onChangeText={(text) => {
                    if (body.newpassword != "") {
                      setWarning(false);
                    }
                    setBody({ ...body, newpassword: text });
                  }}
                  secureTextEntry
                  style={[
                    size.width < 1280
                      ? stylesResponsive.inputPass
                      : styles.inputPass,
                    { marginLeft: 30 },
                  ]}
                />
                {warning && (
                  <Text
                    style={[
                      size.width < 1280
                        ? stylesResponsive.text10R
                        : styles.text14R,
                      ,
                      { marginLeft: 40 },
                    ]}
                  >
                    กรุณากรอกข้อมูลให้ครบถ้วน
                  </Text>
                )}
              </View>
            </View>
            <View
              style={{
                flexDirection: "row",
                marginTop: 40,
                alignSelf: "center",
              }}
            >
              <TouchableOpacity
                onPress={forgetPassword}
                style={{
                  width: size.width < 500 ? 90 : 150,
                  height: size.width < 500 ? 40 : 60,
                  backgroundColor: "#0D88F0",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: 5,
                }}
              >
                <Text
                  style={{
                    fontSize: size.width < 500 ? 14 : 20,
                    fontFamily: "Prompt",
                    color: "#FFFFFF",
                  }}
                >
                  ยืนยัน
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => {
                  setModalPass(!modalPass);
                }}
                style={{
                  width: size.width < 500 ? 90 : 150,
                  height: size.width < 500 ? 40 : 60,
                  borderWidth: 1,
                  borderColor: "#0D88F0",
                  justifyContent: "center",
                  alignItems: "center",
                  marginLeft: 40,
                  borderRadius: 5,
                }}
              >
                <Text
                  style={{
                    fontSize: size.width < 500 ? 14 : 20,
                    fontFamily: "Prompt",
                    color: "#0D88F0",
                  }}
                >
                  ยกเลิก
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </Modal>
      <View
        style={{
          backgroundColor: "#0D88F0",
          width: "100%",
          height: 60,
        }}
      ></View>
      <View style={{ paddingHorizontal: 50 }}>
        <Text
          style={[
            size.width < 1280 ? stylesResponsive.textTitle : styles.textTitle,
          ]}
        >
          แก้ไขโปรไฟล์
        </Text>
        <View
          style={{
            flexDirection: size.width < 500 ? "column" : "row",
            justifyContent: "space-between",
            marginTop: 28,
          }}
        >
          <Text
            style={[
              size.width < 1280
                ? stylesResponsive.textDetail
                : styles.textDetail,
            ]}
          >
            {"ชื่อ\t\t"}
          </Text>
          <Text></Text>
          <TextInput
            autoComplete="off"
            editable={check == null ? true : false}
            defaultValue={state.firstName}
            onChangeText={(text) => setState({ ...state, firstName: text })}
            style={[
              size.width < 1280
                ? stylesResponsive.inputDetail
                : styles.inputDetail,
              { backgroundColor: check == null ? "#FFFFFF" : "#DCDCDC" },
            ]}
          />

          <Text
            style={[
              size.width < 1280
                ? stylesResponsive.textDetail
                : styles.textDetail,
              ,
              {
                paddingHorizontal: size.width < 500 ? 0 : 10,
                marginTop: size.width < 500 && 10,
              },
            ]}
          >
            นามสกุล
          </Text>
          <TextInput
            autoComplete="off"
            editable={check == null ? true : false}
            defaultValue={state.lastName}
            onChangeText={(text) => setState({ ...state, lastName: text })}
            style={[
              size.width < 1280
                ? stylesResponsive.inputDetail
                : styles.inputDetail,
              { backgroundColor: check == null ? "#FFFFFF" : "#DCDCDC" },
            ]}
          />
        </View>
        {token?.role == "TEACHER" ||
          (token?.role == "SCHOOL" && (
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                marginTop: 28,
              }}
            >
              <Text
                style={[
                  size.width < 1280
                    ? stylesResponsive.textDetail
                    : styles.textDetail,
                  ,
                  { paddingRight: 10 },
                ]}
              >
                ตำแหน่ง
              </Text>
              <TextInput
                autoComplete="off"
                editable={token?.role == "SCHOOL" ? false : true}
                defaultValue={state.position}
                onChangeText={(text) => setState({ ...state, position: text })}
                style={[
                  size.width < 1280
                    ? stylesResponsive.inputDetail1
                    : styles.inputDetail1,
                  { backgroundColor: "#DCDCDC" },
                ]}
              />
            </View>
          ))}
        <Text
          style={[
            size.width < 1280 ? stylesResponsive.textDetail : styles.textDetail,
            ,
            { marginTop: 28 },
          ]}
        >
          เกี่ยวกับฉัน
        </Text>
        <TextInput
          maxLength={255}
          autoComplete="off"
          defaultValue={state.about_us}
          onChangeText={(text) => setState({ ...state, about_us: text })}
          textAlignVertical="top"
          multiline
          style={{
            width: "100%",
            minHeight: 300,
            padding: 20,
            borderWidth: 1,
            borderColor: "#E0E0E0",
            marginTop: 18,
            fontSize: size.width < 1280 ? 14 : 20,
            fontFamily: "Prompt",
            color: "#646464",
          }}
        />
        <Text
          style={[
            size.width < 1280 ? stylesResponsive.textDetail : styles.textDetail,
            ,
            { fontSize: 12, alignSelf: "flex-end" },
          ]}
        >
          {state.about_us != undefined && state.about_us.length + "/255"}
        </Text>
        <Text
          style={[
            size.width < 1280 ? stylesResponsive.textDetail : styles.textDetail,
            ,
            { marginTop: 50 },
          ]}
        >
          Social Media
        </Text>
        <View
          style={{
            flexDirection: size.width < 500 ? "column" : "row",
            justifyContent: "space-between",
            marginTop: 10,
          }}
        >
          <Image
            style={{ width: 40, height: 40, marginRight: 10 }}
            source={require("../img/1024px-Facebook_Logo_(2019).png")}
          />
          <TextInput
            autoComplete="off"
            value={state.facebook_url}
            onChangeText={(text) =>
              setState({
                ...state,
                facebook_url: text,
              })
            }
            style={[
              size.width < 1280
                ? stylesResponsive.inputSocial
                : styles.inputSocial,
            ]}
          />
          <Image
            style={{
              width: 40,
              height: 40,
              marginHorizontal: size.width < 500 ? 0 : 10,
            }}
            source={require("../img/instagram-logo-png-transparent-background-800x799.png")}
          />
          <TextInput
            autoComplete="off"
            value={state.instagram_url}
            onChangeText={(text) =>
              setState({
                ...state,
                instagram_url: text,
              })
            }
            style={[
              size.width < 1280
                ? stylesResponsive.inputSocial
                : styles.inputSocial,
            ]}
          />
          <Image
            style={{
              width: 40,
              height: 40,
              marginHorizontal: size.width < 500 ? 0 : 10,
            }}
            source={require("../img/Twitter.png")}
          />
          <TextInput
            autoComplete="off"
            value={state.twitter_url}
            onChangeText={(text) =>
              setState({
                ...state,
                twitter_url: text,
              })
            }
            style={[
              size.width < 1280
                ? stylesResponsive.inputSocial
                : styles.inputSocial,
            ]}
          />
        </View>
        <View
          style={{
            marginTop: 50,

            flexDirection: size.width < 500 ? "column" : "row",
          }}
        >
          <View style={{ width: size.width < 500 ? "100%" : "35%" }}>
            <Text
              style={[
                size.width < 1280
                  ? stylesResponsive.textDetail
                  : styles.textDetail,
              ]}
            >
              เปลี่ยน E-mail
            </Text>
            <TextInput
              autoComplete="off"
              defaultValue={state.email}
              onChangeText={(text) => setState({ ...state, email: text })}
              style={[
                size.width < 1280
                  ? stylesResponsive.inputPass1
                  : styles.inputPass,
              ]}
            />
            {checkEmail && (
              <Text style={styles.text14R}>กรุณากรอกข้อมูลให้ครบถ้วน</Text>
            )}
          </View>
          <View
            style={{
              width: size.width < 500 ? "100%" : "35%",
              marginLeft: size.width < 500 ? 0 : 20,
              marginTop: size.width < 500 && 10,
            }}
          >
            <Text
              style={[
                size.width < 1280
                  ? stylesResponsive.textDetail
                  : styles.textDetail,
              ]}
            >
              เปลี่ยนชื่อผู้ใช้
            </Text>
            <TextInput
              editable={false}
              autoComplete="off"
              defaultValue={state.username}
              onChangeText={(text) => setState({ ...state, username: text })}
              style={[
                size.width < 1280
                  ? stylesResponsive.inputPass1
                  : styles.inputPass,
                { backgroundColor: "#DCDCDC" },
              ]}
            />
            {checkUser && (
              <Text style={styles.text14R}>กรุณากรอกข้อมูลให้ครบถ้วน</Text>
            )}
          </View>
          {/* <View>
            <Text style={styles.textDetail}>เปลี่ยนรหัสผ่าน</Text>
            <TextInput   autoComplete="off" style={styles.inputPass} />
          </View> */}
        </View>
        <View
          style={{
            flexDirection: size.width < 500 ? "column" : "row",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: size.width < 500 && 30,
          }}
        >
          <TouchableOpacity
            onPress={() => {
              setModalPass(true);
            }}
          >
            <Text
              style={[
                size.width < 1280
                  ? stylesResponsive.textDetail
                  : styles.textDetail,
                ,
                { textDecorationLine: "underline" },
              ]}
            >
              เปลี่ยนรหัสผ่าน
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={Edit}
            style={{
              width: size.width < 500 ? 150 : 225,
              height: size.width < 500 ? 40 : 82,
              backgroundColor: "#0D88F0",
              borderRadius: 19,
              justifyContent: "center",
              alignItems: "center",
              alignSelf: size.width < 500 ? "center" : "flex-end",
              marginTop: 37,
              marginBottom: size.width < 500 && 20,
            }}
          >
            <Text
              style={{
                fontSize: size.width < 500 ? 14 : 25,
                fontFamily: "Prompt",
                color: "#FFFFFF",
              }}
            >
              บันทึกการแก้ไข
            </Text>
          </TouchableOpacity>
        </View>
      </View>
    </View>
  );
}
const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: "100%",
    backgroundColor: "#FFFFFF",
  },
  textTitle: {
    fontSize: 30,
    fontFamily: "Prompt",
    color: "#646464",
    marginTop: 45,
  },
  textDetail: {
    fontSize: 25,
    fontFamily: "Prompt",
    color: "#646464",
  },
  inputDetail: {
    width: "100%",
    maxWidth: 450,
    height: 40,
    borderWidth: 1,
    borderColor: "#E0E0E0",
    paddingHorizontal: 15,
    fontSize: 20,
    fontFamily: "Prompt",
    color: "#646464",
  },
  inputDetail1: {
    width: "100%",
    maxWidth: 1070,
    height: 40,
    borderWidth: 1,
    borderColor: "#E0E0E0",
    paddingHorizontal: 15,
    fontSize: 20,
    fontFamily: "Prompt",
    color: "#646464",
  },
  inputSocial: {
    width: "100%",
    maxWidth: 320,
    height: 40,
    borderWidth: 1,
    borderColor: "#E0E0E0",
    paddingHorizontal: 15,
    fontSize: 20,
    fontFamily: "Prompt",
    color: "#646464",
  },
  inputPass: {
    width: "100%",
    // maxWidth: 604,
    height: 40,
    borderWidth: 1,
    borderColor: "#E0E0E0",
    paddingHorizontal: 15,
    fontSize: 20,
    fontFamily: "Prompt",
    color: "#646464",
  },
  bgModal: {
    width: "100%",
    height: "100%",
    backgroundColor: "#000000bb",
    justifyContent: "center",
  },
  text14R: {
    fontSize: 14,
    color: "red",
    fontFamily: "Prompt",
  },
});
const stylesResponsive = StyleSheet.create({
  textDetail: {
    fontSize: 16,
    fontFamily: "Prompt",
    color: "#646464",
  },
  textTitle: {
    fontSize: 22,
    fontFamily: "Prompt",
    color: "#646464",
    marginTop: 20,
  },
  inputDetail: {
    width: "100%",
    maxWidth: 450,
    height: 40,
    borderWidth: 1,
    borderColor: "#E0E0E0",
    paddingHorizontal: 15,
    fontSize: 14,
    fontFamily: "Prompt",
    color: "#646464",
  },
  inputDetail1: {
    width: "100%",
    maxWidth: 1070,
    height: 40,
    borderWidth: 1,
    borderColor: "#E0E0E0",
    paddingHorizontal: 15,
    fontSize: 14,
    fontFamily: "Prompt",
    color: "#646464",
  },
  inputSocial: {
    width: "100%",
    maxWidth: 320,
    height: 40,
    borderWidth: 1,
    borderColor: "#E0E0E0",
    paddingHorizontal: 15,
    fontSize: 14,
    fontFamily: "Prompt",
    color: "#646464",
    marginVertical: 10,
  },
  inputPass: {
    width: "100%",

    height: 40,
    borderWidth: 1,
    borderColor: "#E0E0E0",
    paddingHorizontal: 15,
    fontSize: 12,
    fontFamily: "Prompt",
    color: "#646464",
  },
  inputPass1: {
    width: "100%",
    maxWidth: 604,
    height: 40,
    borderWidth: 1,
    borderColor: "#E0E0E0",
    paddingHorizontal: 15,
    fontSize: 14,
    fontFamily: "Prompt",
    color: "#646464",
  },
  text10R: {
    fontSize: 10,
    color: "red",
    fontFamily: "Prompt",
  },
});
